import React from "react";
import SelectInput from "../../controls/select-input";
import Chip from "../../controls/chip";
import SVGIcon, { icons } from "../../svg-icon";
import { getUrlsEnv, openSecureDoc } from "../../backend-api";
import { connect } from "react-redux";
import { loadNormativityStatus } from "../../../redux/reducers/fleet-reducer";
import ValidatePerms from "../validate-perms";
import styled from "styled-components";
import Loader from "../../icons/tat/loader";

class FleetNormativityRow extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      downloadingFile: {}
    }
  }

  handleInput = (e) => {

    if (e.target.value === 1) {
      return;
    }

    const { shareID = "" } = this.props;

    this.props.onChangeStatus({
      target: {
        name: e.target.name,
        value: e.target.value,
        id: shareID === "" ? this.props.normId : shareID,
      },
    });
  };

  render() {

    return (
      <div className="fleet-normativity-row">
        <div className="header">
          {this.props.iconElement}
          {/* <img src={this.props.title_icon} alt="" /> */}
          <div className="title">{this.props.title}</div>
          {this.props.exceedPlusDiv}
          {this.props.documentStatus != null ? (
            <ValidatePerms
              perms={[
                {
                  id: "38",
                  perm: "u",
                },
              ]}
            >
              {this.props.documentStatusLocked ? (
                this.props.isConsidered ? (
                  <div className={"statusName status-".concat(this.props.documentStatus)}>
                    {
                      (
                        (this.props.normativityStatus || []).find((item) => {
                          return (
                            String(item.id) ===
                            String(this.props.documentStatus)
                          );
                        }) || {}
                      ).description
                    }
                  </div>
                ) : (
                  this.props.isConsidered !== undefined && (
                    <div className="na">No aplica</div>
                  )
                )
              ) : this.props.isConsidered ? (
                <SelectInput
                  // label="Estatus documento"
                  label="Estatus"
                  name={this.props.title}
                  value={this.props.documentStatus}
                  className={
                    this.props.documentStatus === 1
                      ? "pending"
                      : this.props.documentStatus === 2
                      ? "validated"
                      : this.props.documentStatus === 3
                      ? "rejected"
                      : ""
                  }
                  onChange={this.handleInput}
                  items={
                    (this.props.normativityStatus || []).map((item) => {
                      let newItem = { ...item };
                      if (item.id === 1 || item.id === 2 || item.id === 3) {
                        newItem.disabled = false;
                        if(item.id === 1) {
                          newItem.className = "pending";
                          // if(this.props.documentStatus === 1) {
                            newItem.disabled = true;
                          // }
                        }
                        if(item.id === 2) {
                          newItem.className = "validated";
                          if(this.props.documentStatus === 2) {
                            newItem.disabled = true;
                          }
                        }
                        if(item.id === 3) {
                          newItem.className = "rejected";
                          if(this.props.documentStatus === 3) {
                            newItem.disabled = true;
                          }
                        }
                      } else {
                        newItem.disabled = true;
                      }
                      return newItem;
                    })
                    // (this.props.normativityStatus || []).filter(
                    //   status => status.id === 2 || status.id === 3
                    // )
                  }
                />
              ) : (
                <div className="na">No aplica</div>
              )}
            </ValidatePerms>
          ) : (
            <React.Fragment />
          )}
          {
            this.props.extraInfo || ''
          }
        </div>
        <div className="content">
          <div className="row">
            {this.props.documentStatus != null ? (
              <div className="subtitle">{this.props.documentLabel}</div>
            ) : (
              <React.Fragment />
            )}
            <div className="value">
              {(this.props.images || []).map((img) => {
                return (
                  <ChipContainer>
                    <Chip
                      key={img.id}
                      id={img.id}
                      active={true}
                      className="white small"
                      name="files"
                      description={"".concat(
                        String(img.original_name).length > 10
                          ? String(img.original_name).substring(0, 10) + "... "
                          : img.original_name || "",
                        "(",
                        img.file_size,
                        ")"
                      )}
                      onClick={() => this.openDocumentUrlImage(img.url)}
                      prefix={{
                        element: (
                          <SVGIcon
                            name={icons.imageFile}
                            fill="#657795"
                            viewBox="0 -1 16 16"
                          />
                        ),
                      }}
                    />
                    {
                      (this.isDownloadingFile(`${img.id}`)) ? <Loader width="25px" height="25px"/> :
                        <DownloadButtonContainer 
                          onClick={() => this.openDocument(img.url, `${img.id}`)}
                        >
                          <DownloadImage 
                            alt=""
                            src={"/images/download_icon.png"}
                        />
                      </DownloadButtonContainer>
                    }
                  </ChipContainer>
                );
              })}
            </div>
          </div>
          {this.props.details.map((detail) => {
            return this.props.title === "Tarjeta de circulación" &&
              detail.name === "Expiración:" ? (
              <React.Fragment />
            ) : (
              <div className="row" key={detail.name}>
                <div className="subtitle">{detail.name}</div>
                <div className="value">{detail.value}</div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  openDocumentUrlImage = (url) => {
    if (!url) return
    window.open(getUrlsEnv().files.concat(url), "_blank");
  }

  openDocument = (url, id) => {
    this.state.downloadingFile[id] = true;
    this.setState({});

    openSecureDoc(getUrlsEnv().files.concat(url)).then((response) => {
      var array = url.split("/");
      var lastsegment = array.length > 0 ? array[array.length - 1] : ".";

      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);

        let a = document.createElement("a");
        a.href = url;
        a.download = lastsegment;
        a.click();

        this.state.downloadingFile[id] = false;
        this.setState({});
      });
    }).catch((rej) => {
      this.state.downloadingFile[id] = false;
      this.setState({});
    });
  };

  isDownloadingFile = (id) => {
    return (id in this.state.downloadingFile && this.state.downloadingFile[id]);
  }
}

const mapStateToProps = (state) => {
  return {
    normativityStatus: state.fleetReducer.normativityStatus.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  dispatch(loadNormativityStatus());
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FleetNormativityRow);

FleetNormativityRow.defaultProps = {
  title: "Vacio",
  title_icon: "/images/help.png",
  onChangeStatus: () => {
    console.log("Not implemented Yet!");
  },
  documentStatus: 0,
  documentStatusLocked: false,
  details: [
    {
      name: "Sin documentos",
      value: "frente.pdf",
    },
  ],
  documentLabel: "Documentos:",
  isConsideredDisplayed: false,
  isConsidered: true,
};

const ChipContainer = styled.div`
  display: flex;
  align-items: center;
`;

const DownloadButtonContainer = styled.div`
  height: 25px;
  width: 25px;
  background-color: #ebedf1;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DownloadImage = styled.img`
  width: 12px;
  height: 12px;
`;