import React from "react";
import { NotificationConfigurationViewStyle } from "./style";
import Button from "../../shared/controls/button";
import Tag from "../../shared/controls/tag";
import { cloneDeep } from "lodash";

class NotificationConfigurationView extends React.Component { 

  constructor(props) {
    super(props);

    let facilities_data = this.getFacilitiesSelected();

    this.state = {
      notification: props.notification,
      userOpened: null,
      usersSelected: this.setUsersSelected(),
      facilitiesSelected: facilities_data.facilities,
      facilitiesGroup: facilities_data.group_id,
      users: this.getAllUsers(props?.notification?.groups || []),
    };

  }

  componentDidUpdate(prev) {
    if (this.props.usersToDelete && this.props.usersToDelete.notification_pivot !== this.state.notification.id && this.state.usersSelected.length > 0) {
      this.setState({usersSelected: []});
    }

    if (this.props.facilitiesToDelete === null && prev.facilitiesToDelete !== null && this.state.facilitiesGroup !== null) {
      this.setState({facilitiesGroup: null, facilitiesSelected: []});
    }
  }

  getFacilitiesSelected = () => {
    let facilities_data = {facilities: [], group_id: null};

    if (this.props.facilitiesToDelete !== null && this.props.notification !== null && this.props.facilitiesToDelete.notification_id === this.props.notification.id && this.props.facilitiesToDelete.facilities.length > 0) {
      facilities_data.facilities = this.props.facilitiesToDelete.facilities;
      facilities_data.group_id = this.props.facilitiesToDelete.group_id;
    }

    return facilities_data;
  }

  hasFacilitiesToShow = () => {
    let notification_name_list = [
      'Perfil de empresa',
      'Perfil personal',
      'Catálogo de usuarios',
      'Catálogo de productos',
      'Catálogo de flotilla dueño de carga',
      'Catálogo de flotilla transportista',
      'Catálogo de Red'
    ];

    return !notification_name_list.includes(this.state.notification.description || '');
  }

  setUsersSelected = () => {
    let users = [];
    if (this.props.usersToDelete && this.props.usersToDelete.notification_pivot !== null && this.props.usersToDelete.notification_pivot === this.props.notification.id && this.props.usersToDelete.users_to_delete.length > 0) {
      users = this.props.usersToDelete.users_to_delete;
    }

    return users;
  }

  getAllUsers = (groups) => {
    let all_users = [];

    groups.map( item => {
      // Se añade el group id
      let user_detail = item.users_detail.map( item_2 => {
        item_2.group_id = item.id;
        return item_2;
      });

      all_users = all_users.concat(user_detail);
    });

    return all_users;
  }

  buildFacilityTable = (user) => {
    let key = user.group_id;

    let facilities = [];
    let facilities_group = (this.props?.notification?.groups || []).filter( item => item.id === user.group_id );

    if (facilities_group && facilities_group.length > 0) {
      facilities = facilities_group[0].facilities_detail || [];
    }

    if (facilities.length === 0) {
      return(
        <div
          style={{
            height: 50,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: 16
          }}
        >
          {'No hay instalaciones'}
        </div>
      );
    }

    return(
      <div className="configuration-table" key={`configuration-table-${key}`}>
        <div className="row-title">
          <div className="item-title">Instalaciones</div>
          <div className="item-title">Código de Instalación</div>
          <div className="item-title">Alias</div>
          <div className="item-title">Etiquetas</div>
          <div className="item-title" style={{flex: '0.7'}}></div>
        </div>

        {
          facilities.map((item, index) => {
            return(
              <div className="row" key={`row-${index}`}>
                <div className="item" style={{justifyContent: 'start'}}>
                  <div style={{width: 20}}/>

                  <div
                    className={`checkbox-container ${(this.state.facilitiesSelected.includes(item.id) && this.state.facilitiesGroup === user.group_id) ? 'selected' : ''}`} 
                    onClick={() => {

                      if (this.state.facilitiesGroup === null || this.state.facilitiesGroup !== user.group_id) {
                        this.state.facilitiesGroup = user.group_id;
                        this.state.facilitiesSelected = [];
                      }

                      if (this.state.facilitiesSelected.includes(item.id)) {
                        this.state.facilitiesSelected = this.state.facilitiesSelected.filter( user_id => user_id !== item.id);
                      } else {
                        this.state.facilitiesSelected.push(item.id);
                      }

                      this.setState({});

                      this.props.handleChange({
                        target: {
                          name:'add-facilities-to-delete',
                          value: {
                            group_id: user.group_id,
                            facilities: this.state.facilitiesSelected,
                            notification_id: this.state.notification.id,
                            user_name: `${user.users__first_name} ${user.users__last_name}`
                          }
                        }
                      });
                    }}
                  >
                    <div className="checkbox"></div>
                  </div>

                  <div style={{width: 10}}/>
                  {item.name}
                </div>
                <div className="item">{item.code}</div>
                <div className="item">{item.alias}</div>
                <div className="item">
                  {item.tags.map(tag => {
                    return (
                      <Tag
                        key={tag.id}
                        id={tag.id}
                        color={tag.color}
                        title={tag.title}
                        type={tag.type || 0}
                        isFull={true}
                        onClick={() => {
                        }}
                        parentBackground="light"
                      />
                    );
                  })}
                </div>
                <div className="item" style={{flex: '0.7'}}>

                  {
                    (this.state.facilitiesSelected.length === 1 && this.state.facilitiesSelected.includes(item.id) && this.state.facilitiesGroup === user.group_id) && 
                    <img
                      alt=""
                      src="/images/bin.png"
                      style={{ cursor: "pointer" }}
                      onClick={() => {

                        this.props.handleChange({
                          target: {
                            name:'delete-unique-facility',
                            value: {
                              user_name: `${user.users__first_name} ${user.users__last_name}`,
                              group_id: user.group_id,
                              facility_id: item.id,
                              facility_name: `${item.name} ${item.code} ${item.alias}`,
                              notification: this.state.notification.id
                            }
                          }
                        });

                      }}
                    />
                  }

                </div>
              </div>
            );
          })
        }
      </div>
    );
  }

  render() {

    return(
      <NotificationConfigurationViewStyle
        key={`notification-${this.state.notification.id}`}
      >
        <div className="configuration-table">
          <div className="row-title">
            <div className="item-title">Usuario</div>
            <div className="item-title">Correo</div>
            <div className="item-title">Rol</div>
            <div className="item-title">Tipo</div>
            <div className="item-title" style={{flex: '0.7'}}>

              {
                this.hasFacilitiesToShow() && 
                <Button
                  type={'primary small outline dark'}
                  text={'Añadir Usuarios'}
                  onClick={() => {
                    if (this.props.handleChange) {
                      let notification = cloneDeep(this.state.notification);
                      notification.has_facilities = true;

                      this.props.handleChange({
                        target: {
                          name:'show-add-user-modal', 
                          value: notification
                        }
                      });
                    } else {
                      console.log('show-add-user-modal accion no implemetada');
                    }
                  }}
                />                
              }

              <Button
                type={'primary small outline dark'}
                text={'Gestionar Usuarios'}
                onClick={() => {
                  if (this.props.handleChange) {
                    let notification = cloneDeep(this.state.notification);
                    notification.has_facilities = false;

                    this.props.handleChange({
                      target: {
                        name:'show-add-user-modal', 
                        value: notification
                      }
                    });
                  } else {
                    console.log('show-add-user-modal accion no implemetada');
                  }
                }}
              />

            </div>
          </div>

          <div className="list">
            {
              (this.state.users || []).length === 0 &&
              <div
                style={{
                  height: 50,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontSize: 16
                }}
              >
                {'No hay usuarios'}
              </div>
            }

            {
              (this.state.users || []).map( (item, index) => {
                return(
                  <React.Fragment key={`notification-configuration-${this.state.notification.id}-${item.users_id}`}>
                    <div className={`row ${(index % 2) === 0 ? 'style-1' : 'style-2'}`} key={`notification-${this.state.notification.id}-${item.users_id}`}>
                      <div className="item" style={{justifyContent: 'start'}}>
                        <div
                          className={`checkbox-container ${this.state.usersSelected.includes(item.users_id) ? 'selected' : ''}`}
                          onClick={() => {
                            if (this.state.usersSelected.includes(item.users_id)) {
                              this.state.usersSelected = this.state.usersSelected.filter( user_id => user_id !== item.users_id);
                            } else {
                              this.state.usersSelected.push(item.users_id);
                            }

                            this.setState({});

                            this.props.handleChange({
                              target: {
                                name:'add-users-to-delete',
                                value: {
                                  users: this.state.usersSelected,
                                  notification_id: this.state.notification.id
                                }
                              }
                            });
                          }}
                        >
                          <div className="checkbox"></div>
                        </div>
                        
                        {
                          this.hasFacilitiesToShow() &&
                          <>
                            <div style={{width: 10}}/>

                            <div
                              className={"collapser".concat(
                                this.state.userOpened === item.users_id ? " collapsed" : ""
                              )}
                              onClick={() => {
                                this.setState({
                                  userOpened: this.state.userOpened === item.users_id ? null : item.users_id
                                })
                              }}
                            >
                              <img alt="" src="/images/select-arrow.svg" />
                            </div>
                          </>
                        }
                        
                        <div style={{width: 10}}/>

                        {`${item.users__first_name} ${item.users__last_name}`}
                      </div>
                      <div className="item">{item.users__email}</div>
                      <div className="item">{item.role__description}</div>
                      <div className="item">{item.users_type__description}</div>
                      <div className="item" style={{flex: '0.7'}}>

                        {
                          (this.state.usersSelected.length === 1 && this.state.usersSelected[0] === item.users_id) &&
                          <img
                            alt=""
                            src="/images/bin.png"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              this.props.handleChange({
                                target: {
                                  name:'delete-unique-user',
                                  value: {
                                    user_name: `${item.users__first_name} ${item.users__last_name} - ${item.users__email}`,
                                    user_id: item.users_id,
                                    notification: this.state.notification.id
                                  }
                                }
                              });
                            }}
                          />
                        } 

                        {
                          this.hasFacilitiesToShow() &&
                          <Button
                            type={'primary small outline dark fit-width'}
                            text={'Gestionar Instalaciones'}
                            onClick={() => {
                              if (this.props.handleChange) {

                                let facilities_group = (this.props?.notification?.groups || []).filter( group => group.id === item.group_id );
                                let facilities = [];

                                if (facilities_group && facilities_group.length > 0) {
                                  facilities = facilities_group[0].facilities || [];
                                }

                                this.props.handleChange({
                                  target: {
                                    name:'show-manage-facility',
                                    value: {
                                      user: item,
                                      notification: this.state.notification,
                                      facilities: facilities.map(item => parseInt(item))
                                    }
                                  }
                                });

                              } else {
                                console.log('show-manage-facility accion no implemetada');
                              }
                              
                            }}
                          />
                        }                      

                      </div>
                    </div>
                    {
                      (this.state.userOpened && this.state.userOpened === item.users_id) && this.buildFacilityTable(item)
                    }
                  </React.Fragment>
                );
              })
            }
          </div>
        </div>
      </NotificationConfigurationViewStyle>
    );
  }

}

export default NotificationConfigurationView;