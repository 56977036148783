import * as React from "react";
import Modal from "react-modal";
import Button from "../../shared/controls/button";
import Tag from "../../shared/controls/tag";
import TextInput from "../../shared/controls/text-input";
import SVGIcon, { icons } from "../../shared/svg-icon";
import { US_HOURLY_FORMAT } from "../../shared/utils";
import ModalSelectInput from "../shipment/modal-select-input";
import styled from 'styled-components';
import Loader from "../../shared/icons/tat/loader";

class ConsolidateTripModal extends React.Component {
  state = {
    signatureDuplicated: false
  }
  
  onClose = () => {
    this.props.onClose();
  }

  createTrip = () => {

    this.props.handleChange({
      target: { 
        name: "validateSignature", 
        value: { 
          found_callback: () => { this.setState({signatureDuplicated: true}) },
          no_found_callback: () => { 
            this.props.handleChange({
              target: {
                name: "consolidateTrip",
                value: this.onClose
              }
            });
          }
        }
      }
    });

  }

  handleTagClick = (e) => {
    if (e.target.value) {
      this.props.handleChange({
        target: {
          name: "selectedTags",
          value: this.props.selectedTags.filter((t) => t !== e.target.id)
        }
      });
    } else {
      this.props.handleChange({
        target: {
          name: "selectedTags",
          value: this.props.selectedTags.concat(e.target.id)
        }
      });
    }
  };

  render () {
    return  <Modal
        isOpen={this.props.show}
        portalClassName="dialog emails-modal invite-users-modal consolidate-order-modal"
        overlayClassName="overlay"
        className="content"
      >
        <div className="title">
          Consolidar viaje
        </div>
        <div className="close" onClick={this.onClose}></div>
        <div className="message">
          <div style={{width: "50%"}}>
            <TextInput
              label="Signatura"
              name="selectedCode"
              value={this.props.selectedCode}
              className="item"
              onChange={this.props.handleChange}
              // disabled={true}
              isUserTyping={() => {
                if (this.state.signatureDuplicated) {
                  this.setState({signatureDuplicated: false});
                }
              }}
            />
            {
              this.state.signatureDuplicated &&
              <RecommendationError>
                {'La signatura ya está en uso'}
              </RecommendationError>
            }
          </div>
          <div>
            <div style={{display: "flex"}}>
              <div style={{width: "60%", marginRight: "15px"}}>
                <ModalSelectInput
                  isSearchable={false}
                  isDisabled={false}
                  // defaultValue={this.props.providerCatalog[0]}
                  onChange={(a) =>
                    this.props.handleChange({
                      target: { name: "selectedClass", value: a.value },
                    })
                  }
                  name="selectedClass"
                  placeholder="Clase"
                  value={this.props.classCatalog.find(fC => fC.value === this.props.selectedClass) || null}
                  options={this.props.classCatalog}
                />
              </div>
              <div>
                <span style={{fontSize: "12px", lineHeight: "18px", color: "#9ba1acb3"}}>Selecciona las etiquetas de tu viaje (opcional)</span>
                <div className="user-tags-options-container">
                  {(this.props.tagsCatalog || []).map((tag) => {
                    return (
                      <Tag
                        key={tag.id}
                        id={tag.id}
                        color={tag.color}
                        title={tag.title}
                        type={tag.type}
                        isFull={this.props.selectedTags.includes(tag.id)}
                        onClick={this.handleTagClick}
                        parentBackground="light"
                      />
                    );
                  })}
                </div>
              </div>
            </div>
            <div>
              
            </div>
          </div>
          <div className="orders-container">
            <div className="resume-header">
              Órdenes seleccionadas:
            </div>
            <div className="row-header">
              <div style={{width: "30%"}}>Código</div>
              <div style={{width: "35%"}}>Origen</div>
              <div style={{width: "35%"}}>Destino</div>
            </div>
            <div className="row-container">
              {
                this.props.selectedOrders.map(o => (
                  <div className="order-row">
                    <div style={{width: "30%"}}>{o.code}</div>
                    <div style={{width: "35%"}}>{o.from_facility?.name || '-'}</div>
                    <div style={{width: "35%"}}>{o.to_facility?.name || '-'}</div>
                  </div>
                ))
              }
            </div>
          </div>
        </div>
        <div className="actions">
          <Button
            text={"Cancelar"}
            type="secondary outline"
            onClick={this.onClose}
            // disabled={!this.props.selectedCode}
          />
          <Button
            text={this.props.isValidationLoading ? <Loader circleColor={'black'}/> : "Consolidar viaje"} 
            type="primary outline"
            onClick={this.createTrip}
            disabled={!this.props.selectedCode || !this.props.selectedClass || this.props.isValidationLoading}
          />
        </div>
      </Modal>
  }

}

export default ConsolidateTripModal

export const RecommendationError = styled.div`
  color: red;
  font-size: 13px;
  display: flex;
  justify-content: center;
`