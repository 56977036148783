// @flow
import { API_REQUEST_STRATEGY_OPTIONS } from "./helpers";
import { ApiReducerAction, ApiOptions } from "./types";

export const readEntity = (
  entityPath: string,
  params: any = {},
  options: ApiOptions = {}
): ApiReducerAction => ({
  type: `API_REQUEST_READ_${entityPath}`,
  params,
  onSuccess: options.onSuccess,
  onError: options.onError,
  prefix: options.prefix,
  entityPath,
  strategy: options.strategy || API_REQUEST_STRATEGY_OPTIONS.DEFAULT,
  args: options.args,
  alias: options.alias,
});

export const readEntities = (
  entityPath: string,
  params: any = {},
  options: ApiOptions = {}
): ApiReducerAction => ({
  type: `API_REQUEST_LIST_${entityPath}`,
  params,
  onSuccess: options.onSuccess,
  onError: options.onError,
  prefix: options.prefix,
  server: options.server,
  entityPath,
  strategy: options.strategy || API_REQUEST_STRATEGY_OPTIONS.DEFAULT,
  args: options.args,
  alias: options.alias,
});

export const createEntity = (
  entityPath: string,
  params: any = {},
  options: ApiOptions = {}
): ApiReducerAction => ({
  type: `API_REQUEST_CREATE_${entityPath}`,
  params,
  onSuccess: options.onSuccess,
  onError: options.onError,
  entityPath,
  strategy: options.strategy || API_REQUEST_STRATEGY_OPTIONS.DEFAULT,
  args: options.args,
  alias: options.alias,
  isForm: options.isForm || false,
  responseType: options.responseType
});

export const deleteEntity = (
  entityPath: string,
  params: any = {},
  options: ApiOptions = {}
): ApiReducerAction => ({
  type: `API_REQUEST_DELETE_${entityPath}`,
  params,
  onSuccess: options.onSuccess,
  onError: options.onError,
  entityPath,
  strategy: options.strategy || API_REQUEST_STRATEGY_OPTIONS.IGNORE,
  args: options.args,
  alias: options.alias,
});

export const readCatalog = (
  entityPath: string,
  params: any = {},
  options: ApiOptions = {}
): ApiReducerAction => ({
  type: `API_REQUEST_CATALOG_${entityPath}`,
  params,
  onSuccess: options.onSuccess,
  onError: options.onError,
  entityPath,
  strategy: options.strategy || API_REQUEST_STRATEGY_OPTIONS.DEFAULT,
  args: options.args,
  alias: options.alias,
});

export const partialEntity = (
  entityPath: string,
  params: any = {},
  options: ApiOptions = {}
): ApiReducerAction => ({
  type: `API_REQUEST_PARTIAL_${entityPath}`,
  params,
  onSuccess: options.onSuccess,
  onError: options.onError,
  entityPath,
  strategy: options.strategy || API_REQUEST_STRATEGY_OPTIONS.DEFAULT,
  args: options.args,
  alias: options.alias,
  isForm: options.isForm || false,
});

export const updateEntity = (
  entityPath: string,
  params: any = {},
  options: ApiOptions = {}
): ApiReducerAction => ({
  type: `API_REQUEST_UPDATE_${entityPath}`,
  params,
  onSuccess: options.onSuccess,
  onError: options.onError,
  entityPath,
  strategy: API_REQUEST_STRATEGY_OPTIONS.DEFAULT,
  args: options.args,
  alias: options.alias,
  isForm: options.isForm || false,
});

export const cancelRequest = (entityPath: string) => ({
  type: `API_REQUEST_CANCEL_${entityPath}`,
});

export const cancelMultipleRequests = () => ({
  type: 'API_REQUEST_CANCEL_MULTIPLE_REQUESTS'
});
