import React from 'react';
import Modal from 'react-modal';
import Button from '../controls/button';
import CompanyChip from '../controls/company-chip';
import InputCompany from '../controls/input-company';
import { postValidateCompany } from '../../redux/reducers/register-company.reducer';
import { connect } from 'react-redux';
import { getUrlsEnv } from '../backend-api';
import { addToast } from '../../redux/reducers/global-catalog.reducer';
import { shareVehicleFleet, linkCompanyToFleet, shareEquipmentFleet, shareOperatorFleet } from '../../redux/reducers/fleet-reducer';
import styled from 'styled-components';

class ShareFleetModal extends React.Component {

  constructor(props) {
    super(props);

    this.fetchCounter = 0;
    this.responseCounter = 0;
    this.fleetsNumber = 0;
    this.companiesNumber = 0;

    this.counterId = -9999;

    this.state = {
      items: props.items,
      companies: [],
      error_message: ``
    }
  }

  componentDidUpdate() {
    if (
      this.fetchCounter > 0 &&
      this.fetchCounter === this.responseCounter &&
      this.props.toast === null
    ) {
      this.onClose();

      if (this.props.closeSelectMode) {
        this.props.closeSelectMode()
      }

      this.props.reloadItems && this.props.reloadItems();

      this.fetchCounter = 0;
      this.responseCounter = 0;

      this.props.showToast({ text: `Compartido exitoso: Has compartido ${this.fleetsNumber} flotillas con ${this.companiesNumber} empresas de tu red.`});
    }

  }

  onClose = () => {
    this.setState({ items: [], companies: []});
    this.props.closeAction && this.props.closeAction();
    this.counterId = -9999;
  }
  
  applyButtonSettings = {
    text: 'Compartir',
    onClick: (() => {})
  }

  handleEmailInput = (e) => {

    if (this.state.error_message !== '') {
      this.setState({
        error_message: ''
      });
    }


    if(e){
      switch (e.target.name) {
        case 'inviteCompanies':
            e.target.value.filter(it => !it.processed).forEach(i => this.onCompany(i.company));
            break;
        case 'deleteCompany':
            this.setState({ companies: [].concat(e.target.value) });
            break;
        default:
      }
    }     
  }

  onCompany = (companyToSearch) => {
    
    

    if (companyToSearch && companyToSearch.length > 0 && !this.isValueAdded(companyToSearch)) { 
      this.props.postValidateCompany(encodeURIComponent(companyToSearch)).then( companyData => {

        if ((companyData || {}).type === 'VALIDATE_COMPANY_RESPONSE') {

          const { logo = '', name = '', code = '', alias = '', provider = '', id = '', network = '' } = (companyData || {}).company;

          let company = {
            avatar: getUrlsEnv().files.concat(logo), name, code, alias, provider, id: String(id), error: '', network: String(network)
          };
    
          this.setState({ companies: this.state.companies.concat(company) });

        } else {
          this.setState({
            error_message: companyData.error?.msg || 'Ocurrió un error'
          });
        }

      });
    }
  }

  isValueAdded = (value) => {

    let valueFound = false

    this.state.companies.forEach( item => {
      
      if (item.code === value || item.name === value || item.alias === value || item.error === value) {
        valueFound = true;
      }

    });

    return valueFound;

  }

  deleteCompany = (companyToDelete) => {
    let newValue = [];
    
    if (companyToDelete.error && companyToDelete.error !== '' ) {
      newValue = this.state.companies.filter(f => f.error !== companyToDelete.error);
    } else {
      newValue = this.state.companies.filter(f => f.id !== companyToDelete.id);
    }
    
    this.setState({companies: newValue});
  }

  callShareFleet = (provider, fleetDataID) => {

    switch (this.props.fleetType) {
      case 2:

        this.props.shareEquipmentFleet(provider, fleetDataID).then(response => {
          this.responseCounter += 1
          this.forceUpdate();
        })
        
        break;

      case 3:
        
        this.props.shareOperatorFleet(provider, fleetDataID).then(response => {
          this.responseCounter += 1
          this.forceUpdate();
        })
        
        break;
    
      default:
        this.props.shareVehicleFleet(provider, fleetDataID).then(response => {
          this.responseCounter += 1
          this.forceUpdate();
        })
        
        break;
    }

  }

  render() {
    return (
      <Modal
        isOpen = {this.props.open}
        portalClassName="dialog share-fleets-modal"
        overlayClassName="overlay"
        className="content"
        shouldCloseOnOverlayClick = {true}
      >
        <div className="title">Compartir flotillas</div>
        <div className="close" onClick={this.onClose}></div>
        <div className="message">
          <p>Estás compartiendo <b>{this.props.items.length} flotillas</b>:</p>

          <div className="text-input">
            <InputCompany
              placeholder='Ingresa el nombre o código de la empresa de tu red'
              className='field'
              domain={false}
              companiesCatalog={this.props.companiesCatalog}
              companies={this.state.companies}
              onChange={this.handleEmailInput}
              isUserTyping={() => {
                if (this.state.error_message !== '') {
                  this.setState({error_message: ''});
                }
              }}
            />
          </div>

          {
            this.state.error_message !== '' &&
            <RecommendationError>
              {`${this.state.error_message}`}
            </RecommendationError>
          }

          <div className="companies-container">
            {this.state.companies.map((company, index) => {
              return (
                <CompanyChip
                  company={company}
                  key={company.id}
                  deleteCompany={this.deleteCompany}
                />
              )
            })}
          </div>

        </div>
        <div className="actions">
            <Button 
              text={this.fetchCounter > 0 ? 'Compartiendo...' : 'Compartir'} 
              disabled={this.fetchCounter > 0 || this.state.companies.length === 0} 
              settings={this.applyButtonSettings} 
              onClick={ () => {
                this.fleetsNumber = (this.props.items) ? this.props.items.length : 0;
                const companiesResult = this.state.companies.filter( comp => comp.error === '' );
                this.companiesNumber = companiesResult.length;
                if (Array.isArray(this.props.items)) {
                  this.props.items.forEach( item => {
                    if (String(item.shared) !== "-1") { 
                      companiesResult.forEach( company => {
                        if (company.provider === null) {
                          this.props.linkCompanyToFleet(company.network).then( responseData => {
                            if (responseData.type === 'FLEET_SHARES_FLEET_PROVIDER_SUCCESS') {
                              this.fetchCounter += 1;
                              this.forceUpdate();
                              const { id = '' } = responseData.response;
                              this.callShareFleet(id, item.fleet_data_id)
                            }
                          })
                        } else {
                          this.fetchCounter += 1;
                          this.forceUpdate();
                          this.callShareFleet(company.provider, item.fleet_data_id)
                        }
                      })
                    } else {
                      this.fetchCounter += 1;
                      this.forceUpdate();
                      this.callShareFleet(0, 0)
                    }
                  });
                }
              }}
            />
        </div>
      </Modal>
    )
  }
}

const mapStateToProps = state => {
  return {
    toast: state.globalCatalog.toast,
  };
}

const mapDispatchToProps = dispatch => ({
  postValidateCompany: (company) => dispatch(postValidateCompany(company, 'validate_status')),
  showToast: (payload) => dispatch(addToast(payload)),
  shareVehicleFleet: (provider_id, fleet_data_id) => dispatch(shareVehicleFleet(provider_id, fleet_data_id)),
  shareEquipmentFleet: (provider_id, fleet_data_id) => dispatch(shareEquipmentFleet(provider_id, fleet_data_id)),
  shareOperatorFleet: (provider_id, fleet_data_id) => dispatch(shareOperatorFleet(provider_id, fleet_data_id)),
  linkCompanyToFleet: (id) => dispatch(linkCompanyToFleet(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(ShareFleetModal);

ShareFleetModal = {
  items: [],
  companiesCatalog: [],
  closeAction: () => {console.log('Not yet implemented')}
}

export const RecommendationError = styled.div`
  color: red;
  font-size: 13px;
`