//@flow
import React, { createRef } from "react";
import { connect } from "react-redux";
import type { RouterHistory } from "react-router-dom";

import {
  TrafficSpacesGrid,
  TrafficSpacesGrid2,
  ShipmentDetailDialog
} from "orbi_v2_frontend_components";

import CalendarSelectorRibbon from "../styled/calendar-selector-ribbon";

import {
  ApiOptions,
  readEntity,
  readEntities,
  getEntityItems,
  partialEntity,
  FILES_SERVER,
  cancelRequest
} from "../../../redux/api";
import {
  validateObjPerms,
} from "../../../redux/reducers/global-catalog.reducer";
import { getRibbonWeeklyDates } from "../utils";
import * as Types from "../types";
import * as SharedTypes from "../../../shared/types";
import { Flex, flexOpts } from "../../../shared/styled/flex";
import WeekPicker from "../week-picker";

import {
  mapResponseToSpaces,
  mapSpaceTags,
  mapSpaceAppointments,
  mapSpaceWindows,
  getOrderDetail,
  mapSpaceAppointmentsConflicts,
} from "./utils";
// import { setStatus } from "../../../redux/reducers/shipment-reducer";
import EditAppointmentModal from "./edit-appointment-modal";
import {
  loadStates,
  loadCities,
} from "../../../redux/reducers/global-catalog.reducer";
import { Marker } from "../../../shared/icons/traffic";
// import CancelModal from "./cancel-booking-modal";
import CancelBookingModal from "./cancel-booking-modal";
import FinalizeTripModal from "./finalize-trip-modal";
import FinalizeOrderModal from "./finalize-order-modal";
import CancelAppointmentModal from "./cancel-appointment-modal";
import CancelTripModal from "./cancel-trip-modal";
import ShipmentReserveDialog from "../booking/shipment-reserve-dialog";
import { mapBookingAssemblyAvailability } from "../unassigned/utils";
import { getTimeZone, normalizeStr } from "../../../shared/utils";
import Button from "../../../shared/controls/button";
import Modal from "react-modal";
import { setConfig } from "../../../redux/reducers/configuration-reducer";
import FillIcon from "../../../shared/icons/alerts/fill";
import { cancelMultipleRequests } from '../../../redux/api/actions';
import SelectInput from "../../../shared/controls/select-input";
import { cloneDeep } from "lodash";
import AppointmentSummaryModal from "./AppointmentSummaryModal";
import ConflictDetailModal from "./conflict-detail-modal";
import styled from "styled-components";
import { getNotAllowedWindows } from '../utils';
import { convertDateToInt } from '../utils';

type StateToProps = {|
  loading: boolean,
  companyId: number,
  facility: Types.DetailFacility,
  spaces: Types.Space[],
  transportClasses: Array<{ code: string, id: number }>,
  trips: Types.Trip[],
|};

type DispatchToProps = {|
  loadTrip: (
    params: {
      company_id: number,
      trip_id: number,
    },
    opts: ApiOptions
  ) => void,
  loadOrders: (
    params: {
      company_id: number,
      facility_id: number,
      date: string,
    },
    opts: ApiOptions
  ) => void,
  loadTransportClasses: (companyId: number, opts: ApiOptions) => void,
  // setStatus: (tripId: number, newStatus: number) => void,
  setAppointmentDate: (
    params: {
      company_id: number,
      id: number,
      old_date: string,
      new_date: string,
      old_space: number,
      new_space: number,
      submotive: number,
      comment: string,
      view: "load" | "unload",
      action: "single_replace" | "hard_replace",
      replaced_order: number,
    },
    opts: ApiOptions
  ) => void,
  contractAvailability: (
    params: {
      company_id: number,
      facility_id: number,
    },
    options: ApiOptions
  ) => void,
  providerAvailability: (
    params: {
      company_id: number,
      view: string,
    },
    options: ApiOptions
  ) => void,
|};

type OwnProps = {|
  facilityId: number,
  maneuver: string,
  history: RouterHistory,
|};

type Props = {
  ...OwnProps,
  ...StateToProps,
  ...DispatchToProps,
};

type State = {
  currentDate: Date,
  selectedDateId: string,
  selectedDate: Date,
  showModal: boolean,
  currentOrder: Types.Trip | any,
  showEditAppointmentModal: boolean,
  editAppointmentModalData: Types.EditAppointmentModalData,
  selectedProvider: number,
  selectedAssembly: number,
};

const scrollRef = createRef()

class AssignedShipmentBoard extends React.Component<Props, State> {
  constructor(props){
    super(props);

    this.state = {
      currentDate: new Date(),
      selectedDateId: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate()
      )
        .toISOString()
        .split("T")[0],
      selectedDate: new Date(),
      showModal: false,
      currentOrder: {},
      showEditAppointmentModal: false,
      editAppointmentModalData: {},
      selectedProvider: 0,
      selectedAssembly: 0,
      selectedNewDate: null,
      openXlsErrorModal: false,
      facilityTimes: {},
      descriptionImport: [],
      prevSpaces: [],
      begin: 1,
      end: 2300,
      hourSelected: null,
      hourSelectedFormat: null,
      summaryHourSelected: null,
      summaryHourSelectedFormat: null,
      showConflictModal: false,
      currentConflict: null,
      maxElementsToShow: 50,

      // selectedDateId: "2022-07-15",
      // selectedDate: new Date("2022-07-15")
    };
  }

  componentDidMount() {
    this.props.loadTransportClasses(this.props.companyId);
    
    if(this.props.urlFacilities && this.props.companyFacilities) {
      this.getFacilityTime()
    }
    if(this.props.url_date){
      let d = new Date(this.props.url_date)
      this.setState({
        ...this.props.configuration,
        selectedDate: d,
        currentDate: d,
        selectedDateId: d.toISOString().split("T")[0],
        summaryHourSelected: null,
        summaryHourSelectedFormat: null,
      },()=>{this.loadFacilityOrders()});
    }else{
      this.setState({ 
        ...this.props.configuration,
        summaryHourSelected: null,
        summaryHourSelectedFormat: null,
      }, () =>
        this.loadFacilityOrders()
      );
    }

    window.onbeforeunload = function() {
      this.props.cancelMultipleRequests();
      return true;
    }.bind(this);

  }

  componentWillUnmount() {
    this.props.setBaseConfig({ ...this.state, facilityTimes: {}, showModal: false });
    this.props.cancelMultipleRequests();
    window.onbeforeunload = null;
  }

  formatTimeZone = (tZ) => {
    if (tZ.length) {
      if (tZ.includes("UTC")) {
        tZ = tZ.slice(tZ.indexOf("/") + 1);
        tZ = tZ.trim();
      }
      return tZ;
    }
    return "America/Mexico_City";
  };

  getFacilityTime = () => {
    let facilityTimes = {};
    if(this.props.urlFacilities && this.props.urlFacilities.length){
      this.props.urlFacilities.forEach(uF => {
        let tz = ((this.props.companyFacilities || []).find(cF => cF.id.toString() === uF.toString()) || {}).time_zone_description || "";
        if(tz.length){
        let dateString = new Date().toLocaleString("en-US", {
          timeZone: this.formatTimeZone(tz),
          hour: "numeric",
          minute: "numeric",
        });
        // this.setState({ ["facility-time-".concat(uF)]: dateString });
        facilityTimes[uF] = dateString;}
      })
      this.setState({facilityTimes});
      setTimeout(() => this.getFacilityTime(), 60000);
    }
    return "";
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.maneuver !== this.props.maneuver ||
      prevProps.filter !== this.props.filter
    ) {
      this.loadFacilityOrders();
    }
    if(prevProps.urlFacilities !== this.props.urlFacilities) {
      this.loadFacilityOrders();
      if(this.props.urlFacilities && this.props.urlFacilities.length){
        this.getFacilityTime();
      }
    }
  }

  handleScroll = (e) => {
    const scrollTop = e.target.scrollHeight - e.target.scrollTop;
    const validation = ((e.target.clientHeight + 2) > scrollTop) && ((e.target.clientHeight - 2) < scrollTop) &&  `${e.target.className}`.includes('TrafficSpacesGrid');

    if (validation && (this.props.spaces || []).length > this.state.maxElementsToShow) {
      scrollRef.current.scrollTo(0, e.target.scrollTop - 150);
      this.setState({maxElementsToShow: this.state.maxElementsToShow + 30});
    }
  }

  loadFacilityOrders = () => { 
    // this.props.cancelRequest("spacesResponse");
    this.props.cancelMultipleRequests();

    if(this.props.urlFacilities){
      this.props.loadOrders({
        company_id: this.props.companyId,
        facility_id: this.props.urlFacilities[0],
        view: this.props.maneuver,
        date: this.state.selectedDateId,
        status: (this.props.filter.selectedstatus || []).join(","),
        fleet_providers: (this.props.filter.selectedProviders || []).join(","),
        transport_classes: (this.props.filter.transportClass || []).join(","),
        trip_tags: (this.props.filter.selectedTrip || []).join(","),
        order_tags: (this.props.filter.selectedOrder || []).join(","),
        facilities: (this.props.filter.selectedFacilities || []).join(","),
        priority: (this.props.filter.selectedLevel || []).join(","),
        category: '',
        dashboard: this.props.dashboard,
        timezone: getTimeZone(),
        isMultipleCancels: true
      },
      {
        strategy: "REPLACE",
        onSuccess: () => {
          this.props.urlFacilities.forEach((uF, i) => {
            if(i!==0) {
              this.props.loadOrders({
                company_id: this.props.companyId,
                facility_id: uF,
                view: this.props.maneuver,
                date: this.state.selectedDateId,
                status: (this.props.filter.selectedstatus || []).join(","),
                fleet_providers: (this.props.filter.selectedProviders || []).join(","),
                transport_classes: (this.props.filter.transportClass || []).join(","),
                trip_tags: (this.props.filter.selectedTrip || []).join(","),
                order_tags: (this.props.filter.selectedOrder || []).join(","),
                facilities: (this.props.filter.selectedFacilities || []).join(","),
                priority: (this.props.filter.selectedLevel || []).join(","),
                category: '',
                dashboard: this.props.dashboard,
                timezone: getTimeZone(),
                isMultipleCancels: true
              },
              {
                strategy: "ADD"
              });
            }
          }) 
        }
      });
    } else{
      this.props.loadOrders({
        company_id: this.props.companyId,
        facility_id: this.props.facilityId,
        view: this.props.maneuver,
        date: this.state.selectedDateId,
        status: (this.props.filter.selectedstatus || []).join(","),
        fleet_providers: (this.props.filter.selectedProviders || []).join(","),
        transport_classes: (this.props.filter.transportClass || []).join(","),
        trip_tags: (this.props.filter.selectedTrip || []).join(","),
        order_tags: (this.props.filter.selectedOrder || []).join(","),
        facilities: (this.props.filter.selectedFacilities || []).join(","),
        priority: (this.props.filter.selectedLevel || []).join(","),
        category: '',
        dashboard: this.props.dashboard,
        timezone: getTimeZone(),
        isMultipleCancels: true
      },
      {
        strategy: "REPLACE"
      });
    }
  };

  contractAvailability = () => {
    this.props.contractAvailability({
      company_id: this.props.companyId,
      facility_id: this.props.facilityId,
    });
  };
  providerAvailability = () => {
    this.props.providerAvailability({
      company_id: this.props.companyId,
      view: "booking",
    });
  };

  handleChange = (e: SharedTypes.SimpleEvent) => {
    const { name, value } = e.target;
    switch (name) {
      case "selectedAssembly":
        this.setState({
          selectedProvider: value.provider,
          selectedAssembly: value.id,
        });
        break;
      case "selectedContract":
      case "selectedProvider":
        this.setState({
          selectedProvider: value,
          selectedAssembly: 0,
        });
        break;
      case "calendar":
        this.setState(
          {
            selectedDate: value,
            selectedDateId: value.toISOString().split("T")[0],
            currentDate: value,
          },
          () => this.loadFacilityOrders(this.props.facility)
        );
        break;
      case "begin":
      case "end":
        this.setState({hourSelected: null, [name]: value})
        break;
      default:
        this.setState({ [name]: value });
    }
  };
  
  validShow = (perm) => {
    let response = this.props.validateObjPerms([perm], {
      display: true,
    });
    if (Object.keys(response).length) {
      return true;
    }
    return false;
  };

  validatePerm = (perm, type) => {
    return validateObjPerms(
      [ 
        { id: String(perm), perm: String(type)}
      ],
      true
    ) === true;
  };

  get_acctions = (e: currentOrder.extraActions) => {
    // if (this.validShow({id: "61", perm: "u",}) === true && this.validShow({id: "61", perm: "d",}) === true)
    //   return e

    // if (this.validShow({id: "61", perm: "u",}) === true)
    //   return e && e.filter((extra) => extra.caption !== "Cancelar viaje")

    // if (this.validShow({id: "61", perm: "d",}) === true)
    //   return  e && e.filter(extra => extra.caption === 'Cancelar viaje')

    return e;
 
  };

  
  manageError = (err) => {
    this.setState({openXlsErrorModal: true, descriptionImport: err, prevSpaces: []})
  }
  closeXlsErrorModal = () => {
    this.setState({
      openXlsErrorModal: false,
      descriptionImport: []
    });
  };

  dragIsOver = (e) => {
    // debugger;
    // console.log(e);
    e.stopPropagation();
    this.setState({prevSpaces: this.state.prevSpaces.concat(this.props.spaces)});
    this.handleChangeDay(e)
  }

  getScrollWidth = () => {
    let begin = Number(this.state.begin) === 1 ? 0 : Number(this.state.begin);
    let diff = Number(this.state.end) - begin;
    if(diff <= 300) return '100%';
    if(diff === 400) return '1250px';
    if(diff === 500) return '1500px';
    if(diff === 600) return '1750px';
    return '2000px';
  }

  onHourClick = (e) => {
    const value = e.target?.value || '';
    const parsedValue = value ? Number(value.replace(':', '')) : null;
    this.setState({
      hourSelected: parsedValue ===  this.state.hourSelected ? null : parsedValue, 
      hourSelectedFormat: parsedValue ===  this.state.hourSelected ? null : value
    }, () => {
      scrollRef.current.scrollTo(0, 0);
    })
  }

  onSummaryClick = (e) => {
    const value = e.target?.value || '';
    const parsedValue = value ? Number(value.replace(':', '')) : null;
    this.setState({
      summaryHourSelected: parsedValue ===  this.state.summaryHourSelected ? null : parsedValue, 
      summaryHourSelectedFormat: parsedValue ===  this.state.summaryHourSelected ? null : value
    })
  }

  filterSpaces = (spaces) => {
    if(this.state.hourSelected === null || !spaces.length) return this.getMaxSpaces(spaces);


    let testSpaces = cloneDeep(spaces);
    let spacesStartWithin = []; //ventanas que inician durante la hora seleccionada
    let spacesCovering = []; //ventanas que inician antes y terminan despues
    let spacesEndWithin = []; //ventanas que inician antes y terminan durante

    // let spacesAppointments = [];
    // let emptySpaces = [];
    let spacesOutside = [];

    while(testSpaces.length){
      let space = testSpaces.shift();
      let condition = (space.windows || [])
        .some((w, i) => {
          let cond = (this.state.hourSelected + 59) >= w.begin && this.state.hourSelected < w.end;
          if(cond) space.filteredWindowIndex = i;
          return cond;
        });
      if(condition){
        // spacesWithin.push(space);
        // let condition1 = (space.conflicts || []).some(c => (this.state.hourSelected + 59) >= c.begin && this.state.hourSelected < c.end);
        // let condition2 = (space.appointments || []).some(c => (this.state.hourSelected + 59) >= c.begin && this.state.hourSelected < c.end);
        // if(condition1 || condition2) spacesAppointments.push(space);
        // else emptySpaces.push(space);

        if(
          (space.windows || [])
          .some((w, i)=> {
            let cond = w.begin >= this.state.hourSelected && w.begin <= (this.state.hourSelected + 59);
            if(cond) space.filteredWindowIndex = i;
            return cond;
          })
        ){
          spacesStartWithin.push(space);
        } else if(
          (space.windows || [])
          .some((w, i) => {
            let cond = w.begin < this.state.hourSelected && w.end > (this.state.hourSelected + 100);
            if(cond) space.filteredWindowIndex = i;
            return cond;
          })
        ){
          spacesCovering.push(space);
        } else {
          spacesEndWithin.push(space);
        }
      } 
      else spacesOutside.push(space);
    }

    spacesStartWithin.forEach((s, i, a) => {
      let appointmentsWithin = (s.appointments || []).filter(app => (this.state.hourSelected + 59) >= app.begin && this.state.hourSelected < app.end);
      appointmentsWithin = appointmentsWithin.concat((s.conflicts || []).filter(conf => (this.state.hourSelected + 59) >= conf.begin && this.state.hourSelected < conf.end))
      appointmentsWithin.sort((aA, aB) => aA.begin - aB.begin);
      a[i].appointmentsWithin = appointmentsWithin;
    })

    spacesStartWithin.sort((a, b) => {
      if(a.windows[a.filteredWindowIndex].begin < b.windows[b.filteredWindowIndex].begin){
        return -1
      } else if (a.windows[a.filteredWindowIndex].begin > b.windows[b.filteredWindowIndex].begin){
        return 1
      }
      
      if(a.appointmentsWithin.length && !b.appointmentsWithin.length){
        return -1
      } else if(!a.appointmentsWithin.length && b.appointmentsWithin.length){
        return 1
      } else if(!a.appointmentsWithin.length && !b.appointmentsWithin.length){
        return 0
      }

      return a.appointmentsWithin[0].begin - b.appointmentsWithin[0].begin;
    });
    spacesCovering.sort((a, b) => {
      return a.windows[a.filteredWindowIndex].begin - b.windows[b.filteredWindowIndex].begin
    });
    spacesEndWithin.sort((a, b) => {
      return a.windows[a.filteredWindowIndex].end - b.windows[b.filteredWindowIndex].end
    });
    let temp_spaces = spacesStartWithin.concat(spacesCovering).concat(spacesEndWithin).concat(spacesOutside);
    return this.getMaxSpaces(temp_spaces);
  }

  closeSummaryModal = () => {
    this.setState({
      summaryHourSelected: null,
      summaryHourSelectedFormat: null
    })
  }

  redirectSummaryModal = () => {
    this.closeSummaryModal();
    this.props.changeView({
      target: {
        value: 3,
        name: "currentViewOption"
      }
    })
  }

  formatTime = value => {
    const hour = value / 100
    const stringValue = value.toString();

    if (hour < 1) {
      if(value < 10){
        return `00:0${stringValue.charAt(0)}`
      }
      return `00:${stringValue.charAt(0)}${stringValue.charAt(1) ||
        '0'}`
    }
    
    if (hour < 10) {
      return `0${stringValue.charAt(0)}:${stringValue.charAt(1) ||
        '0'}${stringValue.charAt(2) || '0'}`
    }
  
    return `${stringValue.charAt(0)}${stringValue.charAt(1)}:${stringValue.charAt(
      2
    )}${stringValue.charAt(3)}`
  }

  getGeneralSummaryData = () => {

    if (this.props.loading) {
      return({
        scheduled: 0,
        free: 0,
      });
    }

    let testSpaces = cloneDeep(this.props.spaces);

    let beginHour = parseInt(this.state.begin);
    let endHour = parseInt(this.state.end);

    if (beginHour === 1) {
      beginHour = 0;
    }

    let spacesWithin = testSpaces.filter(space => {
        return (space.windows || [])
        .some((w, i) => {
          return (beginHour <= w.begin) && endHour >= w.begin
        })
      })
    let appointments = 0;
    let freeSpaces = 0;
    let conflicts = 0;
    let appointmentsList = [];

    spacesWithin.forEach(s => {
      let appointmentsWithin = (s.appointments || []).filter(app => endHour >= app.begin && beginHour <= app.begin);
      let conflictsWithin = (s.conflicts || []).filter(conf => endHour >= conf.begin && beginHour<= conf.begin);
      appointments += appointmentsWithin.length;
      conflicts += conflictsWithin.length;
      appointmentsList = appointmentsList.concat(appointmentsWithin).concat(conflictsWithin);

      if(!appointmentsWithin.length && !conflictsWithin.length) {
        freeSpaces++;
      }
    })

    return {
      scheduled: appointments,
      free: freeSpaces,
    }
  }

  getSummaryModalData = () => {
    if(this.state.summaryHourSelected === null) return {};


    let testSpaces = cloneDeep(this.props.spaces);

    let spacesWithin = testSpaces.filter(space => {
        return (space.windows || [])
        .some((w, i) => {
          // return (this.state.summaryHourSelected + 59) >= w.begin && this.state.summaryHourSelected < w.end;
          return (this.state.summaryHourSelected <= w.begin) && (this.state.summaryHourSelected + 59) >= w.begin
        })
      })
    let appointments = 0;
    let freeSpaces = 0;
    let conflicts = 0;
    let appointmentsList = [];
    let windowsPerHour = {};

    spacesWithin.forEach(s => {
      // let appointmentsWithin = (s.appointments || []).filter(app => (this.state.summaryHourSelected + 59) >= app.begin && this.state.summaryHourSelected < app.end);
      // let conflictsWithin = (s.conflicts || []).filter(conf => (this.state.summaryHourSelected + 59) >= conf.begin && this.state.summaryHourSelected < conf.end);
      
      let appointmentsWithin = (s.appointments || []).filter(app => (this.state.summaryHourSelected + 59) >= app.begin && this.state.summaryHourSelected <= app.begin);
      let conflictsWithin = (s.conflicts || []).filter(conf => (this.state.summaryHourSelected + 59) >= conf.begin && this.state.summaryHourSelected <= conf.begin);
      appointments += appointmentsWithin.length;
      conflicts += conflictsWithin.length;
      appointmentsList = appointmentsList.concat(appointmentsWithin).concat(conflictsWithin);

      if(!appointmentsWithin.length && !conflictsWithin.length) {
        freeSpaces++;

        (s.windows || []).forEach((w) => {
          // if((this.state.summaryHourSelected + 59) >= w.begin && this.state.summaryHourSelected < w.end) {
          if((this.state.summaryHourSelected + 59) >= w.begin && this.state.summaryHourSelected <= w.begin) {
            if(windowsPerHour[w.begin]){
              windowsPerHour[w.begin]++
            } else {
              windowsPerHour[w.begin] = 1;
            }
          }
        })
      }
    })

    let appointmentsPerHour = {};
    appointmentsList.forEach(app => {
      if(appointmentsPerHour[app.begin]){
        appointmentsPerHour[app.begin]++
      } else {
        appointmentsPerHour[app.begin] = 1;
      }
    });
    return {
      scheduled: appointments,
      free: freeSpaces,
      conflicts: conflicts,
      appointmentsList,
      appointmentsPerHour: Object.entries(appointmentsPerHour).map(e => ({count: e[1], time: this.formatTime(e[0])})),
      windowsPerHour: Object.entries(windowsPerHour).map(e => ({count: e[1], time: this.formatTime(e[0])})),
    }
  }

  getSpaces = () => {
    return (this.props.spaces || []).map((item, index) => {
      item.space_number = index + 1;
      return item
    });
  }

  getMaxSpaces = (spaces_to_show) => {
    return (spaces_to_show || []).slice(0, this.state.maxElementsToShow);
  }

  render() {
    const {
      selectedDate,
      currentDate,
      selectedDateId,
      currentOrder,
    } = this.state;
    // const begin = 0;
    // const end = 2300;

    let summaryData = this.getGeneralSummaryData();
    let spaces_to_show = this.getSpaces();

    return (
      <React.Fragment>
        <Flex
          justifyContent={flexOpts.justifyContent.center}
          alignItems={flexOpts.alignItems.center}
          style={{ backgroundColor: "#212A3C" }}
        >
          <div style={{ width: "auto", minWidth: "20%" }} />
          <CalendarSelectorRibbon
            // rf={this.props.connectDropTarget}
            isCalendar={true}
            bgColor="#212A3C"
            selectedUnderlineColor="#E9643F"
            // className="profile-calendar-ribbon"
            onChange={this.handleChangeDay}
            items={getRibbonWeeklyDates(currentDate, "WEEKDAY")}
            onDragOver={this.dragIsOver}
            activeId={selectedDateId}
            isCalendar={true}
          />
          <WeekPicker
            locale="es"
            name="calendar"
            date={selectedDate}
            onChange={this.handleChange}
          />
          <div
            style={{ marginRight: "20px", cursor: "pointer" }}
            onClick={() => {
              window.location.href = `/company/facility/profile/${this.props.facilityId}?schedules=true`;
            }}
          >
            <img width="30px" src="/images/menu/gear.svg" alt="" />
          </div>
        </Flex>

        <Flex
          style={{ backgroundColor: "#212A3C", alignItems: "center", color: "#fff" }}
        >
          <SelectInput
            label='Inicio:'
            name="begin"
            items={HOURS.filter(h => h.id <= parseInt(this.state.end))}
            value={parseInt(this.state.begin)}
            onChange={this.handleChange}
          />
          <SelectInput 
            label='Fin:'
            name="end"
            items={HOURS.filter(h => h.id >= parseInt(this.state.begin)).map((h, i, a) => {
              if(i < a.length - 1){
                return {
                  id: h.id,
                  description: a[i + 1].description
                }
              } else {
                return {
                  id: h.id,
                  description: "12:00 AM"
                }
              }
            })}
            value={parseInt(this.state.end)}
            onChange={this.handleChange}
          />
          {/* <div style={{marginLeft: "20px"}}>
            <span>33 camiones</span>
          </div> */}
          
          <div style={{width: 20}}/>

          <SpaceDescription>
            <div>Espacios libres</div>
            <div>{summaryData.free}</div>
          </SpaceDescription>

          <div style={{width: 20}}/>

          <SpaceDescription>
            <div>Espacios Ocupados</div>
            <div>{summaryData.scheduled}</div>
          </SpaceDescription>
        </Flex>

        {this.props.loading || this.props.spaces.length === 0 ? (
          <div
            style={{
              backgroundColor: "#212A3C",
              height: "300px",
              textAlign: "center",
              opacity: "0.57",
              color: "#FFFFFF",
              fontSize: "24px",
              fontWeight: "bold",
              letterSpacing: "0",
              lineHeight: "37px",
              paddingTop: "30px",
            }}
          >
            <span>
              {this.props.loading
                ? "Cargando ..."
                : "No hay viajes en este momento"}
            </span>
            <br />
            <img alt="" src="/images/empty-travels.png"></img>
          </div>
        ) : (
          <TrafficSpacesGrid2
            isLoading={this.props.loading}
            hideSortButtons={this.props.loading}
            verticalScrollRef={scrollRef}
            onScroll={this.handleScroll}
            scrollWidth={this.getScrollWidth()}
            begin={Number(this.state.begin) === 1 ? 0 : Number(this.state.begin)}
            end={Number(this.state.end)}
            selectedTime={this.state.hourSelectedFormat}
            selectedTimeNumberFormat={this.state.hourSelected}
            dateValidation={
              Date.parse(this.state.selectedDate.toDateString()) -
              Date.parse(new Date().toDateString())
            }
            onHourClick={this.onHourClick}
            onSummaryClick={this.onSummaryClick}
            urlFacilities={this.props.urlFacilities}
            companyFacilities={this.props.urlFacilities && this.props.urlFacilities.map(uF => {
              let selectedFacility = (this.props.companyFacilities || []).find(f => f.id.toString() === uF.toString()) || {};
              return {
                id: selectedFacility.id,
                name: (selectedFacility.alias || "").concat(' - ', selectedFacility.code, ' - ', selectedFacility.name),
                time: `   Hora local: ${this.state.facilityTimes["".concat(selectedFacility.id)] || '-'}`
              }
            })}
            spaces={this.filterSpaces(spaces_to_show).map((space, index) => ({ 
              ...space,
              windows: space.windows.filter(w => w.end > Number(this.state.begin) && w.begin < (Number(this.state.end) + 100)).map((wnd) => ({
                ...wnd,
                begin: wnd.begin < this.state.begin ? Number(this.state.begin) === 1 ? 0 : Number(this.state.begin) : wnd.begin,
                end: wnd.end > (Number(this.state.end) + 100) ? Number(this.state.end) + 100 : wnd.end,
                realBegin: (wnd.first_day !== undefined) ? (wnd.first_day ? wnd.begin : (wnd.begin - 1)) : wnd.begin,
                realEnd: (wnd.first_day !== undefined) ? (wnd.first_day ? (wnd.end + 1) : wnd.end) : wnd.end,
                dropOnWindow: (droppedOn, item) =>
                  this.manageEditAppointmentModal({
                    target: {
                      name: this.dropOnWindow_validation(),
                      value: { droppedOn, item, space: index + 1 },
                    },
                  }),
              })),
              appointments: space.appointments.filter(w => w.end > Number(this.state.begin) && w.begin < Number(this.state.end) + 100).map((app) => ({
                ...app,
                begin: app.begin < this.state.begin ? Number(this.state.begin) === 1 ? 0 : Number(this.state.begin) : app.begin,
                end: app.end > (Number(this.state.end) + 100) ? (Number(this.state.end) + 100) : app.end,
                onDrop: (droppedOn, item) =>
                  this.manageEditAppointmentModal({
                    target: {
                      name: this.onDrop_validation(),
                      value: { droppedOn, item, space: index + 1 },
                    },
                  }),
                onClick: () => {
                  this.manageDetailModal({
                    target: { name: "open", value: app },
                  })
                },
                searched: (
                  Object.values(this.props.filter).some(f => f.length)
                    ? (
                        this.props.search !== "" 
                        ? (!app.shadow && normalizeStr(app.descriptionText).includes(normalizeStr(this.props.search)))
                        : !app.shadow
                      )
                    : (normalizeStr(app.descriptionText).includes(
                      normalizeStr(this.props.search)))
                      
                ),
                searchTerm: this.props.search ? true : false
              })),
              conflicts: space.conflicts.filter(w => w.end > this.state.begin && w.begin < Number(this.state.end) + 100).map((app) => ({
                ...app,
                // childClick: this.childClick,
                onClick: () => {

                  let newApp = cloneDeep(app);
                  newApp.orders = newApp.raw_orders;

                  this.manageConflictModal({target: { name: "open", value: newApp}})
                },
                childClick: () => {},
                onDrop: (droppedOn, item) =>{
                  // debugger;
                  this.manageEditAppointmentModal({
                    target: {
                      name: this.onDrop_validation(),
                      value: { droppedOn, item, space: index + 1 },
                    },
                  })},
                orders: app.orders.map((order) => ({
                  ...order,
                  searched: (
                    Object.values(this.props.filter).some(f => f.length)
                      ? (
                          this.props.search !== "" 
                          ? (!order.shadow && normalizeStr(order.codeText).includes(normalizeStr(this.props.search)))
                          : !order.shadow
                        )
                      : (normalizeStr(order.codeText).includes(
                        normalizeStr(this.props.search)))
                  ),
                  searchTerm: this.props.search ? true : false
                })),
              })),
            }))}
          />
        )}
        {this.state.showModal ? (
          <ShipmentDetailDialog  
            show={this.state.showModal}
            style={{zIndex: "9999"}}
            {...currentOrder}
            className="trip-detail-modal"
            alertIcon={
              <FillIcon width="30px" height="30px" viewBox="10 -8 48 45" fill={"#FF0000"}/>
            }
            order={{id: this.state.card.id, op: this.state.card.op, description: this.state.card.description}}
            altTrips={this.state.card.altTrips}
            onTripChange={(e) => {
              this.manageDetailModal({target: {
                name: "changeTrip",
                value: e,
              }})
            }}
            tickets={currentOrder.tickets.map((t) => ({
              ...t,
              detailButton: (
                <Marker
                  fill="#3E4E6C"
                  style={{ marginTop: "5px" }}
                  onClick={() =>
                    this.props.history.push(
                      `/company/controls/shipment/orderdetail/${currentOrder.id}?facilityId=${this.props.facilityId}`
                    )
                  }
                />
              ),
            }))}
            actions={this.get_acctions(currentOrder.extraActions)?.map((extra) => (
                {
                ...extra,
                onAction: (a) => {
                  this.manageDetailModal({
                    target: {
                      name: extra.caption,
                      value: a,
                    },
                  });
                },
              }))
            }
            onBack={this.state.currentConflict !== null ? () => { this.setState({ showConflictModal: true}) } : null}
            onClose={() => {
              this.manageDetailModal({
                target: {
                  name: "close",
                  value: undefined,
                },
              });
            }}
            primary={
              currentOrder.buttons.primary
                ? {
                    caption: currentOrder.buttons.primary.caption,
                    onClick: (e) => {
                      this.manageDetailModal({
                        target: {
                          name: currentOrder.buttons.primary.caption,
                          value: currentOrder,
                        },
                      });
                    },
                    type: "primary",
                  }
                : null
            }
            secondary={
              currentOrder.buttons.secondary && {
                caption: currentOrder.buttons?.secondary?.caption,
                onClick: (e) => {
                  this.manageDetailModal({
                    target: {
                      name: currentOrder.buttons.secondary.caption,
                      value: currentOrder,
                    },
                  });
                },
                type: "outline",
              }
            }
          />
        ) : null}
        {this.state.showEditAppointmentModal && (
          <EditAppointmentModal
            show={this.state.showEditAppointmentModal}
            style={{zIndex: "9999"}}
            onChange={this.manageEditAppointmentModal}
            data={this.state.editAppointmentModalData}
            reasons={[
              { label: "Selecciona una opción", value: 0, type: 1 },
            ].concat(this.props.orderMotives.filter((m) => m.type === 1))}
            appointmentAction={this.state.appointmentAction}
            reasonSelect={this.state.reasonSelect}
            onMinutes={this.handleChange}
            selectedNewDate={this.state.selectedNewDate}
          />
        )}
        {this.state.showCancelBookingModal && (
          <CancelBookingModal
            show={this.state.showCancelBookingModal}
            style={{zIndex: "9999"}}
            reasons={[
              { label: "Selecciona una opción", value: 0, type: 2 },
            ].concat(this.props.orderMotives.filter((m) => m.type === 2))}
            data={{
              logo: this.state.currentOrder.courier.logo,
              name: this.state.currentOrder.courier.name,
              trip: this.state.currentOrder.number,
            }}
            reasonSelect={this.state.reasonSelect}
            onChange={this.manageDetailModal}
          />
        )}
        {this.state.showFinalizeTripModal && (
          <FinalizeTripModal
            show={this.state.showFinalizeTripModal}
            style={{zIndex: "9999"}}
            data={{
              logo: this.state.currentOrder.courier.logo,
              name: this.state.currentOrder.courier.name,
              trip: this.state.currentOrder.number,
            }}
            onChange={this.manageDetailModal}
          />
        )}
        {this.state.showFinalizeOrderModal && (
          <FinalizeOrderModal
            show={this.state.showFinalizeOrderModal}
            style={{zIndex: "9999"}}
            data={{
              logo: this.state.currentOrder.courier.logo,
              name: this.state.currentOrder.courier.name,
              trip: this.state.currentOrder.number,
              card: this.state.currentOrder.tickets.find(
                (t) => t.id === this.state.card.id
              ).number,
              maneuver: this.state.currentOrder.tickets.find(
                (t) => t.id === this.state.card.id
              ).maneuver,
            }}
            onChange={this.manageDetailModal}
          />
        )}
        {this.state.showCancelAppointmentModal && (
          <CancelAppointmentModal
            show={this.state.showCancelAppointmentModal}
            style={{zIndex: "9999"}}
            reasons={[
              { label: "Selecciona una opción", value: 0, type: 2 },
            ].concat(this.props.orderMotives.filter((m) => m.type === 2))}
            reasonSelect={this.state.reasonSelect}
            data={{
              logo: this.state.currentOrder.courier.logo,
              // trip: this.state.currentOrder.number,
              trip: this.state.card.description,
              // maneuver: this.state.currentOrder.tickets.find(
              //   (t) => t.id === this.state.card.id
              // ).maneuver,
              maneuver: this.state.card.op === "load" ? "Carga" : "Descarga"
            }}
            onChange={this.manageDetailModal}
            date={
              new Date(
                this.state.currentOrder.tickets.find(
                  (t) => t.id === this.state.card.id
                ).maneuverDate
              )
            }
            place={`Rampa ${this.state.card.spaceIndex + 1}`}
          />
        )}
        {this.state.showCancelTripModal && (
          <CancelTripModal
            show={this.state.showCancelTripModal}
            style={{zIndex: "9999"}}
            reasons={[
              { label: "Selecciona una opción", value: 0, type: 2 },
            ].concat(this.props.orderMotives.filter((m) => m.type === 2))}
            data={{
              logo: this.state.currentOrder.courier.logo,
              trip: this.state.currentOrder.number,
            }}
            reasonSelect={this.state.reasonSelect}
            onChange={this.manageDetailModal}
          />
        )}

        {this.state.showBookingModal && (
          <ShipmentReserveDialog
            open={this.state.showBookingModal}
            style={{zIndex: "9999"}}
            trip={{
              id: this.state.currentOrder.id,
              companyLogo: FILES_SERVER.concat(this.props.companyData.logo),
              companyName: "",
              tripNumber: this.state.currentOrder.number,
              tickets: this.state.currentOrder.tickets,
              class: this.state.currentOrder.expeditionClass,
              tags: this.state.currentOrder.tripTag,
              status: this.state.currentOrder.status,
              actions: [], // { type: string, description: string, action: () => void }[]
              details: this.state.currentOrder.tickets.map((t) => ({
                ticket: t.number,
                origin: {
                  name: t.origin.location,
                  date: t.origin.date,
                },
                destination: {
                  name: t.destination.location,
                  date: t.destination.date,
                },
              })),
            }}
            // assemblies={this.props.bookingAssemblyAvailability}
            // fleetProviders={this.props.bookingFleetProviderAvailability}
            // fleetProviders={this.props.bookingFleetProviderAvailability.map(
            //   (a) => ({
            //     ...a,
            //     selectedProvider: this.state.selectedProvider,
            //     onChange: this.handleChange,
            //   })
            // )}
            assemblies={this.props.bookingAssemblyAvailability.map((a) => ({
              ...a,
              selectedAssembly: this.state.selectedAssembly,
              onChange: this.handleChange,
            }))}
            getContracts={this.contractAvailability}
            contracts={this.props.contracts.map((c) => ({
              ...c,
              id: c.fleet_provider.fleet_provider_id,
              selectedProvider: this.state.selectedProvider,
              selected:
                this.state.selectedProvider.toString() === c.id.toString(),
              onChange: this.handleChange,
            }))}
            getProviders={this.providerAvailability}
            providers={this.props.providers.map((p) => ({
              ...p,
              id: p.fleet_provider.fleet_provider_id || "",
              selectedProvider: this.state.selectedProvider,
              selected:
                this.state.selectedProvider.toString() === p.id.toString(),
              onChange: this.handleChange,
            }))}
            enabledAction={
              this.state.selectedProvider + this.state.selectedAssembly > 0
            }
            onChange={this.manageDetailModal}
          />
        )}

        {
          this.state.summaryHourSelected !== null && (
            <AppointmentSummaryModal 
              show={this.state.summaryHourSelected !== null}
              hourName={this.state.summaryHourSelectedFormat}
              onClose={this.closeSummaryModal}
              onAccept={this.redirectSummaryModal}
              data={this.getSummaryModalData()}
            />
          )
        }

        {
          this.state.showConflictModal && (
            <ConflictDetailModal 
              show={this.state.showConflictModal}
              closeModal={() => {this.manageConflictModal({target: {name: "close"}})}}
              data={this.state.currentConflict}
              date={this.state.selectedDateId}
              showDetail={(o) => {
                
                this.setState({ showConflictModal: false})

                if ((o.altTrips ?? []).length > 0) {

                  let altTripsTemp = [];
                  let o_temp = cloneDeep(o);
                  (o.altTrips ?? []).forEach( item => {
                    if (altTripsTemp.filter( trip_item => trip_item.tripId === item.tripId).length === 0) {
                      altTripsTemp.push(item);
                    }
                  });

                  o_temp.altTrips = altTripsTemp;

                  this.childClick(o_temp)

                } else {
                  this.childClick(o)
                }

              }}
            />
          )
        }
        
        <Modal
          isOpen={this.state.openXlsErrorModal}
          portalClassName="dialog confirm-dialog"
          overlayClassName="overlay"
          className="content invite-operator successModal"
        >
          <div className="title">Error</div>
          <div className="close" onClick={this.closeXlsErrorModal}></div>
          <div className="message">{this.state.descriptionImport.map(e => <div>{e}</div>)}</div>
          <div className="actions successActions">
            <Button
              settings={{
                text: "Aceptar",
                onClick: this.closeXlsErrorModal,
              }}
            />
          </div>
        </Modal>
      </React.Fragment>
    );
  }

  childClick = (order) => {
    // this.manageConflictModal({
    //   target: {
    //     name: "open",
    //     value: order,
    //   },
    // })
    this.manageDetailModal({
      target: {
        name: "open",
        value: order,
      },
    });
  };

  onDrop_validation = () =>{
    // if (this.validShow({id: "61", perm: "u",}) === true)
      return "openOverCard"
    // else
    //   return "forbidden"
  };

  
  dropOnWindow_validation = () =>{
    // if (this.validShow({id: "61", perm: "u",}) === true)
      return 'openOnFree'
    // else
    //   return "forbidden"
  };
  
  cancelEditAppointment = () => {
    this.setState({
      showEditAppointmentModal: false,
      selectedNewDate: null,
      overCard: false,
      editAppointmentModalData: {},
      appointmentAction: "",
      reasonSelect: 0,
      prevSpaces: [],
    });
  }

  manageEditAppointmentModal = (e: SharedTypes.SimpleEvent) => {
    const { name, value } = e.target;
    // console.log("appointmentModal", name, value);

    switch (name) {
      case "forbidden":
        break;
      case "openOnFree":
        // debugger;

        if (!this.validatePerm(61, 'u')) {
          this.manageError(["No tienes permiso para esta acción"]);
          return;
        }

        let orderFree = {};
        orderFree = this.props.spaces
          .map((s) => s.appointments.find((a) => a.id === value.item.orderId))
          .filter((x) => x)
          .pop();

        if (!orderFree) {
          orderFree = this.props.spaces.map((s) => {
            return s.conflicts.map((c) => {
              return c.orders.find((a) => a.id === value.item.orderId) || null;
            });
          }).flat().filter(e => e).pop()/* [0][0] */;
        }

        if(!orderFree) {
          orderFree = this.state.prevSpaces
          .map((s) => s.appointments.find((a) => a.id === value.item.orderId))
          .filter((x) => x)
          .pop();

          if (!orderFree) {
            orderFree = this.state.prevSpaces.map((s) => {
              return s.conflicts.map((c) => {
                return c.orders.find((a) => a.id === value.item.orderId) || null;
              });
            }).flat().filter(e => e).pop()/* [0][0] */;
          }
        }

        let selectedFacility = this.props.spaces.find(s => s.id === value.droppedOn.spaceId).facility;

        if(orderFree.facilityId !== selectedFacility){
          this.manageError(["No se puede cambiar la cita a otra instalación."])
        } else {
          let window = this.props.spaces
          .find(s => s.id === value.droppedOn.spaceId).windows
          .find(w => value.droppedOn.begin >= w.begin && value.droppedOn.begin < w.end);
          // debugger;
          let appDuration = orderFree.end - orderFree.begin;
          let windowEnd = parseInt(window.end / 100) * 100 + (Math.min(window.end % 100, 59) * 100) / 60;
          if(windowEnd >= 2398) windowEnd = 2400;
          if(windowEnd - value.droppedOn.begin < appDuration){
            this.manageError(["La cita sale del horario de atención de la ventana."]);
          }
          else {
            // debugger;
            this.props.loadTrip(
              {
                company_id: this.props.companyId,
                trip_id: orderFree.tripId,
              },
              {
                onSuccess: (trip) => {

                  if (trip.status.id === 6 || trip.status.id === 7) {
                    this.manageError(["El viaje ya no se encuentra en el estatus que permite esta interacción"]);
                    return;
                  }
                  
                  const order = trip.orders.find(
                    (o) => o.id === value.item.orderId
                  );
    
                  const facility =
                    order.to_facility.id === parseInt(selectedFacility)
                      ? { ...order.to_facility, direction: "to" }
                      : { ...order.from_facility, direction: "from" };
                  let min = Math.trunc(value.droppedOn.begin).toString();
                  if (min.length < 4) min = "0".concat(min);
                  // debugger;
                  let h = min.substring(0, 2);
                  let m = min.substring(2, 4);
                  m = Math.round(( m * 60 )/ 100);
                  if(m < 10) m = '0'.concat(m);
                  // debugger;
                  this.setState({
                    showEditAppointmentModal: true,
                    reasonSelect: 0,
                    editAppointmentModalData: {
                      overCard: false,
                      id: orderFree.id,
                      code: order.code,
                      direction: facility.direction,
                      logo: FILES_SERVER.concat(trip.fleet_provider.logo),
                      newSpaceId: value.droppedOn.spaceId,
                      maneuver: facility.direction === "to" ? "Descarga" : "Carga",
                      transportClassId: orderFree.transportClassId,
                      currentAppointment: {
                        spaceId: orderFree.spaceId,
                        date: new Date(
                          facility.direction === "to"
                            ? order.appointment_unload_date
                            : order.appointment_loading_date
                        ),
                        space: `Rampa ${value.item.spaceIndex}`,
                      },
    
                      newAppointment: {
                        spaceId: value.droppedOn.spaceId,
                        date: new Date(
                          this.state.selectedDateId.concat(
                            "T",
                            h,
                            ":",
                            m
                          )
                        ),
                        space: `Rampa ${value.droppedOn.spaceIndex}`,
                      },
                    },
                  });
                },
              }
            );
          }
        }
        break;
      case "openOverCard":
        // debugger;

        if (!this.validatePerm(61, 'u')) {
          this.manageError(["No tienes permiso para esta acción"]);
          return;
        }

        let orderMoved = this.props.spaces
          .map((s) => s.appointments.find((a) => a.id === value.item.orderId))
          .filter((x) => x)
          .pop();
        
        if (!orderMoved) {
          orderMoved = this.props.spaces.map((s) => {
            return s.conflicts.map((c) => {
              return c.orders.find((a) => a.id === value.item.orderId) || null;
            });
          }).flat().filter(e => e).pop()/* [0][0] */;
        }

        if(!orderMoved){
          orderMoved = this.state.prevSpaces
            .map((s) => s.appointments.find((a) => a.id === value.item.orderId))
            .filter((x) => x)
            .pop();
          
          if (!orderMoved) {
            orderMoved = this.state.prevSpaces.map((s) => {
              return s.conflicts.map((c) => {
                return c.orders.find((a) => a.id === value.item.orderId) || null;
              });
            }).flat().filter(e => e).pop()/* [0][0] */;
          }
        }

        const orderOver = this.props.spaces
          .filter((s) => s.id === value.droppedOn.spaceId)
          .map((s) =>
            s.appointments.find(
              (a) =>
                a.begin <= value.droppedOn.begin &&
                a.end >= value.droppedOn.begin
            )
          )
          .pop();

          // debugger;

        let selectedFacility1 = this.props.spaces.find(s => s.id === value.droppedOn.spaceId).facility;

        if(orderMoved.facilityId !== selectedFacility1){
          this.manageError(["No se puede cambiar la cita a otra instalación."])
        } else {
          let spaceWindow = this.props.spaces
            .find(s => s.id === value.droppedOn.spaceId).windows
            .find(w => value.droppedOn.begin >= w.begin && value.droppedOn.begin < w.end);

          
          let spaceWindowEnd = parseInt(spaceWindow.end / 100) * 100 + (Math.min(spaceWindow.end % 100, 59) * 100) / 60;
          if(spaceWindowEnd >= 2398) spaceWindowEnd = 2400;

          // debugger;

          if(spaceWindowEnd - value.droppedOn.begin < orderMoved.end - orderMoved.begin){
            this.manageError(["La cita sale del horario de atención de la ventana."])
          }else{
            // debugger;
            this.props.loadTrip(
              {
                company_id: this.props.companyId,
                trip_id: orderMoved.tripId,
              },
              {
                onSuccess: (trip) => {
                  
                  if (trip.status.id === 6 || trip.status.id === 7) {
                    this.manageError(["El viaje ya no se encuentra en el estatus que permite esta interacción"]);
                    return;
                  }

                  const order = trip.orders.find(
                    (o) => o.id === value.item.orderId
                  );
                  const facility =
                    order.to_facility.id === parseInt(selectedFacility1)
                      ? { ...order.to_facility, direction: "to" }
                      : { ...order.from_facility, direction: "from" };
                      // debugger;
                  let begin =  Math.trunc(value.droppedOn.begin).toString().length < 4 ? '0'.concat(value.droppedOn.begin.toString()) : value.droppedOn.begin.toString();
                  let h = begin.substring(0, 2);
                  let m = begin.substring(2, 4);
                  m = Math.round(( m * 60 )/ 100);
                  if(m < 10) m = '0'.concat(m);
                  this.setState({
                    showEditAppointmentModal: true,
                    reasonSelect: 0,
                    editAppointmentModalData: {
                      overCard: true,
                      id: orderMoved.id,
                      overId: orderOver?.id,
                      code: order.code,
                      direction: facility.direction,
                      logo: FILES_SERVER.concat(trip.fleet_provider.logo),
                      newSpaceId: value.droppedOn.spaceId,
                      maneuver: facility.direction === "to" ? "Descarga" : "Carga",
                      transportClassId: orderFree.transportClassId,
                      currentAppointment: {
                        spaceId: orderMoved.spaceId,
                        date: new Date(
                          facility.direction === "to"
                            ? order.appointment_unload_date
                            : order.appointment_loading_date
                        ),
                        space: `Rampa ${value.item.spaceIndex}`,
                      },
                      newAppointment: {
                        date: new Date(
                          this.state.selectedDateId.concat(
                            "T",
                            h,
                            ":",
                            m
                          )
                        ),
                        spaceId: value.droppedOn.spaceId,
                        space: `Rampa ${value.droppedOn.spaceIndex}`,
                      },
                    },
                  });
                },
              }
            );
          }
        }
        break;
      case "update":
        let newDate =
          this.state.selectedNewDate ||
          this.state.editAppointmentModalData.newAppointment.date;
        // newDate.setMinutes(this.state.selectedNewDate);
        //debugger;

        let spaceSelected = this.props.spaces.filter(item => item.id === this.state.editAppointmentModalData.newAppointment.spaceId);

        let hours = newDate.getHours();
        let minutes = newDate.getMinutes();

        if (spaceSelected.length > 0) {
          let space = spaceSelected[0];

          let dates = space.appointments.map( appoinment_item => {
            return [ convertDateToInt(`${appoinment_item.begin}`), convertDateToInt(`${appoinment_item.end}`)];
          });

          let transportClassId = this.state.editAppointmentModalData?.transportClassId ?? 0;
          let operation = this.state.editAppointmentModalData?.maneuver ?? '';

          let ranges = space.windows.map( windows_item => {
            let maneuver_time = 0;

            (windows_item.capacities || []).forEach( capacity => {
              if (`${capacity.id_class}` === `${transportClassId}`) {
                if (operation.toLowerCase() === 'carga') {
                  maneuver_time = capacity.single_load > 0 ? capacity.single_load : capacity.double_load > 0 ? capacity.double_load : 0;
                } else {
                  maneuver_time = capacity.single_unload > 0 ? capacity.single_unload : capacity.double_unload > 0 ? capacity.double_unload : 0;
                }
              }
            });

            return [ 
              convertDateToInt(`${windows_item.begin}`), 
              convertDateToInt(`${windows_item.end}`),
              [],
              {},
              maneuver_time
            ];
          });

          let minutes_string = minutes < 10 ? `0${minutes}` : `${minutes}`;
          let new_time_to_assign = convertDateToInt(`${hours}${minutes_string}`);

          // Se valida si esta dentro del rango de alguna ventana
          let isInWindow = false;

          ranges.forEach( item => {
            let new_time_to_assign_end = item.length > 4 ? item[4] : 0;

            if (new_time_to_assign >= item[0] && new_time_to_assign_end <= item[1] && new_time_to_assign_end > 0) {
              isInWindow = true;
            }
          });

          if (!isInWindow) {
            this.manageError(["La cita sale del horario de atención de la ventana."])
            this.cancelEditAppointment()
            return;
          }

          // Se valida si esta dentro de los espacios no permitidos
          let resultWindows = getNotAllowedWindows(dates, ranges, 0, false, true);
          let spacesNotAllowed = resultWindows.result_not_allowed;
          let spaceNotAllowed = false;

          spacesNotAllowed.forEach( item => {
            if (new_time_to_assign >= item[0] && new_time_to_assign < item[1]) {
              spaceNotAllowed = true;
            }
          });

          if (spaceNotAllowed) {
            this.manageError(["En este horario no hay tiempo suficiente en la ventana para la maniobra."])
            this.cancelEditAppointment()
            return;
          }

        } else {
          this.manageError(["La cita sale del horario de atención de la ventana."])
          this.cancelEditAppointment()
          return;
        }

        const params = {
          company_id: this.props.companyId,
          id: this.state.editAppointmentModalData.id,
          old_date: this.state.editAppointmentModalData.currentAppointment.date.toISOString(),
          new_date: newDate.toISOString(),
          old_space: this.state.editAppointmentModalData.currentAppointment
            .spaceId,
          new_space: this.state.editAppointmentModalData.newAppointment.spaceId,
          action:"single_replace",
            // this.state.appointmentAction === "2"
            //   ? "hard_replace"
            //   : "single_replace",
          submotive: this.state.reasonSelect,
          replaced_order: this.state.editAppointmentModalData.overId,
        };

        if (this.state.editAppointmentModalData.direction === "to") {
          params.view = "unload";

          // params.unload_space = this.state.editAppointmentModalData.newSpaceId;
          // params.appointment_unload_date = this.state.editAppointmentModalData.newAppointment.date.toISOString();
        } else {
          params.view = "load";
          // params.load_space = this.state.editAppointmentModalData.newSpaceId;
          // params.appointment_loading_date = this.state.editAppointmentModalData.newAppointment.date.toISOString();
        }
        this.props.setAppointmentDate(params, {
          onSuccess: () => {
            this.loadFacilityOrders();
          },
        });
        this.setState({
          showEditAppointmentModal: false,
          selectedNewDate: null,
          overCard: false,
          editAppointmentModalData: {},
          appointmentAction: "",
          reasonSelect: 0,
          prevSpaces: [],
        });
        break;
      case "close":
      case "cancel":
        // debugger;
        this.setState({
          showEditAppointmentModal: false,
          selectedNewDate: null,
          overCard: false,
          editAppointmentModalData: {},
          appointmentAction: "",
          reasonSelect: 0,
          prevSpaces: [],
        });
        break;
      default:
        this.setState({ [name]: value });
    }
  };

  manageConflictModal = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "open":
        this.setState({
          showConflictModal: true,
          currentConflict: value
        })
        break;
      case "close":
        this.setState({
          showConflictModal: false,
          currentConflict: null
        })
        break;
      default:
    }
  }

  manageDetailModal = (e: SharedTypes.SimpleEvent) => {
    const { name, value } = e.target;
    // console.log(name, value);
    switch (name) {
      case "save":
        const params = {
          company_id: this.props.companyId,
          trip_id: this.state.currentOrder.id,
          fleetproviderid: Number(this.state.selectedProvider),
          action: "set_assembly",
        };
        //debugger;
        if (this.state.selectedAssembly > 0) {
          params.assembly = this.state.selectedAssembly;
        }
        this.props.bookReservation(params, {
          onSuccess: () => {
            this.loadFacilityOrders();
            this.manageDetailModal({ target: { name: "close", value: "" } });
          },
        });
        break;
      case "Cancelar viaje":
        this.setState({
          showCancelTripModal: true,
          reasonSelect: 0,
          showModal: false,
        });
        break;
      case "Reservar":
        this.props.tripBooking({
          company_id: this.props.companyId,
          facility_id: this.props.facilityId,
          trip_id: this.state.currentOrder.id,
          view: "booking",
        });
        this.setState({ showBookingModal: true, showModal: false });
        break;
      case "cancelTrip":

        let motiveDescriptionCancel = '';

        (this.props.orderMotives || []).map( motive => {
          if (`${motive.value}` === `${this.state.reasonSelect}`) {
            motiveDescriptionCancel = motive.label;
          }
        })


        this.props.cancelTrip(
          {
            company_id: this.props.companyId,
            trip_id: this.state.currentOrder.id,
            motive_id: this.state.reasonSelect,
            motive_desc: motiveDescriptionCancel
          },
          {
            onSuccess: () => {
              this.setState({
                showCancelTripModal: false,
                showModal: false,
                currentOrder: undefined,
              });
              this.loadFacilityOrders();
            },
          }
        );
        break;
      case "Cancelar cita":
        // console.log(name, value);
        this.setState({
          reasonSelect: 0,
          showCancelAppointmentModal: true,
          showModal: false,
        });
        break;
      case "Cancelar reservación":
        this.setState({
          showCancelBookingModal: true,
          reasonSelect: 0,
          showModal: false,
        });
        break;
      case "Finalizar viaje sin datos":
        this.setState({
          showFinalizeTripModal: true,
          showModal: false,
        });
        break;
      case "Finalizar orden sin datos":
        this.setState({
          showFinalizeOrderModal: true,
          showModal: false,
        });
        break;
      case "reasonSelect":
        this.setState({ reasonSelect: value });
        break;
      case "cancelBooking":

        let motiveDescription = '';

        (this.props.orderMotives || []).map( motive => {
          if (`${motive.value}` === `${this.state.reasonSelect}`) {
            motiveDescription = motive.label;
          }
        })

        this.props.cancelBooking(
          {
            company_id: this.props.companyId,
            trip_id: this.state.currentOrder.id,
            motive_id: this.state.reasonSelect,
            motive_desc: motiveDescription
          },
          {
            onSuccess: () => {
              this.setState({
                showCancelBookingModal: false,
                currentOrder: undefined,
              });
              this.loadFacilityOrders();
            },
          }
        );
        break;
      case "finalizeTrip":
        this.props.finalizeTrip(
          {
            company_id: this.props.companyId,
            trip_id: this.state.currentOrder.id,
          },
          {
            onSuccess: () => {
              this.setState({
                showFinalizeTripModal: false,
                currentOrder: undefined,
              });
              this.loadFacilityOrders();
            },
          }
        );
        break;
      case "finalizeOrder":
        // console.log(name, value);
        this.props.finalizeOrder(
          {
            company_id: this.props.companyId,
            id: this.state.card.id,
            view: value,
          },
          {
            onSuccess: () => {
              this.setState({
                showModal: false,
                showCancelAppointmentModal: false,
                showCancelTripModal: false,
                showCancelBookingModal: false,
                showFinalizeOrderModal: false,
                currentOrder: undefined,
                card: undefined,
              });
              this.loadFacilityOrders();
            },
          }
        );
        break;
      case "cancelAppointment":
        // console.log(name, value);
        this.props.cancelAppointment(
          {
            company_id: this.props.companyId,
            id: this.state.card.id,
            view: value,
            submotive: this.state.reasonSelect,
          },
          {
            onSuccess: () => {
              this.setState({
                showModal: false,
                showCancelAppointmentModal: false,
                showCancelTripModal: false,
                showCancelBookingModal: false,
                currentOrder: undefined,
                card: undefined,
              });
              this.loadFacilityOrders();
            },
          }
        );
        break;
      case "open":
        // console.log("opening", name, value);
        this.props.loadTrip(
          {
            company_id: this.props.companyId,
            trip_id: value.tripId,
          },
          {
            onSuccess: (trip) => {
              // console.log("success", trip);
              // debugger;
              this.setState({
                showModal: true,
                currentOrder: getOrderDetail(trip, {
                  transportClasses: this.props.transportClasses,
                  tags: this.props.companyTags,
                  statusId: value.statusId,
                  // facilityId: this.props.facilityId,
                  facilityId: value.facilityId.toString(),
                  orderId: value.id,
                  orderStatus: value.statusId,
                }),
                card: value,
              });
            },
          }
        );
        // console.log("test");
        break;
      case "changeTrip":
          // debugger;
          this.props.loadTrip(
            {
              company_id: this.props.companyId,
              trip_id: value.tripId,
            },
            {
              onSuccess: (trip) => {
                // console.log("success", trip);
                // debugger;
                this.setState({
                  // showModal: true,
                  currentOrder: getOrderDetail(trip, {
                    transportClasses: this.props.transportClasses,
                    tags: this.props.companyTags,
                    statusId: null,
                    facilityId: this.props.facilityId,
                    orderStatus: (trip.orders.find(o => o.id === value.orderId) || {status: null}).status.id,
                  }),
                  card: {
                    ...this.state.card,
                    id: value.orderId,
                    op: value.op,
                  },
                });
              },
            }
          );
          break
      case "close":
        this.setState({
          showModal: false,
          showCancelAppointmentModal: false,
          showCancelTripModal: false,
          showCancelBookingModal: false,
          showFinalizeTripModal: false,
          showFinalizeOrderModal: false,
          showBookingModal: false,
          currentOrder: undefined,
          card: undefined,
        });
        break;
      case "cancel":
        this.setState({
          showModal: false,
          showCancelAppointmentModal: false,
          showCancelTripModal: false,
          showCancelBookingModal: false,
          showFinalizeTripModal: false,
          showFinalizeOrderModal: false,
          showBookingModal: false,
          currentOrder: undefined,
          card: undefined,
        });
        break;
      case "updateStatus":
        // this.props.setStatus(this.state.currentOrder.id, value);
        this.setState({
          showModal: false,
          showCancelAppointmentModal: false,
          showCancelTripModal: false,
          showCancelBookingModal: false,
          currentOrder: undefined,
          card: undefined,
        });
        break;

      default:
    }
  };

  handleChangeDay = (e: SharedTypes.SimpleEvent) => {
    const { currentDate } = this.state;

    if (
      e.target &&
      e.target.attributes["name"] &&
      parseInt(e.target.attributes["name"].value) &&
      e.target.attributes["name"].value.length < 9
    ) {
      let dat = currentDate;
      let d = new Date(
        dat.getFullYear(),
        dat.getMonth(),
        dat.getDate() + parseInt(e.target.attributes["name"].value)
      );
      this.setState({ currentDate: d });
    } else if (e instanceof Date) {
      let d = new Date(e.getFullYear(), e.getMonth(), e.getDate());
      this.setState({
        selectedDate: d,
        currentDate: d,
        selectedDateId: d.toISOString().split("T")[0],
      });
      this.loadFacilityOrders();
    } else if (
      e.target.attributes["name"] &&
      e.target.attributes["name"].value
    ) {
      let x = e.target.attributes["name"].value.split("-");
      let d = new Date(x[0], parseInt(x[1]) - 1, x[2]);
      this.setState(
        {
          selectedDate: d,
          selectedDateId: d.toISOString().split("T")[0],
        },
        () => this.loadFacilityOrders()
      );
    }
  };
}

const mapStateToProps = (state): StateToProps => {
  // console.log(state.shipment.trips.items);
  const loading =
    state.api["currentFacility"]?.status?.isFetching ||
    state.api["COMPANIES.FACILITIES.SPACES"]?.status?.isFetching ||
    state.api["COMPANIES.TAGS"]?.status?.isFetching ||
    state.api["spacesResponse"]?.status?.isFetching;

  const companyId: number = state.globalCatalog.session.company.id;
  const companyData = state.globalCatalog.session.company;
  const companyFacilities = getEntityItems(state, "COMPANIES.FACILITIES");
  const facility: Types.DetailFacility = state.api["currentFacility"]?.items;
  const companyTags: Array<any> = getEntityItems(state, "COMPANIES.TAGS");
  const transportClasses: Array<any> = getEntityItems(
    state,
    "COMPANIES.TRANSPORTCLASS"
  );
  const spacesResponse = getEntityItems(state, "spacesResponse");
  // const trips = state.shipment.trips.items;
  let spacesTemp = spacesResponse.map((space, index) => ({
    ...mapResponseToSpaces(space, index),
    tags: mapSpaceTags(space, companyTags),
    windows: mapSpaceWindows(space),
    appointments: mapSpaceAppointments(
      space.load_orders,
      space.unload_orders,
      transportClasses,
      companyTags,
      mapSpaceWindows(space),
      () => {},
      index,
      space.space_id,
      space.facility_id,
      ((companyFacilities.find(cF => cF.id.toString() === space.facility_id.toString()) || {}).time_zone_description || "")
    ),
    conflicts: mapSpaceAppointmentsConflicts(
      space.conflicts,
      transportClasses,
      companyTags,
      () => {},
      index,
      space.space_id,
      space.facility_id,
      space.windows
    ),
  }));

  let spaces = cloneDeep(spacesTemp).map( space => {

    let spaceTemp = space;

    let conflictsFilter = spaceTemp.conflicts.filter(conf => conf.begin !== conf.end).map(item => {
      if (item.begin > item.end || item.end > 2359) {
        item.end = 2359;
      }
      return item;
    });

    let appointmentsFilter = spaceTemp.appointments.map(item => {
      if (item.begin > item.end || item.end > 2359) {
        item.end = 2359;
      }
      return item;
    });

    spaceTemp.conflicts = conflictsFilter;

    spaceTemp.appointments = spaceTemp.appointments.filter(item => {
      return conflictsFilter.filter(item2 => item.begin >= item2.begin && item.end <= item2.end).length === 0;
    });

    let appointmentsTemp = appointmentsFilter.concat(conflictsFilter);

    // se obtienen las citas que no esten en una ventana
    spaceTemp.windows.forEach( wnd => {

      let appointsTemp = appointmentsTemp;

      appointmentsTemp.forEach((ap, apIndex) => {
        if (ap.begin >= wnd.begin && ap.end <= wnd.end) {
          appointsTemp = appointsTemp.filter(item => item.id !== ap.id)
        }
      });

      appointmentsTemp = appointsTemp;

    });

    //se marcan con error las citas
    spaceTemp.appointments = spaceTemp.appointments.map(app => {
      if ((appointmentsTemp.filter(item => item.id === app.id)).length > 0) {
        app.error = true;
      }

      return app;
    });

    if (appointmentsTemp && appointmentsTemp.length && appointmentsTemp.length === 0) {
      return spaceTemp;
    }
    
    let windowsTemp = spaceTemp.windows;
    let lastWindow = windowsTemp.length > 0 ? cloneDeep(windowsTemp[windowsTemp.length - 1]) : null;

    //Se agregan las ventanas faltantes
    if (lastWindow != null) {

      appointmentsTemp.forEach(app => {
        lastWindow.begin = app.begin;
        lastWindow.end = app.end;
        lastWindow.error = true;

        windowsTemp = windowsTemp.concat(cloneDeep(lastWindow));
      });

      spaceTemp.windows = windowsTemp;

    }

    //Se validan las ventanas restantes
    windowsTemp.sort((a, b) => a.begin - b.begin);
    let windowsTemp2 = [];

    cloneDeep(windowsTemp).forEach(item => {
      if (windowsTemp2.filter(item2 => item2.begin === item.begin && item2.end === item.end).length === 0) {
        windowsTemp2.push(item);
      }
    });

    //let windowsTemp2 = cloneDeep(windowsTemp);
    let windsToDelete = [];

    windowsTemp2.forEach( (wnd, index, source) => {

      // if (wnd.error && source[index - 1] && source[index - 1].end > wnd.begin) {

      //   windowsTemp2[index].begin = source[index - 1].begin;
      //   windsToDelete = windsToDelete.concat(index - 1);

      // } else 
      if (wnd.error && source[index - 1] && source[index + 1]) {

        if (source[index - 1].end > wnd.begin) {
          windowsTemp2[index - 1].end = wnd.begin;
        }

        if (source[index + 1].begin < wnd.end) {
          windowsTemp2[index + 1].begin = wnd.end;
        }
        
      } else if (wnd.error && source[index + 1] && source[index + 1].begin < wnd.end) {

        windowsTemp2[index].end = source[index + 1].begin;

      } else if (wnd.error && source[index - 1] && source[index - 1].end > wnd.begin) {

        windowsTemp2[index - 1].end = wnd.begin;

      }

    });

    // let windowsTemp3 = [];

    // windowsTemp2.forEach((wnd, index) => {
    //   if (!windsToDelete.some( item => item === index)) {
    //     windowsTemp3 = windowsTemp3.concat(wnd);
    //   }
    // });

    spaceTemp.windows = windowsTemp2.filter( wnd => !(wnd.begin === 0 && wnd.end === 0));

    return spaceTemp;

  });

  const cities = state.globalCatalog.cities.items;
  const states = state.globalCatalog.states.items;
  const orderMotives = getEntityItems(state, "COMPANIES.CATALOGS.ORDERSMOTIVES")
    .map((o) =>
      o.submotives.map((s) => ({
        label: `${o.description} - ${s.description}`,
        value: s.id,
        type: s.type,
      }))
    )
    .flat();

  const tripAvailability = getEntityItems(
    state,
    "COMPANIES.FACILITIES.TRIPSCONTROL.BOOKING"
  );
  const bookingAssemblyAvailability: Types.BookingAssemblyRow = tripAvailability
    .map(mapBookingAssemblyAvailability)
    .flat();
  const contracts = getEntityItems(state, "COMPANIES.FACILITIES.CONTRACTS");
  const providers = getEntityItems(state, "COMPANIES.NETWORK");

  // const bookingFleetProviderAvailability = tripAvailability.map(
  //   mapBookingFleetProviderAvailability
  // );


  return {
    configuration: state.configuration.assignedShipmentBoard,
    orderMotives,
    cities,
    states,
    loading,
    companyId,
    facility,
    spaces,
    transportClasses,
    companyTags,
    companyData,
    bookingAssemblyAvailability,
    contracts,
    providers,
    // bookingFleetProviderAvailability,
    // trips,
  };
};
const mapDispatchToProps = (dispatch): DispatchToProps => {
  // dispatch(loadStates());
  // dispatch(loadCities());
  dispatch(readEntities("COMPANIES.CATALOGS.ORDERSMOTIVES"));
  return {
    cancelMultipleRequests: () => dispatch(cancelMultipleRequests()),
    cancelRequest: (entityPath: string) => dispatch(cancelRequest(entityPath)),
    setBaseConfig: (config) => dispatch(setConfig("assignedShipmentBoard", config)),
    searchCompany: (
      params: { company_id: number, search_company_id: number },
      opts: ApiOptions
    ) => dispatch(readEntity("COMPANIES.NETWORK.SEARCH")),
    loadTrip: (
      params: { company_id: number, trip_id: number },
      opts: ApiOptions
    ) =>
      dispatch(
        readEntity("COMPANIES.TRIPS", { ...params, view: "control" }, opts)
      ),
    loadTransportClasses: (company_id, opts) =>
      dispatch(readEntities("COMPANIES.TRANSPORTCLASS", { company_id }, opts)),
    loadOrders: (
      params: {
        company_id: number,
        facility_id: number,
        date: string,
      },
      opts: ApiOptions
    ) =>
      dispatch(
        readEntities("COMPANIES.FACILITIES.ORDERSCONTROL", params, {
          ...opts,
          alias: "spacesResponse",
        })
      ),
    // setStatus: (tripId: number, newStatus: number) =>
    //   dispatch(setStatus(tripId, newStatus)),
    setAppointmentDate: (params: any, opts: ApiOptions) =>
      dispatch(partialEntity("COMPANIES.ORDERS", params, opts)),
    cancelAppointment: (params: any, opts: ApiOptions) =>
      dispatch(
        partialEntity(
          "COMPANIES.ORDERS",
          { ...params, action: "cancel_appointment" },
          opts
        )
      ),
    cancelBooking: (params, options) =>
      dispatch(
        partialEntity(
          "COMPANIES.TRIPS",
          { ...params, action: "cancel_booking" },
          options
        )
      ),
    finalizeTrip: (params, options) =>
      dispatch(
        partialEntity(
          "COMPANIES.TRIPS",
          { ...params, action: "finalize_trip" },
          options
        )
      ),
    finalizeOrder: (params: any, opts: ApiOptions) =>
      dispatch(
        partialEntity(
          "COMPANIES.ORDERS",
          { ...params, action: "finalize_order" },
          opts
        )
      ),
    cancelTrip: (params, options) =>
      dispatch(
        partialEntity(
          "COMPANIES.TRIPS",
          { ...params, action: "cancel_trip" },
          options
        )
      ),
    tripBooking: (params, opts) =>
      dispatch(
        readEntities("COMPANIES.FACILITIES.TRIPSCONTROL.BOOKING", params, opts)
      ),
    bookReservation: (params, options) =>
      dispatch(partialEntity("COMPANIES.TRIPS", params, options)),
    contractAvailability: (params, options) =>
      dispatch(readEntities("COMPANIES.FACILITIES.CONTRACTS", params, options)),
    providerAvailability: (params, options) =>
      dispatch(readEntities("COMPANIES.NETWORK", params, options)),
    // cancelAppointment: (params, options) =>
    //   dispatch(
    //     partialEntity(
    //       "COMPANIES.TRIPS",
    //       { ...params, action: "cancel_trip" },
    //       options
    //     )
    //   ),
    validateObjPerms: (perms, obj) => validateObjPerms(perms, obj),
  };
};

export default connect<Props, OwnProps, _, _, _, _>(
  mapStateToProps,
  mapDispatchToProps
)(AssignedShipmentBoard);

export const mapBookingFleetProviderAvailability = (
  t
): Types.BookingFleetProviderRow => ({
  fleetProvider: {
    logo: FILES_SERVER.concat(t.contract.fleet_provider.logo),
    name: t.contract.fleet_provider.corporation_name,
    id: t.contract.fleet_provider.fleet_provider_id,
  },
  id: t.contract.id,
  onChange: () => {},
  selectedProvider: 0,
  expClass: t.transport_class.code,
  total: t.quantity,
  available: t.available,
  used: t.quantity - t.unavailable - t.available,
  unavailable: t.unavailable,
});

const HOURS = [
  {
    id: 1,
    description: "00:00 AM"
  },
  {
    id: 100,
    description: "01:00 AM"
  },
  {
    id: 200,
    description: "02:00 AM"
  },
  {
    id: 300,
    description: "03:00 AM"
  },
  {
    id: 400,
    description: "04:00 AM"
  },
  {
    id: 500,
    description: "05:00 AM"
  },
  {
    id: 600,
    description: "06:00 AM"
  },
  {
    id: 700,
    description: "07:00 AM"
  },
  {
    id: 800,
    description: "08:00 AM"
  },
  {
    id: 900,
    description: "09:00 AM"
  },
  {
    id: 1000,
    description: "10:00 AM"
  },
  {
    id: 1100,
    description: "11:00 AM"
  },
  {
    id: 1200,
    description: "12:00 PM"
  },
  {
    id: 1300,
    description: "01:00 PM"
  },
  {
    id: 1400,
    description: "02:00 PM"
  },
  {
    id: 1500,
    description: "03:00 PM"
  },
  {
    id: 1600,
    description: "04:00 PM"
  },
  {
    id: 1700,
    description: "05:00 PM"
  },
  {
    id: 1800,
    description: "06:00 PM"
  },
  {
    id: 1900,
    description: "07:00 PM"
  },
  {
    id: 2000,
    description: "08:00 PM"
  },
  {
    id: 2100,
    description: "09:00 PM"
  },
  {
    id: 2200,
    description: "10:00 PM"
  },
  {
    id: 2300,
    description: "11:00 PM"
  },
]

const SpaceDescription = styled.div`
  color: white;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: solid 2px white;
  border-radius: 10px;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 12px;
  padding-right: 12px;
`