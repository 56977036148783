import React from "react";
import { connect } from "react-redux";
import { createEntity, deleteEntity,/* , getEntityItems, */ partialEntity, readEntities, getEntityItems, SaveItemCounter } from "../../redux/api";
import { loadCompanyUsers } from "../../redux/reducers/company-reducer";
import CompanyNotificationRow from "./company-notification-row";
import { groupFacilities, showSimpleFlashNotification } from "../../shared/utils";
import { validateObjPerms } from "../../redux/reducers/global-catalog.reducer";
import AddUserModal from "../../shared/dialog/add-user-modal";
import ManageFacilityUserModal from "../../shared/dialog/manage-facility-user-modal";
import CatalogSelectionFooter from "../../shared/component/catalog-selection-footer";
import GenericDialog from "../../shared/dialog/generic-modal";
import Loader from "../../shared/icons/tat/loader";
import { cancelMultipleRequests } from "../../redux/api/actions";
// import CompanyAlertRow from "./company-alert-row";

class NotificationListView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // openInviteModal: false,
      showAddUserModal: null,
      showManageFacility: null,
      notifications: [],
      seed: Math.random(),

      notificationPivot: null,
      usersToDelete: [],
      showDeleteUsers: false,
      errorMessage: null,
      deleteUniqueUser: null,
      deleteUniqueFacility: null,
      addFacilitiesToDelete: null,
      showDeleteFacilities: false,
      isLoading: false
    };
  }

  componentDidMount() {
    document.addEventListener("keydown", this.cancelUpdate);
    this.loadData();
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.cancelUpdate, true);
  }

  cancelUpdate = (e) => {
    if(e.key === "Escape" && this.state.isLoading){
      this.setState({isLoading: false});
      this.props.cancelMultipleRequests();
    }
  }

  loadData = () => {
    this.setState({isLoading: true});

    if(this.props.companyType === 1){
      this.props.loadCompanyTags({ company_id: this.props.companyId });
    } else {
      this.props.loadCompanyFacilities({ company_id: this.props.companyId });
    }

    const counter = new SaveItemCounter(() => {
      this.setState({isLoading: false});
    });

    this.state.notifications = [];

    this.props.listNotificationCatalog({
      company_id: this.props.companyId,
      isMultipleCancels: true
    }, {
      onSuccess: async response => {
        (response.notifications || []).map( notification => {
          counter.add();
        });

        (response.notifications || []).map( notification => {
          this.props.getDetailNotification({
            company_id: this.props.companyId,
            notification_id: notification.id,
            user_id: this.props.userId,
            isMultipleCancels: true
          }, {
            onSuccess: r => {
              notification.groups = r.groups.map( item => {
                item.edit = false;
                item.users = item.users.map( info => `${info}`)
                item.facilities = item.facilities.map( info => `${info}`)
                return item;
              })

              this.state.notifications.push(notification);

              counter.done();
            },
            onError: () => {
              counter.done();
            }
          });
        });
      }
    });
  }

  validShow = (perm) => {
    let response = this.props.validateObjPerms([perm], {
      display: true,
    });
    if (Object.keys(response).length) {
      return true;
    }
    return false;
  };

  loadNotification = (notification_id) => {
    this.props.getDetailNotification({
      company_id: this.props.companyId,
      notification_id,
      user_id: this.props.userId
    }, {
      onSuccess: r => {

        (this.state.notifications || []).map( (item, index) => {

          if (`${notification_id}` === `${item.id}`) {
            this.state.notifications[index].groups = r.groups.map( item => {
              item.edit = false;
              item.users = item.users.map( info => `${info}`)
              item.facilities = item.facilities.map( info => `${info}`)
              return item;
            });
          }

        });

        this.setState({});

      }
    });

  }

  handleChange = (e) => {
    let value = e.target.value;
    let name = e.target.name;

    switch(name) {
      case 'delete-unique-facility':
        this.setState({
          deleteUniqueFacility: value
        });
        break;
      case 'delete-unique-user':
        this.setState({
          deleteUniqueUser: value
        });
        break;
      case 'add-facilities-to-delete':
        this.setState({
          addFacilitiesToDelete: value,
          notificationPivot: null,
          usersToDelete: []
        });
        break;
      case 'add-users-to-delete':
        this.setState({
          notificationPivot: value.notification_id,
          usersToDelete: value.users,
          addFacilitiesToDelete: null
        });
        break;
      case 'show-add-user-modal':
        this.setState({showAddUserModal: value});
        break;
      case 'show-add-user-facility-modal-success':
        this.setState({showAddUserModal: null, seed: Math.random()});
        showSimpleFlashNotification('Se añadieron correctamante los usuarios con sus instalaciones', null);
        this.loadNotification(value);
        break;
      case 'show-add-user-modal-success':
        this.setState({showAddUserModal: null, seed: Math.random()});
        showSimpleFlashNotification('Se añadieron correctamante los usuarios', null);
        this.loadNotification(value);
        break;
      case 'show-manage-facility':
        this.setState({showManageFacility: value})
        break;
      case 'show-manage-facility-success':
        this.setState({showManageFacility: null, seed: Math.random()});
        showSimpleFlashNotification('Se añadieron correctamante las instalaciones', null);
        this.loadNotification(value);
        break;
      default:
        this.setState({[name]: value});
        break;
    }
  }

  render() {

    let allGroups = [];

    this.state.notifications.map( item => {
      allGroups = allGroups.concat(item.groups);
    });

    return (
      <React.Fragment>
        {
          (this.state.isLoading) && 
          <div
            style={{
              position: "absolute",
              top: "0",
              left: "0",
              width: "100%",
              height: "100%",
              backgroundColor: "#00000080",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              zIndex: 1001,
              backdropFilter: "blur(3px)"
            }}
          >
            <div style={{display: "flex", alignItems: "center"}}>
              <Loader width="100px" height="100px"/>
              <div style={{color: "#fff", fontSize: "36px"}}>
                Cargando
              </div>
            </div>
            <div style={{color: "#fff", fontSize: "30px"}}>
              Si desea cancelar la consulta, presione ESC
            </div>
          </div>
        }

        {
          this.state.notifications.sort((a, b) => a.id - b.id).map(notification => (
            <CompanyNotificationRow
              key={`notification-row-${notification.id}-${this.state.seed}`}
              notification={notification}
              onNotificationDataChange={this.handleNotificationDataChange}
              users={this.props.companyUsers}
              facilities={this.props.facilitiesByTag}
              takenFacilities={(allGroups).map(g => g.facilities || []).flat()}
              takenUsers={(allGroups).map(g => g.users || []).flat()}
              isLoading={this.props.isLoading}
              isLt={this.props.companyType === 1}
              updateActivated={this.validShow({
                id: "5",
                perm: "u",
              })}
              deleteActivated={this.validShow({
                id: "5",
                perm: "d",
              })}
              createActivated={this.validShow({
                id: "5",
                perm: "c",
              })}
              handleChange={(e) => {
                this.handleChange(e) 
              }}
              usersToDelete={{users_to_delete: this.state.usersToDelete, notification_pivot: this.state.notificationPivot}}
              facilitiesToDelete={this.state.addFacilitiesToDelete}
            />
          ))
        }

        {
          this.state.showAddUserModal !== null &&
          <AddUserModal
            showModal={this.state.showAddUserModal !== null}
            notification={this.state.showAddUserModal}
            closeAction={() => this.setState({showAddUserModal: null})}
            handleChange={this.handleChange}
          />
        }

        {
          this.state.showManageFacility !== null &&
          <ManageFacilityUserModal
            showModal={this.state.showManageFacility !== null}
            notification={this.state.showManageFacility}
            closeAction={() => this.setState({showManageFacility: null})}
            handleChange={this.handleChange}
          />
        }

        {
          this.state.showDeleteFacilities &&
          <GenericDialog
            open={this.state.showDeleteFacilities}
            title={'¿Estás seguro?'}
            message={`Esta acción eliminará las instalaciones del usuario ${this.state.addFacilitiesToDelete.user_name}, y será irreversible`}
            isLoading={this.props.isUsersLoading}
            acceptAction={() => {

              let data = {
                action: 'add_facilities',
                company_id: this.props.companyId,
                group: this.state.addFacilitiesToDelete.group_id,
                facilities_to_add: [],
                facilities_to_remove: this.state.addFacilitiesToDelete.facilities,
                notification_id: this.state.addFacilitiesToDelete.notification_id
              };

              this.props.manageUsers(data, {
                onSuccess: () => {
                  this.loadNotification(this.state.addFacilitiesToDelete.notification_id);
                  showSimpleFlashNotification('Se eliminaron correctamente las instalaciones', null);
                  this.setState({showDeleteFacilities: false, deleteUniqueUser: null, deleteUniqueFacility: null, addFacilitiesToDelete: null, seed: Math.random()});
                },
                onError: (e) => {
                  this.setState({
                    errorMessage: e.response?.data?.error || 'Ocurrió un error, intente de nuevo'
                  })
                }
              });

            }}
            closeAction={() => this.setState({addFacilitiesToDelete: null, showDeleteFacilities: false})}
          />
        }

        {
          this.state.deleteUniqueFacility !== null &&
          <GenericDialog
            open={this.state.deleteUniqueFacility !== null}
            title={'¿Estás seguro?'}
            message={`Esta acción eliminará la instalación ${this.state.deleteUniqueFacility.facility_name} del usuario ${this.state.deleteUniqueFacility.user_name}, y será irreversible`}
            isLoading={this.props.isUsersLoading}
            acceptAction={() => {

              let data = {
                action: 'add_facilities',
                company_id: this.props.companyId,
                group: this.state.deleteUniqueFacility.group_id,
                facilities_to_add: [],
                facilities_to_remove: [this.state.deleteUniqueFacility.facility_id],
                notification_id: this.state.deleteUniqueFacility.notification
              };

              this.props.manageUsers(data, {
                onSuccess: () => {
                  this.loadNotification(this.state.deleteUniqueFacility.notification);
                  showSimpleFlashNotification('Se eliminó correctamente la instalación', null);
                  this.setState({deleteUniqueFacility: null, deleteUniqueUser: null, seed: Math.random()});
                },
                onError: (e) => {
                  this.setState({
                    errorMessage: e.response?.data?.error || 'Ocurrió un error, intente de nuevo'
                  })
                }
              });

            }}
            closeAction={() => this.setState({deleteUniqueFacility: null})}
          />
        }

        {
          this.state.deleteUniqueUser !== null &&
          <GenericDialog
            open={this.state.deleteUniqueUser !== null}
            title={'¿Estás seguro?'}
            message={`Esta acción eliminará el usuario ${this.state.deleteUniqueUser.user_name}, y será irreversible`}
            isLoading={this.props.isUsersLoading}
            acceptAction={() => {

              let data = {
                action: 'add_new_user',
                company_id: this.props.companyId,
                users: [],
                users_to_remove: [this.state.deleteUniqueUser.user_id],
                notification_id: this.state.deleteUniqueUser.notification
              };

              this.props.manageUsers(data, {
                onSuccess: () => {
                  this.loadNotification(this.state.deleteUniqueUser.notification);
                  showSimpleFlashNotification('Se eliminó correctamente el usuario', null);
                  this.setState({deleteUniqueUser: null, deleteUniqueFacility: null, seed: Math.random()});
                },
                onError: (e) => {
                  this.setState({
                    errorMessage: e.response?.data?.error || 'Ocurrió un error, intente de nuevo'
                  })
                }
              });

            }}
            closeAction={() => this.setState({deleteUniqueUser: null})}
          />
        }

        {
          this.state.showDeleteUsers &&
          <GenericDialog
            open={this.state.showDeleteUsers}
            title={'¿Estás seguro?'}
            message={'Esta acción eliminará los usuarios, y será irreversible'}
            isLoading={this.props.isUsersLoading}
            acceptAction={() => {

              let data = {
                action: 'add_new_user',
                company_id: this.props.companyId,
                users: [],
                users_to_remove: this.state.usersToDelete,
                notification_id: this.state.notificationPivot
              };

              this.props.manageUsers(data, {
                onSuccess: () => {
                  this.loadNotification(this.state.notificationPivot);
                  showSimpleFlashNotification('Se eliminaron correctamente', null);
                  this.setState({showDeleteUsers: false, usersToDelete: [], deleteUniqueFacility: null, notificationPivot: null, deleteUniqueUser: null, seed: Math.random()});
                },
                onError: (e) => {
                  this.setState({
                    errorMessage: e.response?.data?.error || 'Ocurrió un error, intente de nuevo'
                  })
                }
              });

            }}
            closeAction={() => this.setState({showDeleteUsers: false})}
          />
        }

        {
          this.state.errorMessage !== null &&
          <GenericDialog
            open={this.state.errorMessage !== null}
            title={'Error'}
            message={this.state.errorMessage}
            acceptAction={() => this.setState({errorMessage: null})}
            closeAction={() => this.setState({errorMessage: null})}
          />
        }

        <CatalogSelectionFooter
          key={`notifications-catalog-footer`}
          hideSelectAll={true}
          show={this.state.usersToDelete.length > 1 || (this.state.addFacilitiesToDelete !== null && this.state.addFacilitiesToDelete.facilities.length > 1)}
          selectedElements={ this.state.addFacilitiesToDelete !== null ? this.state.addFacilitiesToDelete.facilities.length : this.state.usersToDelete.length}
          actions={[
            
            this.state.addFacilitiesToDelete !== null ? {
              description: "Eliminar Instalaciones",
              f: () => { this.setState({showDeleteFacilities: true}) }
            } : {
              description: "Eliminar Usuarios",
              f: () => { this.setState({showDeleteUsers: true}) }
            },
            {
              description: "Quitar Selecciones",
              f: () => {
                this.setState({
                  usersToDelete: [],
                  notificationPivot: null,
                  addFacilitiesToDelete: null
                });
              }
            }
          ]}
        />
      </React.Fragment>
    );
  }

  handleNotificationDataChange = (e, type, notificationId) => {
    let notifications = [];
    let notificationIndex = 0;
    let groupIndex = 0;
    switch(type) {
      case "active":
        notifications = this.state.notifications;
        notificationIndex = notifications.findIndex(a => a.id === notificationId.id);
        this.props.updateNotification({
          company_id: this.props.companyId,
          notification_id: notificationId.id,
          user_id: this.props.userId,
          action: 'activation',
          enable: !notifications[notificationIndex].is_active
        }, {
          onSuccess: r => {
            notifications[notificationIndex].is_active = !notifications[notificationIndex].is_active;
            this.setState({notifications});
          }
        }) 
        break;
      case "user":
        notifications = this.state.notifications;
        notificationIndex = notifications.findIndex(a => a.id === notificationId.id);
        groupIndex = notifications[notificationIndex].groups.findIndex(a => a.id === notificationId.group);
        notifications[notificationIndex].groups[groupIndex].users = e.target.value
        this.setState({notifications});
        break;
      case "facility":
        notifications = this.state.notifications;
        notificationIndex = notifications.findIndex(a => a.id === notificationId.id);
        groupIndex = notifications[notificationIndex].groups.findIndex(a => a.id === notificationId.group);
        notifications[notificationIndex].groups[groupIndex].facilities = e.target.value
        this.setState({notifications});
        break;
      case "edit":
        notifications = this.state.notifications;
        notificationIndex = notifications.findIndex(a => a.id === notificationId.id);
        groupIndex = notifications[notificationIndex].groups.findIndex(a => a.id === notificationId.group);
        notifications[notificationIndex].groups[groupIndex].edit = !notifications[notificationIndex].groups[groupIndex].edit;
        this.setState({notifications});
        break;
      case "new_group":
        notifications = this.state.notifications;
        notificationIndex = notifications.findIndex(a => a.id === notificationId.id);

        this.props.createNewGroup({
          company_id: this.props.companyId,
          notification_id: notificationId.id,
          user_id: this.props.userId,
          action: 'new_group'
        }, {
          onSuccess: r => {
            if (r.notification_group_id) {
              notifications[notificationIndex].groups.push({
                id: r.notification_group_id,
                users: [],
                facilities: [],
                edit: true
              });

              this.setState({notifications});
            }
          }
        });
        break;
      case "delete":
        notifications = this.state.notifications;
        notificationIndex = notifications.findIndex(a => a.id === notificationId.id);

        this.props.deleteGroup({
          company_id: this.props.companyId,
          group_id: notificationId.group,
        }, {
          onSuccess: r => {
            notifications[notificationIndex].groups = notifications[notificationIndex].groups.filter(item => item.id !== notificationId.group);
            this.setState({notifications});
          }
        })
        break;
      case "cancel":
        notifications = this.state.notifications;
        notificationIndex = notifications.findIndex(a => a.id === notificationId.id);
        groupIndex = notifications[notificationIndex].groups.findIndex(a => a.id === notificationId.group);

        this.props.getDetailNotification({
          company_id: this.props.companyId,
          notification_id: notificationId.id,
          user_id: this.props.userId
        }, {
          onSuccess: r => {
            (r.groups || []).map (item => {
              if (item.id === notificationId.group) {
                notifications[notificationIndex].groups[groupIndex] = {id: item.id, edit: false, users: item.users, facilities: item.facilities}
                this.setState({notifications});
              }
            });
          }
        });
        break;
      case "save": 
        notifications = this.state.notifications;
        notificationIndex = notifications.findIndex(a => a.id === notificationId.id);
        groupIndex = notifications[notificationIndex].groups.findIndex(a => a.id === notificationId.group);
        
        let users = notifications[notificationIndex].groups[groupIndex].users.map( item => Number(item))
        let facilities = notifications[notificationIndex].groups[groupIndex].facilities.map( item => Number(item))

        this.props.updateGroup({
          company_id: this.props.companyId,
          group_id: notificationId.group,
          users, 
          facilities
        }, {
          onSuccess: r => {
            notifications[notificationIndex].groups[groupIndex].edit = false;
            this.setState({notifications});
          }
        });
        break;
      default:
    }
  }
}

async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}

const mapStateToProps = state => {
  const companyFacilities = getEntityItems(state, "COMPANIES.FACILITIES");
  const facilitiesByTag = groupFacilities(companyFacilities);

  const companyTags = getEntityItems(state, "COMPANIES.TAGS");
  const baseTags = companyTags.filter(t => t.type === 3);

  const isLoadingDetails = state?.api['COMPANIES.NOTIFICATIONS.DETAILS']?.status?.isFetching || false;

  let usersByTag = [
    { id: 9999999999, description: "Sin rol", items: [] },
  ];
  if(state.companyReducer.companyUsers.data && Array.isArray(state.companyReducer.companyUsers.data)){
    (state.companyReducer.companyUsers.data || []).forEach((u) => {
      if(u.role){
        let tagIndex = usersByTag.map((uT) => uT.id).indexOf(u.role);
          if (tagIndex !== -1) {
            usersByTag[tagIndex].items.push({
              id: u.users,
              description: u.username,
            });
          } else {
            let newTag = {
              id: u.role_detail.id,
              description: u.role_detail.description,
              items: [
                {
                  id: u.users,
                  description: u.username,
                },
              ],
            };
            usersByTag.push(newTag);
          }
      } else {
        usersByTag[0].items.push({
          id: u.users,
          description: u.username,
        });
      }
    });
    
    usersByTag = usersByTag.map((tag) => {
      let sortedItems = tag.items.sort((a, b) => {
        if (a.description.toLowerCase() > b.description.toLowerCase()) {
          return 1;
        }
        if (a.description.toLowerCase() < b.description.toLowerCase()) {
          return -1;
        }
        return 0;
      });
      return { ...tag, items: sortedItems };
    });
    
    let tagless = usersByTag.shift();
    
    usersByTag = usersByTag.sort((a, b) => {
      if (a.description.toLowerCase() > b.description.toLowerCase()) {
        return 1;
      }
      if (a.description.toLowerCase() < b.description.toLowerCase()) {
        return -1;
      }
      return 0;
    });
    
    usersByTag.push(tagless);
  
    // return usersByTag;
  }

  const isUsersLoading = state?.api['COMPANIES.NOTIFICATIONS.MANAGEUSERS']?.status?.isFetching || false;
  
  return {
    facilitiesByTag,
    baseTags,
    companyUsers: usersByTag,
    companyId: state.globalCatalog.session.company.id,
    userId: state.globalCatalog.session.user.id,
    isLoading: isLoadingDetails,
    companyType: state.globalCatalog.session.company.type.id,
    users_list: (state.companyReducer.companyUsers.data || []),
    isUsersLoading
  };
};

const mapDispatchToProps = dispatch => {
  dispatch(loadCompanyUsers());
  return {
    cancelMultipleRequests: () => dispatch(cancelMultipleRequests()),
    loadCompanyTags: (params, opts) => dispatch(readEntities("COMPANIES.TAGS", params, opts)),
    loadCompanyFacilities: (params, opts) => dispatch(readEntities("COMPANIES.FACILITIES", params, opts)),
    listNotificationCatalog: (params = {}, opts = {}) =>
      dispatch(
        readEntities(
          "COMPANIES.NOTIFICATIONS",
          { ...params },
          { args: { ...params }, ...opts }
        )
      ),
    getDetailNotification: (params = {}, opts = {}) =>
      dispatch(
        readEntities(
          "COMPANIES.NOTIFICATIONS.DETAILS",
          { ...params },
          { args: { ...params }, ...opts }
        )
      ),
    createNewGroup: (params, opt) => dispatch(createEntity("COMPANIES.NOTIFICATIONS.DETAILS", params, opt)),
    deleteGroup: (params, opt) => dispatch(deleteEntity("COMPANIES.NOTIFICATIONS.DETAILS", params, opt)),
    updateGroup: (params, opt) => dispatch(partialEntity("COMPANIES.NOTIFICATIONS.DETAILS", params, opt)),
    updateNotification: (params, opt) => dispatch(partialEntity("COMPANIES.NOTIFICATIONS", params, opt)),
    listNotificationUsers: (params = {}, opts = {}) =>
      dispatch(
        readEntities(
          "COMPANIES.NOTIFICATIONS.USERS",
          { ...params },
          { args: { ...params }, ...opts }
        )
      ),
    createNotificationUsers: (params = {}, opts = {}) =>
      dispatch(
        createEntity(
          "COMPANIES.NOTIFICATIONS.USERS",
          { ...params },
          { args: { ...params }, ...opts }
        )
      ),
    validateObjPerms: (perms, obj) => validateObjPerms(perms, obj),
    manageUsers: (params, opt) => dispatch(partialEntity("COMPANIES.NOTIFICATIONS.MANAGEUSERS", params, opt))
  };
};

const connectToStore = connect(mapStateToProps, mapDispatchToProps);

export default connectToStore(NotificationListView);
