import * as React from "react";
// import Caret from "../../../shared/component/caret";
// import type { RouterHistory } from "react-router-dom";
import styled from "styled-components";
import Caret from "../../shared/component/caret";
import RowD from "../../shared/component/row-d";
import { ActionsTricolon } from "../../shared/controls/actions-tricolon";
// import Button from "../../shared/controls/button";
import ExceedPlus from "../../shared/controls/exceed-plus";
import Tag from "../../shared/controls/tag";
// import Button from "../../../shared/controls/button";
// import { ActionsTricolon } from "../../../shared/controls/actions-tricolon";
// import * as Types from "../types";
// import RowD from "../../../shared/component/row-d";
// import UnassignedShipmentDetailTable from "./unassigned-shipment-detail-table";
// import Tag from "../../../shared/controls/tag";
// import ExceedPlus from "../../../shared/controls/exceed-plus";
// import ReactTooltip from "react-tooltip";
// import { listCompanyUsersFacilities } from "../../../redux/reducers/company-reducer";
import OrderRowDetailTable from "./order-row-detail-table";
import Button from "../../shared/controls/button";

class OrderCatalogRow extends React.Component {
  state = {
    collapsed: true,
  };

  componentDidUpdate(prevProps) {
    if(prevProps.collapsed !== this.props.collapsed){
      this.setState({collapsed: this.props.collapsed});
    }
  }

  getContentByType = () => {
    switch(this.props.itemType){
      case 1:
      case 2:
        return (<RowD {...this.props}>
          <CaretContainer
            onClick={() => this.setState({ collapsed: !this.state.collapsed })}
          >
            <Caret up={this.state.collapsed} fill="#AFC8FF" />
          </CaretContainer>
          <CompanyAvatar
          // data-place="right"
          // data-tip
          // data-for={this.props.id.toString()}
          >
            <img
              src={this.props.companyLogo || "/images/interrogacion.svg"}
              alt=""
            />
            <Name>{this.props.from_facility?.name || '-'}</Name>
          </CompanyAvatar>
          <CompanyAvatar
          // data-place="right"
          // data-tip
          // data-for={this.props.id.toString()}
          >
            <img
              src={this.props.companyLogo || "/images/interrogacion.svg"}
              alt=""
            />
            <Name>{this.props.to_facility?.name || '-'}</Name>
          </CompanyAvatar>
          <Text onClick={() => {this.props.history.push(`/company/controls/order/orderdetail/${this.props.id}?isOrder=${this.props.itemType=== 2 ? 'true' : 'false'}`)}}><span style={{cursor: "pointer"}}>{this.props.code}</span></Text>
          <Text>{this.props.itemType === 1 ? (this.props.order?.code || '') : this.props.priority}</Text>
          <Text>{this.props.type || 'FTL'}</Text>
          <TagsContainer name={this.props.id}>
            {(this.props.ordertag_order || []).map((t, index) => (
              <Tag {...t.tag} key={index} />
            ))}
          </TagsContainer>
          <Text>{this.props.status?.description || '-'}</Text>
          <ActionsContainer>
            {/* {this.props.actions && this.props.actions.length ? <ActionsTricolon actions={this.props.actions} tooltipPlacement={"left"}/> : ''} */}
            {
              this.props.actions && this.props.actions.length > 0 && [1,2].includes(this.props.itemType) &&
              <Button
                text={this.props.itemType === 1 ? 'Convertir en orden' : this.props.itemType === 2 ? 'Convertir en embarque' : ''}
                type={'primary small'}
                className={'custom-1'}
                onClick={() => {
                  if (this.props.actions[0].action) {
                    this.props.actions[0].action();
                  }
                }}
              />
            }
          </ActionsContainer>
        </RowD>)
      case 3:
        return (<RowD {...this.props}>
            <CaretContainer
              onClick={() => this.setState({ collapsed: !this.state.collapsed })}
            >
              <Caret up={this.state.collapsed} fill="#AFC8FF" />
            </CaretContainer>
            <Text 
              onClick={() => {
                /* this.props.history.push(`/company/controls/order/orderdetail/${this.props.id}?isOrder=${this.props.itemType=== 2 ? 'true' : 'false'}`) */
              }}
            >
              <span style={{/* cursor: "pointer" */}}>{this.props.code}</span>
            </Text>
            <Text>{this.props.package_pallet.length}</Text>
            <Text>{this.props.net_weight} kg</Text>
            <Text>{this.props.gross_weight} kg</Text>
            <Text>{this.props.volume} cbm</Text>
            <ActionsContainer>
              {this.props.actions && this.props.actions.length ? <ActionsTricolon actions={this.props.actions} tooltipPlacement={"left"}/> : ''}
            </ActionsContainer>
          </RowD>)
      default:
        return <></>
    }
  }

  render() {
    // const extraActions = this.props.actions;
    // )
      
    return (
      <React.Fragment>
        {/* <ReactTooltip
          id={this.props.id.toString()}
        >{`viaje ${this.props.id}`}</ReactTooltip> */}
        {
          this.getContentByType()
        }
        {!this.state.collapsed &&  (
          <OrderRowDetailTable
            {...this.props}
          />
        )}
      </React.Fragment>
    );
  }
}

export default OrderCatalogRow;

const CompanyAvatar = styled.div`
  display: flex;
  flex-direction: row;
  & img {
    width: 48px;
    min-height: 48px;
    border-radius: 24px;
    margin-right: 6px;
  }
`;

const ActionsContainer = styled.div`
  align-items: center;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  & .btn {
    width: 100px;
    margin: 3px;
  }
`;

const CaretContainer = styled.div`
  align-self: center;
  cursor: pointer;
`;

const Name = styled.div`
  align-self: center;
  width: 100%;
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 22px;
  overflow: hidden; 
  text-overflow: ellipsis;
  max-width: 135px;
  text-align: start;
`;

const Text = styled.div`
  align-self: center;
  width: 100%;

  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 22px;
`;

const TagsContainer = styled(ExceedPlus)`
  justify-content: center;
  width: 100%;
`;
