import styled from "styled-components";

export const TabContainer = styled.div`
  display: flex;
  margin-bottom: 20px;

  & .tab {
    margin-right: 30px;
    font-size: 25px;
    color: white;

    &.selected {
      border-bottom-style: solid;
      border-bottom-color: #2f4ff1;
      border-bottom-width: 4px;
    }

    &:hover {
      cursor: pointer;
      font-size: 25.3px;
    }
  }
`;