import React from "react";
import PropTypes from "prop-types";
import Loader from "../icons/tat/loader";
import ReactTooltip from "react-tooltip";

class CatalogSelectionFooter extends React.Component {
  render() {
    // console.log("1", this.props.actions);
    return (
      <div
        className={"selection-footer".concat(this.props.show ? "" : " hidden")}
      >
        <div className="count-circle">{this.props.selectedElements}</div>
        {
          this.props.hideSelectAll ? '' : <span onClick={this.props.selectAll}>Seleccionar Todos</span>
        }
        <div
          className={"align-right".concat(
            this.props.selectedElements ? "" : " disabled"
          )}
        >
          {this.props.actions.map((action, index) => {
            return (
              <>
                <span 
                  key={`catalog-${action.description}`} 
                  onClick={action.disabled ? () => {} : action.f}
                  style={{opacity: action.disabled ? '0.4' : '1'/* , pointerEvents: action.disabled ? 'none' : 'auto' */}}
                  data-place="top"
                  data-tip
                  data-for={`actions-${index}`}
                >
                  {
                    action.loading 
                    ? <div style={{display: "inline-block", position: "relative", height: "17.5px"}}>
                        <span style={{opacity: "0"}}>{action.description}</span>
                        <Loader className="action-loader" width="25px" height={"25px"} circleColor={"#000"}/>
                      </div>
                    : action.description
                  }
                </span>
                {
                  action.error 
                  ? <ReactTooltip
                      html={true}
                      id={`actions-${index}`}
                    >
                      {action.error}
                    </ReactTooltip>
                  : ''
                }
              </>
            );
          })}
        </div>
      </div>
    );
  }

  static propTypes = {
    show: PropTypes.bool,
    selectedElements: PropTypes.number,
    actions: PropTypes.arrayOf(
      PropTypes.shape({
        description: PropTypes.string,
        f: PropTypes.func
      })
    ),
    selectAll: PropTypes.func
  };
}

export default CatalogSelectionFooter;

CatalogSelectionFooter.defaultProps = {
  show: false,
  selectedElements: 0,
  selectAll: () => {
    console.log("Not yet implemented");
  },
  actions: []
};
