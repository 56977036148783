import styled from "styled-components";

export const MainContent = styled.div`
  padding-top: 20px;
  padding-left: 15%;
  padding-right: 15%;
  /* overflow-x: scroll; */
  /* height: calc(100% - 20px); */
  background-color: #fff;
  background-image: url(/images/img/survey-background.svg);
  background-size: 100%;
  background-repeat: no-repeat;
  background-position-y: bottom;    
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100%;
  box-sizing: border-box;

  @media only screen and (max-width: 769px) {
    padding-left: 5%;
    padding-right: 5%;
  }
`;

export const FieldPage = styled.div`
  font-size: 20px;
  color: transparent;
  background: linear-gradient(-90deg, #2A4397 12%, #3A72ED 35%, #D757D4 66%, #F67554 98%);
  background-clip: text;
`;

export const LogoImageContainer = styled.div`
  flex-grow: 1;
  display: flex;
  padding-bottom: 70px;
  align-items: end;
  padding-top: 20px;

  @media only screen and (max-width: 426px) {
    padding-bottom: 40px;
  }
`;

export const LogoImage = styled.img`
  /* position: absolute; */
  /* bottom: 45px; */
  /* left: 50%; */
  /* transform: translateX(-50%); */
  width: 155px;
`;

export const LongQuestionContainer = styled.div`
  width: 100%;
  height: 45vh;
  overflow: auto;
`;

export const DraggableCardContainer = styled.div`
  display: flex;
  flex-direction: row;
  background-color: #fff;
  align-items: center;
  margin-bottom: 8px;
  opacity: ${({ opacity }) => (opacity)};
  position: relative;
  width: 100%;

  ::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 10px;
    padding: 1px;
    background: linear-gradient(-90deg, rgb(42, 67, 151) 0%, rgb(58, 114, 237) 28%, rgb(215, 87, 212) 59%, rgb(246, 117, 84) 86%);
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    pointer-events: none;
    opacity: 0.2;
  }
`;

export const DraggableCard = styled.div`
  padding: 10px;
  width: 100%;
  /* background-color: #fff; */
  color: #9ba1ac;
`;

export const FieldTitle = styled.div`
  /* color: #9ba1ac; */
  margin-top: ${({ marginTop }) => (marginTop === null || marginTop === undefined ? '30' : marginTop)}px;
  margin-bottom: ${({ marginBottom }) => (marginBottom === null || marginBottom === undefined ? '30' : marginBottom)}px;
  text-align: center;
  color: transparent;
  background: linear-gradient(-90deg, #2A4397 12%, #3A72ED 35%, #D757D4 66%, #F67554 98%);
  background-clip: text;
  font-weight: 700;
  font-size: 30px;

  @media only screen and (max-width: 769px) {
    font-size: 20px;
  }
  @media only screen and (max-width: 426px) {
    font-size: 18px;
  }

  .medium {
    font-size: 20px;

    @media only screen and (max-width: 769px) {
      font-size: 15px;
    }
    @media only screen and (max-width: 426px) {
      font-size: 10px;
    }
  }

  .small {
    font-size: 15px;

    @media only screen and (max-width: 769px) {
      font-size: 12px;
    }
    @media only screen and (max-width: 426px) {
      font-size: 8px;
    }
  }

  .underline {
    text-decoration: underline;
    text-decoration-color: #2A4397;
  }

  .link {
    font-size: ${({ isMobile }) => (isMobile ? '10' : '20')}px;
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const FieldSubtitle = styled.div`
  color: #9ba1ac;
  margin-top: ${({ marginTop }) => (marginTop === null || marginTop === undefined ? '30' : marginTop)}px;
  margin-bottom: ${({ marginBottom }) => (marginBottom === null || marginBottom === undefined ? '30' : marginBottom)}px;
  font-size: ${({ isMobile }) => (isMobile ? '15' : '25')}px;
  display: flex;
  align-items: center;
`;

export const ImgSubtitle = styled.img`
  width: ${({ isMobile }) => (isMobile ? '30' : '60')}px;
  margin-right: 10px;
`;

export const SelectorContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  /* align-items: baseline; */
  /* align-items: center; */
  gap: 30px;    /* width: 100%; */
  flex-wrap: wrap;
  max-height: 40vh;
  overflow: auto;
  width: 100%;
`;

export const SelectorContainerHorizontal = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: baseline;
  flex-direction: column;
`;

export const SelectorBorder = styled.div`
  width: ${({ isMobile }) => (isMobile ? '80' : '130')}px;
  width: 40%;
  max-width: ${({ maxWidth }) => (maxWidth || '150px')};
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  cursor: pointer;
  background: #EFF4FF;
  /* height: 100%; */
  max-height: 212px;
  border-radius: 10px;
  position: relative;
  padding: 10px;
  box-sizing: border-box;

  ::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 10px;
    padding: 1px;
    background: linear-gradient(-90deg, rgb(42, 67, 151) 0%, rgb(58, 114, 237) 28%, rgb(215, 87, 212) 59%, rgb(246, 117, 84) 86%);
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    pointer-events: none;
    opacity: 0.2;
  }
`;

export const SelectorBorderSmall = styled.div`
  width: ${({ isMobile }) => (isMobile ? '50' : '150')}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: ${({ maxWidth }) => (maxWidth || 'none')};
  justify-content: start;
  cursor: pointer;
`;

export const SelectorBorderHorizontalSmall = styled.div`
  width: 100%;
  display: flex;
  /* justify-content: space-evenly; */
  align-items: center;
  cursor: pointer;
  margin-bottom: 10px;
  justify-content: start;
`;

export const SelectorTitle = styled.div`
  color: #9ba1ac;
  text-align: center;
  font-size: 11px;
`;

export const SelectorTitleHorizontal = styled.div`
  color: #9ba1ac;
  text-align: left;
  font-size: 11px;
  padding-left: 8px;
`;

export const SelectorTitleMedium = styled.div`
  color: #9ba1ac;
  text-align: center;
  font-size: 14px;
`;

export const SelectorImgContainer = styled.img`
  width: 80%;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  max-width: 100px;
`;

export const SelectorImgContainerSmall = styled.img`
  width: 15%;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
`;

export const BottomContainer = styled.div`
  /* margin-bottom: 40px; */
  /* padding: 10px; */
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;

export const BottomBlankSpace = styled.div`
  height: ${({ isMobile }) => (isMobile ? '30' : '30')}px;
  width: 100%;
`;

export const ProgressBarContainer = styled.div`
  width: 100%;
  height: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  /* background-color: rgb(46, 59, 82); */
  margin-top: 0px;
  margin-bottom: 10px;
  background: linear-gradient(-90deg, #2A4397 12%, #3A72ED 35%, #D757D4 66%, #F67554 98%);
  display: flex;
  justify-content: end;
`;

export const ProgressBar = styled.div`
  /* background-color: rgb(247, 64, 255); */
  height: 10px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 10px;
  border-top-left-radius: 0px;
  border-top-right-radius: 10px;
  width: ${({ progress_width }) => (100 - progress_width)}%;
  /* background: linear-gradient(-90deg, #2A4397 12%, #3A72ED 35%, #D757D4 66%, #F67554 98%); */
  background: rgb(46, 59, 82);
`;

export const TextArea = styled.textarea`
  resize: none;
  border: 2px solid #EBEEF0;
  border-radius: 8px;
  padding-top: 8px;
  padding-left: 8px;
  width: 100%;
  height: ${({ isMobile }) => (isMobile ? '70' : '100')}px;
`;

export const Separator = styled.div`
  height: 10px;
`;

export const HorizontalSeparator = styled.div`
  width: 10px;
`;

export const RadioItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #9ba1ac;
  font-size: 12px;
`;

export const RadioContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`;

export const ImgQuestion = styled.img`
  width:  ${({ width }) => (width || '35%')};
  @media only screen and (max-width: 426px) {
    width: ${({ mobileWidth }) => (mobileWidth || '15%')};;
  }
`;

export const ImgQuestionMedium = styled.img`
  width: ${({ isMobile }) => (isMobile ? '30' : '10')}%;
`;

export const ImgQuestionSmall = styled.img`
  width: ${({ isMobile }) => (isMobile ? '20' : '10')}%;
`;

export const ImgContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const BackContainer = styled.div`
  position: absolute;
  width: ${({ isMobile }) => (isMobile ? '10' : '15')}%;
  top: 0px;
  left: 0px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const BackButton = styled.div`
  cursor: pointer;
  color: #9ba1ac;
  font-size: ${({ isMobile }) => (isMobile ? '18' : '25')}px;
`;

export const CheckboxContainerLevel1 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .checkbox-container input:checked ~ .checkmark {
    background: linear-gradient(-60deg, #2A4397 0%, #3A72ED 10%, #D757D4 65%, #F67554 100%);
  }
`;

export const CheckboxContainerLevel2 = styled.div`
  width: fit-content;
`;

export const AgentContainer = styled.div`
  /* background-color: rgb(9, 32, 71); */
  background: #EFF4FF;
  width: fit-content;
  color: #9ba1ac;
  padding: 10px;
  font-size: 18px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  position: relative;

  ::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 10px;
    padding: 1px;
    background: linear-gradient(-90deg, rgb(42, 67, 151) 0%, rgb(58, 114, 237) 28%, rgb(215, 87, 212) 59%, rgb(246, 117, 84) 86%);
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    -webkit-mask-composite: exclude;
    mask-composite: exclude;
    pointer-events: none;
    opacity: 0.2;
  }
`;

export const FormContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const InputLabel = styled.label`
  font-size: 16px;
  color: transparent;
  background: linear-gradient(-90deg, #2A4397 12%, #3A72ED 35%, #D757D4 66%, #F67554 98%);
  background-clip: text;
`;

export const InputField = styled.input`
  padding: 15px 16px;
  border-radius: 10px;
  background: #EFF4FF;
  color: #9E9E9E;
  border: none;
  width: 100%;

  :focus-visible {
    outline: none;
  }

  :-internal-autofill-selected {
    background-color: #EFF4FF !important;
    color: #9E9E9E !important;
  }
`;

export const InputFieldBorder = styled.span`
  padding: 1px;
  background: linear-gradient(-90deg, rgb(42 67 151 / 40%) 0%, rgb(58 114 237 / 40%) 28%, rgb(215 87 212 / 40%) 59%, rgb(246 117 84 / 40%) 86%);
  border-radius: 11px;
`;