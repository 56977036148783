import React, { Component } from "react";
import { connect } from "react-redux";
import Modal from "react-modal";
import { SERVER } from "../../redux/api/api-urls";
import CatalogHeader, { LIST } from "../../shared/component/catalog-header";
import FilterBar from "../../shared/component/filter/filter-bar";
import CatalogSelectionFooter from "../../shared/component/catalog-selection-footer";
import RouteRow from "./route-row";
import {
  // searchFleetsByName,
  // setFleetSearchStr,
  postUploadFleets,
} from "../../redux/reducers/fleet-reducer";
import {
  loadSession,
  removeToast,
  validateObjPerms,
} from "../../redux/reducers/global-catalog.reducer";
import Button from "../../shared/controls/button";
import Selector from "../../shared/component/selector";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { normalizeStr } from "../../shared/utils";
import { FILTER_LIST_TYPES } from "../../shared/component/filter/filter-bar-content";
import FileSelector, {
  acceptTypes,
  getFileDescription,
} from "../../shared/controls/file-selector";
import SVGIcon, { icons } from "../../shared/svg-icon";
import Chip from "../../shared/controls/chip";
import {
  readCatalog,
  getEntityItems,
  getEntityArgs,
  readEntities,
  deleteEntity,
  cancelRequest
} from "../../redux/api/";
import { setConfig } from "../../redux/reducers/configuration-reducer";
import PaginatorControlled from "../../shared/controls/paginator-controlled";
import UserChipContainer from "../../shared/component/user-chip-container";
import ConfirmDialog from "../../shared/dialog/confirm-dialog";
import DownloadImage from "../../shared/icons/routes/downloadIcon";
import ReactTooltip from "react-tooltip";
import { DownloadIcon } from "./style";
import { getUrlsEnv, openSecureDoc } from "../../shared/backend-api";
import { setNavbarAction, removeNavbarAction } from "../../redux/reducers/navbar-reducer";
import HelperFilesModal from "../../shared/component/helper_files_modal";

// const defaultPaginatorItems = 10;
// const defaultPaginatorItems = 25;

class RouteCatalogView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewMode: LIST,
      openConfirmDeleteModal: false,
      // filters: filtrosFlotillas,
      selectionModeOn: false,
      selectedItemsIds: [],
      openXlsModal: false,
      openXlsSuccessModal: false,
      openXlsErrorModal: false,

      // resetPaginator: false,

      // filters
      origin: [],
      destination: [],
      tags: [],
      classes: [],
      searchStr: "",
      limit: 10,
      offset: 1,

      // searchText: "",
      xls: null,
      loading: false,
      totalImport: 0,
      correctImport: 0,
      failedImport: 0,
      uploadIdImport: 0,
      companyIdImport: 0,
      descriptionImport: "",

      buttonFilterName: "Aplicar",
    };

    this.openedLink = false;

    this.showToast = (text) => {
      toast.success(
        <div>
          <img
            alt=""
            src="/images/white-check.svg"
            style={{ marginRight: "8px", marginLeft: "4px" }}
          />
          {text}
        </div>,
        {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          className: "my-toast-class",
        }
      );
    };
  }

  filterContents = () => {
    let contents = [];
    contents = [
      {
        title: "Origen",
        items: this.props.originFilter,
        name: "origin",
        listType: FILTER_LIST_TYPES.checkListTextSearchAll,
      },
      {
        title: "Destino",
        items: this.props.destinationFilter,
        name: "destination",
        listType: FILTER_LIST_TYPES.checkListTextSearchAll,
        // listType: FILTER_LIST_TYPES.chips,
      },
      // {
      //   title: "Instalaciones",
      //   items: [], //this.props.tags,
      //   name: "installations",
      //   listType: FILTER_LIST_TYPES.checkList,
      //   // listType: FILTER_LIST_TYPES.chips,
      // },
      {
        title: "Etiquetas",
        items: this.props.tagsFilter,
        name: "tags",
        listType: FILTER_LIST_TYPES.chips,
      },
      {
        title: "Clases",
        items: this.props.classesFilter,
        name: "classes",
        // listType: FILTER_LIST_TYPES.checkList,
        listType: FILTER_LIST_TYPES.checkListTextSearchAll,
      },
    ];
    return contents;
  };

  filtersChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    this.setState({ buttonFilterName: "Aplicar" });
  };

  handleSearch = (e, searchText) => {
    this.setState({ searchStr: normalizeStr(searchText) }, () => {
      this.reloadItems();
      // this.props.loadRoutesCatalog({
      //   company_id: this.props.companyId,
      //   // limit: this.state.limit,
      //   limit: this.state.limit,
      //   offset: this.state.offset,
      //   search: normalizeStr(searchText),
      // });
    });
    // this.props.setSearchStr(searchText);
  };

  // handleFilter = () => {
  //   //   let selectedTags = [...this.selectedTags.values()].toString();
  //   //   let selectedStatus = [...this.selectedStatus.values()].toString();
  //   //   let selectedCompany = [...this.selectedCompany.values()].toString();
  //   //   let selectedType = [...this.selectedType.values()].toString();
  //   //   let selectedGpsStatus = [...this.selectedGpsStatus.values()].toString();
  //   this.setState({ resetPaginator: true });
  //   this.reloadItems();
  // };

  componentDidMount() {
    // this.props.loadRoutesCatalog(this.props.companyId);
    // this.props.loadRoutesCatalog({
    //   company_id: 31,
    // });
    this.setState({ ...this.props.configuration });
    this.props.loadFilters({
      company_id: this.props.companyId,
    });

    this.props.setNavbarAction("routes-catalog", [
      {
        icon: <HelperFilesModal
          files={[]}
        />
      }
    ]);

  }
  componentWillUnmount() {
    let config = this.state;

    if (!this.openedLink) {
      config.searchStr = '';
    }

    this.props.setConfig(config);
    this.openedLink = false;

    this.props.removeNavbarAction("routes-catalog");
  }

  componentDidUpdate() {
    if (this.props.toast) {
      this.props.removeToast();
      this.showToast(this.props.toast.text);
    }
  }

  toggleView = (viewMode) => {
    this.setState({ viewMode: viewMode });
  };
  toggleSelectMode = () => {
    this.setState({
      selectionModeOn: !this.state.selectionModeOn,
      selectedItemsIds: [],
    });
  };

  openConfirmDeleteModal = () => {
    this.setState({ openConfirmDeleteModal: true });
  };

  openXlsModal = () => {
    this.setState({ openXlsModal: true });
  };
  closeXlsModal = () => {
    if (!this.state.loading) {
      this.setState({
        openXlsModal: false,
        xls: null,
        loading: false,
      });
    }
  };

  openXlsSuccessModal = (data) => {
    this.setState({
      openXlsModal: false,
      xls: null,
      loading: false,
      openXlsSuccessModal: true,
      totalImport: data.total,
      correctImport: data.correct,
      failedImport: data.failed,
      uploadIdImport: data.upload_id,
      companyIdImport: data.company_id,
    });
    const win = window.open(
      `${SERVER}/api/v2/companies/${data.company_id}/upload/${data.upload_id}/`,
      "_blank"
    );
    if (win != null) {
      win.focus();
    }
  };

  closeXlsSuccessModal = () => {
    this.setState({
      openXlsSuccessModal: false,
      totalImport: 0,
      correctImport: 0,
      failedImport: 0,
      uploadIdImport: 0,
      companyIdImport: 0,
    });
    this.reloadItems();
  };

  openXlsErrorModal = (data) => {
    let descriptionImport;
    if (Array.from(data).length > 0) {
      descriptionImport = data.description;
    } else {
      descriptionImport = "Ocurrió un error favor de llamar a soporte.";
    }
    this.setState({
      openXlsModal: false,
      xls: null,
      loading: false,
      openXlsErrorModal: true,
      descriptionImport,
    });
  };

  closeXlsErrorModal = () => {
    this.setState({
      openXlsErrorModal: false,
      totalImport: 0,
      correctImport: 0,
      failedImport: 0,
      uploadIdImport: 0,
      companyIdImport: 0,
    });
  };

  // getToolTipContent = (item) => {
  //   let fleetId = this.props.companyFleet[0].id;
  //   const { shared = 0 } = item;
  //   item.content =
  //     (this.props.catalogStatusLog[1] || [])
  //       .concat(this.props.catalogStatusLog[2] || [])
  //       .filter(
  //         (i) =>
  //           i.source.fleet_data_id === item.fleet_data_id &&
  //           (i.source.fleet_id === fleetId || shared < 0)
  //       ) || [];
  // };

  timeFixed = (time, fix = 2) => {
    return time !== 0 ? time.toFixed(fix) : time;
  };

  getOperatorData = (data, childData) => {
    if (childData.tat_2 === 0 && childData.outward_trip_time_two === 0) {
      return [
        {
          tat: this.timeFixed(childData.tat_1),
          originType: childData.from_operation_type,
          origin: this.timeFixed(childData.from_operation_time),
          transit: this.timeFixed(childData.outward_trip_time_one),
          destinationType: childData.to_operation_type,
          destination: this.timeFixed(childData.to_operation_time),
          comeback: this.timeFixed(childData.return_trip_time_one),
        },
      ];
    } else {
      return [
        {
          tat: this.timeFixed(childData.tat_1),
          originType: childData.from_operation_type,
          origin: this.timeFixed(childData.from_operation_time),
          transit: this.timeFixed(childData.outward_trip_time_one),
          destinationType: childData.to_operation_type,
          destination: this.timeFixed(childData.to_operation_time),
          comeback: this.timeFixed(childData.return_trip_time_one),
        },
        {
          tat: this.timeFixed(childData.tat_2),
          originType: childData.from_operation_type,
          origin: this.timeFixed(childData.from_operation_time),
          transit: this.timeFixed(childData.outward_trip_time_two),
          destinationType: childData.to_operation_type,
          destination: this.timeFixed(childData.to_operation_time),
          comeback: this.timeFixed(childData.outward_trip_time_two),
        },
      ];
    }
  };

  getItems = () => {
    let catalog = this.props.routesCatalog;
    // console.log(catalog.length);
    // console.log(catalog);
    // let vI = this.props.vehicleItems.map((item) => {
    //   this.getToolTipContent(item);
    //   return item;
    // });
    // console.log(vI);
    // return vI;
    // return catalog.maps((item) => {
    //   return {
    //     route: item.alias,
    //     code: item.code,
    //   };
    // });
    if (!(catalog || 0).length) {
      return [];
    }
    return catalog.map((item) => {
      // console.log(item.details);
      return {
        id: item.id,
        route: item.description,
        code: item.code,
        alias: item.alias,
        classes: item.details.length,
        tat: `${item.tat_max} HRS`,
        tags: item.tags.map((tag) => {
          return tag.tag;
        }),
        childs: item.details.map((child) => {
          return {
            id: child.id,
            classes: child.transport_class.code,
            distance: child.distancia,
            details: this.getOperatorData(item, child),
            tooltip: child.transport_class.rutas_tipo_vehiculo,
          };
        }),
      };
    });

    // return [
    //   {
    //     route: "Ruta Lechera CDMX",
    //     code: "ABCD12345",
    //     alias: "GDL - CMM - GDL",
    //     classes: "3",
    //     tat: "48 hrs",
    //     tags: [
    //       {
    //         id: 1,
    //         color: "EB496B",
    //         title: "Dedicado",
    //       },
    //     ],
    //     childs: [
    //       {
    //         classes: "ZG",
    //         distance: "380 KM",
    //         details: [
    //           {
    //             tat: "190.3",
    //             originType: 3,
    //             origin: "2.5",
    //             transit: "92.4",
    //             destinationType: 3,
    //             destination: "2.5",
    //             comeback: "92.4",
    //           },
    //           {
    //             tat: "190.3",
    //             originType: 3,
    //             origin: "2.5",
    //             transit: "92.4",
    //             destinationType: 3,
    //             destination: "2.5",
    //             comeback: "92.4",
    //           },
    //         ],
    //       },
    //       {
    //         classes: "X1",
    //         distance: "390 KM",
    //         details: [
    //           {
    //             tat: "190.3",
    //             originType: 3,
    //             origin: "2.5",
    //             transit: "92.4",
    //             destinationType: 3,
    //             destination: "2.5",
    //             comeback: "92.4",
    //           },
    //         ],
    //       },
    //       {
    //         classes: "ZF",
    //         distance: "410 KM",
    //         details: [
    //           {
    //             tat: "190.3",
    //             originType: 3,
    //             origin: "2.5",
    //             transit: "92.4",
    //             destinationType: 3,
    //             destination: "2.5",
    //             comeback: "92.4",
    //           },
    //         ],
    //       },
    //     ],
    //   },
    //   {
    //     route: "Ruta Lechera CDMX",
    //     code: "ABCD12345",
    //     alias: "GDL - CMM - GDL",
    //     classes: "3",
    //     tat: "48 hrs",
    //     tags: [
    //       {
    //         id: 1,
    //         color: "EB496B",
    //         title: "Dedicado",
    //       },
    //     ],
    //     childs: [
    //       {
    //         classes: "ZG",
    //         distance: "380 KM",
    //         details: [
    //           {
    //             tat: "190.3",
    //             originType: 3,
    //             origin: "2.5",
    //             transit: "92.4",
    //             destinationType: 3,
    //             destination: "2.5",
    //             comeback: "92.4",
    //           },
    //           {
    //             tat: "190.3",
    //             originType: 3,
    //             origin: "2.5",
    //             transit: "92.4",
    //             destinationType: 3,
    //             destination: "2.5",
    //             comeback: "92.4",
    //           },
    //         ],
    //       },
    //       {
    //         classes: "X1",
    //         distance: "390 KM",
    //         details: [
    //           {
    //             tat: "190.3",
    //             originType: 3,
    //             origin: "2.5",
    //             transit: "92.4",
    //             destinationType: 3,
    //             destination: "2.5",
    //             comeback: "92.4",
    //           },
    //         ],
    //       },
    //       {
    //         classes: "ZF",
    //         distance: "410 KM",
    //         details: [
    //           {
    //             tat: "190.3",
    //             originType: 3,
    //             origin: "2.5",
    //             transit: "92.4",
    //             destinationType: 3,
    //             destination: "2.5",
    //             comeback: "92.4",
    //           },
    //         ],
    //       },
    //     ],
    //   },
    //   {
    //     route: "Ruta Lechera CDMX",
    //     code: "ABCD12345",
    //     alias: "GDL - CMM - GDL",
    //     classes: "3",
    //     tat: "48 hrs",
    //     tags: [
    //       {
    //         id: 1,
    //         color: "EB496B",
    //         title: "Dedicado",
    //       },
    //     ],
    //     childs: [
    //       {
    //         classes: "ZG",
    //         distance: "380 KM",
    //         details: [
    //           {
    //             tat: "190.3",
    //             originType: 3,
    //             origin: "2.5",
    //             transit: "92.4",
    //             destinationType: 3,
    //             destination: "2.5",
    //             comeback: "92.4",
    //           },
    //           {
    //             tat: "190.3",
    //             originType: 3,
    //             origin: "2.5",
    //             transit: "92.4",
    //             destinationType: 3,
    //             destination: "2.5",
    //             comeback: "92.4",
    //           },
    //         ],
    //       },
    //       {
    //         classes: "X1",
    //         distance: "390 KM",
    //         details: [
    //           {
    //             tat: "190.3",
    //             originType: 3,
    //             origin: "2.5",
    //             transit: "92.4",
    //             destinationType: 3,
    //             destination: "2.5",
    //             comeback: "92.4",
    //           },
    //         ],
    //       },
    //       {
    //         classes: "ZF",
    //         distance: "410 KM",
    //         details: [
    //           {
    //             tat: "190.3",
    //             originType: 3,
    //             origin: "2.5",
    //             transit: "92.4",
    //             destinationType: 3,
    //             destination: "2.5",
    //             comeback: "92.4",
    //           },
    //         ],
    //       },
    //     ],
    //   },
    // ];
  };

  redirectToProfile = (routeItemId) => {
    this.openedLink = true;
    this.props.history.push(`/company/routes/route/details/${routeItemId.id}`);
    // if (!this.state.selectionModeOn) {
    //   //     this.props.history.push(
    //   //       `/company/fleet/vehicle/profile/${
    //   //         fleetItemId.fleet_data_id
    //   //       }/?isShared=${
    //   //         String(fleetItemId.shared) === "-1"
    //   //           ? `1&fleets=${fleetItemId.fleetId}`
    //   //           : 0
    //   //       }`
    //   //     );
    //   //     this.props.history.push(
    //   //       `/company/fleet/equipment/profile/${
    //   //         fleetItemId.fleet_data_id
    //   //       }/?isShared=${
    //   //         String(fleetItemId.shared) === "-1"
    //   //           ? `1&fleets=${fleetItemId.fleetId}`
    //   //           : 0
    //   //       }`
    //   //     );
    //   //     this.props.history.push(
    //   //       `/company/fleet/operator/profile/${
    //   //         fleetItemId.fleet_data_id
    //   //       }/?isShared=${
    //   //         String(fleetItemId.shared) === "-1"
    //   //           ? `1&fleets=${fleetItemId.fleetId}`
    //   //           : 0
    //   //       }`
    //   //     );
    // }
  };

  selectItem = (e) => {
    console.log(e.target.name);
    let selectedItemsIds = this.state.selectedItemsIds;
    if (e.target.value) {
      selectedItemsIds.push(e.target.name);
    } else {
      selectedItemsIds.splice(
        selectedItemsIds.findIndex((f) => f === e.target.name),
        1
      );
    }
    this.setState({ selectedItemsIds: selectedItemsIds });
  };

  selectAllItems = () => {
    if (this.getItems().length === this.state.selectedItemsIds.length) {
      this.setState({ selectedItemsIds: [] });
    } else {
      let selectedItemsIds = this.getItems().map((item) => {
        // selectedItemsIds.push(item.id);
        return item.id;
      });
      this.setState({ selectedItemsIds: selectedItemsIds });
    }
  };

  createRouteItem = () => {
    this.props.history.push("/company/routes/create");
  };

  reloadItems = () => {
    // let filters = {
    //   tags: this.state.tags,
    //   text: this.state.searchStr,
    // };
    this.props.cancelRequest("COMPANIES.ROUTES");

    this.props.loadRoutesCatalog({
      company_id: this.props.companyId,
      limit: this.state.limit,
      offset: this.state.offset,
      from_facility: this.state.origin.join(","),
      to_facility: this.state.destination.join(","),
      tags: this.state.tags.join(","),
      classes: this.state.classes.join(","),
      search: this.state.searchStr,
    });
  };

  getTotalElements = () => {
    return (this.props.routesCatalogState || 0).count || 0;
  };

  validShow = (perm) => {
    let response = this.props.validateObjPerms([perm], {
      display: true,
    });
    if (Object.keys(response).length) {
      return true;
    }
    return false;
  };

  handleAddDocument = (e) => {
    this.setState({ xls: e.target.value });
  };

  handleRemoveDocument = () => {
    if (!this.state.loading) {
      this.setState({ xls: null });
    }
  };

  importXls = () => {
    this.setState({ loading: true });
    postUploadFleets(this.state.xls, 4)
      .then((data) => {
        if (data.status !== "error") {
          this.openXlsSuccessModal(data);
        } else {
          this.openXlsErrorModal(data);
        }
      })
      .catch((error) => {
        this.openXlsErrorModal(error);
      });
  };

  handleFilter = () => {
    if(this.state.buttonFilterName === "Aplicar"){
      this.setState({ buttonFilterName: "Restablecer" }, () => this.reloadItems() );
    } else {
      this.setState({ buttonFilterName: "Aplicar" }, () => this.handlerClean() );
    }
  };

  handlerClean = () => {
    this.setState({
      origin: [],
      destination: [],
      tags: [],
      classes: [],
    }, () => this.reloadItems() );
  }

  selectRoute = (id, selected) => {
    let selectedItemsIds = this.state.selectedItemsIds;
    if (selected) {
      selectedItemsIds.push(id);
    } else {
      selectedItemsIds.splice(
        selectedItemsIds.findIndex((f) => f === id),
        1
      );
    }
    this.setState({ selectedItemsIds: selectedItemsIds });
  };

  deleteRoute = () => {
    // debugger
    this.state.selectedItemsIds.forEach((item) => {
      this.props.deleteRoute(
        {
          company_id: this.props.companyId,
          route_id: item,
        },
        {
          onSuccess: () => {
            this.setState(
              {
                openConfirmDeleteModal: false,
                selectedItemsIds: [],
                selectionModeOn: false,
              },
              () => {
                this.reloadItems();
              }
            );
          },
        }
      );
    });
  };

  openDocument = (url) => {
    openSecureDoc(getUrlsEnv().files.concat(url)).then((response) => {
      var array = url.split("/");
      var lastsegment = array.length > 0 ? array[array.length - 1] : ".";

      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);

        let a = document.createElement("a");
        a.href = url;
        a.download = lastsegment;
        a.click();
      });
    });
  };

  render() {
    return (
      <div className="route-catalog-view catalog view">
        <ToastContainer className="global-toast-container" />
        {/* <ValidatePerms
          perms={[
            {
              id: "35",
              perm: "c",
            },
          ]}
          optionalElement={
            <CatalogHeader
              title="Flotillas"
              viewMode={this.state.viewMode}
              toggleView={this.toggleView}
              showViewMode={false}
            />
          }
        > */}
        <CatalogHeader
          title="Rutas"
          viewMode={this.state.viewMode}
          toggleView={this.toggleView}
          actionButtons={[
            {
              text: "Subir xls",
              onClick: this.openXlsModal,
              type: "primary outline",
            },
            {
              text: "Crear ruta",
              onClick: this.createRouteItem,
              type: "primary fit-width",
            },
          ]}
          showViewMode={false}
        >
          <DownloadIcon data-tip data-for={"download-icon"}>
            <DownloadImage
              fill={"#FFFFFF"}
              width={"50px"}
              height={"50px"}
              onClick={() =>
                this.openDocument("media/files/Cargador_Rutas_V1808.xlsx")
              }
            />
          </DownloadIcon>
          <ReactTooltip
            id={"download-icon"}
            delayHide={50}
            delayShow={50}
            delayUpdate={200}
            place={"bottom"}
            border={true}
            type={"light"}
            effect="solid"
          >
            Descargar template xlsx
          </ReactTooltip>
        </CatalogHeader>
        {/* </ValidatePerms> */}
        <FilterBar
          searchAction={this.handleSearch}
          filterAction={this.handleFilter}
          searchStr={this.state.searchStr}
          onChange={this.filtersChange}
          searchButtonText={this.state.buttonFilterName}
          content={this.filterContents()}
          selectedItems={{
            origin: this.state.origin,
            destination: this.state.destination,
            tags: this.state.tags,
            classes: this.state.classes,
          }}
        />
        <div className={"content ".concat(this.state.viewMode)}>
          <div className="row route-row-header">
            <div className="route">Ruta</div>
            <div className="code">Codigo</div>
            <div className="alias">Alias</div>
            <div className="classes">Clases</div>
            <div className="tags">Etiquetas</div>
            <div className="tat" style={{ width: "10%" }}>
              TAT Máx.
            </div>
            <div className="caret"></div>
          </div>
          <Selector
            selectedItemClass="selected"
            selectionModeClass="selection"
            selectedItems={this.state.selectedItemsIds}
            onSelect={this.selectItem}
            selectionModeOn={this.state.selectionModeOn}
            toggleSelectionMode={this.toggleSelectMode}
            showSelector={true
              // this.validShow({
              //   id: "35",
              //   perm: "u",
              // })
            }
          >
            {this.getItems().map((routeItem) => {
              return (
                <RouteRow
                  key={routeItem.id}
                  id={routeItem.id}
                  itemType={this.state.routeTypeItem}
                  itemInfo={routeItem}
                  // selectionModeOn={this.state.selectionModeOn}
                  isSelected={this.state.selectedItemsIds.includes(
                    routeItem.id
                  )}
                  selectItem={this.selectItem}
                  clickHandler={this.redirectToProfile}
                  // onClick={() => this.redirectToProfile(routeItem)}
                />
              );
            })}
          </Selector>
          <PaginatorControlled
            itemCount={this.getTotalElements()}
            onChange={(e) =>
              this.setState(
                { [e.target.name]: e.target.value },
                this.reloadItems
              )
            }
            // resetPaginator={this.state.resetPaginator}
            // onReset={() => {
            //   this.setState({ resetPaginator: false });
            // }}
            limit={this.state.limit}
            offset={this.state.offset}
          />
          <CatalogSelectionFooter
            show={this.state.selectionModeOn}
            selectedElements={this.state.selectedItemsIds.length}
            selectAll={this.selectAllItems}
            actions={[
              // this.props.validateObjPerms(
              //   [
              //     {
              //       id: "35",
              //       perm: "d",
              //     },
              //   ],
                {
                  description: "Eliminar",
                  f: this.openConfirmDeleteModal,
                }
              // ),
            ]}
          />
        </div>
        <Modal
          isOpen={this.state.openXlsModal}
          portalClassName="dialog confirm-dialog"
          overlayClassName="overlay"
          className="content invite-operator"
        >
          <div className="title">Agregar archivos</div>
          <div className="close" onClick={this.closeXlsModal}></div>
          <div className="message">
            <div className="attach-xls">
              <div>
                <FileSelector
                  name={"xls"}
                  acceptTypes={[acceptTypes.xls]}
                  onChange={this.handleAddDocument}
                  allowMultiple={false}
                >
                  <Button
                    settings={{
                      type: "primary small",
                      text: "Subir archivo",
                      prefix: {
                        element: (
                          <SVGIcon
                            name={icons.uploadFile}
                            width="24px"
                            height="24px"
                            viewBox="0 0 24 24"
                          />
                        ),
                      },
                    }}
                  />
                </FileSelector>
              </div>
              <div>
                {(this.state.xls || []).map((f, i) => (
                  <Chip
                    key={i}
                    id={i}
                    active={true}
                    className="medium"
                    name="files"
                    description={getFileDescription(f, 25)}
                    onClick={() => {}}
                    prefix={{
                      element: (
                        <SVGIcon
                          name={icons.imageFile}
                          fill="#657795"
                          viewBox="0 -1 16 16"
                        />
                      ),
                    }}
                    suffix={{
                      element: (
                        <div
                          onClick={() => this.handleRemoveDocument("xls", f)}
                        >
                          {" "}
                          <SVGIcon
                            name={icons.cross}
                            fill="#A7B6D6"
                            viewBox="0 3 16 16"
                          />
                        </div>
                      ),
                    }}
                  />
                ))}
              </div>
            </div>
          </div>
          <div className="actions">
            <Button
              settings={{
                text: "Cancelar",
                type: "primary outline",
                onClick: this.closeXlsModal,
              }}
            />
            <Button
              settings={{
                text: this.state.loading ? "Cargando..." : "Cargar",
                disabled:
                  this.state.xls === null || this.state.loading ? true : false,
                onClick: () => {
                  this.importXls();
                },
              }}
            />
          </div>
        </Modal>
        <Modal
          isOpen={this.state.openXlsSuccessModal}
          portalClassName="dialog confirm-dialog"
          overlayClassName="overlay"
          className="content invite-operator successModal"
        >
          <div className="title">Carga finalizada</div>
          <div className="close" onClick={this.closeXlsSuccessModal}></div>
          <div className="message">
            <div className="successContent">
              <div className="text-group">
                <div class="image">
                  <img alt="" src="/images/oval green.svg"></img>
                </div>
                <div className="text">
                  {this.state.correctImport} de {this.state.totalImport}{" "}
                  Registros cargados éxitosamente.
                </div>
              </div>

              <div className="text-group">
                <div class="image">
                  <img alt="" src="/images/oval red.svg"></img>
                </div>
                <div className="text">
                  {this.state.failedImport} de {this.state.totalImport}{" "}
                  Registros rechazados.
                </div>
              </div>

              <div className="errorsDetails"></div>

              <div className="export">
                Para ver más detalles ver el{" "}
                <a
                  href={`${SERVER}/api/v2/companies/${this.state.companyIdImport}/upload/${this.state.uploadIdImport}/`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  descargable
                </a>
              </div>
            </div>
          </div>
          <div className="actions successActions">
            <Button
              settings={{
                text: "Aceptar",
                onClick: this.closeXlsSuccessModal,
              }}
            />
          </div>
        </Modal>
        <Modal
          isOpen={this.state.openXlsErrorModal}
          portalClassName="dialog confirm-dialog"
          overlayClassName="overlay"
          className="content invite-operator successModal"
        >
          <div className="title">Error en archivo</div>
          <div className="close" onClick={this.closeXlsErrorModal}></div>
          <div className="message">{this.state.descriptionImport}</div>
          <div className="actions successActions">
            <Button
              settings={{
                text: "Aceptar",
                onClick: this.closeXlsErrorModal,
              }}
            />
          </div>
        </Modal>
        <ConfirmDialog
          open={this.state.openConfirmDeleteModal}
          title="¿Eliminar ruta?"
          message={"¿Estás seguro que deseas eliminar esta ruta?"}
          contentObject={
            <UserChipContainer
              deleteTag={(id) => this.selectRoute(id, false)}
              items={
                !Array.isArray(this.props.routesCatalog)
                  ? []
                  : this.props.routesCatalog
                      .filter((f) => this.state.selectedItemsIds.includes(f.id))
                      .map((f) => {
                        return {
                          avatar: "hide",
                          name: `${f.code} ${f.description}`,
                        };
                      })
              }
            />
          }
          acceptText="Sí, deseo eliminarla"
          class="delete-facility"
          showCancel={true}
          cancelText="Cancelar"
          cancelButtonType="secondary"
          closeAction={() => this.setState({ openConfirmDeleteModal: false })}
          acceptAction={this.deleteRoute}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const routesCatalogArgs = getEntityArgs(state, "COMPANIES.ROUTES");
  const routesCatalog = getEntityItems(state, "COMPANIES.ROUTES");
  const routesCatalogState = state.api["COMPANIES.ROUTES"];
  const routesFilters = getEntityItems(state, "COMPANIES.ROUTES.FILTERS");
  let originFilter = [];
  let destinationFilter = [];
  let classesFilter = [];
  if (routesFilters.origin) {
    originFilter = Object.keys(routesFilters.origin).map(function(item, key) {
      return {
        id: key + 1,
        description: item,
        items: routesFilters.origin[item].map((item) => {
          return {
            id: item.id,
            description: `${item.name} ${item.alias} ${item.code}`,
          };
        }),
      };
    });
  }
  if (routesFilters.origin) {
    destinationFilter = Object.keys(routesFilters.destination).map(function(
      item,
      key
    ) {
      return {
        id: key + 1,
        description: item,
        items: routesFilters.destination[item].map((item) => {
          return {
            id: item.id,
            description: `${item.name} ${item.alias} ${item.code}`,
          };
        }),
      };
    });
  }

  const tagsFilter = (routesFilters.tags || []).map((item) => ({
    id: item.id,
    title: item.title,
    color: item.color,
  }));

  if (routesFilters.classes) {
    classesFilter = Object.keys(routesFilters.classes).map(function(item, key) {
      return {
        id: key + 1,
        description: item,
        items: routesFilters.classes[item].map((item, key) => {
          let data = Object.entries(item)[0];
          return {
            id: data[0],
            description: data[1],
          };
        }),
      };
    });
  }

  //   {
  //     "id": 49,
  //     "title": "Etiqueta 10",
  //     "color": "22B6A9"
  // },

  // {"id":139,"title":"drv 122","color":"79A3FF","type":2,"company":31}

  // filtrosFlotillas[0].data = tags.map(t => ({ id: t.id, description: t.title, items: [], active: false, status: 'full' }));
  // // filtrosFlotillas[1].data = vehicleNormativity.map(t => ({ id: t.id, description: t.description, items: [], active: false }));
  // filtrosFlotillas[1].data = normativityStatus.map(t => ({ id: t.id, description: t.description, items: [], active: false }));
  // filtrosFlotillas[2].data = fleetProvider.map(t => ({ id: t.company, description: t.company_name, items: [], active: false }));
  // filtrosFlotillas[3].data = vehicleType;
  // console.log("3", state.fleetReducer);
  const configuration = state.configuration.RoutesCatalog;
  return {
    configuration,
    routesCatalog,
    routesCatalogArgs,
    routesCatalogState,
    originFilter,
    destinationFilter,
    tagsFilter,
    classesFilter,
    // filters
    searchStr: state.fleetReducer.searchStr,
    toast: state.globalCatalog.toast,
    companyId: state.globalCatalog.session.company.id,
    companyType: state.globalCatalog.session.company.type.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  const loadRoutesCatalog = (params = {}, opts = {}) =>
    dispatch(
      readCatalog(
        "COMPANIES.ROUTES",
        {
          ...params,
          view: "catalog",
        },
        { args: { ...params }, ...opts }
      )
    );

  const deleteRoute = (params = {}, opts = {}) =>
    dispatch(
      deleteEntity(
        "COMPANIES.ROUTES",
        {
          ...params,
        },
        { args: { ...params }, ...opts }
      )
    );

  const loadFilters = (params = {}, opts = {}) =>
    dispatch(
      readEntities(
        "COMPANIES.ROUTES.FILTERS",
        {
          ...params,
          view: "filters",
          group: "tags",
        },
        { args: { ...params }, ...opts }
      )
    );

  dispatch(loadSession());

  return {
    setConfig: (config) => dispatch(setConfig("RoutesCatalog", config)),
    loadRoutesCatalog,
    deleteRoute,
    loadFilters,
    // filterByName: (name) => dispatch(searchFleetsByName(name)),
    // setSearchStr: (searchStr) => dispatch(setFleetSearchStr(searchStr)),
    removeToast: () => dispatch(removeToast()),
    validateObjPerms: (perms, obj) => validateObjPerms(perms, obj),
    setNavbarAction: (name, config) => dispatch(setNavbarAction(name, config)),
    removeNavbarAction: (name) => dispatch(removeNavbarAction(name)),
    cancelRequest: (entityPath) => dispatch(cancelRequest(entityPath)),
  };
};

const connectToStore = connect(mapStateToProps, mapDispatchToProps);

export default connectToStore(RouteCatalogView);

// FleetCatalogView.defaultProps = {}
