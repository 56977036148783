import React from "react";
import LoginDialog from "../shared/dialog/login-modal";
import RegisterDialog from "../shared/dialog/register-modal";
import ResetNewPasswordDialog from "../shared/dialog/reset-new-password-modal";
import ResetPasswordMessageDialog from "../shared/dialog/reset-password-message-modal";
import RecoverPasswordDialog from "../shared/dialog/recover-password-modal";
import GenericDialog from "../shared/dialog/generic-modal";
import ErrorDialog from "../shared/dialog/error-dialog";
import LoginErrorDialog from "../shared/dialog/login-error-modal";
// import { IcoIsoOrbiColor } from "./components/IcoIsoOrbiColor";
// import { IconHistorial } from "./components/IconHistorial";
// import { IconFcl1 } from "./components/IconFcl1";
import { BACKEND, getApiUrlWithoutAuthorization } from "../shared/backend-api";
import { tranformSpecialCharacterUrl } from "../shared/utils";
import "../styles/landingStyle.css"
import LoginDetailModal from "../shared/dialog/login-detail-modal";
import { Link } from "react-router-dom";
import { CarouselProvider, Slider, Slide, DotGroup, ButtonBack, ButtonNext } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";

class Login extends React.Component {

  constructor(props) {
    super(props);

    this.pricingRef = React.createRef();
    this.mainPage = React.createRef();

    this.state = {
      showLoginModal: false,
      showRegisterModal: false,
      showRecoverPasswordModal: false,
      showRecoverMessageModal: false,
      showRegisterMessageModal: false,
      showResetNewPasswordModal: false,
      showResetNewPasswordMessage: false,
      showSuccessValidation: false,
      showSuccessInvitation: false,
      showRegisterInvitationMessageModal: false,
      showInvitationModal: false,
      showSessionError: false,
      emailInvitation: this.getEmailInvitation(props),
      hasInvitation: false,
      errorMessage: "",
      emailRegister: "",

      currentSlide: 0,

      mode: 1,
      pricingMode: 1,
      showViewDetail: false,
      detailTitle: '',
      detailMessage: '',
      detailImg: '',
      showMobileMenu: false,
      benefitsSlides: 3,
      brandsSlides: 3,
      screenWidth: 0,
      isMobileDevice: false,
      scrollBrandsCarousel: false
    };
  }



  handleResize = () => {
    const screenWidth = window.innerWidth;
    this.setState({ screenWidth });

    if (screenWidth <= 425) {
      this.setState({ benefitsSlides: 1, brandsSlides: 2   });
    } else if (screenWidth <= 600) {
      this.setState({ benefitsSlides: 1, brandsSlides: 2  });
    } else if (screenWidth <= 704) {
      this.setState({ benefitsSlides: 1, brandsSlides: 2  });
    } else if (screenWidth <= 768) {
      this.setState({ benefitsSlides: 2, brandsSlides: 3 });
    } else if (screenWidth <= 1024) {
      this.setState({ benefitsSlides: 2, brandsSlides: 4 });
    } else if (screenWidth <= 1056) {
      this.setState({ benefitsSlides: 2, brandsSlides: 4 });
    } else if (screenWidth <= 1408) {
      this.setState({ benefitsSlides: 3, brandsSlides: 4 });
    } else if (screenWidth <= 1440) {
      this.setState({ benefitsSlides: 3, brandsSlides: 5 });
    } else if (screenWidth <= 1761) {
      this.setState({ benefitsSlides: 4, brandsSlides: 5 });
    } else if (screenWidth <= 2113) {
      this.setState({ benefitsSlides: 5, brandsSlides: 5 });
    } else {
      this.setState({ benefitsSlides: 6, brandsSlides: 5  });
    }
  }
  
  detectMobile = () => {
    let navegador = navigator.userAgent;
    if (navegador.match(/Android/i) || navigator.userAgent.match(/webOS/i) || navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPod/i) || navigator.userAgent.match(/BlackBerry/i) || navigator.userAgent.match(/Windows Phone/i)) {
        this.setState({ isMobileDevice: true });
    } else {
        this.setState({ isMobileDevice: false });
    }
  }

  componentDidMount() {
    let self = this;

    const {
      location: { search = "" },
    } = this.props;

    this.handleResize();

    this.detectMobile();

    window.addEventListener("resize", this.handleResize);

    var observer = new IntersectionObserver(function(entries) {
      if(entries[0].isIntersecting === true){
        self.setState({scrollBrandsCarousel: true});
      } else {
        self.setState({scrollBrandsCarousel: false});
      }
    }, { threshold: [1] });
    
    observer.observe(document.querySelector("#brandsCarousel"));

    if (search !== "" && search.length > 1) {
      if (search.includes("valid_email")) {
        const result = search.split("=");

        if (result.length > 1 && String(result[1]) === "success") {
          this.setState({
            showSuccessValidation: true,
          });
        } else if (result.length > 1 && String(result[1]) === "info") {
          this.setState({
            errorMessage: "Tu cuenta ya fue validada previamente",
          });
        } else {
          this.setState({
            errorMessage:
              "Ocurrió un error al validar su email, intentalo más tarde",
          });
        }
      } else if (search.includes("invitation")) {
        const result = search.split("=");

        if (result.length > 1 && String(result[1]) === "success") {
          this.setState({
            showSuccessInvitation: true,
          });
        } else if (result.length > 1 && String(result[1]) === "error") {
          this.setState({
            errorMessage:
              "Ocurrió un error al aceptar la invitación, intentalo más tarde",
          });
        } else {
          this.setState({
            errorMessage: decodeURI(String(result[1])),
          });
        }
      } else if (search.includes("email")) {
        const result = search.split("=");

        if (result.length > 1) {
          let email = result[1];
          getApiUrlWithoutAuthorization(BACKEND.USERS.SIGN_UP.CHECK_EMAIL, {
            email: tranformSpecialCharacterUrl(email)
          })
            .then((response) => response.json())
            .then((response) => {
              if (response.response === "ok") {
                this.setState(
                  {
                    hasInvitation: true,
                    showRegisterModal: true,
                  },
                  () => this.setState({ emailInvitation: String(result[1]) })
                );
              }
            })
            .catch((error) =>
              error.then((e) => {
                if (e.response === "duplicated") {
                  this.setState({
                    showInvitationModal: true,
                  });
                } else {
                  this.setState({
                    errorMessage:
                      "Ocurrió un error al validar su email, intentalo más tarde",
                  });
                }
              })
            );
        } else {
          this.setState({
            errorMessage:
              "Ocurrió un error al validar su email, intentalo más tarde",
          });
        }
      } else if (search.includes("register")){

        const result = search.split("user=");

        if (result.length > 1) {

          let email = result[1];
          getApiUrlWithoutAuthorization(BACKEND.USERS.SIGN_UP.CHECK_EMAIL, {
            email: tranformSpecialCharacterUrl(email)
          })
            .then((response) => response.json())
            .then((response) => {
              if (response.response === "ok") {
                this.setState(
                  {
                    hasInvitation: true,
                    showRegisterModal: true
                  },
                  () => this.setState({ emailRegister: String(result[1]) })
                );
              }
            })
            .catch((error) =>
              error.then((e) => {
                if (e.response === "duplicated") {
                  this.setState({
                    showInvitationModal: true,
                  });
                } else {
                  this.setState({
                    errorMessage:
                      "Ocurrió un error al validar su email, intentalo más tarde",
                  });
                }
              })
            );
        } else {
          this.setState({
            hasInvitation: true,
            showRegisterModal: true
          });
        }
      } else if (search.includes("expiration")) {
        this.setState({
          showSessionError: true,
        });
      } else if (search.includes("view")){
        if(search.includes("co")){
          this.setState({mode: 2});
        } else if(search.includes("lt")){
          this.setState({mode: 3});
        }
      } else {
        const token = search.slice(3);

        this.setState({
          showResetNewPasswordModal: true,
          token,
        });
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  getEmailInvitation = (props) => {
    const {
      location: { search = "" },
    } = props;

    if (search !== "" && search.length > 1 && search.includes("email")) {
      const result = search.split("=");

      if (result.length > 1) {
        return String(result[1]);
      } else {
        return "";
      }
    }

    return "";
  };

  handleEnd = () => {
    const { currentSlide } = this.slider.context.state;
    this.setState({ currentSlide });
  };

  handleModeChange = (e) => {
    this.setState({ mode: e });
  };

  closeModals = () =>
    this.setState({
      showLoginModal: false,
      showRegisterModal: false,
      showRecoverPasswordModal: false,
      showRecoverMessageModal: false,
      showRegisterMessageModal: false,
      errorMessage: "",
      showResetNewPasswordModal: false,
      showResetNewPasswordMessage: false,
      showSuccessValidation: false,
      showSuccessInvitation: false,
      showRegisterInvitationMessageModal: false,
      showSessionError: false,
    });

  forgotModal = (value) => {
    this.setState({
      showLoginModal: false,
      showRecoverPasswordModal: value,
    });
  };

  changeToLogin = () => {
    this.setState({
      showRegisterModal: false,
      showLoginModal: true,
    });
  };

  toggleMobileMenu = () => {
    this.setState({
      showMobileMenu: !this.state.showMobileMenu
    });
  };

  getBrands = () => {

    const logos = [
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/GrupoModelo_MX.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/ALDAFAMX.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/AtPilot.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/Zemog.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/Transportes_Baez.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/TMC.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/MURILLO.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/CODA.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/ABC.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/KUGAR.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/Trans_EduardoA.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/COSTA_VERDE.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/Lym_Logistics.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/ACUNA.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/TRANSHER.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/jlpm0566.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/Mexamerik.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/Maverick.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/Kargofer.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/ARLEQUIN.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/SINTRA.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/Garcias_Trucking.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/ESMACADA.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/Torta.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/ARCACS.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/TRANSPOVER_SA_DE_CV.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/TML.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/TRANSPORTES_DEGAM.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/VEAR.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/TLDC.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/Perla.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/Veta2020.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/ALCA.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/Trebol.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/atb.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/TICARSA.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/KLS.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/AGUILERA.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/TRAILMEXA.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/traficovrv.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/PEX_LOGISTICA.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/LOGICSA.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/segovias.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/VILCHIS.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/SEVA.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/CarMavi.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/TRANSPORTES_ACOSTA.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/RAEL.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/LitransporteSA.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/TITSA.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/Mi_oste.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/servicioaclientetle.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/TRANSPORTES_D_LOPEZ.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/84Trainsa.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/FLETES2HERMANOS.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/AUTO_TRANSPORTESLA.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/TELFRA.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/GRUPO_D_E_H_.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/TRASMISA.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/Level_One.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/IMEX_CP.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/RO-K.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/NARCEA_ENLACES.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/ASJ_MERIDA.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/PORTE.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/PEXLOG.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/Robinson.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/FRETHUNTERS.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/PROPANE.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/GARBE.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/Transportes_Garcia.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/Transportes_AFA.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/TTELOGISTICA.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/Kemblin.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/10113236.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/TRANS_GARCIAS.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/T_RAMOS.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/MHZ.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/TECOVE_LOGISTIC.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/TRANSPORTES_TLR.jpg"
    ]

    let result = [];
    let logoI = 0;
    for(let i = 1; i <= logos.length; i++) {
      result.push({
        id: i, 
        url: logos[logoI++]
      });
      if(logoI === logos.length) {
        logoI = 0;
      }
    }
    return result
  }

  getHeroSectionImage = (index) => {
    let mainImages = [
      "/images/img/landing1.jpg",
      "/images/img/landing2.jpg",
      "/images/img/landing3.jpg",
    ]
    let COImages = [
      "/images/img/CO1.jpg",
      "/images/img/CO2.jpg",
      "/images/img/CO3.jpg",
    ]
    let TRImages = [
      "/images/img/TR1.jpg",
      "/images/img/TR2.jpg",
      "/images/img/TR3.jpg",
    ]

    switch(this.state.mode){
      case 1:
        return mainImages[index];
      case 2:
        return COImages[index]; 
      case 3:  
        return TRImages[index];
      default:
        return mainImages[index];
    }
  }

  render() {
    return (
    <div className="landingpage" ref={this.mainPage} onClick={() => {this.setState({showMobileMenu: false})}}>
      <div className={"group"+(this.state.mode === 1 ? '' : ' white')+(this.state.mode !== 3 ? '' : ' TL')}>
        <div className="overlap">
          {
            this.state.mode === 1 ? (
              <div className="frame">
                <div className="div">
                  <img
                    className="subtract"
                    alt="Subtract"
                    src="/images/img/subtract.svg"
                  />
    
                  <img
                    className="mask-group"
                    alt="Mask group"
                    src="/images/img/mask-group.png"
                  />
                </div>
              </div>
            ) : (
              <div className="frame-0">
              </div>
            )
          }

          <div className="group-2">

            <div className="group-3">
            <div className={`frame-4${ [2,3].includes(this.state.mode) ? ' background' : '' }`}>
                <img
                  className="ico-logo-orbi-color"
                  alt="Ico logo orbi color"
                  src={`/images/img/${this.state.mode === 1 ? 'ico-logo-orbi-color' : 'logo-orbi-blanco'}.svg`}
                  onClick={() => {this.mainPage.current.scrollIntoView();this.handleModeChange(1)}}
                />
                <img
                  className="ico-logo-orbi-color-mobile"
                  alt="Ico logo orbi color"
                  src={`/images/img/${this.state.mode === 1 ? 'ico-logo-orbi-color-mobile' : 'ico-logo-orbi-blanco-mobile'}.svg`}
                  onClick={() => {this.mainPage.current.scrollIntoView();this.handleModeChange(1)}}
                />


                <div className="frame-5">
                  <div className={"text-wrapper-3" } onClick={() => {this.mainPage.current.scrollIntoView();this.handleModeChange(1)}}>Inicio</div>

                  <div className={"text-wrapper-3" + (this.state.mode === 2 ? ' selected' : '') } onClick={() => {this.mainPage.current.scrollIntoView();this.handleModeChange(2)}}>Dueños de Carga</div>

                  <div className={"text-wrapper-3" + (this.state.mode === 3 ? ' selected' : '') } onClick={() => {this.mainPage.current.scrollIntoView();this.handleModeChange(3)}}>Transportistas</div>

                  <div className={"text-wrapper-3" } onClick={() => {
                    this.pricingRef.current.scrollIntoView();
                  }}>
                    Precios
                  </div>
                  <div className={"text-wrapper-3"}>
                    <Link to={`/survey`}>
                      Solicitar invitación
                    </Link>
                  </div>

                  <div className="crea-tu-cuenta-wrapper">
                    <div className="crea-tu-cuenta-2">
                      <button 
                        className="crea-tu-cuenta-2-btn"
                        // style={{border: "0px", backgroundColor: "unset", height: "59px", width: "243px"}}
                        onClick={(e) => {
                          e.stopPropagation();
                          this.setState({
                            showLoginModal: true
                          });
                        }}
                      >
                        Iniciar sesión
                      </button>
                    </div>
                  </div>

                  <div
                    className="frame-5-mobile-title"
                  >{this.state.mode === 2 ? 'Dueños de carga' : this.state.mode === 3 ? 'Transportistas' : ''}</div>

                  <div className="frame-5-mobile">
                    <img
                      className="ico-mobile-menu"
                      alt="Ico logo orbi color"
                      src={`/images/img/mobile-menu-icon${this.state.mode !== 1 ? '-white' : ''}.svg`}
                      onClick={(e) => {
                        e.stopPropagation();
                        this.toggleMobileMenu()
                      }}
                    />
                    <div className="mobile-menu" style={{display: this.state.showMobileMenu ? 'block' : 'none'}}>
                      <div
                        style={{
                          position: 'absolute',
                          top: 0,
                          bottom: 0,
                          left: 0,
                          right: 0,
                          backgroundColor: 'blue',
                          zIndex: -1
                        }}
                      />

                      <div className="mobile-menu-item" onClick={() => { this.mainPage.current.scrollIntoView(); this.setState({mode: 1, showMobileMenu: false})}}>Inicio</div>
                      <div className="mobile-menu-item" onClick={() => { this.mainPage.current.scrollIntoView(); this.setState({mode: 2, showMobileMenu: false})}}>Dueños de Carga</div>
                      <div className="mobile-menu-item" onClick={() => { this.mainPage.current.scrollIntoView(); this.setState({mode: 3, showMobileMenu: false})}}>Transportistas</div>
                      <div className="mobile-menu-item" onClick={() => { this.pricingRef.current.scrollIntoView(); this.setState({showMobileMenu: false}) }}>Precios</div>
                      <div className="mobile-menu-item">
                        <Link to={`/survey`}>
                          Solicitar invitación
                        </Link>
                      </div>
                      <div className="mobile-menu-item" onClick={() => { this.mainPage.current.scrollIntoView(); this.setState({showLoginModal: true, showMobileMenu: false})}}>Iniciar Sesión</div>
                    </div>
                  </div>
                </div>

              </div>

              <div className={"frame-2 ".concat(this.state.mode === 1 ? 'base-page' : 'special-page')} >
                <p className="la-forma-m-s-r-pida">
                  <span className="text-wrapper">
                    {
                      this.state.mode === 1 ? (
                        'El único canal que conecta todas las cadenas de abastecimiento'
                      ) : this.state.mode === 2 ? (
                        <>La forma más RÁPIDA y SIMPLE
                          de conectar a todos tus
                          clientes, proveedores y transportistas
                          en un  mismo canal.</>
                      ) : <>La forma más RÁPIDA y SIMPLE
                            de conectar a todos tus clientes
                            en un mismo canal.</>
                    }
                  </span>
                </p>
                <div className="text-wrapper-2">
                {
                  this.state.mode === 1 ? (
                    <>Orbinetwork es la comunidad en la nube que conecta a <b>todas las empresas de todas las cadenas de abastecimiento.</b><br/> ¿Aún no te han invitado?<br/> ¡No esperes más! <br/> Solicita tu invitación y conecta tu negocio a la red de Orbinetwork.</>
                  ) : this.state.mode === 2 ? (
                    <>Orbinetwork proporciona las herramientas que facilitan la toma de <b>decisiones estratégicas</b> y la implementación de una <b>cultura sistémica</b> con <b>experiencia digital</b> en tu organización y en todas las que forman parte de tu cadena de abastecimiento con el objetivo de lograr <b>mejoras y eficiencias en todo tu ecosistema</b>.</>
                  ) : (
                    <>Orbinetwork proporciona las herramientas que facilitan la toma de <b>decisiones estratégicas</b> y la implementación de una <b>cultura sistémica</b> con <b>experiencia digital</b> en tu organización y en todas  las que forman parte de las cadenas de abastecimiento donde participas con el objetivo de lograr <b>mejoras y eficiencias en todos los ecosistemas donde tienes operaciones</b>.</>
                  )
                }
                </div>
                  
                <div className="frame-3">
                  <div className="CTA-primary rectangle">
                    {/* <div className="rectangle" /> */}
                    <Link to={`/survey`}>
                      <button className="crea-tu-cuenta">Solicitar invitación</button>
                    </Link>
                  </div>
                </div>
              </div>

              
            </div>

            <div className="carousel-container">
              <CarouselProvider
                visibleSlides={1}
                totalSlides={3}
                step={1}
                // currentSlide={currentSlide}
                naturalSlideWidth={100}
                naturalSlideHeight={125}
                isIntrinsicHeight={true}
                isPlaying={true}
                interval={4000}
              >
                <div className="slider-wrapper">
                  <Slider>
                    <Slide index={0} className="slide">
                      <div className="hero-section-image-container">
                        <img 
                          className="element-ordenes-linea" 
                          alt="Element ordenes linea" 
                          src={this.getHeroSectionImage(0)} 
                        />
                      </div>
                    </Slide>
                    <Slide index={1}>
                      <div className="hero-section-image-container">
                        <img 
                          className="element-ordenes-linea" 
                          alt="Element ordenes linea" 
                          src={this.getHeroSectionImage(1)} 
                        />
                      </div>
                    </Slide>
                    <Slide index={1}>
                      <div className="hero-section-image-container">
                        <img 
                          className="element-ordenes-linea" 
                          alt="Element ordenes linea" 
                          src={this.getHeroSectionImage(2)} 
                        />
                      </div>
                    </Slide>
                  </Slider>
                  {/* <div className="controls">
                    <ButtonBack className="btn-arrow reverse-arrow">
                      <img src="/images/img/carousel-arrow.svg" alt="arrow" />
                    </ButtonBack>
                    <DotGroup className="dot-group" />
                    <ButtonNext className="btn-arrow">
                      <img src="/images/img/carousel-arrow.svg" alt="arrow" />
                    </ButtonNext>
                  </div> */}
                </div>
              </CarouselProvider>
            </div>
          </div>

          {
            this.state.mode === 1 && (
              <div className="frame-6">
                <p className="p">Conoce algunos de nuestros miembros:</p>
                <div className="carousel-container" id="brandsCarousel">
                  <CarouselProvider
                    visibleSlides={this.state.brandsSlides}
                    totalSlides={this.getBrands().length}
                    step={1}
                    // currentSlide={currentSlide}
                    naturalSlideWidth={100}
                    naturalSlideHeight={125}
                    isIntrinsicHeight={true}
                    isPlaying={this.state.scrollBrandsCarousel}
                    // lockOnWindowScroll={true}
                    interval={2000}
                  >
                    <div className="slider-wrapper">
                      <Slider>
                        {
                          this.getBrands().map((brand, index) => (
                            <Slide index={index} className="slide" key={index}>
                              <img className="group-4" alt="Group" src={brand.url} />
                            </Slide>

                          ))
                        }
                        {/* <Slide index={1}>
                          <img className="group-4" alt="Group" src="/images/img/group-330-2.svg" />
                        </Slide>
                        <Slide index={2}>
                          <img className="group-4" alt="Group" src="/images/img/group-330-3.svg" />
                        </Slide>
                        <Slide index={3}>
                          <img className="group-4" alt="Group" src="/images/img/group-330-4.svg" />
                        </Slide>
                        <Slide index={4}>
                          <img className="group-4" alt="Group" src="/images/img/group-330-5.svg" />
                        </Slide>
                        <Slide index={5}>
                          <img className="group-4" alt="Group" src="/images/img/group-330-6.svg" />
                        </Slide> */}
                      </Slider>
                      {/* <div className="controls">
                        <ButtonBack className="btn-arrow reverse-arrow">
                          <img src="/images/img/carousel-arrow.svg" alt="arrow" />
                        </ButtonBack>
                        <DotGroup className="dot-group" />
                        <ButtonNext className="btn-arrow">
                          <img src="/images/img/carousel-arrow.svg" alt="arrow" />
                        </ButtonNext>
                      </div> */}
                    </div>
                  </CarouselProvider>
                </div>
              </div>
            )
          }
        </div>
      </div>
      
      {
        this.state.mode === 1 && (
          <div className="tech-wrapper">
            <img
              className="img-2-s"
              alt="Icon calendar"
              src="/images/img/group-146-3.svg"
            />
            <div className="tech">
              <div className="title">
                <p className="uno-para-todos-y">
                  Un mismo canal para todos y todos para uno
                </p>
    
                <p className="text-wrapper-4">
                  Visualiza, optimiza y transforma tu operación con <b>una</b> misma <b>plataforma</b>, universal, ominicanal y omnidireccional <b>que beneficia a todas</b> las organizaciones de tu cadena de abastecimiento al compartir todos la misma herramienta.
                </p>
    
                <div className="frame-3">
                  <div className="CTA-primary rectangle">
                    <Link to={`/survey`}>
                      <button className="crea-tu-cuenta">Solicitar invitación</button>
                    </Link>
                  </div>
                </div>
    
                <div className="overlap-wrapper">
                  <img
                    className="img-2"
                    alt="Icon calendar"
                    src="/images/img/group-146-3.svg"
                  />
                </div>
              </div>
    
              <div className="row">
                <div className="frame-wrapper">
                  <div className="frame-7">
                    <div className="text-wrapper-5">Universal</div>
    
                    <p className="text-wrapper-6">
                      Un canal de comunicación único con interfaces simples que cualquiera puede usar sin ser expertos en tecnología, para que todas las áreas de la operación y toma de decisiones de tu negocio tengan la información que necesitan en tiempo real, desde <b>protección patrimonial, producción, almacén, planeación, compras, ventas, logística, distribución, torre de control, dirección</b>, etc. Además con el mismo canal pueden colaborar también todos tus clientes, proveedores y transportistas, sin importar el giro ni el tamaño de sus empresas. 
                    </p>
                  </div>
                </div>
    
                <div className="frame-wrapper">
                  <div className="frame-8">
                    <div className="text-wrapper-5">Omnicanal</div>
    
                    <p className="text-wrapper-6">
                      Integra a todas las <b>fábricas, almacenes, puntos de venta, transportistas</b> de tu <b>distribución primaria, secundaria, última milla, canal moderno, tradicional</b> o <b>digital</b> de tu empresa, de tus clientes y de tus proveedores. 
                    </p>
                  </div>
                </div>
    
                <div className="frame-wrapper">
                  <div className="frame-9">
                    <div className="text-wrapper-5">Omnidireccional</div>
    
                    <p className="text-wrapper-6">
                      Conéctate de forma <b>privada</b> con todos tus clientes, proveedores, transportistas con una misma red donde todos ellos  también puedan colaborar con su propia red de clientes proveedores y transportistas <b>sin complejidades</b> y <b>sin fragmentación</b>. 
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      }

      {
        this.state.mode === 1 && (
          <div className="frame-10">
            <div className="frame-11">
              <div className="text-wrapper-7">Integra toda tu operación en minutos</div>
    
              <p className="text-wrapper-8">
                A una comunidad privada que soporta cientos de empresas transportistas, miles de clientes y millones de pedidos y viajes.
              </p>
            </div>
    
            <div className="frame-12">
              <div className="frame-13">
                <div className="frame-14">
                  <p className="text-wrapper-9">
                    Reduce hasta un <b>50%</b> el tiempo de ciclo de viajes.
                  </p>
                </div>
              </div>
    
              <div className="frame-15">
                <div className="frame-14">
                  <p className="text-wrapper-9">
                    Incrementa hasta un <b>30%</b> la productividad de los camiones en tu operación.
                  </p>
                </div>
              </div>
            </div>
          </div>
        )
      }

      {/* <div className="frame-16">
        <div className="frame-17">
          <div className="text-wrapper-10">Testimoniales</div>

          <div className="frame-18">
            <div className="group-6">
              <div className="overlap-group-2">
                <p className="text-wrapper-11">
                  Designers need to have a strong understanding of the
                  principles of design in order to create effective solutions.
                  They must also be aware of the latest trends and technologies
                  so that they can stay ahead of the curve.
                </p>

                <div className="overlap-group-3">
                  <div className="text-wrapper-12">Jane Doe</div>

                  <div className="text-wrapper-13">CEO of Inkyy.com</div>
                </div>

                <img className="group-7" alt="Group" src="/images/img/group-91.png" />

                <img className="line" alt="Line" src="/images/img/line-1.svg" />
              </div>

              <img
                className="rectangle-2"
                alt="Rectangle"
                src="/images/img/path-8.png"
              />
            </div>

            <div className="group-6">
              <div className="overlap-3">
                <p className="text-wrapper-11">
                  Designers need to have a strong understanding of the
                  principles of design in order to create effective solutions.
                  They must also be aware of the latest trends and technologies
                  so that they can stay ahead of the curve.
                </p>

                <div className="overlap-group-3">
                  <div className="text-wrapper-12">Jane Doe</div>

                  <div className="text-wrapper-13">CEO of Inkyy.com</div>
                </div>

                <img className="group-7" alt="Group" src="/images/img/group-91.png" />

                <img className="line-2" alt="Line" src="/images/img/line-1-1.svg" />

                <img
                  className="rectangle-3"
                  alt="Rectangle"
                  src="/images/img/path-8.png"
                />
              </div>
            </div>

            <div className="group-8">
              <div className="overlap-3">
                <p className="text-wrapper-11">
                  Designers need to have a strong understanding of the
                  principles of design in order to create effective solutions.
                  They must also be aware of the latest trends and technologies
                  so that they can stay ahead of the curve.
                </p>

                <div className="overlap-group-3">
                  <div className="text-wrapper-12">Jane Doe</div>

                  <div className="text-wrapper-13">CEO of Inkyy.com</div>
                </div>

                <img
                  className="group-9"
                  alt="Group"
                  src="/images/img/group-91-2.png"
                />

                <img className="line-3" alt="Line" src="/images/img/line-1-2.svg" />

                <img
                  className="rectangle-3"
                  alt="Rectangle"
                  src="/images/img/path-8.png"
                />
              </div>
            </div>
          </div>

          <div className="group-10">
            <div className="ellipse-6" />

            <div className="ellipse-7" />

            <div className="ellipse-8" />
          </div>
        </div>
      </div> */}

      {
        this.state.mode === 1 && (
          <div className="frame-19">
            <div className="frame-20">
              {/* <div className="text-wrapper-14">Nuestros datos</div> */}
    
              <div className="text-wrapper-15">Más de...</div>
            </div>
    
            <div className="frame-21">
              <div className="frame-22">
                <p className="element">
                  <span className="text-wrapper">3</span>
    
                  <span className="text-wrapper-16">M</span>
                </p>
    
                <div className="text-wrapper-17">De viajes monitoreados</div>
              </div>
    
              <div className="frame-22">
                <p className="element">
                  <span className="text-wrapper">$50</span>
    
                  <span className="text-wrapper-16">MM</span>
                </p>
    
                <p className="text-wrapper-17">
                  de pesos timbrados en cartas portes desde nuestra plataforma
                </p>
              </div>
    
              <div className="frame-22">
                <p className="element">
                  <span className="text-wrapper">4</span>
    
                  <span className="text-wrapper-16">K</span>
                </p>
    
                <p className="text-wrapper-17">
                  {" "}
                  operadores que utilizan nuestra app
                </p>
              </div>
            </div>
          </div>
        )
      }

      <div className="frame-23">

        {
          this.state.mode === 1 && <>
            <div className="frame-11">
              <div className="text-wrapper-7">Beneficios</div>
    
              <p className="text-wrapper-8">
                Siente el poder de la velocidad de la información colaborativa en tiempo real, la simplicidad en su máxima expresión y explota tu verdadero potencial:
              </p>
            </div>
            <div className="frame-24-0">
              {/* <p className="text-wrapper-8-1">Maximiza...</p> */}
              <div className="frame-24">
                <div className="frame-25">
                  <div className="frame-26">
                    <img className="vector" alt="Vector" src="/images/img/vector.svg" />
      
                    <div className="frame-27">
                      <p className="text-wrapper-18">
                        Maximiza la productividad de tus recursos y operación.
                      </p>
                    </div>
                  </div>
                </div>
      
                <div className="frame-25">
                  <div className="frame-26">
                    <img
                      className="pag-pregunta-b"
                      alt="Pag pregunta b"
                      src="/images/img/pag-11-pregunta-b-act.svg"
                    />
      
                    <div className="frame-27">
                      <p className="text-wrapper-18">
                        Maximiza el nivel de servicio y experiencia de tus clientes:
                        NPS
                      </p>
                    </div>
                  </div>
                </div>
      
                <div className="frame-25">
                  <div className="frame-26">
                    <img
                      className="pag-pregunta-a"
                      alt="Pag pregunta a"
                      src="/images/img/pag-11-pregunta-a-act.svg"
                    />
      
                    <div className="frame-27">
                      <p className="text-wrapper-18">
                        Maximiza la rentabilidad de tu negocio: EBITDA
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        }

        <div className="frame-28">

          <div className="text-wrapper-19">
            {
              this.state.mode === 1 ? (
                '¿Cómo funciona Orbinetwork?'
              ) : this.state.mode === 2 ? (
                'Beneficios para tu cadena de abastecimiento'
              ) : 'Beneficios para tu operación'
            }
          </div>

          {
            this.state.mode === 1 && (
              <p className="text-wrapper-19-0">Únete solo por invitación.</p>
            )
          }

          <div className={"frame-29" + (this.state.mode === 1 ? '' : ' special-page')}>
            <div className="frame-30">
              <div className="frame-31">
                <div className="frame-31-1">
                  <img
                    className="frame-32"
                    alt="Frame"
                    src={ this.state.mode === 1 ? (
                      "/images/img/group-129-1.svg" ) : this.state.mode === 2 ? (
                        "/images/img/group-129-3.svg"
                      ) : "/images/img/group-129-3.svg"
                    }
                  />
                </div>

                <div className="frame-33">
                  <div className="text-wrapper-20">
                    {
                      this.state.mode === 1 ? (
                        '1. Regístrate'
                      ) : this.state.mode === 2 ? (
                        'Transformación digital integral'
                      ) : 'Transformación digital integral '
                    }
                  </div>

                  <p className="div-2">
                    <span className="text-wrapper-21">
                      {
                        this.state.mode === 1 ? (
                          <>Recibe una invitación para crear tu <b>perfil personal</b> y el <b>perfil</b> para tu compañía.</>
                        ) : this.state.mode === 2 ? (
                            <>Libera todo el potencial de tu cadena de abastecimiento y conquista la verdadera...</>
                        ) : <>Libera todo el potencial de tu operación y conquista la verdadera transformación...</>
                      }
                      <br />
                    </span>

                    {
                      this.state.mode !== 1 && (
                        <span
                         className="text-wrapper-22"
                         onClick={() => {
                          if(this.state.mode === 2) {
                            this.setState({
                              showViewDetail: true,
                              detailImg: '/images/img/group-129-3.svg',
                              detailTitle: 'Transformación digital integral',
                              detailMessage: <>Libera todo el potencial de tu cadena de abastecimiento y conquista la verdadera transformación digital con nuestras <b>herramientas tecnológicas</b> y el <b>acompañamiento humano</b> de nuestro equipo en cada paso del camino.</>
                            })
                          } else {
                            this.setState({
                              showViewDetail: true,
                              detailImg: '/images/img/group-129-3.svg',
                              detailTitle: 'Transformación digital integral',
                              detailMessage: <>Libera todo el potencial de tu operación y conquista la verdadera transformación digital con nuestras <b>herramientas tecnológicas</b> y el <b>acompañamiento humano</b> de nuestro equipo en cada paso del camino.</>
                            })
                          }
                         }}
                        >Leer más</span>
                      )
                    }
                  </p>
                </div>
              </div>
            </div>

            <div className="frame-30">
              <div className="frame-31">
                <div className="frame-31-1">
                  <img 
                    className="frame-32" 
                    alt="Frame" 
                    src={ this.state.mode === 1 ? (
                      "/images/img/group-129-2.svg" ) : this.state.mode === 2 ? (
                        "/images/img/cargo-owner-1.svg"
                      ) : "/images/img/cargo-owner-1.svg"
                    }
                  />
                </div>

                <div className="frame-33">
                  <div className="text-wrapper-23">
                    {
                      this.state.mode === 1 ? (
                        '2. Invita'
                      ) : this.state.mode === 2 ? (
                        `Visibilidad Omnicanal en tiempo real`
                      ) : 'Visibilidad Omnicanal en tiempo real'
                    }
                  </div>

                  <p className="div-2">
                    <span className="text-wrapper-21">
                      {
                        this.state.mode === 1 ? (
                          `A todos los colaboradores de tu empresa involucrados en la operación de la cadena de abastecimiento y en la toma de decisiones del negocio.`
                        ) : this.state.mode === 2 ? (
                          "Accede a toda la información de forma rápida y simple para monitorear cada fase del ciclo de..."
                        ) : `Brinda estatus automático, ubicación y evidencias a todos tus clientes y a todos los involucrados...`
                      }
                      <br />
                    </span>
                    {
                      this.state.mode !== 1 && (
                        <span
                          className="text-wrapper-22"
                          onClick={() => {
                            if(this.state.mode === 2) {
                              this.setState({
                                showViewDetail: true,
                                detailImg: '/images/img/cargo-owner-1.svg',
                                detailTitle: 'Visibilidad Omnicanal en tiempo real',
                                detailMessage: <>Accede a toda la información de forma rápida y simple para monitorear cada fase del ciclo de cada pedido y embarque, desde su creación hasta la entrega, con seguimiento minuto a minuto. Mejora la transparencia, anticipa eventos y reacciona rápido ante imprevistos  en cada segmento de tu cadena productiva y en todos tus canales de distribución.</>
                              })
                            } else {
                              this.setState({
                                showViewDetail: true,
                                detailImg: '/images/img/cargo-owner-1.svg',
                                detailTitle: 'Visibilidad Omnicanal en tiempo real',
                                detailMessage: <>Brinda estatus automático, ubicación y evidencias a todos tus clientes y a todos los involucrados en cada etapa de tus embarques en tiempo real, desde la confirmación de las reservaciones que te solicitan, asignación de unidad, recolección de mercancias, cambios de vehículos u operadores y hasta la entrega en destino final de los embarques de cualquier operación de tus clientes.</>
                              })
                            }
                         }}
                        >Leer más</span>
                      )
                    }
                  </p>
                </div>
              </div>
            </div>

            <div className="frame-30">
              <div className="frame-31">
                <div className="frame-31-1">
                  <img
                    className="group-11"
                    alt="Group"
                    src={ this.state.mode === 1 ? (
                      "/images/img/group-129-3.svg" ) : this.state.mode === 2 ? (
                        "/images/img/cargo-owner-2.svg"
                      ) : "/images/img/cargo-owner-2.svg"
                    }
                  />
                </div>
                <div className="frame-33">
                  <div className="text-wrapper-23">
                      {
                        this.state.mode === 1 ? (
                          `3. Configura`
                        ) : this.state.mode === 2 ? (
                          `Logística inteligente`
                        ) : `Logística inteligente`
                      }
                  </div>
                  <p className="div-2">
                    <span className="text-wrapper-21">
                      {
                        this.state.mode === 1 ? (
                          <>Los catálogos de <b>datos maestros</b> de la cuenta de tu empresa.</>
                        ) : this.state.mode === 2 ? (
                          `Agiliza la gestion de pedidos y entregas, la planificación de órdenes y...`
                        ) : `Recibe de tus clientes las asignaciones de embarques y citas planeadas y...`
                      }
                      <br />
                    </span>
                    {
                      this.state.mode !== 1 && (
                        <span
                          className="text-wrapper-22"
                          onClick={() => {
                            if(this.state.mode === 2) {
                              this.setState({
                                showViewDetail: true,
                                detailImg: '/images/img/cargo-owner-2.svg',
                                detailTitle: 'Logística inteligente',
                                detailMessage: 'Agiliza la gestion de pedidos y entregas, la planificación de órdenes y embarques, la asignación de citas y reservaciones con nuestras herramientas de inteligencia artificial entrenada con datos históricos y la información actualizada en tiempo real de todos los involucrados en cada operación.'
                              })
                            } else {
                              this.setState({
                                showViewDetail: true,
                                detailImg: '/images/img/cargo-owner-2.svg',
                                detailTitle: 'Logística inteligente',
                                detailMessage: 'Recibe de tus clientes las asignaciones de embarques y citas planeadas y optimizadas con nuestras herramientas apoyadas en la ubicación y disponibilidad automática que tus unidades reportan tus clientes y nuestra inteligencia artificial.'
                              })

                            }
                          }}
                        >Leer más</span>
                      )
                    }
                  </p>
                </div>
              </div>
            </div>

            {
              this.state.mode !== 1 && (
                <div className="frame-30">
                  <div className="frame-31">
                    <div className="frame-31-1">
                      <img
                        className="group-11"
                        alt="Group"
                        src="/images/img/cargo-owner-3.svg"
                      />
                    </div>

                    <div className="frame-33">
                      <div className="text-wrapper-23">
                        Seguridad vial y cumplimiento fiscal de la carta porte
                      </div>

                      <p className="div-2">
                        <span className="text-wrapper-21">
                          {
                            this.state.mode === 2 ? (
                              'Audita a tus transportistas y verifica que cumplan con la documentación normativa...'
                            ) : (
                              'Evita accidentes, audita el cumplimiento de la documentación normativa de tu flota...'
                            )
                          }
                          <br />
                        </span>
                        <span
                          className="text-wrapper-22"
                          onClick={() => {
                            this.setState({
                              showViewDetail: true,
                              detailImg: '/images/img/cargo-owner-3.svg',
                              detailTitle: 'Seguridad vial y cumplimiento fiscal de la carta porte',
                              detailMessage: (
                                this.state.mode === 2 ? (
                                  'Audita a tus transportistas y verifica que cumplan con la documentación normativa vial y fiscal como la Carta Porte, y reduce el riesgo de accidentes y multas.'
                                ) : (
                                  'Evita accidentes, audita el cumplimiento de la documentación normativa de tu flota y permite a tus clientes que los validen.  Garantiza el cumplimiento fiscal de la carta porte con las funciones de timbrado automático al momento de cargar las mercancías o durante el viaje cada vez que hay un cambio de operador o vehículo.'
                                )
                              )
                            })
                          }}
                        >Leer más</span>
                      </p>
                    </div>
                  </div>
                </div>
              )
            }

            <div className="frame-30">
              <div className="frame-31">
                <div className="frame-31-1">
                  <img 
                    className="frame-35" 
                    alt="Frame" 
                    src={ this.state.mode === 1 ? (
                      "/images/img/group-129-4.svg" ) : this.state.mode === 2 ? (
                        "/images/img/group-129-5.svg"
                      ) : "/images/img/group-129-5.svg"
                    } 
                  />
                </div>

                <div className="frame-33">
                  <p className="text-wrapper-20">
                    
                    {
                      this.state.mode === 1 ? (
                        `4. Crea tu red de colaboración`
                      ) : this.state.mode === 2 ? (
                        `Inteligencia de negocios con IA`
                      ) : `Inteligencia de negocios con IA`
                    }
                  </p>

                  <p className="text-wrapper-24">
                    
                    {
                      this.state.mode === 1 ? (
                        `Envía invitaciones a todos tus clientes, proveedores y transportistas para que se únan a colaborar de forma privada en la red de Orbinetwork. `
                      ) : this.state.mode === 2 ? (
                        `Evalúa el desempeño, mide la productividad y la mejora continua de...`
                      ) : `Evalúa el desempeño, mide la productividad y la mejora continua de...`
                    }
                    {
                      this.state.mode !== 1 && (
                        <>
                          <br />
                          <span
                            className="text-wrapper-22"
                            onClick={() => {
                              if(this.state.mode === 2) {
                                this.setState({
                                  showViewDetail: true,
                                  detailImg: '/images/img/group-129-5.svg',
                                  detailTitle: 'Inteligencia de negocios con IA',
                                  detailMessage: <>Evalúa el desempeño, mide la productividad y la mejora continua de toda tu cadena con reportes, indicadores y tableros gráficos personalizables del módulo de <i><u>inteligencia de negocios</u></i> que funciona con <b>inteligencia artificial</b>. Visualizar la información nunca habia sido tan sencillo y te permitirá tomar más y mejores decisiones estratégicas para tu negocio.</>
                                })
                              } else {
                                this.setState({
                                  showViewDetail: true,
                                  detailImg: '/images/img/group-129-5.svg',
                                  detailTitle: 'Inteligencia de negocios con IA',
                                  detailMessage: <>Evalúa el desempeño, mide la productividad y la mejora continua de tus unidades, operadores y de todos tus clientes con reportes, indicadores y tableros gráficos personalizables del módulo de <i><u>inteligencia de negocios</u></i> que funciona con <b>inteligencia artificial</b>. Visualizar la información nunca habia sido tan sencillo y te permitirá tomar más y mejores decisiones estratégicas para tu negocio.</>
                                })
                              }
                            }}
                          >Leer más</span>
                        </>
                      )
                    }
                  </p>
                </div>
              </div>
            </div>
            
            {
              this.state.mode === 1 && 
              <div className={this.state.mode !== 1 ? "frame-30" : "frame-36"}>
                {
                  this.state.mode === 1 && (
                    <div className="frame-31-1">
                      <img 
                        className="frame-32" 
                        alt="Frame" 
                        src={ this.state.mode === 1 ? (
                          "/images/img/group-129-5.svg" ) : this.state.mode === 2 ? (
                            "/images/img/cargo-owner-5.svg"
                          ) : ""
                        } 
                      /> 
                    </div>
                  )
                }

                <p className="text-wrapper-25">
                  ¡Todo listo! comienza a usar Orbinetwork
                </p>

                <p className="text-wrapper-26">
                  {
                    this.state.mode === 1 ? (
                      `Conoce todo lo que Orbinetwork puede hacer para ti.`
                    ) : this.state.mode === 2 ? (
                      `Conoce todo lo que Orbinetwork puede hacer para ti. Regístrate ahora para recibir una invitación.`
                    ) : `Audita el cumplimiento normativo de tu flota y permite a tus clientes que...`
                  }
                </p>

                <div className="frame-37">
                      <Link to={`/survey`}>
                        <div className="crea-tu-cuenta-2">Solicitar invitación</div>
                      </Link>
                </div>
              </div>
            }
          </div>
        </div>

        {
          this.state.mode !== 1 && (
            <div className="frame-3">
              <div className="CTA-primary rectangle">
                <Link to={`/survey`}>
                  <button className="crea-tu-cuenta">Solicitar invitación</button>
                </Link>
              </div>
            </div>
          )
        }
      </div>

      <div className="frame-38">
        <div className="frame-39">
          <div className="frame-40">
            <p className="text-wrapper-28">
              
              {
                this.state.mode === 1 ? (
                  `Conoce los beneficios y funciones para...`
                ) : this.state.mode === 2 ? (
                  `Funciones para Dueños de Carga`
                ) : `Funciones para Transportistas`
              }
            </p>
          </div>
        </div>
        
        {
          this.state.mode === 1 ? (
            <div className="frame-41">
              <div className="frame-42">
                <div className="frame-43">
                  <div className="group-12" />
    
                  <div className="frame-44">
                    <div className="frame-45">
                      <div className="text-wrapper-29">Dueños de Carga</div>
    
                      <p className="si-tu-actividad">
                        Si tu empresa fabrica, distribuye, comercializa y/o vende cualquier producto o materia prima, descubre todo lo que Orbinetwork tiene para los dueños de carga como tú.
                      </p>
                    </div>
    
                    <div className="frame-46">
                      <div className="CTA-primary-2 rectangle-4">
                          <button 
                            className="crea-tu-cuenta-3" 
                            onClick={() => {this.mainPage.current.scrollIntoView();this.handleModeChange(2)}}
                          >Conoce más</button>
                      </div>
                      <Link to={`/survey`}>
                        <div className="crea-tu-cuenta-4">Solicitar invitación</div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
    
              <div className="frame-47">
                <div className="frame-48">
                  <div className="frame-49">
                    <img
                      className="group-13"
                      alt="Group"
                      src="/images/img/group-148.png"
                    />
                    <div className="text-wrapper-30">Transportistas</div>
                  </div>
    
                  
                  <p className="si-tu-actividad-2">
                    Si tu empresa presta servicio de transporte de carga federal o privada a dueños de carga, descubre todo lo que Orbinetwork tiene para los transportistas como tú.
                  </p>
    
                  <div className="frame-46">
                    <div className="CTA-primary-3">
                      <img
                        className="rectangle-5"
                        alt="Rectangle"
                        src="/images/img/rectangle.svg"
                      />
                        <button 
                          className="crea-tu-cuenta-5" 
                          onClick={() => {this.mainPage.current.scrollIntoView();this.handleModeChange(3)}}
                        >Conoce más</button>
                    </div>
                    <Link to={`/survey`}>
                      <div className="crea-tu-cuenta-6">Solicitar invitación</div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          ) : this.state.mode === 2 ? (
            <div className="carousel-container">
              <CarouselProvider
                visibleSlides={this.state.benefitsSlides}
                totalSlides={7}
                step={1}
                // currentSlide={currentSlide}
                naturalSlideWidth={100}
                naturalSlideHeight={125}
                isIntrinsicHeight={true}
              >
                <div className="slider-wrapper">
                  <Slider>
                    <Slide index={0} className="slide">
                      <div className="frame-41-2">
                        <img className="frame-41-2-img" alt="icon" src="/images/img/frame-41-1-1.svg" />
                        <p className="frame-41-2-title">
                          Red de colaboración
                        </p>
                        <p className="frame-41-2-text">
                          Crea tu propia red privada de colaboracion y conecta a todos tus clientes, proveedores y transportistas con una misma herramienta en el módulo de “Mi red”.
                        </p>
                        <span
                          className="text-wrapper-22"
                          onClick={() => {
                            this.setState({
                              showViewDetail: true,
                              detailImg: '/images/img/frame-41-1-1-white.svg',
                              detailTitle: 'Red de colaboración',
                              detailMessage: <>Crea tu propia red privada de colaboracion y conecta a todos tus clientes, proveedores y transportistas con una misma herramienta en el módulo de “Mi red”.</>
                            })
                          }}
                        >Leer más</span>
                      </div>
                    </Slide>
                    <Slide index={1}>
                      <div className="frame-41-2">
                        <img className="frame-41-2-img" alt="icon" src="/images/img/frame-41-1-2.svg" />
                        <p className="frame-41-2-title">
                          Control de pedidos, órdenes y embarques
                        </p>
                        <p className="frame-41-2-text">
                          En éste módulo crea y gestiona de principio a fin los pedidos y entregas, de tus clientes y proveedores,  consolida órdenes y embarques de todas tus operaciones con todos tus transportistas de todos los segmentos de tu cadena de producción y de todos los canales de distribución.
                        </p>
                        <span
                          className="text-wrapper-22"
                          onClick={() => {
                            this.setState({
                              showViewDetail: true,
                              detailImg: '/images/img/frame-41-1-2-white.svg',
                              detailTitle: 'Control de pedidos, órdenes y embarques',
                              detailMessage: <>En éste módulo crea y gestiona de principio a fin los pedidos y entregas, de tus clientes y proveedores,  consolida órdenes y embarques de todas tus operaciones con todos tus transportistas de todos los segmentos de tu cadena de producción y de todos los canales de distribución.</>
                            })
                          }}
                        >Leer más</span>
                      </div>
                    </Slide>
                    <Slide index={2}>
                      <div className="frame-41-2">
                        <img className="frame-41-2-img" alt="icon" src="/images/img/frame-41-1-3.svg" />
                        <p className="frame-41-2-title">
                          Monitoreo
                        </p>
                        <p className="frame-41-2-text">
                          Visualiza estatus y ubicación en tiempo real y centraliza la gestion citas de recolección y entrega de todos tus embarques de todas tus operaciones.
                        </p>
                        <span
                          className="text-wrapper-22"
                          onClick={() => {
                            this.setState({
                              showViewDetail: true,
                              detailImg: '/images/img/frame-41-1-3-white.svg',
                              detailTitle: 'Monitoreo',
                              detailMessage: <>Visualiza estatus y ubicación en tiempo real y centraliza la gestion citas de recolección y entrega de todos tus embarques de todas tus operaciones.</>
                            })
                          }}
                        >Leer más</span>
                      </div>
                    </Slide>
                    <Slide index={3}>
                      <div className="frame-41-2">
                        <img className="frame-41-2-img" alt="icon" src="/images/img/frame-41-1-4.svg" />
                        <p className="frame-41-2-title">
                          Timbrado CFDI con Complemento Carta Porte
                        </p>
                        <p className="frame-41-2-text">
                          Automatiza el timbrado de los CFDIS con Complemento Carta Porte de tus embarques con todos tus transportistas.
                        </p>
                        <span
                          className="text-wrapper-22"
                          onClick={() => {
                            this.setState({
                              showViewDetail: true,
                              detailImg: '/images/img/frame-41-1-4-white.svg',
                              detailTitle: 'Timbrado CFDI con Complemento Carta Porte',
                              detailMessage: <>Automatiza el timbrado de los CFDIS con Complemento Carta Porte de tus embarques con todos tus transportistas.</>
                            })
                          }}
                        >Leer más</span>
                      </div>
                    </Slide>
                    <Slide index={4}>
                      <div className="frame-41-2">
                        <img className="frame-41-2-img" alt="icon" src="/images/img/frame-41-1-5.svg" />
                        <p className="frame-41-2-title">
                          Normatividad
                        </p>
                        <p className="frame-41-2-text">
                          Visualiza y audita los documentos normativos de las flotas que todos tus transportistas te comparten con el módulo de catálogo de flotilla compartida.
                        </p>
                        <span
                          className="text-wrapper-22"
                          onClick={() => {
                            this.setState({
                              showViewDetail: true,
                              detailImg: '/images/img/frame-41-1-5-white.svg',
                              detailTitle: 'Normatividad',
                              detailMessage: <>Visualiza y audita los documentos normativos de las flotas que todos tus transportistas te comparten con el módulo de catálogo de flotilla compartida.</>
                            })
                          }}
                        >Leer más</span>
                      </div>
                    </Slide>
                    <Slide index={5}>
                      <div className="frame-41-2">
                        <img className="frame-41-2-img" alt="icon" src="/images/img/frame-41-1-6.svg" />
                        <p className="frame-41-2-title">
                          Disponibilidad de Flota
                        </p>
                        <p className="frame-41-2-text">
                          Éste módulo te brinda un reporte actualizado en tiempo real con las unidades disponibles de tus transportistas que se encuentren asignadas a tus operaciones.
                        </p>
                        <span
                          className="text-wrapper-22"
                          onClick={() => {
                            this.setState({
                              showViewDetail: true,
                              detailImg: '/images/img/frame-41-1-6-white.svg',
                              detailTitle: 'Disponibilidad de Flota',
                              detailMessage: <>Éste módulo te brinda un reporte actualizado en tiempo real con las unidades disponibles de tus transportistas que se encuentren asignadas a tus operaciones.</>
                            })
                          }}
                        >Leer más</span>
                      </div>
                    </Slide>
                    <Slide index={6}>
                      <div className="frame-41-2">
                        <img className="frame-41-2-img" alt="icon" src="/images/img/frame-41-1-7.svg" />
                        <p className="frame-41-2-title">
                          Inteligencia de negocios y reportes
                        </p>
                        <p className="frame-41-2-text">
                          Visualiza decenas de indicadores y gráficas de desempeño de tus operaciones en todas las organizaciones de tu cadena de abastecimiento desde el módulo de <i>inteligencia de negocios</i> con <b>Inteligencia Artificial</b>.
                        </p>
                        <span
                          className="text-wrapper-22"
                          onClick={() => {
                            this.setState({
                              showViewDetail: true,
                              detailImg: '/images/img/frame-41-1-7-white.svg',
                              detailTitle: 'Inteligencia de negocios y reportes',
                              detailMessage: <>Visualiza decenas de indicadores y gráficas de desempeño de tus operaciones en todas las organizaciones de tu cadena de abastecimiento desde el módulo de <i>inteligencia de negocios</i> con <b>Inteligencia Artificial</b>.</>
                            })
                          }}
                        >Leer más</span>
                      </div>
                    </Slide>
                  </Slider>
                  <div className="controls">
                    <ButtonBack className="btn-arrow reverse-arrow">
                      <img src="/images/img/carousel-arrow.svg" alt="arrow" />
                    </ButtonBack>
                    <DotGroup className="dot-group" />
                    <ButtonNext className="btn-arrow">
                      <img src="/images/img/carousel-arrow.svg" alt="arrow" />
                    </ButtonNext>
                  </div>
                </div>
              </CarouselProvider>
            </div>
          ) : (
            <div className="carousel-container">
              <CarouselProvider
                visibleSlides={this.state.benefitsSlides}
                totalSlides={7}
                step={1}
                // currentSlide={currentSlide}
                naturalSlideWidth={100}
                naturalSlideHeight={125}
                isIntrinsicHeight={true}
              >
                <div className="slider-wrapper">
                  <Slider>
                    <Slide index={0} className="slide">
                      <div className="frame-41-2">
                        <img className="frame-41-2-img" alt="icon" src="/images/img/frame-41-1-1.svg" />
                        <p className="frame-41-2-title">
                          Red de colaboración
                        </p>
                        <p className="frame-41-2-text">
                        Crea tu propia red privada de colaboracion y conecta a todos tus clientes con una misma herramienta en el módulo de “Mi red”.
                        </p>
                        <span
                          className="text-wrapper-22"
                          onClick={() => {
                            this.setState({
                              showViewDetail: true,
                              detailImg: '/images/img/frame-41-1-1-white.svg',
                              detailTitle: 'Red de colaboración',
                              detailMessage: <>Crea tu propia red privada de colaboracion y conecta a todos tus clientes con una misma herramienta en el módulo de “Mi red”.</>
                            })
                          }}
                        >Leer más</span>
                      </div>
                    </Slide>
                    <Slide index={1}>
                      <div className="frame-41-2">
                        <img className="frame-41-2-img" alt="icon" src="/images/img/frame-41-1-2.svg" />
                        <p className="frame-41-2-title">
                        Control de embarques
                        </p>
                        <p className="frame-41-2-text">
                        En este módulo gestiona y asigna unidades a los embarques que tus clientes te requieren. Cambia de operador o vehículo cada que requieras y manten siempre informado a tus clientes de todos los movimientos relacionados con los viajes de sus mercancías.
                        </p>
                        <span
                          className="text-wrapper-22"
                          onClick={() => {
                            this.setState({
                              showViewDetail: true,
                              detailImg: '/images/img/frame-41-1-2-white.svg',
                              detailTitle: 'Control de embarques',
                              detailMessage: <>En este módulo gestiona y asigna unidades a los embarques que tus clientes te requieren. Cambia de operador o vehículo cada que requieras y manten siempre informado a tus clientes de todos los movimientos relacionados con los viajes de sus mercancías.</>
                            })
                          }}
                        >Leer más</span>
                      </div>
                    </Slide>
                    <Slide index={2}>
                      <div className="frame-41-2">
                        <img className="frame-41-2-img" alt="icon" src="/images/img/frame-41-1-3.svg" />
                        <p className="frame-41-2-title">
                        Monitoreo
                        </p>
                        <p className="frame-41-2-text">
                        Visualiza estatus, ubicacion y evidencias en tiempo real, solicita cambios de citas de recolección y entrega de todas tus operaciones.
                        </p>
                        <span
                          className="text-wrapper-22"
                          onClick={() => {
                            this.setState({
                              showViewDetail: true,
                              detailImg: '/images/img/frame-41-1-3-white.svg',
                              detailTitle: 'Monitoreo',
                              detailMessage: <>Visualiza estatus, ubicacion y evidencias en tiempo real, solicita cambios de citas de recolección y entrega de todas tus operaciones.</>
                            })
                          }}
                        >Leer más</span>
                      </div>
                    </Slide>
                    <Slide index={3}>
                      <div className="frame-41-2">
                        <img className="frame-41-2-img" alt="icon" src="/images/img/frame-41-1-4.svg" />
                        <p className="frame-41-2-title">
                          Timbrado CFDI con Complemento Carta Porte
                        </p>
                        <p className="frame-41-2-text">
                        Automatiza el timbrado de los CFDIs con complemento carta porte de los embarques de todos tus clientes.
                        </p>
                        <span
                          className="text-wrapper-22"
                          onClick={() => {
                            this.setState({
                              showViewDetail: true,
                              detailImg: '/images/img/frame-41-1-4-white.svg',
                              detailTitle: 'Timbrado CFDI con Complemento Carta Porte',
                              detailMessage: <>Automatiza el timbrado de los CFDIs con complemento carta porte de los embarques de todos tus clientes.</>
                            })
                          }}
                        >Leer más</span>
                      </div>
                    </Slide>
                    <Slide index={4}>
                      <div className="frame-41-2">
                        <img className="frame-41-2-img" alt="icon" src="/images/img/frame-41-1-5.svg" />
                        <p className="frame-41-2-title">
                          Normatividad
                        </p>
                        <p className="frame-41-2-text">
                        Comparte con tus clientes y permite que auditen los documentos de las normatividades de tu flotilla desde el módulo de catálogo de flotilla.
                        </p>
                        <span
                          className="text-wrapper-22"
                          onClick={() => {
                            this.setState({
                              showViewDetail: true,
                              detailImg: '/images/img/frame-41-1-5-white.svg',
                              detailTitle: 'Normatividad',
                              detailMessage: <>Comparte con tus clientes y permite que auditen los documentos de las normatividades de tu flotilla desde el módulo de catálogo de flotilla.</>
                            })
                          }}
                        >Leer más</span>
                      </div>
                    </Slide>
                    <Slide index={5}>
                      <div className="frame-41-2">
                        <img className="frame-41-2-img" alt="icon" src="/images/img/frame-41-1-6.svg" />
                        <p className="frame-41-2-title">
                          Disponibilidad de Flota
                        </p>
                        <p className="frame-41-2-text">
                        Éste módulo reporta automáticamente y en tiempo real la disponibilidad de tus unidades asignadas a cada uno de tus clientes.
                        </p>
                        <span
                          className="text-wrapper-22"
                          onClick={() => {
                            this.setState({
                              showViewDetail: true,
                              detailImg: '/images/img/frame-41-1-6-white.svg',
                              detailTitle: 'Disponibilidad de Flota',
                              detailMessage: <>Éste módulo reporta automáticamente y en tiempo real la disponibilidad de tus unidades asignadas a cada uno de tus clientes.</>
                            })
                          }}
                        >Leer más</span>
                      </div>
                    </Slide>
                    <Slide index={6}>
                      <div className="frame-41-2">
                        <img className="frame-41-2-img" alt="icon" src="/images/img/frame-41-1-7.svg" />
                        <p className="frame-41-2-title">
                        Inteligencia de negocios y reportes
                        </p>
                        <p className="frame-41-2-text">
                        Visualiza decenas de indicadores y gráficas de desempeño de tus operaciones con cada uno de tus clientes desde el módulo de inteligencia de negocios con Inteligencia Artificial.
                        </p>
                        <span
                          className="text-wrapper-22"
                          onClick={() => {
                            this.setState({
                              showViewDetail: true,
                              detailImg: '/images/img/frame-41-1-7-white.svg',
                              detailTitle: 'Inteligencia de negocios y reportes',
                              detailMessage: <>Visualiza decenas de indicadores y gráficas de desempeño de tus operaciones con cada uno de tus clientes desde el módulo de <i>inteligencia de negocios</i> con <b>Inteligencia Artificial</b>.</>
                            })
                          }}
                        >Leer más</span>
                      </div>
                    </Slide>
                  </Slider>
                  <div className="controls">
                    <ButtonBack className="btn-arrow reverse-arrow">
                      <img src="/images/img/carousel-arrow.svg" alt="arrow" />
                    </ButtonBack>
                    <DotGroup className="dot-group" />
                    <ButtonNext className="btn-arrow">
                      <img src="/images/img/carousel-arrow.svg" alt="arrow" />
                    </ButtonNext>
                  </div>
                </div>
              </CarouselProvider>
            </div>
          )
        }

        {
          this.state.mode !== 1 && (
            <div className="frame-3">
              <div className="CTA-primary rectangle">
                <Link to={`/survey`}>
                  <button className="crea-tu-cuenta">Solicitar invitación</button>
                </Link>
              </div>
            </div>
          )
        }
        

      </div>

      <div className="frame-50"  ref={this.pricingRef}>
        <div className="frame-51">
          <div className="text-wrapper-19">Nuestros precios</div>

          <p className="text-wrapper-31">
            Elige el plan que mejor se adapte a ti
          </p>
        </div>

        <div className="frame-52">
          {
            this.state.mode === 1 && 
            <div className="rectangle-6" />
          }

          {
            this.state.mode !== 3 &&
            <div 
              className={"frame-53 " + (this.state.pricingMode === 1 || this.state.mode === 2 ? 'active' : '') + (this.state.mode !== 1 ? ' unique' : '')} 
              onClick={() => { 
                this.setState({pricingMode: 1});
              }}
            >
              <div className="text-wrapper-32" >
                Dueños de carga
              </div>
            </div>
          }

          {
            this.state.mode !== 2 &&
            <div 
              className={"frame-54 " + (this.state.pricingMode === 2 || this.state.mode === 3 ? 'active' : '') + (this.state.mode !== 1 ? ' unique' : '')} 
              onClick={() => {
                this.setState({pricingMode: 2});
              }}
            >
              <div className="text-wrapper-33">
                Transportistas
                </div>
            </div>
          }
        </div>

        <div className="carousel-container">
          <CarouselProvider
            visibleSlides={1}
            totalSlides={(this.state.mode === 2 || (this.state.mode === 1 && this.state.pricingMode === 1)) ? 2 : 1}
            step={1}
            // currentSlide={currentSlide}
            naturalSlideWidth={100}
            naturalSlideHeight={125}
            isIntrinsicHeight={true}
          >
            <div className="slider-wrapper">
              <Slider>
                {
                  (this.state.mode === 2 || (this.state.mode === 1 && this.state.pricingMode === 1)) 
                  ? (<>
                    <Slide index={0} className="slide">
                      <div className="group-14-container">
                        <p className="text-wrapper-141">Plan Básico</p>
                        <p className="text-wrapper-142">Gratis</p>
                        
                        <p className="text-wrapper-143">Catálogos</p>
                        <div className="group-14-item">
                          <img className="group-14-checkmark" alt="Checkmark" src="/images/img/frame-109.svg"/>
                          Usuarios
                        </div>
                        <div className="group-14-item">
                          <img className="group-14-checkmark" alt="Checkmark" src="/images/img/frame-109.svg"/>
                          Productos
                        </div>
                        <div className="group-14-item">
                          <img className="group-14-checkmark" alt="Checkmark" src="/images/img/frame-109.svg"/>
                          Instalaciones
                        </div>
                        <div className="group-14-item">
                          <img className="group-14-checkmark" alt="Checkmark" src="/images/img/frame-109.svg"/>
                          Mi red
                        </div>
                        
                        <p className="text-wrapper-143">Controles</p>
                        <div className="group-14-item">
                          <img className="group-14-checkmark" alt="Checkmark" src="/images/img/frame-109.svg"/>
                          Pedidos
                        </div>
                        <div className="group-14-item">
                          <img className="group-14-checkmark" alt="Checkmark" src="/images/img/frame-109.svg"/>
                          Órdenes
                        </div>
                        <div className="group-14-item">
                          <img className="group-14-checkmark" alt="Checkmark" src="/images/img/frame-109.svg"/>
                          <span>
                            Embarques <u>versión Lite</u> 
                            <img className="group-14-checkmark-small" alt="Checkmark" src="/images/img/frame-109-1.svg"/> <u>versión Premium</u> pago por embarque con Orbibucks*.
                          </span>
                        </div>

                        <p className="text-wrapper-143">Reportes</p>
                        <div className="group-14-item">
                          <img className="group-14-checkmark" alt="Checkmark" src="/images/img/frame-109-1.svg"/>
                          Utilización
                        </div>
                        <div className="group-14-item">
                          <img className="group-14-checkmark" alt="Checkmark" src="/images/img/frame-109-1.svg"/>
                          Alertas
                        </div>
                        <div className="group-14-item">
                          <img className="group-14-checkmark" alt="Checkmark" src="/images/img/frame-109-1.svg"/>
                          Remolques
                        </div>
                        <div className="group-14-item">
                          <img className="group-14-checkmark" alt="Checkmark" src="/images/img/frame-109-1.svg"/>
                          Inteligencia de negocios con IA
                        </div>

                      </div>
                    </Slide>
                    <Slide index={1}>
                      <div className="group-14-container">
                        <p className="text-wrapper-144">Plan Premium</p>
                        <p className="text-wrapper-145">$1,199 USD/mes + iva / mes</p>
                        
                        <p className="text-wrapper-143">Catálogos</p>
                        <div className="group-14-item">
                          <img className="group-14-checkmark" alt="Checkmark" src="/images/img/frame-109.svg"/>
                          <span>Todos los del <b>Plan Básico</b> más:</span>
                        </div>
                        <div className="group-14-item">
                          <img className="group-14-checkmark" alt="Checkmark" src="/images/img/frame-109.svg"/>
                          Flotilla compartida
                        </div>
                        
                        <p className="text-wrapper-143">Controles</p>
                        <div className="group-14-item">
                          <img className="group-14-checkmark" alt="Checkmark" src="/images/img/frame-109.svg"/>
                          <span>Todos los del <b>Plan Básico</b> más:</span>
                        </div>
                        <div className="group-14-item">
                          <img className="group-14-checkmark" alt="Checkmark" src="/images/img/frame-109.svg"/>
                          <span>Embarques <u>versión Premium</u> </span>
                        </div>
                        <div className="group-14-item">
                          <img className="group-14-checkmark" alt="Checkmark" src="/images/img/frame-109.svg"/>
                          Contratos
                        </div>

                        <p className="text-wrapper-143">Reportes</p>
                        <div className="group-14-item">
                          <img className="group-14-checkmark" alt="Checkmark" src="/images/img/frame-109.svg"/>
                          Utilización
                        </div>
                        <div className="group-14-item">
                          <img className="group-14-checkmark" alt="Checkmark" src="/images/img/frame-109.svg"/>
                          Alertas
                        </div>
                        <div className="group-14-item">
                          <img className="group-14-checkmark" alt="Checkmark" src="/images/img/frame-109.svg"/>
                          Remolques
                        </div>
                        <div className="group-14-item">
                          <img className="group-14-checkmark" alt="Checkmark" src="/images/img/frame-109.svg"/>
                          Disponibilidad
                        </div>
                        <div className="group-14-item">
                          <img className="group-14-checkmark" alt="Checkmark" src="/images/img/frame-109.svg"/>
                          Inteligencia de negocios con IA
                        </div>

                      </div>
                    </Slide>
                  </>)
                  : (
                    <Slide index={0} className="slide">
                      <div className="group-14-container">
                        <p className="text-wrapper-141">Plan Básico</p>
                        <p className="text-wrapper-142">Gratis</p>
                        
                        <p className="text-wrapper-143">Catálogos</p>
                        <div className="group-14-item">
                          <img className="group-14-checkmark" alt="Checkmark" src="/images/img/frame-109.svg"/>
                          Usuarios
                        </div>
                        <div className="group-14-item">
                          <img className="group-14-checkmark" alt="Checkmark" src="/images/img/frame-109.svg"/>
                          Flotilla: vehículos, equipos y operadores
                        </div>
                        <div className="group-14-item">
                          <img className="group-14-checkmark" alt="Checkmark" src="/images/img/frame-109.svg"/>
                          Mi red
                        </div>
                        
                        <p className="text-wrapper-143">Controles</p>
                        <div className="group-14-item">
                          <img className="group-14-checkmark" alt="Checkmark" src="/images/img/frame-109.svg"/>
                          Ensambles
                        </div>
                        <div className="group-14-item">
                          <img className="group-14-checkmark" alt="Checkmark" src="/images/img/frame-109-1.svg"/>
                          <span>Embarques <u>versión Premium</u> pago por embarque con Orbibucks*.</span>
                        </div>
                        <div className="group-14-item">
                          <img className="group-14-checkmark" alt="Checkmark" src="/images/img/frame-109.svg"/>
                          Contratos
                        </div>
                        <div className="group-14-item">
                          <img className="group-14-checkmark" alt="Checkmark" src="/images/img/frame-109.svg"/>
                          Recursos
                        </div>

                        <p className="text-wrapper-143">Reportes</p>
                        <div className="group-14-item">
                          <img className="group-14-checkmark" alt="Checkmark" src="/images/img/frame-109-1.svg"/>
                          Utilización
                        </div>
                        <div className="group-14-item">
                          <img className="group-14-checkmark" alt="Checkmark" src="/images/img/frame-109-1.svg"/>
                          Remolques
                        </div>
                        <div className="group-14-item">
                          <img className="group-14-checkmark" alt="Checkmark" src="/images/img/frame-109.svg"/>
                          Disponibilidad
                        </div>
                        <div className="group-14-item">
                          <img className="group-14-checkmark" alt="Checkmark" src="/images/img/frame-109-1.svg"/>
                          CFDIs
                        </div>
                        <div className="group-14-item">
                          <img className="group-14-checkmark" alt="Checkmark" src="/images/img/frame-109-1.svg"/>
                          Inteligencia de negocios con IA
                        </div>

                      </div>
                    </Slide>
                  )
                }
              </Slider>
              <div className="controls">
                <ButtonBack className="btn-arrow reverse-arrow">
                  <img src="/images/img/carousel-arrow.svg" alt="arrow" />
                </ButtonBack>
                <DotGroup className="dot-group" />
                <ButtonNext className="btn-arrow">
                  <img src="/images/img/carousel-arrow.svg" alt="arrow" />
                </ButtonNext>
              </div>
            </div>
          </CarouselProvider>
        </div>

        <div className="group-14-2">

          <div className="group-14-item">
            <img className="group-14-checkmark" alt="Checkmark" src="/images/img/frame-109.svg"/>
            Incluido de forma ilimitada.
          </div>
          <div className="group-14-item">
            <img className="group-14-checkmark" alt="Checkmark" src="/images/img/frame-109-1.svg"/>
            Incluido solo para los embarques version premium pagados por embarque con Orbibucks.
          </div>
          <div className="group-14-item">
            <img className="group-14-checkmark" alt="Checkmark" src="/images/img/group-286.svg"/>
            *Los embarques premium en modalidad "Pago por embarque con Orbibucks"  están sujetos a la tarifa de Orbibucks aplicable por tipo de embarque: FTL (camión completo) o LTL (carga consolidada).
          </div>
        </div>

        <div className="group-14-3">
          <p className="text-wrapper-147">Tarifas Orbibucks por tipo de embarque:</p>
          <div className="group-14-item">
            <img className="group-14-checkmark" alt="Checkmark" src="/images/img/group-286-1.svg"/>
            <div className="group-14-item-description">
              <div className="text-wrapper-148">FTL Camión completo:</div>
              <div className="text-wrapper-149">$1.00 Orbibucks por remolque por embarque.</div>
            </div>
          </div>
          <div className="group-14-item">
            <img className="group-14-checkmark" alt="Checkmark" src="/images/img/group-286-1.svg"/>
            <div className="group-14-item-description">
              <div className="text-wrapper-148">LTL Carga consolidada:</div>
              <div className="text-wrapper-149">$0.40 Orbibucks por pedido/entrega.</div>
            </div>
          </div>
        </div>

        <div className="frame-69-1">
          <p className="text-wrapper-691">Precios Orbibucks</p>
          <div className="frame-69-container">
            <img className="frame-69-checkmark" alt="Checkmark" src="/images/img/group-286-2.svg"/>
            <div className="frame-69-item-description">
              <div className="text-wrapper-692">100 Orbibucks</div>
              <div className="text-wrapper-693">$190 USD + IVA</div>
              <div className="text-wrapper-694">P. Unitario $1.90 USD + IVA</div>
            </div>
            <div className="frame-69-extra-info">

            </div>
          </div>
          <div className="frame-69-container extra-info">
            <img className="frame-69-checkmark" alt="Checkmark" src="/images/img/group-286-2.svg"/>
            <div className="frame-69-item-description">
              <div className="text-wrapper-692">200 Orbibucks</div>
              <div className="text-wrapper-693">$340 USD + IVA</div>
              <div className="text-wrapper-694">P. Unitario $1.70 USD + IVA</div>
            </div>
            <div className="frame-69-extra-info">
              Ahorra
              <p className="text-wrapper-695">+10%</p>
            </div>
          </div>
          <div className="frame-69-container extra-info">
            <img className="frame-69-checkmark" alt="Checkmark" src="/images/img/group-286-2.svg"/>
            <div className="frame-69-item-description">
              <div className="text-wrapper-692">500 Orbibucks</div>
              <div className="text-wrapper-693">$750 USD + IVA</div>
              <div className="text-wrapper-694">P. Unitario $1.50 USD + IVA</div>
            </div>
            <div className="frame-69-extra-info">
              Ahorra
              <p className="text-wrapper-695">+20%</p>
            </div>
          </div>
          <div className="frame-69-container extra-info">
            <img className="frame-69-checkmark" alt="Checkmark" src="/images/img/group-286-2.svg"/>
            <div className="frame-69-item-description">
              <div className="text-wrapper-692">1000+ Orbibucks</div>
              <div className="text-wrapper-693">$1300 USD + IVA</div>
              <div className="text-wrapper-694">P. Unitario $1.30 USD + IVA</div>
            </div>
            <div className="frame-69-extra-info">
              Ahorra
              <p className="text-wrapper-695">+30%</p>
            </div>
          </div>
        </div>

      </div>

      <div className="frame-75">
        <div className="group-41">
          <div className="overlap-10">
            <div className="rectangle-13" />

            <div className="frame-77">
              <div className="frame-78">
                <div className="frame-79">
                  <p className="text-wrapper-60">
                    Decide transformarte y únete a la comunidad Orbinetwork hoy
                    mismo.
                  </p>

                  <p className="adem-s-recibe">
                    {
                      this.state.mode === 3 ? 
                      <>Además <b>gana cupones de hasta 50% de descuento</b> por invitar a tus clientes a que se únan y disfruten juntos los beneficios de Orbinetwork.</>
                      : <>Además <b>gana cupones de hasta 50% de descuento</b> por invitar a tus clientes, proveedores y transportistas a que se únan y disfruten juntos los beneficios de Orbinetwork.</>
                    }
                  </p>
                </div>

                <div className="frame-80">
                  <div className="CTA-primary-wrapper">
                    <div className="CTA-primary-4">
                      <div className="rectangle-20" />

                      <Link to={`/survey`}>
                        <div className="crea-tu-cuenta-7">Solicitar invitación</div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

              <div className="group-43">
                <img
                  className="cloud-copy"
                  alt="Cloud copy"
                  src="/images/img/group-135.svg"
                />
              </div>
            </div>

            <div style={{height: 50}}/>
            {/* <div className="group-76">
              <div className="frame-76">
                <div className="text-wrapper-59">Respaldo</div>

                <p className="text-wrapper-4">
                  Organizaciones y certificaciones que respaldan nuestro trabajo
                </p>
              </div>

              <div className="group-42">
                <div className="group-42-1">
                  <div className="rectangle-14" />

                  <div className="rectangle-14" />

                  <div className="rectangle-14" />
                </div>
                <div className="group-42-1">
                  <div className="rectangle-14" />

                  <div className="rectangle-14" />

                  <div className="rectangle-14" />
                </div>

              </div>
            </div> */}

            
          </div>
        </div>
      </div>

      <div className="footer">
        <div className="footer-3">
          <div className="frame-81">
            <div className="text-wrapper-61">Dueños de Carga</div>
          </div>

          <img className="icon" alt="Icon" src="/images/img/icon-1.svg" />

          <div className="frame-82">
            <div className="text-wrapper-61">Transportistas</div>
          </div>
        </div>
        {/* <img className="footer-2" alt="Footer" src="/images/img/footer.svg" /> */}

        <div className="footer-2">
          <a href="https://www.tiktok.com/@orbinetwork" target="_blank" style={{textDecoration: 'none'}}>
            <img alt="tiktok" src="https://orbiback.qa.orbinetwork.com/static_backend/img/tiktok.png" style={{cursor: 'pointer'}}/>
          </a>
          <a href="http://Instagram.com/orbinetwork" target="_blank" style={{textDecoration: 'none'}}>
            <img alt="instagram" src="https://orbiback.qa.orbinetwork.com/static_backend/img/instagram.png" style={{cursor: 'pointer'}}/>
          </a>
          <a href="http://facebook.com/orbinetwork" target="_blank" style={{textDecoration: 'none'}}>
            <img alt="fb" src="https://orbiback.qa.orbinetwork.com/static_backend/img/fb.png" style={{cursor: 'pointer'}}/>
          </a>
          <a href="http://x.com/orbinetwork" target="_blank" style={{textDecoration: 'none'}}>
            <img alt="x" src="https://orbiback.qa.orbinetwork.com/static_backend/img/x.png" style={{cursor: 'pointer'}}/>
          </a>
          <a href="https://www.linkedin.com/company/orbinetworkcom/" target="_blank" style={{textDecoration: 'none'}}>
            <img alt="in" src="https://orbiback.qa.orbinetwork.com/static_backend/img/in.png" style={{cursor: 'pointer'}}/>
          </a>
        </div>

      </div>

      
      <LoginDetailModal
        open={this.state.showViewDetail}
        img={this.state.detailImg}
        title={this.state.detailTitle}
        message={this.state.detailMessage}
        closeAction={() => this.setState( { showViewDetail: false, detailImg: '', detailTitle: '', detailMessage: '' })}
      />

      <LoginDialog
        open={this.state.showLoginModal}
        title="Iniciar sesión"
        isMobile={this.state.isMobileDevice}
        closeAction={() => this.closeModals()}
        closure={() => this.setState({ showRegisterModal: true })}
        showError={(errorMessage) => {
          this.setState({ errorMessage });
        }}
        forgotAction={this.forgotModal}
      />

      <RegisterDialog
        open={this.state.showRegisterModal}
        title="Crea tu cuenta"
        isMobile={this.state.isMobileDevice}
        closeAction={() => this.closeModals()}
        closure={() => {
          if (this.state.emailRegister !== '') {
            this.setState({ showRegisterInvitationMessageModal: true, emailRegister: "" });
            return;
          }

          if (this.state.emailInvitation === "") {
            this.setState({ showRegisterMessageModal: true });
          } else {
            this.setState({ showRegisterInvitationMessageModal: true });
          }
        }}
        showError={(errorMessage) => {
          this.setState({ errorMessage });
        }}
        emailInvitation={this.state.emailInvitation}
        hasInvitation={this.state.hasInvitation}
        changeToLogin={() => this.changeToLogin()}
        emailRegister={this.state.emailRegister}
      />

      <ResetNewPasswordDialog
        open={this.state.showResetNewPasswordModal}
        token={this.state.token}
        title="Crea contraseña nueva"
        closeAction={() => this.closeModals()}
        closure={() => this.setState({ showResetNewPasswordMessage: true })}
        showError={(errorMessage) => {
          this.setState({ errorMessage });
        }}
      />

      <ResetPasswordMessageDialog
        open={this.state.showResetNewPasswordMessage}
        message="Tu contraseña fue modificada con éxito"
        acceptAction={() => {
          this.closeModals();
          this.setState({ showLoginModal: true });
        }}
        closeAction={() => {
          this.closeModals();
          this.setState({ showLoginModal: true });
        }}
      />

      <RecoverPasswordDialog
        open={this.state.showRecoverPasswordModal}
        title="Recupera tu contraseña"
        closeAction={() => this.closeModals()}
        closure={() => this.setState({ showRecoverMessageModal: true })}
      />

      <GenericDialog
        open={this.state.showSessionError}
        title="¡Error!"
        message="La sesión se ha expirado"
        acceptAction={() => this.closeModals()}
        closeAction={() => this.closeModals()}
      />

      <GenericDialog
        open={this.state.showRecoverMessageModal}
        title="¡Enviado!"
        message="Te hemos enviado un correo, para que puedas cambiar tu contraseña"
        acceptAction={() => this.closeModals()}
        closeAction={() => this.closeModals()}
      />

      <GenericDialog
        open={this.state.showSuccessValidation}
        title="Éxito"
        message="Tu email ha sido verificado."
        acceptAction={() => this.closeModals()}
        closeAction={() => this.closeModals()}
      />

      <GenericDialog
        open={this.state.showSuccessInvitation}
        title="Éxito"
        message="La invitación ha sido aceptada."
        acceptAction={() => this.closeModals()}
        closeAction={() => this.closeModals()}
      />

      <GenericDialog
        open={this.state.showRegisterMessageModal}
        title="¡Listo!"
        message="Te hemos enviado un correo, para que confirmes tu cuenta"
        acceptAction={() => {
          this.closeModals();
          window.location.href = "/register/account/";
        }}
        closeAction={() => this.closeModals()}
      />

      <GenericDialog
        open={this.state.showRegisterInvitationMessageModal}
        title="¡Listo!"
        message="Tu cuenta ha sido creada con éxito"
        acceptAction={() => {
          this.closeModals();
          window.location.href = "/register/account/";
        }}
        closeAction={() => this.closeModals()}
      />

      <ErrorDialog
        addClass="invitation-modal"
        open={this.state.showInvitationModal}
        message="Ya tienes una cuenta"
        acceptText="Iniciar Sesión"
        acceptAction={() => {
          this.setState({ showInvitationModal: false, showLoginModal: true });
        }}
        closeAction={() => {
          this.setState({ showInvitationModal: false });
        }}
      />
      
      <LoginErrorDialog
        open={this.state.errorMessage !== ""}
        message={this.state.errorMessage}
        acceptText="Aceptar"
        acceptAction={() => {
          this.closeModals();
          this.setState({ emailInvitation: "" });
        }}
        openCreateAccount={ () => {
          this.setState({
            errorMessage: '',
            showRegisterModal: true,
          });
        }}
        openForgetPassword={() => {
          this.setState({
            errorMessage: '',
            showRecoverPasswordModal: true,
          });
        }}
        closeAction={() => {
          this.closeModals();
          this.setState({ emailInvitation: "" });
        }}
      />

    </div>
  );
  }
}

export default Login;