import React, { Component } from "react";
import { connect } from "react-redux";
import ReactDOM from "react-dom";

import { MapController } from "../../shared/component/map-container";
import Button from "../../shared/controls/button";
import Tooltip from "../../shared/component/tooltip";
import TextInput from "../../shared/controls/text-input";
import ConfirmDialog from "../../shared/dialog/confirm-dialog";
import { loadGiTypes } from "../../redux/reducers/facility-create.reducer";
import {
  createGI,
  loadGIByFacilityId,
  deleteGI,
  updateGI,
  loadAvatares
} from "../../redux/reducers/facility-reducer";
// import SelectInput from '../../shared/controls/select-input';
import { BACKEND } from "../../shared/backend-api";
import ValidatePerms from "../../shared/component/validate-perms";

const STAND_BY_MODE = 0;
const SELECT_TYPE_MODE = 1;
const CREATE_MODE = 2;
const EDIT_MODE = 3;

class FacilityGeofences extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: true,
      geofences: [],
      currentGeofence: {},
      mode: props.edit ? STAND_BY_MODE : SELECT_TYPE_MODE,
      showDialog: false,
      isCreatingGeofence: false
    };
    this.dialog = "";
    this.mapController = new MapController();
  }
  firstLoad = true;
  idCounter = -999999;

  componentDidMount() {
    this.mapController.loadMap(null, null, null, "satellite", false);
    this.mapController.isReadySubject.subscribe(ready => {
      if (ready) {
        this.marker = this.mapController.createMarker();
        this.marker.setIcon("/images/icon_factory_map.svg");
        this.marker.setMap(this.mapController.map);
        let latLng = {
          lat: this.props.facility.geopos_x,
          lng: this.props.facility.geopos_y
        };
        this.mapController.setCenterMap(latLng);
        this.mapController.setMarker(latLng, this.marker);
        this.mapController.map.setZoom(16);
        if (this.props.facility && this.props.facility.id !== 0) {
          this.loadGeofences();
        }
      }
    });
  }
  componentDidUpdate(prevProps) {
    if (
      prevProps.facility.id !== this.props.facility.id &&
      this.mapController.map &&
      window.google.maps.Polygon
    )
      this.loadGeofences();
    if (
      this.mapController.map &&
      this.props.avatar &&
      this.props.avatar.length
    ) {
      let avatar = this.props.avatar.find(
        av => av.id.toString() === this.props.facility.avatar.toString()
      );
      this.marker.setIcon(
        BACKEND.FILES_SERVER.concat(
          (avatar || {}).image || "/images/icon_factory_map.svg"
        )
      );
    }
  }

  loadGeofences = () => {
    this.props
      .loadGeofences(this.props.facility.id)
      .then(result => {
        this.setState({
          geofences: (Array.isArray(result.response) ? result.response : [])
            .filter(item => item.gi_type !== 4)
            .map(item => {
              item.geo_data.pop();
              let pol = this.mapController.createPolygon(
                item.geo_data.map(g => ({ lat: g[0], lng: g[1] })),
                GEOFENCE_TYPES.getById(item.gi_type).color
              );
              item.clickListener = window.google.maps.event.addListener(
                pol,
                "click",
                () => {
                  this.handleEditGeofence(pol);
                }
              );
              return buildGeofence(item, pol);
            })
        });
        return (Array.isArray(result.response) ? result.response : []).filter(r => r.gi_type === 4);
      })
      .then(() => {
        this.showGeofences();
        if (
          this.state.geofences.find(gf => gf.GF_TYPE.name === PROXIMITY)
            ? false
            : true && this.firstLoad && !this.props.edit
        ) {
          this.setCurrentGeofenceType(GEOFENCE_TYPES[PROXIMITY].id);
          this.firstLoad = false;
        }
      });
  };

  // Main handlers
  handleCollapse = () => {
    this.setState({ collapsed: !this.state.collapsed });
  };
  handleAddGeofence = () => {
    if (this.state.mode === SELECT_TYPE_MODE) {
      this.setState({
        mode: STAND_BY_MODE,
        currentGeofence: {}
      });
    } else if (
      this.state.mode === CREATE_MODE ||
      this.state.mode === EDIT_MODE
    ) {
      return;
    } else {
      if (
        this.state.geofences.find(gf => gf.GF_TYPE.name === PROXIMITY)
          ? false
          : true
      ) {
        this.setCurrentGeofenceType(GEOFENCE_TYPES[PROXIMITY].id);
      }
      this.setState({ mode: SELECT_TYPE_MODE });
    }
  };

  // Controls
  geoFenceTypesControl = (enableClick = false) => {
    let currentType = this.state.currentGeofence.GF_TYPE || {};
    return (
      <div
        className="geofence-type-selector"
        onClick={enableClick ? this.handleGeofenceTypeSelection : () => {}}
      >
        <div
          name={PROXIMITY}
          className={PROXIMITY.concat(
            GEOFENCE_TYPES[PROXIMITY].id === currentType.id ? " selected" : ""
          )}
        >
          Proximidad
        </div>
        <div
          name={GATE}
          className={GATE.concat(
            GEOFENCE_TYPES[GATE].id === currentType.id ? " selected" : ""
          )}
        >
          Entradas
        </div>
        <div
          name={VALIDATION}
          className={VALIDATION.concat(
            GEOFENCE_TYPES[VALIDATION].id === currentType.id ? " selected" : ""
          )}
        >
          Validación
        </div>
      </div>
    );
  };
  getGeofenceItemControls = items => {
    items = items.sort((a, b) => {
      return a.gi_type - b.gi_type;
    });
    return items.map((item, index) => {
      return (
        <div key={index}>
          {" "}
          {items[index - 1] && items[index - 1].gi_type === item.gi_type
            ? ""
            : item.GF_TYPE.description}
          <div
            name={item.gi_type || item.description}
            key={item.gi_type || item.description}
            className={"geofence-item-control ".concat(
              item.GF_TYPE.name,
              items[index + 1] && items[index + 1].gi_type !== item.gi_type
                ? " last"
                : ""
            )}
          >
            <div className="dot"></div>
            <div className="description">{item.description}</div>
            <div className="geo-actions">
              <ValidatePerms
                perms={[
                  {
                    id: "31",
                    perm: "d"
                  }
                ]}
              >
                <div
                  className="delete"
                  onClick={() => this.handleRemoveGeofence(item)}
                ></div>
              </ValidatePerms>
              <ValidatePerms
                perms={[
                  {
                    id: "31",
                    perm: "u"
                  }
                ]}
              >
                <div
                  className="edit"
                  onClick={() => this.handleEditGeofence(item.polygon)}
                ></div>
              </ValidatePerms>
            </div>
          </div>
        </div>
      );
    });
  };
  getGeofenceCollapsedControls = items => {
    return [
      GEOFENCE_TYPES[PROXIMITY],
      GEOFENCE_TYPES[GATE],
      GEOFENCE_TYPES[VALIDATION]
      // GEOFENCE_TYPES[RAMP]
    ].map(geofenceType => {
      let counter = items.filter(item => item.gi_type === geofenceType.id)
        .length;
      if (counter > 0) {
        return (
          <div
            key={geofenceType.id}
            className={"geofence-item-control ".concat(geofenceType.name)}
          >
            <div className="dot">
              <span>{counter}</span>
            </div>
          </div>
        );
      } else return "";
    });
  };

  // Control handlers
  handleGeofenceTypeSelection = event => {
    let hasProximity = this.state.geofences.find(
      gf => gf.GF_TYPE.name === PROXIMITY
    );
    if (event.target.attributes["name"].value === PROXIMITY && hasProximity) {
      return;
    }
    if (event.target.attributes["name"].value !== PROXIMITY && !hasProximity) {
      return;
    }
    this.setCurrentGeofenceType(
      GEOFENCE_TYPES[event.target.attributes["name"].value].id
    );
  };
  handleRemoveGeofence = geofence => {
    if (this.state.mode !== STAND_BY_MODE) return;
    this.setState({ currentGeofence: { id: geofence.id } });
    this.showRemoveDialog();
  };
  handleEditGeofence = polygon => {
    if (this.state.mode !== STAND_BY_MODE) return;
    const self = this;

    this.state.geofences.forEach(gf => {
      if (gf.polygon === polygon) {
        let path = polygon.getPath();
        let p = new window.google.maps.Polygon({
          map: this.mapController.map,
          paths: path,
          fillColor: gf.GF_TYPE.color,
          fillOpacity: 0.2,
          strokeColor: gf.GF_TYPE.color,
          strokeWeight: 2,
          editable: true
        });

        window.google.maps.event.addListener(p, 'dblclick', function(event) {
          if (event.vertex == undefined) {
            return;
          } else {
            let path = p.getPath();
            path.removeAt(event.vertex);
          }
        });

        window.google.maps.event.addListener(p, 'mousedown', function() {
          self.mapController.hideInfoWindow();
        });
        
        window.google.maps.event.addListener(p, 'mouseup', function() {
            let currentPosition = new window.google.maps.LatLng(0, -180);
            p.getPath().forEach(
              x => (currentPosition = x.lng() > currentPosition.lng() ? x : currentPosition)
            );
            self.mapController.showInfoWindow(content, currentPosition, 165, 100, () =>
              self.editNameInfowindowActions(gf.description)
            );
        });

        this.setState({ 
          currentGeofence: Object.assign({
            polygon: p,
            // color: gf.color,
            id: gf.id,
            description: gf.description,
            typeId: gf.typeId,
            GF_TYPE: gf.GF_TYPE,
            rampType: gf.rampType || 0,
            rampItems: gf.rampItems || []
          }),
          mode: EDIT_MODE,
          collapsed: false
        });
        polygon.setVisible(false);

        let position = new window.google.maps.LatLng(0, -180);
        p.getPath().forEach(
          x => (position = x.lng() > position.lng() ? x : position)
        );
        // if (gf.GF_TYPE.name !== RAMP) {
        const content = `<div id='infowindowitem' class='info-window-item' ></div>`;
        this.mapController.showInfoWindow(content, position, 165, 100, () =>
          this.editNameInfowindowActions(gf.description)
        );
        // }
        //  else {
        //   const content = `<div id='infowindowitem' class='info-window-item ramp' ></div>`;
        //   this.mapController.showInfoWindow(content, position, 275, 200, this.editRampInfowindowActions);
        // }
      }
    });
  };

  // State modfiers
  setCurrentGeofenceType = typeId => {
    // let gfType = GEOFENCE_TYPES.getById(typeId) || null;
    let gfType = GEOFENCE_TYPES.getById(typeId) || {
      name: "",
      id: 0,
      color: ""
    };
    this.setState({
      currentGeofence: Object.assign({}, this.state.currentGeofence, {
        GF_TYPE: gfType,
        gi_type: typeId
      })
    });
  };
  setcurrentGeofence = polygon => {
    this.setState({
      currentGeofence: Object.assign({}, this.state.currentGeofence, {
        polygon: polygon
      })
    });
  };

  render() {
    return (
      <div className="facility-geofences">
        <div className="title">2.1 Geocercas:</div>
        <div
          className={"content".concat(this.state.collapsed ? " collapsed" : "")}
        >
          <div className="map">
            <ValidatePerms
              perms={[
                {
                  id: "31",
                  perm: "c"
                }
              ]}
            >
              <Button
                settings={{
                  type: "circle active add-geofence",
                  text: "+",
                  onClick: this.handleAddGeofence
                }}
              />
            </ValidatePerms>
            <Tooltip
              isOpen={this.state.mode === SELECT_TYPE_MODE}
              onClose={this.handleAddGeofence}
              title={"¿Que tipo de geocerca?"}
              actionText={"Crear"}
              content={this.geoFenceTypesControl(true)}
              className="add-geofence-tooltip"
              onAction={this.drawNewGeofence}
              selectedItem={(this.state.currentGeofence || {}).gi_type}
            />
            <div className="selected-geofence-type">
              {this.state.mode === CREATE_MODE || this.state.mode === EDIT_MODE
                ? this.geoFenceTypesControl()
                : ""}
            </div>

            {this.mapController.getMapContainer({ className: "map-view" })}
          </div>
          <div className="collapser" onClick={this.handleCollapse}></div>
          <div className="controls">
            {this.state.collapsed
              ? this.getGeofenceCollapsedControls(this.state.geofences)
              : this.getGeofenceItemControls(this.state.geofences)}
          </div>
        </div>
        <ConfirmDialog
          closeAction={this.dialog.closeAction}
          open={this.state.showDialog}
          title={this.dialog.title}
          message={this.dialog.message}
          acceptText={this.dialog.acceptText}
          acceptAction={this.dialog.acceptAction}
          showCancel={this.dialog.showCancel}
          cancelText={this.dialog.CancelText}
        />
      </div>
    );
  }

  // Create polygon
  drawNewGeofence = () => {
    if (this.state.currentGeofence.GF_TYPE) {
      this.setState({ mode: CREATE_MODE });
      this.mapController.drawPolygon(
        this.state.currentGeofence.GF_TYPE.color,
        this.onCompletePolygon
      );
    }
  };
  onCompletePolygon = polygon => {
    this.setcurrentGeofence(polygon);
    polygon.setEditable(true);
    const self = this;
    const content = `<div id='infowindowitem' class='info-window-item' ></div>`;

    window.google.maps.event.addListener(polygon, 'dblclick', function(event) {
      if (event.vertex == undefined) {
        return;
      } else {
        let path = polygon.getPath();
        path.removeAt(event.vertex);
      }
    });

    window.google.maps.event.addListener(polygon, 'mousedown', function() {
      self.mapController.hideInfoWindow();
    });
    
    window.google.maps.event.addListener(polygon, 'mouseup', function() {
        let currentPosition = new window.google.maps.LatLng(0, -180);
        polygon.getPath().forEach(
          x => (currentPosition = x.lng() > currentPosition.lng() ? x : currentPosition)
        );
        self.mapController.showInfoWindow(content, currentPosition, 165, 100, self.CreateNameInfowindowActions
        );
    });

    let position = new window.google.maps.LatLng(0, -180);
    polygon
      .getPath()
      .forEach(x => (position = x.lng() > position.lng() ? x : position));

    if (this.state.currentGeofence.GF_TYPE.name) {
      this.mapController.showInfoWindow(
        content,
        position,
        165,
        100,
        this.CreateNameInfowindowActions
      );
    }
  };
  CreateNameInfowindowActions = (event = { target: { value: "" } }) => {
    let infoWindow = document.getElementById("infowindowitem");
    ReactDOM.render(
      geofenceNameTooltip(
        event.target.value,
        this.handleCreateGeofenceNameInput,
        this.handleCancelCreateGeofence,
        this.handleCreateGeofenceSave
      ),
      infoWindow
    );
  };
  handleCreateGeofenceNameInput = event => {
    this.setState({
      currentGeofence: Object.assign({}, this.state.currentGeofence, {
        description: event.target.value
      })
    });
    this.CreateNameInfowindowActions(event);
  };
  handleCreateGeofenceSave = () => {
    if (this.state.isCreatingGeofence) {
      return;
    }

    this.setState({
      isCreatingGeofence: true
    });
    
    this.state.currentGeofence.polygon.setMap(null);
    let path = this.state.currentGeofence.polygon.getPath();
    let p = new window.google.maps.Polygon({
      map: this.mapController.map,
      paths: path,
      fillColor: this.state.currentGeofence.GF_TYPE.color,
      fillOpacity: 0.2,
      strokeColor: this.state.currentGeofence.GF_TYPE.color,
      strokeWeight: 2
    });
    let clickListener = window.google.maps.event.addListener(p, "click", () => {
      this.handleEditGeofence(p);
    });
    let geofence = Object.assign(this.state.currentGeofence, {
      polygon: p,
      clickListener: clickListener,
      id: 0
    });

    this.props
      .createGeofence(
        geofence.description,
        this.props.facility.id,
        geofence.gi_type,
        path.getArray()
      )
      .then(result => {
        geofence.id = result.response.id;
        this.setState({
          isCreatingGeofence: false,
          geofences: [...this.state.geofences, geofence],
          currentGeofence: {},
          mode: STAND_BY_MODE
        });
        this.mapController.hideInfoWindow();
      });
    // }
  };
  handleCancelCreateGeofence = () => {
    this.mapController.hideInfoWindow();
    this.removecurrentGeofence();
    this.setState({
      mode: STAND_BY_MODE,
      currentGeofence: {}
    });
  };
  handleCreateCancelConfirmDialogResult = result => {
    if (result) {
      this.handleCancelCreateGeofence();
    }
    this.closeDialog();
  };

  // Edit polygon
  editNameInfowindowActions = (name = "") => {
    let infoWindow = document.getElementById("infowindowitem");
    ReactDOM.render(
      geofenceNameTooltip(
        name,
        this.handleGeofenceNameEditInput,
        this.handleGeofenceEditCancel,
        this.handleEditGeofenceSave,
        true
      ),
      infoWindow
    );
  };
  handleGeofenceNameEditInput = event => {
    this.setState({
      currentGeofence: Object.assign({}, this.state.currentGeofence, {
        description: event.target.value
      })
    });
    this.editNameInfowindowActions(event.target.value);
  };
  handleGeofenceEditCancel = () => {
    this.mapController.hideInfoWindow();
    this.state.currentGeofence.polygon.setEditable(false);
    this.state.currentGeofence.polygon.setVisible(false);
    this.state.currentGeofence.polygon.setMap(null);
    this.setState({
      mode: STAND_BY_MODE,
      currentGeofence: {}
    }); 
    //this.showGeofences();

    this.removeGeofences();
    this.loadGeofences();
  };
  handleEditGeofenceSave = () => {
    this.state.currentGeofence.polygon.setMap(null);
    let path = this.state.currentGeofence.polygon.getPath();
    let geofence = this.state.geofences.find(
      gf => gf.id === this.state.currentGeofence.id
    );
    geofence.polygon.setPath(path);
    geofence.polygon.setVisible(true);
    geofence.description = this.state.currentGeofence.description;

    geofence.rampType = this.state.currentGeofence.rampType;
    geofence.rampItems = this.state.currentGeofence.rampItems;

    this.props.updateGeofence(
      geofence.id,
      geofence.description,
      path.getArray(),
      this.props.facility.id,
      geofence.gi_type
    );

    this.setState({
      mode: STAND_BY_MODE,
      currentGeofence: {}
    });
    this.mapController.hideInfoWindow();
  };

  // remove polygon
  showRemoveDialog = () => {
    this.dialog = {
      closeAction: this.handleRemoveConfirmDialogResult,
      open: this.state.showDialog,
      title: "Eliminación de geocercas",
      message: "Si continúas se eliminará esta geocerca",
      acceptText: "Eliminar",
      acceptAction: this.handleRemoveConfirmDialogResult,
      showCancel: true,
      cancelText: "Cancelar"
    };
    this.setState({ showDialog: true });
  };
  handleRemoveConfirmDialogResult = result => {
    if (result) {
      this.removeGeofence();
    }
    this.closeDialog();
  };
  removeGeofence = () => {
    let filtered = this.state.geofences.filter(gf => {
      if (gf.id !== this.state.currentGeofence.id) {
        return gf;
      } else {
        gf.polygon.setMap(null);
        return null;
      }
    });
    this.props.removeGeofence(this.state.currentGeofence.id);

    this.setState({
      geofences: [...filtered],
      currentGeofence: {}
    });
  };
  removecurrentGeofence = () => {
    let polygon = this.state.currentGeofence.polygon;
    if (polygon) {
      this.mapController.removePolygon(polygon);
    }
  };
  showGeofences = () => {
    this.state.geofences.forEach(gf => {
      gf.polygon.setVisible(true);
      gf.polygon.setMap(this.mapController.map);
    });
  };

  removeGeofences = () => {
    this.state.geofences.forEach(gf => {
      if (gf.polygon) {
        this.mapController.removePolygon(gf.polygon);
      }
    });
  };

  // Dialogs
  showCancelCreateDialog = () => {
    this.dialog = {
      closeAction: this.handleCreateCancelConfirmDialogResult,
      open: this.state.showDialog,
      title: "Creación de geocercas",
      message:
        "¿Deseas salir de la edición? Si eliges salir, perderas los cambios no guardados a ests geocerca.",
      acceptText: "Salir",
      acceptAction: this.handleCreateCancelConfirmDialogResult,
      showCancel: true,
      cancelText: "Regresar"
    };
    this.setState({ showDialog: true });
  };
  showCancelEditDialog = () => {
    this.dialog = {
      closeAction: this.handleGeofenceEditCancel,
      open: this.state.showDialog,
      title: "Edición de geocercas",
      message: "Si continúas se perderan los últimos cambios a esta geocerca",
      acceptText: "Continuar",
      acceptAction: this.handleGeofenceEditCancel,
      showCancel: true,
      cancelText: "Regresar"
    };
    this.setState({ showDialog: true });
  };
  closeDialog = () => {
    this.dialog = "";
    this.setState({ showDialog: false });
  };
}

const mapStateToProps = state => {
  return {
    // facility: state.facilityCreate.facility,
    giTypes: state.facilityCreate.giTypes,
    // equipmentTypes: state.facilityCreate.equipmentTypes,
    // rampTypes: state.facilityCreate.rampTypes,
    avatar: state.facilityReducer.avatar.items || []
  };
};
const mapDispatchToProps = dispatch => {
  dispatch(loadGiTypes());
  dispatch(loadAvatares());
  return {
    createGeofence: (description, facility, gi_type, polygonPathArray) =>
      dispatch(createGI(description, facility, gi_type, polygonPathArray)),
    loadGeofences: facility_id => dispatch(loadGIByFacilityId(facility_id)),
    removeGeofence: facililty_gi_id => dispatch(deleteGI(facililty_gi_id)),
    updateGeofence: (
      facililty_gi_id,
      description,
      polygonPathArray,
      facility_Id,
      gi_type
    ) =>
      dispatch(
        updateGI(
          facililty_gi_id,
          description,
          polygonPathArray,
          facility_Id,
          gi_type
        )
      )
  };
};
const connectToStore = connect(mapStateToProps, mapDispatchToProps);

export default connectToStore(FacilityGeofences);

const PROXIMITY = "PROXIMITY";
const GATE = "GATE";
const VALIDATION = "VALIDATION";
// const RAMP = 'RAMP';

const GEOFENCE_TYPES = {
  PROXIMITY: {
    name: PROXIMITY,
    description: "Proximidad:",
    id: 1,
    color: "#55B740"
  },
  GATE: {
    name: GATE,
    description: "Entradas:",
    id: 2,
    color: "#5EB5FF"
  },
  VALIDATION: {
    name: VALIDATION,
    description: "Validaciones:",
    id: 3,
    color: "#FFF845"
  },
  // ,
  // RAMP: {
  //   name: RAMP,
  //   description: 'Rampas:',
  //   id: 4,
  //   color: '#E9643F'
  // },
  getById: id => {
    return (Object.entries(GEOFENCE_TYPES).find(i => (i[1] || {}).id === id) ||
      {})[1];
  }
};

const geofenceNameTooltip = (
  name,
  onChange,
  onCancel,
  onSave,
  isEdit = false
) => {
  return (
    <div className="tooltip-content">
      <div className="title dark">
        {isEdit ? "Editando:" : "Nombra tu geocerca:"}
      </div>
      <TextInput
        name="description"
        value={name}
        label="Ingresa el nombre de tu geocerca"
        onChange={onChange}
      />
      <div className="actions">
        <button className="primary outline small dark" onClick={onCancel}>
          Cancelar
        </button>
        <button
          className={"primary small ".concat(name ? "" : "disabled")}
          onClick={onSave}
        >
          Guardar
        </button>
      </div>
    </div>
  );
};
// const rampNameTooltip = (name, rampTypeId = 0, onChange, onCancel, onSave, rampTypes, isEdit = false) => {
//   return (
//     <div className='tooltip-content'>
//       <div className="title dark">{isEdit ? 'Editando:' : 'Nombra esta rampa:'}</div>
//       <TextInput
//         name='description'
//         value={name}
//         label='Ingresa el nombre de tu rampa'
//         onChange={onChange}
//       />
//       <SelectInput
//         name='rampType'
//         label='Tipo de rampa'
//         value={parseInt(rampTypeId)}
//         onChange={onChange}
//         items={rampTypes}
//       />
//       <div className='actions'>
//         <button
//           className='primary outline small dark'
//           onClick={onCancel} >Cancelar</button>
//         <button
//           className={'primary small '.concat(name && rampTypeId ? '' : 'disabled')}
//           onClick={onSave} >Guardar</button>
//       </div>
//     </div>
//   );
// }

// TODO: hacer el collapse que funciona en cualquier parte del div
// TODO: poner puntero como pincel
// TODO: Proximidad limita todas sus hijitas

const buildGeofence = (gf, pol) => ({
  id: gf.id || 0,
  description: gf.description || "",
  gi_type: gf.gi_type || 0,
  geo_data: "",
  polygon: pol,
  GF_TYPE: GEOFENCE_TYPES.getById(gf.gi_type),
  rampType: gf.rampType || 0,
  rampId: gf.rampId || 0,
  clickListener: gf.clickListener || undefined
});
