//@flow
import * as React from "react";
import TableD from "../../../shared/component/table-d.jsx";
import * as Types from "../types";
import UnassignedShipmentRow from "./unassigned-shipment-row.jsx";
import {
  readCatalog,
  ApiOptions,
  getEntityItems,
  getEntityCount,
  readEntities,
  partialEntity,
  cancelRequest,
  createEntity,
} from "../../../redux/api/";
import {
  validateObjPerms,
} from "../../../redux/reducers/global-catalog.reducer";
import { cancelTrips } from "../../../redux/reducers/products.reducer";
import { connect } from "react-redux";
import ShipmentReserveDialog from "../booking/shipment-reserve-dialog.jsx";
import {
  mapTripToUnassignedShipmentRowType,
  mapBookingAssemblyAvailability,
  // mapBookingFleetProviderAvailability,
  // getAssembleIconByTransportClassId,
  TableConfig, 
  getAssembleIconByAssemble
} from "./utils.jsx";
import PaginatorControlled from "../../../shared/controls/paginator-controlled.jsx";
import CatalogSelectionFooter from "../../../shared/component/catalog-selection-footer";
import ShipmentBoardRow from "../../../shared/component/shipment-board-row";
import ConfirmDialog from "../../../shared/dialog/confirm-dialog";
import LoaderDialog from "../../../shared/dialog/loader-dialog";
import UserChipContainer from "../../../shared/component/user-chip-container";
import CancelBookingModal from "../detail/cancel-booking-modal.jsx";
import FinalizeTripModal from "../detail/finalize-trip-modal.jsx";
import FinalizeOrderModal from "../detail/finalize-order-modal.jsx";
import CancelAppointmentModal from "../detail/cancel-appointment-modal.jsx";
import CancelTripModal from "../detail/cancel-trip-modal.jsx";
import { getTimeZone, normalizeStr, sendMessageToMultipleUsersInSocket } from "../../../shared/utils.jsx";
import Selector from "../../../shared/component/selector";
import ReactTooltip from "react-tooltip";
import { AssembleAvatar } from "../../assemble/assemble-avatar.jsx";
// import EditAppointmentModal from "../detail/edit-appointment-modal.jsx";
import CreateAppointmentModal from "../detail/create-appointment-modal.jsx";
import Caret from "../../../shared/component/caret.jsx";
import styled from "styled-components";
import ChangeAppointmentModal from "../detail/change-appointment-model.jsx";
import { setConfig } from "../../../redux/reducers/configuration-reducer.jsx";
import ChangeDateModal from "../../../shared/dialog/change-date-modal.jsx";
import DeleteOrderTripModal from "../../../shared/dialog/delete-order-trip-modal.jsx";
import { loadCompanyUsers } from "../../../redux/reducers/company-reducer.jsx";
import { getUserInfo, getUserTypes } from "../../../redux/reducers/user-reducer.jsx";
import { getUser } from "../../../redux/reducers/register-account.reducer.jsx";
import TransportsShipmentReserveDialog from '../booking/transports-shipment-reserve-dialog';
import { setOrderSeleted } from '../../../redux/reducers/global-catalog.reducer';
import BaseDialog from '../../../shared/dialog/base-dialog';

type StateToProps = {|
  trips: Types.Trip[],
  rows: Types.UnassignedShipmentRowType[],
  bookingFleetProviderAvailability: Types.BookingFleetProviderRow[],
  bookingAssemblyAvailability: Types.BookingAssemblyRow[],
  loading: boolean,
  tripsCount: number,
  orderMotives: { id: number, description: string, type: number }[],
|};
type DispatchToProps = {|
  loadTrips: (
    params: {
      company_id: number,
      facility_id: number,
      view: "load" | "unload" | "all",
      offset: number,
      limit: number,
      status: string,
    },
    opts: ApiOptions
  ) => void,
  loadTripAvailability: (
    params: {
      company_id: number,
      facility_id: number,
      trip_id: number,
      view: "booking" | "trip",
    },
    options: ApiOptions
  ) => void,
  bookReservation: (
    params: {
      company_id: number,
      trip_id: number,
      fleetproviderid: number,
      assembly: number,
      action: string,
    },
    options: ApiOptions
  ) => void,
  cancelAppointment: (
    params: {
      company_id: number,
      id: number,
      view: string,
      submotive: number,
    },

    opts: ApiOptions
  ) => void,
  cancelBooking: (
    params: {
      company_id: number,
      trip_id: number,
    },
    options: ApiOptions
  ) => void,
  cancelTrip: (
    params: {
      company_id: number,
      trip_id: number,
    },
    options: ApiOptions
  ) => void,
  contractAvailability: (
    params: {
      company_id: number,
      facility_id: number,
    },
    options: ApiOptions
  ) => void,
  providerAvailability: (
    params: {
      company_id: number,
      view: string,
    },
    options: ApiOptions
  ) => void,
|};

type OwnProps = {|
  loading: boolean,
  companyId: number,
  facilityId: number,
  limit: number,
  offset: number,
|};

type Props = {
  ...StateToProps,
  ...DispatchToProps,
  ...OwnProps,
};

type State = {
  showReserveDialog: boolean,
  showTransportsDialog: boolean,
  currentTrip: {} | Types.UnassignedShipmentRowType,
  selectedAssembly: number,
  selectedProvider: number,
  showCancelTripModal: boolean,
  showCancelAppointmentModal: boolean,
  showCancelBookingModal: boolean,
  showFinalizeTripModal: boolean,
  showFinalizeOrderModal: boolean,
  limit: number,
  offset: number,
};

let typingTimer;

const session = JSON.parse(sessionStorage.getItem("orbinetwork_session"));
const companyData = session?.company?.id || 0;
const companyTypeId = session?.company?.type?.id || 0;
const user = session?.user?.id || 0;

class UnassignedShipmentBoard extends React.Component<Props, State> {
  static defaultProps = {
    rows: [],
    // limit: 10,
    // offset: 1,
  };

  state = {
    showReserveDialog: false,
    showTransportsDialog: false,
    providers: [],
    selectedTransport: null,
    selectedAssembly: 0,
    selectedProvider: 0,
    currentTrip: {},
    limit: 10,
    offset: 1,
    showCancelTripModal: false,
    showCancelAppointmentModal: false,
    showCancelBookingModal: false,
    shownewAppointmentModal: false,
    showChangeAppointmentModal: false,
    showFinalizeOrderModal: false,
    showFinalizeTripModal: false,
    selectedNewDate: null,
    spaceSelect: 0,
    reasonSelect: 0,
    collapseAll: true,
    getTripsCalled: false,
    isLoadingTrips: false,

    selectionModeOn: false,
    selectedTripsId: [],

    showDeleteOrderTrip: false,
    orderIdToDelete: null,
    tripIdToDelete: null,
    user,
    
    showResultModal: false,
    reloadTrips: true,
    isHovered: false,
    openFinalizeTripModal: false,
    tripsToFinalize: 0,
    showSuccessFinalizeTripsModal: false
  };

  tripsCounter = 0;

  validShow = (perm) => {
    let response = this.props.validateObjPerms([perm], {
      display: true,
    });
    if (Object.keys(response).length) {
      return true;
    }
    return false;
  };

  componentDidMount() {
    this.setState({ ...this.props.configuration }, () => {/* this.loadTrips() */});
    this.props.loadSpaces({company_id: this.props.companyId, facility_id: this.props.facilityId});
    this.props.getUser(companyData, user);
    document.addEventListener("keydown", this.cancelSearch)
  }
  componentDidUpdate(prevProps: Props) {
    if (
      prevProps.filterStatus !== this.props.filterStatus ||
      prevProps.maneuver !== this.props.maneuver ||
      prevProps.filter !== this.props.filter ||
      prevProps.search !== this.props.search
    ){
      if(this.props.resetPaginator && (this.state.limit > 10 || this.state.offset > 1))
        this.setState({limit: 10, offset: 1});
      clearTimeout(typingTimer);
      typingTimer = setTimeout(this.loadTrips, 500);
    }
  }
  componentWillUnmount() {
    this.props.setBaseConfig({ ...this.state });
    document.removeEventListener("keydown", this.cancelSearch)
  }

  cancelSearch = (e) => {
    if(e.key === "Escape"){
      // console.log("CANCELLLIIIIIING");
      if(this.state.isLoadingTrips){
        this.props.cancelRequest("COMPANIES.FACILITIES.TRIPSCONTROL");
        this.setState({isLoadingTrips: false});
      }
    }
  }

  getFacilities = (type, facilities) => {
    switch (type) {
      case "all":
        return "";
      default:
        return (facilities || []).join(",");
    }
  };

  //SELECTOR
  toggleSelectMode = () => {
    this.setState({
      selectionModeOn: !this.state.selectionModeOn,
      selectedTripsId: []
    });
  };

  handleSelectTrip = (e) => {

    let selectedTripsId = [...this.state.selectedTripsId];
    if (e.target.value) {
      selectedTripsId.push(e.target.name);
    } else {
      selectedTripsId.splice(
        selectedTripsId.findIndex((f) => f === e.target.name),
        1
      );
    }
    this.setState({ selectedTripsId });

  };

  getSelectedFunction = (item) => {
    return this.state.selectedTripsId.find((sU) => sU === item.id) ? true : false
  }

  selectAllTrips = () => {
    if (this.props.rows.length === (this.state.selectedTripsId.length)) {
      this.setState({ selectedTripsId: []});
    } else {
      let selectedTripsId = [];

      this.props.rows
        .map((item) => {
          selectedTripsId.push(item.id);
          return null;
        });
      
      this.setState({ selectedTripsId});
    }
  };

  //SHOW/HIDE EACH MODAL

  showDeleteTripsModal = (show) => {
    this.setState({ showDeleteTripsModal: show });
  };

  showResultModal = (show) => {
    if (this.state.resultModalTitle.includes('Error')) {
      this.loadTrips();
    }
    if (!show && this.state.reloadTrips) {
      this.setState({ resetPaginator: true });
      this.loadTrips();
    }
    this.setState({ showResultModal: show, showProgressLoader: false, reloadTrips: true  });
  };

  deleteTrips = () => {
    if(this.state.selectedTripsId.length > 0){
      const json = {
        trips: this.state.selectedTripsId
      };

      this.setState({
        showDeleteTripsModal: false,
        showProgressLoader: true,
        showResultModal: false,
        selectionModeOn: false
      });
  
      this.props.cancelTrips(json)
        .then(response => {
          this.setState({
            showDeleteTripsModal: false,
            showResultModal: true,
            reloadTrips: true,
            showProgressLoader: false,
            selectionModeOn: false,
            resultModalText: "Los viajes han sido cancelados",
            resultModalTitle: "¡Listo!",
          });
        })
        .catch(e => {
          this.setState({
            showDeleteTripsModal: false,
            showResultModal: true,
            reloadTrips: true,
            showProgressLoader: false,
            selectionModeOn: false,
            resultModalText: "No pudimos cancelar, intenta de nuevo",
            resultModalTitle: "¡Error!",
          });
        })
    } else {
      this.setState({
        showDeleteTripsModal: false,
        showResultModal: false,
        showProgressLoader: false,
        selectionModeOn: false
      });
    }
  };

  handleProductChipClick = (id) => {
    let selectedTripsId = [...this.state.selectedTripsId];
    selectedTripsId.splice(
      selectedTripsId.findIndex((f) => f === id),
      1
    );
    this.setState({ selectedTripsId });
  };

  loadTrips = () => {
    const { companyId, facilityId, maneuver, filter, search, category } = this.props;
    const { limit, offset } = this.state;

    this.setState({isLoadingTrips: true });
    this.props.cancelRequest("COMPANIES.FACILITIES.TRIPSCONTROL");
    this.props.toggleLoadingState(true);

    if(search && search.length > 0){
      this.props.loadTrips({
        category: category || '',
        company_id: companyId,
        facility_id: facilityId,
        limit: limit,
        offset: offset,
        view: maneuver,
        // status: this.props.filterStatus || "",
        appointment_status: '',
        status: '',
        fleet_providers: '', //(filter.selectedProviders || []).join(","),
        transport_classes: '', //(filter.transportClass || []).join(","),
        trip_tags: '', //(filter.selectedTrip || []).join(","),
        order_tags: '', //(filter.selectedOrder || []).join(","),
        facilities: '',//this.getFacilities(maneuver, filter.selectedFacilities),
        priority: '', //(filter.selectedLevel || []).join(","),
        search: search || "",
        search_type: this.props.searchType  === "1" ? 'folio' : this.props.searchType  === "2" ? 'vehiculo' : 'placas',
        multifacilities: "",
        from_facilities: "",
        to_facilities: "",
        appointment: '', /*(filter.appointment && filter.appointment[0]) 
          ? "".concat'', //(filter.appointment[0].toISOString(), ",", filter.appointment[1] ? filter.appointment[1].toISOString() : filter.appointment[0].toISOString()) 
          : "",*/
        appointment_type: '', //(filter.appointmentType  || []).join(",") || "1,2,3"
        timezone: getTimeZone()
      }, {
        onSuccess: () => {
          this.props.toggleLoadingState(false);
          this.setState({isLoadingTrips: false, getTripsCalled: true});
        }, 
        onError: () => {
          this.props.toggleLoadingState(false);
          this.setState({isLoadingTrips: false, getTripsCalled: true});
        }
      });
    } else {
      this.props.loadTrips({
        category: category || '',
        company_id: companyId,
        facility_id: facilityId,
        limit: limit,
        offset: offset,
        view: maneuver,
        // status: this.props.filterStatus || "",
        appointment_status: (filter.selectedAppointmentStatus || []).join(","),
        status: (filter.selectedstatus || []).join(","),
        fleet_providers: (filter.selectedProviders || []).join(","),
        transport_classes: (filter.transportClass || []).join(","),
        trip_tags: (filter.selectedTrip || []).join(","),
        order_tags: (filter.selectedOrder || []).join(","),
        facilities: this.getFacilities(maneuver, filter.selectedFacilities),
        priority: (filter.selectedLevel || []).join(","),
        search: search || "",
        search_type: '',
        from_facilities: filter.from_facilities || '',
        to_facilities: filter.to_facilities || '',
        multifacilities: this.props.urlFacilities || "",
        appointment: (filter.appointment && filter.appointment[0]) 
          ? "".concat(filter.appointment[0].toISOString(), ",", filter.appointment[1] ? filter.appointment[1].toISOString() : filter.appointment[0].toISOString()) 
          : "",
        appointment_type: (filter.appointmentType  || []).join(",") || "1,2,3",
        timezone: getTimeZone()
      }, {
        onSuccess: () => {
          this.props.toggleLoadingState(false);
          this.setState({isLoadingTrips: false, getTripsCalled: true});
        }, 
        onError: () => {
          this.props.toggleLoadingState(false);
          this.setState({isLoadingTrips: false, getTripsCalled: true});
        }
      });
    }
  };

  loadAvailability = (booking: boolean = false) => {
    this.props.loadTripAvailability(
      {
        company_id: this.props.companyId,
        facility_id: this.props.facilityId,
        trip_id: this.state.currentTrip.id,
        view: booking ? "booking" : "trip",
      },
      {
        args: {
          company_id: this.props.companyId,
          facility_id: this.props.facilityId,
          trip_id: this.state.currentTrip.id,
          view: booking ? "booking" : "trip",
        },
      }
    );
  };

  contractAvailability = () => {
    this.props.contractAvailability({
      company_id: this.props.companyId,
      facility_id: this.props.facilityId,
    });
  };
  providerAvailability = () => {
    this.props.providerAvailability({
      company_id: this.props.companyId,
      view: "booking",
    });
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "selectedAssembly":
        this.setState({
          selectedProvider: value.provider,
          selectedAssembly: value.id,
        });
        break;
      case "selectedContract":
      case "selectedProvider":
        this.setState({
          selectedProvider: value,
          selectedAssembly: 0,
        });
        break;
      default:
        this.setState({ [name]: value });
    }
  };

  manageReserveModal = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "open":
        this.setState({ showReserveDialog: true, currentTrip: value }, () =>
          this.loadAvailability(true)
        );
        break;
      case "close":
        this.setState({ showReserveDialog: false, currentTrip: {}, selectedTransport: null });
        break;
      case "close-modals":
        this.setState({ showReserveDialog: false, showTransportsDialog: false, selectedTransport: null, currentTrip: {}, selectedProvider: 0 });
        break;
      case "save":

        if (!this.isOwnerUser()) {
          this.bookReservation();
        } else {
          let providersToShow = [];
        
          for (var i = 0; i < (this.props.providers || []).length; i++) {
            if (`${this.props.providers[i].fleet_provider.fleet_provider_id || 0}` === `${this.state.selectedProvider}`) {
              providersToShow = this.props.providers[i].primary_providers || [];
            }
          }

          if (providersToShow.length > 1) {
            this.setState({
              showTransportsDialog: true,
              showReserveDialog: false,  
              providers: providersToShow
            });
          } else if (providersToShow.length === 1) {
            this.setState({ selectedTransport: `${providersToShow[0].id}`}, () => {
              this.bookReservation();
            });
          } else {
            this.bookReservation();
          }
        }

        break;
      case "save-transports": 
        this.bookReservation();
        break;
      case "back-transports":
        this.setState({ showTransportsDialog: false, showReserveDialog: true });
        break;
      case "select-transport":
        this.setState({ selectedTransport: value });
        break;
      default:
    }
  };

  bookReservation = () => {
    if (this.props.isLoadingBookReservation) { return; }

    const params = {
      company_id: this.props.companyId,
      trip_id: this.state.currentTrip.id,
      fleetproviderid: Number(this.state.selectedProvider),
      action: "set_assembly",
      primary_provider: this.state.selectedTransport || ''
    };
    if (this.state.selectedAssembly > 0) {
      params.assembly = this.state.selectedAssembly;
    } else if (this.state.selectedProvider > 0) {
    }
    this.props.bookReservation(params, {
      onSuccess: () => { 
        this.loadTrips();
        this.manageReserveModal({ target: { name: "close-modals", value: "" } });
      },
    });
  };

  manageCancelDialogs = (e: any) => {
    const { name, value } = e.target;

    switch (name) {
      case "reasonSelect": 
        this.setState({
          reasonSelect: value
        });
        break;
      case "newAppointment":
        // this.props.loadSpaces({
        //   company_id: this.props.companyId, 
        //   facility_id: value.maneuver.facilityId
        // }, {
        //   onSuccess: (r) => {
        //     if(r.length === 1){
        //       this.setState({
        //         spaceSelect: r[0].id
        //       })
        //     }
        //   }
        // });
        this.setState({
          shownewAppointmentModal: true,
          reasonSelect: 0,
          currentTrip: value,
          selectedNewDate: new Date()
        });
        break;
      case "openAppointment":
        //debugger;
        this.setState({
          reasonSelect: 0,
          showCancelAppointmentModal: true,
          currentTrip: value,
        });
        break;
      case "changeAppointment":
        // this.props.loadSpaces({
        //   company_id: this.props.companyId, 
        //   facility_id: value.maneuver.facilityId
        // },{
        //   onSuccess: (r) => {
        //     if(r.length === 1){
        //       this.setState({
        //         spaceSelect: r[0].id
        //       })
        //     }
        //   }
        // });
        this.setState({
          reasonSelect: 0,
          showChangeAppointmentModal: true,
          currentTrip: value,
          selectedNewDate: new Date()
        });
        break; 
      case "openTrip":
        this.setState({
          showCancelTripModal: true,
          reasonSelect: 0,
          currentTrip: value,
        });
        break;
      case "openBooking":
        this.setState({
          showCancelBookingModal: true,
          reasonSelect: 0,
          currentTrip: value,
        });
        break;
      case "openFinalizeTrip":
          this.setState({
            showFinalizeTripModal: true,
            reasonSelect: 0,
            currentTrip: value,
          });
          break;
      case "openFinalizeOrder":
        this.setState({
          showFinalizeOrderModal: true,
          reasonSelect: 0,
          currentTrip: value,
        });
        break;
      case "cancelTrip":

        let motiveDescriptionCancel = '';

        (this.props.orderMotives || []).map( motive => {
          if (`${motive.value}` === `${this.state.reasonSelect}`) {
            motiveDescriptionCancel = motive.label;
          }
        })

        this.props.cancelTrip(
          {
            company_id: this.props.companyId,
            trip_id: this.state.currentTrip.id,
            motive_id: this.state.reasonSelect,
            motive_desc: motiveDescriptionCancel
          },
          {
            onSuccess: () => {
              this.setState({
                showCancelTripModal: false,
                currentTrip: undefined,
              });
              this.loadTrips();
            },
            onError: (e) => {
              this.setState({
                showCancelTripModal: false,
                currentTrip: undefined,
                showResultModal: true,
                reloadTrips: false,
                resultModalText: e.response?.data?.errors || ["Ocurrió un error al cancelar el viaje."],
                resultModalTitle: "¡Error!",
              });
            }
          }
        );
        break;
      case "cancelBooking":

        let motiveDescription = '';

        (this.props.orderMotives || []).map( motive => {
          if (`${motive.value}` === `${this.state.reasonSelect}`) {
            motiveDescription = motive.label;
          }
        })

        this.props.cancelBooking(
          {
            company_id: this.props.companyId,
            trip_id: this.state.currentTrip.id,
            motive_id: this.state.reasonSelect,
            motive_desc: motiveDescription
          },
          {
            onSuccess: () => {
              this.setState({
                showCancelBookingModal: false,
                currentTrip: undefined,
              });
              this.loadTrips();
            },
            onError: (e) => {
              this.setState({
                showCancelBookingModal: false,
                currentTrip: undefined,
                showResultModal: true,
                reloadTrips: false,
                resultModalText: e.response?.data?.errors || ["Ocurrió un error al cancelar la reservación."],
                resultModalTitle: "¡Error!",
              });
            }
          }
        );
        break;
      case "finalizeTrip":
          this.props.finalizeTrip(
            {
              company_id: this.props.companyId,
              trip_id: this.state.currentTrip.id,
            },
            {
              onSuccess: () => {
                this.setState({
                  showFinalizeTripModal: false,
                  currentTrip: undefined,
                });
                this.loadTrips();
              },
              onError: (e) => {
                this.setState({
                  showFinalizeTripModal: false,
                  currentTrip: undefined,
                  showResultModal: true,
                  reloadTrips: false,
                  resultModalText: e.response?.data?.errors || ["Ocurrió un error al cancelar la cita."],
                  resultModalTitle: "¡Error!",
                });
              }
            }
          );
          break;
      case "finalizeOrder":
        // console.log(name, value);
        this.props.finalizeOrder(
          {
            company_id: this.props.companyId,
            id: this.state.currentTrip.maneuver.orderId,
            view: value,
          },
          {
            onSuccess: () => {
              this.setState({
                showFinalizeOrderModal: false,
                currentTrip: undefined,
                card: undefined,
              });
              this.loadTrips();
            },
            onError: (e) => {
              this.setState({
                showFinalizeOrderModal: false,
                currentTrip: undefined,
                card: undefined,
                showResultModal: true,
                reloadTrips: false,
                resultModalText: e.response?.data?.errors || ["Ocurrió un error al cancelar la cita."],
                resultModalTitle: "¡Error!",
              });
            }
          }
        );
        break;
      case "cancelAppointment":
        const params = {
          company_id: this.props.companyId,
          id: this.state.currentTrip.maneuver.orderId,
          view:
            this.state.currentTrip.maneuver.maneuver === "Carga"
              ? "load"
              : "unload",
          submotive: this.state.reasonSelect,
        };
        this.props.cancelAppointment(params, {
          onSuccess: () => {
            this.setState({
              showCancelAppointmentModal: false,
              currentTrip: undefined,
            });
            this.loadTrips();
          },
          onError: (e) => {
            this.setState({
              showCancelAppointmentModal: false,
              currentTrip: undefined,
              showResultModal: true,
              reloadTrips: false,
              resultModalText: e.response?.data?.errors || ["Ocurrió un error al cancelar la cita."],
              resultModalTitle: "¡Error!",
            });
          }
        });
        break;
      case "close":
      case "cancel":
        this.setState({
          showCancelTripModal: false,
          showCancelBookingModal: false,
          showCancelAppointmentModal: false,
          showChangeAppointmentModal: false,
          showFinalizeOrderModal: false,
          showFinalizeTripModal: false,
          spaceSelect: 0,
          reasonSelect: 0,
        });
        break;
      default:
        this.setState({ [name]: value });
    }
  };

  isOwnerUser = () => {
    if (`${companyTypeId}` === '2') { return true; } 
    return false;
  }

  finalizeTripsSuccess = () => {
    this.tripsCounter = 0;
    this.setState({
      tripsToFinalize: 0,
      openFinalizeTripModal: false,
      showSuccessFinalizeTripsModal: true,
      selectionModeOn: false
    }, () => {
      this.loadTrips();
    });
  }

  render() {

    return (
      <React.Fragment>
        <TableD
          tableConfig={
            {
              ...TableConfig,
              columns: [
                {
                  ...TableConfig.columns[0],
                  text: <CaretContainer
                    onClick={() => this.setState({ collapseAll: !this.state.collapseAll })}
                    onMouseEnter={() => this.setState({ isHovered: true })}
                    onMouseLeave={() => this.setState({ isHovered: false })}
                  >
                    <Caret up={this.state.collapseAll} fill="#AFC8FF" isHovered={this.state.isHovered}/>
                  </CaretContainer>
                }
              ].concat(TableConfig.columns.slice(1))
            }
          }>
          {(this.props.loading) ?
            <div
              style={{
                backgroundColor: "#212A3C",
                height: "300px",
                textAlign: "center",
                opacity: "0.57",
                color: "#FFFFFF",
                fontSize: "24px",
                fontWeight: "bold",
                letterSpacing: "0",
                lineHeight: "37px",
                paddingTop: "30px",
              }}
            >
              <span>
                {this.props.loading
                  ? "Cargando ..."
                  : "No hay viajes en este momento"}
              </span>
              <br />
              <img alt="" src="/images/empty-travels.png"></img>
            </div> 
            :
            this.props.rows.length > 0 ? (
              <Selector
                selectedItemClass="selected"
                selectionModeClass="selection"
                selectedItems={this.state.selectedTripsId}
                onSelect={this.handleSelectTrip}
                selectionModeOn={this.state.selectionModeOn} 
                toggleSelectionMode={this.toggleSelectMode}
              >
                {
                  this.props.rows.map((r: Types.UnassignedShipmentRowType, index) => (
                    <ShipmentBoardRow
                      key={ r.id}
                      id={r.id}
                      selected={this.getSelectedFunction(r)}
                    >
                    <UnassignedShipmentRow 
                      {...r}
                      tableConfig={
                        {
                          ...TableConfig,
                          columns: [
                            {
                              ...TableConfig.columns[0],
                              text: <CaretContainer
                                onClick={() => this.setState({ collapseAll: !this.state.collapseAll })}
                              >
                                <Caret up={this.state.collapseAll} fill="#AFC8FF" />
                              </CaretContainer>
                            }
                          ].concat(TableConfig.columns.slice(1))
                        }
                      }
                      collapsed={this.state.collapseAll}
                      mainFacility={r.mainFacility.map(f => ((this.props.companyFacilities || []).find(cF => cF.id === f) || {}).name)}
                      assemble={this.getAssembleItem(r)}
                      history={this.props.history}
                      facilityId={this.props.facilityId}
                      details={r.details.map((detail) => {
                        return {
                          ...detail,
                          tags: detail.tags.map((o) =>
                            this.props.companyTags.find((t) => t.id === o.tag)
                          ),
                          assemble: this.getAssembleItem(detail)
                        };
                      })}
                      actions={() => {
                        return this.actionResolver(r);
                      }}
                      tags={r.tags.map((o) =>
                        this.props.companyTags.find((t) => t.id === o)
                      )}
                      currentFacilityId={this.props.facilityId}
                      key={index}
                      setOrderDetail={orderToSelect => {
                        this.props.setOrderDetail(orderToSelect)
                      }}
                    />
                    </ShipmentBoardRow>
                  )
                )
              }
            </Selector>
            ) : (
                <div
                  style={{
                    backgroundColor: "#212A3C",
                    height: "300px",
                    textAlign: "center",
                    opacity: "0.57",
                    color: "#FFFFFF",
                    fontSize: "24px",
                    fontWeight: "bold",
                    letterSpacing: "0",
                    lineHeight: "37px",
                    paddingTop: "30px",
                  }}
                >
                  <span>
                    {this.props.loading
                      ? "Cargando ..."
                      : "No hay viajes en este momento"}
                  </span>
                  <br />
                  <img alt="" src="/images/empty-travels.png"></img>
                </div>
              )
          }
        </TableD>
        {this.state.showReserveDialog && ( 
          <ShipmentReserveDialog
            open={this.state.showReserveDialog}
            isLoadingBooking={this.props.bookingLoading}
            onChange={this.manageReserveModal}
            trip={this.state.currentTrip}
            // fleetProviders={this.props.bookingFleetProviderAvailability.map(
            //   (a) => ({
            //     ...a,
            //     selectedProvider: this.state.selectedProvider,
            //     onChange: this.handleChange,
            //   })
            // )}
            assemblies={this.props.bookingAssemblyAvailability.map((a) => ({
              ...a,
              selectedAssembly: this.state.selectedAssembly,
              selected:
                this.state.selectedAssembly.toString() === a.id.toString(),
              onChange: this.handleChange,
            }))}
            getContracts={this.contractAvailability}
            contracts={this.props.contracts.map((c) => ({
              ...c,
              id: c.fleet_provider.fleet_provider_id,
              selectedProvider: this.state.selectedProvider,
              selected:
                this.state.selectedProvider.toString() === c.id.toString(),
              onChange: this.handleChange,
            }))}
            getProviders={this.providerAvailability}
            providers={this.props.providers.map((p) => ({
              ...p,
              id: p.fleet_provider.fleet_provider_id || "",
              selectedProvider: this.state.selectedProvider,
              selected:
                this.state.selectedProvider.toString() === p.id.toString(),
              onChange: this.handleChange,
            }))}
            enabledAction={
              this.state.selectedProvider + this.state.selectedAssembly > 0
            }
          />
        )}
        {
          this.state.showTransportsDialog &&
          <TransportsShipmentReserveDialog
            open={this.state.showTransportsDialog}
            onChange={this.manageReserveModal}
            selectedTransport={this.state.selectedTransport}
            providers={this.state.providers}
          />
        }
        <PaginatorControlled
          itemCount={this.props.tripsCount}
          onChange={(e) => {
            this.setState({ [e.target.name]: e.target.value }, () => {
              if (this.state.getTripsCalled === true) {
                this.loadTrips();
              }
            });
          }}
          limit={this.state.limit}
          offset={this.state.offset}
        />

        <CatalogSelectionFooter
          show={this.state.selectionModeOn}
          selectedElements={this.state.selectedTripsId.length}
          selectAll={this.selectAllTrips}
          actions={[
            {
              description: "Cerrar",
              f: () => this.setState({selectionModeOn: false, selectedTripsId: []}),
            },
            {
              description: "Cancelar viaje(s)",
              f: () => this.showDeleteTripsModal(true),
              disabled: !this.state.selectedTripsId.length
            },
            {
              description: "Finalizar viajes sin datos",
              f: () => this.setState({openFinalizeTripModal: true}),
              disabled: !this.state.selectedTripsId.length
            }
          ]}
        />

        <ConfirmDialog
          open={this.state.showResultModal}
          title={this.state.resultModalTitle}
          message={this.state.resultModalText}
          acceptText="Aceptar"
          class="result-message"
          closeAction={() => this.showResultModal(false)}
          acceptAction={() => this.showResultModal(false)}
        />

        <LoaderDialog
          open={this.state.showProgressLoader}
          title={"Cancelando..."}
          message={"Espere un momento"}
          class="result-message"
          closeAction={() => this.showResultModal(false)}
          acceptAction={() => this.showResultModal(false)}
        />

        <ConfirmDialog
          open={this.state.openFinalizeTripModal}
          isAcceptLoading={this.state.tripsToFinalize !== 0}
          title="Finalizar viajes"
          message={"Esta interacción finalizará sin datos todas las órdenes de los viajes seleccionados, ¿Estás seguro que deseas continuar? "}
          contentObject={
            <UserChipContainer
              deleteTag={(id) => this.handleProductChipClick(id)}
              items={
                !Array.isArray(this.props.rows)
                  ? []
                  : this.props.rows
                      .filter((u) =>
                        this.state.selectedTripsId.includes(u.id)
                      )
                      .map((u) => {
                        return {
                          id: u.id,
                          name: u.tripNumber,
                          avatarType: "contained",
                        };
                      })
              }
            />
          }
          acceptText="Aceptar"
          class="delete-facility"
          showCancel={true}
          cancelText="Cancelar"
          cancelButtonType="secondary"
          closeAction={() => this.setState({openFinalizeTripModal: false})}
          acceptAction={() => {

            if (this.state.tripsToFinalize !== 0) { 
              this.setState({
                openFinalizeTripModal: false,
                selectionModeOn: false
              });
              return;
            }

            let trips = this.props.rows.filter((u) =>
              this.state.selectedTripsId.includes(u.id)
            );

            if (trips.length === 0) {
              this.setState({
                openFinalizeTripModal: false,
                selectionModeOn: false
              });
              return;
            }

            this.setState({
              tripsToFinalize: trips.length
            });

            for (var i = 0; i < trips.length; i++) {
              this.props.finalizeTrip( 
                {
                  company_id: this.props.companyId,
                  trip_id: trips[i].id,
                },
                {
                  onSuccess: () => {
                    this.tripsCounter = this.tripsCounter + 1
                    
                    if (this.tripsCounter === this.state.tripsToFinalize) {
                      this.finalizeTripsSuccess()
                    }
                  },
                  onError: () => {
                    this.tripsCounter = this.tripsCounter + 1;

                    if (this.tripsCounter === this.state.tripsToFinalize) {
                      this.finalizeTripsSuccess()
                    }
                  }
                }
              );
            }
          }}
        />

        <BaseDialog
          closeAction={() => {
            this.setState({
              showSuccessFinalizeTripsModal: false, 
              selectionModeOn: false
            })
          }}
          acceptAction={() => {
            this.setState({
              showSuccessFinalizeTripsModal: false, 
              selectionModeOn: false
            })
          }}
          open={this.state.showSuccessFinalizeTripsModal}
          title={"Éxito"}
          message={"Se finalizaron los viajes correctamente"}
          showCancel={false}
          cancelText="Cancelar"
          cancelButtonType="primary outline dark"
          acceptText="Aceptar"
          acceptButtonType="primary"
          style={{ content: { width: "600px", marginLeft: "-300px" } }}
        />

        <ConfirmDialog
          open={this.state.showDeleteTripsModal}
          title="¿Cancelar viajes?"
          message={"¿Estás seguro que deseas cancelar estos viajes?"}
          contentObject={
            <UserChipContainer
              deleteTag={(id) => this.handleProductChipClick(id)}
              items={
                !Array.isArray(this.props.rows)
                  ? []
                  : this.props.rows
                      .filter((u) =>
                        this.state.selectedTripsId.includes(u.id)
                      )
                      .map((u) => {
                        return {
                          id: u.id,
                          name: u.tripNumber,
                          avatarType: "contained",
                          // avatar: getAvatarUrl(u.url, u.status_id),
                        };
                      })
              }
            />
          }
          acceptText="Aceptar"
          class="delete-facility"
          showCancel={true}
          cancelText="Cancelar"
          cancelButtonType="secondary"
          closeAction={() => this.showDeleteTripsModal(false)}
          acceptAction={() => this.deleteTrips()}
        />

        {this.state.showCancelBookingModal && (
          <CancelBookingModal 
            show={this.state.showCancelBookingModal}
            reasonSelect={this.state.reasonSelect}
            reasons={[
              { label: "Selecciona una opción", value: 0, type: 2 },
            ].concat(this.props.orderMotives.filter((m) => m.type === 2))}
            data={{
              logo: this.state.currentTrip.companyLogo,
              name: this.state.currentTrip.companyName,
              trip: this.state.currentTrip.tripNumber,
            }}
            onChange={this.manageCancelDialogs}
            isCancelingLoading={this.props.bookingLoading}
          />
        )}
        {this.state.showFinalizeTripModal && (
          <FinalizeTripModal
            show={this.state.showFinalizeTripModal}
            data={{
              logo: this.state.currentTrip.companyLogo,
              name: this.state.currentTrip.companyName,
              trip: this.state.currentTrip.tripNumber,
            }}
            onChange={this.manageCancelDialogs}
          />
        )}
        {this.state.showFinalizeOrderModal && (
          <FinalizeOrderModal
            show={this.state.showFinalizeOrderModal}
            data={{
              logo: this.state.currentTrip.companyLogo,
              name: this.state.currentTrip.companyName,
              trip: this.state.currentTrip.tripNumber,
              card: this.state.currentTrip.ticket
            }}
            onChange={this.manageCancelDialogs}
          />
        )}
        {this.state.showCancelAppointmentModal && (
          <CancelAppointmentModal
            show={this.state.showCancelAppointmentModal}
            reasons={[
              { label: "Selecciona una opción", value: 0, type: 2 },
            ].concat(this.props.orderMotives.filter((m) => m.type === 2))}
            reasonSelect={this.state.reasonSelect}
            data={{
              logo: this.state.currentTrip.companyLogo,
              trip: this.state.currentTrip.tripNumber,
              maneuver: this.state.currentTrip.maneuver.maneuver,
            }}
            onChange={this.manageCancelDialogs}
            date={new Date(this.state.currentTrip.maneuver.date)}
            place={``}
          />
        )}
        {this.state.showCancelTripModal && (
          <CancelTripModal
            show={this.state.showCancelTripModal}
            reasons={[
              { label: "Selecciona una opción", value: 0, type: 2 },
            ].concat(this.props.orderMotives.filter((m) => m.type === 2))}
            data={{
              logo: this.state.currentTrip.companyLogo,
              trip: this.state.currentTrip.tripNumber,
            }}
            reasonSelect={this.state.reasonSelect}
            onChange={this.manageCancelDialogs}
            isCancelingLoading={this.props.bookingLoading}
          />
        )}
        {
          (this.state.showDeleteOrderTrip) &&
          <DeleteOrderTripModal
            open={this.state.showDeleteOrderTrip}
            orderToDelete={this.state.orderIdToDelete}
            tripToDelete={this.state.tripIdToDelete}
            success={() => {
              this.loadTrips();
            }}
            onClose={() => {
              this.setState({
                showDeleteOrderTrip: false
              });
            }}
          />
        }
        {
          this.state.shownewAppointmentModal && (
            // <CreateAppointmentModal
            //   show={this.state.shownewAppointmentModal}
            //   onChange={this.manageEditAppointmentModal}
            //   data={{
            //     code: this.state.currentTrip.ticket, 
            //     logo: this.state.currentTrip.companyLogo,
            //     maneuver: this.state.currentTrip.maneuver.maneuver,
            //   }}
            //   reasons={[
            //     { label: "Selecciona un espacio", value: 0, type: 1 },
            //   ].concat(this.props.facilitySpaces.map((space, i) => ({label: "Espacio ".concat(i + 1), value: space.id, type: 1})))}
            //   hourTo={(this.props.facilitySpaces.find(s => s.id === this.state.spaceSelect)?.windows || []).filter(w => this.state.selectedNewDate.getDay() + 1 === w.weekdays).map(w => w.hour_to)}
            //   hourFrom={(this.props.facilitySpaces.find(s => s.id === this.state.spaceSelect)?.windows || []).filter(w => this.state.selectedNewDate.getDay() + 1 === w.weekdays).map(w => w.hour_from)}
            //   minuteTo={(this.props.facilitySpaces.find(s => s.id === this.state.spaceSelect)?.windows || []).filter(w => this.state.selectedNewDate.getDay() + 1 === w.weekdays).map(w => w.minute_to)}
            //   minuteFrom={(this.props.facilitySpaces.find(s => s.id === this.state.spaceSelect)?.windows || []).filter(w => this.state.selectedNewDate.getDay() + 1 === w.weekdays).map(w => w.minute_from)}
            //   spaceSelect={this.state.spaceSelect}
            //   onMinutes={this.handleChange}
            //   selectedNewDate={this.state.selectedNewDate}
            // />

            <ChangeDateModal
              editDate={false}
              open={this.state.shownewAppointmentModal}
              onClose={() => {
                this.setState({
                  shownewAppointmentModal: false
                });
              }}
              facilityId= {this.state.currentTrip.maneuver.facilityId}
              orderId={this.state.currentTrip.maneuver.orderId}
              tripDetails = {this.state.currentTrip.details.map((detail) => {
                return {
                  ...detail, tags: detail.tags.map((o) => this.props.companyTags.find((t) => t.id === o.tag) )
                };
              })}
              data={{
                code: this.state.currentTrip.ticket, 
                logo: this.state.currentTrip.companyLogo,
                maneuver: this.state.currentTrip.maneuver.maneuver,
              }}
              reasons={this.props.orderMotives.filter((m) => m.type === 2)}
              date={new Date(this.state.currentTrip.maneuver.date)}
              loadTrips={ () => {
                this.setAcceptedResponse(this.state.currentTrip?.maneuver?.orderId || 0);
                this.loadTrips();
              }}
            />

          )
        }
        
        {
          this.state.showChangeAppointmentModal && (
            <ChangeDateModal
              isLoadingReasons={this.props.isLoadingReasons}
              editDate={true}
              open={this.state.showChangeAppointmentModal}
              onClose={() => {
                this.setState({
                  showChangeAppointmentModal: false
                });
              }}
              tripDetails = {this.state.currentTrip.details.map((detail) => {
                return {
                  ...detail, tags: detail.tags.map((o) => this.props.companyTags.find((t) => t.id === o.tag) )
                };
              })}
              facilityId= {this.state.currentTrip.maneuver.facilityId}
              orderId={this.state.currentTrip.maneuver.orderId}
              data={{
                code: this.state.currentTrip.ticket, 
                logo: this.state.currentTrip.companyLogo,
                maneuver: this.state.currentTrip.maneuver.maneuver,
              }}
              reasons={this.props.orderMotives.filter((m) => m.type === 2)}
              date={new Date(this.state.currentTrip.maneuver.date)}
              loadTrips={ () => {
                this.setAcceptedResponse(this.state.currentTrip?.maneuver?.orderId || 0);
                this.loadTrips();
              }}
            />

            // <ChangeAppointmentModal
            //   show={this.state.showChangeAppointmentModal}
            //   onChange={this.manageEditAppointmentModal}
            //   data={{
            //     code: this.state.currentTrip.ticket, 
            //     logo: this.state.currentTrip.companyLogo,
            //     maneuver: this.state.currentTrip.maneuver.maneuver,
            //   }}
            //   spaces={[
            //     { label: "Selecciona un espacio", value: 0, type: 1 },
            //   ].concat(this.props.facilitySpaces.map((space, i) => ({label: "Espacio ".concat(i + 1), value: space.id, type: 1})))}
            //   hourTo={(this.props.facilitySpaces.find(s => s.id === this.state.spaceSelect)?.windows || []).filter(w => this.state.selectedNewDate.getDay() + 1 === w.weekdays).map(w => w.hour_to)}
            //   hourFrom={(this.props.facilitySpaces.find(s => s.id === this.state.spaceSelect)?.windows || []).filter(w => this.state.selectedNewDate.getDay() + 1 === w.weekdays).map(w => w.hour_from)}
            //   minuteTo={(this.props.facilitySpaces.find(s => s.id === this.state.spaceSelect)?.windows || []).filter(w => this.state.selectedNewDate.getDay() + 1 === w.weekdays).map(w => w.minute_to)}
            //   minuteFrom={(this.props.facilitySpaces.find(s => s.id === this.state.spaceSelect)?.windows || []).filter(w => this.state.selectedNewDate.getDay() + 1 === w.weekdays).map(w => w.minute_from)}
            //   spaceSelect={this.state.spaceSelect}
            //   onMinutes={this.handleChange}
            //   selectedNewDate={this.state.selectedNewDate}

            //   reasons={[
            //     { label: "Selecciona una opción", value: 0, type: 2 },
            //   ].concat(this.props.orderMotives.filter((m) => m.type === 2))}
            //   reasonSelect={this.state.reasonSelect}
            //   date={new Date(this.state.currentTrip.maneuver.date)}
            //   place={``}
            // />
          )
        }
      </React.Fragment>
    );
  }
  getAssembleItem = (r) => {
    return (
      <div>
        {r.assemble &&
          (Object.keys(r.assemble.vehicles).length > 0 ||
            Object.keys(r.assemble.equipments).length > 0 ||
            Object.keys(r.assemble.operators).length > 0) && (
            <ReactTooltip id={`order-assemble-${r.id}`}>
              {Object.values(r.assemble.vehicles)
                .flat()
                .map((e) => (
                  <AssembleAvatar
                    fleetTypeId={e.type}
                    description={`${e.economic_number} - ${e.plates}`}
                    //  color?: string,
                    showIcon={true}
                    //  icon?: any,
                  />
                ))}
              {Object.values(r.assemble.equipments)
                .flat()
                .map((e) => (
                  <AssembleAvatar
                    fleetTypeId={e.type}
                    description={`${e.economic_number} - ${e.plates}`}
                    //  color?: string,
                    showIcon={true}
                    //  icon?: any,
                  />
                ))}
              {Object.values(r.assemble.operators)
                .flat()
                .map((e) => (
                  <AssembleAvatar
                    fleetTypeId={e.type}
                    description={`${e.first_name} ${e.last_name}`}
                    //  color?: string,
                    showIcon={true}
                    //  icon?: any,
                  />
                ))}
            </ReactTooltip>
          )}
        <div data-place="right" data-tip data-for={`order-assemble-${r.id}`}>
          {getAssembleIconByAssemble(r.assemble, r.is_unhooked)}
          {r.assemble && Object.keys(r.assemble.vehicles).length && (
            <div>{
              Object.values(r.assemble.vehicles)
                .flat()
                .map((e) => e.economic_number)
              }</div>
          )}
        </div>
      </div>
    );
  };

  manageEditAppointmentModal = (e: SharedTypes.SimpleEvent) => {
    const { name, value } = e.target;
    // console.log(name, value);

    switch (name) {
      case "update":
        let newDate =
          this.state.selectedNewDate;
        // newDate.setMinutes(this.state.selectedNewDate);
        // debugger;
        const params = {
          company_id: this.props.companyId,
          id: this.state.currentTrip.maneuver.orderId,
          old_date: null,
          new_date: newDate.toISOString(),
          old_space: null,
          new_space: this.state.spaceSelect,
          action: "single_replace",
          submotive: this.state.reasonSelect || null,
          replaced_order: null,
        };

        if (this.state.currentTrip.maneuver.maneuver === "Descarga") {
          params.view = "unload";

          // params.unload_space = this.state.editAppointmentModalData.newSpaceId;
          // params.appointment_unload_date = this.state.editAppointmentModalData.newAppointment.date.toISOString();
        } else {
          params.view = "load";
          // params.load_space = this.state.editAppointmentModalData.newSpaceId;
          // params.appointment_loading_date = this.state.editAppointmentModalData.newAppointment.date.toISOString();
        }
        this.props.setAppointmentDate(params, {
          onSuccess: () => {
            this.loadTrips();
          },
        });
        
        // debugger;
        this.setState({
          shownewAppointmentModal: false,
          showChangeAppointmentModal: false,
          selectedNewDate: null,
          spaceSelect: 0,
          reasonSelect: 0,
        });
        break;
      case "close":
      case "cancel":
        this.setState({
          shownewAppointmentModal: false,
          showChangeAppointmentModal: false,
          selectedNewDate: null,
          spaceSelect: 0,
          reasonSelect: 0,
        });
        break;
      default:
        this.setState({ [name]: value });
    }
  };

  setAcceptedResponse = (order_id) => {
    if (order_id === 0 ) return;
    this.props.setActionAppointmentRequest(
      {
        company_id: this.props.companyId,
        order_id: order_id,
        action: 'accept_change_appointment_request'
      }, 
      {
        onSuccess: (result) => {
          if (result && result['accepted'] && result['accepted']['users'] && result['accepted']['users'].length > 0) {
            sendMessageToMultipleUsersInSocket(
              this.props.userId,
              result['accepted']['users'],
              result['accepted']['data']
            );
          }
        }
      }
    )
  }

  actionResolver = (r) => {

    let result = []

    r.details.forEach(d => {
      if(d.status.toLowerCase() === "cancelado" && this.isOwnerUser()){
        result.push(
          {
            type: "detail xtra-".concat(d.id),
            description: "Eliminar orden",
            action: (additional: number) => {
              this.setState({
                showDeleteOrderTrip: true,
                orderIdToDelete: `${d.id}`,
                tripIdToDelete: null,
              });
            },
          }
        );
      }
    })

    if(r.status === "Finalizado sin datos" || r.status === "Cancelado"){

      if (r.status === "Cancelado") {

        var showDeleteTrip = true;

        for (var i = 0; i < (r.details?.length || 0); i++) {
          if (r.details[i].status.toLowerCase() !== 'cancelado') {
            showDeleteTrip = false
          }
        }

        if (this.isOwnerUser() && showDeleteTrip) {
          result.push(
            {
              type: "extra",
              description: "Eliminar viaje",
              action: () => {
                this.setState({
                  showDeleteOrderTrip: true,
                  tripIdToDelete: `${r.id}`,
                  orderIdToDelete: null,
                });
              }
            }
          );
        }

        return result;

      } else {

        // if ( this.validShow({id: "61", perm: "u",}) === true ) {
          result.push({
            type: "detail cancel",
            description: "Cancelar cita",
            action: (additional) =>
              this.manageCancelDialogs({
                target: {
                  name: "openAppointment",
                  value: {
                    ...r,
                    maneuver: additional.maneuver,
                    ticket: additional.ticket,
                  },
                },
              }),
          }, {
            type: "detail assign",
            description: "Asignar cita",
            action: (additional) =>
              this.manageCancelDialogs({
                target: {
                  name: "newAppointment",
                  value: {
                    ...r,
                    maneuver: additional.maneuver,
                    ticket: additional.ticket,
                  },
                },
              }),
          }, {
            type: "detail change",
            description: "Cambiar cita",
            action: (additional) =>
              this.manageCancelDialogs({
                target: {
                  name: "changeAppointment",
                  value: {
                    ...r,
                    maneuver: additional.maneuver,
                    ticket: additional.ticket,
                  },
                },
              }),
          });
        // }

        return result
      }

    } if(r.status === "Viaje completado"){
      return [{
        type: "extra",
        description: "Cancelar viaje",
        action: () =>
          this.manageCancelDialogs({ target: { name: "openTrip", value: r } }),
      }]
    }
    const maneuver = r.details
      .filter(
        (o) =>
          // o.destination.facilityId.toString() ===
          //   this.props.facilityId.toString() ||
          // o.origin.facilityId.toString() === this.props.facilityId.toString()
          r.mainFacility.includes(o.origin.facilityId) || r.mainFacility.includes(o.destination.facilityId)
      )
      .map((m) =>
        r.mainFacility.includes(m.destination.facilityId)
          ? {
              ...m.destination,
              maneuver: "Descarga",
              ticket: m.ticket,
              orderId: m.id,
            }
          : { ...m.origin, maneuver: "Carga", ticket: m.ticket, orderId: m.id }
      );

    // if (this.validShow({id: "61", perm: "d",}) === true){
      result.push(
        {
          type: "extra",
          description: "Cancelar viaje",
          action: () =>
            this.manageCancelDialogs({ target: { name: "openTrip", value: r } }),
        }
      )
    // }

    // if (this.validShow({id: "61", perm: "u",}) === true){
      result.push(
        {
          type: "extra",
          description: "Finalizar viaje sin datos",
          action: () =>
            this.manageCancelDialogs({ target: { name: "openFinalizeTrip", value: r } }),
        }
      )
        r.details.forEach(d => {
          if(d.status.toLowerCase() !== "finalizado sin datos"){
            result.push(
              {
                type: "detail xtra-".concat(d.id),
                description: "Finalizar orden sin datos",
                action: (additional: number) => {
                  //debugger;
                  this.manageCancelDialogs({
                    target: {
                      name: "openFinalizeOrder",
                      value: {
                        ...r,
                        maneuver: maneuver.find((f) => f.ticket === additional),
                        ticket: additional,
                      },
                    },
                  })},
              }
            )
          }
        })
      
    // }

    if ( 
      // this.validShow({id: "61", perm: "u",}) === true &&
      "sin reservacion reservacion rechazada".includes(
        normalizeStr(r.status.toLowerCase())
      )
    )
      result.push({
        type: "primary",
        description: "Reservar",
        action: () =>
          this.manageReserveModal({
            target: { name: "open", value: r },
          }),
      });
    // else if (this.validShow({id: "61", perm: "u",}) === true){
      result.push({
        type: "extra",
        description: "Cancelar reservación",
        action: () =>
          this.manageCancelDialogs({
            target: { name: "openBooking", value: r },
          }),
      });
    // }
    // if ( this.validShow({id: "61", perm: "u",}) === true ) {
      result.push({
        type: "detail cancel",
        description: "Cancelar cita",
        action: (additional) =>
          this.manageCancelDialogs({
            target: {
              name: "openAppointment",
              value: {
                ...r,
                maneuver: additional.maneuver,
                ticket: additional.ticket,
              },
            },
          }),
      }, {
        type: "detail assign",
        description: "Asignar cita",
        action: (additional) =>
          this.manageCancelDialogs({
            target: {
              name: "newAppointment",
              value: {
                ...r,
                maneuver: additional.maneuver,
                ticket: additional.ticket,
              },
            },
          }),
      }, {
        type: "detail change",
        description: "Cambiar cita",
        action: (additional) =>
          this.manageCancelDialogs({
            target: {
              name: "changeAppointment",
              value: {
                ...r,
                maneuver: additional.maneuver,
                ticket: additional.ticket,
              },
            },
          }),
      });
    // }
    return result;
  };
}

const mapStateToProps = (state): StateToProps => {
  const loading = state.api["COMPANIES.FACILITIES.TRIPSCONTROL"]?.status?.isFetching;
  const bookingLoading = state.api["COMPANIES.TRIPS"]?.status?.isFetching || false;
  const trips = getEntityItems(state, "COMPANIES.FACILITIES.TRIPSCONTROL");
  const tripsCount = getEntityCount(state, "COMPANIES.FACILITIES.TRIPSCONTROL");
  const rows: Types.UnassignedShipmentRowType[] = trips.map(
    mapTripToUnassignedShipmentRowType 
  );
  // .sort((a, b) => Math.max(a.level) - Math.max(b.level));
  const tripAvailability = getEntityItems(
    state,
    "COMPANIES.FACILITIES.TRIPSCONTROL.BOOKING"
  );
  // const tripAvailabilityArgs = getEntityArgs(
  //   state,
  //   "COMPANIES.FACILITIES.TRIPSCONTROL.BOOKING"
  // );
  // const vehicleTypeCatalog = getEntityItems(state, "RUTAS.TIPOVEHICULO");
  const bookingAssemblyAvailability: Types.BookingAssemblyRow = tripAvailability
    .map(mapBookingAssemblyAvailability)
    .flat();

  const orderMotives = getEntityItems(state, "COMPANIES.CATALOGS.ORDERSMOTIVES")
    .map((o) =>
      o.submotives.map((s) => ({
        label: `${o.description} - ${s.description}`,
        value: s.id,
        type: s.type,
      }))
    )
    .flat();
  const contracts = getEntityItems(state, "COMPANIES.FACILITIES.CONTRACTS");
  const providers = getEntityItems(state, "COMPANIES.NETWORK");
  // const transportTypes = getEntityItems(state, "COMMONS.CHOICES.TRANSPORTTYPE");
  const facilitySpaces = getEntityItems(state, "COMPANIES.FACILITIES.SPACES.SIMPLE");
  const isLoadingBookReservation = state?.api['COMPANIES.TRIPS']?.status?.isFetching || false;
  const isLoadingReasons = state?.api['COMPANIES.CATALOGS.ORDERSMOTIVES']?.status?.isFetching || false;
  
  return {
    bookingLoading,
    configuration: state.configuration.UnassignedShipmentBoardBase,
    // transportTypes,
    bookingAssemblyAvailability,
    bookingFleetProviderAvailability: [],
    loading,
    trips,
    tripsCount,
    rows,
    orderMotives,
    contracts,
    providers,
    facilitySpaces,
    userInfo: state.userReducer.user.data,
    isLoadingBookReservation,
    isLoadingReasons,
    userId: state.globalCatalog.session.user.id,
  };
};

const mapDispatchToProps = (dispatch): DispatchToProps => {
  // dispatch(readEntities("COMMONS.CHOICES.TRANSPORTTYPE"));
  dispatch(readEntities("COMPANIES.CATALOGS.ORDERSMOTIVES"));
  dispatch(readEntities("RUTAS.TIPOVEHICULO"));
  return {
    setOrderDetail: (payload) => dispatch(setOrderSeleted(payload)),
    getUser: (userId, companyId) => dispatch(getUserInfo(userId, companyId)),
    cancelTrips: (tripsId) => dispatch(cancelTrips(tripsId)),
    cancelRequest: (entityPath: string) => dispatch(cancelRequest(entityPath)),
    setBaseConfig: (config) => dispatch(setConfig("UnassignedShipmentBoardBase", config)),
    loadSpaces: (params, opt) => 
      dispatch(
        readEntities("COMPANIES.FACILITIES.SPACES.SIMPLE", params, opt)
      ),
    loadTrips: (params, options) =>
      dispatch(
        readCatalog("COMPANIES.FACILITIES.TRIPSCONTROL", params, options)
      ),
    setAppointmentDate: (params: any, opts: ApiOptions) =>
      dispatch(partialEntity("COMPANIES.ORDERS", params, opts)),
    loadTripAvailability: (params, options) =>
      dispatch(
        readEntities(
          "COMPANIES.FACILITIES.TRIPSCONTROL.BOOKING",
          params,
          options
        )
      ),
    bookReservation: (params, options) =>
      dispatch(partialEntity("COMPANIES.TRIPS", params, options)),

    cancelAppointment: (params: any, opts: ApiOptions) =>
      dispatch(
        partialEntity(
          "COMPANIES.ORDERS",
          { ...params, action: "cancel_appointment" },
          opts
        )
      ),
    cancelBooking: (params, options) =>
      dispatch(
        partialEntity(
          "COMPANIES.TRIPS",
          { ...params, action: "cancel_booking" },
          options
        )
      ),
    finalizeTrip: (params, options) =>
      dispatch(
        partialEntity(
          "COMPANIES.TRIPS",
          { ...params, action: "finalize_trip" },
          options
        )
      ),
    finalizeOrder: (params: any, opts: ApiOptions) =>
      dispatch(
        partialEntity(
          "COMPANIES.ORDERS",
          { ...params, action: "finalize_order" },
          opts
        )
      ),
    cancelTrip: (params, options) =>
      dispatch(
        partialEntity(
          "COMPANIES.TRIPS",
          { ...params, action: "cancel_trip" },
          options
        )
      ),
    contractAvailability: (params, options) =>
      dispatch(readEntities("COMPANIES.FACILITIES.CONTRACTS", params, options)),
    providerAvailability: (params, options) =>
      dispatch(readEntities("COMPANIES.NETWORK", params, options)),
    validateObjPerms: (perms, obj) => validateObjPerms(perms, obj),
    setActionAppointmentRequest: (params, opt) =>
      dispatch(createEntity("COMPANIES.NOTIFICATIONSV2.CANCELREQUEST", params, opt))
  };
};

export default connect<Props, OwnProps, _, _, _, _>(
  mapStateToProps,
  mapDispatchToProps
)(UnassignedShipmentBoard);

const CaretContainer = styled.div`
  align-self: center;
  cursor: pointer;
`;
