import React, { Component } from "react";
import { connect } from "react-redux";
import Panel from "../../shared/component/panel";
import Button from "../../shared/controls/button";
import TabSelectorRibbon from "../../shared/controls/tab-selector-ribbon";
import ConfirmDialog from "../../shared/dialog/confirm-dialog";
import Checkbox from "../../shared/controls/checkbox";
import { loadTags } from "../../redux/reducers/tags.reducer";
import Tag from "../../shared/controls/tag";
import {
  AttentionSpanContainerGrid,
  Select,
} from "orbi_v2_frontend_components";
import SVGIcon, { icons } from "../../shared/svg-icon";
import {
  listSpaces,
  createSpace,
  deleteSpace,
  updateSpace,
  loadSpaceTags,
  createSpaceTag,
  deleteSpaceTag,
  listSpaceWindows,
  createSpaceWindow,
  deleteSpaceWindow,
  updateSpaceWindow,
  listSpaceWindowCapacities,
  createSpaceWindowCapacity,
  deleteSpaceWindowCapacity,
  updateSpaceWindowCapacity,
  createSpaceWindowCapacityTransport,
  listSpaceWindowCapacityTransport,
  deleteSpaceWindowCapacityTransport,
} from "../../redux/reducers/facility-reducer";
import { arrayDiff } from "../../shared/utils";
import ExceedPlus from "../../shared/controls/exceed-plus";
import { listExpeditionClasses } from "../../redux/reducers/company-reducer";
import OrbiSelector, {
  ORBI_SELECTOR_LISTS,
} from "../../shared/controls/orbi-selector";
import ValidatePerms from "../../shared/component/validate-perms";
import { validateObjPerms } from "../../redux/reducers/global-catalog.reducer";
// import Caret from "./caret";
import styled from "styled-components";
import Caret from "../../shared/component/caret";
import { createEntity, deleteEntity, partialEntity, readEntity, SaveItemCounter, updateEntity } from "../../redux/api";
import TextInput from "../../shared/controls/text-input";
import GenericDialog from '../../shared/dialog/generic-modal';
import Loader from '../../shared/icons/tat/loader';
import { cloneDeep } from 'lodash';
import { cancelMultipleRequests } from "../../redux/api/actions";

const DAYS = [
  { id: 1, description: "Dom" },
  { id: 2, description: "Lun" },
  { id: 3, description: "Mar" },
  { id: 4, description: "Mie" },
  { id: 5, description: "Jue" },
  { id: 6, description: "Vie" },
  { id: 7, description: "Sab" },
];

class FacilitySchedules extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentDay: 2,
      showSpaceDialog: false,
      showWindowDialog: false,
      showConfirmSpaceDialog: false,
      showConfirmWindowDialog: false,
      genericMessage: null,
      editWindowConfirmation: null,
      timeInProcess: 0,
      isLoadingRoutesDetail: false,
      currentSpace: {},
      currentWindow: {},
      capacities: [],
      expeditionClasses: [],
      editClasses: false,
      currentConflicts: [],
      errors: [],
      showErrorModal: false,
      isEngineHovered: false,
      hoveredIndex: -1,
      isDefaultManueverTimeLoading: false,
      defaultManueverTime: {
        isEditMode: false,
        full: '0',
        single: '0',
        unit: '0'
      },
      defaultManueverTimeMessage: '',
      maxElementsToShow: 15,
      isLoadingSpaces: false,
    };
    this.facilityId = this.props.facilityId;
  }
  timer = null;
  counter = -99999;

  handleDeleteSpaceDialog = (spaceIndex) => {
    let currentSpace = {};
    if (parseInt(spaceIndex)) {
      currentSpace = this.props.spaces[spaceIndex - 1];
      currentSpace.name = spaceIndex;
    } else if (spaceIndex) {
      this.props.deleteSpace({
        company_id: this.props.companyId,
        facility_id: this.facilityId,
        space_id: this.state.currentSpace.id
      }, {
        onSuccess: () => {
          this.loadSpaces()
        }, 
        onError: (e) => {
          this.setState({errors: e.response?.data?.errors || [], showErrorModal: true})
          // console.log("ERROR" , e, e.response);
        }
      })
        // .deleteSpace(this.facilityId, this.state.currentSpace.id)
        // .then(() => this.loadSpaces())
        // .catch((error) => {console.log(error)});
        // this.props.deleteSpace
    }
    this.setState({
      currentSpace,
      showConfirmSpaceDialog: !this.state.showConfirmSpaceDialog,
    });
  };

  componentDidMount() {
    document.addEventListener('page-content-scroll', this.handlePageContentScroll);
    this.loadSpaces();
    this.props.getDefaultTime({
      company_id: this.props.companyId,
      facility_id: this.props.facilityId,
    }, {
      onSuccess: (m) => {
        // debugger;
        this.showDefaultManueverTime(m);
      }
    })
  }

  componentWillUnmount() {
    this.stopTimer();
    document.removeEventListener('page-content-scroll', this.handlePageContentScroll);
  }

  handlePageContentScroll = (data) => {
    const e = data.detail;
    const scrollTop = e.target.scrollHeight - e.target.scrollTop;
    const validation = ((e.target.clientHeight + 2) > scrollTop) && ((e.target.clientHeight - 2) < scrollTop);

    if (validation && (this.props.spaces || []).length > this.state.maxElementsToShow) {
      this.props.spaces.slice(this.state.maxElementsToShow, this.state.maxElementsToShow + 15).forEach((sp) => {
        this.props.loadSpaceTags(this.facilityId, sp.id);
        this.props.listSpaceWindows(this.facilityId, sp.id);
      });

      this.setState({maxElementsToShow: this.state.maxElementsToShow + 15});
    }

  }

  loadSpaces() {
    this.setState({
      isLoadingSpaces: true,
      maxElementsToShow: 15
    });

    this.props.loadSpaces(this.facilityId).then(() => {

      this.props.spaces.slice(0, this.state.maxElementsToShow).forEach((sp) => {
        this.props.loadSpaceTags(this.facilityId, sp.id);
        this.props.listSpaceWindows(this.facilityId, sp.id);
      });

    });
  }

  handleSaveSpace = () => {
    let { currentSpace } = this.state;
    let propsSpace = Object.assign(
      {},
      this.props.spaces.find((psp) => currentSpace.id === psp.id)
    );
    if (currentSpace.id) {
      this.props
        .updateSpace(
          this.facilityId,
          currentSpace.id,
          currentSpace.load,
          currentSpace.unload,
          currentSpace.loadunload
        )
        .then(() => {
          let remove = arrayDiff(
            propsSpace.tags.map((t) => t.tags),
            currentSpace.tags
          );
          let add = arrayDiff(
            currentSpace.tags,
            propsSpace.tags.map((t) => t.tags)
          );
          let c = remove.length + add.length;
          remove.forEach((rmv) =>
            this.props
              .deleteSpaceTag(
                this.facilityId,
                currentSpace.id,
                propsSpace.tags.find((t) => t.tags === rmv).id
              )
              .then(() => (c === 1 ? this.loadSpaces() : c--))
          );
          add.forEach((ad) =>
            this.props
              .createSpaceTag(this.facilityId, currentSpace.id, ad)
              .then(() => (c === 1 ? this.loadSpaces() : c--))
          );
          return c;
        })
        .then((c) => (c === 0 ? this.loadSpaces() : ""));
    } else {
      let tags = currentSpace.tags;
      this.props
        .createSpace(
          this.facilityId,
          "sp",
          currentSpace.load,
          currentSpace.unload,
          currentSpace.loadunload
        )
        .then((r) => {
          tags.length > 0
            ? tags.forEach((t, i) =>
                this.props
                  .createSpaceTag(this.facilityId, r.response.id, t)
                  .then(() => {
                    if (i === tags.length - 1) this.loadSpaces();
                  })
              )
            : this.loadSpaces();
        });
    }
    this.handleSpaceDialog();
  };

  validWindowData = () => {
    let {
      currentSpace,
      currentWindow,
      capacities,
      expeditionClasses,
    } = this.state;
    let caps = capacities.filter((cap) => !cap.invisible);
    let x =
      (currentWindow.next_day ? true : (currentWindow.begin < currentWindow.end)) &&
      caps.length > 0 &&
      caps.every(
        (cap) =>
          expeditionClasses.filter(
            (xp) => xp.active && xp.currentCapacityId === cap.id
          ).length > 0
      ) &&
      caps.every((cap) =>
        currentSpace.load
          ? parseInt(cap.single_load) || parseInt(cap.doble_load)
          : true
      ) &&
      caps.every((cap) =>
        currentSpace.unload
          ? parseInt(cap.single_unload) || parseInt(cap.doble_unload)
          : true
      ) &&
      caps.every((cap) =>
        currentSpace.loadunload
          ? parseInt(cap.single_loadunload) || parseInt(cap.doble_loadunload)
          : true
      );
    return !x;
  };

  windowConflictDetector = (window) => {
    let prospect = {
      days: []
        .concat(
          window.repeatDays === "2" ? [1, 2, 3, 4, 5, 6, 7] : window.days || []
        )
        .filter((d) => d !== window.day || window.weekdays)
        .concat([window.day || window.weekdays]),
      spaces: []
        .concat(
          window.allSpaces
            ? this.props.spaces.map((sp) => sp.id)
            : window.spaces || []
        )
        .filter((sp) => sp.toString() !== this.state.currentSpace.id.toString())
        .concat([this.state.currentSpace.id]),
      begin: window.begin,
      end: window.next_day ? 2359 : window.end,
    };

    if (Object.keys(window).length === 0 && window.constructor === Object)
      return;
    let conflictWindows = [];
    prospect.days.forEach((day) => {
      prospect.spaces.forEach((space) => {
        (
          (
            this.props.spaces.find(
              (fi) => fi.id.toString() === space.toString()
            ) || {}
          ).windows || []
        )
          .filter((fi) => fi.weekdays.toString() === day.toString())
          .forEach((existent) => {
            existent.begin = existent.hour_from * 100 + existent.minute_from;
            existent.end = existent.hour_to * 100 + existent.minute_to;
            if (existent.begin < prospect.begin) {
              if (existent.end > prospect.begin) {
                conflictWindows.push(existent);
              }
            } else if (prospect.begin < existent.begin) {
              if (prospect.end > existent.begin) {
                conflictWindows.push(existent);
              }
            } else {
              conflictWindows.push(existent);
            }
          });
      });
    });

    if (window.next_day) {

      prospect.begin = 0;
      prospect.end = window.end;

      prospect.days.forEach((day) => {
        prospect.spaces.forEach((space) => {
          (
            (
              this.props.spaces.find(
                (fi) => fi.id.toString() === space.toString()
              ) || {}
            ).windows || []
          )
            .filter((fi) => fi.weekdays.toString() === (parseInt(day) + 1).toString())
            .forEach((existent) => {
              existent.begin = existent.hour_from * 100 + existent.minute_from;
              existent.end = existent.hour_to * 100 + existent.minute_to;
              if (existent.begin < prospect.begin) {
                if (existent.end > prospect.begin) {
                  conflictWindows.push(existent);
                }
              } else if (prospect.begin < existent.begin) {
                if (prospect.end > existent.begin) {
                  conflictWindows.push(existent);
                }
              } else {
                conflictWindows.push(existent);
              }
            });
        });
      });
    }

    this.setState({ currentConflicts: conflictWindows });
  };

  closeRoutesDetailUpdate = () => {

    if (this.state.showWindowDialog) {
      this.state.showWindowDialog = false;
    }

    if (this.state.isLoadingRoutesDetail) {
      this.setState({
        editWindowConfirmation: null,
        isLoadingRoutesDetail: false,
      }, () => {
        this.stopTimer();
        this.props.cancelMultipleRequests();
      })
    } else {
      this.setState({
        editWindowConfirmation: null,
        isLoadingRoutesDetail: false
      });
    }

  }

  handleSaveWindow = () => {
    this.props.cancelMultipleRequests();
    let counter = new SaveItemCounter(() => {
      this.loadSpaces();
    });
    let {
      currentWindow,
      currentSpace,
      capacities,
      expeditionClasses,
      // currentConflicts,
    } = this.state;
    let spaceIds = [];
    let dayIds = [];
    if (currentWindow.allSpaces)
      spaceIds = this.props.spaces.map((sp) => sp.id);
    else if (currentWindow.spaces) spaceIds = currentWindow.spaces;
    else spaceIds.push(currentSpace.id);
    if (currentWindow.repeatDays && currentWindow.repeatDays.toString() === "2")
      dayIds = [1, 2, 3, 4, 5, 6, 7];
    else if (
      currentWindow.repeatDays &&
      currentWindow.repeatDays.toString() === "3"
    )
      dayIds = dayIds.concat(currentWindow.days);
    else dayIds.push(this.state.currentDay);

    let isInterday = currentWindow.next_day && currentWindow.interday === 2;

    if(currentWindow.id){
        counter.add()
        this.props.createWindow({
          spaces: spaceIds,
          isMultipleCancels: true,
          company_id: this.props.companyId,
          facility_id: this.facilityId,
          space_id: spaceIds.length > 0 ? spaceIds[0] : 0,
          next_day: currentWindow.next_day ? currentWindow.next_day : false,
          hour_from: parseInt(currentWindow.begin / 100),
          hour_to: parseInt(currentWindow.end / 100),
          minute_from: currentWindow.begin % 100,
          minute_to: currentWindow.end % 100,
          weekdays: dayIds.map(d => isInterday ? (parseInt(d) - 1) : parseInt(d)),
          capacities: capacities.filter(cap => !cap.invisible).map(cap => ({
            single_load: cap.single_load,
            single_unload: cap.single_unload,
            doble_load: cap.doble_load,
            doble_unload: cap.doble_unload,
            single_loadunload: cap.single_loadunload,
            doble_loadunload: cap.doble_loadunload,
            dobleequipment: cap.dobleequipment,
            transport_class: expeditionClasses.filter((f) => f.currentCapacityId === cap.id).map(eC => eC.id),
          })),
        }, {
          onSuccess: () => {
            this.setState({
              editWindowConfirmation: null,
              isLoadingRoutesDetail: false,
              timeInProcess: 0,
              genericMessage: {title: '¡Listo!', message: 'Se actualizó correctamente'}
            }, () => this.stopTimer());
            counter.done()
          },
          onError: () => {
            this.setState({
              editWindowConfirmation: null,
              timeInProcess: 0,
              isLoadingRoutesDetail: false,
              genericMessage: {title: '¡Error!', message: 'Ocurrió un error, intente de nuevo'}
            }, () => this.stopTimer());
            counter.done()
          }
        })
    } else {
      counter.add()
      this.props.createWindow({
        spaces: spaceIds,
        isMultipleCancels: true,
        company_id: this.props.companyId,
        facility_id: this.facilityId,
        space_id: spaceIds.length > 0 ? spaceIds[0] : 0,
        next_day: currentWindow.next_day ? currentWindow.next_day : false,
        hour_from: parseInt(currentWindow.begin / 100),
        hour_to: parseInt(currentWindow.end / 100),
        minute_from: currentWindow.begin % 100,
        minute_to: currentWindow.end % 100,
        weekdays: dayIds.map(d => isInterday ? (parseInt(d) - 1) : parseInt(d)),
        capacities: capacities.filter(cap => !cap.invisible).map(cap => ({
          single_load: cap.single_load,
          single_unload: cap.single_unload,
          doble_load: cap.doble_load,
          doble_unload: cap.doble_unload,
          single_loadunload: cap.single_loadunload,
          doble_loadunload: cap.doble_loadunload,
          dobleequipment: cap.dobleequipment,
          transport_class: expeditionClasses.filter((f) => f.currentCapacityId === cap.id).map(eC => eC.id),
        })),
      }, {
        onSuccess: () => {
          this.setState({
            editWindowConfirmation: null,
            isLoadingRoutesDetail: false,
            timeInProcess: 0,
            genericMessage: {title: '¡Listo!', message: 'Se actualizó correctamente'}
          }, () => this.stopTimer());
          counter.done()
        },
        onError: () => {
          this.setState({
            editWindowConfirmation: null,
            timeInProcess: 0,
            isLoadingRoutesDetail: false,
            genericMessage: {title: '¡Error!', message: 'Ocurrió un error, intente de nuevo'}
          }, () => this.stopTimer());
          counter.done()
        }
      })
    }

    // if (currentWindow.id && currentConflicts.length === 0) {
    //   this.props
    //     .updateSpaceWindow(
    //       this.facilityId,
    //       currentSpace.id,
    //       currentWindow.id,
    //       parseInt(currentWindow.begin / 100),
    //       currentWindow.begin % 100,
    //       parseInt(currentWindow.end / 100),
    //       currentWindow.end % 100,
    //       currentWindow.day
    //     )
    //     .then(() =>
    //       capacities.forEach((capacity, i) => {
    //         if (capacity.invisible) {
    //           this.props.deleteSpaceWindowCapacity(
    //             this.facilityId,
    //             currentSpace.id,
    //             currentWindow.id,
    //             capacity.id
    //           );
    //         } else if (capacity.id < 0) {
    //           this.props
    //             .createSpaceWindowCapacity(
    //               this.facilityId,
    //               currentSpace.id,
    //               currentWindow.id,
    //               capacity.single_load,
    //               capacity.single_unload,
    //               capacity.doble_load,
    //               capacity.doble_unload,
    //               capacity.single_loadunload,
    //               capacity.doble_loadunload,
    //               capacity.dobleequipment
    //             )
    //             .then((r) => {
    //               if (r.response) {
    //                 expeditionClasses
    //                   .filter((f) => f.currentCapacityId === capacity.id)
    //                   .forEach((ec) =>
    //                     this.props.createSpaceWindowCapacityTransport(
    //                       this.facilityId,
    //                       currentSpace.id,
    //                       currentWindow.id,
    //                       r.response.id,
    //                       ec.id
    //                     )
    //                   );
    //               }
    //             });
    //         } else {
    //           this.props
    //             .updateSpaceWindowCapacity(
    //               this.facilityId,
    //               currentSpace.id,
    //               currentWindow.id,
    //               capacity.id,
    //               capacity.single_load,
    //               capacity.single_unload,
    //               capacity.doble_load,
    //               capacity.doble_unload,
    //               capacity.single_loadunload,
    //               capacity.doble_loadunload,
    //               capacity.dobleequipment
    //             )
    //             .then(() => {
    //               expeditionClasses
    //                 .filter((f) => f.currentCapacityId === null)
    //                 .forEach((ec) =>
    //                   this.props.deleteSpaceWindowCapacityTransport(
    //                     this.facilityId,
    //                     currentSpace.id,
    //                     currentWindow.id,
    //                     capacity.id,
    //                     ec.relationId
    //                   )
    //                 );
    //               expeditionClasses
    //                 .filter(
    //                   (f) =>
    //                     f.currentCapacityId !== f.originalCapacityId &&
    //                     f.originalCapacityId === capacity.id
    //                 )
    //                 .forEach((ec) =>
    //                   this.props.deleteSpaceWindowCapacityTransport(
    //                     this.facilityId,
    //                     currentSpace.id,
    //                     currentWindow.id,
    //                     capacity.id,
    //                     ec.relationId
    //                   )
    //                 );
    //               expeditionClasses
    //                 .filter(
    //                   (f) =>
    //                     f.currentCapacityId === capacity.id &&
    //                     f.currentCapacityId !== f.originalCapacityId
    //                 )
    //                 .forEach((ec) =>
    //                   this.props.createSpaceWindowCapacityTransport(
    //                     this.facilityId,
    //                     currentSpace.id,
    //                     currentWindow.id,
    //                     capacity.id,
    //                     ec.id
    //                   )
    //                 );
    //             });
    //         }
    //         if (i === capacities.length - 1) this.loadSpaces();
    //       })
    //     );
    // } else {
    //   spaceIds.forEach((spId, ind) => {
    //     dayIds.forEach((dayId) => {
    //       this.props
    //         .createSpaceWindow(
    //           this.facilityId,
    //           spId,
    //           parseInt(currentWindow.begin / 100),
    //           currentWindow.begin % 100,
    //           parseInt(currentWindow.end / 100),
    //           currentWindow.end % 100,
    //           dayId
    //         )
    //         .then((window) => {
    //           if (window.response) {
    //             capacities.filter(cap => !cap.invisible).forEach((cap) =>
    //               this.props
    //                 .createSpaceWindowCapacity(
    //                   this.facilityId,
    //                   spId,
    //                   window.response.id,
    //                   cap.single_load,
    //                   cap.single_unload,
    //                   cap.doble_load,
    //                   cap.doble_unload,
    //                   cap.single_loadunload,
    //                   cap.doble_loadunload,
    //                   cap.dobleequipment
    //                 )
    //                 .then((capacity) => {
    //                   if (capacity.response) {
    //                     expeditionClasses
    //                       .filter((f) => f.currentCapacityId === cap.id)
    //                       .forEach((ec) =>
    //                         this.props.createSpaceWindowCapacityTransport(
    //                           this.facilityId,
    //                           currentSpace.id,
    //                           window.response.id,
    //                           capacity.response.id,
    //                           ec.id
    //                         )
    //                       );
    //                   }
    //                 })
    //             );
    //           }
    //         });
    //       // .then(() => count < 1 ? count-- : this.loadSpaces())
    //     });
    //     if (ind === spaceIds.length - 1) this.loadSpaces();
    //   });
    //   currentConflicts.forEach((cc) =>
    //     this.props.deleteSpaceWindows(this.facilityId, cc.spaces, cc.id)
    //   );
    // }
    this.handleWindowDialog();
  };

  handleDeleteWindowDialog = (windowId) => {
    let currentSpace = {};
    let currentWindow = {};
    if (parseInt(windowId)) {
      this.props.spaces.forEach((sp) => {
        let win = sp.windows.find((w) => w.id === windowId);
        if (win ? true : false) {
          currentSpace = sp;
          currentWindow = win;
          currentWindow.begin = win.hour_from * 100 + win.minute_from;
          currentWindow.end = win.hour_to * 100 + win.minute_to;
        }
      });
    } else if (windowId) {
      this.props.deleteWindow({
        company_id: this.props.companyId,
        facility_id: this.facilityId,
        space_id: this.state.currentSpace.id,
        window_id: this.state.currentWindow.id
      }, {
        onSuccess: () => {
          this.loadSpaces()
        },
        onError: () => {
          this.loadSpaces()
        }
      });
      // this.props
      //   .deleteSpaceWindows(
      //     this.facilityId,
      //     this.state.currentSpace.id,
      //     this.state.currentWindow.id
      //   )
      //   .then(() => this.loadSpaces());
    }
    this.setState({
      currentSpace,
      currentWindow,
      showConfirmWindowDialog: !this.state.showConfirmWindowDialog,
    });
  };

  handleChange = (e) => {
    const name = e.target.name || e.target.attributes["name"].value;
    let currentSpace = Object.assign({}, this.state.currentSpace);
    let currentWindow = Object.assign({}, this.state.currentWindow);
    let capacities = [...this.state.capacities];
    let expeditionClasses = Object.assign(this.state.expeditionClasses);
    switch (name) {
      case "tags":
        currentSpace.tags = e.target.value
          ? currentSpace.tags.filter((t) => t !== e.target.id)
          : currentSpace.tags.concat(e.target.id);
        break;
      case "maneuver":
        currentSpace[e.target.value] = e.target.checked;
        break;
      case "startHour":
        currentWindow.begin =
          (currentWindow.begin % 100) +
          this.limitNumber(parseInt(e.target.value), 23) * 100;
        break;
      case "startMinute":
        currentWindow.begin =
          parseInt(currentWindow.begin / 100) * 100 +
          this.limitNumber(parseInt(e.target.value), 59);
        break;
      case "endHour":
        currentWindow.end =
          (currentWindow.end % 100) +
          this.limitNumber(parseInt(e.target.value), 23) * 100;
        break;
      case "endMinute":
        currentWindow.end =
          parseInt(currentWindow.end / 100) * 100 +
          this.limitNumber(parseInt(e.target.value), 59);
        break;
      case "nextDay": 
        currentWindow.next_day = e.target.checked;
        break;
      case "allDay":
        if (e.target.checked) {
          currentWindow.begin = 0;
          currentWindow.end = 2359;
        }
        break;
      case "removeCapacity":
        capacities.find(
          (c) => c.id.toString() === e.target.value.toString()
        ).invisible = true;
        expeditionClasses
          .filter(
            (c) =>
              (c.currentCapacityId || "").toString() ===
              e.target.value.toString()
          )
          .forEach((c) => {
            c.currentCapacityId = null;
            c.active = false;
          });
        break;
      case "allSpaces":
        currentWindow.allSpaces = e.target.checked;
        break;
      case "addSpace":
        currentWindow.spaces = (currentWindow.spaces || []).filter(
          (s) => s.toString() !== e.target.value.toString()
        );
        if (e.target.checked) currentWindow.spaces.push(e.target.value);
        break;
      case "addDay":
        currentWindow.days = (currentWindow.days || []).filter(
          (s) => s.toString() !== e.target.value.toString()
        );
        if (e.target.checked) currentWindow.days.push(e.target.value);
        break;
      case "repeatDays":
        currentWindow.repeatDays = e.target.value;
        if (e.target.value === "3") this.setState({ showDays: true });
        else this.setState({ showDays: false });
        break;
      case "single_load":
      case "single_unload":
      case "doble_load":
      case "doble_unload":
      case "single_loadunload":
      case "doble_loadunload":
        (capacities.find((c) => c.id.toString() === e.target.id.toString()) ||
          {})[e.target.name] = e.target.value;
        break;
      case "expClass":
        if (e.target.value === "-1") {
          let x = capacities.find((c) => c.id === this.state.currentCapacityId);
          expeditionClasses
            .filter((c) => c.equipment_quantity === (x.dobleequipment ? 2 : 1))
            .filter(
              (c) =>
                c.currentCapacityId === this.state.currentCapacityId ||
                !c.active
            )
            .forEach((c) => {
              c.active = e.target.checked;
              c.currentCapacityId = e.target.checked
                ? this.state.currentCapacityId
                : null;
            });
        } else {
          (
            expeditionClasses.find(
              (c) => c.id.toString() === e.target.value.toString()
            ) || {}
          ).active = e.target.checked;
          (
            expeditionClasses.find(
              (c) => c.id.toString() === e.target.value.toString()
            ) || {}
          ).currentCapacityId = e.target.checked
            ? this.state.currentCapacityId
            : null;
        }
        break;
      default:
        // this.setState({[name]: e.target.value});
    }
    this.windowConflictDetector(currentWindow);
    this.setState({
      currentSpace,
      currentWindow,
      capacities,
      expeditionClasses,
    });
  };

  handleSpaceDialog = (id) => {
    let currentSpace = {};
    if (parseInt(id)) {
      currentSpace = Object.assign({}, this.props.spaces[id - 1]);
      currentSpace.tags = currentSpace.tags.map((t) => t.tags);
    } else {
      currentSpace = {
        load: false,
        unload: false,
        loadunload: false,
        tags: [],
        name: "",
        id: null,
      };
    }
    this.setState({
      showSpaceDialog: !this.state.showSpaceDialog,
      currentSpace,
    });
  };

  handleWindowDialog = (index, initTime) => {
    initTime *= 100;
    let currentSpace = {};
    let currentWindow = {};
    let capacities = [];
    let expeditionClasses = JSON.parse(JSON.stringify(this.props.expClasses));
    let currentCapacityId = null;
    let editClasses = false;
    if (parseInt(index) && isNaN(initTime)) {
      this.props.spaces.forEach((sp, ind) => {
        let win = sp.windows.find((w) => w.id === index);

        if (win && win.next_day) {
          win = sp.windows.find((w) => w.id === index && w.weekdays === this.state.currentDay);
        }

        if (win ? true : false) {
          win.spaces = [win.spaces.toString()];
          this.props
            .listSpaceWindowCapacities(this.facilityId, sp.id, win.id)
            .then(() => {
              let caps = this.props.capacities;
              caps.forEach((capacity) =>
                this.props
                  .listSpaceWindowCapacityTransport(
                    this.facilityId,
                    sp.id,
                    win.id,
                    capacity.id
                  )
                  .then((aver) => {
                    if (aver.response)
                      aver.response.forEach((x) => {
                        (
                          expeditionClasses.find(
                            (l) => l.id === x.transport_class
                          ) || {}
                        ).active = true;
                        (
                          expeditionClasses.find(
                            (l) => l.id === x.transport_class
                          ) || {}
                        ).currentCapacityId = capacity.id;
                        (
                          expeditionClasses.find(
                            (l) => l.id === x.transport_class
                          ) || {}
                        ).originalCapacityId = capacity.id;
                        (
                          expeditionClasses.find(
                            (l) => l.id === x.transport_class
                          ) || {}
                        ).relationId = x.id;
                      });
                    this.setState({ capacities: caps });
                  })
              );
            });
          currentSpace = sp;
          currentSpace.name = ind + 1;
          currentWindow = win;
          currentWindow.begin = win.next_day ? (win.hour_from_real * 100 + win.minute_from_real) : (win.hour_from * 100 + win.minute_from);
          currentWindow.end = win.next_day ? (win.hour_to_real * 100 + win.minute_to_real) : (win.hour_to * 100 + win.minute_to);
        }
      });
    } else if (index) {
      currentSpace = this.props.spaces[index - 1];
      currentSpace.name = index;
      currentWindow = {
        day: this.state.currentDay,
        begin: initTime,
        end: initTime + 100,
        spaceIndex: index,
        id: null,
      };
    }
    this.setState({
      currentSpace,
      currentWindow,
      currentCapacityId,
      editClasses,
      showWindowDialog: !this.state.showWindowDialog,
      capacities,
      showDays: false,
      showSpaces: false,
      expeditionClasses,
    });
  };

  handleChangeDay = (e) => {
    if (e.target.attributes["name"])
      this.setState({
        currentDay: parseInt(e.target.attributes["name"].value),
      });
  };

  handleTimeInput = (e) => {
    let tms = e.target.value.replace(":", "");
    this.handleChange({ target: { name: e.target.name, value: tms } });
  };
  switchEditMode = () => {
    this.props.switchEditView(false);
  };

  getHours = () => {
    let hours = [];
    let h = 0;
    while(h < 24) {
      let temp = h < 10 ? '0'.concat(h) : h.toString();
      hours.push({
        id: temp,
        description: temp
      })
      h++;
    }
    return hours;
  }

  getMinutes = () => {
    let minutes = [];
    let m = 0;
    while(m < 60) {
      let temp = m < 10 ? '0'.concat(m) : m.toString();
      minutes.push({
        id: temp,
        description: temp
      })
      m = m + 10;
    }
    return minutes;
  }

  showDefaultManueverTime = (manuever) => {

    let fullTime = '0';
    let singleTime = '0';
    let unitTime = '0';

    if (manuever !== null) { 

      if (manuever.full_operation_minutes) {
        fullTime = `${manuever.full_operation_minutes}`
      }

      if (manuever.single_operation_minutes) {
        singleTime = `${manuever.single_operation_minutes}`
      }

      if (manuever.unit_operation_minutes) {
        unitTime = `${manuever.unit_operation_minutes}`
      }

    }
    
    let manueverTime = this.state.defaultManueverTime;

    manueverTime.full = fullTime;
    manueverTime.single = singleTime;
    manueverTime.unit = unitTime;

    this.setState({
      defaultManueverTime: manueverTime
    });

  }

  setDefaultManueverMessage = (message) => {

    let tempValue = this.state.defaultManueverTime;
    tempValue.isEditMode = false;

    this.setState({
      defaultManueverTime: tempValue,
      defaultManueverTimeMessage: message,
      isDefaultManueverTimeLoading: false
    });

  }

  getSpaces = () => {
    return this.props.spaces.slice(0, this.state.maxElementsToShow);
  }

  render() {
    let spaces_data = this.getSpaces();

    let title = this.props.editMode ? "" : "Espacios de atención";
    const panelClass = this.props.editMode
      ? "facility-schedules-panel anti-panel"
      : "facility-schedules-panel";
    let number = this.props.editMode ? "" : "3.1";
    let message = this.props.editMode
      ? ""
      : "Selecciona el día e indica tus horarios de atención";
    let gear = this.props.editMode ? ( 
      <div 
        className="config-gear" 
        onClick={this.switchEditMode}
        onMouseEnter={() => this.setState({ isEngineHovered: true })}
        onMouseLeave={() => this.setState({ isEngineHovered: false})}
        >
        Regresar al calendario
        <img 
          width={ this.state.isEngineHovered ? "35px" : "30px"} 
          height={ this.state.isEngineHovered ? "35px" : "30px"} 
          src="/images/menu/gear.svg" alt="" />
      </div>
    ) : (
      <Button
        onClick={this.handleSpaceDialog}
        type="primary"
        text="Crear espacio"
      />
    );
    let editingSpaceButton = this.props.editMode ? (
      <ValidatePerms
        perms={[
          {
            id: "33",
            perm: "c",
          },
        ]}
      >
        <Button
          onClick={this.handleSpaceDialog}
          type="primary small"
          text="Crear espacios"
          className="mb-10"
        />
      </ValidatePerms>
    ) : (
      <React.Fragment />
    );

    return (
      <React.Fragment>
        <div className="facility-schedules">
          <Panel title={title} number={number} className={panelClass}>
            <div
              style={{
                display: "flex",
                width: "100%",
                marginTop: "-30px",
                marginBottom: "10px",
                justifyContent: "space-between",
              }}
            >
              <label style={{ color: "white", marginLeft: "60px" }}>
                {message}
              </label>
              {gear}
              {/* <div className="default-time-selection">
                
                <div style={{width: 115}}>
                  {'Tiempos de atención\ndefault en minutos:'}
                </div>

                <ManueverTimeComponent>
                  <TextInput 
                    label="Full"
                    name="full"
                    type="number"
                    value={this.state.defaultManueverTime.full}
                    disabled={!this.state.defaultManueverTime.isEditMode}
                    onChange={(e) => {
                      let tempValue = this.state.defaultManueverTime;
                      tempValue.full = `${e.target.value}`.replace(/[^0-9]/g, '');

                      this.setState({
                        defaultManueverTime: tempValue
                      });
                    }}
                  />
                </ManueverTimeComponent>

                <ManueverTimeComponent>
                  <TextInput 
                    label="Sencillo"
                    name="sencillo"
                    type="number"
                    value={this.state.defaultManueverTime.single}
                    disabled={!this.state.defaultManueverTime.isEditMode}
                    onChange={(e) => {
                      let tempValue = this.state.defaultManueverTime;
                      tempValue.single = `${e.target.value}`.replace(/[^0-9]/g, '');

                      this.setState({
                        defaultManueverTime: tempValue
                      });
                    }}
                  />
                </ManueverTimeComponent>

                <ManueverTimeComponent>
                  <TextInput 
                    label="Unitario"
                    name="unit"
                    type="number"
                    value={this.state.defaultManueverTime.unit}
                    disabled={!this.state.defaultManueverTime.isEditMode}
                    onChange={(e) => {
                      let tempValue = this.state.defaultManueverTime;
                      tempValue.unit = `${e.target.value}`.replace(/[^0-9]/g, '');

                      this.setState({
                        defaultManueverTime: tempValue
                      });
                    }}
                  />
                </ManueverTimeComponent>

                <div style={{width: 15}}/>
                
                {
                  this.state.defaultManueverTime.isEditMode ?
                  <Button
                    settings={{
                      type: "primary small",
                      text: this.state.isDefaultManueverTimeLoading ? <Loader /> : "Guardar",
                      className: "mb-10",
                      onClick: () => {

                        if (this.state.isDefaultManueverTimeLoading) { return; }

                        this.setState({
                          isDefaultManueverTimeLoading: true
                        }, () => {

                          this.props.updateDefaultTime({
                            company_id: this.props.companyId,
                            facility_id: this.props.facilityId,
                            full_operation_minutes: this.state.defaultManueverTime.full,
                            single_operation_minutes: this.state.defaultManueverTime.single,
                            unit_operation_minutes: this.state.defaultManueverTime.unit,
                          }, {
                            onSuccess: (response) => {
                              this.setDefaultManueverMessage("Se guardó con éxito los tiempo de atención");
                            },
                            onError: () => {
                              this.setDefaultManueverMessage("Ocurrió un error, intente de nuevo");
                            }
                          });

                        });
                      }
                    }}
                  /> : <EditManueverTimeComponent
                    onClick={() => {
                      let tempValue = this.state.defaultManueverTime;
                      tempValue.isEditMode = true;

                      this.setState({
                        defaultManueverTime: tempValue
                      });
                    }}
                  >
                    <img alt="" src="/images/edit-transparent.svg" />
                  </EditManueverTimeComponent>
                }
              </div> */}
              <div className="default-time-selection"></div>
            </div>
            <TabSelectorRibbon
              className="facility-schedules-ribbon"
              onChange={this.handleChangeDay}
              items={DAYS}
              activeId={this.state.currentDay}
            />
            {editingSpaceButton}
            <div
              style={{
                minHeight: "auto",
                width: "100%",
                position: "relative",
              }}
            >
              <div
                style={{
                  height: "auto",
                  overflowX: "auto",
                  overflowY: "auto",
                }}
              >
                <div
                  style={{
                    minWidth: "2000px",
                    width: "100%",
                    color: "white",
                  }}
                >
                  <AttentionSpanContainerGrid
                    className="facility-schedules-grid"
                    spaces={spaces_data.map((sp, index) => ({
                      tagElement: (
                        <ExceedPlus maxVisibleitems="1" name={sp.id}>
                          {(sp.tags || [])
                            .map((spt) =>
                              this.props.tags.find((prt) => prt.id === spt.tags)
                            )
                            .map((t) => (
                              <Tag
                                key={t.id}
                                id={t.id}
                                color={t.color}
                                title={t.title}
                                //  type: PropTypes.number,
                                //  isFull: PropTypes.bool,
                                //  parentBackground: PropTypes.oneOf(['light', 'dark']),
                                //  className: PropTypes.string,
                                //  onClick: PropTypes.func
                              />
                            ))}
                        </ExceedPlus>
                      ),
                      id: index + 1,
                      onEditSpace: validateObjPerms([{ id: "33", perm: "u" }], {
                        r: true,
                      }).r
                        ? this.handleSpaceDialog
                        : null,
                      onDeleteSpace: validateObjPerms(
                        [{ id: "33", perm: "d" }],
                        {
                          r: true,
                        }
                      ).r
                        ? this.handleDeleteSpaceDialog
                        : null,
                      onCreateWindow: validateObjPerms(
                        [{ id: "34", perm: "c" }],
                        {
                          r: true,
                        }
                      ).r
                        ? this.handleWindowDialog
                        : null,
                      onEditWindow: validateObjPerms(
                        [{ id: "34", perm: "u" }],
                        {
                          r: true,
                        }
                      ).r
                        ? this.handleWindowDialog
                        : null,
                      onDeleteWindow: validateObjPerms(
                        [{ id: "34", perm: "d" }],
                        {
                          r: true,
                        }
                      ).r
                        ? this.handleDeleteWindowDialog
                        : null,
                      load: sp.load,
                      tagList: (sp.tags || []).map((spt) =>
                        this.props.tags.find((prt) => prt.id === spt.tags)
                      ),
                      unload: sp.unload,
                      loadUnload: sp.loadunload,
                      attentionSpans: sp.windows
                        .filter((f) => f.weekdays === this.state.currentDay)
                        .map((w) => ({
                          id: w.id,
                          startTime: w.hour_from * 100 + w.minute_from,
                          endTime: w.hour_to * 100 + w.minute_to,
                          first_day: (w.next_day && w.interday !== undefined) ? w.interday === 1 : undefined
                        })),
                    }))}
                    visible={this.props.gridVisible}
                  />
                </div>
              </div>
              {
              (this.state.isLoadingSpaces && (this.props.spaces || []).length === 0) &&
              <div
                style={{
                  height: 140,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: 'white',
                  fontSize: 23
                }}
              >
                Cargando
                <Loader width="60px" height="60px"/>
              </div>
            }
            </div>
          </Panel>

          <ConfirmDialog
            closeAction={this.handleSpaceDialog}
            open={this.state.showSpaceDialog}
            title={`${
              this.state.currentSpace.id ? "Editar" : "Crear"
            } espacio ${(this.state.currentSpace.id
              ? this.props.spaces.findIndex(
                  (sp) => sp.id === this.state.currentSpace.id
                )
              : this.props.spaces.length) + 1}`}
            // message={}
            className="spaces-modal"
            acceptText="Guardar"
            acceptAction={this.handleSaveSpace}
            showCancel={true}
            cancelText="Cancelar"
            contentObject={this.getSpaceContent()}
            disabledAccept={
              !(
                this.state.currentSpace.load ||
                this.state.currentSpace.unload ||
                this.state.currentSpace.loadunload
              )
            }
          />

          <ConfirmDialog
            closeAction={this.handleWindowDialog}
            open={this.state.showWindowDialog}
            title=""
            className="spaces-window-modal"
            acceptText="Guardar"
            acceptAction={
              () => {
              this.props.getRoutesDetail({
                company_id: this.props.companyId,
                facility_id: this.facilityId
              }, {
                onSuccess: (r) => {
                  this.setState({
                    editWindowConfirmation: r,
                  });
                },
                onError: () => {
                  this.setState({
                    editWindowConfirmation: {
                      error: 'Ocurrió un error, intente de nuevo'
                    }
                  });
                }
              })
            }}
            contentObject={this.getWindowContent()}
            showCancel={true}
            cancelText="Cancelar"
            disabledAccept={this.props.isLoadingRoutesDetailInfo || this.validWindowData()}
          />

          <ConfirmDialog 
            closeAction={() => this.closeRoutesDetailUpdate()}
            open={this.state.editWindowConfirmation !== null}
            title="Actualización de ventana"
            className="spaces-window-modal"
            acceptText="Continuar"
            acceptAction={() => {
              this.setState({
                isLoadingRoutesDetail: true,
                timeInProcess: 0
              }, () => {
                this.handleSaveWindow();
                this.startTimer();
              });
            }}
            contentObject={this.getRoutesDetailContent()}
            showCancel={!this.props.isLoadingWindowsCreation}
            showCloseModal={!this.props.isLoadingWindowsCreation}
            cancelText="Cancelar"
            disabledAccept={this.props.isLoadingWindowsCreation || this.state.isLoadingRoutesDetail || this.isBlockedRoutesDetailEditing()}
          />

          <ConfirmDialog
            closeAction={this.handleDeleteSpaceDialog}
            open={this.state.showConfirmSpaceDialog}
            title="Eliminar espacio"
            message={`¿Estas seguro de eliminar el espacio ${this.state.currentSpace.name}?`}
            className=""
            acceptText="Eliminar"
            acceptAction={this.handleDeleteSpaceDialog}
            showCancel={true}
            cancelText="Cancelar"
          />

          <ConfirmDialog
            closeAction={this.handleDeleteWindowDialog}
            open={this.state.showConfirmWindowDialog}
            title="Eliminar espacio"
            message={"¿Estas seguro de eliminar la ventana?"}
            className=""
            acceptText="Eliminar"
            acceptAction={this.handleDeleteWindowDialog}
            showCancel={true}
            cancelText="Cancelar"
          />

          <ConfirmDialog
            closeAction={() => this.setState({showErrorModal: false, errors: []})}
            open={this.state.showErrorModal}
            title="Error"
            message={this.state.errors}
            className=""
            acceptText="Aceptar"
            acceptAction={() => this.setState({showErrorModal: false, errors: []})}
            showCancel={false}
            cancelText="Cancelar"
          />

          <GenericDialog
            open={this.state.defaultManueverTimeMessage !== ''}
            title="¡Listo!"
            message= {this.state.defaultManueverTimeMessage}
            acceptAction={() => {
              this.setState({defaultManueverTimeMessage: ''});
            }}
            closeAction={() => {
              this.setState({defaultManueverTimeMessage: ''});
            }}
          />

          <GenericDialog
            open={this.state.genericMessage !== null}
            title={this.state.genericMessage?.title ?? ''}
            message= {this.state.genericMessage?.message ?? ''}
            acceptAction={() => this.setState({genericMessage: null})}
            closeAction={() => this.setState({genericMessage: null})}
          />

        </div>
      </React.Fragment>
    );
  }

  getSpaceContent = () => {
    return (
      <div className="checkbox-block">
        <div>Tipo de maniobras:</div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "15px",
            marginBottom: "25px",
          }}
        >
          <Checkbox
            onChange={this.handleChange}
            item={{
              description: "Solo carga",
              id: "load",
              active: this.state.currentSpace.load,
            }}
            name="maneuver"
          />
          <Checkbox
            onChange={this.handleChange}
            item={{
              description: "Solo descarga",
              id: "unload",
              active: this.state.currentSpace.unload,
            }}
            name="maneuver"
          />
          <Checkbox
            onChange={this.handleChange}
            item={{
              description: "Carga y Descarga",
              id: "loadunload",
              active: this.state.currentSpace.loadunload,
            }}
            name="maneuver"
          />
        </div>
        Selecciona las etiquetas del tipo de operación que deseas atender de
        manera exclusiva en este espacio
        <div className="space-tags">
          {this.mapTagsToChips(this.state.currentSpace.tags)}
        </div>
      </div>
    );
  };

  getFormatTimeInTime = (time) => {
    let minutes = time > 0 ? Math.floor(time / 60) : 0;
    let seconds = time > 0 ? time - minutes * 60 : 0;

    let secondsText = seconds > 9 ? `${seconds}` : `0${seconds}`;

    return `${minutes}:${secondsText}`
  }

  startTimer = () => {
    this.stopTimer();

    this.timer = setInterval(() => {
      this.state.timeInProcess = this.state.timeInProcess + 1;
      this.setState({});
    }, 1000);

  }

  stopTimer = () => {
    clearTimeout(this.timer);
    this.timer = null;
  }

  isBlockedRoutesDetailEditing = () => {
    return this.state.editWindowConfirmation?.blocked ?? false;
  }

  getRoutesDetailContent = () => {

    if (this.state.editWindowConfirmation === null) {
      return(<React.Fragment></React.Fragment>);
    }

    if (this.state.editWindowConfirmation.blocked) {

      return(
        <React.Fragment>
          <ItemsWithMargin>{'Actualmente se esta realizando un cambio, intente más tarde'}</ItemsWithMargin>
        </React.Fragment>
      );

    } else {

      let time = this.state.editWindowConfirmation.estimated_time || 0;
      let timeText = this.getFormatTimeInTime(time);

      if (this.state.isLoadingRoutesDetail) {
        return (
          <React.Fragment>
            
            <ItemsWithMargin>{`Tiempo estimado ${timeText} min:`}</ItemsWithMargin>
            <div>{`Registros: ${this.state.editWindowConfirmation.registers || 0}`}</div>
            <ItemsWithMargin>{`Rutas: ${this.state.editWindowConfirmation.routes || 0}`}</ItemsWithMargin>
            <CenterItems>{this.getFormatTimeInTime(this.state.timeInProcess)}</CenterItems>
            <CenterItems><Loader circleColor={'black'} width="40px" height="40px"/></CenterItems>
    
          </React.Fragment>
        );
      } else {
        return (
          <React.Fragment>
            
            <ItemsWithMargin>{`
            Esta acción modificara ${(this.state.currentWindow.allSpaces || false) ? (this.props.spaces || []).length : 1} ventanas en ${this.state.editWindowConfirmation.routes || 0} rutas, tardará un tiempo estimado de ${timeText} min.
Al hacer clik en “continuar” la acción no podrá ser cancelada y no podrá hacer ningún otro cambio en la ventana hasta que el proceso termine. Por favor no cierre la ventana del navegador, sin embargo aunque la cierre el proceso continuará en segundo plano hasta que todas la ventanas y rutas mencionadas hayan sido actualizadas.
`}</ItemsWithMargin>
            <div>{`¿Desea continuar?`}</div>
    
          </React.Fragment>
        );
      }

    }

  }

  getWindowContent = () => {
    let {
      currentSpace,
      currentWindow,
      showDays,
      showSpaces,
      capacities,
    } = this.state;
    return (
      <React.Fragment>
        <div className="space-window-dialog">
          <div className="header">
            <div className="title">
              {`${currentWindow.id ? "Editar" : "Crear"} ventana en espacio ${
                currentSpace.name
              }`}
            </div>
            <div className="attention-span-data">
              <div className="time-block">
                <div 
                  className="title"
                  style={{display: 'flex'}}
                >
                  Horario de atención:
                  <div 
                    style={{
                      paddingLeft: 12,
                      fontWeight: 100
                    }}
                  >
                    <Checkbox
                      name="allDay"
                      item={{
                        id: 0,
                        description: "Todo el día",
                      }}
                      active={ 
                        currentWindow.begin === 0 &&
                        currentWindow.end === 2359
                      }
                      disabled={currentWindow.next_day}
                      onChange={this.handleChange}
                    />  
                  </div>
                </div>
                <div className="data">
                  de:
                  <input
                    name="startHour"
                    type="number"
                    value={this.formatNumberToTimePart(
                      currentWindow.begin,
                      true
                    )}
                    onChange={this.handleTimeInput}
                  />
                  :
                  <input
                    name="startMinute"
                    type="number"
                    value={this.formatNumberToTimePart(currentWindow.begin)}
                    onChange={this.handleTimeInput}
                  />
                  a:
                  <input
                    name="endHour"
                    type="number"
                    value={this.formatNumberToTimePart(currentWindow.end, true)}
                    onChange={this.handleTimeInput}
                  />
                  :
                  <input
                    name="endMinute"
                    type="number"
                    value={this.formatNumberToTimePart(currentWindow.end)}
                    onChange={this.handleTimeInput}
                  />

                    <Checkbox
                      name="nextDay"
                      item={{
                        id: 0,
                        description: "Día siguiente",
                      }}
                      active={currentWindow.next_day}
                      disabled={
                        currentWindow.begin === 0 &&
                        currentWindow.end === 2359
                      }
                      onChange={this.handleChange}
                    />
                </div>
                <div className="repeat">
                  <Select
                    label=""
                    name="repeatDays"
                    value={currentWindow.repeatDays}
                    className="repeat-days"
                    onChange={this.handleChange}
                    items={[
                      { id: 1, description: "No se repite" },
                      { id: 2, description: "Toda la semana" },
                      {
                        id: 3,
                        description: `Personalizar (${(currentWindow.days || [])
                          .sort((a, b) => a - b)
                          .map((d) =>
                            d !== "4"
                              ? DAYS[d - 1].description.substr(0, 1)
                              : "X"
                          )})`,
                      },
                    ]}
                  />
                  <div
                    className={showDays ? "tooltip-days-container" : "hidden"}
                  >
                    {DAYS.map((d) => (
                      <Checkbox
                        key={d.id}
                        name="addDay"
                        item={{
                          id: d.id,
                          description: d.description,
                          active: (currentWindow.days || []).find(
                            (wd) => wd.toString() === d.id.toString()
                          )
                            ? true
                            : false,
                        }}
                        onChange={this.handleChange}
                      />
                    ))}
                    <div className="close-actions">
                      <Button
                        type="primary small"
                        text="cerrar"
                        className="close-action"
                        onClick={() => this.setState({ showDays: false })}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="replicate-block">
                <div className="title">Aplicar a otros espacios:</div>
                <div className="spaces">
                  <Checkbox
                    item={{
                      id: 0,
                      description: "Aplicar a todos",
                    }}
                    name="allSpaces"
                    active={currentWindow.allSpaces}
                    onChange={this.handleChange}
                  />
                  <div className="select-spaces">
                    <SVGIcon
                      name={icons.dotBoard}
                      fill="#79A3FF"
                      onClick={() => this.setState({ showSpaces: !showSpaces })}
                    />
                    Seleccionar espacios
                    <div
                      className={
                        showSpaces ? "tooltip-spaces-container" : "hidden"
                      }
                    >
                      {this.props.spaces.map((sp, ind) => (
                        <Checkbox
                          key={sp.id}
                          name="addSpace"
                          item={{
                            id: sp.id,
                            description: `espacio ${ind + 1}`,
                            active: (
                              [].concat((currentWindow || {}).spaces) || []
                            ).find(
                              (ws) => (ws || "").toString() === sp.id.toString()
                            )
                              ? true
                              : false,
                          }}
                          onChange={this.handleChange}
                        />
                      ))}
                      <Button
                        type="primary small"
                        text="cerrar"
                        className="close-action"
                        onClick={() => this.setState({ showSpaces: false })}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="capacity">
            <div className="capacity-title">Tipo de atención(min.):</div>
            <div className="capacity-header">
              <div className="header-content">
                <div className="titles">
                  <div className="classes">
                    <SVGIcon
                      name={icons.truckSingle}
                      fill="#1F8EFA"
                      viewBox="0 -6 45 36"
                      width="25px"
                      height="20px"
                    />
                    Clases sencillo
                  </div>
                  <div
                    className="load"
                    style={
                      this.state.currentSpace.load ? {} : { display: "none" }
                    }
                  >
                    Carga
                  </div>
                  <div
                    className="unload"
                    style={
                      this.state.currentSpace.unload ? {} : { display: "none" }
                    }
                  >
                    Descarga
                  </div>
                  <div
                    className="load-unload"
                    style={
                      this.state.currentSpace.loadunload
                        ? {}
                        : { display: "none" }
                    }
                  >
                    Carga y Descarga
                  </div>
                </div>
              </div>
            </div>
            <div className="capacity-rows">
              {capacities
                .filter((c) => !c.dobleequipment && !c.invisible)
                .map((cp) => this.getCapacityRowContent(cp))}
            </div>
            <div className="dialog-actions">
              <Button
                type="primary small outline dark circle"
                name="addCapacity"
                onClick={this.addCapacity}
                text="+"
              />
            </div>
            <div className="division"></div>
            <div className="capacity-header">
              <div className="header-content">
                <div className="titles">
                  <div className="classes">
                    <SVGIcon
                      name={icons.TruckDouble}
                      fill="#1F8EFA"
                      viewBox="0 0 80 30"
                      width="40px"
                      height="22px"
                    />
                    Clases full
                  </div>
                  <div
                    className="load"
                    style={
                      this.state.currentSpace.load ? {} : { display: "none" }
                    }
                  >
                    Carga
                  </div>
                  <div
                    className="unload"
                    style={
                      this.state.currentSpace.unload ? {} : { display: "none" }
                    }
                  >
                    Descarga
                  </div>
                  <div
                    className="load-unload"
                    style={
                      this.state.currentSpace.loadunload
                        ? {}
                        : { display: "none" }
                    }
                  >
                    Carga y Descarga
                  </div>
                </div>
              </div>
            </div>
            <div className="capacity-rows">
              {capacities
                .filter((c) => c.dobleequipment && !c.invisible)
                .map((cp) => this.getCapacityRowContent(cp))}
            </div>
            <div className="dialog-actions">
              <Button
                type="primary small outline dark circle"
                name="addCapacity"
                onClick={(e) => this.addCapacity(e, true)}
                text="+"
              />
            </div>
          </div>
          {this.state.currentConflicts.filter(
            (cc) => cc.id !== currentWindow.id
          ).length ? (
            <div style={{ color: "#fb8a8a", marginTop: "15px" }}>
              {`Existe(n) ${
                this.state.currentConflicts.filter(
                  (cc) => cc.id !== currentWindow.id
                ).length
              } conflicto(s). Las ventanas en conflicto se eliminaran`}
            </div>
          ) : (
            <React.Fragment />
          )}
        </div>
      </React.Fragment>
    );
  };

  addCapacity = (e, dobleequipment = false) => {
    let newCapacity = {
      expeditionClasses: [],
      single_load: 0,
      single_unload: 0,
      doble_load: 0,
      doble_unload: 0,
      single_loadunload: 0,
      doble_loadunload: 0,
      dobleequipment,
      id: this.counter++,
    };
    this.setState({ capacities: this.state.capacities.concat([newCapacity]) });
  };

  removeCapacity = (id = null) => {
    if (id) {
      this.props.deleteSpaceWindowCapacity(
        this.props.facilityId,
        this.state.currentSpace.id,
        this.state.currentWindow.id,
        id
      );
    }
  };

  handleEditClasses = (item) => {
    let capacity = item.dobleequipment ? 2 : 1;
    if (!this.state.editClasses)
      this.setState({
        editClasses: capacity,
        currentCapacityId: item.id,
      });
    else if (this.state.editClasses !== capacity) {
      this.setState({
        editClasses: capacity,
        currentCapacityId: item.id,
      });
    } else {
      this.setState({
        editClasses: !this.state.editClasses,
        currentCapacityId: null,
      });
    }
  };

  getCapacityRowContent = (item) => {
    return (
      <div className="row" key={item.id}>
        <div className="equipment-inputs classes">
          <div
            style={{ width: "100%", height: "100%", position: 'relative' }}
            onClick={() => {
              this.handleEditClasses(item);
            }}
            onMouseEnter={() => this.setState({ hoveredIndex: item.id })}
            onMouseLeave={() => this.setState({ hoveredIndex: -1})}
          >
            <ExceedPlus name={item.id} maxVisibleItems={3}>
              {this.state.expeditionClasses
                .filter((f) => f.active && f.currentCapacityId === item.id)
                .map((exc) => (
                  <span className="span-label" key={exc.id}>
                    {exc.description}{" "}
                  </span>
                ))}
            </ExceedPlus>
            <div
              style={{
                position: 'absolute',
                right: 0,
                top: '15%',
                pointerEvents: 'none'
              }}
            >
              <Caret
                up={
                  this.state.editClasses === (item.dobleequipment ? 2 : 1) &&
                  item.id === this.state.currentCapacityId
                }
                isHovered={this.state.hoveredIndex === item.id}
              />
            </div>
          </div>
          <OrbiSelectorStyled
            manageByState={false}
            onChange={this.handleChange}
            className={"classes-selector"}
            isOpen={
              this.state.editClasses === (item.dobleequipment ? 2 : 1) &&
              item.id === this.state.currentCapacityId
            }
            title=""
            items={[
              {
                id: -1,
                description: "Todos",
                equipment_quantity: item.dobleequipment ? 2 : 1,
              },
            ]
              .concat(
                (this.state.expeditionClasses || [])
                  .filter((c) => c.tags.length === 0)
                  .filter(
                    (ec) =>
                      (ec.equipment_quantity ===
                        (item.dobleequipment ? 2 : 1) &&
                        !ec.active) ||
                      ec.currentCapacityId === this.state.currentCapacityId
                  )
                  .concat(
                    (
                      (
                        this.state.capacities.find(
                          (cp) => cp.id === this.state.currentCapacityId
                        ) || {}
                      ).expeditionClasses || []
                    ).filter((g) => g.active) || []
                  )
              )
              .concat(
                this.props.classTags
                  .map((ct) => ({
                    ...ct,
                    items: (this.state.expeditionClasses || [])
                      .filter((c) => c.tags.length > 0)
                      .filter((c) => c.tags.some((t) => t === ct.id))
                      .filter(
                        (ec) =>
                          (ec.equipment_quantity ===
                            (item.dobleequipment ? 2 : 1) &&
                            !ec.active) ||
                          ec.currentCapacityId === this.state.currentCapacityId
                      )
                      .concat(
                        (
                          (
                            this.state.capacities.find(
                              (cp) => cp.id === this.state.currentCapacityId
                            ) || {}
                          ).expeditionClasses || []
                        ).filter((g) => g.active) || []
                      ),
                  }))
                  .filter((ct) => ct.items.length > 0)
              )
              .sort((a, b) => a.id - b.id)}
            // items={[
            //   {
            //     id: -1,
            //     description: "Todos",
            //     equipment_quantity: item.dobleequipment ? 2 : 1,
            //   },
            // ]
            //   .concat(
            //     (this.state.expeditionClasses || []).filter(
            //       (ec) =>
            //         (ec.equipment_quantity === (item.dobleequipment ? 2 : 1) &&
            //           !ec.active) ||
            //         ec.currentCapacityId === this.state.currentCapacityId
            //     )
            //   )
            //   .sort((a, b) => a.id - b.id)}
            selected={[]}
            listType={ORBI_SELECTOR_LISTS.CHECKLISTRECURSIVE}
            name="expClass"
          />
        </div>
        <div
          className="inputs load"
          style={this.state.currentSpace.load ? {} : { display: "none" }}
        >
          <input
            name={item.dobleequipment ? "doble_load" : "single_load"}
            onChange={(e) => this.handleManeuverValues(e, item)}
            value={item.dobleequipment ? item.doble_load : item.single_load}
          />
        </div>
        <div
          className="inputs unload"
          style={this.state.currentSpace.unload ? {} : { display: "none" }}
        >
          <input
            name={item.dobleequipment ? "doble_unload" : "single_unload"}
            onChange={(e) => this.handleManeuverValues(e, item)}
            value={item.dobleequipment ? item.doble_unload : item.single_unload}
          />
        </div>
        <div
          className="inputs load-unload"
          style={this.state.currentSpace.loadunload ? {} : { display: "none" }}
        >
          <input
            name={
              item.dobleequipment ? "doble_loadunload" : "single_loadunload"
            }
            onChange={(e) => this.handleManeuverValues(e, item)}
            value={
              item.dobleequipment
                ? item.doble_loadunload
                : item.single_loadunload
            }
          />
        </div>
        <div className="action-inputs">
          <SVGIcon
            name={icons.trash}
            fill="#1F8EFA"
            onClick={() =>
              this.handleChange({
                target: { name: "removeCapacity", value: item.id },
              })
            }
          />
        </div>
      </div>
    );
  };

  handleManeuverValues = (e, i) => {
    if (RegExp(/^[0-9]*$/).test(e.target.value) && e.target.value.length < 4) {
      this.handleChange({
        target: { value: e.target.value, name: e.target.name, id: i.id },
      });
    }
  };

  formatNumberToTimePart = (number, hour = false) => {
    let hours = parseInt(number / 100);
    hours = this.limitNumber(hours, 23);
    let minutes = parseInt(number) % 100;
    minutes = this.limitNumber(minutes, 59);
    hours = "00".concat(hours.toString()).substr(-2, 2);
    minutes = "00".concat(minutes.toString()).substr(-2, 2);
    return hour ? hours : minutes;
  };

  limitNumber = (number, upperLimit, lowerLimit = 0) => {
    number = number < lowerLimit ? lowerLimit : number;
    number = number > upperLimit ? upperLimit : number;
    return number;
  };

  mapTagsToChips = (tags = []) => {
    return this.props.tags.map((tag) => (
      <Tag
        key={tag.id}
        id={tag.id}
        color={tag.color || "3D77F7"}
        title={tag.title || "-"}
        name="tags"
        isFull={tags.find((t) => t === tag.id) ? true : false}
        parentBackground="light"
        onClick={this.handleChange}
      />
    ));
  };
}

const mapStateToProps = (state) => {
  const isLoadingRoutesDetail = state?.api['COMPANIES.FACILITIES.ROUTESDETAIL']?.status?.isFetching || false;
  const isLoadingRoutesDetailInfo = state?.api['COMPANIES.FACILITIES.ROUTESDETAIL.INFO']?.status?.isFetching || false;
  const isLoadingWindowsCreation = state?.api['COMPANIES.FACILITIES.SPACES.WINDOWS']?.status?.isFetching || false;

  const classTags = (state.tags.tags || [])
    .filter((tag) => tag.type === 8)
    .map((t) => ({ id: t.id, description: t.title }));
  let spaces = (state.facilityReducer.spaces.data || [])
    .sort((a, b) => a.id - b.id)
    .map((sp) => {
      sp.tags = sp.tags || [];
      sp.windows = sp.windows || [];
      return sp;
    });

  for (let j in (spaces || [])) {
    let tempSpace = cloneDeep(spaces[j]);
    let tempSpace2 = cloneDeep(spaces[j]);
    tempSpace.windows = [];

    for (let i in tempSpace2.windows) {
      let tempWindow = tempSpace2.windows[i];

      if (tempWindow.next_day) {
        let cloneWindow = cloneDeep(tempWindow);

        tempWindow.hour_to_real = tempWindow.hour_to;
        tempWindow.minute_to_real = tempWindow.minute_to;
        tempWindow.hour_from_real = tempWindow.hour_from;
        tempWindow.minute_from_real = tempWindow.minute_from;
        tempWindow.weekdays_real = tempWindow.weekdays;
        tempWindow.hour_to = 23;
        tempWindow.minute_to = 59;
        tempWindow.interday = 1;

        cloneWindow.hour_from_real = cloneWindow.hour_from;
        cloneWindow.minute_from_real = cloneWindow.minute_from;
        cloneWindow.hour_to_real = cloneWindow.hour_to;
        cloneWindow.minute_to_real = cloneWindow.minute_to;
        cloneWindow.hour_from = 0;
        cloneWindow.minute_from = 0;
        cloneWindow.weekdays_real = cloneWindow.weekdays;
        cloneWindow.weekdays = (cloneWindow.weekdays + 1) > 7 ? 1 : cloneWindow.weekdays + 1;
        cloneWindow.interday = 2;

        tempSpace.windows.push(tempWindow);
        tempSpace.windows.push(cloneWindow);
        
      } else {
        tempSpace.windows.push(tempWindow);
      }

    }

    spaces[j] = tempSpace;
  }

  let expClasses = (
    state.companyReducer.companyExpeditionClasses.data || []
  ).map((ec) => ({
    description: ec.code,
    equipment_quantity: ec.equipment_quantity,
    id: ec.id,
    tags: ec.tags.map((t) => t.id),
  }));
  return {
    isLoadingWindowsCreation,
    isLoadingRoutesDetail,
    isLoadingRoutesDetailInfo,
    companyId: state.globalCatalog.session.company.id,
    classTags,
    expClasses,
    capacities: state.facilityReducer.spaceWindowCapacities.data || [],
    tags: (state.tags.tags || []).filter((tag) => tag.type === 7),
    spaces,
    gridVisible: spaces.length ? true : false,
    capacityTransports: (
      (state.facilityReducer.spaceWindowCapacityTransport || { data: [] })
        .data || []
    ).map((swct) => {
      let cls = expClasses.find((exp) => exp.id === swct.transport_class);
      (cls || {}).relationId = swct.id;
      return cls;
    }),
  };
};

const mapDispatchToProps = (dispatch) => {
  dispatch(loadTags());
  dispatch(listExpeditionClasses());
  return {
    cancelMultipleRequests: () => dispatch(cancelMultipleRequests()),
    createWindow: (params, opt) =>{
      dispatch(
        createEntity("COMPANIES.FACILITIES.SPACES.WINDOWS", params, opt) 
      );
    },
    updateRoutesDetail: (params, opt) =>{
      dispatch(
        updateEntity("COMPANIES.FACILITIES.ROUTESDETAIL", params, opt) 
      );
    },
    getRoutesDetail: (params, opt) =>{
      dispatch(
        readEntity("COMPANIES.FACILITIES.ROUTESDETAIL.INFO", params, opt) 
      );
    },
    deleteWindow: (params, opt) =>{
      dispatch(
        deleteEntity("COMPANIES.FACILITIES.SPACES.WINDOWS", params, opt) 
      );
    },
    deleteSpace: (params, opt) =>{
      dispatch(
        deleteEntity("COMPANIES.FACILITIES.SPACES.ERASE", params, opt) 
      );
    },
    loadSpaces: (facilityId) => dispatch(listSpaces(facilityId)),
    createSpace: (facilityId, name = "", load, unload, loadUnload) =>
      dispatch(createSpace(facilityId, name, load, unload, loadUnload)),
    // deleteSpace: (facilityId, spaceId) =>
    //   dispatch(deleteSpace(facilityId, spaceId)),
    updateSpace: (facilityId, spaceId, load, unload, loadUnload) =>
      dispatch(updateSpace(facilityId, spaceId, load, unload, loadUnload)),

    loadSpaceTags: (facilityId, spaceId) =>
      dispatch(loadSpaceTags(facilityId, spaceId)),
    createSpaceTag: (facilityId, spaceId, tagId) =>
      dispatch(createSpaceTag(facilityId, spaceId, tagId)),
    deleteSpaceTag: (facilityId, spaceId, tagId) =>
      dispatch(deleteSpaceTag(facilityId, spaceId, tagId)),

    listSpaceWindows: (facilityId, spaceId) =>
      dispatch(listSpaceWindows(facilityId, spaceId)),
    createSpaceWindow: (
      facilityId,
      spaceId,
      hourFrom,
      minuteForm,
      hourTo,
      minuteTo,
      day
    ) =>
      dispatch(
        createSpaceWindow(
          facilityId,
          spaceId,
          hourFrom,
          minuteForm,
          hourTo,
          minuteTo,
          day
        )
      ),
    deleteSpaceWindows: (facilityId, spaceId, windowId) =>
      dispatch(deleteSpaceWindow(facilityId, spaceId, windowId)),
    updateSpaceWindow: (
      facilityId,
      spaceId,
      WindowId,
      hourFrom,
      minuteFrom,
      hourTo,
      minuteTo,
      day
    ) =>
      dispatch(
        updateSpaceWindow(
          facilityId,
          spaceId,
          WindowId,
          hourFrom,
          minuteFrom,
          hourTo,
          minuteTo,
          day
        )
      ),

    listSpaceWindowCapacities: (facilityId, spaceId, WindowId) =>
      dispatch(listSpaceWindowCapacities(facilityId, spaceId, WindowId)),
    createSpaceWindowCapacity: (
      facilityId,
      spaceId,
      WindowId,
      sLoad,
      sUnload,
      dLoad,
      dUnload,
      sLoadUnload,
      dLoadUnload,
      dEquipment
    ) =>
      dispatch(
        createSpaceWindowCapacity(
          facilityId,
          spaceId,
          WindowId,
          sLoad,
          sUnload,
          dLoad,
          dUnload,
          sLoadUnload,
          dLoadUnload,
          dEquipment
        )
      ),
    deleteSpaceWindowCapacity: (facilityId, spaceId, WindowId, id) =>
      dispatch(deleteSpaceWindowCapacity(facilityId, spaceId, WindowId, id)),
    updateSpaceWindowCapacity: (
      facilityId,
      spaceId,
      windowId,
      capacityId,
      sLoad,
      sUnload,
      dLoad,
      dUnload,
      sLoadUnload,
      dLoadUnload,
      double
    ) =>
      dispatch(
        updateSpaceWindowCapacity(
          facilityId,
          spaceId,
          windowId,
          capacityId,
          sLoad,
          sUnload,
          dLoad,
          dUnload,
          sLoadUnload,
          dLoadUnload,
          double
        )
      ),

    createSpaceWindowCapacityTransport: (
      facilityId,
      spaceId,
      windowId,
      capacityId,
      transportClass
    ) =>
      dispatch(
        createSpaceWindowCapacityTransport(
          facilityId,
          spaceId,
          windowId,
          capacityId,
          transportClass
        )
      ),
    listSpaceWindowCapacityTransport: (
      facilityId,
      spaceId,
      windowId,
      capacityId
    ) =>
      dispatch(
        listSpaceWindowCapacityTransport(
          facilityId,
          spaceId,
          windowId,
          capacityId
        )
      ),
    getDefaultTime: (params, opt = {}) =>
      dispatch(
        readEntity(
          "COMPANIES.FACILITIES.DEFAULTTIME",
          params,
          opt
        )
      ),
    updateDefaultTime: (params, opt = {}) =>
      dispatch(
        partialEntity(
          "COMPANIES.FACILITIES.DEFAULTTIME",
          params,
          opt
        )
      ),
    deleteSpaceWindowCapacityTransport: (
      facilityId,
      spaceId,
      windowId,
      capacityId,
      id
    ) =>
      dispatch(
        deleteSpaceWindowCapacityTransport(
          facilityId,
          spaceId,
          windowId,
          capacityId,
          id
        )
      ),
  };
};
const connectToStore = connect(mapStateToProps, mapDispatchToProps);

export default connectToStore(FacilitySchedules);

// TODO: quitar el cuadro esquinero de los scrolls
// FIX: Al replicar, cuando existen capacities vacias eliminadas, se replican
// TODO: Si por modificacion del espacio, las ventanas se invalidan, marcarlas como tal

const OrbiSelectorStyled = styled(OrbiSelector)`
  & .orbi-selector-content {
    width: 200px;
    & .items {
      & .sub-item {
        margin-left: 10px;
      }
    }
  }
`;

export const ManueverTimeComponent = styled.div`
  width: 60px;
  min-width: 60px;
  margin-left: 10px;
`;

export const EditManueverTimeComponent = styled.div`
  padding: 5px;
  cursor: pointer;
`;

const CenterItems = styled.div`
  display: flex;
  justify-content: center;
`;

const ItemsWithMargin = styled.div`
  margin-bottom: 12px;
`;