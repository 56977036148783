import React from "react";
import Modal from "react-modal";
import Button from "../controls/button";
import Loader from "../icons/tat/loader";
import { cloneDeep } from "lodash";

Modal.setAppElement("#root");

class ModifyProductDeliveryModal extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      product: cloneDeep(props.data),
      productQty: props.data.qty_per_sku,
      delivery: (props.deliveries || []).length > 0 ? props.deliveries[0] : null
    }
  }

  buildTitle = () => {
    return(
      <>
        <div className="title-action">{'Editar Cantidad'}</div>
        <div className="subtitle">{`Entrega ${this.state.delivery?.requisition_code || ''} ${this.state.delivery?.suffix || ''}`}</div> 
      </>
    );
  }

  buildHeaders = () => {
    return(
      <div className={`product-container`} style={{marginTop: 25}}>
        <div className="product-row-2">
          <div className="product-item" style={{fontSize: 15, fontWeight: 600}}>{'Cantidad'}</div>
          <div className="product-item" style={{fontSize: 15, fontWeight: 600}}>{'Peso Neto'}</div>
          <div className="product-item" style={{fontSize: 15, fontWeight: 600}}>{'Peso Bruto'}</div>
          <div className="product-item" style={{fontSize: 15, fontWeight: 600}}>{'Volumen'}</div>
        </div>
      </div>
    );
  }

  buildTextInput = () => {

    let gross_weight_clave = `${this.state.productSelected?.data?.sat_net_weight_unit?.clave || ''}`.toLowerCase()
    let gross_weight_description = `${this.state.product.gross_weight ? (parseFloat(this.state.product.gross_weight) * (this.state.productQty || 0) * (gross_weight_clave === 'tne' ? 1000 : 1)).toFixed(2) : ''} kg`;

    let net_weight_clave = `${this.state.productSelected?.data?.sat_net_weight_unit?.clave || ''}`.toLowerCase()
    let net_weight_description =`${this.state.product.net_weight ? (parseFloat(this.state.product.net_weight) * (this.state.productQty || 0) * (net_weight_clave === 'tne' ? 1000 : 1)).toFixed(2) : ''} kg`;
    let volume_description =`${this.state.product.volume ? (parseFloat(this.state.product.volume) * (this.state.productQty || 0)).toFixed(2) : ''} cbm`.toLowerCase();

    return(
      <div className={`product-container`}>
        <div className="product-row-2">
          <div className="product-item">{this.state.product.product_name}</div>
          <div className="product-item">{this.state.product.sku}</div>
        </div>
        <div className="product-row-2">
          <div className="product-item">
            <input
              style={{textAlign: 'center'}}
              className="text-input-custom"
              name="productQty"
              type="number"
              value={this.state.productQty}
              autocomplete={'off'}
              onChange={this.handleChange}
              onBlur={(e) => {
                let value = `${e.target.value}` !== '' ? parseInt(e.target.value) : 0;
            
                if (value < 1) {
                  this.setState({productQty: 1});
                }
              }}
            />
          </div>
          <div className="product-item">{net_weight_description}</div>
          <div className="product-item">{gross_weight_description}</div>
          <div className="product-item">{volume_description}</div>
        </div>
        
      </div>
    );
  }

  handleChange = (e) => {
    this.setState({[e.target.name]: e.target.value})
  }

  onClose = () => {
    if (this.props.isUpdatingLoading) return;
    if (this.props.closeAction) this.props.closeAction();
  };

  buildActions = () => {

    return(
      <div className="actions">
        <div
          style={{
            display: 'flex',
            justifyContent: 'end',
            alignItems: 'center'
          }}
        >
          <Button
            disabled={this.props.isUpdatingLoading}
            text={'Cancelar'}
            type={'btn secondary primary'}
            onClick={() => {
              this.onClose();
            }}
          />

          <div style={{width: 20}}/>

          <Button
            disabled={this.props.isUpdatingLoading}
            text={this.props.isUpdatingLoading ? <Loader circleColor={'black'}/> : 'Guardar'}
            type={'btn outline primary dark'}
            onClick={() => {

              this.props.onClickAccept({
                qty: parseInt(this.state.productQty),
                id: this.props.data.product_delivery_id,
                delivery_id: this.props.data.delivery_id,
                qty_old: this.props.data.qty_per_sku,
                product_name: this.props.data.product_name,
                delivery: `${this.state.delivery?.requisition_code || ''} ${this.state.delivery?.suffix || ''}`
              });

            }}
          />
        </div>
      </div>
    );
  }

  

  render() {
    return (
      <Modal
        isOpen={this.props.deliveryData !== null}
        portalClassName={`dialog divide-requisition-delivery-dialog ${
          this.props.addClass ? this.props.addClass : ""
        }`}
        overlayClassName="overlay"
        className="content"
      >
        <div className="close" onClick={() => {
          this.onClose();
        }}></div>

        { this.buildTitle() }

        { this.buildHeaders() }
        { this.buildTextInput() }

        { this.buildActions() }

      </Modal>
    );
  }
}

export default ModifyProductDeliveryModal;