//@flow
import React, { createRef, useRef } from "react";
import { connect } from "react-redux";

import { View, ViewTitle } from "../../../shared/styled/view";
import FilterBarCompact from "../../../shared/component/filter/filter-bar-compact";

import OptionSelectorSwitch from "../../../shared/controls/option-selector-switch";

import { SummaryPanel } from "../styled/summary-panel";

import {
  readEntity,
  readEntities,
  readCatalog,
  ApiOptions,
  getEntityItems,
  getEntityCount,
  createEntity
} from "../../../redux/api/";
import * as Types from "../types";
import * as SharedTypes from "../../../shared/types";
import type { Match } from "react-router-dom";
import { Flex, flexOpts } from "../../../shared/styled/flex";

import {
  setStatus,
  setAppointmentDate,
} from "../../../redux/reducers/shipment-reducer";
import TabSelectorRibbon from "../../../shared/controls/tab-selector-ribbon";
import UnassignedShipmentBoard from "../unassigned/unassigned-shipment-board";
import AssignedShipmentBoard from "./assigned-shipment-board";
import {
  setNavbarAction,
  removeNavbarAction,
} from "../../../redux/reducers/navbar-reducer";
import { Trash } from "../../../shared/icons/traffic";
import { listNetworkApprovedInvitations } from "../../../redux/reducers/company-reducer";
import { getTimeZone, groupFacilities } from "../../../shared/utils";
import Axios from "axios";
import { SERVER, FILES_SERVER } from "../../../redux/api/api-urls";
import { setConfig } from "../../../redux/reducers/configuration-reducer";
import RadioInput from "../../../shared/controls/radio-input";
import Loader from "../../../shared/icons/tat/loader";
import UnassignedOrdersBoard from "../unassigned/unassigned-orders-board";
import SearchTypeDropdown from "../../../shared/controls/search-type-dropdown";
// import PaginatorControlled from "../../../shared/controls/paginator-controlled";
import ReactTooltip from "react-tooltip";
import TripControlColumnsSettingsModal from '../../../shared/dialog/trip-controls-availability-columns-settings-modal';
import { getDefaultOrder, transformIdArrays, reorderArray, initialOrder } from './trips-control-configuration';
import { DownloadIcon } from "../../../company/routes/style";
import DownloadImage from "../../../shared/icons/routes/downloadIcon";
import Button from "../../../shared/controls/button";
import FileSelector, { acceptTypes, getFileDescription } from "../../../shared/controls/file-selector";
import SVGIcon, { icons } from "../../../shared/svg-icon";
import Chip from "../../../shared/controls/chip";
import Modal from "react-modal";
import ValidatePerms from '../../../shared/component/validate-perms';
import HelperFilesModal from "../../../shared/component/helper_files_modal";
import ErrorDialog from "../../../shared/dialog/error-dialog";

type StateToProps = {|
  tripStatuses: any,
  orderStatuses: any,
  loading: boolean,
  companyId: number,
  facility: Types.DetailFacility,
  companyTags: any[],
|};

type DispatchToProps = {|
  loadFacility: Function,
  setStatus: (tripId: number, newStatus: number) => void,
  setAppointmentDate: (tripId: number, newAppointmentDate: Date) => void,
  loadCompanyTags: (company_id: number, opts: ApiOptions) => void,
  loadCompanyProviders: (
    params: { company_id: number, view: string },
    opts: ApiOptions
  ) => void,
  loadCompanyTransportClasses: (
    params: { company_id: number },
    opts: ApiOptions
  ) => void,
  loadCompanyFacilities: (
    params: { company_id: number },
    opts: ApiOptions
  ) => void,
|};

type OwnProps = {|
  match: Match,
|};

type Props = {
  ...OwnProps,
  ...StateToProps,
  ...DispatchToProps,
};

type State = {
  maneuver: Types.Maneuvers,
  currentViewOption: 1 | 2 | 3,
  searchStr: string,
  filter: any,
  assignProcess: boolean,
};

const ViewOptions = [
  { id: 3, description: "Órdenes" },
  { id: 1, description: "Viajes" },
  { id: 2, description: "Citas" },
];

let timer;

class ShipmentDetailView extends React.Component<Props, State> {
  state = {
    maneuver: "all",
    currentViewOption: 1,
    facilityTime: "",
    searchStr: "",
    emails: [],
    buttonFilterName: "Aplicar",
    filter: {},
    filterTemp: {
      appointment: [],
    },
    dateFilter: {},
    dateFilterTemp: {
      appointment: [],
    },
    assignProcess: false,
    urlFacilities: null,
    limit: 10,
    offset: 1,
    resetPaginator: false,
    downloadingFile: false,
    cancelTokenSource: null,
    isLoadingTrips: false,
    searchType: "1",
    isShowSettingsModal: false,
    columnsData: [],
    columnsHidden: [],
    columnsData: getDefaultOrder(false, []),
    categories: [],
    tripControlCategories: [],
    isLoadingCategories: true,
    refreshSelected: null,

    openXlsModal: false,
    xls: null, 
    isLoadingXls: false,
    openXlsSuccessModal: false,
    totalImport: 0,
    correctImport: 0,
    failedImport: 0,
    openXlsErrorModal: false,
    descriptionImport: [],
    uploadId: null,

    refreshData: false,
    msgError: ''
  };
  
  componentDidMount() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    let urlFacilities = urlParams.get('facilities');
    let urlSearch = urlParams.get('search')
    let dateSearch = urlParams.get('date')
    let transportClasses = urlParams.get('transport_classes');
    let providers = urlParams.get('fleet_providers');
    let maneuver = urlParams.get('maneuver');
    let category =  this.props.configuration?.filter ? null : urlParams.get('category');
    let showOrders = urlParams.get('orders');
    this.props.getContainerStatus();

    let statusToShow = ['1', '2', '3', '5', '6', '7'];

    if(showOrders){
      this.setState({
        currentViewOption: 3,
      })
    }

    if(urlFacilities) urlFacilities = urlFacilities.split(",")
    this.setState({ 
      ...this.props.configuration, 
      urlFacilities, 
      maneuver: maneuver || 'all',
      category,
      filterTemp: {
        selectedstatusorder: statusToShow,
        selectedstatus: category ? [] : ["5", "8", "1", "3,4,10"],
        urlFacilities: this.props.configuration?.filterTemp?.urlFacilities || urlFacilities || [this.props.match.params.facility],
        selectedFacilities: this.props.configuration?.filterTemp?.selectedFacilities || urlFacilities || [this.props.match.params.facility],
        transportClass: transportClasses ? transportClasses.split(",") : [],
        selectedProviders: providers ? providers.split(",") : [],
        from_facilities: [],
        to_facilities: [],
        ...(this.props.configuration?.filterTemp || {}),
      },
      filter: {
        selectedstatusorder: statusToShow,
        selectedstatus: category ? [] : ["5", "8", "1", "3,4,10"],
        urlFacilities: this.props.configuration?.filter?.urlFacilities || urlFacilities || [this.props.match.params.facility],
        selectedFacilities: this.props.configuration?.filter?.selectedFacilities || urlFacilities || [this.props.match.params.facility],
        transportClass: transportClasses ? transportClasses.split(",") : [],
        selectedProviders: providers ? providers.split(",") : [],
        from_facilities: [],
        to_facilities: [],
        ...(this.props.configuration?.filter || {}),
      },
      dateFilterTemp: {
        selectedstatus: ["5", "8", "1", "3,4,10"],
        ...(this.props.configuration?.dateFilterTemp || {}),
        urlFacilities: this.props.configuration?.dateFilterTemp?.urlFacilities || urlFacilities || [this.props.match.params.facility]
      },
      dateFilter: {
        selectedstatus: ["5", "8", "1", "3,4,10"],
        ...(this.props.configuration?.dateFilter || {}),
        urlFacilities: this.props.configuration?.dateFilterTemp?.urlFacilities || urlFacilities || [this.props.match.params.facility]
      },
    }, () => {
      // this.setState({
      //   urlFacilities, 
      //   filterTemp: {
      //     ...this.state.filterTemp,
      //     urlFacilities: urlFacilities || [this.props.match.params.facility]
      //   },
      //   filter: {
      //     ...this.state.filter,
      //     urlFacilities: urlFacilities || [this.props.match.params.facility]
      //   }
      // });
    });

    if (dateSearch){
      this.setState({currentViewOption:2, url_date: dateSearch })

    }
    if (urlSearch){
      this.setState({ emails: [{email: urlSearch, avatar: "hide", avatarType: "", userId: null}], searchStr: urlSearch});
    }
    
    this.props.loadFilterCategories({ company_id: this.props.companyId, filter_type: 1, company_type: this.props.companyType }, {
      onSuccess: (response) => {
        this.setState({
          categories: response && response.length ? response : []
        });
      }
    });

    this.props.loadFilterCategories({ company_id: this.props.companyId, filter_type: 2, company_type: this.props.companyType }, {
      onSuccess: (response) => {
        this.setState({
          tripControlCategories: response && response.length ? response : [],
          //columnsData: getDefaultOrder(false, response && response.length ? response : [])
        }, () => { this.loadUserFilters() });
      }
    });

    this.props.setNavbarAction("ShipmentDetailView-bin", [
      {
        icon: <HelperFilesModal
          files={[]}
        />
      },
      {
        icon: <Trash stroke="#576791" />,
        onClick: () => {
          this.props.history.push(
            this.props.location.pathname.split("?")[0].concat("?bin")
          );
        },
      }
    ]
    );

    this.props.loadOrderStatus();

    this.props.loadCompanyTags(this.props.companyId);
    if(!urlFacilities){
      this.props.loadFacility(
        this.props.companyId,
        this.props.match.params.facility,
        {
          alias: "currentFacility",
          onSuccess: () => {
            this.getFacilityTime();
          },
        }
      );
    }

    this.props.loadCompanyProviders({
      company_id: this.props.companyId,
      view: "booking",
    });

    this.props.loadCompanyTransportClasses({
      company_id: this.props.companyId,
      view: "grouped",
    });

    this.props.loadCompanyFacilities({ company_id: this.props.companyId });
    this.props.loadTagsTripCatalog({ company_id: this.props.companyId });
    this.props.loadTagsOrderCatalog({ company_id: this.props.companyId });
    // if(!urlFacilities)
      // setInterval(this.getFacilityTime, 1000);

  }

  componentWillUnmount() {
    clearTimeout(timer);
    this.props.removeNavbarAction("ShipmentDetailView-bin");
    this.props.setBaseConfig({ ...this.state, resetPaginator: false });

    // Cancel request
    if(this.state.cancelTokenSource !== null){
      this.state.cancelTokenSource.cancel();
    }
  }

  loadUserFilters = () => {

    this.props.loadUserFilter({ company_id: this.props.companyId, filter_type: 2 }, {
      onSuccess: (response) => {

        this.state.isLoadingCategories = false;

        if (response.filter && response.filter.autorefresh_option) {
          this.state.refreshSelected = response.filter.autorefresh_option;
        }

        if (response.filter && response.filter.columnsHidden && response.filter.columnsHidden.length) {
          this.state.columnsHidden = transformIdArrays(response.filter.columnsHidden);
        }

        if (response.filter && response.filter.columns && response.filter.columns.length && this.state.tripControlCategories.length === response.filter.columns.length) {
          let array = reorderArray(this.state.tripControlCategories, response.filter.columns);
          
          this.state.columnsData = getDefaultOrder(false, array);
        } else {
          this.state.columnsData = initialOrder(getDefaultOrder(true, this.state.tripControlCategories));
          this.state.columnsHidden = [18, 19, 20, 21, 22, 23, 24];
        }

        this.setState({});
      }
    });

  }

  getRealHiddenColumns = () => {
    let realIds = [];

    this.state.columnsHidden.map( item => {

      this.state.columnsData.map( item2 => {
        if (item === item2.id) {
          realIds.push(item2.realId);
        }
      });

    });

    return realIds;
  }

  saveUserFilter = () => {

    let filterToSave = {
      company_id: this.props.companyId,
      type: 2,
      filter: {
        columns: this.state.columnsData.map( item => item.realId),
        columnsHidden: this.getRealHiddenColumns(),
        autorefresh_option: this.state.refreshSelected
      },
      company_user: this.props.userData
    };

    this.props.saveFilter(filterToSave);
  }

  followMeCard = (e) => {
    const cursorItem = document.getElementById("assignCard");
    const x = e.clientX;
    const y = e.clientY;
    cursorItem.style.visibility = `visible`;
    cursorItem.style.left = `${x}px`;
    cursorItem.style.top = `${y}px`;
  };

  handleESC = (e) => {
    if (this.state.assignProcess && e.keyCode === 27) {
      this.stopAssign();
    }
  };

  startAssign = () => {
    if (this.state.currentViewOption !== 2)
      this.setState({ currentViewOption: 2, assignProcess: true });
    document.addEventListener("mousemove", this.followMeCard);

    document.addEventListener("keydown", this.handleESC);
  };

  stopAssign = () => {
    document.removeEventListener("mousemove", this.followMeCard);
    document.removeEventListener("keydown", this.handleESC);
    const cursorItem = document.getElementById("assignCard");
    cursorItem.style.visibility = `hidden`;
    // console.log("test 2");
    // const cursorItem = document.getElementById("assignCard");
    // console.log("test 3");
    // this.setState({ assignProcess: false });
    // console.log("test 4");
    // document.removeEventListener(
    //   "mousemove",
    //   (e) => {
    //     const x = e.clientX;
    //     const y = e.clientY;
    //     cursorItem.style.visibility = `visible`;
    //     cursorItem.style.left = `${x}px`;
    //     cursorItem.style.top = `${y}px`;
    //   },
    //   true
    // );
    // console.log("test 5");

    // document.removeEventListener("keydown", (e) => {
    //   if (this.state.assignProcess && e.keyCode === 27) {
    //     console.log("test");
    //     this.stopAssign();
    //   }
    // });
  };



  handleChange = (e: SharedTypes.SimpleEvent) => {
    const { name, value } = e.target;

    // const { currentViewOption } = this.state;
    
    switch (name) {
      case "maneuver": 
        this.setState({
          resetPaginator: true,
          [name]: value
        })
        break;
      case "searchStr":
        // if (currentViewOption === 1)
        // {
        //   let tags = e.target.value.split(/[ ,]/gi)
        //   tags = tags.map(t => <div>{t}</div>)
        //   this.setState({ [name]: tags });
        // }else{
          this.setState({resetPaginator: true, [name]: value })
        // }
        break;
      case "currentViewOption":
        this.setState({ [name]: value, searchStr:null, emails: [], limit: value === 3 ? 100 : 10, offset: 1 })
        break;
      default:
        this.setState({ [name]: value });
    }
  };

  formatTimeZone = (tZ) => {
    // debugger
    if (tZ.length) {
      if (tZ.includes("UTC") || (tZ.match(/\//g) || []).length > 1) {
        tZ = tZ.slice(tZ.indexOf("/") + 1);
        tZ = tZ.trim();
      }
      return tZ;
    }
    return "America/Mexico_City";
  };

  getFacilityTime = () => {
    if (this.props.facility && !this.state.urlFacilities) {
      let tz = this.props.facility.time_zone_description || "";
      let dateString = new Date().toLocaleString("en-US", {
        timeZone: this.formatTimeZone(tz),
        hour: "numeric",
        minute: "numeric",
      });
      this.setState({ facilityTime: dateString });
    }
    setTimeout(() => this.getFacilityTime(), 60000);
    return "";
  };

  getLevelFilter = () => {
    return [
      {
        id: "1",
        description: "1",
      },
      {
        id: "2",
        description: "2",
      },
      {
        id: "3",
        description: "3",
      },
      {
        id: "4",
        description: "4",
      },
      {
        id: "5",
        description: "5",
      },
    ];
  };

  getAppointmentFilter = () => {
    const { maneuver } = this.state;
    switch (maneuver) {
      case "unload":
        return [
          {
            id: "3",
            description: "Folios con cita de descarga",
          },
          {
            id: "4",
            description: "Folios sin cita de descarga",
          },
        ];
      case "load":
        return [
          {
            id: "1",
            description: "Folios con cita de carga",
          },
          {
            id: "2",
            description: "Folios sin cita de carga",
          },
        ];
      default:
        return [
          {
            id: "1",
            description: "Folios con cita de carga",
          },
          {
            id: "2",
            description: "Folios sin cita de carga",
          },
          {
            id: "3",
            description: "Folios con cita de descarga",
          },
          {
            id: "4",
            description: "Folios sin cita de descarga",
          },
        ];
    }
  };

  getAppointmentTypeFilter = () => {

  }

  toggleLoadingTrips = (value) => {
    this.setState({isLoadingTrips: value});
  }

  getCurrentView = () => {
    switch(this.state.currentViewOption){
      case 1:
        return <UnassignedShipmentBoard
          assignAction={this.startAssign}
          // loading={this.props.loading}
          filterStatus={this.props.location.search.length > 0 ? "9" : ""}
          companyId={this.props.companyId}
          facilityId={this.props.match.params.facility}
          urlFacilities={this.state.filter.urlFacilities}
          companyFacilities={this.props.companyFacilities}
          companyTags={this.props.companyTags}
          maneuver={this.state.maneuver}
          history={this.props.history}
          limit={this.state.limit}
          offset={this.state.offset} 
          filter={this.state.filter}
          search={this.state.searchStr}
          resetPaginator={this.state.resetPaginator}
          toggleLoadingState={this.toggleLoadingTrips}
          searchType={this.state.searchType}
          category={this.state.category}
        />;
      case 2: 
        return <AssignedShipmentBoard
          facilityTime={this.state.facilityTime}
          assignProcess={this.state.assignProcess}
          companyTags={this.props.companyTags}
          history={this.props.history}
          facilityId={this.props.match.params.facility}
          urlFacilities={this.state.dateFilter.urlFacilities}
          companyFacilities={this.props.companyFacilities}
          maneuver={this.state.maneuver}
          filter={this.state.dateFilter}
          search={this.state.searchStr}
          url_date={this.state.url_date}
          category={this.state.category}
          dashboard={'dates'}
          changeView={this.handleChange}
        />;
      case 3:
        return <UnassignedOrdersBoard 
          history={this.props.history}
          companyTags={this.props.companyTags}
          facilityId={this.props.match.params.facility}
          urlFacilities={this.state.filter.urlFacilities}
          companyId={this.props.companyId}
          limit={this.state.limit}
          offset={this.state.offset} 
          filter={this.state.filter}
          search={this.state.searchStr}
          searchType={this.state.searchType} 
          toggleLoadingState={this.toggleLoadingTrips}
          maneuver={this.state.maneuver}
          category={this.state.category}
          resetPaginator={this.state.resetPaginator}
          updatePaginator={this.handleChange}
          columnsData={this.state.columnsData}
          columnsHidden={this.state.columnsHidden}
          refreshSelected={this.state.refreshSelected}
          refreshData={this.state.refreshData}
          updateRefreshData={(value) => {this.setState({refreshData: value})}}
        />;
      default: 
        return <div></div>
    }
  }

  render() {
    const { currentViewOption } = this.state;
    return (
      <View className="shipment-detail-view">
        {
          (this.state.isLoadingTrips || this.state.isLoadingCategories) &&
          <div
            style={{
              position: "absolute",
              top: "0",
              left: "0",
              width: "100%",
              height: "100%",
              backgroundColor: "#00000080",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              zIndex: 1001,
              backdropFilter: "blur(3px)"
            }}
          >
            <div style={{display: "flex", alignItems: "center"}}>
              <Loader width="100px" height="100px"/>
              <div style={{color: "#fff", fontSize: "36px"}}>
                Cargando
              </div>
            </div>
            <div style={{color: "#fff", fontSize: "30px"}}>
              Si desea cancelar la consulta, presione ESC
            </div>
          </div>
        }
        <AssignCard enabled={this.state.assignProcess} />
        {(currentViewOption === 1 || currentViewOption === 3) && this.getViewTitle()}

        <SummaryPanel>
          <Flex
            justifyContent={flexOpts.justifyContent.center}
            alignItems={flexOpts.alignItems.center}
            style={{ backgroundColor: "#212A3C" }}
          >
            {currentViewOption === 2 && (
              <div style={{ color: "white" }}>{this.getFacilityTitle()}</div>
            )}
            { currentViewOption === 2 ?
              <div>
                <TabSelectorRibbon
                  // rf={this.props.connectDropTarget}
                  bgColor="#212A3C"
                  selectedUnderlineColor="#3D77F7"
                  openDocument={this.openDocument}
                  getUrl={this.getUrl}
                  // className="profile-calendar-ribbon"
                  onChange={(e) =>
                    this.handleChange({
                      target: {
                        name: "currentViewOption",
                        value: parseInt(
                          e.target.attributes["name"]?.value ||
                            this.state.currentViewOption
                        ),
                      },
                    })
                  }
                  items={ViewOptions}
                  // onDragOver={this.dragIsOver}
                  activeId={currentViewOption}
                />
              </div> :
                <TabSelectorRibbon 
                // rf={this.props.connectDropTarget}
                disableDownload={this.state.currentViewOption === 3 && this.props.ordersCount > 100}
                disableMessage={this.props.ordersCount > 100 ? 'No se permite descargar más de 100 ordenes.' : ''}
                addDownloadIcon={true}
                bgColor="#212A3C"
                selectedUnderlineColor="#3D77F7"
                openDocument={this.openDocument}
                downloadingFile={this.state.downloadingFile}
                getUrl={this.getUrl}
                // className="profile-calendar-ribbon"
                onChange={(e) =>
                  this.handleChange({
                    target: {
                      name: "currentViewOption",
                      value: parseInt(
                        e.target.attributes["name"]?.value ||
                          this.state.currentViewOption
                      ),
                    },
                  })
                }
                items={ViewOptions}
                // onDragOver={this.dragIsOver}
                activeId={currentViewOption}
                rightComponent={
                  this.state.currentViewOption === 3 ? <div 
                    style={{display: 'flex', alignItems: 'center', marginRight: 8, cursor: 'pointer'}}
                    onClick={() => {
                      this.setState({
                        isShowSettingsModal: true
                      });
                    }}
                  >
                    <img alt="" src={'/images/settings_icon.png'} style={{ width: 30, height: 30}} data-tip data-for='tooltip-settting'/>
                    <ReactTooltip
                      id={'tooltip-settting'}
                      getContent={() =>
                        'Configurar columnas de tabla'
                      }
                      delayHide={200}
                      place={"left"}
                      border={true}
                      type={"light"}
                    />
                  </div> : <div/>
                }
              />
            }
            {currentViewOption === 2 && this.getTitleOptionSelector()}
            {currentViewOption === 2 && this.getTitleFilters()}
          </Flex>
          {
            this.getCurrentView()
          }
        </SummaryPanel>
        {/* {currentViewOption === 1 && (
          
      )} */}

        <TripControlColumnsSettingsModal
          open={this.state.isShowSettingsModal}
          refreshSelected={this.state.refreshSelected}
          itemsToHide={this.state.columnsHidden}
          updateItems={(value) => {
            this.setState({
              columnsHidden: value
            }, () => {
              this.saveUserFilter();
            });
          }}
          columns={this.state.columnsData}
          onClose={() => {
            this.setState({
              isShowSettingsModal: false
            })
          }}
          moveCard={ (dragIndex, hoverIndex) => {

            let data = [...this.state.columnsData];
          
            let temp = data[dragIndex];
            data[dragIndex] = data[hoverIndex];
            data[hoverIndex] = temp;

            this.setState({
              columnsData: data
            });

          }}
          dropItems={() => {
            this.saveUserFilter()
          }}
          changeAutorefresh={(refreshSelected) => {
            this.setState({
              refreshSelected
            }, () => {
              this.saveUserFilter();
            });
          }}
        />
        <Modal
          isOpen={this.state.openXlsModal}
          portalClassName="dialog confirm-dialog"
          overlayClassName="overlay"
          className="content invite-operator"
        >
          <div className="title">Agregar archivo</div>
          <div className="close" onClick={this.closeXlsModal}></div>
          <div className="message">
            <div className="attach-xls">
              <div style={{minHeight: "43px"}}>
                <FileSelector
                  name={"xls"}
                  acceptTypes={[acceptTypes.xls]}
                  onChange={this.handleAddDocument}
                  allowMultiple={false}
                >
                  <Button
                    settings={{
                      type: "primary small",
                      text: "Subir archivo",
                      prefix: {
                        element: (
                          <SVGIcon
                            name={icons.uploadFile}
                            width="24px"
                            height="24px"
                            viewBox="0 0 24 24"
                          />
                        ),
                      },
                    }}
                  />
                </FileSelector>
              </div>
              <div>
                {(this.state.xls || []).map((f, i) => (
                  <Chip
                    key={i}
                    id={i}
                    active={true}
                    className="medium"
                    name="files"
                    description={getFileDescription(f, 25)}
                    onClick={() => {}}
                    // prefix={{
                    //   element: (
                    //     <SVGIcon
                    //       name={icons.imageFile}
                    //       fill="#657795"
                    //       viewBox="0 -1 16 16"
                    //     />
                    //   ),
                    // }}
                    suffix={{
                      element: (
                        <div
                          onClick={() => this.handleRemoveDocument("xls", f)}
                        >
                          {" "}
                          <SVGIcon
                            name={icons.cross}
                            fill="#A7B6D6"
                            viewBox="0 3 16 16"
                          />
                        </div>
                      ),
                    }}
                  />
                ))}
              </div>
            </div>
            <div>
              <Button 
                type="primary small"
                text="Descargar template"
                onClick={() => {this.openDocument(this.getTemplateURL(), "Template de Edición de Citas.xlsx", true)}}
              />
            {/* <DownloadIcon data-tip data-for={"download-icon"}>
                {
                  this.state.downloadingFile ? 
                  <Loader width="40px" height="40px" /> :
                  <DownloadImage
                    fill={"#FFFFFF"}
                    width={"50px"}
                    height={"50px"}
                    onClick={(e) => {e.stopPropagation(); this.openDocument(this.getTemplateURL(), "Ordenes_Template.xlsx", true)}}
                  />
                }
              </DownloadIcon>
              <ReactTooltip
                id={"download-icon"}
                delayHide={50}
                delayShow={50}
                delayUpdate={200}
                place={"bottom"}
                border={true}
                type={"light"}
                effect="solid"
              >
                <span style={{lineHeight: "20px"}}>{`Descargar citas template xlsx`}</span>
              </ReactTooltip> */}
            </div>
          </div>
          <div className="actions">
            <Button
              settings={{
                text: "Cancelar",
                type: "primary outline",
                onClick: this.closeXlsModal,
              }}
            />
            <Button
              settings={{
                text: this.state.isLoadingXls ? "Cargando..." : "Cargar",
                disabled:
                  this.state.xls === null || this.state.isLoadingXls ? true : false,
                onClick: () => {
                  this.importXls();
                },
              }}
            />
          </div>
        </Modal>
        
        <Modal
          isOpen={this.state.openXlsSuccessModal}
          portalClassName="dialog confirm-dialog"
          overlayClassName="overlay"
          className="content invite-operator successModal"
        >
          <div className="title">Carga finalizada</div>
          <div className="close" onClick={this.closeXlsSuccessModal}></div>
          <div className="message">
            <div className="successContent">
              <div className="text-group">
                {`Órdenes procesadas: ${this.state.totalImport}`}
              </div>
              <div className="text-group">
                {`Órdenes modificadas: ${this.state.correctImport}`}
              </div>
              <div className="text-group">
                {`Órdenes rechazadas: ${this.state.failedImport}`}
              </div>
              <div className="export">
                Para ver más detalles ver el{" "}
                <a
                  href={`${SERVER}/api/v2/companies/${this.props.companyId}/upload/${this.state.uploadId}/`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  descargable
                </a>
              </div>
            </div>
          </div>
          <div className="actions successActions">
            <Button
              settings={{
                text: "Aceptar",
                onClick: this.closeXlsSuccessModal,
              }}
            />
          </div>
        </Modal>
        <Modal
          isOpen={this.state.openXlsErrorModal}
          portalClassName="dialog confirm-dialog"
          overlayClassName="overlay"
          className="content invite-operator successModal"
        >
          <div className="title">Error</div>
          <div className="close" onClick={this.closeXlsErrorModal}></div>
          <div className="message">{this.state.descriptionImport.map(e => <div>{e}</div>)}</div>
          <div className="actions successActions">
            <Button
              settings={{
                text: "Aceptar",
                onClick: this.closeXlsErrorModal,
              }}
            />
          </div>
        </Modal>
        <ErrorDialog
          open={this.state.msgError !== ''}
          message={this.state.msgError}
          acceptText="OK"
          acceptAction={() => this.setState({msgError: ''})}
          closeAction={() => this.setState({msgError: ''})}
        />
        
      </View>
    );
  }


  
  getToken = () => {
    return {
      Authorization:
        "Bearer " +
        (JSON.parse(sessionStorage.getItem("orbinetwork_session")) || {})
          .access,
    };
  };

  getUrl = () => {
    let multifacilities = this.state.filter.urlFacilities.filter(item => item !== '1');

    if(this.state.currentViewOption === 3){
      if(this.state.searchStr && this.state.searchStr.length > 0){
        return `${SERVER}/api/v2/companies/${this.props.companyId}/shipment_control/?view=${this.state.maneuver}&offset=${this.state.offset}&limit=${this.state.limit}&appointment=&appointment_type=&multifacilities=&from_facilities=&to_facilities=&status=${this.state.searchType  === "1" ? '' : (this.state.filter.selectedstatusorder || []).join(",")}&appointment_status=&fleet_providers=&transport_classes=&trip_tags=&order_tags=&priority=&search=${this.state.searchStr || ""}&search_type=${this.state.searchType === "1" ? 'folio' : this.state.searchType  === "2" ? 'vehiculo' : 'placas'}&category=&start_date=${(this.state.filter.appointment && this.state.filter.appointment[0]) ? this.state.filter.appointment[0].toISOString() : ''}&end_date=${(this.state.filter.appointment && this.state.filter.appointment[1]) ? this.state.filter.appointment[1].toISOString() : ''}&timezone=${getTimeZone()}&facilities=&container_status=&eta_load_sort=&eta_unload_sort=&download=true`;
      } else {
        const { maneuver, filter } = this.state;
        let appointmentType = maneuver === 'all' ? (this.state.filter.appointmentType  || []) : [];
  
        let facilities = this.getFacilities(maneuver, this.state.filter.selectedFacilities);
        let appointments = (filter.appointment && filter.appointment[0]) ? "".concat(filter.appointment[0].toISOString(), ",", filter.appointment[1] ? filter.appointment[1].toISOString() : filter.appointment[0].toISOString()) : "";
  
        return `${SERVER}/api/v2/companies/${this.props.companyId}/shipment_control/?view=${this.state.maneuver}&offset=${this.state.offset}&limit=${this.state.limit}&appointment=${appointments}&appointment_type=${appointmentType.join(",") || "1,2,3"}&multifacilities=${multifacilities || ""}&from_facilities=${filter.from_facilities || []}&to_facilities=${filter.to_facilities || []}&status=${(this.state.filter.selectedstatusorder || []).join(",")}&appointment_status=${(this.state.filter.selectedAppointmentStatus || []).join(",")}&fleet_providers=${(this.state.filter.selectedProviders || []).join(",")}&transport_classes=${(this.state.filter.transportClass || []).join(",")}&trip_tags=${(this.state.filter.selectedTrip || []).join(",")}&order_tags=${(this.state.filter.selectedOrder || []).join(",")}&priority=${(filter.selectedLevel || []).join(",")}&search=${this.state.searchStr || ""}&search_type=&category=${this.state.category || ''}&start_date=${(this.state.filter.appointment && this.state.filter.appointment[0]) ? this.state.filter.appointment[0].toISOString() : ''}&end_date=${(this.state.filter.appointment && this.state.filter.appointment[1]) ? this.state.filter.appointment[1].toISOString() : ''}&timezone=${getTimeZone()}&facilities=${facilities}&container_status=${(filter.containerStatus || []).join(",")}&eta_load_sort=&eta_unload_sort=&download=true`;
      }
    } else {
      if(this.state.searchStr && this.state.searchStr.length > 0){
        return `${SERVER}/api/v2/companies/${this.props.companyId}/facilities/${this.props.match.params.facility}/trips_control/?view=${this.state.maneuver}&offset=${this.state.offset}&limit=${this.state.limit}&appointment=&appointment_type=&multifacilities=&from_facilities=&to_facilities=&status=&appointment_status=&fleet_providers=&transport_classes=&trip_tags=&order_tags=&facilities=&priority=&search=${this.state.searchStr || ""}&search_type=${this.state.searchType === "1" ? 'folio' : this.state.searchType  === "2" ? 'vehiculo' : 'placas'}&category=${this.state.category || ''}&timezone=${getTimeZone() || ""}&download=true`;
      } else {
        const { maneuver, filter } = this.state;
        let appointmentType = maneuver === 'all' ? (this.state.filter.appointmentType  || []) : [];
  
        let facilities = this.getFacilities(maneuver, this.state.filter.selectedFacilities);
        let appointments = (filter.appointment && filter.appointment[0]) ? "".concat(filter.appointment[0].toISOString(), ",", filter.appointment[1] ? filter.appointment[1].toISOString() : filter.appointment[0].toISOString()) : "";
  
        return `${SERVER}/api/v2/companies/${this.props.companyId}/facilities/${this.props.match.params.facility}/trips_control/?view=${this.state.maneuver}&offset=${this.state.offset}&limit=${this.state.limit}&appointment=${appointments}&appointment_type=${appointmentType.join(",") || "1,2,3"}&multifacilities=${multifacilities || ""}&from_facilities=${(this.state.filter.from_facilities || []).join(",")}&to_facilities=${(this.state.filter.to_facilities || []).join(",")}&status=${(this.state.filter.selectedstatus || []).join(",")}&appointment_status=${(this.state.filter.selectedAppointmentStatus || []).join(",")}&fleet_providers=${(this.state.filter.selectedProviders || []).join(",")}&transport_classes=${(this.state.filter.transportClass || []).join(",")}&trip_tags=${(this.state.filter.selectedTrip || []).join(",")}&order_tags=${(this.state.filter.selectedOrder || []).join(",")}&facilities=${facilities}&priority=${(this.state.filter.selectedLevel || []).join(",")}&search=${this.state.searchStr || ""}&search_type=&category=${this.state.category || ''}&timezone=${getTimeZone() || ""}&download=true`;
      }
    }
  };

  getFacilities = (type, facilities) => {
    switch (type) {
      case "all":
        return "";
      default:
        return (facilities || []).join(",");
    }
  };

  openDocument = (url, fileName, ignoreAuth) => {
    this.setState({ 
      downloadingFile: true, 
      cancelTokenSource: Axios.CancelToken.source() 
    }, () => {
      Axios({
        url: url,
        method: "GET",
        responseType: "blob",
        cancelToken: this.state.cancelTokenSource.token,
        headers: ignoreAuth ? null : this.getToken(),
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName ? fileName : this.state.currentViewOption === 3 ? "Ordenes.xlsx" : "Trafico.xlsx");
        document.body.appendChild(link);
        link.click();
        this.setState({
          downloadingFile: false, 
          cancelTokenSource: null
        })
      }).catch((response) => {
        let error = (response.errors && response.errors.length > 0) ? response.errors.join() : response.error || 'Ocurrió un error, intente de nuevo';
        
        this.setState({
          msgError: error,
          downloadingFile: false, 
          cancelTokenSource: null
        });
      });
    })
    
  };

  getTemplateURL = () => {
    return `${FILES_SERVER}media/files/Cargador_Citas_V19072022.xlsx`
  }

  openXlsModal = () => {
    this.setState({ openXlsModal: true });
  };
  closeXlsModal = () => {
    if (!this.state.isLoadingXls) {
      this.setState({
        openXlsModal: false,
        xls: null,
        isLoadingXls: false,
      });
    }
  };

  handleAddDocument = (e) => {
    this.setState({ xls: e.target.value });
  };

  handleRemoveDocument = () => {
    if (!this.state.isLoadingXls) {
      this.setState({ xls: null });
    }
  };

  importXls = () => {
    this.setState({ isLoadingXls: true });
    this.props.uploadXls({
      excel_file: this.state.xls[0],
      company_id: this.props.companyId
    }, {
      isForm: true,
      onSuccess: (response) => {
        if(response["successful"] !== null && response["successful"] !== undefined){

          this.setState({
            openXlsSuccessModal: true,
            totalImport: response.total ?? '-',
            correctImport:  response.successful ?? '-',
            failedImport:  response.errors ?? '-',
            uploadId: response.upload_id ?? 0,
            isLoadingXls: false,
            openXlsModal: false,
            xls: null
          })
        } else {

          this.setState({
            openXlsErrorModal: true,
            descriptionImport: response["errors"],
            isLoadingXls: false,
            openXlsModal: false,
            uploadId: null,
            xls: null
          })
        }
      },
      onError: (error) => {
        this.setState({
          openXlsErrorModal: true,
          descriptionImport: error.response?.data?.errors || ["Ocurrió un error al cargar el archivo"],
          isLoadingXls: false,
          openXlsModal: false,
          uploadId: null,
          xls: null
        })
      }
    })
  };
  
  closeXlsSuccessModal = () => {
    this.setState({
      openXlsSuccessModal: false,
      totalImport: 0,
      correctImport: 0,
      failedImport: 0,
      uploadId: null,
      refreshData: true
    });
  };

  
  closeXlsErrorModal = () => {
    this.setState({
      openXlsErrorModal: false,
      descriptionImport: [],
      totalImport: 0,
      correctImport: 0,
      failedImport: 0,
      uploadId: null,
    });
  };

  getViewTitle = () => {
    const viewTitle = (
      <React.Fragment>
        <ViewTitle>
          <div style={{flex: "1"}}>
          {this.getFacilityTitle()}
          </div>

          {/* <ValidatePerms
            perms={[
              {
                id: "61",
                perm: "u"
              }
            ]}
          > */}
            {
              this.state.currentViewOption === 3 &&
              <div style={{display: "flex", alignItems: "center"}}>
                <Button 
                  type="primary outline"
                  text="Edición masiva de citas"
                  onClick={() => {this.openXlsModal()}}
                />
              </div>
            }
          {/* </ValidatePerms> */}
          <div style={{display: "flex", flexDirection: "column", alignItems: "end"}}>
            {this.getTitleFilters()}
            <div style={{display: "flex", transform: `translateY(${this.state.emails?.length ? "30" : "10"}px)`}}>
              {/* <RadioInput
                className="filter-radio"
                name="searchType"
                title="Órdenes"
                value="1"
                selected={this.state.searchType}
                onClick={(e) => {this.setState({searchType: e.target.value})}}
              />
              <RadioInput
                className="filter-radio"
                name="searchType"
                title="Vehículos"
                value="2"
                selected={this.state.searchType}
                onClick={(e) => {this.setState({searchType: e.target.value})}}
              /> */}
              <SearchTypeDropdown
                items={[
                  {
                    title: 'Órdenes',
                    value:  '1'
                  },
                  {
                    title: 'Vehículos',
                    value:  '2'
                  },
                  {
                    title: 'Placas',
                    value:  '3'
                  }
                ]}
                selected={this.state.searchType}
                onSelect={(item) => {
                  this.setState({
                    searchType: item
                  });
                }}
              />
            </div>
          </div>
        </ViewTitle>

        {this.getTitleOptionSelector()}
      </React.Fragment>
    );
    return viewTitle;
  };

  getCategoryName = () => {
    let name = '';
    let categories = this.state.category.split('-');

    if (categories.length > 1) {
      this.state.categories.map( item => {
        if (`${item.id}` === `${categories[0]}`) {
          name = item.description;
        }
      });
    }

    return name;
  }

  getFacilityTitle = () => {
    return (
      <div>
        {
          this.state.category ? `Control de viajes: ${this.getCategoryName()}` :
          this.state.urlFacilities ?
          "Control de viajes"  
          :
          <>
            {`Control de viajes ${this.props.facility?.name || ""}`}
            <div
              style={{ fontSize: "15px", lineHeight: "15px" }}
            >{`Hora local de la instalación: ${this.state.facilityTime}`}</div>
          </>
        }
      </div>
    );
  };

  SummaryDetailsFilters = (
    status: any[] = [],
    transportClasses: any[] = [],
    tagsTrip: any[] = [],
    tagsOrder: any[] = [],
    facilities: any[] = [],
    level: any[] = [],
    appointment: any[] = []
  ) => {
    const { maneuver } = this.state;
    
    let statusCondensed = status.filter(s => !s.description.toLowerCase().includes("reservación confirmada"));
    let combined = status.filter(s => s.description.toLowerCase().includes("reservación confirmada")).map(s => s.id);
    statusCondensed.push({id: combined.join(), description: "Reservación confirmada"});
    let containerStatusCatalog = this.props.containerStatusCatalog;

    if (this.state.currentViewOption === 3) {
      containerStatusCatalog = (containerStatusCatalog || []).map(c => ({
        id: c.id,
        description: c.event_description
      }));
    }
    
    switch (maneuver) {
      case "unload":
        return [
          {
            title: this.state.currentViewOption === 3 ? "Estatus orden" : 'Estatus',
            items: this.state.currentViewOption === 3 ? this.props.orderStatuses : statusCondensed,
            name: this.state.currentViewOption === 3 ?  "selectedstatusorder" : "selectedstatus",
            listType: 5,
          },
          {
            title: "Estatus equipo",
            items: containerStatusCatalog,
            name: "containerStatus",
            listType: 5,
          },
          {
           title: "Cita",
           items: appointment,
           name: "selectedAppointmentStatus",
           listType: 5,
          },
          {
            title: "Transportista",
            items: this.props.networkCompanies,
            name: "selectedProviders",
            listType: 5,
          },
          {
            title: "Clase",
            items: transportClasses,
            name: "transportClass",
            listType: 5,
          },
          {
            title: "Etiqueta Viaje",
            items: tagsTrip,
            name: "selectedTrip",
            listType: 1,
          },
          {
            title: "Etiqueta Orden",
            items: tagsOrder,
            name: "selectedOrder",
            listType: 1,
          },
          {
            title: "Origen",
            items: facilities,
            name: "from_facilities",
            listType: 5,
          },
          {
            title: "Destino",
            items: facilities,
            name: "to_facilities",
            listType: 5,
          },
          {
            title: "Instalación",
            items: facilities,
            name: "selectedFacilities",
            listType: 5,
          },
          {
            title: "Nivel de importancia",
            items: level,
            name: "selectedLevel",
            listType: 5,
          },
          {
            title: "Fecha",
            name: "appointment",
            listType: 7,
            time: true
          },
          // {
          //   title: "Tipo fecha",
          //   items: [
          //     {
          //       id: "1",
          //       description: "Carga"
          //     },
          //     {
          //       id: "2",
          //       description: "Descarga"
          //     },
          //     {
          //       id: "3",
          //       description: "Plan de carga"
          //     },
          //   ],
          //   name: "appointmentType",
          //   listType: 5,
          // }
        ].filter( item => {

          

          if (this.state.currentViewOption === 1) {
            return (item.title !== 'Estatus de equipo');
          } else if (this.state.currentViewOption === 2) {
            return (item.title !== 'Origen' && item.title !== 'Destino' && item.title !== 'Estatus de equipo');
          } else {
            return true;
          }

        });
      case "load":
        return [
          {
            title: this.state.currentViewOption === 3 ? "Estatus orden" : 'Estatus',
            items: this.state.currentViewOption === 3 ? this.props.orderStatuses : statusCondensed,
            name: this.state.currentViewOption === 3 ?  "selectedstatusorder" : "selectedstatus",
            listType: 5,
          },
          {
            title: "Estatus equipo",
            items: containerStatusCatalog,
            name: "containerStatus",
            listType: 5,
          },
          {
           title: "Cita",
           items: appointment,
           name: "selectedAppointmentStatus",
           listType: 5,
          },
          {
            title: "Transportista",
            items: this.props.networkCompanies,
            name: "selectedProviders",
            listType: 5,
          },
          {
            title: "Clase",
            items: transportClasses,
            name: "transportClass",
            listType: 5,
          },
          {
            title: "Etiqueta Viaje",
            items: tagsTrip,
            name: "selectedTrip",
            listType: 1,
          },
          {
            title: "Etiqueta Orden",
            items: tagsOrder,
            name: "selectedOrder",
            listType: 1,
          },
          {
            title: "Origen",
            items: facilities,
            name: "from_facilities",
            listType: 5,
          },
          {
            title: "Destino",
            items: facilities,
            name: "to_facilities",
            listType: 5,
          },
          {
            title: "Instalación",
            items: facilities,
            name: "selectedFacilities",
            listType: 5,
          },
          {
            title: "Nivel de importancia",
            items: level,
            name: "selectedLevel",
            listType: 5,
          },
          {
            title: "Fecha",
            name: "appointment",
            listType: 7,
            time: true
          },
          // {
          //   title: "Tipo fecha",
          //   items: [
          //     {
          //       id: "1",
          //       description: "Carga"
          //     },
          //     {
          //       id: "2",
          //       description: "Descarga"
          //     },
          //     {
          //       id: "3",
          //       description: "Plan de carga"
          //     },
          //   ],
          //   name: "appointmentType",
          //   listType: 5,
          // }
        ].filter( item => {

          

          if (this.state.currentViewOption === 1) {
            return (item.title !== 'Estatus de equipo');
          } else if (this.state.currentViewOption === 2) {
            return (item.title !== 'Origen' && item.title !== 'Destino' && item.title !== 'Estatus de equipo');
          } else {
            return true;
          }
          
        });
      default:
        return [
          {
            title: this.state.currentViewOption === 3 ? "Estatus orden" : 'Estatus',
            items: this.state.currentViewOption === 3 ? this.props.orderStatuses : statusCondensed,
            name: this.state.currentViewOption === 3 ?  "selectedstatusorder" : "selectedstatus",
            listType: 5,
          },
          {
            title: "Estatus equipo",
            items: containerStatusCatalog,
            name: "containerStatus",
            listType: 5,
          },
          {
           title: "Cita",
           items: appointment,
           name: "selectedAppointmentStatus",
           listType: 5,
          },
          {
            title: "Transportista",
            items: this.props.networkCompanies,
            name: "selectedProviders",
            listType: 5,
          },
          {
            title: "Clase",
            items: transportClasses,
            name: "transportClass",
            listType: 5,
          },
          {
            title: "Etiqueta Viaje",
            items: tagsTrip,
            name: "selectedTrip",
            listType: 1,
          },
          {
            title: "Etiqueta Orden",
            items: tagsOrder,
            name: "selectedOrder",
            listType: 1,
          },
          {
            title: "Nivel de importancia",
            items: level,
            name: "selectedLevel",
            listType: 5,
          },
          {
            title: "Origen",
            items: facilities,
            name: "from_facilities",
            listType: 5,
          },
          {
            title: "Destino",
            items: facilities,
            name: "to_facilities",
            listType: 5,
          },
          {
            title: "Instalación",
            items: facilities,
            name: "urlFacilities",
            listType: 5,
          },
          {
            title: "Fecha",
            name: "appointment",
            listType: 7,
            time: true
          },
          {
            title: "Tipo fecha",
            items: [
              {
                id: "1",
                description: "Carga"
              },
              {
                id: "2",
                description: "Descarga"
              },
              {
                id: "3",
                description: "Programa"
              },
              {
                id: "4",
                description: "Programa y cita de carga"
              },
            ],
            name: "appointmentType",
            listType: 5,
          }
        ].filter( item => {

          if (this.state.currentViewOption === 1) {
            return (item.title !== 'Estatus de equipo');
          } else if (this.state.currentViewOption === 2) {
            return (item.title !== 'Origen' && item.title !== 'Destino' && item.title !== 'Estatus de equipo');
          } else {
            return true;
          }
          
        });
    }
  };

  setFilter = () => {
    let filterTemp = this.state.filterTemp;
    let dateFilterTemp = this.state.dateFilterTemp;
    this.setState({
      searchStr: "",
      emails: [],
      filter: filterTemp,
      dateFilter: dateFilterTemp,
      resetPaginator: true,
    });
  };

  handleEmailInput = (e) => {
    if (e) {
      switch (e.target.name) {
        case "inviteTags":
          // debugger;
          let mails = e.target.value
            // .filter((it) => !it.processed)
            .map((i) => ({
              email: i.email,
              avatar: "hide",
              avatarType: "",
              userId: null,
              // processed: true,
            }));
          this.setState({resetPaginator: true, emails: mails, searchStr: e.target.value.map(e => e.email)});
          break;
        case "deleteTags":
          this.setState({resetPaginator: true, emails: [].concat(e.target.value), searchStr: e.target.value.map(e => e.email) });
          break;
        default:
      }
    }
  };

  getTitleFilters = () => {
    return (
      <FilterBarCompact
        limit={350}
        limitErrorItem={this.state.currentViewOption === 2 ? "" : this.state.searchType === "1" ? "folios" : this.state.searchType === "2" ? "vehículos" : "placas"}
        searchStr={this.state.currentViewOption === 2 ? this.state.searchStr : this.state.emails}
        searchAction={this.state.currentViewOption === 2 ? this.handleChange : this.handleEmailInput}
        multiText={this.state.currentViewOption !== 2}
        searchPlaceholder={this.state.currentViewOption === 2 ? "Buscar" : this.state.searchType === "1" ? "Buscar orden ó viaje" : this.state.searchType === "2" ? "Buscar vehículo" : "Buscar placa"}
        clearMultiText={() => {
          this.setState({ emails: [], searchStr: "" });
        }}
        content={this.SummaryDetailsFilters( 
          this.props.tripStatuses,
          this.props.companyTransportClasses,
          this.props.companyTagsTrip,
          this.props.companyTagsOrder,
          this.props.facilitiesByTag,
          this.getLevelFilter(), 
          this.getAppointmentFilter(),
        )}
        filterAction={() => {
          // this.loadFacilities(this.props.facilitiesByTagArgs.date);
          // Cancel request
          if(this.state.cancelTokenSource !== null){
            this.state.cancelTokenSource.cancel();
          }

          if(this.state.buttonFilterName === "Aplicar"){
            this.setState(
              {
                buttonFilterName: "Restablecer",
                downloadingFile: false
              },
              () => { this.setFilter(); }
            );
          } else {
            this.setState(
              {
                filterTemp: {
                  appointment: [],
                  urlFacilities: this.state.urlFacilities,
                },
                dateFilterTemp: {
                  appointment: [],
                  urlFacilities: this.state.urlFacilities,
                },
                buttonFilterName: "Aplicar",
                downloadingFile: false
              },
              () => { this.setFilter(); }
            );
          }
        }}
        onChange={(e) => {

          this.state.filterTemp = {
            ...this.state.filterTemp,
            [e.target.name]: e.target.value,
          };

          this.state.dateFilterTemp = {
            ...this.state.dateFilterTemp,
            [e.target.name]: e.target.value,
          };

          if (e.target.name === "selectedstatusorder") {
            this.state.filterTemp.containerStatus = [];
            this.state.dateFilterTemp.containerStatus = [];
          }

          if (e.target.name === "containerStatus") {
            this.state.filterTemp.selectedstatusorder = [];
            this.state.dateFilterTemp.selectedstatusorder = [];
          }

          if (e.target.name === "from_facilities" || e.target.name === "to_facilities") {
            this.state.filterTemp.selectedFacilities = [];
            this.state.dateFilterTemp.selectedFacilities = [];
            this.state.filterTemp.urlFacilities = [];
            this.state.dateFilterTemp.urlFacilities = [];
          }

          if (e.target.name === "selectedFacilities" || e.target.name === "urlFacilities") {
            this.state.filterTemp.from_facilities = [];
            this.state.dateFilterTemp.from_facilities = [];
            this.state.filterTemp.to_facilities = [];
            this.state.dateFilterTemp.to_facilities = [];
          }

          this.setState({
            offset: 1,
            category: null,
            categoryName: null,
            buttonFilterName: "Aplicar"
          });
        }}
        buttonFilterName={this.state.buttonFilterName}
        selectedItems={ this.state.currentViewOption === 2 ? this.state.dateFilterTemp : this.state.filterTemp }
      />
    );
  };
  getTitleOptionSelector = () => {
    return (
      <div style={{ width: "300px", marginLeft: "auto", marginRight: "auto" }}>
        <OptionSelectorSwitch
          value={this.state.maneuver}
          name="maneuver"
          onChange={this.handleChange}
          items={[
            { description: "Descarga", value: "unload" },
            { description: "Cargas", value: "load" },
            { description: "Todo", value: "all" },
          ]}
        />
      </div>
    );
  };
}

const mapStateToProps = (state): StateToProps => {
  const loading = state.api["currentFacility"]?.status?.isFetching;
  const companyId: number = state.globalCatalog.session.company.id;
  const facility: Types.DetailFacility = state.api["currentFacility"]?.items;
  const containerStatusCatalog = getEntityItems(state, "COMPANIES.CATALOGS.CONTAINERSTATUS");
  const tripStatuses = getEntityItems(
    state,
    "COMPANIES.CATALOGS.TRIPSTATUS"
  ).map((f) => ({
    description: f.description,
    id: f.id || 0,
  }));

  let orderStatuses = getEntityItems(
    state,
    "COMPANIES.CATALOGS.ORDERSTATUS"
  ).map((f) => ({
    description: f.description,
    id: f.id || 0,
  }));

  if (orderStatuses && orderStatuses.length && orderStatuses.length > 0) {
    orderStatuses = orderStatuses.filter(status => {
      return ![4,10,12].includes(status.id);
    });
  }

  const companyTags = getEntityItems(state, "COMPANIES.TAGS");

  // const companyProviders2 = getEntityItems(state, "COMPANIES.NETWORK").map(
  //   (f) => ({
  //     description: f.fleet_provider.corporation_name,
  //     id: f.fleet_provider.fleet_provider_id || 0,
  //   })
  // );

  const companyProviders = getEntityItems(state, "COMPANIES.NETWORK");

  const companyTransportClasses = getEntityItems(
    state,
    "COMPANIES.TRANSPORTCLASS.FILTERS"
  ).map((f) => ({
    description: f.title,
    id: f.id,
    items: f.transport_classes.map((tc) => ({
      id: tc.id,
      description: tc.code,
    })),
  }));

  const companyFacilities = getEntityItems(state, "COMPANIES.FACILITIES");

  const facilitiesByTag = groupFacilities(companyFacilities);

  const companyTagsTrip = getEntityItems(state, "tagsTrips");
  const companyTagsOrder = getEntityItems(state, "tagsOrder");

  let ownCompanyId = state.globalCatalog.session.company.id;
  let ownCompanyType = (state.globalCatalog.session.company.type || { id: 0 }).id;

  let oppositeCompanyType = ownCompanyType === 1 ? 2 : 1;

  let networkCompanies =
    Array.isArray(state.companyReducer.networkApprovedInvitations.data) &&
    state.companyReducer.networkApprovedInvitations.data.reduce(
      (result, network) => {
        let id = companyProviders.find(
          (provider) => provider.id === network.id
        );
        if (network.from_companies.id === ownCompanyId) {
          if (network.to_companies.type === oppositeCompanyType) {
            result.push({
              id: String(id?.fleet_provider?.fleet_provider_id),

              description: network.to_companies_alias.concat(
                " - ",
                network.to_companies_code,
                " - ",
                network.to_companies.orbi_id
              ),
            });
          }
        } else {
          if (network.from_companies.type === oppositeCompanyType)
            result.push({
              // id: network.id,
              id: String(id?.fleet_provider?.fleet_provider_id),
              // description: network.from_companies.corporation_name,
              description: network.from_companies.orbi_id.concat(
                " - ",
                network.from_companies_alias,
                " - ",
                network.from_companies_code
              ),
            });
        }
        return result;
      },
      []
    );

  
  let ordersCount = getEntityCount(state, "COMPANIES.FACILITIES.COMMANDCONTROLCO") || 0;

  return {
    configuration: state.configuration.ShipmentDetailBase,
    companyTags,
    tripStatuses,
    orderStatuses,
    loading,
    companyId,
    facility,
    companyProviders,
    companyTransportClasses,
    companyFacilities,
    companyTagsTrip,
    companyTagsOrder,
    networkCompanies,
    facilitiesByTag,
    // categories,
    containerStatusCatalog,
    ordersCount,
    companyType: state.globalCatalog.session.company.type.id,
    userData: state.globalCatalog.session.company?.companyuser?.id || 0,
  };
};
const mapDispatchToProps = (dispatch): DispatchToProps => {
  dispatch(readEntities("COMPANIES.CATALOGS.TRIPSTATUS"));

  const loadTagsTripCatalog = (params = {}, opts = {}) =>
    dispatch(
      readEntities(
        "COMPANIES.ROUTES.TAGS",
        { ...params, type: "9" },
        { args: { ...params }, ...opts, alias: "tagsTrips" }
      )
    );
  const loadTagsOrderCatalog = (params = {}, opts = {}) =>
    dispatch(
      readEntities(
        "COMPANIES.ROUTES.TAGS",
        { ...params, type: "7" },
        { args: { ...params }, ...opts, alias: "tagsOrder" }
      )
    );

  dispatch(listNetworkApprovedInvitations("", ""));

  return {
    loadFilterCategories: (params, opt) => dispatch(readEntities("COMPANIES.COMMANDCONTROL.FILTER.CATEGORIES", params, opt)),
    uploadXls: (params, opt) => dispatch(createEntity("COMPANIES.APPOINTMENTCONTROL.UPLOAD", params, opt)),
    getContainerStatus: (params, opt) => dispatch(readEntities("COMPANIES.CATALOGS.CONTAINERSTATUS", params, opt)),
    loadOrderStatus: () => dispatch(readEntities("COMPANIES.CATALOGS.ORDERSTATUS")),
    setBaseConfig: (config) => dispatch(setConfig("ShipmentDetailBase", config)),
    downloadTrips: (params, options) =>
      dispatch(
        readEntity("COMPANIES.FACILITIES.TRIPSCONTROL.DOWNLOAD", params, options)
      ),
    loadFacility: (company_id, id, opts) =>
      dispatch(readEntity("COMPANIES.FACILITIES", { company_id, id }, opts)),
    loadCompanyTags: (company_id, opts) =>
      dispatch(readEntities("COMPANIES.TAGS", { company_id }, opts)),
    setStatus: (tripId: number, newStatus: number) =>
      dispatch(setStatus(tripId, newStatus)),
    setAppointmentDate: (tripId: number, newAppointmentDate: Date) =>
      dispatch(setAppointmentDate(tripId, newAppointmentDate)),
    setNavbarAction: (name, config) => dispatch(setNavbarAction(name, config)),
    removeNavbarAction: (name) => dispatch(removeNavbarAction(name)),
    loadCompanyProviders: (params, opts) =>
      dispatch(readEntities("COMPANIES.NETWORK", params, opts)),
    loadCompanyTransportClasses: (params, opts) =>
      dispatch(readEntities("COMPANIES.TRANSPORTCLASS.FILTERS", params, opts)),
    loadCompanyFacilities: (params, opts) =>
      dispatch(readEntities("COMPANIES.FACILITIES", params, opts)),
    loadTagsTripCatalog,
    loadTagsOrderCatalog,
    loadFilterCategories: (params, opt) => dispatch(readEntities("COMPANIES.COMMANDCONTROL.FILTER.CATEGORIES", params, opt)),
    loadUserFilter: (params, opt) => dispatch(readEntity("COMPANIES.COMMANDCONTROL.FILTER", params, opt)),
    saveFilter: (params, opts) => dispatch(createEntity("COMPANIES.COMMANDCONTROL.FILTER", params, opts)),
    setNavbarAction: (name, config) => dispatch(setNavbarAction(name, config)),
    removeNavbarAction: (name) => dispatch(removeNavbarAction(name))
  };
};

export default connect<Props, OwnProps, _, _, _, _>(
  mapStateToProps,
  mapDispatchToProps
)(ShipmentDetailView);

const AssignCard = ({ enabled = false }) => {
  return (
    <div
      id="assignCard"
      style={{
        visibility: "hidden",
        width: "60px",
        height: "75px",
        position: "absolute",
        backgroundColor: "green",
        border: "solid 1px orange",
      }}
    />
  );
};
