import * as React from "react";

const ImageWithCheckMark = (props) => {

  return (
    <div
      style={ props.customStyle ? props.customStyle : props.width ? { width: props.width, height: props.width, borderRadius: '50%', position: 'relative' } : { borderRadius: '50%', position: 'relative' }}
      onClick={() => {
        if (props.onClick) {
          props.onClick()
        }
      }}
    >
      <img 
        alt="" 
        src={props.src} style={ props.customImgStyle ? props.customImgStyle : props.width ? { width: props.width, height: props.width, borderRadius: '50%' } : { borderRadius: '50%' }}
        onError={(e) => {
          e.currentTarget.src = props.isCo ? "/images/factory-bckgrnd.png" : "/images/truck-bckgrnd.png";
        }}  
      />
      {
        props.isCheckMark &&
        <div
          style={{
            position: 'absolute',
            right: (props.width === 30) ? -5 : (props.width === 100) ? 5 : (props.width > 100) ? 10 : 0,
            bottom: (props.width === 30) ? -5 : (props.width === 100) ? 5 : (props.width > 100) ? 10 : 0,
            width: (props.width > 100) ? 25 : 15,
            height: (props.width > 100) ? 25 : 15,
            minHeight: (props.width > 100) ? 25 : 15,
            minWidth: (props.width > 100) ? 25 : 15,
          }}
        >
          <img
            alt=""
            src="/images/check-mark.png"
            style={{ 
              width: (props.width > 100) ? 25 : 15,
              height: (props.width > 100) ? 25 : 15,
              minHeight: (props.width > 100) ? 25 : 15,
              minWidth: (props.width > 100) ? 25 : 15,
              borderRadius: '50%'
            }}
          />
        </div> 
      }
    </div>
  );
}

export default ImageWithCheckMark;