import React from "react";
import PropTypes from "prop-types";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import es from "date-fns/locale/es";
import { validateControl, validationErrors } from "../validate";
import { getLastSession, US_FORMAT } from "../utils";
import { setHours, setMinutes } from "date-fns";
import ReactTooltip from 'react-tooltip';

registerLocale("es", es);

export const validate = {
  required: "required",
};

class TextInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasValue: false,
      touched: false,
      valid: undefined,
      validationErrors: [],
    };
  }
  componentDidMount() {
    this.isValid(this.props.value);
  }

  componentDidUpdate() {
    if (this.hasValue(this.props.value) !== this.state.hasValue) {
      this.setState({ hasValue: !this.state.hasValue });
    }

    const { touched } = this.state;
    const { onFocused } = this.props;

    if (onFocused && !touched) {
      this.setState({ touched: true }, () => {
        this.isValid(this.props.value);
      });
    }
  }

  handleInput = (e) => {
    if (this.props.isUserTyping) {
      this.props.isUserTyping();
    }

    if (
      this.props.length &&
      restrictLength(e.target.value, this.props.length)
    ) {
      return false;
    }
    if (this.props.numeric && !restrictNumeric(e.target.value)) {
      return false;
    }
    if (this.props.integer && !restrictInteger(e.target.value)) {
      return false;
    }
    if(this.props.naturalNumber && !restrictNatural(e.target.value)){
      return false;
    }

    this.props.onChange(e);
    // this.hasValue(e.target.value);
    this.isValid(e.target.value);
  };

  hasValue = (value) => {
    return value && value.toString().length ? true : false;
  };

  isValid = (value = this.props.value) => {
    if (this.state.touched) {
      let result = validateControl(
        this.props.validate,
        value,
        this.props.validationParam
      );
      // debugger
      this.setState({
        valid: result.validationResult.valid,
        validationErrors: result.validationResult.errors,
      });
    }
  };

  getMinDate = (date, days) => {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  };

  getMinHour = () => {
    const { minDate, timeValidationEnable, value } = this.props;
    if (minDate !== null && minDate !== undefined && timeValidationEnable !== null && timeValidationEnable !== undefined && timeValidationEnable) {
      let hours = this.getMinDate(minDate, 0).getHours();
      let mins = this.getMinDate(minDate, 0).getMinutes();

      if (value > minDate) {
        return null;
      }

      return setHours(setMinutes(new Date(), mins === 30 ? 0 : mins + 1), mins === 30 ? hours + 1 : hours );
      
    } else {
      return null;
    }
  }

  getMaxHour = () => {
    const { minDate, timeValidationEnable, value, maxDate } = this.props;
    if (minDate !== null && minDate !== undefined && timeValidationEnable !== null && timeValidationEnable !== undefined && timeValidationEnable) {

      if (value > minDate) {
        return null;
      }
      //debugger;
      return setHours(setMinutes(new Date(), 59), 23);
    } else {
      return null;
    }
  }

  setFocus = () => {
    this.input.focus();
  }

  getExludedTimes = () => {
    return (this.props.excludedTime !== null && this.props.excludedTime !== undefined) ? this.props.excludedTime : [];
  }

  getInputControlByType = () => {
    switch (this.props.type) {
      case "date":
        const years = [];
        for (let i = 1920; i <= 2050; i++) {
          years.push(i);
        }
        const months = [
          "Enero",
          "Febrero",
          "Marzo",
          "Abril",
          "Mayo",
          "Junio",
          "Julio",
          "Agosto",
          "Septiembre",
          "Octubre",
          "Noviembre",
          "Diciembre",
        ];
        return (
          <ReactDatePicker
            onFocus={() => this.setState({ touched: true })}
            // onBlur={() => this.isValid()}
            className="field"
            // showMonthDropdown
            // showYearDropdown
            // renderCustomHeader={({
            //   date,
            //   changeYear,
            //   changeMonth,
            //   decreaseMonth,
            //   increaseMonth,
            //   prevMonthButtonDisabled,
            //   nextMonthButtonDisabled,
            // }) => (
            //   <div
            //     style={{
            //       display: "flex",
            //       justifyContent: "center",
            //     }}
            //   >
            //     <button
            //       onClick={decreaseMonth}
            //       disabled={prevMonthButtonDisabled}
            //     >
            //       {"<"}
            //     </button>
            //     <select
            //       className="year"
            //       value={new Date(date).getFullYear()}
            //       onChange={({ target: { value } }) => changeYear(value)}
            //     >
            //       {years.map((option) => (
            //         <option key={option} value={option}>
            //           {option}
            //         </option>
            //       ))}
            //     </select>

            //     <select
            //       value={months[new Date(date).getMonth()]}
            //       onChange={({ target: { value } }) =>
            //         changeMonth(months.indexOf(value))
            //       }
            //     >
            //       {months.map((option) => (
            //         <option key={option} value={option}>
            //           {option}
            //         </option>
            //       ))}
            //     </select>

            //     <button
            //       onClick={increaseMonth}
            //       disabled={nextMonthButtonDisabled}
            //     >
            //       {">"}
            //     </button>
            //   </div>
            // )}
            onChange={(value) =>{
              this.handleInput({
                target: {
                  name: this.props.name,
                  value: getLastSession(value, this.props.timeValueFormat),
                },
              })}
            }
            selected={Date.parse(this.props.value || "")}
            locale="es"
            disabled={this.props.disabled}
            minDate={Date.parse(
              this.getMinDate(
                this.props.minDate,
                this.props.dateAdjust ? 1 : 0
              ) || ""
            )}
            minTime={this.getMinHour()}
            maxTime={this.getMaxHour()}
            maxDate={
              this.props.maxDate
                ? Date.parse(
                    this.getMinDate(
                      this.props.maxDate,
                      this.props.dateAdjust ? 1 : 0
                    )
                  )
                : null
            }
            filterDate={this.props.filterDate}
            showTimeSelect={this.props.showTimeSelect}
            dateFormat={this.props.format}
            selectsStart={this.props.selectsStart}
            selectsEnd={this.props.selectsEnd}
            startDate={this.props.startDate ? Date.parse(this.props.startDate) : null}
            endDate={this.props.endDate ? Date.parse(this.props.endDate) : null}
            excludeTimes={this.getExludedTimes()}
          />
        );

      default:
        return (
          <input
            ref={(input) => { this.input = input; }} 
            onFocus={() => this.setState({ touched: true })}
            // onBlur={this.props.handleBlur ? this.props.handleBlur : () => this.isValid()}
            onBlur={(e) => {
              this.isValid();
              this.props.handleBlur && this.props.handleBlur(e);
            }}
            id={this.props.id}
            name={this.props.name}
            type={this.props.type}
            className="field"
            onChange={this.handleInput}
            value={this.props.value}
            data-tip={this.props.dataTip}
            data-for={this.props.dataFor}
            disabled={this.props.disabled}
            autoComplete={"new-password"}
            onKeyDown={(e) => {
              if (this.props.handleCustomEnter) {
                this.props.handleCustomEnter(e);
                return
              }

              if (e.key === "Enter" && this.props.handleEnter)
                this.props.handleEnter();
            }}
          />
        );
    }
  };

  render() {
    return (
      <>
        <ReactTooltip
          id={"tooltip-".concat(this.props.name)}
          clickable={true}
          delayHide={50}
          delayShow={50}
          delayUpdate={200}
          place={"bottom"}
          border={true}
          type={"light"}
        >
          {this.props.extraInfo}
        </ReactTooltip>
        <div
          data-tip
          data-for={this.props.extraInfo ? "tooltip-".concat(this.props.name) : ''}
          className={"text-input ".concat(
            this.props.className,
            this.hasValue(this.props.value) ? " has-input" : "",
            this.state.valid === false || this.props.errors.length > 0
              ? " invalid"
              : " valid"
          ).concat(this.props.simple ? ' simple' : '')}
          onClick={this.props.onClick}
        >

          {this.hasValue(this.props.value) && React.cloneElement(this.props.prefix.element, {
            className: "prefix ".concat(this.props.prefix.className),
          })}

          {this.getInputControlByType()}
          <label htmlFor={this.props.name} className="label">
            {this.props.label} 
            {
              this.props.extraInfo &&
              <img
                src="/images/menu/Help.png"
                alt=""
                style={{marginLeft: 5, height: 13, minHeight: 13, width: 13}}
                className="icon"
              />
            }
          </label>

          {React.cloneElement(this.props.suffix.element, {
            className: "suffix ".concat(this.props.suffix.className),
          })}

          {this.state.valid === false && (
            <div className="text-input-invalid">
              <img className="i-error" alt="" src="/images/red-exclamation.svg" />
              <p className="p-error">
                {this.state.validationErrors &&
                this.state.validationErrors.length > 0
                  ? this.state.validationErrors[0]
                  : validationErrors.required}
              </p>
            </div>
          )}
          {this.props.errors.length > 0 && (
            <div className="text-input-invalid">
              <img className="i-error" alt="" src="/images/red-exclamation.svg" />
              <p className="p-error">{this.props.errors.join(", ")}</p>
            </div>
          )}
        </div>
      </> 
    );
  }
}

export default TextInput;

const restrictLength = (value, length) => {
  return value.length > length;
};
const restrictNumeric = (value) => {
  return RegExp(/^[0-9.-]*$/).test(value);
};
const restrictInteger = (value) => {
  return RegExp(/^[0-9-]*$/).test(value);
};
const restrictNatural = (value) => {
  return RegExp(/^[0-9]*$/).test(value);
}

TextInput.propTypes = {
  type: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string.isRequired,
  name: PropTypes.string,
  length: PropTypes.number,
  numeric: PropTypes.bool,
  integer: PropTypes.bool,
  naturalNumber: PropTypes.bool,
  onChange: PropTypes.func,
  dateAdjust: PropTypes.bool,
  prefix: PropTypes.shape({
    element: PropTypes.element,
    className: PropTypes.string,
  }),
  suffix: PropTypes.shape({
    element: PropTypes.element,
    className: PropTypes.string,
  }),
  validate: PropTypes.array,
  id: PropTypes.string,
  errors: PropTypes.array,
  filterDate: PropTypes.func,
  showTimeSelect: PropTypes.bool,
  format: PropTypes.string,
};

TextInput.defaultProps = {
  onChange: () => {
    console.log("Not implemented yet!");
  },
  value: "",
  validate: [],
  className: "",
  prefix: {
    element: <React.Fragment />,
    className: "",
  },
  suffix: {
    element: <React.Fragment />,
    className: "",
  },
  id: '',
  errors: [],
  dateAdjust: true,
  filterDate: null,
  showTimeSelect: false,
  timeValueFormat: US_FORMAT,
  format: "MM/dd/yyyy",
};
