//@flow
import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";

class Navbar extends React.Component {
  backwards = () => {
    if ((window.location.href.includes("/fleet/vehicle/") || window.location.href.includes("/fleet/equipment/") || window.location.href.includes("/fleet/operator/")) && !window.location.href.includes("/fleet/equipment/profile/") && !window.location.href.includes("/fleet/vehicle/profile/") && !window.location.href.includes("/fleet/operator/profile/")) {
      window.history.go(-1);
      return;
    }
    if (!window.location.href.includes("/configurations")) window.history.back();
  };
  render() {
    return (
      <NavbarStyled>
        <BackIconStyled onClick={this.backwards}>ᐸ Regresar</BackIconStyled>
        <ActionsContainer>
          {this.props.navbarActions.map((a, index) => (
            <ActionsStyled key={index} onClick={a.onClick}>
              {a.icon}
            </ActionsStyled>
          ))}
        </ActionsContainer>
      </NavbarStyled>
    );
  }
}

const mapStateToProps = (state) => {
  const navbarActions = [];
  const tempNav = Object.values(state.navbar);

  for (let i in tempNav) {
    if (tempNav[i].length) {
      for (let j in tempNav[i]) {
        navbarActions.push(tempNav[i][j]);
      }
    }
  }

  return {
    navbarActions
  };
};
export default connect(mapStateToProps)(Navbar);

const NavbarStyled = styled.div`
  background-color: #576791;
  z-index: 2;
  height: 60px;
  position: relative;
  display: flex;
  justify-content: space-between;
`;

const BackIconStyled = styled.div`
  color: #afc8ff;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  margin: auto 40px;
`;

const ActionsContainer = styled.div`
  color: #afc8ff;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  margin: auto 40px;
  display: flex;
`;
const ActionsStyled = styled.div`
  margin-left: 4px;
  margin-right: 4px;
`;
