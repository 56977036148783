import React, { Component } from "react";
import Loader from '../icons/tat/loader';
import ReactTooltip from "react-tooltip";

class OptionsListBlack extends Component {

  buildList = () => {
    let ids_blocked = this.props.idsBlocked || [];

    if (this.props.loading && this.props.visible) {
      return (
        <div
          className='option-list-component'
          style={{
            height: 45, 
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'center',
            backgroundColor: '#576791', 
            width: this.props.width || 50,
            position: 'absolute', 
            borderRadius: 5,
          }}
        >
          <Loader/>
        </div>
      );

    } else if ((this.props.items || []).length > 3 && this.props.visible) {
      return (
        <div className='option-list-component' style={{backgroundColor: '#576791', width: this.props.width || 50, position: 'absolute', borderRadius: 5, height: 150, overflowY: 'scroll'}}>
          {
            (this.props.items || []).map( (item, index)  => {
              return(
                <>
                  <div 
                    className='option-list-component' 
                    key={`option-list-component-${index}`}
                    data-tip
                    data-for={`option-list-component-${index}`}
                  >
                    <div 
                      className='option-list-component'
                      style={ids_blocked.includes(item.id || 0) ? {
                        color: 'white', 
                        height: 45, 
                        display: 'flex', 
                        alignItems: 'center', 
                        flexDirection: 'column', 
                        fontSize: 15, 
                        justifyContent: 'center', 
                        fontWeight: 'normal', 
                        whiteSpace: 'normal', 
                        textAlign: 'center',
                        cursor: 'not-allowed'
                      } : {
                        color: 'white', 
                        height: 45, 
                        display: 'flex', 
                        alignItems: 'center', 
                        flexDirection: 'column', 
                        fontSize: 15, 
                        justifyContent: 'center', 
                        fontWeight: 'normal', 
                        whiteSpace: 'normal', 
                        textAlign: 'center',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        if (!ids_blocked.includes(item.id || 0)) {
                          this.props.onClick(item)
                        }
                      }}
                    >
                      {item.description}
                    </div>
                    {
                      (this.props.items.length - 1) > index && <div style={{height: 0.5, backgroundColor: 'white', width: '100%'}}/>
                    }
                  </div>
                  {
                    ids_blocked.includes(item.id || 0) &&
                    <ReactTooltip
                      id={`option-list-component-${index}`}
                      place="top"
                      className='eta-tooltip-content'
                      event="mouseover"
                      eventOff="mouseleave"
                    > 
                      <div>{'Este producto ya se encuentra en ésta entrega, no se puede añadir de nuevo'}</div>
                    </ReactTooltip>
                  }
                </>
              );
            })
          }
        </div>
      );
    } else if ((this.props.items || []).length > 0 && this.props.visible) {
      return (
        <div className='option-list-component' style={{backgroundColor: '#576791', width: this.props.width || 50, position: 'absolute', borderRadius: 5}}>
          {
            (this.props.items || []).map( (item, index) => {
              return(
                <>
                  <div className='option-list-component' key={`option-list-component-${index}`}>
                    <div 
                      className='option-list-component'
                      style={ids_blocked.includes(item.id || 0) ? {
                        color: 'white', 
                        height: 45, 
                        display: 'flex', 
                        alignItems: 'center', 
                        flexDirection: 'column', 
                        fontSize: 15, 
                        justifyContent: 'center', 
                        fontWeight: 'normal', 
                        whiteSpace: 'normal', 
                        textAlign: 'center',
                        cursor: 'not-allowed'
                      } : {
                        color: 'white', 
                        height: 45, 
                        display: 'flex', 
                        alignItems: 'center', 
                        flexDirection: 'column', 
                        fontSize: 15, 
                        justifyContent: 'center', 
                        fontWeight: 'normal', 
                        whiteSpace: 'normal', 
                        textAlign: 'center',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        if (!ids_blocked.includes(item.id || 0)) {
                          this.props.onClick(item)
                        }
                      }}
                    >
                      {item.description}
                    </div>

                    {
                      (this.props.items.length - 1) > index && <div style={{height: 0.5, backgroundColor: 'white', width: '100%'}}/>
                    }
                  </div>
                  {
                    ids_blocked.includes(item.id || 0) &&
                    <ReactTooltip
                      id={`option-list-component-${index}`}
                      place="top"
                      className='eta-tooltip-content'
                      event="mouseover"
                      eventOff="mouseleave"
                    > 
                      <div>{'Este producto ya se encuentra en ésta entrega, no se puede añadir de nuevo'}</div>
                    </ReactTooltip>
                  }
                </>
              );
            })
          }
        </div>
      );
    } else {
      return (<div/>);
    }

  }

  render() {
    return (
      this.buildList()
    );
  }
}
export default OptionsListBlack;
