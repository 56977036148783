import React from "react";
import Modal from "react-modal";
import Button from "../controls/button";
import { getEntityItems, partialEntity } from "../../redux/api";
import { loadSession } from "../../redux/reducers/global-catalog.reducer";
import Loader from "../icons/tat/loader";
import { connect } from "react-redux";
import { cancelMultipleRequests, readEntities } from "../../redux/api/actions";
import ReactTooltip from "react-tooltip";
import InputSearch from "../controls/input-search";
import { cloneDeep } from "lodash";
import { groupFacilities } from "../utils";

Modal.setAppElement("#root");

class AddUserModal extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      search: '',
      users: [],
      moreUsers: false,
      usersSelected: this.getAllUsers(props?.notification?.groups || []),
      usersAdded: this.getAllUsersDetail(props?.notification?.groups || []),
      usersAddedByUser: [],
      isAllSelected: false,
      errorMessage: null,
      offset: 1,
      confirmActions: false,
      hasFacilities: props?.notification?.has_facilities,
      stage: 1,

      searchFacility: '',
      facilitiesSelected: [],
      isAllFacilitiesSelected: false,
      isPendingInfo: false
    }
  }

  getAllUsers = (groups) => {
    let all_users = [];

    groups.map( item => {
      all_users = all_users.concat(item.users.map( item2 => parseInt(item2)));
    });

    return all_users;
  }

  getAllUsersDetail = (groups) => {
    let all_users = [];

    groups.map( item => {
      all_users = all_users.concat(item.users_detail);
    });

    return all_users;
  }

  componentDidMount() {
    this.loadUsers(this.state.offset);

    if (this.state.hasFacilities) {
      this.props.loadFacilities({ company_id: this.props.companyId });
    }
  }

  hasChanged = () => {
    if (this.state.usersSelected.length > 0 && this.state.usersAdded.length === this.state.usersSelected.length) {
      return !this.state.usersAdded.every( item => this.state.usersSelected.includes(item.users_id));
    } else if (this.state.usersSelected.length > 0) {
      return true;
    } else if (this.state.usersSelected.length !== this.state.usersAdded.length) {
      return true;
    }

    return false;
  }

  buildTitle = () => {

    let title = '';

    switch(this.state.stage) {
      case 1:
        title = this.state.hasFacilities ? 'Añadir Usuarios' : 'Gestionar Usuarios'; 
        break;
      case 2:
        title = 'Gestionar Instalaciones';
        break;
      case 3:
        title = 'Resumen';
        break;
      default:
        title = '';
    }

    return(
      <>
        <div className="title-action">{title}</div>
      </>
    );
  }

  buildSearch = () => {
    if (this.state.stage !== 1 || this.state.isPendingInfo) return '';

    return(
      <div className="search-component">
        <InputSearch
          type='text'
          label='Buscar'
          name='search'
          className="full"
          onChange={(name, value) => {
            this.handleChange({
              target: { name, value }
            }, () => {

              if (value.length > 3 || value.length === 0) {
                this.setState({
                  offset: 1
                });
          
                this.loadUsers(1);
              }
            });
          }}
        />
        <img
          src="/images/search.svg"
          alt=""
          className="icono"
        />
      </div>
    )
  }

  buildUsers = () => {

    if (this.state.stage !== 1 || this.state.isPendingInfo) return '';

    // if (this.props.isLoading) {
    //   return(
    //     <div className="users-section">
    //       <div className="users-list" style={{height: this.state.errorMessage ? 'calc(100% - 250px)' : 'calc(100% - 230px)'}} onScroll={this.handleScroll}>
    //         <Loader circleColor={'black'} width="40px" height="40px"/>
    //       </div>
    //     </div>
    //   );
    // }

    return(
      <div className="users-section">

        {/* <div className="user-item">
          <div
            className={`checkbox-container ${this.state.isAllSelected ? 'selected' : ''}`}
            onClick={() => {
              if (this.state.isAllSelected) {
                this.setState({
                  usersSelected: [],
                  isAllSelected: false
                });
              } else {
                this.setState({
                  usersSelected: this.state.users.map( item => item.id),
                  isAllSelected: true
                });
              }
            }}
          >
            <div className="checkbox"></div>
          </div>

          <div style={{width: 8}}/>
          {'Todos'}
        </div> */}

        <div className="users-list" style={{height: this.state.errorMessage ? 'calc(100% - 250px)' : 'calc(100% - 230px)'}} onScroll={this.handleScroll}>
          {
            // (this.state.search === '' ? this.state.users : this.state.users.filter( item => item.name.toLowerCase().includes(this.state.search.toLowerCase())) ).map( user => {
            this.state.users.map( user => {
              return(
                <div className="user-item" key={`user-${user.id}`}>
                  
                  <div
                    className={`checkbox-container ${this.state.usersSelected.includes(user.id) ? 'selected' : ''}`}
                    onClick={() => {
                      if (this.state.usersSelected.includes(user.id)) {
                        this.setState({
                          usersSelected: this.state.usersSelected.filter( user_id => user_id !== user.id),
                          isAllSelected: false
                        })
                      } else {
                        this.state.usersAddedByUser.push(user);
                        this.state.usersSelected.push(user.id);
                        this.setState({});
                      }
                    }}
                  >
                    <div className="checkbox"></div>
                  </div>

                  <div style={{width: 8}}/>
                  {`${user.first_name} ${user.last_name}`}
                </div>
              );
            })
          }
        </div>

      </div>
    );
  }

  handleScroll = (e) => { 
    this.scrollTop = e.target.scrollTop;
    const bottom = (e.target.scrollHeight - e.target.scrollTop) < (e.target.clientHeight + 10);
    if (bottom && !this.props.isLoading && this.state.moreUsers) {

      let new_offset = this.state.offset + 1;
      this.setState({
        offset: new_offset
      });

      this.loadUsers(new_offset); 
    }
  }

  loadUsers = (offset) => {
    this.props.cancelMultipleRequests();
    this.props.loadUsers({
      company_id: this.props.companyId,
      offset: offset || '',
      search: this.state.search
    }, {
      onSuccess: (response) => {

        let current_users = [];

        if (this.state.hasFacilities) {
          current_users = this.state.usersAdded.map( item => parseInt(item.users_id));
        }

        current_users.push(this.props.userId);
        let users = (`${offset}` === '1' ? response : this.state.users.concat(response)).filter( item => !current_users.includes(item.id));

        this.setState({
          moreUsers: `${offset}` === '1' ? true : response.length > 0,
          users
        })
      }
    })
  }

  handleChange = (e, action) => {
    this.setState({[e.target.name]: e.target.value}, () => {
      if (action) action();
    })
  }

  onClose = () => {
    if (this.props.isUpdatingLoading) return;
    if (this.props.closeAction) this.props.closeAction();
  };

  buildActions = () => {
    return(
      <div className="actions" style={{marginTop: 5}}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <div/>

          <div style={{display: 'flex'}}>
            <Button
              disabled={this.props.isUpdatingLoading}
              text={this.state.isPendingInfo ? 'Cancelar' : [2,3].includes(this.state.stage) ? 'Cancelar' : 'Cerrar'}
              type={'btn secondary primary'}
              onClick={() => {

                if (this.state.isPendingInfo) {
                  this.setState({
                    isPendingInfo: false
                  });
                  return;
                }

                if (this.state.stage === 1) {
                  this.onClose();
                } else if (this.state.stage === 2) {
                  this.setState({stage: 1});
                } else if (this.state.stage === 3) {
                  this.setState({stage: this.state.hasFacilities ? 2 : 1});
                }

              }}
            />

            <div style={{width: 20}}/>
            
            <Button
              disabled={this.state.isPendingInfo ? false : this.props.isUpdatingLoading || this.state.stage === 3 ? false : this.state.stage === 2 ? this.state.facilitiesSelected.length === 0 : !this.hasChanged() }
              text={this.state.isPendingInfo ? 'Continuar' : this.props.isUpdatingLoading ? <Loader circleColor={'black'}/> : this.state.stage === 3 ? 'Aceptar' : 'Continuar' }
              type={'btn outline primary dark'}
              onClick={() => {

                if (this.state.isPendingInfo) {
                  this.onClose();
                  return;
                }

                if (this.state.stage === 3) {

                  if (this.state.hasFacilities) {

                    let resume = this.getResumeWithFacilities();

                    let new_users = resume.new_users;
                    let new_facilities = resume.new_facilities;

                    let data = {
                      action: 'add_new_user_with_facilities',
                      company_id: this.props.companyId,
                      new_users: new_users.map( item => item.id),
                      new_facilities: new_facilities.map( item => item.id),
                      notification_id: this.props.notification.id
                    };

                    this.props.manageUsers(data, {
                      onSuccess: () => {
                        if (this.props.handleChange) {
                          this.props.handleChange({
                            target: {
                              name:'show-add-user-facility-modal-success',
                              value: this.props.notification.id
                            }
                          });
                        }
                      },
                      onError: (e) => {
                        this.setState({
                          errorMessage: e.response?.data?.error || 'Ocurrió un error, intente de nuevo'
                        })
                      }
                    });

                  } else {
                    let resume = this.getResume();

                    let new_users = resume.new_users;
                    let users_to_remove = resume.users_to_remove;

                    let data = {
                      action: 'add_new_user',
                      company_id: this.props.companyId,
                      users: new_users.map( item => item.id),
                      users_to_remove: users_to_remove.map( item => item.users_id),
                      notification_id: this.props.notification.id
                    };

                    this.props.manageUsers(data, {
                      onSuccess: () => {
                        if (this.props.handleChange) {
                          this.props.handleChange({
                            target: {
                              name:'show-add-user-modal-success',
                              value: this.props.notification.id
                            }
                          });
                        }
                      },
                      onError: (e) => {
                        this.setState({
                          errorMessage: e.response?.data?.error || 'Ocurrió un error, intente de nuevo'
                        })
                      }
                    });
                  }

                } else {
                  this.setState({
                    stage: (!this.state.hasFacilities && this.state.stage === 1) ? 3 : this.state.stage + 1
                  });
                }
              }}
            />

          </div>

        </div>
      </div>
    );
  }

  getResume = () => {
    let new_users=[];

    this.state.usersAddedByUser.map( user => {
      if (this.state.usersSelected.includes(user.id) && !this.state.usersAdded.includes(user.id)) {
        new_users.push(user);
      }
    });

    let users_to_remove = (this.state.usersAdded || []).filter( user => !this.state.usersSelected.includes(user.users_id));

    return {
      users_to_remove, new_users
    };
  }

  getResumeWithFacilities = () => {
    let new_users=[];
    this.state.usersAddedByUser.map( user => {
      if (this.state.usersSelected.includes(user.id)) {
        new_users.push(user);
      }
    });

    let new_facilities=[];

    this.props.facilitiesByTag.map( tag => {
      (tag.items || []).map( facility => {
        if (this.state.facilitiesSelected.includes(facility.id)) {
          let facilities_found = new_facilities.filter( item => item.id === facility.id);
          if (facilities_found.length === 0) {
            new_facilities.push(facility);
          }
        }
      })
    });

    return {
      new_users, new_facilities
    };
  }

  confirmActions = () => {

    if (this.state.stage !== 3 || this.state.isPendingInfo) return '';

    let resume = this.getResume();

    let new_users = resume.new_users;
    let users_to_remove = resume.users_to_remove;

    return(
      <div style={{
        height: '70%',
        marginBottom: '20px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        textDecoration: 'underline',
        alignItems: 'center',
        fontSize: 18
      }}>
        <div
          data-tip
          data-for={`new_users`}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: 'fit-content'
          }}
        ><img alt="" src="/images/oval green.svg"></img> <div style={{width: 10}}/> {`${new_users.length} Usuarios agregados`}</div>

        {
          (new_users && new_users.length > 0) &&
          <ReactTooltip
            id={`new_users`}
            className='eta-tooltip-content'
            event="mouseover"
            eventOff="mouseleave"
            place="top"
          > 
            {
              new_users.map( (item, index) => {
                return(
                  <div key={`new-user-${index}`}>{`${item.first_name} ${item.last_name} - ${item.username}`}</div>
                );
              })
            }
          </ReactTooltip>
        }

        <div
          data-tip
          data-for={`remove_users`}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: 'fit-content'
          }}
        ><img alt="" src="/images/oval red.svg"></img> <div style={{width: 10}}/> {`${users_to_remove.length} Usuarios eliminados`}</div>
        {
          (users_to_remove && users_to_remove.length > 0) &&
          <ReactTooltip
            id={`remove_users`}
            className='eta-tooltip-content'
            event="mouseover"
            eventOff="mouseleave"
            place="top"
          > 
            {
              users_to_remove.map( (item, index) => {
                return(
                  <div key={`remove-user-${index}`}>{`${item.users__first_name} ${item.users__last_name} - ${item.users__username}`}</div>
                );
              })
            }
          </ReactTooltip>
        }
      </div>
    );

  }

  confirmActionsWithFacilities = () => {

    if (this.state.stage !== 3 || this.state.isPendingInfo) return '';

    let resume = this.getResumeWithFacilities();

    let new_users = resume.new_users;
    let new_facilities = resume.new_facilities;

    return(
      <div style={{
        height: '70%',
        marginBottom: '20px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        textDecoration: 'underline',
        alignItems: 'center',
        fontSize: 18
      }}>
        <div
          data-tip
          data-for={`new_users`}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: 'fit-content'
          }}
        ><img alt="" src="/images/oval green.svg"></img> <div style={{width: 10}}/> {`${new_users.length} Usuarios agregados`}</div>

        {
          (new_users && new_users.length > 0) &&
          <ReactTooltip
            id={`new_users`}
            className='eta-tooltip-content'
            event="mouseover"
            eventOff="mouseleave"
            place="top"
          > 
            {
              new_users.map( (item, index) => {
                return(
                  <div key={`new-user-${index}`}>{`${item.first_name} ${item.last_name} - ${item.username}`}</div>
                );
              })
            }
          </ReactTooltip>
        }

        <div
          data-tip
          data-for={`new_facilities`}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: 'fit-content'
          }}
        ><img alt="" src="/images/oval green.svg"></img> <div style={{width: 10}}/> {`${new_facilities.length} Instalaciones agregados`}</div>

        {
          (new_facilities && new_facilities.length > 0) &&
          <ReactTooltip
            id={`new_facilities`}
            className='eta-tooltip-content'
            event="mouseover"
            eventOff="mouseleave"
            place="top"
          > 
            {
              new_facilities.map( (item, index) => {
                return(
                  <div key={`new-facility-${index}`}>{`${item.description}`}</div>
                );
              })
            }
          </ReactTooltip>
        }
      </div>
    );

  }

  getFacilitiesSearched = () => {
  
    if (this.state.searchFacility === '') { return this.props.facilitiesByTag || []; }

    let tags_temp = cloneDeep(this.props.facilitiesByTag || []);

    (this.props.facilitiesByTag || []).map( (tag, index) => {
      tags_temp[index].items = tag.items.filter( facility => facility.description.toLowerCase().includes(this.state.searchFacility.toLowerCase()))
    });

    return tags_temp.filter( tag => tag.items.length > 0);
  }

  isTagSelected = (tag) => {
    return tag.items.every( facility => this.state.facilitiesSelected.includes(facility.id) )
  }

  buildFacilitiesSection = () => {
    if (this.state.stage !== 2 || this.state.isPendingInfo) return '';

    return(
      <>
        <div className="search-component">
          <InputSearch
            type='text'
            label='Buscar Instalaciones'
            name='searchFacility'
            className="full"
            onChange={(name, value) => {
              this.handleChange({
                target: { name, value }
              })
            }}
          />
          <img
            src="/images/search.svg"
            alt=""
            className="icono"
          />
        </div>

        <div className="users-section">
          <div className="user-item">
            <div
              className={`checkbox-container ${this.state.isAllFacilitiesSelected ? 'selected' : ''}`}
              onClick={() => {
                if (this.state.isAllFacilitiesSelected) {
                  this.setState({
                    facilitiesSelected: [],
                    isAllFacilitiesSelected: false
                  });
                } else {
  
                  let facility_ids = [];
  
                  this.props.facilitiesByTag.map( tag => {
                    tag.items.map( facility => {
                      facility_ids.push(facility.id)
                    })
                  })
  
                  this.setState({
                    facilitiesSelected: facility_ids,
                    isAllFacilitiesSelected: true
                  });
                }
              }}
            >
              <div className="checkbox"></div>
            </div>
  
            <div style={{width: 8}}/>
            {'Todos'}
          </div>
  
          <div className="users-list" style={{height: this.state.errorMessage ? 'calc(100% - 295px)' : 'calc(100% - 265px)'}}>
            {
              this.getFacilitiesSearched().map( tag => {
                return(
                  <React.Fragment key={`tag-content-${tag.id}`}>
                    <div className="user-item" key={`tag-${tag.id}`}>
                      <div
                        className={`checkbox-container ${this.isTagSelected(tag) ? 'selected' : ''}`}
                        onClick={() => {
                          if (this.isTagSelected(tag)) {
                            let facilities_not_allowed = tag.items.map( item => item.id);
                            this.setState({
                              facilitiesSelected: this.state.facilitiesSelected.filter( facility_id => !facilities_not_allowed.includes(facility_id)),
                              isAllFacilitiesSelected: false
                            })
                          } else {
                            let facilities_to_allow = tag.items.filter( item => !this.state.facilitiesSelected.includes(item.id) ).map( item => item.id);
  
                            this.setState({
                              facilitiesSelected: this.state.facilitiesSelected.concat(facilities_to_allow)
                            });
                          }
                        }}
                      >
                        <div className="checkbox"></div>
                      </div>
  
                      <div style={{width: 8}}/>
                      {tag.description}
                    </div>
                    {this.buildFacilities(tag)}
                  </React.Fragment>
                );
              })
            }
          </div>     
  
        </div>
      </>
    );
  }

  buildFacilities = (tag) => {
    return(
      <>
        {
          tag.items.map( facility => {
            return(
              <div className="user-item" key={`tag-${tag.id}-${facility.id}`}>
                <div style={{width: 30}}/> 
                <div
                    className={`checkbox-container ${this.state.facilitiesSelected.includes(facility.id) ? 'selected' : ''}`}
                    onClick={() => {
                      if (this.state.facilitiesSelected.includes(facility.id)) {
                        this.setState({
                          facilitiesSelected: this.state.facilitiesSelected.filter( item_id => item_id !== facility.id),
                          isAllSelected: false
                        })
                      } else {
                        this.state.facilitiesSelected.push(facility.id);
                        this.setState({});
                      }
                    }}
                  >
                  <div className="checkbox"></div>
                </div>
                <div style={{width: 20}}/> 
                {facility.description}
              </div>
            );
          })
        }
      </>
    );
  }

  buildPendingInfo = () => {
    if (!this.state.isPendingInfo) return '';

    return(
      <div style={{height: '75%', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 18}}>
        {'Tienes cambios sin guardar, ¿quieres continuar?'}
      </div>
    );
  }

  render() {
    return (
      <Modal
        isOpen={this.props.showModal !== null}
        portalClassName={`dialog add-user-dialog ${
          this.props.addClass ? this.props.addClass : ""
        }`}
        overlayClassName="overlay"
        className="content"
      >
        <div className="close" onClick={() => {
          if (this.state.hasFacilities && [2,3].includes(this.state.stage) ) {
            this.setState({
              isPendingInfo: true
            });

            return;
          }

          this.onClose();
        }}></div>

        { this.buildTitle() }
        { this.buildSearch() }
        { this.buildUsers() }
        { this.state.hasFacilities ? this.confirmActionsWithFacilities() : this.confirmActions() }
        { this.buildFacilitiesSection() }
        { this.buildPendingInfo() }

        {
          this.state.errorMessage && <div style={{color: 'red', fontSize: 13}}>{this.state.errorMessage}</div>
        }

        { this.buildActions() } 

      </Modal>
    );
  }
} 

const mapStateToProps = (state) => {
  const companyId = state.globalCatalog.session.company.id;
  const userId = state.globalCatalog.session.user.id;
  const isUpdatingLoading = state?.api['COMPANIES.REQUISITIONSCONTROL.REQUISITIONS.ACTIONS']?.status?.isFetching || false;
  const isLoading = state?.api['COMPANIES.USERSCATALOG']?.status?.isFetching || false;
  const isUsersLoading = state?.api['COMPANIES.NOTIFICATIONS.MANAGEUSERS']?.status?.isFetching || false;
  const companyFacilities = getEntityItems(state, "COMPANIES.FACILITIES");
  const facilitiesByTag = groupFacilities(companyFacilities); 

  return {
    companyId,
    isUpdatingLoading: isUsersLoading || isUpdatingLoading,
    isLoading,
    isUsersLoading,
    userId,
    facilitiesByTag
  };
}

const mapDispatchToProps = (dispatch) => {
  dispatch(loadSession());
  
  return {
    cancelMultipleRequests: () => dispatch(cancelMultipleRequests()),
    loadUsers: (params, opts) => dispatch(readEntities("COMPANIES.USERSCATALOG", params, opts)),
    manageUsers: (params, opt) => dispatch(partialEntity("COMPANIES.NOTIFICATIONS.MANAGEUSERS", params, opt)),
    loadFacilities: (params, opts) => dispatch(readEntities("COMPANIES.FACILITIES", params, opts)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddUserModal);