import React from 'react';
import Modal from 'react-modal';

Modal.setAppElement('#root');

class LoginDetailModal extends React.Component {

  onClose = () => {
    this.props.closeAction();
  }

  render() {
    return(
      <Modal
        isOpen={this.props.open}
        portalClassName="dialog login-detail-modal"
        overlayClassName="overlay"
        className="content"
      >
        <div className="modal-icon">
          <img src={this.props.img}/>
        </div>
        <div className="modal-title">{this.props.title}</div>
        <div className='modal-message' /* style={{maxHeight: 300, overflow: 'auto'}} */>{this.props.message}</div>
        <img className='modal-close' src="/images/img/close-blue-icon.svg"  onClick={this.onClose}/>
      </Modal>
    );   
  }

}

export default LoginDetailModal;