import React, { Component } from "react";
import ReactDatePicker from 'react-datepicker';
import moment from 'moment';
import Button from "../controls/button";

class CustomDatePicker extends Component { 

  constructor(props) {
    super(props);
    this.state = {
      dateSelected: props.dateSelected ? props.dateSelected : new Date(),
      currentSchedule: props.timeSelected ? { end: props.timeSelected } : { end: 0 },
    }
  }

  formatNumberToTimePart = (number, hour = false) => {
    let hours = parseInt(number / 100);
    hours = this.limitNumber(hours, 23);
    let minutes = parseInt(number) % 100;
    minutes = this.limitNumber(minutes, 59);
    hours = "00".concat(hours.toString()).substr(-2, 2);
    minutes = "00".concat(minutes.toString()).substr(-2, 2);
    return hour ? hours : minutes;
  };

  getTimeData = (number) => {
    let hours = parseInt(number / 100);
    hours = this.limitNumber(hours, 23);
    let minutes = parseInt(number) % 100;
    minutes = this.limitNumber(minutes, 59);
    hours = "00".concat(hours.toString()).substr(-2, 2);
    minutes = "00".concat(minutes.toString()).substr(-2, 2);

    return `${hours}:${minutes}`;
  };

  limitNumber = (number, upperLimit, lowerLimit = 0) => {
    number = number < lowerLimit ? lowerLimit : number;
    number = number > upperLimit ? upperLimit : number;
    return number;
  };

  handleTimeInput = (e) => {
    let tms = e.target.value.replace(":", "");
    this.handleChange({ target: { name: e.target.name, value: tms } });
  };

  handleChange = (e) => {
    const name = e.target.name || (e.target.attributes["name"] || {}).value || "";
    let currentSchedule = Object.assign({}, this.state.currentSchedule);

    switch (name) {
      case "endHour":
        currentSchedule.end =
          (currentSchedule.end % 100) +
          this.limitNumber(parseInt(e.target.value), 23) * 100;
        break;
      case "endMinute":
        currentSchedule.end =
          parseInt(currentSchedule.end / 100) * 100 +
          this.limitNumber(parseInt(e.target.value), 59);
        break;
    }
    
    this.setState({currentSchedule});
  }

  render() {
    let currentSchedule = Object.assign({}, this.state.currentSchedule);

    return (
      <div className="custom-date-picker">
        <div className="close-action">
          <img 
            src='/images/close-icon.svg' 
            alt='' 
            style={{width: 25, height: 25, cursor: 'pointer'}}
            onClick={() => {
              this.props.onClose();
            }}
          />
        </div>

        <ReactDatePicker
          minDate={this.props.minDate}
          locale='es' 
          inline
          selected={this.state.dateSelected}
          onChange={ (value) => {
            this.setState({
              dateSelected: value,
              currentSchedule: {end: 0},
            });
          }}
        />

        {
          this.props.withTime ?
          <div className="dates">
            <div style={{display: 'flex'}}>
              <div className='appointment-date-active'>
                {`${moment(this.state.dateSelected).format('DD MMMM yyyy')}`}
                <input
                  style={{color: 'black'}}
                  name="endHour"
                  type="number"
                  value={this.formatNumberToTimePart(
                    currentSchedule.end,
                    true
                  )}
                  onChange={this.handleTimeInput}
                />
                :
                <input
                  style={{color: 'black'}}
                  name="endMinute"
                  type="number"
                  value={this.formatNumberToTimePart(currentSchedule.end)}
                  onChange={this.handleTimeInput}
                />
              </div>
            </div>
          </div> : <div style={{height: 20}}/>
        }

        <div className='content-buttons'>
        
          <Button
            text={'Cancelar'}
            type={'primary small outline dark'}
            onClick={() => {this.props.onClose()}}
          />

          <Button
            text={'Guardar'}
            type={'primary small'}
            onClick={() => {

              this.props.handleChange({
                target: {
                  name: 'change_date',
                  value: {
                    time: this.getTimeData(currentSchedule.end),
                    date: `${moment(this.state.dateSelected).format('DD/MM/yyyy')}`
                  }
                }
              });

            }}
          />
                
        </div>
        
      </div>
    );
  }
}

export default CustomDatePicker;
