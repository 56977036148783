import React, { Component } from "react";
import ReactTooltip from "react-tooltip";
import PropTypes from "prop-types";

class ExceedPlus extends Component {
  static propTypes = {
    className: PropTypes.string,
    maxVisibleItems: PropTypes.number,
    name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    additionalText: PropTypes.string,
    replaceText: PropTypes.string,
    position: PropTypes.oneOf(["right", "bottom", "left"]),
    face: PropTypes.element,
    tooltipOpts: PropTypes.object,
  };
  static defaultProps = {
    className: "",
    maxVisibleItems: 1,
    additionalText: "",
    replaceText: "",
    position: "right",
    face: undefined,
    delayHide: 600,
    tooltipOpts: {},
  };

  render() {
    let el = React.Children.toArray(this.props.children);
    return (
      <div
        className={"exceed-plus".concat(" ", this.props.className)}
        // style={{
        //   flexDirection: this.props.position === "right" ? "row" : "column",
        // }}
      >
        {this.props.face || el.slice(0, this.props.maxVisibleItems)}
        <div
          data-tip
          data-for={"tooltip-".concat(this.props.name)}
        >
          <div
            className={el.length > this.props.maxVisibleItems ? "plus" : "hidden"}
          >
            {(this.props.replaceText.length > 0
              ? this.props.replaceText
              : `+${el.length - this.props.maxVisibleItems}`
            ).concat(this.props.additionalText)}
          </div>
          <ReactTooltip
            className={this.props.onTooltipClassname}
            id={"tooltip-".concat(this.props.name)}
            // getContent={() => el}
            clickable={true}
            delayHide={this.props.delayHide}
            // delayShow={200}
            // delayUpdate={500}
            place={this.props.position}
            isCapture={true}
            border={true}
            type={"light"}
            effect={"solid"}
            {...this.props.tooltipOpts}
          >
            {el}
          </ReactTooltip>
        </div>
      </div>
    );
  }
}

export default ExceedPlus;
