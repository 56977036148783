import React from "react";
import {
  MainContent,
  FieldTitle,
  SelectorContainer,
  SelectorBorder,
  SelectorTitle,
  SelectorImgContainer,
  FieldPage,
  LogoImage,
  LogoImageContainer
} from "./style/view";

class RegisterView extends React.Component {

  state = {
    booked: false
  }

  componentDidMount() {
    if (this.props.location.search.includes('booked')) {
      const query = new URLSearchParams(this.props.location.search);
      this.state.booked = query.get('booked') === 'true';
    }
  }

  render() {
    let isMobile = window.innerWidth < 900;

    return(
      <MainContent
        isSmallSize={isMobile}
        className="register-new-client-co"
      >

        {/* <FieldPage>1 / xx</FieldPage> */}

        <FieldTitle
          isMobile={isMobile}
        >{'Elige el perfil de tu empresa'}</FieldTitle>

        <SelectorContainer>

          <SelectorBorder
            onClick={() => {
              this.props.history.push(`/registerTr${ this.state.booked ? '?booked=true' : '' }`);
            }}
            style={{maxHeight: 240, maxWidth: 240}}
          >
            <SelectorImgContainer 
              alt="" 
              src={ "/images/img/TR_icon_color.svg" }/>
              
            <div style={{marginTop: 10}}>
              <SelectorTitle>{`Transporte:`}</SelectorTitle>
              <SelectorTitle>{`Prestadores de servicio de transporte de carga federal o transporte privado de carga.`}</SelectorTitle>
            </div>

          </SelectorBorder>

          <SelectorBorder
            onClick={() => {
              this.props.history.push(`/registerCo${ this.state.booked ? '?booked=true' : '' }`);
            }}
            style={{maxHeight: 240, maxWidth: 240}}
          >
            <SelectorImgContainer 
              alt="" 
              src={ "/images/img/CO_icon_color.svg" }
              style={{width: "70%"}}
            />

            <div style={{marginTop: 10}}>
              <SelectorTitle>{`Dueños de Carga:`}</SelectorTitle>  
              <SelectorTitle>{`Fabricantes, comercializadoras o distribuidoras de cualquier tipo de producto o materia prima.`}</SelectorTitle>
            </div>

          </SelectorBorder>

        </SelectorContainer>

        <LogoImageContainer>
          <LogoImage src="/images/img/ico-logo-orbi-color.svg" alt="orbi icon"/>
        </LogoImageContainer>

      </MainContent>
    );
  }

}

export default RegisterView;