import React from "react";
import { connect } from "react-redux";
/*import PermissionsTable from '../shared/component/permissions-table';*/
// import AlertList from '../shared/component/alert-list';
// import NotificationList from '../shared/component/notification-list';
/*import FacilityUsers from './facility/facility-users';
import FacilityGeofences from './facility/facility-geofences';*/
import ProfileInformation from "../shared/component/profile-information";
// import LevelInfo from "../shared/component/level-info";
// import LevelStats from "../shared/component/level-stats";
import { getCompanyData } from "../redux/reducers/company-profile-reducer";
import { getUrlsEnv } from "../shared/backend-api";
import { withRouter } from "react-router-dom";
import TagsTab from "./company/tags-tab";

import NotificationListView from './notifications/notifications-list-view.jsx';
import AlertListView from './alerts/alerts-list-view.jsx';
import {
  loadStates,
  loadCities,
  loadSession,
} from "../redux/reducers/global-catalog.reducer";
import ExpeditionsTab from "./company/expeditions-tab";
import PermissionsTab from "./company/permissions-tab";
import { MapController } from "../shared/component/map-container";
import TaxAddressListView from "./billing/tax-address-list-view";
import { getEntityItems } from "../redux/api/helpers.jsx";
import NotificationConfigurationView from "./notifications/notification-configuration-view.jsx";
import CatalogSelectionFooter from "../shared/component/catalog-selection-footer.jsx";
import AddUserModal from "../shared/dialog/add-user-modal.jsx";
import ManageFacilityUserModal from "../shared/dialog/manage-facility-user-modal.jsx";

const { files } = getUrlsEnv();

class CompanyProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected_tab: "etiquetas",
      id: "",
      name: "",
      alias: "",
      mainImage: null,
      address: "",
      city: "",
      state: "",
      zipCode: "",
      email: "",
      webPage: "",
      country: "",
      marker: {},
      showAddUserModal: null,
      showManageFacility: null
    };

    this.mapController = new MapController();
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const tab = urlParams.get("tab");
    if (tab){
      this.setState({ selected_tab: tab });
    }
    this.props
      .getCompanyData(this.props.match.params.company)
      .then((response) => {
        const json = (response || {}).params || {};
        this.setState(
          {
            id: json.orbi_id,
            name: json.corporation_name,
            alias: json.orbi_id,
            mainImage: json.logo,
            address: json.address,
            city: json.city,
            state: json.state,
            zipCode: json.postalcode,
            email: json.email,
            webPage: json.web_page,
            country: json.country,
          },
          () => {
            this.mapController.loadMap(null, null, null, "roadmap", true);
            this.props.loadStates(this.state.country);
            this.props.loadCities(this.state.state);
          }
        );
      });

    this.mapController.isReadySubject.subscribe((ready) => {
      if (ready) {
        const { states, cities } = this.props;
        const { address, city, state, zipCode } = this.state;
        let cityData =
          Object.values(cities).find((item) => item.id === city) || {};
        let stateData =
          Object.values(states).find((item) => item.id === state) || {};
        let addressFull = `${address}, ${cityData.description}, ${stateData.description}, ${zipCode}`;
        this.mapController.geocodeAddress(addressFull);
        this.geocodeResult = this.mapController.geocodeResultSubject.subscribe(
          (next) => {
            this.mapController.setCenterMap(next);
            let icon = this.state.mainImage
              ? `${files}${this.state.mainImage}`
              : // : "/images/factory-bckgrnd.png",
                "/".concat(this.props.companyDefaultLogo);
            this.setState({
              marker: this.mapController.setMarkerImage(
                icon,
                {
                  lat: next[0].geometry.location.lat(),
                  lng: next[0].geometry.location.lng(),
                },
                { width: 50, height: 50 }
              ),
            });
            this.state.marker.setMap(this.mapController.map);
            // this.mapController.setMarker(next, this.marker);
            // this.props.onFacilityChange({
            //   target: {
            //     name: "geopos_x",
            //     value: next[0].geometry.location.lat(),
            //   },
            // });
            // this.props.onFacilityChange({
            //   target: {
            //     name: "geopos_y",
            //     value: next[0].geometry.location.lng(),
            //   },
            // });
          }
        );
        // this.mapController.geocodeFromAddress();
        // let icon = this.state.mainImage
        //   ? `${files}${this.state.mainImage}?${Date.now()}`
        //   : // : "/images/factory-bckgrnd.png",
        //     "/".concat(this.props.companyDefaultLogo);
        // this.point = this.mapController.setMarkerImage(
        //   icon,
        //   {
        //     lat: this.props.originMarker.position[0],
        //     lng: this.props.originMarker.position[1],
        //   },
        //   { width: 25, height: 25 }
        // );
        // this.mapController.geocodeLocation(
        //   // this.props.facility.geopos_x,
        //   // this.props.facility.geopos_y
        //   23.634501,
        //   -102.552784
        // );
      }
    });
  }

  switchTab = (e) => {
    if (e.target.id) {
      this.setState({ selected_tab: e.target.id });
    }
  };
  getTabContent = () => {
    switch (this.state.selected_tab) {
      case "etiquetas":
        return (
          <TagsTab
            companyId={this.props.companyId}
            permsTabs={[
              {
                id: "14",
                perm: "u",
              },
              {
                id: "14",
                perm: "d",
              },
            ]}
            evalTabs={"or"}
            permsAdd={[
              {
                id: "14",
                perm: "c",
              },
            ]}
            permsUpdate={[
              {
                id: "14",
                perm: "u",
              },
            ]}
            permsRead={[
              {
                id: "14",
                perm: "r",
              },
            ]}
          />
        );
      case "clases":
        return (
          <ExpeditionsTab
            companyId={this.props.companyId}
            permsTabs={[
              {
                id: "15",
                perm: "d",
              },
            ]}
            evalTabs={"or"}
            permsAdd={[
              {
                id: "15",
                perm: "c",
              },
            ]}
            permsUpdate={[
              {
                id: "15",
                perm: "u",
              },
            ]}
            permsRead={[
              {
                id: "15",
                perm: "r",
              },
            ]}
          />
        );
      case "permisos":
        return (
          <PermissionsTab
            companyId={this.props.companyId}
            permsTabs={[
              {
                id: "5",
                perm: "u",
              },
              {
                id: "5",
                perm: "d",
              },
            ]}
            evalTabs={"or"}
            permsAdd={[
              {
                id: "5",
                perm: "c",
              },
            ]}
            permsUpdate={[
              {
                id: "5",
                perm: "u",
              },
            ]}
            permsRead={[
              {
                id: "5",
                perm: "r",
              },
            ]}
          />
        );
      // return <PermissionsTable facility={1} />;
      case 'alertas':
          // return <AlertList companyLevel={true} facility={this.props.facilityId}/>;
          return <AlertListView />;
      case 'notificaciones':
          return <NotificationListView /* companyLevel={true} facility={this.props.facilityId} *//>;
      /*case 'usuarios':
          return <FacilityUsers facility={this.props.facilityId}/>;
      case 'geocercas':
          return <FacilityGeofences />;
      case 'ventanas':
          return <img alt="" src="/images/calendario-mockup.png" style={{"width": "100%"}}/>;*/
      case 'rfc':
        return <TaxAddressListView history={this.props.history}/>
      default:
        return <div />;
    }
  };

  // this.props.handleChange({
  //   target: {
  //     name:'show-add-user-modal',
  //     value: this.state.notification
  //   }
  // })

  handleChange = (e) => {
    let value = e.target.value;
    let name = e.target.name;

    switch(name) {
      case 'show-add-user-modal':
        this.setState({showAddUserModal: value});
        break;
      case 'show-add-user-modal-success':
        this.setState({showAddUserModal: value});
        break;
      case 'show-manage-facility':
        this.setState({showManageFacility: value})
        break;
      default:
        this.setState({[name]: value});
    }

    console.log('handle change', name, value);
  }

  render() {
    const {
      id,
      name,
      alias,
      mainImage,
      address,
      city,
      state,
      zipCode,
      email,
      webPage,
    } = this.state;
    const { states, cities } = this.props;

    const citieList = cities
      ? cities.filter((c) => String(c.id) === String(city))
      : [];
    const cityName = citieList.length > 0 ? citieList[0].description : "";

    const stateList = states
      ? states.filter((c) => String(c.id) === String(state))
      : [];
    const stateName = stateList.length > 0 ? stateList[0].description : "";
    return (
      <div className="facility-profile-container">
        <div className="facility-profile">
          <div className="top-row">
            <div
              className="facility-info"
              style={{ backgroundColor: "rgba(0,0,0,0)", padding: "0px" }}
            >
              <ProfileInformation
                isCompanyVerified={this.props.isCompanyVerified}
                type={"COMPANY-PROFILE"}
                data={{
                  id,
                  name,
                  alias,
                  mainImage: mainImage
                    ? `${files}${mainImage}`
                    : // : "/images/factory-bckgrnd.png",
                      "/".concat(this.props.companyDefaultLogo),
                  address:
                    address +
                    ", " +
                    cityName +
                    ", " +
                    stateName +
                    " " +
                    zipCode,
                  email,
                  webPage,
                  clickEditBtn: () =>
                    this.props.history.push(
                      `/company/data/${this.props.match.params.company}`
                    ),
                }}
              />
              <div
                style={{
                  backgroundColor: "#20293d",
                  marginTop: "-10px",
                  color: "rgba(0,0,0,0)",
                }}
              >
                sds{" "}
              </div>
              <div
                style={{
                  backgroundColor: "#20293d",
                  color: "rgba(0,0,0,0)",
                  borderBottomLeftRadius: "12px",
                  borderBottomRightRadius: "12px",
                }}
              >
                sds{" "}
              </div>
            </div>
            <div className="facility-orbi-levels" style={{ marginTop: "60px" }}>
              {/* <LevelInfo
                image={"egg.png"}
                currentLevel={"Eficiente"}
                coins={"2,500"}
                coinsToNextLevel={"400"}
                nextLevel={"Leyenda"}
              />
              <LevelStats levelStats={FACILITY.levelStats} /> */}
              <div className="map">
                {this.mapController.getMapContainer({ className: "map-view" })}
              </div>
            </div>
          </div>

          {/* <div
            style={{
              marginTop: 50,
              width: '100%',
              backgroundColor: 'red'
            }}
          >
            <NotificationConfigurationView
              handleChange={this.handleChange}
            />  
          </div> */}

          <div className="bottom-row">
            <div className="tabs">
              {/* <div
                className={
                  this.state.selected_tab === "empresa" ? "selected" : ""
                }
                onClick={this.switchTab}
              >
                <span id="empresa">EMPRESA</span>
              </div> */}
              <div
                className={
                  this.state.selected_tab === "alertas" ? "selected" : ""
                }
                onClick={this.switchTab}
              >
                <span id="alertas">ALERTAS</span>
              </div>
              <div
                className={
                  this.state.selected_tab === "notificaciones" ? "selected" : ""
                }
                onClick={this.switchTab}
              >
                <span id="notificaciones">NOTIFICACIONES</span>
              </div>
              <div
                className={
                  this.state.selected_tab === "etiquetas" ? "selected" : ""
                }
                onClick={this.switchTab}
              >
                <span id="etiquetas">
                  {" "}
                  {this.props.companyType === 1 ? "BASES" : "ETIQUETAS"}
                </span>
              </div>
              <div
                className={
                  this.state.selected_tab === "permisos" ? "selected" : ""
                }
                onClick={this.switchTab}
              >
                <span id="permisos">PERMISOS</span>
              </div>
              {this.props.companyType === 1 ? (
                ""
              ) : (
                <div
                  className={
                    this.state.selected_tab === "clases" ? "selected" : ""
                  }
                  onClick={this.switchTab}
                >
                  <span id="clases">CLASES</span>
                </div>
              )}
              {/* <div
                className={
                  this.state.selected_tab === "certificaciones"
                    ? "selected"
                    : ""
                }
                onClick={this.switchTab}
              >
                <span id="certificaciones">CERTIFICACIONES</span>
              </div> */}
              <div
                className={
                  this.state.selected_tab === "rfc" ? "selected" : ""
                }
                onClick={this.switchTab}
              >
                <span id="rfc">RFC</span>
              </div>
            </div>
            <div className="tab-content">{this.getTabContent()}</div>
          </div>
        </div>

        {/* <ManageFacilityUserModal
          showModal={false}
          notification={{}}
          closeAction={() => {}}
          handleChange={() => {}}
          facilitiesSelected={[6718, 6331]}
        /> */}

        {/* <CatalogSelectionFooter
          key={`catalog-footer`}
          show={false}
          selectedElements={1}
          actions={[
            {
              description: "Eliminar",
              f: () => {}
            }
          ]}
        /> */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  let company_info = getEntityItems(state, "COMPANIES.RETRIEVE");

  return {
    facilityId: state.facilityCatalog.selectedFacility,
    states: state.globalCatalog.states.items || [],
    cities: state.globalCatalog.cities.items || [],
    companyId: state.globalCatalog.session.company.id,
    companyType: state.globalCatalog.session.company.type.id,
    companyDefaultLogo: state.globalCatalog.session.company.type.avatar_image,
    isCompanyVerified: company_info.verification_status === 3
  };
};
const mapDispatchToProps = (dispatch) => {
  dispatch(loadSession());

  return {
    getCompanyData: (orbi_id) => dispatch(getCompanyData({ orbi_id })),
    loadStates: (country) => dispatch(loadStates(country)),
    loadCities: (state) => dispatch(loadCities(state)),
  };
};
const connectToStore = connect(mapStateToProps, mapDispatchToProps);

export default withRouter(connectToStore(CompanyProfile));

// const FACILITY = {
//   code: "FG0012",
//   name: "Grupo Modelo",
//   alias: "Stark Industries",
//   avatar: { avatar_image: "/images/factory-bckgrnd.png" },
//   address: "Av Mariano Otero 663",
//   city: "Guadalajara",
//   state: "Jalisco",
//   country: "Mexico",
//   zip_code: 44909,
//   tags: [
//     { tag: { title: "Grupo 134" } },
//     { tag: { title: "Grupo 42" } },
//     { tag: { title: "Grupo 13" } },
//   ],
//   web_site: "www.starkindustries.com",
//   users: { admin: 124, warehouser: 248 },
//   levelStats: {
//     orbiPoints: 229,
//     stats: [
//       {
//         name: "Adopción",
//         percentage: 50,
//         points: 55,
//       },
//       {
//         name: "Calidad de información",
//         percentage: 70,
//         points: 42,
//       },
//       {
//         name: "Tiempo de atención",
//         percentage: 80,
//         points: 66,
//       },
//       {
//         name: "Cumplimiento de citas",
//         percentage: 60,
//         points: 44,
//       },
//       {
//         name: "Productividad/Utilización",
//         percentage: 50,
//         points: 22,
//       },
//     ],
//   },
// };
