import styled from "styled-components";

export const RequisitionText = styled.div`
  width: 100%;
  height: 55px;
  display: flex;
  justify-content: center;
`;

export const RequisitionCenter = styled.div`
  width: 100%;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const RequisitionLeft = styled.div`
  width: 100%;
  height: 55px;
  display: flex;
  justify-content: start;
`;

export const RequisitionDetailText = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
`;

export const RequisitionDetailCenter = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const RequisitionDetailLeft = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: start;
  align-items: center;
`;