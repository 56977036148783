import React from "react";
import ReactDOM from "react-dom";

import { Provider } from "react-redux";
import store from "./redux/store";
import { routes } from "./router.jsx";

import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import "./styles/theme.scss";
import "./styles/style.scss";

import { LicenseManager } from "ag-grid-enterprise";

let CryptoJS = require("crypto-js");

var unit_test_deprecated = CryptoJS.AES.decrypt(window.UNIT_TEST_DEPRECATED || '', "ICj9y1GOWUtxI526x237dde7AkXphbVRizEjPGolBpNEKGM9ZT");
var unit_test_deprecated_txt = unit_test_deprecated.toString(CryptoJS.enc.Utf8);

LicenseManager.setLicenseKey(unit_test_deprecated_txt);

ReactDOM.render(
  <DndProvider backend={HTML5Backend}>
    <Provider store={store}>{routes}</Provider>
  </DndProvider>,
  document.getElementById("root")
);
