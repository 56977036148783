import React, { useState, useMemo, useCallback, createRef } from "react";
import moment from 'moment';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { cloneDeep } from "lodash";
import { AG_GRID_LOCALE_ES } from "../shared/language";
import Button from "../shared/controls/button";

const filterParams = {
  comparator: (filterLocalDateAtMidnight, cellValue) => {
    const dateAsString = cellValue;
    if (dateAsString == null) return -1;

    let cellDate2 = moment.utc(dateAsString);
    let cellDate = new Date(
      Number(cellDate2.year()),
      Number(cellDate2.month()),
      Number(cellDate2.date()),
    );

    if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
      return 0;
    }

    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    }

    if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    }

    return 0;
  }
};

const getCellView = (key, params, props) => {
  let view = null;

  switch(key) {
    case 'verification_status_description':

      if (props.tab === 3 && params.data.verification_status === 1) {
        view = <div style={{display: 'flex', height: 45, alignItems: 'center'}}>
          <Button
            text={'Aceptar'}
            type='primary small'
            onClick={() => {
              props.handleChange({
                target: {
                  name: "accept-company",
                  value: params.data
                }
              });

            }}
          />

          <div style={{width: 10}}/>

          <Button
            text={'Cancelar'}
            type='secondary small'
            onClick={() => {
              props.handleChange({
                target: {
                  name: "cancel-company",
                  value: params.data
                }
              });
            }}
          />
        </div>
      } else {
        view = <div>{params.value}</div>;
      }
    break;
    case 'verification_csf':
      view = params.value === null ? <div/> : <div 
        style={{color: 'blue', textDecoration: 'underline', cursor: 'pointer', width: 'fit-content'}}
        onClick={() => {
          if (params.value === null) return;
          props.handleChange({
            target: {
              name: "open-url",
              value: params.value
            }
          });
        }}
      >{'Abrir'}</div>
    break;
    case 'created':
    case 'verification_date':
    case 'recommendation_sent':
      view = <div>
        { params.value !== null ? moment(params.value).format('DD/MM/YYYY HH:mm') : ''}
      </div>
      break;
    case 'answers':
      view = params.value === null ? <div/> : <div 
        style={{color: 'blue', textDecoration: 'underline', cursor: 'pointer', width: 'fit-content'}}
        onClick={() => {
          props.handleChange({
            target: {
              name: "open-url",
              value: params.value
            }
          });
        }}
      >{'Respuestas'}</div>
      break;
    default:
      view = <div></div>;
      break;
  }

  return view;
}

const getCorrectName = (field, default_columns) => {
  let column = default_columns.filter( item => item.field === field);
  return (column && column.length > 0) ? column[0].headerName : null;
}

const getColumnsWithCustomCells = (columns, default_columns, props) => {

  columns.map( (item, index) => {

    let new_header_name = getCorrectName(item.field, default_columns);
    if (new_header_name !== null) {
      columns[index].headerName = new_header_name;
    }

    if (['recommendation_sent', 'created', 'answers', 'verification_date', 'verification_csf'].includes(item.field)) {
      columns[index].cellRenderer = params => {
        return getCellView(item.field, params, props)
      }
    }

  })

  return columns;
}

const applyNewColumns = (temp_colDefs, props) => {

  if (props.configuration['columns'] !== undefined && typeof props.configuration['columns'] === "object") {

    let temp_columns = props.configuration['columns'];

    if (temp_columns.length === temp_colDefs.length) {
      return getColumnsWithCustomCells(temp_columns, temp_colDefs, props);
    }

    try {

      let currentColumns = temp_colDefs.map( item => item.field);
      let currentColumnsSaved = props.configuration['columns'].map( item => item.field);
      let difference = currentColumns.filter(x => !currentColumnsSaved.includes(x));

      let newColumnsToAdd = [];

      temp_colDefs.map( (item, index) => {
        if (difference.includes(item.field)) {
          newColumnsToAdd.push({
            index,
            column: item
          })
        }
      });

      newColumnsToAdd.map( item => {
        temp_columns.splice(item.index, 0, item.column);
      });

      return getColumnsWithCustomCells(temp_columns, temp_colDefs, props);

    } catch (error) {
      console.error('Error setting columns definitions', error);

      return getColumnsWithCustomCells(temp_columns, temp_colDefs, props);
    }
  }
}

export const CrmTableView = (props) => {

  const gridRef = createRef();
  const [rowData, setRowData] = useState(props.rowData);
  const [called, setCalled] = useState(false);

  let default_columns = [];

  if (props.tab === 2) {
    default_columns = [
      { headerName: 'Fecha', field: 'recommendation_sent', minWidth: 270, filter: 'agDateColumnFilter', filterParams: filterParams, suppressMenu: true, sortable: true,
        cellRenderer: params => {
          return getCellView('recommendation_sent', params)
        }
      },
      { headerName: 'Recomendado', field: 'recommendated_email', suppressMenu: true, filter: 'agTextColumnFilter', minWidth: 270, sortable: true },
      { headerName: 'Promotor', field: 'promoter_fullname', suppressMenu: true, filter: 'agTextColumnFilter', minWidth: 270, sortable: true },
      { headerName: 'Usuario', field: 'promoter_email', suppressMenu: true, filter: 'agTextColumnFilter', minWidth: 270, sortable: true },
      { headerName: 'Compañía', field: 'promoter_company_orbi_id', suppressMenu: true, filter: 'agTextColumnFilter', minWidth: 270, sortable: true },
      { headerName: 'Razon social', field: 'promoter_company_corporation_name', suppressMenu: true, filter: 'agTextColumnFilter', minWidth: 270, sortable: true },
      // { headerName: 'Ejecutivo', field: 'sales_agent_name', suppressMenu: true, filter: 'agTextColumnFilter', minWidth: 270, sortable: true }
    ];
  } else if (props.tab === 1) {
    default_columns = [
      { headerName: 'Fecha', field: 'created', minWidth: 270, filter: 'agDateColumnFilter', filterParams: filterParams, suppressMenu: true, sortable: true, 
        cellRenderer: params => {
          return getCellView('created', params)
        }
      },
      { headerName: 'Nombre', field: 'name', suppressMenu: true, filter: 'agTextColumnFilter', minWidth: 270, sortable: true },
      { headerName: 'Empresa', field: 'company_name', suppressMenu: true, filter: 'agTextColumnFilter', minWidth: 270, sortable: true },
      { headerName: 'Correo', field: 'email', suppressMenu: true, filter: 'agTextColumnFilter', minWidth: 270, sortable: true },
      { headerName: 'Celular', field: 'mobile_phone', suppressMenu: true, filter: 'agTextColumnFilter', minWidth: 270, sortable: true },
      { headerName: 'Medio de contacto', field: 'contact_type', suppressMenu: true, filter: 'agTextColumnFilter', minWidth: 270, sortable: true },
      { headerName: 'Tipo de empresa', field: 'company_type', suppressMenu: true, filter: 'agTextColumnFilter', minWidth: 270, sortable: true },
      { headerName: 'Respuestas', field: 'answers', suppressMenu: true, filter: 'agTextColumnFilter', minWidth: 270, sortable: true,
        cellRenderer: params => {
          return getCellView('answers', params, props)
        }
      },
      { headerName: 'Agente Comercial', field: 'sales_agent_name', suppressMenu: true, filter: 'agTextColumnFilter', minWidth: 270, sortable: true }
    ];
  } else if ([3,4,5].includes(props.tab)) {
    default_columns = [
      { headerName: 'Compañía', field: 'orbi_id', suppressMenu: true, filter: 'agTextColumnFilter', minWidth: 270, sortable: true },
      { headerName: 'Razon social', field: 'corporation_name', suppressMenu: true, filter: 'agTextColumnFilter', minWidth: 270, sortable: true },
      { headerName: 'Código postal', field: 'postalcode', suppressMenu: true, filter: 'agTextColumnFilter', minWidth: 270, sortable: true },
      { headerName: 'Agente Comercial', field: 'commercial_agent', suppressMenu: true, filter: 'agTextColumnFilter', minWidth: 270, sortable: true },
      // { headerName: 'Ejecutivo', field: 'sales_agent_name', suppressMenu: true, filter: 'agTextColumnFilter', minWidth: 270, sortable: true },
      { headerName: 'Fecha', field: 'verification_date', minWidth: 270, filter: 'agDateColumnFilter', filterParams: filterParams, suppressMenu: true, sortable: true,
        cellRenderer: params => {
          return getCellView('verification_date', params)
        }
      },
      { headerName: 'CSF', field: 'verification_csf', suppressMenu: true, filter: 'agTextColumnFilter', minWidth: 270, sortable: true,
        cellRenderer: params => {
          return getCellView('verification_csf', params, props)
        }
      },
      { headerName: 'Estatus', field: 'verification_status_description', suppressMenu: true, filter: 'agTextColumnFilter', minWidth: 350, sortable: true,
        cellRenderer: params => {
          return getCellView('verification_status_description', params, props)
        }
      },
      
    ];
    

  }
  
  let new_def = applyNewColumns(cloneDeep(default_columns), props);
  
  if (new_def && new_def.length > 0) {
    default_columns = new_def;
  }
    
  const [colDefs, setColDefs] = useState(default_columns);

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      floatingFilter: true,
    };
  }, []);

  const autoGroupColumnDef = useMemo(() => {
    return {
      minWidth: 200,
      pinned: "left",
    };
  }, []);

  const updateAggridConf = (params) => {
    if (called) {
      let config = {
        columns: params.api.getColumnDefs(),
        filter: params.api.getFilterModel()
      };

      if (props.setNewFilter) {
        props.setNewFilter(config);
      }
    }
  };

  const onGridReady = useCallback((params) => {

    if (props.allSelected) {
      params.api.selectAll()
    }

    setCalled(true);

  }, []);

  return (
    <div>
      <div style={{
        display: 'flex',
        justifyContent: 'end'
      }}>
        <Button
          text={'Resetear filtros'}
          type='primary small'
          onClick={() => {
            if (gridRef && gridRef.current && gridRef.current.api)
              gridRef.current.api.setFilterModel(null);
          }}
        />
      </div>
      <div
        className="ag-theme-alpine"
        style={{ height: 650, marginTop: 20, marginBottom: 50 }}
      >
        <AgGridReact
          ref={gridRef}
          rowData={rowData}
          columnDefs={colDefs}
          enableCellTextSelection={true}
          suppressRowClickSelection={true}
          rowHeight={45}
          rowSelection="multiple"
          localeText={AG_GRID_LOCALE_ES}
          defaultColDef={defaultColDef}
          onCellClicked={ params => {
            if (props.isModeSelection) return;

            props.handleChange({
              target: {
                name: "requisition_detail",
                value: params.data
              }
            });
          }}
          onSelectionChanged ={ params => {
            props.handleChange({
              target: {
                name: "requisitions_selected",
                value: (gridRef?.current?.api?.getSelectedRows() || []).map( item => item.requisition_id)
              }
            });
          }}
          onGridReady={onGridReady}
          gridOptions={{
            sideBar: {
              toolPanels: ['columns', 'filters'],
              position: 'right',
              defaultToolPanel: '',
            },
          }}

          groupDisplayType={"groupRows"}
          rowGroupPanelShow={"always"}
          autoGroupColumnDef={autoGroupColumnDef}
          enableCharts={true}
          enableRangeSelection={true}
          onFilterChanged={ params => {
            updateAggridConf(params);
          }}
          onColumnMoved={ params => {
            if (params.finished) {
              updateAggridConf(params);
            }
          }}
          onColumnVisible={ params => {
            updateAggridConf(params);
          }}
        />
      </div>
    </div>
  );
}