import React from "react";
import { connect } from "react-redux";
import AlertList from "../../shared/component/alert-list";
import FacilityUsers from "./facility-users";
import FacilityGeofences from "./facility-geofences";
import NotificationList from "../../shared/component/notification-list";
import { withRouter } from "react-router-dom";
import { loadFacility } from "../../redux/reducers/facility-reducer";
import { BACKEND } from "../../shared/backend-api";
import ExceedPlus from "../../shared/controls/exceed-plus";
import Tag from "../../shared/controls/tag";
import FacilitySchedulesProfile from "./facility-schedules-profile";
import FacilitySchedules from "./facility-schedules";
import ValidatePerms from "../../shared/component/validate-perms";
import SubStringText from "../../shared/component/substring-text";
import { MapController } from "../../shared/component/map-container";
import RfcList from '../../shared/component/rfc-list';

// const TABS = {
//   PERMISOS: 'PERMISOS',
//   ALERTAS: 'ALERTAS',
//   NOTIFICACIONES: 'NOTIFICACIONES',
//   USUARIOS: 'USUARIOS',
//   GEOCERCAS: 'GEOCERCAS',
//   VENTANAS: 'VENTANAS',
// }

class FacilityProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // selected_tab: 'ventanas',
      selected_tab: "geocercas",
      scheduleEdit: false,
      marker: null,
    };

    this.mapController = new MapController();
  }

  componentDidMount() {
    this.mapController.loadMap(null, null, null, "roadmap", true);
    if (this.props.match.params.id)
      this.props.loadFacility(this.props.match.params.id);
    const urlParams = new URLSearchParams(window.location.search);
    const schedules = urlParams.get("schedules");
    if (schedules)
      setTimeout(() => this.setState({ selected_tab: "ventanas" }), 500);
    this.mapController.isReadySubject.subscribe((ready) => {
      // console.log("yxed");
      // console.log(ready);
      // if (ready) {
      //   let icon = this.props.image;
      //   // console.log(icon);
      //   // console.log(this.props.position);
      //   let latLng = {
      //     lat: this.props.position.x,
      //     lng: this.props.position.y,
      //   };
      //   this.setState({
      //     marker: this.mapController.setMarkerImage(icon, latLng, {
      //       width: 25,
      //       height: 25,
      //     }),
      //   });

      //   this.state.marker.setMap(this.mapController.map);

      //   this.mapController.setCenterMap(latLng);
      // }
    });
  }

  componentDidUpdate(prevProps) {
    if((prevProps.position?.x || 0) !== (this.props.position?.x || 0)){
      // debugger;
      if(this.mapController.map){
        this.paintFacilityonMap()
      } else {
        this.mapController.isReadySubject.subscribe((ready) => {
          if (ready) {
            this.paintFacilityonMap()
          }
        });
      }
    }
  }

  paintFacilityonMap = () => {
    let icon = this.props.image;
    // console.log(icon);
    // console.log(this.props.position);
    // debugger;
    let latLng = {
      lat: this.props.position.x,
      lng: this.props.position.y,
    };
    if(this.state.marker){
      this.state.marker.setMap(null);
    }
    this.setState({
      marker: this.mapController.setMarkerImage(icon, latLng, {
        width: 25,
        height: 25,
      }),
    }, () => {
      this.state.marker.setMap(this.mapController.map);
    });
    this.mapController.setCenterMap(latLng);
  }

  switchTab = (e) => {
    if (e.target.id) {
      this.setState({ selected_tab: e.target.id });
    }
  };
  getTabContent = () => {
    if (this.props.facility.id === undefined) {
      return '';
    }

    switch (this.state.selected_tab) {
      // case 'permisos':
      //   return <PermissionsTable facility={this.props.facility.id} />;
      case "alertas":
        return <AlertList facilityId={this.props.facility.id} />;
      case "notificaciones":
        return <NotificationList facilityId={this.props.facility.id} />;
      case "usuarios":
        return <FacilityUsers facilityId={this.props.facility.id} />;
      case "geocercas":
        return <FacilityGeofences facility={this.props.facility} edit={true} />;
      case "ventanas":
        return this.state.scheduleEdit ? ( 
          <FacilitySchedules
            facilityId={this.props.facility.id}
            switchEditView={this.switchScheduleEditView}
            defaultMinutes={this.props.facility.default_operation_minutes}
            editMode={true}
          />
        ) : (
          <FacilitySchedulesProfile
            facilityId={this.props.facility.id}
            switchEditView={this.switchScheduleEditView}
            defaultMinutes={this.props.facility.default_operation_minutes}
          />
        );
      case "rfc": return <RfcList facilityId={this.props.facility.id}/> 

      // <img alt="" src="/images/calendario-mockup.png" style={{ "width": "100%" }} />;
      default:
        return <div />;
    }
  };

  switchScheduleEditView = (editMode) => {
    this.setState({ scheduleEdit: editMode });
  };

  render() {
    return (
      <div className="facility-profile view">
        <div className="top-row">
          <div className="facility-info">
            <div className="avatar-container">
              <img alt="" src={this.props.image} className="avatar" />
            </div>
            <SubStringText
              label={`ID: ${
                this.props.facility.code === undefined
                  ? ""
                  : this.props.facility.code
              }`}
              labelTag={"p"}
              labelSize={22}
              labelClass={"code"}
              tooltipClass={"tooltip-class-name"}
            />

            <p className="name">{this.props.facility.name}</p>
            <p className="alias">{this.props.facility.alias}</p>
            <ValidatePerms
              perms={[
                {
                  id: "29",
                  perm: "u",
                },
              ]}
            >
              <img
                alt=""
                src="/images/edit-transparent.svg"
                className="edit-icon"
                onClick={() =>
                  this.props.history.push(`/company/facility/edit`, {
                    facility: this.props.facility,
                  })
                }
              />
            </ValidatePerms>
            <div className="darker">
              <div className="facility-location">
                <img
                  alt=""
                  src="/images/location-pin.svg"
                  className="location-pin"
                />
                {this.props.facility.address || ""}
                {', '}
                {this.props.facility?.data?.city?.descripcion || ''}
                {', '}
                {this.props.facility?.data?.state?.descripcion || ''}
                <br />
                {this.props.facility.zip_code || ""}
              </div>
              <div className="facility-tags">
                Etiquetas: <br />
                <ExceedPlus name="tags" maxVisibleItems={2} className="wrap">
                  {(this.props.facility.tags || []).map((tag) => {
                    return (
                      <Tag
                        key={tag.id}
                        id={tag.id}
                        title={tag.title}
                        color={tag.color}
                      />
                    );
                  })}
                </ExceedPlus>
              </div>
            </div>
            <p className="facility-website">{this.props.facility.web_site}</p>
            <div className="facility-users">
              <img alt="" src="/images/user-icon-blue.svg" className="admin" />
              {this.props.facility.count_admin}
              <img
                alt=""
                src="/images/user-icon-golden.svg"
                className="warehouser"
              />
              {this.props.facility.count_user}
            </div>
          </div>
          <div className="facility-orbi-levels">
            <div className="map">
              {this.mapController.getMapContainer({
                className: "map-view-facility",
              })}
            </div>
            {/* <div className="facility-level">
              <div className="facility-level-info">
                <img alt="" src="/images/egg.png" />
                <div>
                  <p>
                    Nivel 4: <span className="current-level">Eficiente</span>
                  </p>
                  <p>
                    <img alt="" src="/images/orbicoin.png" className="coin" />{" "}
                    2,500
                  </p>
                </div>
              </div>
              <div className="progress-bar">
                <div className="checkpoint"></div>
                <div className="checkpoint"></div>
                <div className="checkpoint"></div>
                <div className="checkpoint"></div>
                <div className="complete"></div>
                <div className="missing"></div>
              </div>
              <p>
                400 OC para ser <span className="next-level">Leyenda</span>
              </p>
            </div>
            <div className="facility-level-stats">
              <div className="selector">
                <span>LAST WEEK</span>
                <span className="selected">THIS WEEK</span>
              </div>
              <div className="stats">
                <div className="row">
                  <div className="stat-name">Adopción</div>
                  <div className="progress-bar">
                    <span className="percentage">70%</span>
                    <div className="complete"></div>
                    <div className="missing"></div>
                  </div>
                  <div className="points">42</div>
                </div>
                <div className="row">
                  <div className="stat-name">Calidad de información</div>
                  <div className="progress-bar">
                    <span className="percentage">70%</span>
                    <div className="complete"></div>
                    <div className="missing"></div>
                  </div>
                  <div className="points">42</div>
                </div>
                <div className="row">
                  <div className="stat-name">Tiempo de atención</div>
                  <div className="progress-bar">
                    <span className="percentage">70%</span>
                    <div className="complete"></div>
                    <div className="missing"></div>
                  </div>
                  <div className="points">42</div>
                </div>
                <div className="row">
                  <div className="stat-name">Cumplimiento de citas</div>
                  <div className="progress-bar">
                    <span className="percentage">70%</span>
                    <div className="complete"></div>
                    <div className="missing"></div>
                  </div>
                  <div className="points">42</div>
                </div>
                <div className="row">
                  <div className="stat-name">Productividad/Utilización</div>
                  <div className="progress-bar">
                    <span className="percentage">70%</span>
                    <div className="complete"></div>
                    <div className="missing"></div>
                  </div>
                  <div className="points">42</div>
                </div>
              </div>
              <div className="orbi-points">
                Orbipoints: <img alt="" src="/images/orbicoin.png" /> 210
              </div>
            </div> */}
          </div>
        </div>
        <div className="bottom-row">
          <div className="tabs">
            {/* <div
              className={
                this.state.selected_tab === "usuarios" ? "selected" : ""
              }
              onClick={this.switchTab}
            >
              <span id="usuarios">USUARIOS</span> 
            </div> */}
            <div
              className={
                this.state.selected_tab === "geocercas" ? "selected" : ""
              }
              onClick={this.switchTab}
            >
              <span id="geocercas">GEOCERCAS</span>
            </div>
            <div
              className={
                this.state.selected_tab === "ventanas" ? "selected" : ""
              }
              onClick={this.switchTab}
            > 
              <span id="ventanas">VENTANAS</span>
            </div>
            {/* <div
              className={
                this.state.selected_tab === "alertas" ? "selected" : ""
              }
              onClick={this.switchTab}
            >
              <span id="alertas">ALERTAS</span>
            </div> */}
            {/* <div
              className={
                this.state.selected_tab === "notificaciones" ? "selected" : ""
              }
              onClick={this.switchTab}
            >
              <span id="notificaciones">NOTIFICACIONES</span>
            </div> */}
            <div
              className={
                this.state.selected_tab === "rfc" ? "selected" : ""
              }
              onClick={this.switchTab}
            > 
              <span id="rfc">RFC</span>
            </div>
          </div>
          <div className="tab-content">{this.getTabContent()}</div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { avatar, geopos_x, geopos_y } =
    state.facilityReducer.facility.data || {};
  const image = avatar
    ? BACKEND.FILES_SERVER.concat(avatar.image)
    : "/images/factory-bckgrnd.png";
  const position = {
    x: geopos_x,
    y: geopos_y,
  };
  return {
    image,
    // facility: Object.assign({}, FACILITY, state.facilityReducer.facility.data, { avatar: { image: image } })
    facility: Object.assign({}, FACILITY, state.facilityReducer.facility.data, {
      avatar: (avatar || {}).id || 0,
    }),
    position,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    loadFacility: (facilityId) => dispatch(loadFacility(facilityId)),
  };
};
const connectToStore = connect(mapStateToProps, mapDispatchToProps);

export default withRouter(connectToStore(FacilityProfile));

const FACILITY = {
  facilities_tags: [],
  address: "",
  country: "",
  city: "",
  state: "",
  geolocation: "",
};
