import React, { Component } from "react";
import { getUrlsEnv } from "../backend-api";

let filesPath = getUrlsEnv().files;

class NormativityLogItem extends Component {
  getAvatar = props => {
    if (props.image !== null && props.image !== "") {
      return filesPath.concat(props.image);
    } else {
      return "/" + props.avatar;
    }
  };

  render() {
    return (
      <div className={"normativity-log-item"}>
        <div className="icon-image-container">
          <img
            className={"company-logo"}
            src={this.getAvatar(this.props)}
            alt={""}
            onError={e => {
              e.target.onerror = null;
              e.target.src = "/images/factory-bckgrnd.png";
            }}
          />
          <img
            className={"status-icon"}
            src={this.props.statusIcon}
            alt={""}
            onError={e => {
              e.target.onerror = null;
              e.target.src = "/images/factory-bckgrnd.png";
            }}
          />
        </div>

        <div className={"description-container"}>
          <div className={"company"}>{this.props.responsible}</div>
          <div className={"details"}>{this.props.details}</div>
        </div>
      </div>
    );
  }
}

export default NormativityLogItem;