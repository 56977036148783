//@flow
import * as React from "react";
// import PanelCollapsible from "../../../shared/component/panel-collapsible";
import styled from "styled-components";
import TableD from "../../../shared/component/table-d";
import type TableDConfig from "../../../shared/component/table-d";
import * as Types from "../types";
import AssembliesAvailabilityRow from "./assemblies-availability-row";
import ContractAvailabilityRow from "./contract-availability-row";
import ProviderAvailabilityRow from "./provider-availability-row";
import TabSelectorRibbon from "../../../shared/controls/tab-selector-ribbon";
import FilterBarCompact from "../../../shared/component/filter/filter-bar-compact";
import { normalizeStr } from "../../../shared/utils";

type Props = {
  trip: Types.UnassignedShipmentRowType,
  FleetProviders: Types.BookingFleetProviderRow[],
  assemblies: Types.BookingAssemblyRow[],
  getContracts: () => void,
  contracts: any[],
  getProviders: () => void,
  providers: any[],
};

type State = {
  selectedTab: number,
};

class ShipmentReserveDialogContent extends React.Component<Props, State> {
  state = { 
    selectedTab: 3,
    searchStr: "",
  };
  static defaultProps = {
    fleetProviders: [],
    assemblies: [],
    contracts: [],
  };

  switchTab = (tabNumber) => {
    switch (tabNumber) {
      case 2:
        this.props.getContracts();
        break;
      case 3:
        this.props.getProviders();
        break;
      default:
    }

    this.setState({
      selectedTab: tabNumber,
    });
  };
  onSearch = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }
  render() {
    return (
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div>
          <TablePanelHeader>
            <div>
              <TabSelectorRibbonStyled
                textColor={'black'}
                activeId={this.state.selectedTab}
                items={TAB_ITEMS}
                onChange={(a) =>
                  this.switchTab(parseInt(a.target.attributes["name"].value))
                }
                bgColor="none"
              />
            </div>
            <FilterBarCompact searchOnly={true} searchAction={this.onSearch} searchStr={this.state.searchStr}/>
          </TablePanelHeader>
          {this.state.selectedTab === 4 && (
            <TableD tableConfig={AssembleTableConfig}>
              {this.props.assemblies.filter(a => (
                normalizeStr(a.fleetProvider?.name).includes(normalizeStr(this.state.searchStr))
                || normalizeStr(a.truck?.name).includes(normalizeStr(this.state.searchStr))
                || normalizeStr(a.expClass).includes(normalizeStr(this.state.searchStr))
                || (a.contract && normalizeStr(a.contract.facility?.name).includes(normalizeStr(this.state.searchStr)))
                || normalizeStr(a.ticketNumber).includes(normalizeStr(this.state.searchStr))
                || normalizeStr(a.expClass).includes(normalizeStr(this.state.searchStr))
                // || (a.availabilityDate && normalizeStr((new Date(a.availabilityDate)).toLocaleString("es-MX")).includes(normalizeStr(this.state.searchStr)))
              )).map((f, index) => (
                <AssembliesAvailabilityRow {...f} key={index} />
              ))}
            </TableD>
          )}
          {this.state.selectedTab === 2 && (
            <TableD tableConfig={ContractTableConfig}>
              {this.props.contracts.filter(c => (
                  normalizeStr(c.fleet_provider.alias).includes(normalizeStr(this.state.searchStr))
                  || c.contract_classes.some(cC => (normalizeStr(cC.code).includes(normalizeStr(this.state.searchStr)))) 
                  || normalizeStr(c.slots.toString()).includes(normalizeStr(this.state.searchStr))
                  || normalizeStr(c.available.toString()).includes(normalizeStr(this.state.searchStr))
                  || normalizeStr(c.in_transit.toString()).includes(normalizeStr(this.state.searchStr))
                  || normalizeStr(c.out_of_service.toString()).includes(normalizeStr(this.state.searchStr))
                )).map((c, index) => (
                <ContractAvailabilityRow {...c} key={index} />
              ))}
            </TableD>
          )}
          {this.state.selectedTab === 3 && (
            <TableD tableConfig={ProviderTableConfig}>
              {this.props.providers.filter(p => ( p.fleet_provider.type_id === 1 &&
                (normalizeStr(p.fleet_provider.alias).includes(normalizeStr(this.state.searchStr))
                || normalizeStr(p.fleet_provider.orbi_id).includes(normalizeStr(this.state.searchStr)))
              )).map((f, index) => (
                <ProviderAvailabilityRow {...f} key={index} />
              ))}
            </TableD>
          )}
        </div>
        <OrdersContainer>
          <OrdersContainerTitle>{`Viaje No: ${this.props.trip.tripNumber}`}</OrdersContainerTitle>
          {this.props.trip.details.map(
            (o: Types.UnassignedShipmentRowDetailType, index, a) => { return(
              <Order key={index}>
                <OrderTitle>{`Folio: ${o.ticket}`}</OrderTitle>
                <OrderDataContainer>
                  <OrderData>
                    <OrderLocation>{o.origin.name}</OrderLocation>
                    {/* {o.origin.date?.toUTCString().substring(4, 16)} */}
                    {o.origin.date
                      ? o.origin.date.toUTCString().substring(4, 16)
                      : "Pendiente"}
                  </OrderData>
                  <OrderConnector inner={index < a.length - 1} />
                  <OrderData>
                    <OrderLocation>{o.destination.name}</OrderLocation>
                    {o.destination.date
                      ? o.destination.date.toUTCString().substring(4, 16)
                      : "Pendiente"}
                  </OrderData>
                </OrderDataContainer>
              </Order>
            )}
          )}
        </OrdersContainer>
      </div>
    );
  }
}

export default ShipmentReserveDialogContent;

const TablePanelHeader = styled.div`
  display: flex;
  background-color: rgba(152, 172, 193, 0.11);
  height: 60px;
  width: 979px;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
`;

const TabSelectorRibbonStyled = styled(TabSelectorRibbon)`
  & > div {
    color: black;
  }
  box-shadow: none;
`;

// const PanelCollapsibleStyled = styled(PanelCollapsible)`
//   width: 979px;
//   & .header {
//     margin: 0 10px;
//   }
// `;

const OrdersContainer = styled.div`
  margin-left: 35px;
`;

const OrdersContainerTitle = styled.div`
  opacity: 0.8;
  color: #252325;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 31px;
  text-align: center;
`;

const Order = styled.div`
  width: 240px;
  border-radius: 12px;
  background-color: rgb(152, 172, 193, 0.14);
  margin: 30px 20px;
  padding: 5px;
`;

const OrderConnector = styled.div`
  position: relative;
  display: ${({ inner }) => (inner ? "block" : "none")};
  width: 0;
  bottom: -46px;
  left: 20px;
  height: 20px;
  border: dashed 2px #5e6e8f;
  &::before {
    position: relative;
    top: -5px;
    left: -7;
    content: "";
    display: inline-block;
    width: 15px;
    height: 15px;
    -moz-border-radius: 7.5px;
    -webkit-border-radius: 7.5px;
    border-radius: 7.5px;
    background-color: #5e6e8f;
  }
  &::after {
    position: relative;
    top: 5px;
    left: -7;
    content: "";
    display: inline-block;
    width: 15px;
    height: 15px;
    -moz-border-radius: 7.5px;
    -webkit-border-radius: 7.5px;
    border-radius: 7.5px;
    background-color: #5e6e8f;
  }
`;

const OrderTitle = styled.div`
  color: #2f3b52;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 22px;
`;

const OrderDataContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
`;
const OrderData = styled.div`
  color: #3c4453;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 18px;
`;
const OrderLocation = styled.div`
  color: #2f3b52;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 25px;
`;

const cellStyle = {
  justifyContent: "center",
};

const headerItemStyle = {
  textAlign: "center",
};

// const TableHeaderCollapsible = {
//   backgroundColor: "rgba(152, 172, 193,0.11)",
//   color: "#252325",
//   fontSize: "20px",
//   fontWeight: "bold",
//   letterSpacing: "0",
//   lineHeight: "31px",
// };

const AssembleTableConfig: TableDConfig = {
  headerStyle: {
    height: "53px",
    backgroundColor: "rgba(152, 172, 193,0.14)",
    color: "#252325",
    fontSize: "14px",
    fontWeight: "bold",
    letterSpacing: "0",
    lineHeight: "22px",
    borderTop: "solid 1px #979797",
  },
  contentStyle: {
    maxHeight: "150px",
    borderBottom: "solid 1px #979797",
    overflow: "auto",
  },
  rowStyle: {
    hover: {
      backgroundColor: "rgba(255,247,142,0.25)",
    },
    selected: {
      backgroundColor: "rgba(255,247,142,0.8)",
    },
  },
  columns: [
    {
      text: "",
      width: "3%",
      cellStyle: { ...cellStyle, margin: "10px 0px" },
      headerItemStyle,
    },
    {
      text: "Linea transportista",
      width: "15%",
      cellStyle: { ...cellStyle, justifyContent: "flex-start" },
      headerItemStyle,
    },
    {
      text: "Camión",
      width: "12%",
      cellStyle,
      headerItemStyle,
    },
    {
      text: "Maniobra / operación",
      width: "10%",
      cellStyle,
      headerItemStyle,
    },
    {
      text: "Etiquetas",
      width: "10%",
      cellStyle,
      headerItemStyle,
    },
    {
      text: "Clase de expedición",
      width: "9%",
      cellStyle,
      headerItemStyle,
    },
    {
      text: "Contrato",
      width: "9%",
      cellStyle,
      headerItemStyle,
    },
    {
      text: "Folio",
      width: "10%",
      cellStyle,
      headerItemStyle,
    },
    {
      text: "Cita / disponible",
      width: "17%",
      cellStyle,
      headerItemStyle,
    },
    {
      text: "Alerta",
      width: "5%",
      cellStyle,
      headerItemStyle,
    },
  ],
};

const ContractTableConfig: TableDConfig = {
  headerStyle: {
    height: "53px",
    backgroundColor: "rgba(152, 172, 193,0.14)",
    color: "#252325",
    fontSize: "14px",
    fontWeight: "bold",
    letterSpacing: "0",
    lineHeight: "22px",
    borderTop: "solid 1px #979797",
  },
  contentStyle: {
    maxHeight: "150px",
    borderBottom: "solid 1px #979797",
    overflow: "auto",
  },
  rowStyle: {
    hover: {
      backgroundColor: "rgba(255,247,142,0.25)",
    },
    selected: {
      backgroundColor: "rgba(255,247,142,0.8)",
    },
  },
  columns: [
    {
      text: "",
      width: "3%",
      cellStyle: { ...cellStyle, margin: "6px 0px" },
      headerItemStyle,
    },
    {
      text: "Linea transportista",
      width: "17%",
      cellStyle: { ...cellStyle, justifyContent: "flex-start" },
      headerItemStyle,
    },
    {
      text: "Expedición",
      width: "16%",
      cellStyle,
      headerItemStyle,
    },
    {
      text: "Contratos",
      width: "16%",
      cellStyle,
      headerItemStyle,
    },
    {
      text: "Disponible",
      width: "16%",
      cellStyle,
      headerItemStyle,
    },
    {
      text: "En viaje",
      width: "16%",
      cellStyle,
      headerItemStyle,
    },
    {
      text: "Fuera de operación",
      width: "16%",
      cellStyle,
      headerItemStyle,
    },
  ],
};

const ProviderTableConfig: TableDConfig = {
  headerStyle: {
    height: "53px",
    backgroundColor: "rgba(152, 172, 193,0.14)",
    color: "#252325",
    fontSize: "14px",
    fontWeight: "bold",
    letterSpacing: "0",
    lineHeight: "22px",
    borderTop: "solid 1px #979797",
  },
  contentStyle: {
    maxHeight: "150px",
    borderBottom: "solid 1px #979797",
    overflow: "auto",
  },
  columns: [
    {
      text: "",
      width: "5%",
      cellStyle: { ...cellStyle, margin: "6px 0px" },
      headerItemStyle,
    },
    {
      text: "Linea transportista",
      width: "20%",
      cellStyle: { ...cellStyle, justifyContent: "flex-start" },
      headerItemStyle,
    },
    {
      text: "Orbi ID",
      width: "15%",
      cellStyle,
      headerItemStyle,
    },
    {
      text: "Base",
      width: "15%",
      cellStyle,
      headerItemStyle,
    },
    {
      text: "",
      width: "50%",
      cellStyle,
      headerItemStyle,
    },
  ],
};

const TAB_ITEMS = [
  { id: 3, description: "Proveedores" },
  { id: 4, description: "Disponibles" },
  { id: 2, description: "Contratos" },
];
