import React from "react";
import Modal from "react-modal";
import Button from "../controls/button";
import { cloneDeep } from "lodash";
import TextInput from "../controls/text-input";
import { partialEntity } from "../../redux/api";
import { loadSession } from "../../redux/reducers/global-catalog.reducer";
import Loader from "../icons/tat/loader";
import { connect } from "react-redux";
import { calculateGrossWeightProducts, calculateVolumeProducts, sendMessageToMultipleUsersInSocket, showSimpleFlashNotification } from "../utils";
import { cancelMultipleRequests } from "../../redux/api/actions";
import CustomDatePicker from "../component/custom-date-picker";
import ReactTooltip from "react-tooltip";

Modal.setAppElement("#root");

class ConfirmRequisitionModal extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      requisitions: cloneDeep(props.requisitions),
      deliveries: cloneDeep(props.deliveries),
      selectedDate: null,
      deliveryInCommonEdition: null,
      errorMessage: null,
      isAppliedAll: false
    }
  }

  buildTitle = () => {
    return(
      <>
        <div className="title-action">{'Confirmar Pedido'}</div>
      </>
    );
  }

  buildHeaders = () => {
    return(
      <div className="deliveries-content" style={{marginTop: 25}}>
        <div className="delivery-row">
          <div className="delivery-item">{'Número de Entrega'}</div>
          <div className="delivery-item" style={{flex: 1.5}}>{'Fecha Estimada de Recolección'}</div>
          <div className="delivery-item" style={{flex: 1.5}}>{'Fecha Estimada de Entrega'}</div>
          <div className="delivery-item">{'Peso Bruto Total'}</div>
          <div className="delivery-item">{'Volumen Total'}</div>
          <div className="delivery-item">{'Cantidad de SKU Únicos'}</div>
          <div className="delivery-item">{'Cantidad de Piezas'}</div>
        </div>
      </div>
    );
  }

  buildDeliveriesContent = () => {
    return(
      <div className="deliveries-content">

        <div className="delivery-list">
          {
            (this.state.deliveries || []).length === 0 ? 
            <div
              style={{
                height: '45%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'end',
                fontSize: 18
              }}
            >{'No hay entregas con el mismo origen y destino al pedido'}</div> : 
            (this.state.deliveries || []).map( (delivery, index) => {
              return(
                <div className="delivery-row with-hover" key={`delivery-row-${index}`}>
 
                  <div className="delivery-item">{`${delivery.requisition_code} ${delivery.suffix}`}</div>
                  <div className="delivery-item" style={{flex: 1.5}}>
                    { delivery.collection_date ? `${delivery.collection_date}` : '-'}
                    <img
                      alt=""
                      src="/images/edit.png"
                      style={{ cursor: "pointer", width: 18, height: 18, marginLeft: 10 }}
                      onClick={() => {
                        if (this.state.deliveryInCommonEdition === null) {
                          this.setState({deliveryInCommonEdition: {index, type: 1, date_data: {date: delivery.collection_date, time: null}}});
                        } else {
                          if (this.state.deliveryInCommonEdition.index === index && this.state.deliveryInCommonEdition.type === 1) {
                            this.setState({deliveryInCommonEdition: null});
                          } else {
                            this.setState({deliveryInCommonEdition: {index, type: 1, date_data: {date: delivery.collection_date, time: null}}});
                          }
                        }
                      }}
                    />
                  </div>
                  <div className="delivery-item" style={{flex: 1.5}}>
                    {delivery.delivery_date ? `${delivery.delivery_date}` : '-'}
                  </div>
                  <div className="delivery-item">{calculateGrossWeightProducts(delivery.products)}</div>
                  <div className="delivery-item">{calculateVolumeProducts(delivery.products)}</div>
                  <div className="delivery-item">{delivery.sku_variants.split(',').length}</div>
                  <div className="delivery-item">{this.getProductsByDelivery(delivery)}</div>
                </div>
              );
            })
          }
        </div>

      </div>
    );
  }

  getProductsByDelivery = (delivery) => {
    let product_count = 0;

    delivery.products.map( product => {
      product_count = product_count + parseInt(product.qty_per_sku);
    });

    return product_count;
  }

  buildDateInput = () => {

    let date_value = this.state.deliveryInCommonEdition.type === 1 ? this.state.deliveries[this.state.deliveryInCommonEdition.index].collection_date : this.state.deliveries[this.state.deliveryInCommonEdition.index].delivery_date;

    if (date_value !== null) {

      let date_value_components = date_value.split('/');
      if (date_value_components.length > 2) {
        date_value = new Date(
          date_value_components[2],
          date_value_components[1] - 1,
          date_value_components[0]
        )
      }
    }

    return(
      <TextInput
        simple={true}
        type="date"
        format={"dd/MMM/yyyy"}
        minDate={
          new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            new Date().getDate() - 1
          )
        }
        value={date_value}
        name="selectedDate"
        onChange={(e) => {
          let date_components = (e.target.value || '').split('/');

          if (date_components.length > 2) {
            if (this.state.deliveryInCommonEdition.type === 1) {

              this.state.deliveries[this.state.deliveryInCommonEdition.index].collection_date = `${date_components[1]}/${date_components[0]}/${date_components[2]}`;

            } else if (this.state.deliveryInCommonEdition.type === 2) {

              this.state.deliveries[this.state.deliveryInCommonEdition.index].delivery_date = `${date_components[1]}/${date_components[0]}/${date_components[2]}`;

            }

            this.setState({});            
          }
        }}
        showTimeSelect={false}
      />
    );
  }

  getGrossWeightInCommon = () => {
    let deliveries = this.state.deliveries;
    let products = [];

    deliveries.map( delivery => {
      products = products.concat(delivery.products);
    })

    return calculateGrossWeightProducts(products);
  }

  getVolumeInCommon = () => {
    let deliveries = this.state.deliveries;
    let products = [];

    deliveries.map( delivery => {
      products = products.concat(delivery.products);
    })

    return calculateVolumeProducts(products);
  }

  getQtyCommon = () => {
    let deliveries = this.state.deliveries;
    let product_count = 0;

    deliveries.map( delivery => {
      delivery.products.map( product => {
        product_count = product_count + parseInt(product.qty_per_sku);
      });
    })

    return product_count;
  }

  getTotalGrossWeight = () => {
    let products = [];

    this.state.deliveries.map( delivery => {
      products = products.concat(delivery.products);
    })

    return calculateGrossWeightProducts(products);
  }

  getTotalVolume = () => {
    let products = [];

    this.state.deliveries.map( delivery => {
      products = products.concat(delivery.products);
    })

    return calculateVolumeProducts(products);
  }

  getTotalQty = () => {
    let products = [];

    this.state.deliveries.map( delivery => {
      products = products.concat(delivery.products);
    })

    return products.length;
  }

  handleChange = (e) => {
    this.setState({[e.target.name]: e.target.value})
  }

  onClose = () => {
    if (this.props.isUpdatingLoading) return;
    if (this.props.closeAction) this.props.closeAction();
  };

  isAbleToConfirm = () => {
    let is_able_to_confirm = true;

    this.state.deliveries.map( item => {
      if (item.collection_date === null) {
        is_able_to_confirm = false;
      }
    });

    return is_able_to_confirm;
  }

  buildActions = () => {
    return(
      <div className="actions" style={{marginTop: 5}}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <div/>

          <div style={{display: 'flex'}}>
            <Button
              disabled={this.props.isUpdatingLoading}
              text={'Cerrar'}
              type={'btn secondary primary'}
              onClick={() => {
                this.onClose();
              }}
            />

            <div style={{width: 20}}/>
            
            {
              this.isAbleToConfirm() ? 
              <Button
                disabled={this.props.isUpdatingLoading || !this.isAbleToConfirm()}
                text={this.props.isUpdatingLoading ? <Loader circleColor={'black'}/> : 'Continuar' }
                type={'btn outline primary dark'}
                onClick={() => {
                  this.props.callActionRequisition(this.getDataToSave(), {
                    onSuccess: (response) => {

                      try {
                        if ('notifications' in response) {
                          (response.notifications || []).map( item => {
                            sendMessageToMultipleUsersInSocket(
                              this.props.userId,
                              item['users'],
                              item['data']
                            );
                          })
                        }
                      } catch(e) {
                        console.log('cancel requisitions error: ', e)
                      }

                      showSimpleFlashNotification('Se realizó el cambio con éxito', null);
                      this.props.handleChange({
                        target: {
                          name: 'load_items'
                        }
                      });
                      this.onClose();
                    },
                    onError: (e) => {
                      this.setState({
                        errorMessage: e.response?.data?.error || 'Ocurrió un error, intente de nuevo'
                      })
                    }
                  });
                }}
              /> :
              <>
                <Button
                  disabled={true}
                  text={'Continuar'}
                  type={'btn outline primary dark'}
                  onClick={() => {}}
                  dataTip
                  dataFor={`confirm-requisition`}
                />
                <ReactTooltip
                  id={`confirm-requisition`}
                  place="top"
                  className='eta-tooltip-content'
                  event="mouseover"
                  eventOff="mouseleave"
                > 
                  <div>{'Ingresa fecha de recolección para continuar'}</div>
                </ReactTooltip>
              </>
            }

          </div>

        </div>
      </div>
    );
  }

  getDateFormat = (date_string) => {
    let components = (date_string || '').split('/');

    if (components.length > 2) {
      return `${components[2]}-${components[1]}-${components[0]}`;
    }

    return '';
  }

  getRequisitionIdByDeliveries = (delivery_id) => {
    let requisition_id = null;

    (this.state.requisitions || []).map( item => {
      let requisition_found = item.deliveries.filter( delivery => delivery.id === delivery_id);
      if (requisition_found && requisition_found.length > 0) {
        requisition_id = item.id;
      }
    })

    return requisition_id;
  }

  getDataToSave = () => {
    return { 
      action: 'confirm_requisition',
      company_id: this.props.companyId,
      facility: this.props.facility,
      requisition: {
        ids: (this.state.requisitions || []).map( item => item.id),
        deliveries: this.state.deliveries.map( item => ({id: item.id, date: item.collection_date ? this.getDateFormat(item.collection_date) : null, requisition_code: item.requisition_code, suffix: item.suffix, requisition_id: this.getRequisitionIdByDeliveries(item.id)}))
      }
    };
  }

  handleDataChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    if (name === 'change_date') {

      if (this.state.deliveryInCommonEdition.type === 1) {

        this.state.deliveries[this.state.deliveryInCommonEdition.index].collection_date = value.date;

      } else if (this.state.deliveryInCommonEdition.type === 3) {

        this.state.selectedDate = value;

        this.state.deliveries.map( (item, index) => {
          this.state.deliveries[index].collection_date = value.date;
        })

      }

      this.setState({deliveryInCommonEdition: null});            
      
    }

  }

  transformToDate = (data) => {
    let date_string = data.date || null;
    if (date_string) {
      let components = date_string.split('/');
      if (date_string.length > 2) {
        return(
          new Date(
            components[2],
            components[1] - 1,
            components[0]
          )
        );
      }
    }

    return null;
  }

  transformToTime = (data) => {
    let time_string = data.time || null;
    if (time_string) {
      let components = time_string.split(':');
      return(parseInt(`${components[0]}${components[1]}`));
    }

    return null;
  }

  getSkuCount = () => {
    let skus_array = (this.state.requisitions || []).map( item => item.sku_variants ).join(',').split(',');

    let filteredArr = skus_array.filter(function(item, index) {
      if (skus_array.indexOf(item) == index)
        return item;
    });

    return filteredArr.length;
  }

  render() {
    return (
      <Modal
        isOpen={this.props.requisition !== null}
        portalClassName={`dialog confirm-requisition-dialog ${
          this.props.addClass ? this.props.addClass : ""
        }`}
        overlayClassName="overlay"
        className="content"
      >
        <div className="close" onClick={() => {
          this.onClose();
        }}></div>

        { this.buildTitle() }

        { this.buildHeaders() }

        <div style={{overflow: 'hidden', overflowY: 'auto', height: this.state.errorMessage ? 'calc(100% - 290px)' : 'calc(100% - 280px)'}}>
          { this.buildDeliveriesContent() }
        </div>

        { 
          <div className="deliveries-content">
            <div className="delivery-row" style={{borderBottom: '0px solid white'}}>
            <div className="delivery-item">{`Total de Entregas ${this.state.deliveries.length}`}</div>
            <div className="delivery-item" style={{flex: 1.5}}>{''}</div>
            <div className="delivery-item" style={{flex: 1.5}}>{''}</div>
            <div className="delivery-item">{this.getGrossWeightInCommon()}</div>
            <div className="delivery-item">{this.getVolumeInCommon()}</div>
            <div className="delivery-item">{this.getSkuCount()}</div>
            <div className="delivery-item">{this.getQtyCommon()}</div>
            </div>
          </div>
        }

        { 
          <div className="deliveries-content">
            <div className="delivery-row" style={{borderBottom: '0px solid white'}}>
              <div className="delivery-item">
                <div 
                  style={{display: 'flex', fontSize: 13, alignItems: 'center', justifyContent: 'center', cursor: 'pointer'}}
                  onClick={() => {
                    this.setState({
                      isAppliedAll: !this.state.isAppliedAll
                    })
                  }}
                >
                  <div>{'Aplicar a Todos'}</div>
                  <div className={`checkbox-container-2 ${ this.state.isAppliedAll ? 'selected' : ''}`}>
                    <div className="checkbox"></div>
                  </div>
                </div>
              </div>
              <div className="delivery-item" style={{flex: 1.5}}>
                {
                  this.state.isAppliedAll &&
                  <img
                    alt=""
                    src="/images/edit.png"
                    style={{ cursor: "pointer", width: 18, height: 18, marginLeft: 10 }}
                    onClick={() => {
                      this.setState({deliveryInCommonEdition: {index: -1, type: 3, date_data: {date: null, time: null}}});
                    }}
                  />
                }
              </div>
              <div className="delivery-item" style={{flex: 1.5}}>{this.state.isAppliedAll ? '-' : ''}</div>
              <div className="delivery-item">{this.state.isAppliedAll ? '-' : ''}</div>
              <div className="delivery-item">{this.state.isAppliedAll ? '-' : ''}</div>
              <div className="delivery-item">{this.state.isAppliedAll ? '-' : ''}</div>
              <div className="delivery-item">{this.state.isAppliedAll ? '-' : ''}</div>
            </div>
          </div>
        }

        {
          this.state.errorMessage && <div style={{color: 'red', fontSize: 13}}>{this.state.errorMessage}</div>
        }

        { this.buildActions() }

        {
          this.state.deliveryInCommonEdition &&
          <>
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%'
              }}
              onClick={() => this.setState({deliveryInCommonEdition: null})}
            />
            <CustomDatePicker
              minDate={new Date()}
              dateSelected={this.transformToDate(this.state.deliveryInCommonEdition.date_data || {})}
              timeSelected={this.state.deliveryInCommonEdition.index === -1 ? this.transformToTime(this.state.deliveryInCommonEdition.date_data || {}) : null}
              handleChange={this.handleDataChange}
              onClose={() => {
                this.setState({deliveryInCommonEdition: null})
              }}
            />
          </>
        }

      </Modal>
    );
  }
} 

const mapStateToProps = (state) => {
  const companyId = state.globalCatalog.session.company.id;
  const isUpdatingLoading = state?.api['COMPANIES.REQUISITIONSCONTROL.REQUISITIONS.ACTIONS']?.status?.isFetching || false;

  return {
    companyId,
    isUpdatingLoading,
    userId: state.globalCatalog.session.user.id,
  };
}

const mapDispatchToProps = (dispatch) => {
  dispatch(loadSession());

  return {
    cancelMultipleRequests: () => dispatch(cancelMultipleRequests()),
    callActionRequisition: (params, opts) => dispatch(partialEntity("COMPANIES.REQUISITIONSCONTROL.REQUISITIONS.ACTIONS", params, opts)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmRequisitionModal);