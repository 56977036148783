import styled from "styled-components";

export const NotificationConfigurationViewStyle = styled.div`
  height: 300px;
  padding: 20px;
  font-size: 16px;
  color: white;

  & .configuration-table {
    background-color: blue;
    border-radius: 8px;
    background-color: rgb(26, 32, 46);

    & .row-title {
      display: flex;
      border-bottom: 2px solid #c0cbdd;
      
      & .item-title {
        display: flex;
        flex: 1;
        height: 45px;
        justify-content: center;
        align-items: center;
        color: white;
      }

    }

    & .list {
      height: 255px;
      overflow: hidden;
      overflow-y: auto;
      font-size: 14px;
    
      & .row {
        display: flex;
        border-bottom: 1px solid #c0cbdd;
        
        & .item {
          display: flex;
          flex: 1;
          height: 45px;
          justify-content: center;
          align-items: center;
          color: white;

          & .checkbox-container {
            height: 10px;
            position: relative;

            & .checkbox {
              position: absolute;
              top: 0px;
              right: 0px;
              height: 20px;
              width: 20px;
              border-radius: 4px;
              background-color: #a7b6d6;
              transition: 0.3s ease;
            }

            &.selected {
              & .checkbox {
                background-color: #3d77f7;
                &::after {
                  content: "";
                  height: 4px;
                  width: 10px;
                  border-bottom: 3px solid #fff;
                  border-left: 3px solid #fff;
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -69%) rotate(-45deg);
                }
              }
            }
          }

          & .collapser {
            cursor: pointer;
    
            & img {
              transform: rotate(180deg);
              transition: 0.4s ease;
            }
    
            &.collapsed img {
              transform: rotate(0deg) translateY(3px);
            }
          }
        }

        &.style-1 {
          background-color: rgb(26, 32, 46);
        }

        &.style-2 {
          background-color: rgb(26, 32, 46);
        }

        &:hover {
          background-color: rgb(32, 41, 61);
        }

      }
      
    }

  }

`;