import React, { useState, useMemo, useCallback, createRef } from "react";

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import Button from "../../../shared/controls/button";
import { RequisitionDetailCenter, RequisitionDetailLeft } from "./style";
import { AG_GRID_LOCALE_ES } from "../../../shared/language";
import ReactTooltip from "react-tooltip";
import { calculateGrossWeightProducts, calculateNetWeightProducts, calculateVolumeProducts } from "../../../shared/utils";
import moment from 'moment';
import { cloneDeep } from "lodash";

const getCellView = (key, params, props) => {
  let view = null;

  switch(key) {
    case 'trip_status':
      let trip_status = params.data?.trip_status || null;
      if (trip_status) {
        view = <div style={{lineHeight: 'normal', height: 50, display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
        {
          <div style={{fontSize: 13, height: 18}}>{`${trip_status.description} ${moment(trip_status.event_date, "YYYY-MM-DD HH:mm:ss").format('DD/MM/YYYY HH:mm')}`}</div>
        }
      </div>
      } else {
        view = <div>{''}</div>
      }
      break;
    case 'order_status':
      let order_status = params.data?.order_status || null;

      if (order_status) {
        view = <div style={{lineHeight: 'normal', height: 50, display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
          {
            <div style={{fontSize: 13, height: 18}}>{`${order_status.description} ${moment(order_status.event_date, "YYYY-MM-DD HH:mm:ss").format('DD/MM/YYYY HH:mm')}`}</div>
          }
        </div>
      } else {
        view = <div>{''}</div>
      }
      break;
    case 'container_status':
      let containers_date = params.data?.container_status || [];
      view = <div style={{lineHeight: 'normal', height: 50, display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
        {
          containers_date.map( (item, index) => {
            return(
              <div key={`container-status-${index}`} style={{fontSize: 13, height: 18}}>{`${item.description} ${moment(item.event_date, "YYYY-MM-DD HH:mm:ss").format('DD/MM/YYYY HH:mm')}`}</div>
            );
          })
        }
      </div>
      break;
    case 'volume':
      let total_volume = calculateVolumeProducts([params.data]);
      view = <div>{`${total_volume}`}</div>
      break;
    case 'gross_weight_description':
      let total_gross = calculateGrossWeightProducts([params.data]);
      view = <div>{`${total_gross}`}</div>
      break;
    case 'net_weight_description':
      let total_weight = calculateNetWeightProducts([params.data]);
      view = <div>{`${total_weight}`}</div>
      break;
    case 'suffix':
      view = <div>{`${params.data?.requisition_code || ''}-${params.data?.suffix || ''}`}</div>
      break;
    case 'qty':
      view = <div style={{display: 'flex', alignItems: 'center'}}>
        <div>{params.data.qty_per_sku}</div>

        <img
          alt=""
          src="/images/edit.png"
          style={{ cursor: "pointer", marginLeft: 10, width: 21, height: 21 }}
          onClick={() => {
            props.handleChange({
              target: {
                name: "modify_product_data",
                value: params.data
              }
            });
          }}
        />
        
      </div>;
    break;
    case 'tags_description':
      view = <RequisitionDetailLeft>
        {
          (params.data?.tags || []).map( tag => {
            return(
              <div
                key={`tag-${tag.id}`}
                style={{
                  backgroundColor: tag.color,
                  display: 'flex',
                  height: 25,
                  justifyContent: 'center',
                  alignItems: 'center',
                  paddingLeft: 4,
                  paddingRight: 4,
                  color: 'white',
                  fontSize: 11,
                  borderRadius: 8,
                  marginRight: 8
                }}
              >
                {tag.title}
              </div>
            );
          })
        }
      </RequisitionDetailLeft>
      break;
    case 'cta':
      const isRow = params.node.data;
      if (!isRow) { view = <div></div>; break; }

      view = <div style={{width: '100%', height: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        {
          ([1,2].includes(props.requisition?.status?.id || 0) && params.data.order_id === null) &&
          <Button 
            text='Convertir Entrega en Orden' 
            type='primary small'
            onClick={() => {
              props.handleChange({
                target: {
                  name: "convert_delivery_to_order",
                  value: {deliveries: [params.data], requisition: props.requisition}
                }
              });  
            }}
          />
        }
      </div>
      break;
    default:
      view = <div></div>;
  }

  return view
};

const filterParams = {
  comparator: (filterLocalDateAtMidnight, cellValue) => {
    const dateAsString = cellValue;
    if (dateAsString == null) return -1;
    const dateParts = dateAsString.split("/");
    const cellDate = new Date(
      Number(dateParts[2]),
      Number(dateParts[1]) - 1,
      Number(dateParts[0]),
    );

    if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
      return 0;
    }

    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    }

    if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    }
    return 0;
  }
};

const filterParamsStatusListFormatter = params => {
  let r = params.value;
  let new_value = '';

  if (r === null) return '';

  if (Array.isArray(r)) {
    if (r.length > 0) {
      new_value = (typeof r[0] === "object") ? r[0].description : r;
    }
  } else {
    new_value = (typeof r === "object") ? r.description : r;
  }

  return new_value;
}

const filterParamsStatusList = {
  valueFormatter: filterParamsStatusListFormatter
}

const getCorrectName = (field, default_columns) => {
  let column = default_columns.filter( item => item.field === field);
  return (column && column.length > 0) ? column[0].headerName : null;
}

const getColumnsWithCustomCells = (columns, default_columns, props) => {

  columns.map( (item, index) => {

    let new_header_name = getCorrectName(item.field, default_columns);
    if (new_header_name !== null) {
      columns[index].headerName = new_header_name;
    }

    if (['suffix', 'tags_description', 'cta', 'order_status', 'container_status', 'trip_status'].includes(item.field)) {
      columns[index].cellRenderer = params => {
        return getCellView(item.field, params, props)
      }
    }

    if (['order_status', 'container_status', 'trip_status'].includes(item.field)) {
      columns[index].filterParams = filterParamsStatusList;
      columns[index].filter = "agSetColumnFilter";
      columns[index].valueFormatter = filterParamsStatusListFormatter;
    }

  })

  return columns;
}

const applyNewColumns = (temp_colDefs, props) => {

  if (props.configuration['columns'] !== undefined && typeof props.configuration['columns'] === "object") {

    let temp_columns = props.configuration['columns'];

    if (temp_columns.length === temp_colDefs.length) {
      return getColumnsWithCustomCells(temp_columns, temp_colDefs, props);
    }

    try {

      let currentColumns = temp_colDefs.map( item => item.field);
      let currentColumnsSaved = props.configuration['columns'].map( item => item.field);
      let difference = currentColumns.filter(x => !currentColumnsSaved.includes(x));

      let newColumnsToAdd = [];

      temp_colDefs.map( (item, index) => {
        if (difference.includes(item.field)) {
          newColumnsToAdd.push({
            index,
            column: item
          })
        }
      });

      newColumnsToAdd.map( item => {
        temp_columns.splice(item.index, 0, item.column);
      });

      return getColumnsWithCustomCells(temp_columns, temp_colDefs, props);

    } catch (error) {
      console.error('Error setting columns definitions', error);

      return getColumnsWithCustomCells(temp_columns, temp_colDefs, props);
    }
  }
}

export const RequisitionDetailView = (props) => {

  const gridRef = createRef();
  const [rowData, setRowData] = useState(props.rowData);
  const [called, setCalled] = useState(false);

  let default_columns = [
    { headerName: 'Pedido', field: 'requisition_code',  minWidth: 250, filter: 'agTextColumnFilter', suppressMenu: true, sortable: true, cellRenderer: "agGroupCellRenderer" },
    { headerName: 'Entrega', field: 'suffix', suppressMenu: true, filter: 'agTextColumnFilter', minWidth: 200, sortable: true,
      cellRenderer: params => {
        return getCellView('suffix', params, props)
      }
    },
    { headerName: 'Orden', field: 'order_code', suppressMenu: true, filter: 'agTextColumnFilter', minWidth: 200, sortable: true },
    { headerName: 'ID de Orden', field: 'order_id', suppressMenu: true, filter: 'agTextColumnFilter', minWidth: 200, sortable: true },
    { headerName: 'Fecha Deseada de Recolección', field: 'collection_date',  minWidth: 250, filter: 'agDateColumnFilter', filterParams: filterParams, suppressMenu: true, sortable: true },
    { headerName: 'Fecha Deseada de Entrega', field: 'delivery_date',  minWidth: 250, filter: 'agDateColumnFilter', filterParams: filterParams, suppressMenu: true, sortable: true },
    { headerName: 'Etiquetas', field: 'tags_description', minWidth: 200, filter: 'agTextColumnFilter', suppressMenu: true,
      cellRenderer: params => {
        return getCellView('tags_description', params, props)
      }
    },
    { headerName: 'Criticidad', field: 'criticality', minWidth: 200, filter: true, suppressMenu: true, sortable: true, filter: "agSetColumnFilter" },
    { headerName: 'Estatus de Orden', field: 'order_status', minWidth: 290, filter: true, suppressMenu: true, sortable: true, filterParams: filterParamsStatusList, filter: "agSetColumnFilter", valueFormatter: filterParamsStatusListFormatter,
      cellRenderer: params => {
        return getCellView('order_status', params, props)
      }
    },
    { headerName: 'Estatus de Viaje', field: 'trip_status', minWidth: 290, filter: true, suppressMenu: true, sortable: true, filterParams: filterParamsStatusList, filter: "agSetColumnFilter", valueFormatter: filterParamsStatusListFormatter,
      cellRenderer: params => {
        return getCellView('trip_status', params, props)
      }
    },
    { headerName: 'Estatus de Remolque', field: 'container_status', minWidth: 290, filter: true, suppressMenu: true, sortable: true, filterParams: filterParamsStatusList, filter: "agSetColumnFilter", valueFormatter: filterParamsStatusListFormatter,
      cellRenderer: params => {
        return getCellView('container_status', params, props)
      }
    },
    { headerName: 'CTA', field: 'cta', minWidth: 230, suppressMenu: true, 
      cellRenderer: params => {
        return getCellView('cta', params, props)
      }
    },
    { headerName: 'Acciones', field: 'actions', minWidth: 200, suppressMenu: true, tooltipField: 'actions' }
  ];

  let new_def = applyNewColumns(cloneDeep(default_columns), props);

  if (new_def && new_def.length > 0) {
    default_columns = new_def;
  }
  
  const [colDefs, setColDefs] = useState(default_columns);

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      floatingFilter: true,
      tooltipComponent: params => {
        return(
          <div
            style={{
              backgroundColor: 'black',
              color: 'white',
              padding: 10,
              borderRadius: 10
            }}
          >
            <div
              style={{
                cursor: 'pointer'
              }}
              onClick={() => {
                props.handleChange({
                  target: {
                    name: "add-new-product",
                    value: params.data
                  }
                })
              }}
            >{'Añadir Producto Nuevo'}</div>

            {
              params.data.total_deliveries > 1 ?
              <div
                style={{cursor: 'pointer', marginTop: 8}}
                onClick={() => {
                  props.handleChange({
                    target: {
                      name: "delete-delivery",
                      value: params.data
                    }
                  })
                }}
              >{'Eliminar Entrega'}</div> :
              <>
                <div
                  data-tip
                  data-for={`delete-delivery-${params.data.id}`}
                  style={{marginTop: 8}}
                  onMouseEnter={(e) => {
                    ReactTooltip.hide();
                    ReactTooltip.show(e.currentTarget);
                  }}
                >{'Eliminar Entrega'}</div>

                <ReactTooltip
                  id={`delete-delivery-${params.data.id}`}
                  className='eta-tooltip-content'
                  event="focusin"
                  eventOff="focusout"
                  place="right"
                > 
                  <>
                    <div>{`El pedido debe tener más de una entrega`}</div>
                  </>
                </ReactTooltip>
              </>
            }

            <div
              style={{
                cursor: 'pointer',
                marginTop: 8
              }}
              onClick={() => {
                props.handleChange({
                  target: {
                    name: "divide-requisition-delivery",
                    value: params.data
                  }
                })
              }}
            >{'Dividir Entrega'}</div>
          </div>
        );
      },
    };
  }, []);

  const autoGroupColumnDef = useMemo(() => {
    return {
      minWidth: 200,
      pinned: "left",
    };
  }, []);

  const onGridReady = useCallback((params) => {

    if (props.allSelected) {
      params.api.forEachNode( node => {
        node.setExpanded(true);
      });
    }

    setCalled(true);
    
    // if (props.configuration['filter'] !== undefined && typeof props.configuration['filter'] === "object") {
    //   params.api.setFilterModel(
    //     props.configuration['filter']
    //   );
    // }

    // if (props.configuration['columns'] !== undefined && typeof props.configuration['columns'] === "object") {

    //   let temp_columns = props.configuration['columns'];

    //   if (temp_columns.length === colDefs.length) {
    //     params.api.setColumnDefs(
    //       getColumnsWithCustomCells(temp_columns)
    //     );
    //     setCalled(true);
    //     return;
    //   }

    //   try {

    //     let currentColumns = colDefs.map( item => item.field);
    //     let currentColumnsSaved = props.configuration['columns'].map( item => item.field);
    //     let difference = currentColumns.filter(x => !currentColumnsSaved.includes(x));

    //     let newColumnsToAdd = [];

    //     colDefs.map( (item, index) => {
    //       if (difference.includes(item.field)) {
    //         newColumnsToAdd.push({
    //           index,
    //           column: item
    //         })
    //       }
    //     });

    //     newColumnsToAdd.map( item => {
    //       temp_columns.splice(item.index, 0, item.column);
    //     });

    //     params.api.setColumnDefs(
    //       getColumnsWithCustomCells(temp_columns)
    //     );
    //     setCalled(true);

    //   } catch (error) {
    //     console.error('Error setting columns definitions', error);

    //     params.api.setColumnDefs(
    //       getColumnsWithCustomCells(temp_columns)
    //     );
    //     setCalled(true);
    //   }
    // } else {
    //   setCalled(true);
    // }

  }, []);

  const updateAggridConf = (params) => {
    if (called) {
      let config = {
        columns: params.api.getColumnDefs(),
        filter: params.api.getFilterModel()
      };

      if (props.setNewFilter) {
        props.setNewFilter(config);
      }
    }
  };

  const detailCellRendererParams = useMemo(() => {
    return {
      detailGridOptions: {
        rowHeight: 50,
        columnDefs: [
          { headerName: 'Producto', field: "product_name", suppressMenu: true, checkboxSelection: true, headerCheckboxSelection: true },
          { headerName: 'SKU', field: "sku", suppressMenu: true },
          { headerName: 'Cantidad', field: "qty_per_sku", suppressMenu: true,
            cellRenderer: params => {
              return getCellView('qty', params || {}, props);
            }
          },
          { headerName: 'Peso Neto', field: "net_weight_description", suppressMenu: true,
            cellRenderer: params => {
              return getCellView('net_weight_description', params || {}, props);
            }
          },
          { headerName: 'Peso Bruto', field: "gross_weight_description", suppressMenu: true,
            cellRenderer: params => {
              return getCellView('gross_weight_description', params || {}, props);
            }
          },
          { headerName: 'Volumen', field: "volume", suppressMenu: true,
            cellRenderer: params => {
              return getCellView('volume', params || {}, props);
            }
          },
          { headerName: 'CTA', field: 'cta', suppressMenu: true, 
            cellRenderer: params => {
              const isRow = params.node.data;
              if (!isRow) { return <div></div> }

              return(
                <div style={{width: '100%', height: '50px', display: 'flex', alignItems: 'center'}}>

                  {
                    params.node.parent.allChildrenCount > 1 &&
                    <img
                      alt=""
                      src="/images/bin.png"
                      style={{ cursor: "pointer", marginLeft: 10}}
                      onClick={() => {
                        let code = '';

                        (props.rowData || []).map( item => {
                          if (item.id === params.data.delivery_id) {
                            code = `${item.requisition_code}-${item.suffix}`
                          }
                        })

                        props.handleChange({
                          target: {
                            name: "remove_unique_product_data",
                            value: {
                              id: params.data.delivery_id,
                              products: [{id: params.data.product_delivery_id, product_name: params.data.product_name}],
                              code
                            }
                          }
                        });
                      }}
                    />
                  }

                </div>
              );
            }
          }
        ],
        rowSelection: 'multiple',
        defaultColDef: {
          flex: 1
        },
        suppressRowClickSelection: true,
        onRowSelected: params => {
          let selected = params.api.getSelectedRows().map( item => item.product_delivery_id);
          let all_ids = [];

          (params?.node?.parent?.allLeafChildren || []).map( item => {
            let product_id = {id: item.data.product_delivery_id, selected: selected.includes(item.data.product_delivery_id), delivery_id: item.data.delivery_id, product_name: item.data.product_name };
            all_ids.push(product_id);
          });

          props.handleChange({
            target: {
              name: "products_selected",
              value: all_ids
            }
          });
        },
        getRowId: params => {
          return String(params.data.product_delivery_id);
        },
        onGridReady: params => {
          if (props.allSelected) {
            params.api.forEachNode( node => {
              node.setSelected(true);
            });
          }
        }
      },
      getDetailRowData: (params) => {
        params.successCallback(params.data.products);
      },
    };
  }, []);

  const getRowHeight = useCallback((params) => {
    const isDetailRow = params.node.detail;
    // for all rows that are not detail rows, return nothing
    if (!isDetailRow) { return undefined; }

    // otherwise return height based on number of rows in detail grid
    const detailPanelHeight = ((params.data?.products || []).length * 52) + 50;
    return detailPanelHeight < 50 ? 150 : detailPanelHeight;
  }, []);

  return (
    <div>

      <div
        className="ag-theme-alpine"
        style={{ height: 650, marginBottom: 50 }}
      >
        <AgGridReact
          ref={gridRef}
          rowData={rowData}
          columnDefs={colDefs}
          enableCellTextSelection={true}
          rowHeight={50}
          detailCellRendererParams={detailCellRendererParams}
          masterDetail={true}
          getRowHeight={getRowHeight}
          rowSelection="multiple"
          suppressRowClickSelection={true}
          localeText={AG_GRID_LOCALE_ES}
          defaultColDef={defaultColDef}

          tooltipShowDelay={500}
          tooltipInteraction={true}
          keepDetailRows={true}
          
          // onRowGroupOpened={params => {

          //   //params.node.setSelected(true);//.api.selectAll()

          //   params.api.forEachDetailGridInfo((node, index) => {
          //     // node.detailNode.detailGridInfo.api.forEachNode((node_2, index_2) => {
          //     //   console.log(index_2, node_2);
          //     // });
          //     node.api.flashCells();
          //     console.log(index, node);
          //   });

          //   // console.log('open', params);
          // }}

          onGridReady={onGridReady}
          getRowId = { params => {
            return String(params.data.id);
          }}
          gridOptions={{
            sideBar: {
              toolPanels: ['columns', 'filters'],
              position: 'right',
              defaultToolPanel: '',
            },
          }}

          groupDisplayType={"groupRows"}
          rowGroupPanelShow={"always"}
          autoGroupColumnDef={autoGroupColumnDef}
          enableCharts={true}
          enableRangeSelection={true}
          onFilterChanged={ params => {
            updateAggridConf(params);
          }}
          onColumnMoved={ params => {
            if (params.finished) {
              updateAggridConf(params);
            }
          }}
          onColumnVisible={ params => {
            updateAggridConf(params);
          }}
        />
      </div>
      {/* <div style={{
        display: 'flex',
        justifyContent: 'end',
        marginBottom: 20
      }}>
        <Button
          settings={{
            disabled: props.isLoading,
            type: "btn primary",
            text: props.isLoading ? <Loader/> : "Guardar",
            onClick: () => {
              let config = {
                columns: gridRef.current.api.getColumnDefs(),
                filter: gridRef.current.api.getFilterModel()
              };

              if (props.setNewFilter) {
                props.setNewFilter(config);
              }
            }
          }}
        />
      </div> */}
    </div>
  );
}