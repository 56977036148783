import React from "react";
import ProfileInformation from "../../shared/component/profile-information";
import { getUrlsEnv } from "../../shared/backend-api";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getUser } from "../../redux/reducers/register-account.reducer";
import TagsContainer from "../../shared/component/tags-container";
// import PermissionsTable from '../../shared/component/permissions-table';
// import AlertList from '../../shared/component/alert-list';
// import NotificationList from '../../shared/component/notification-list';
// import { getLastSession, USER_FORMAT } from '../../shared/utils';
import {
  getUserInfo,
  getUserTypes,
  getUserTags,
  removeTagFromUser,
  addTagToUser,
  editTagFromUser,
  getUserFleetTags,
  getUserPermissions,
  setUserPermissions,
} from "../../redux/reducers/user-reducer";
import {
  loadAccountStatusCatalog,
  loadPermissionsCatalog,
} from "../../redux/reducers/global-catalog.reducer";
import { listFacilities } from "../../redux/reducers/facility-reducer";
import Button from "../../shared/controls/button";
import Modal from "react-modal";
import InputSearch from "../../shared/controls/input-search";
import Checkbox from "../../shared/controls/checkbox";
import { loadTags } from "../../redux/reducers/tags.reducer";
import Tag from "../../shared/controls/tag";
import {
  removeFacilityFromUser,
  addFacilityToUser,
  loadUserFacilities,
  listCompanyRoles,
  loadCompanyUsers,
  changeCompanyUserRole,
  removeCompanyUserRole,
  getSessionCompanyData,
} from "../../redux/reducers/company-reducer";
import ConfirmDialog from "../../shared/dialog/confirm-dialog";
import PermissionsTable from "../../shared/component/permissions-table";
import { normalizeStr } from "../../shared/utils";
import ValidatePerms from "../../shared/component/validate-perms";
import UserAlertList from "../../shared/component/user-alert-list";
import UserNotificationList from "../../shared/component/user-notification-list";
import ChangeUserTypeModal from "../../shared/dialog/change-user-type";
import { isArray } from "lodash";
import { getEntityItems, readEntities } from "../../redux/api";


const { files } = getUrlsEnv();

class UserProfile extends React.Component {
  constructor(props) {
    super(props);

    const session = JSON.parse(sessionStorage.getItem("orbinetwork_session"));
    const companyData = session.company;
    const user = session.user;

    this.state = {
      selected_tab: "permisos",
      userId: props.match.params.user || user.id || "",
      companyId: companyData.id,
      buttonText: "Continuar",
      showEditFacilitiesModal: false,
      facilityButtonDisabled: false,
      showEditRolesModal: false,
      showEditFleetsModal: false,
      showResultModal: false,
      newFacilities: [],
      searchStr: "",
      displayFacilityBy: "name",
      newRol: [],
      newFleets: [],
      showEditUserType: false,
      // facilitiesToShow: {labels: [], tags: []}
    };

    // this.facilitiesToShow = {labels: [], tags: []};
  }

  componentDidMount() {
    this.props.getUserInfo(this.state.companyId, this.state.userId);
    this.props.getUserFacilities({
      company_id: this.state.companyId,
      user_id: this.state.userId,
    });
    this.props.getUserTags(this.state.userId);
    this.props.getUserFleetTags(this.state.userId);
    this.props.getUserPermissions(this.state.userId);
  }
  showEditFacilitiesModal = (show) => {
    if (show) {
      this.setState({
        newFacilities: (this.props.userInfo.facilities || []).map((f) => f.id),
      });
    }
    this.setState({ showEditFacilitiesModal: show });
  };
  handleFacilitySearch = (name, value) => {
    this.setState({ searchStr: value });
  };
  setDisplayFacilityBy = (value) => {
    this.setState({ displayFacilityBy: value });
  };
  selectAllFacilities = (e) => {
    // let facilitiesIds = [];
    // if (this.state.newFacilities.length < this.props.facilities.length)
    //   facilitiesIds = this.props.facilities.map(f => f.id);
    // this.setState({ newFacilities: facilitiesIds });

    let st = [];
    if (e.target.checked) {
      this.props.facilitiesByTag.forEach((fe) => {
        if ((fe.items || []).length > 0) {
          fe.items.forEach((ch) => {
            if (!st.includes(ch.id)) st.push(ch.id);
          });
        } else {
          st.push(fe.id);
        }
      });
    }
    this.setState({ newFacilities: st });
  };
  handleFilterListSearch = (items, field, value = "") => {
    return value.length > 0
      ? items
          .map((i) =>
            (i.items || []).length &&
            !normalizeStr(i[field]).includes(normalizeStr(value))
              ? {
                  ...i,
                  items: this.handleFilterListSearch(
                    i.items,
                    "description",
                    this.state.searchStr
                  ),
                }
              : i
          )
          .filter(
            (i) =>
              normalizeStr(i[field]).includes(normalizeStr(value)) ||
              (i.items || []).length
          )
      : items;
  };
  handleSelectFacility = (e, items) => {
    // if (e.target.value)
    //   this.setState({
    //     newFacilities: this.state.newFacilities.filter(
    //       fId => fId !== e.target.id
    //     )
    //   });
    // else
    //   this.setState({
    //     newFacilities: this.state.newFacilities.concat(e.target.id)
    //   });

    let st = this.state.newFacilities.map((m) => m.toString()) || [];
    if ((items || {}).length > 0) {
      items.forEach((item) => {
        let val = item.id.toString();
        st = st.filter((a) => a !== val);
        if (e.target.checked) {
          st = st.concat(val);
        }
      });
    } else {
      if (st.includes(e.target.value.toString())) {
        st = st.filter((a) => a.toString() !== e.target.value.toString());
      } else {
        st = st.concat(e.target.value.toString());
      }
    }
    this.setState({ newFacilities: st });
  };
  handleChangeFacilities = async () => {
    this.setState({ facilityButtonDisabled: true, buttonText: "Guardando..." });
    let addedFacilities = this.state.newFacilities.filter(
      (f) =>
        !this.props.userInfo.facilities
          .map((oF) => oF.id.toString())
          .includes(f.toString())
    );
    let removedFacilities = this.props.userInfo.facilities
      .filter(
        (f) =>
          !this.state.newFacilities
            .map((oF) => oF.toString())
            .includes(f.id.toString())
      )
      .map((f) => f.id);
    // debugger;
    await this.addFacilities(this.state.userId, addedFacilities);
    await this.removeFacilities(
      removedFacilities,
      this.state.userId,
      this.props.user_facilities
    );
    this.setState({
      showEditFacilitiesModal: false,
      showResultModal: true,
      facilityButtonDisabled: false,
      buttonText: "Continuar",
    });
  };
  addFacilities = async (userId, facilities) => {
    return Promise.all(
      facilities.map((facility) => this.props.addUserFacility(userId, facility))
    );
  };
  removeFacilities = async (
    removedFacilities,
    userId,
    user_facilities_catalog
  ) => {
    return Promise.all(
      removedFacilities.map((rFacility) =>
        this.props.deleteUserFacility(
          userId,
          (
            user_facilities_catalog.find((uF) => uF.facilities === rFacility) ||
            {}
          ).id
        )
      )
    );
  };
  showEditRolesModal = (show) => {
    if (show) {
      this.setState({
        newRol: (this.props.userInfo.role_id || []).map((r) => r.id),
      });
    }
    this.setState({ showEditRolesModal: show });
  };
  handleTagClick = (e) => {
    if (e.target.value) this.setState({ newRol: [] });
    else this.setState({ newRol: [e.target.id] });
  };
  handleChangeRoles = () => {
    // debugger;
    let userRoles = this.props.userInfo.role_id || [];
    if (userRoles.length) {
      //Ya tiene rol
      if (this.state.newRol.length) {
        //Hay uno seleccionado
        if (this.state.newRol[0] !== userRoles[0].id) {
          //Es diferente al que tenia
          this.props.editUserRole(
            (
              this.props.company_users.find(
                (cU) => cU.users.toString() === String(this.state.userId)
              ) || {}
            ).id,
            this.state.newRol[0]
          );
        }
      } else {
        //Se deseleccionó
        this.props.removeUserRole(this.state.userId);
      }
    } else if (this.state.newRol.length) {
      //Se le asigna uno nuevo
      let company_user_id = (
        this.props.company_users.find(
          (cU) => cU.users.toString() === String(this.state.userId)
        ) || {}
      ).id;
      this.props.editUserRole(company_user_id, this.state.newRol[0]);
    }
    this.setState({ showEditRolesModal: false, showResultModal: true });
  };

  showEditFleetsModal = (show) => {
    if (show) {
      // this.setState({
      //   newFleets: (this.props.userInfo.groups || [])
      //     .filter(g => g.type === 3)
      //     .map(r => r.id)
      // });
      this.setState({
        newFleets: (this.props.user_fleet_tags || []).map((r) => r.tags),
      });
    }
    this.setState({ showEditFleetsModal: show });
  };
  handleFleetTagClick = (e) => {
    if (e.target.value) {
      this.setState({
        newFleets: this.state.newFleets.filter((r) => r !== e.target.id),
      });
    } else {
      this.setState({ newFleets: this.state.newFleets.concat(e.target.id) });
    }
  };
  handleChangeFleets = () => {
    // debugger;
    let addedFleets = this.state.newFleets.filter(
      (f) => !this.props.user_fleet_tags.map((oF) => oF.tags).includes(f)
    );
    let removedFleets = this.props.user_fleet_tags
      .filter((f) => !this.state.newFleets.includes(f.tags))
      .map((f) => f.id);
    addedFleets.forEach((addedFleetId) => {
      this.props.addUserTags(this.state.userId, addedFleetId);
    });
    removedFleets.forEach((removedFleetId) => {
      this.props.removeUserTags(this.state.userId, removedFleetId);
    });
    this.setState({ showEditFleetsModal: false, showResultModal: true });
  };

  showResultModal = (show) => {
    this.props.getUserInfo(this.state.companyId, this.state.userId);
    if (!show) {
      this.setState({ showResultModal: show });
      this.props.getUserFacilities({
        company_id: this.state.companyId,
        user_id: this.state.userId,
      });
      this.props.getUserTags(this.state.userId);
      this.props.getUserFleetTags(this.state.userId);
      this.props.getUserPermissions(this.state.userId);
    }
  };

  saveIndividualPermissions = (matrixPermissions) => {
    this.props
      .setUserPermissions(this.state.userId, matrixPermissions)
      .then((result) => {
        if (result) this.setState({ showResultModal: true });
      });
  };

  switchTab = (e) => {
    if (e.target.id) {
      this.setState({ selected_tab: e.target.id });
    }
  };
  getTabContent = () => {

    let permissions_not_allowed = this.props.isCO ? [
      'Instalación - Menu Controles', 
      'Reportes', 
      'Control de embarques',
      'Control de instalaciones para creación de límites',
      'Control de instalaciones para validación de límites',
      'Control de instalaciones para aplicar límites de ventanas de atención',
      'Catálogo de flotilla - solo operadores',
      'Catálogo de flotilla  - Menú, resumen de flotilla y catálogos',
      'Perfil de instalación pestaña de destinatarios de alertas&notificaciones',
      'Perfil de instalación pestaña de usuarios invitar, cancelar invitaciones, eliminar usuarios',
      'Perfil usuario - Etiquetas de flotilla'
    ] : [];

    let new_permissions = this.props.permissionsCatalog.filter(item => !permissions_not_allowed.includes(item.permission));

    if (
      this.props.userInfo.status_id === 2 ||
      this.props.userInfo.status_id === 4
    ) {
      switch (this.state.selected_tab) {
        case "permisos":
          return (
            <ValidatePerms perms={[{ id: "5", perm: "r" }]}>
              <PermissionsTable
                isIndividual={true}
                permissionsCatalog={new_permissions.filter(
                  (p) => p.company_type === (this.props.companyInfo || {}).type
                )}
                activePermissions={
                  (this.props.userPermissions[0] || {}).permissions
                }
                onSave={this.saveIndividualPermissions}
              />
            </ValidatePerms>
          );
        case "alertas":
          return <UserAlertList userId={this.state.userId} />;
        case "notificaciones":
          return <UserNotificationList userId={this.state.userId} />;
        default:
          return <div />;
      }
    } else {
      return <div />;
    }
  };

  getFacilitiesTags = () => {
    if(!(this.props.facilities || []).length || !(this.props.user_facilities || []).length) return {labels: [], tags: []}
    // debugger;
    let labels = (this.props.user_facilities || []).map(uF => {
      return (this.props.facilities || []).find(f => f.id === uF.facilities) || null
    }).filter(f => f);
    // debugger;
    let userFacilitiesIds = labels.map(l => l.id);
    // let labels = this.props.facilities.filter(f => {
      
    // });
    let tags = [];

    this.props.facilitiesByTag.forEach((fT) => {
      let foundIndex = [];
      let counterCoincidence = fT.items.filter(fTi => {
        let i = userFacilitiesIds.findIndex(i => i === fTi.id);
        if(i !== -1){
          foundIndex.push(i);
          return true;
        } else {
          return false;
        }
        // userFacilitiesIds.includes(fTi.id)
      });
      if(foundIndex.length) foundIndex = [...new Set(foundIndex)];
      if(counterCoincidence.length === fT.items.length){
        tags.push({title: fT.description, color: fT.color});
        foundIndex.forEach(i => {
          labels[i].found = true;
        })
      }
      
    })

    // for (let i = 0; i < this.props.facilitiesByTag.length; i++) {
    //   const tagContent = this.props.facilitiesByTag[i];
    //   let counterCoincidence = 0;
    //   for (let j = 0; j < tagContent.items.length; j++) {

    //     for (let k = 0; k < this.props.facilities.length; k++) {
    //       if (tagContent.items[j].description.includes(this.props.facilities[k].description)) {
    //         counterCoincidence = counterCoincidence + 1;
    //       }
    //     }

    //     if (tagContent.items.length === counterCoincidence) {
    //       labels = labels.filter( (item) => {
    //         return !tagContent.items.find( (subItem) => {
    //           return subItem.name.includes(item.name);
    //         });
    //       });
    //       tags = tags.concat({title: tagContent.description, color: tagContent.color});
    //     }

    //   }
    // }

    // this.setState({
    //   facilitiesToShow: {
    //     labels: labels.filter(f => !f.found).map(f => f.name), 
    //     tags
    //   }
    // })

    return {labels: labels.filter(f => !f.found).map(f => f.name), tags};
  }

  render() {
    let counter = -9999;

    // if (this.facilitiesToShow.labels.length === 0 && this.facilitiesToShow.tags.length === 0) {
    //   this.facilitiesToShow = this.getFacilitiesTags();
    // }

    let isUserCargoOwner = `${this.props.userInfo.user_type}` === '5' || `${this.props.userInfo.user_type}` === '6';

    return (
      <div className="facility-profile-container">
        <div className="facility-profile">
          <div className="top-row">
            <div
              className="facility-info"
              style={{ backgroundColor: "rgba(0,0,0,0)", padding: "0px" }}
            >
              <ProfileInformation
                type={"USER-PROFILE"}
                // color={ this.props.userInfo.user_type === 1 || this.props.userInfo.user_type === 3 ? '#79A3FF' : '#FFB643' }
                color={
                  (
                    (this.props.userTypes || []).find(
                      (uT) => uT.id === this.props.userInfo.user_type
                    ) || {}
                  ).description === "Warehouser"
                    ? "#FFB643"
                    : "#79A3FF"
                }
                companyUsers={this.props.company_users}
                data={{
                  id: this.props.userInfo.user_id,
                  name: this.props.userInfo.full_name,
                  userType: (
                    (this.props.userTypes || []).find(
                      (uT) => uT.id === this.props.userInfo.user_type
                    ) || {}
                  ).description,
                  alias: this.props.userInfo.email,
                  mainImage: `${
                    this.props.userInfo.profile_image
                      ? files.concat(this.props.userInfo.profile_image)
                      : "/images/user_large.svg"
                  }`,
                  lastLogin: this.props.userInfo.last_login,
                  // isActive,
                  accountStatus: (
                    (this.props.accountStatusCatalog || []).find(
                      (uT) => uT.id === this.props.userInfo.status_id
                    ) || {}
                  ).description,
                  clickEditBtn: () => this.props.history.push(`/user/data`),
                  showEditUserType: true,
                  clickEditUserType: () => {
                    this.setState({
                      showEditUserType: true
                    });
                  }
                }}
              />
            </div>

            {/* <div className="facility-tags-container">
              <div className="container-item">
                <TagsContainer
                  title="Instalaciones:"
                  name="instalaciones"
                  // labels={(this.props.userInfo.facilities || []).map(f => f.name)}
                  tags={(this.props.userInfo.facilities || []).map(f => ({
                    title: f.name
                  }))}
                  onClick={() => this.showEditFacilitiesModal(true)}
                />
              </div>
            </div> */}

            <div className="facility-tags-container"> 
              {(this.props.userInfo.user_type && this.props.companyType === 2 && !['5', '6', '3', '1'].includes(`${this.props.userInfo.user_type}`)) ? (
                <React.Fragment>
                  <div className="container-item double-size">
                    <TagsContainer
                      title="Instalaciones:"
                      name="instalaciones"
                      // labels={(this.props.userInfo.facilities || []).map(f => f.name)}
                      tags={this.getFacilitiesTags().tags}
                      labels={this.getFacilitiesTags().labels}
                      // tags={(this.props.userInfo.facilities || []).map((f) => ({
                      //   title: f.name,  
                      // }))}
                      onClick={() => this.showEditFacilitiesModal(true)}
                      permsContent={[
                        {
                          id: "23",
                          perm: "r",
                        },
                      ]}
                      permsEditBtn={[
                        {
                          id: "23",
                          perm: "u",
                        },
                      ]}
                    />
                  </div>

                  <div className="line-divider" />
                </React.Fragment>
              ) : (this.props.companyType === 2) ? <div className="container-item double-size"/> : ''
              }

              { (this.props.companyType === 1 && !['5', '6']) ? (
                <React.Fragment>
                  <div className="container-item double-size">
                    <TagsContainer
                      name="flotilla"
                      title={
                        this.props.companyType === 1 ? "Bases" : "Flotilla:"
                      }
                      // tags={(this.props.userInfo.groups || []).filter(
                      //   t => t.type === 3
                      // )}
                      tags={this.props.fleetTags.filter((fT) =>
                        (this.props.user_fleet_tags || [])
                          .map((r) => r.tags)
                          .includes(fT.id)
                      )}
                      onClick={() => this.showEditFleetsModal(true)}
                      // permsContent={[ 
                      //   {
                      //     id: "24",
                      //     perm: "r",
                      //   },
                      // ]}
                      // permsEditBtn={[
                      //   {
                      //     id: "24",
                      //     perm: "u",
                      //   },
                      // ]}
                    />
                  </div>

                  <div className="line-divider" />
                </React.Fragment>
              ) : this.props.companyType === 1 ? <div className="container-item double-size"/> : ''}

              {
                ((this.props.userInfo.user_type && this.props.companyType === 2 && !['5', '6', '4', '2'].includes(`${this.props.userInfo.user_type}`)) ||
                  (this.props.userInfo.user_type && this.props.companyType === 1 && !['5', '6'].includes(`${this.props.userInfo.user_type}`))
                ) ? (
                  <div className="container-item">
                    <TagsContainer
                      hideEditBtn={isUserCargoOwner}
                      name="roles"
                      title="Roles:"
                      tags={(this.props.userInfo.role_id || []).map((r) => ({
                        ...r,
                        title: r.description,
                      }))}
                      onClick={() => this.showEditRolesModal(true)}
                      permsContent={[
                        {
                          id: "5",
                          perm: "r",
                        },
                      ]}
                      permsEditBtn={[
                        {
                          id: "5",
                          perm: "u",
                        },
                      ]}
                    />
                  </div>
                ) : <div className="container-item"/>
              }

            </div>
          </div>
          <div className="bottom-row">
            <div className="tabs">
              <div
                className={
                  this.state.selected_tab === "permisos" ? "selected" : ""
                }
                onClick={this.switchTab}
              >
                <span id="permisos">PERMISOS</span>
              </div>
              {/* <div
                className={
                  this.state.selected_tab === "alertas" ? "selected" : ""
                }
                onClick={this.switchTab}
              >
                <span id="alertas">ALERTAS</span>
              </div>
              <div
                className={
                  this.state.selected_tab === "notificaciones" ? "selected" : ""
                }
                onClick={this.switchTab}
              >
                <span id="notificaciones">NOTIFICACIONES</span>
              </div> */}
            </div>
            <div className="tab-content">{this.getTabContent()}</div>
          </div>
        </div>
        <Modal
          isOpen={this.state.showEditRolesModal}
          portalClassName="dialog emails-modal invite-users-modal"
          overlayClassName="overlay"
          className="content"
        >
          <div className="title">Etiquetas de roles</div>
          <div
            className="close"
            onClick={() => this.showEditRolesModal(false)}
          ></div>
          <div className="message">
            <div className="user-tags-options-container">
              {this.props.tags.map((tag) => {
                return (
                  <Tag
                    key={tag.id}
                    id={tag.id}
                    color={tag.color}
                    title={tag.title}
                    type={tag.type}
                    isFull={this.state.newRol.includes(tag.id)}
                    onClick={this.handleTagClick}
                    parentBackground="light"
                  />
                );
              })}
            </div>
          </div>
          <div className="actions">
            <Button
              text="Continuar"
              type="primary"
              onClick={this.handleChangeRoles}
            />
          </div>
        </Modal>
        <ChangeUserTypeModal
          open={this.state.showEditUserType}
          companyTypeId={this.props.userInfo.company_type_id || 0}
          userData = {
            ( this.props.company_users !== null && isArray(this.props.company_users) ) ? this.props.company_users.find( (value) => {
              return `${value.users}` === `${this.props.userInfo.user_id}`
            }) : null
          }
          onClose={ () => {
            this.setState({
              showEditUserType: false
            });
          }}
          success={() => {
            this.props.getUserInfo(this.state.companyId, this.state.userId);
          }}
        />
        <Modal
          isOpen={this.state.showEditFleetsModal}
          portalClassName="dialog emails-modal invite-users-modal"
          overlayClassName="overlay"
          className="content"
        >
          <div className="title">Bases</div>
          <div
            className="close"
            onClick={() => this.showEditFleetsModal(false)}
          ></div>
          <div className="message">
            <div className="user-tags-options-container">
              {this.props.fleetTags.map((tag) => {
                return (
                  <Tag
                    key={tag.id}
                    id={tag.id}
                    color={tag.color}
                    title={tag.title}
                    type={tag.type}
                    isFull={this.state.newFleets.includes(tag.id)}
                    onClick={this.handleFleetTagClick}
                    parentBackground="light"
                  />
                );
              })}
            </div>
          </div>
          <div className="actions">
            <Button
              text="Continuar"
              type="primary"
              onClick={this.handleChangeFleets}
            />
          </div>
        </Modal>
        <Modal
          isOpen={this.state.showEditFacilitiesModal}
          portalClassName="dialog emails-modal invite-users-modal"
          overlayClassName="overlay"
          className="content"
        >
          <div className="title">Instalaciones</div>
          <div
            className="close"
            onClick={() => this.showEditFacilitiesModal(false)}
          ></div>
          <div className="message">
            <div className="facilities-options-container">
              <div className="search-container">
                <InputSearch
                  type="text"
                  label="Buscar instalaciones"
                  name="usersFacilitySearch"
                  className="full"
                  onChange={this.handleFacilitySearch}
                />
                <img src="/images/search.svg" alt="" className="icono" />
              </div>
              {/* <div className="facilities-display-by">
                Ver como:
                <div className="facilities-radio-container">
                  <label
                    onClick={() => this.setDisplayFacilityBy("name")}
                    className="radio-container"
                  >
                    Nombre
                    <input
                      readOnly
                      type="radio"
                      name="displayFacilityBy"
                      value={"name"}
                      checked={this.state.displayFacilityBy === "name"}
                    />
                    <span className="checkmark check"></span>
                  </label>
                  <label
                    onClick={() => this.setDisplayFacilityBy("alias")}
                    className="radio-container"
                  >
                    Alias
                    <input
                      readOnly
                      type="radio"
                      name="displayFacilityBy"
                      value={"alias"}
                      checked={this.state.displayFacilityBy === "alias"}
                    />
                    <span className="checkmark"></span>
                  </label>
                  <label
                    onClick={() => this.setDisplayFacilityBy("code")}
                    className="radio-container"
                  >
                    Código
                    <input
                      readOnly
                      type="radio"
                      name="displayFacilityBy"
                      value={"code"}
                      checked={this.state.displayFacilityBy === "code"}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
              </div> */}
              <div className="facilities-list-container">
                <Checkbox
                  onChange={(e) => this.selectAllFacilities(e)}
                  item={{
                    id: 0,
                    description: "Todos",
                    active:
                      this.state.newFacilities.length ===
                      this.props.maxFacilities,
                  }}
                  name="facilities"
                  className="bold"
                />
                <div className="separator" />
                <div className="items checks">
                  {
                    // this.props.facilities
                    // .filter(f =>
                    //   normalizeStr(f[this.state.displayFacilityBy]).includes(
                    //     normalizeStr(this.state.searchStr)
                    //   )
                    // )
                    this.handleFilterListSearch(
                      this.props.facilitiesByTag || [],
                      "description",
                      this.state.searchStr
                    ).map((i) => {
                      let l = [];
                      // l.push(
                      //   <Checkbox
                      //     key={i.id}
                      //     onChange={() =>
                      //       this.handleSelectFacility({
                      //         target: {
                      //           id: i.id,
                      //           value: this.state.newFacilities.find(
                      //             si => si.toString() === i.id.toString()
                      //           )
                      //             ? true
                      //             : false
                      //         }
                      //       })
                      //     }
                      //     item={{
                      //       id: i.id,
                      //       description: i[this.state.displayFacilityBy],
                      //       active: this.state.newFacilities.find(
                      //         si => si.toString() === i.id.toString()
                      //       )
                      //         ? true
                      //         : false
                      //     }}
                      //     name="facilities"
                      //     className=""
                      //   />
                      // );
                      l.push(
                        <Checkbox
                          key={counter++}
                          onChange={(e) =>
                            this.handleSelectFacility(e, i.items)
                          }
                          item={{
                            id: i.id,
                            description: i.description,
                            active: (this.state.newFacilities || []).find(
                              (si) => si.toString() === i.id.toString()
                            )
                              ? true
                              : false,
                          }}
                          name="facilities"
                          className={(i.items || []).length ? "bold" : ""}
                        />
                      );
                      if ((i.items || []).length) {
                        i.items.forEach((ci) =>
                          l.push(
                            <Checkbox
                              key={counter++}
                              className={"sub-item"}
                              onChange={(e) => this.handleSelectFacility(e)}
                              item={{
                                id: ci.id,
                                description: ci.description,
                                active: (this.state.newFacilities || []).find(
                                  (si) => si.toString() === ci.id.toString()
                                )
                                  ? true
                                  : false,
                              }}
                              name="facilities"
                            />
                          )
                        );
                        l.push(<div className="separator" key={counter++} />);
                        let full = true;
                        i.items.forEach((fe) =>
                          (this.state.newFacilities || []).find(
                            (si) => si.toString() === fe.id.toString()
                          ) && full
                            ? (full = true)
                            : (full = false)
                        );
                        l[0].props.item.active = full;
                      }
                      return l;
                    })
                  }
                </div>
              </div>
            </div>
          </div>
          <div className="actions">
            <Button
              disabled={this.state.facilityButtonDisabled}
              text={this.state.buttonText}
              type="primary"
              onClick={this.handleChangeFacilities}
            />
          </div>
        </Modal>
        <ConfirmDialog
          open={this.state.showResultModal}
          title={"¡Listo!"}
          message={"Tus modificaciones se han guardado con éxito"}
          acceptText="Aceptar"
          class="result-message"
          closeAction={() => this.showResultModal(false)}
          acceptAction={() => this.showResultModal(false)}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  let facilitiesByTag = [
    { id: 9999999999, description: "Sin etiqueta", items: [] },
  ];
  (state.facilityReducer.facilities.data || []).forEach((f) => {
    if (f.tags.length) {
      f.tags.forEach((t) => {
        let tagIndex = facilitiesByTag.map((fT) => fT.id).indexOf(t.id);
        if (tagIndex !== -1) {
          facilitiesByTag[tagIndex].items.push({
            id: f.id,
            description: f.name.concat(" - ", f.code, " - ", f.alias),
            name: f.name
          });
        } else {
          let newTag = {
            id: t.id,
            description: t.title,
            color: t.color,
            items: [
              {
                id: f.id,
                description: f.name.concat(" - ", f.code, " - ", f.alias),
                name: f.name
              },
            ],
          };
          facilitiesByTag.push(newTag);
        }
      });
    } else {
      facilitiesByTag[0].items.push({
        id: f.id,
        description: f.name.concat(" - ", f.code, " - ", f.alias),
        name: f.name
      });
    }
  });

  facilitiesByTag.push(facilitiesByTag.shift());

  let facilities = state.facilityReducer.facilities.data && state.facilityReducer.facilities.data.map((f) => ({
    id: f.id,
    description: f.name,
    name: f.name,
    code: f.code,
    alias: f.alias,
  }));

  const user_facilities = getEntityItems(state, "COMPANIES.USERS.USERFACILITIES");

  return {
    userInfo: state.userReducer.user.data,
    accountStatusCatalog: state.globalCatalog.accountStatus.data,
    userTypes: state.userReducer.userTypes.data,
    facilitiesByTag,
    maxFacilities: state.facilityReducer.facilities.data.length,
    facilities,
    tags: Array.isArray(state.companyReducer.companyRoles.data)
      ? state.companyReducer.companyRoles.data.map((rol) => ({
          ...rol,
          title: rol.description,
        }))
      : [],
    fleetTags: (state.tags.tags || []).filter(
      (tag) =>
        tag.type ===
        (
          (state.tags.tagTypes.data || []).find(
            (type) => type.description.toLowerCase() === "flotilla"
          ) || { id: 0 }
        ).id
    ),
    user_facilities,
    user_tags: state.userReducer.userTags.data,
    user_fleet_tags: state.userReducer.userFleetTags.data,
    company_users: state.companyReducer.companyUsers.data,
    companyInfo: state.companyReducer.company.data,
    permissionsCatalog: state.globalCatalog.permissionsCatalog.data,
    userPermissions: state.userReducer.userPermissions.data,
    companyType: state.globalCatalog.session.company?.type?.id || 0,
    isCO: (state.globalCatalog.session.company.type.id || 0) === 2,
  };
};

const mapDispatchToProps = (dispatch) => {
  dispatch(getSessionCompanyData());
  dispatch(getUserTypes());
  dispatch(loadCompanyUsers());
  dispatch(loadAccountStatusCatalog());
  dispatch(listFacilities());
  dispatch(loadTags());
  dispatch(listCompanyRoles());
  dispatch(loadPermissionsCatalog());
  return {
    getUser: (userId, companyId) => dispatch(getUser(userId, companyId)),
    getUserInfo: (userId, companyId) =>
      dispatch(getUserInfo(userId, companyId)),
    // getUserFacilities: (userId) => dispatch(loadUserFacilities(userId)),
    getUserFacilities: (params, opt) => 
      dispatch(readEntities("COMPANIES.USERS.USERFACILITIES", params, opt)),
    deleteUserFacility: (user_id, user_facility_id) =>
      dispatch(removeFacilityFromUser(user_id, user_facility_id)),
    addUserFacility: (user_id, facility_id) =>
      dispatch(addFacilityToUser(user_id, facility_id)),
    getUserTags: (userId) => dispatch(getUserTags(userId)),
    getUserFleetTags: (userId) => dispatch(getUserFleetTags(userId)),
    addUserTags: (userId, tagId) => dispatch(addTagToUser(userId, tagId)),
    editUserTags: (userId, newTagId, userTagId) =>
      dispatch(editTagFromUser(userId, newTagId, userTagId)),
    removeUserTags: (userId, userTagId) =>
      dispatch(removeTagFromUser(userId, userTagId)),
    editUserRole: (company_user_id, role_id) =>
      dispatch(changeCompanyUserRole(company_user_id, role_id)),
    removeUserRole: (user_id) => dispatch(removeCompanyUserRole(user_id)),
    getUserPermissions: (user_id) => dispatch(getUserPermissions(user_id)),
    setUserPermissions: (user_id, permissionsMatrix) =>
      dispatch(setUserPermissions(user_id, permissionsMatrix)),
  };
};
const connectToStore = connect(mapStateToProps, mapDispatchToProps);

export default withRouter(connectToStore(UserProfile));

UserProfile.defaultProps = {
  userInfo: {},
};
