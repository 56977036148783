import React from "react";
import Button from "../shared/controls/button";
import LoginDialog from "../shared/dialog/login-modal";
import RegisterDialog from "../shared/dialog/register-modal";
import { CarouselProvider, Slider, Slide } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import RecoverPasswordDialog from "../shared/dialog/recover-password-modal";
import GenericDialog from "../shared/dialog/generic-modal";
import ErrorDialog from "../shared/dialog/error-dialog";
import ResetNewPasswordDialog from "../shared/dialog/reset-new-password-modal";
import ResetPasswordMessageDialog from "../shared/dialog/reset-password-message-modal";
import { BACKEND, getApiUrlWithoutAuthorization } from "../shared/backend-api";
import LoginErrorDialog from "../shared/dialog/login-error-modal";
import { tranformSpecialCharacterUrl } from "../shared/utils";

class Login extends React.Component {
  moveCarousel = (pos) => {
    this.setState({ currentSlide: pos });
  };

  constructor(props) {
    super(props);

    this.state = {
      showLoginModal: false,
      showRegisterModal: false,
      showRecoverPasswordModal: false,
      showRecoverMessageModal: false,
      showRegisterMessageModal: false,
      showResetNewPasswordModal: false,
      showResetNewPasswordMessage: false,
      showSuccessValidation: false,
      showSuccessInvitation: false,
      showRegisterInvitationMessageModal: false,
      showInvitationModal: false,
      showSessionError: false,
      emailInvitation: this.getEmailInvitation(props),
      hasInvitation: false,
      errorMessage: "",
      emailRegister: "",

      currentSlide: 0,
    };
  }

  componentDidMount() {
    const {
      location: { search = "" },
    } = this.props;
    // debugger;
    if (search !== "" && search.length > 1) {
      if (search.includes("valid_email")) {
        const result = search.split("=");

        if (result.length > 1 && String(result[1]) === "success") {
          this.setState({
            showSuccessValidation: true,
          });
        } else if (result.length > 1 && String(result[1]) === "info") {
          this.setState({
            errorMessage: "Tu cuenta ya fue validada previamente",
          });
        } else {
          this.setState({
            errorMessage:
              "Ocurrió un error al validar su email, intentalo más tarde",
          });
        }
      } else if (search.includes("invitation")) {
        const result = search.split("=");

        if (result.length > 1 && String(result[1]) === "success") {
          this.setState({
            showSuccessInvitation: true,
          });
        } else if (result.length > 1 && String(result[1]) === "error") {
          this.setState({
            errorMessage:
              "Ocurrió un error al aceptar la invitación, intentalo más tarde",
          });
        } else {
          this.setState({
            errorMessage: decodeURI(String(result[1])),
          });
        }
      } else if (search.includes("email")) {
        const result = search.split("=");

        if (result.length > 1) {
          let email = result[1];
          getApiUrlWithoutAuthorization(BACKEND.USERS.SIGN_UP.CHECK_EMAIL, {
            email: tranformSpecialCharacterUrl(email)
          })
            .then((response) => response.json())
            .then((response) => {
              if (response.response === "ok") {
                this.setState(
                  {
                    hasInvitation: true,
                    showRegisterModal: true,
                  },
                  () => this.setState({ emailInvitation: String(result[1]) })
                );
              }
            })
            .catch((error) =>
              error.then((e) => {
                if (e.response === "duplicated") {
                  this.setState({
                    showInvitationModal: true,
                  });
                } else {
                  this.setState({
                    errorMessage:
                      "Ocurrió un error al validar su email, intentalo más tarde",
                  });
                }
              })
            );
        } else {
          this.setState({
            errorMessage:
              "Ocurrió un error al validar su email, intentalo más tarde",
          });
        }
      } else if (search.includes("register")){

        const result = search.split("user=");

        if (result.length > 1) {

          let email = result[1];
          getApiUrlWithoutAuthorization(BACKEND.USERS.SIGN_UP.CHECK_EMAIL, {
            email: tranformSpecialCharacterUrl(email)
          })
            .then((response) => response.json())
            .then((response) => {
              if (response.response === "ok") {
                this.setState(
                  {
                    hasInvitation: true,
                    showRegisterModal: true
                  },
                  () => this.setState({ emailRegister: String(result[1]) })
                );
              }
            })
            .catch((error) =>
              error.then((e) => {
                if (e.response === "duplicated") {
                  this.setState({
                    showInvitationModal: true,
                  });
                } else {
                  this.setState({
                    errorMessage:
                      "Ocurrió un error al validar su email, intentalo más tarde",
                  });
                }
              })
            );
        } else {
          this.setState({
            hasInvitation: true,
            showRegisterModal: true
          });
        }
      } else if (search.includes("expiration")) {
        this.setState({
          showSessionError: true,
        });
      } else {
        const token = search.slice(3);

        this.setState({
          showResetNewPasswordModal: true,
          token,
        });
      }
    }
  }

  getEmailInvitation = (props) => {
    const {
      location: { search = "" },
    } = props;

    if (search !== "" && search.length > 1 && search.includes("email")) {
      const result = search.split("=");

      if (result.length > 1) {
        return String(result[1]);
      } else {
        return "";
      }
    }

    return "";
  };

  handleEnd = () => {
    const { currentSlide } = this.slider.context.state;
    this.setState({ currentSlide });
  };

  closeModals = () =>
    this.setState({
      showLoginModal: false,
      showRegisterModal: false,
      showRecoverPasswordModal: false,
      showRecoverMessageModal: false,
      showRegisterMessageModal: false,
      errorMessage: "",
      showResetNewPasswordModal: false,
      showResetNewPasswordMessage: false,
      showSuccessValidation: false,
      showSuccessInvitation: false,
      showRegisterInvitationMessageModal: false,
      showSessionError: false
    });

  forgotModal = (value) => {
    this.setState({
      showLoginModal: false,
      showRecoverPasswordModal: value,
    });
  };

  changeToLogin = () => {
    this.setState({
      showRegisterModal: false,
      showLoginModal: true,
    });
  };

  render() {
    return (
      <div className="login-view">
        <div className="top-container">
          <img
            src="/images/orbinetwork-icon.svg"
            className="orbi-icon"
            alt=""
          />

          <div className="login-form">
            <Button
              text={"Crear tu cuenta"}
              type={"secondary"}
              onClick={() => this.setState({ showRegisterModal: true })}
            />

            <div style={{ width: "10px" }} />

            <Button
              text={"Iniciar sesión"}
              type={"primary"}
              onClick={() => this.setState({ showLoginModal: true })}
            />
          </div>

          {/* <div className='forgot-link' onClick={ () => this.setState({showRecoverPasswordModal: true}) }>¿Olvidaste tu contraseña?</div> */}
        </div>

        <div className="content">
          <div className="title">Conecta, visualiza</div>
          <div className="title">y evalúa</div>
          <div className="sub-title">tus cadenas de abastecimiento</div>
          <CarouselProvider
            naturalSlideWidth={1}
            naturalSlideHeight={1}
            totalSlides={3}
            currentSlide={this.state.currentSlide}
            className="carousel"
            dragEnabled={true}
          >
            <Slider
              ref={(ref) => (this.slider = ref)}
              onTransitionEnd={this.handleEnd}
            >
              <Slide index={0}>
                {Panel(
                  "01",
                  `Controla en tiempo real tu cadena de logística internacional, nacional o de distribución interna; desde la orden de compra hasta que la mercancía llegue a destino`
                )}
              </Slide>
              <Slide index={1}>
                {Panel(
                  "02",
                  "Conecta tu red, clientes, proveedores, y transportistas de todo el mundo"
                )}
              </Slide>
              <Slide index={2}>
                {Panel(
                  "03",
                  "Genera tus CFDIs de Ingreso y Traslado con complemento carta porte de forma fácil y rápida y deja que las cancelaciones y nuevos timbrados se generen de forma automática y simultanea cada vez que se modifican tus embarques."
                )}
              </Slide>
            </Slider>
          </CarouselProvider>
          <div
            className="indicators"
            style={{ zIndex: "2", position: "relative", width: "60%" }}
          >
            <span
              onClick={() => this.moveCarousel(0)}
              className={this.state.currentSlide === 0 ? "dot active" : "dot"}
            ></span>
            <span
              onClick={() => this.moveCarousel(1)}
              className={this.state.currentSlide === 1 ? "dot active" : "dot"}
            ></span>
            <span
              onClick={() => this.moveCarousel(2)}
              className={this.state.currentSlide === 2 ? "dot active" : "dot"}
            ></span>
          </div>
        </div>

        <div style={{
          position: 'absolute',
          fontSize: 15,
          fontWeight: 'bold',
          textAlign: 'center',
          bottom: 20,
          left: 0,
          right: 0
        }}>Copyright 2024: On Tech Development S de RL de CV</div>

        <LoginDialog
          open={this.state.showLoginModal}
          title="Iniciar sesión"
          closeAction={() => this.closeModals()}
          closure={() => this.setState({ showRegisterModal: true })}
          showError={(errorMessage) => {
            this.setState({ errorMessage });
          }}
          forgotAction={this.forgotModal}
        />

        <RegisterDialog
          open={this.state.showRegisterModal}
          title="Crea tu cuenta"
          closeAction={() => this.closeModals()}
          closure={() => {
            if (this.state.emailRegister !== '') {
              this.setState({ showRegisterInvitationMessageModal: true, emailRegister: "" });
              return;
            }

            if (this.state.emailInvitation === "") {
              this.setState({ showRegisterMessageModal: true });
            } else {
              this.setState({ showRegisterInvitationMessageModal: true });
            }
          }}
          showError={(errorMessage) => {
            this.setState({ errorMessage });
          }}
          emailInvitation={this.state.emailInvitation}
          hasInvitation={this.state.hasInvitation}
          changeToLogin={() => this.changeToLogin()}
          emailRegister={this.state.emailRegister}
        />

        <ResetNewPasswordDialog
          open={this.state.showResetNewPasswordModal}
          token={this.state.token}
          title="Crea contraseña nueva"
          closeAction={() => this.closeModals()}
          closure={() => this.setState({ showResetNewPasswordMessage: true })}
          showError={(errorMessage) => {
            this.setState({ errorMessage });
          }}
        />

        <ResetPasswordMessageDialog
          open={this.state.showResetNewPasswordMessage}
          message="Tu contraseña fue modificada con éxito"
          acceptAction={() => {
            this.closeModals();
            this.setState({ showLoginModal: true });
          }}
          closeAction={() => {
            this.closeModals();
            this.setState({ showLoginModal: true });
          }}
        />

        <RecoverPasswordDialog
          open={this.state.showRecoverPasswordModal}
          title="Recupera tu contraseña"
          closeAction={() => this.closeModals()}
          closure={() => this.setState({ showRecoverMessageModal: true })}
        />

        <GenericDialog
          open={this.state.showSessionError}
          title="¡Error!"
          message="La sesión se ha expirado"
          acceptAction={() => this.closeModals()}
          closeAction={() => this.closeModals()}
        />

        <GenericDialog
          open={this.state.showRecoverMessageModal}
          title="¡Enviado!"
          message="Te hemos enviado un correo, para que puedas cambiar tu contraseña"
          acceptAction={() => this.closeModals()}
          closeAction={() => this.closeModals()}
        />

        <GenericDialog
          open={this.state.showSuccessValidation}
          title="Éxito"
          message="Tu email ha sido verificado."
          acceptAction={() => this.closeModals()}
          closeAction={() => this.closeModals()}
        />

        <GenericDialog
          open={this.state.showSuccessInvitation}
          title="Éxito"
          message="La invitación ha sido aceptada."
          acceptAction={() => this.closeModals()}
          closeAction={() => this.closeModals()}
        />

        <GenericDialog
          open={this.state.showRegisterMessageModal}
          title="Listo!"
          message="Te hemos enviado un correo, para que confirmes tu cuenta"
          acceptAction={() => {
            this.closeModals();
            window.location.href = "/register/account/";
          }}
          closeAction={() => this.closeModals()}
        />

        <GenericDialog
          open={this.state.showRegisterInvitationMessageModal}
          title="¡Listo!"
          message="Tu cuenta ha sido creada con éxito"
          acceptAction={() => {
            this.closeModals();
            window.location.href = "/register/account/";
          }}
          closeAction={() => this.closeModals()}
        />

        <ErrorDialog
          addClass="invitation-modal"
          open={this.state.showInvitationModal}
          message="Ya tienes una cuenta"
          acceptText="Iniciar Sesión"
          acceptAction={() => {
            this.setState({ showInvitationModal: false, showLoginModal: true });
          }}
          closeAction={() => {
            this.setState({ showInvitationModal: false });
          }}
        />
        
        <LoginErrorDialog
          open={this.state.errorMessage !== ""}
          message={this.state.errorMessage}
          acceptText="Aceptar"
          acceptAction={() => {
            this.closeModals();
            this.setState({ emailInvitation: "" });
          }}
          openCreateAccount={ () => {
            this.setState({
              errorMessage: '',
              showRegisterModal: true,
            });
          }}
          openForgetPassword={() => {
            this.setState({
              errorMessage: '',
              showRecoverPasswordModal: true,
            });
          }}
          closeAction={() => {
            this.closeModals();
            this.setState({ emailInvitation: "" });
          }}
        />
      </div>
    );
  }
}

const Panel = (number, text) => {
  return (
    <div className={"panel "}>
      <div className="title">
        {/* <div className="number">
          {number}
          <div className="border"></div>
        </div> */}
        {text}
      </div>
    </div>
  );
};

export default Login;
