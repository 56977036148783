import React from "react";
import { getUrlsEnv } from "../backend-api";
import ValidatePerms from "./validate-perms";

let defaultData = {
  profile_image: "/images/user-white.svg",
  mail_image: "/images/mail.svg",
  connection: "connected",
  name: "",
  email: "",
  status: ""
};

class UserRow extends React.Component {
  render() {
    return (
      <div className="user-row">
        <div className="user-avatar">
          <div
            className={"user-image ".concat(
              !this.props.user.profile_image ||
                this.props.user.user_status === null
                ? "default"
                : ""
            )}
          >
            <img
              alt=""
              src={
                this.props.user.profile_image
                  ? getUrlsEnv().files.concat(this.props.user.profile_image)
                  : this.props.user.user_status === null
                  ? defaultData.mail_image
                  : defaultData.profile_image
              }
            />
          </div>
          <div className={"status ".concat(defaultData.connection)}></div>
        </div>
        <div className="user-name">
          {this.props.user.full_name || this.props.user.email}
        </div>
        <div className="user-mail">{this.props.user.email || ""}</div>
        <div className="user-status">
          {USER_STATUS[this.props.user.status_id]}
        </div>
        <div className="user-actions">
          {/* <ValidatePerms
            perms={[
              {
                id: "30",
                perm: "d"
              }
            ]}
          > */}
            <img
              alt="bin"
              src="/images/bin.png"
              onClick={() => this.props.delete(this.props.user)}
            />
          {/* </ValidatePerms> */}
        </div>
      </div>
    );
  }
}

const USER_STATUS = {
  "1": "No verificado no vinculado",
  "2": "No verificado vinculado",
  "3": "Verificado no vinculado",
  "4": "Verificado vinculado",
  "5": "Invitado"
};

export default UserRow;

// email: null
// email_invited: "training@orbinetwork.com"
// first_name: null
// id: null
// invitation_id: 236
// invitation_status: 1
// invitation_type: 3
// last_login: null
// last_name: null
// profile_image: null
// user_status: null
// username: null
