import React from "react";
import { View, ViewTitle } from "../../shared/styled/view";
import styled from "styled-components";
import { readEntities } from "../../redux/api";
import { connect } from "react-redux";

import Loader from "../../shared/icons/tat/loader";
import { cancelMultipleRequests, partialEntity, readEntity } from "../../redux/api/actions";
import Button from "../../shared/controls/button";
import { Grid } from "./business-intelligence-table";
import FilterBarCompact from "../../shared/component/filter/filter-bar-compact";
import ConfirmDialog from "../../shared/dialog/confirm-dialog";
import TextInput from "../../shared/controls/text-input";
import CustomContentDialog from "../../shared/dialog/custom-content-dialog";

class BusinessIntelligenceProductivityView extends React.Component {

  state = {
    errorMessage: '',
    configuration: null,
    searchStr: "",
    searchValues: [],
    vehiclesToShow: [],
    buttonFilterName: "Aplicar",
    searchType: "1",
    filterParams: {
      appointment: []
    },
    newNameReportModal: false,
    exitReportModal: false,
    nameInput: '',
    errorMessageBelow: '',
    gridFilter: null
  }

  componentDidMount() {

    this.loadConfigurationData(() => {

      let filter = this.state.configuration['filter'];

      if (filter !== undefined && typeof filter === "object" && filter['main_filter'] !== undefined && typeof filter['main_filter'] === "object") {
        let main_filter = filter['main_filter'];

        if ('dates' in main_filter && main_filter['dates'] !== '' && main_filter['dates'].length > 0) {

          if (main_filter['dates'].length > 1) {
            this.state.filterParams.appointment = [
              new Date(main_filter['dates'][0]),
              new Date(main_filter['dates'][1])
            ]
          } else if (main_filter['dates'].length === 0) {
            this.state.filterParams.appointment = [
              new Date(main_filter['dates'][0])
            ]
          }

        }

        this.setState({}, () => this.loadCFDIData());
      } else {
        this.loadCFDIData();
      }

    });

    document.addEventListener("keydown", this.cancelUpdate);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.cancelUpdate, true);
  }

  buildMainFilterParams = () => {
    return {
      company_id: this.props.companyId,
      isMultipleCancels: true,
      dates: this.state.filterParams.appointment
    };
  }

  loadConfigurationData = (callback) => {

    this.props.getConfiguration({
      company_id: this.props.companyId,
      configuration_id: this.props.match.params.configurationId
    }, {
      onSuccess: (e) => this.setState({configuration: e}, () => {
        if (callback) {
          callback();
        }
      })
    });
  }

  loadCFDIData = () => {
    this.props.getCFDIs({
      company_id: this.props.companyId,
      isMultipleCancels: true,
      dates: this.state.filterParams.appointment[0] 
          ? "".concat(this.state.filterParams.appointment[0].toISOString(), ",", this.state.filterParams.appointment[1] ? this.state.filterParams.appointment[1].toISOString() : this.state.filterParams.appointment[0].toISOString())
          : "",
    });
  }

  cancelUpdate = (e) => {
    if (e.key === "Escape" && this.props.isLoading) {
      this.props.cancelMultipleRequests();
    }
  }

  ShipmentsFilters = () => {
    return [
      {
        title: "Fecha",
        name: "appointment",
        listType: 7,
        time: true
      }
    ];
  };

  updateNameConfiguration = () => {

    this.setState({errorMessageBelow: ''});

    this.props.updateConfiguration({
      company_id: this.props.companyId,
      name: this.state.nameInput,
      configuration_id: this.props.match.params.configurationId
    },{
      alias: "BIBUSINESSINTELLIGENCE_UPDATE",
      onSuccess: () => {
        this.setState({nameInput: '', newNameReportModal: false});
        this.loadConfigurationData();
      },
      onError: (e) => {
        this.setState({errorMessageBelow: e.response?.data?.error || 'Ocurrió un error'})
      }
    });

  }

  updateFilterConfiguration = (callback) => {

    this.setState({errorMessage: ''});

    let filter = this.state.gridFilter;
    filter['main_filter'] = this.buildMainFilterParams();

    this.props.updateConfiguration({
      company_id: this.props.companyId,
      filter,
      configuration_id: this.props.match.params.configurationId
    },{
      alias: "BIBUSINESSINTELLIGENCE_UPDATE_CONFIGURATION",
      onSuccess: () => {
        if (callback) {
          callback()
        }
      },
      onError: (e) => {
        this.setState({errorMessage: e.response?.data?.error || 'Ocurrió un error'})
      }
    });

  }

  render() {
    return (
      <View>
        <ViewTitle>
          {
            this.props.isLoading &&
            <div
              style={{
                position: "absolute",
                top: "0",
                left: "0",
                width: "100%",
                height: "100%",
                backgroundColor: "#00000080",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                zIndex: 1001,
                backdropFilter: "blur(3px)"
              }}
            >
              <div style={{display: "flex", alignItems: "center"}}>
                <Loader width="100px" height="100px"/>
                <div style={{color: "#fff", fontSize: "36px"}}>
                  Cargando
                </div>
              </div>
              <div style={{color: "#fff", fontSize: "30px"}}>
                Si desea cancelar la consulta, presione ESC
              </div>
            </div>
          }
          <span>
            <div style={{display: 'flex'}}>
              {this.state.configuration ? this.state.configuration.name : ''}
              <img
                style={{cursor: 'pointer', marginLeft: 20, width: 30, height: 30, marginTop: 8}}
                src="/images/edit_blue.svg"
                alt=""
                onClick={ () => {
                  this.setState({newNameReportModal: true})
                }}
              />
            </div>
          </span>

          <FilterBarCompact
            filtered={false}
            showTextSearch={false}
            searchAction={(e) => {}}
            content={this.ShipmentsFilters()}
            filterAction={() => {
              if(this.state.buttonFilterName === "Aplicar"){
                this.setState(
                  {
                    offset: 1,
                    buttonFilterName: "Restablecer"
                  },
                  () => { this.loadCFDIData(); }
                );
              } else {
                this.setState(
                  {
                    searchValues: [], 
                    searchStr: '', 
                    filterParams: {
                      dates: []
                    },
                    buttonFilterName: "Aplicar"
                  },
                  () => { this.loadCFDIData(); }
                );
              }
            }}
            onChange={(e) => {

              this.state.filterParams = {
                ...this.state.filterParams,
                [e.target.name]: e.target.value,
              };

              this.setState({
                searchValues: [], 
                searchStr: '', 
                buttonFilterName: "Aplicar"
              });
            }}
            buttonFilterName={this.state.buttonFilterName}
            selectedItems={this.state.filterParams}
          />
            
        </ViewTitle>
        {
          this.props.isLoading ? <div/> :
          this.props.cfdis.length === 0 ?
          <NoResultsStyled>
            No se encontró ningún dato
            <LoadContentStyled>
              <Button
                settings={{
                  type: "btn primary small",
                  text: "Cargar",
                  onClick: () => this.loadCFDIData(),
                }}
              />
            </LoadContentStyled>
          </NoResultsStyled> : 
          
          <Grid
            configuration={this.state.configuration?.filter || {}}
            isLoading={this.props.isUpdatingConfigLoading}
            rowData={this.props.cfdis}
            setNewFilter={(filter) => this.setState({exitReportModal: true, gridFilter: filter})}
          />
        }

        <ConfirmDialog
          open={this.state.newNameReportModal}
          message="Nombra el nuevo reporte que deseas guardar"
          contentObject={
            <div className="input-tagger-container">
              <TextInput
                label='Ingresa el nombre'
                type='text'
                value={this.state.nameInput}
                name='nameInput'
                onChange={(e) => {
                  const { name, value } = e.target;
                  this.setState({[name]: value});
                }}
              />
              {
                this.state.errorMessageBelow !== '' ? <ErrorMessageBelow>{`*${this.state.errorMessageBelow}`}</ErrorMessageBelow> : <></>
              }
            </div>
          }
          acceptText="Continuar"
          class="bi-business-intelligence-modal-name"
          closeAction={() => this.setState({newNameReportModal: false})}
          acceptAction={() => this.updateNameConfiguration()}
          disabledAccept={this.state.nameInput === ''}
          isAcceptLoading={this.props.isUpdatingLoading && this.state.errorMessageBelow === ''}
        />

        <CustomContentDialog
          open={this.state.exitReportModal}
          title=' '
          message="¿Deseas guardar la configuración de esta tabla y sus gráficas?"
          class="bi-business-intelligence-modal-name"
          contentObject={
            <div style={{
              display: 'flex',
              justifyContent: 'end',
              marginTop: 45,
              fontSize: 'normal'
            }}>
              <Button
                settings={{
                  type: "btn primary outline dark",
                  text: "Cancelar",
                  onClick: () => this.setState({ exitReportModal: false }),
                }}
              />

              <div style={{width: 20, height: 20}}/>

              <Button
                settings={{
                  type: "btn primary",
                  text: "Guardar y Continuar",
                  onClick: () => {
                    this.setState({
                      exitReportModal: false
                    }, () => this.updateFilterConfiguration());
                  },
                }}
              />

              <div style={{width: 20, height: 20}}/>

              <Button
                settings={{
                  type: "btn primary",
                  text: "Guardar y Cerrar",
                  onClick: () => {
                    this.setState({
                      exitReportModal: false
                    }, () => this.updateFilterConfiguration(() => window.history.back()));
                  }
                }}
              />
            </div>
          }
          closeAction={() => this.setState({exitReportModal: false})}
        />
      </View>
    );
  }
}

const mapStateToProps = (state) => {

  let isLoading = state?.api["COMPANIES.REPORTS.CFDI"]?.status?.isFetching || false;
  let cfdis = state?.api["COMPANIES.REPORTS.CFDI"]?.items || [];

  let isConfigurationLoading = state?.api["COMPANIES.BIBUSINESSINTELLIGENCE.DETAIL"]?.status?.isFetching || false;
  let isUpdatingLoading = state?.api["BIBUSINESSINTELLIGENCE_UPDATE"]?.status?.isFetching || false;
  let isUpdatingConfigLoading = state?.api["BIBUSINESSINTELLIGENCE_UPDATE_CONFIGURATION"]?.status?.isFetching || false;

  return {
    isUpdatingConfigLoading,
    isLoading: isLoading || isConfigurationLoading,
    isUpdatingLoading,
    cfdis,
    companyId: state.globalCatalog.session.company.id
  };
}

const mapDispatchToProps = (dispatch) => {
  dispatch(readEntities("COMPANIES.CATALOGS.TRIPSTATUS"));
  
  return {
    cancelMultipleRequests: () => dispatch(cancelMultipleRequests()),
    getConfiguration: (params, opts) => dispatch(readEntity("COMPANIES.BIBUSINESSINTELLIGENCE.DETAIL", params, opts)), 
    updateConfiguration: (params, opt) => dispatch(partialEntity("COMPANIES.BIBUSINESSINTELLIGENCE.DETAIL", params, opt)),
    getCFDIs: (params, opts) => dispatch(readEntities("COMPANIES.REPORTS.CFDI", params, opts)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BusinessIntelligenceProductivityView);

const NoResultsStyled = styled.div`
  text-align: center;
  color: white;
  font-size: 35px;
  margin-top: 80px;
`;

const LoadContentStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 15px;
`;

const ErrorMessageBelow = styled.div`
  font-size: 13px;
  color: red;
  font-weight: normal;
`;