import React, { useState, useMemo, useCallback, createRef } from "react";
import moment from 'moment';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import Button from "../../../shared/controls/button";
import { AG_GRID_LOCALE_ES } from "../../../shared/language";
import { RequisitionLeft, RequisitionCenter } from './style';
import ImageWithCheckMark from "../../../shared/component/image-with-check-mark";
import { getUrlsEnv } from "../../../shared/backend-api";
import ReactTooltip from "react-tooltip";
import { cloneDeep } from "lodash";

const filterParams = {
  comparator: (filterLocalDateAtMidnight, cellValue) => {
    const dateAsString = cellValue;
    if (dateAsString == null) return -1;
    const dateParts = dateAsString.split("/");
    const cellDate = new Date(
      Number(dateParts[2]),
      Number(dateParts[1]) - 1,
      Number(dateParts[0]),
    );

    if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
      return 0;
    }

    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    }

    if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    }
    return 0;
  }
};

const getCellView = (key, params, props) => {
  let view = null;

  switch(key) {
    case 'sku_variants':
      view = <RequisitionLeft>{`${(params.data?.sku_variants || '').split(',').length}`}</RequisitionLeft>
      break;
    case 'total_volume':
      view = <RequisitionLeft>{`${params.data?.total_volume || 0} cbm`}</RequisitionLeft>
      break;
    case 'total_weight':
      view = <RequisitionLeft>{`${params.data?.total_weight || 0} kg`}</RequisitionLeft>
      break;
    case 'requisition_status_description':
      view = <RequisitionLeft>{params.data?.status?.description || '-'}</RequisitionLeft>
      break;
    case 'origin_name':
      view = <RequisitionLeft>{params.data?.from_facility?.name || '-'}</RequisitionLeft>
      break;
    case 'destiny_name':
      view = <RequisitionLeft>{params.data?.to_facility?.name || '-'}</RequisitionLeft>
      break;
    case 'company':
      let company = props.transactionType === 2 ? params.data?.to_facility?.company || {} : params.data?.from_facility?.company || {}
      view = <RequisitionLeft>
        
        <ImageWithCheckMark
          src={getUrlsEnv().files.concat(company.logo?.substring(1) || '')}
          width={30}
          isCheckMark={company.verification_status === 3}
          customStyle={{
            width: 30, height: 30, borderRadius: '50%', position: 'relative', marginRight: 12, marginTop: 8
          }}
          isCo={true}
        />
        
        <div>{company.orbi_id}</div>
      </RequisitionLeft>
      break;
    case 'cta':
      const isRow = params.node.data;
      if (!isRow) { view = <div></div>; break; }

      let status_id = params.data?.status?.id || 0;

      if (status_id === 1) {
        view = <div style={{width: '100%', height: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>

          {
            params.data.last_facility !== props.facility ?
            <Button 
              text={'Confirmar'}
              type='primary small'
              onClick={() => {
                if (props.isModeSelection) return;

                props.handleChange({
                  target: {
                    name: "confirm_requisition",
                    value: params.data
                  }
                });

              }}
            /> :
            <>
              <div
                data-tip
                data-for={`confirm_requisition-${params.data.id}`}
                onMouseEnter={(e) => {
                  ReactTooltip.hide();
                  ReactTooltip.show(e.currentTarget);
                }}
                onMouseLeave={() => {
                  ReactTooltip.hide();
                }}
              >
                <Button 
                  disabled={true}
                  text={'Confirmar'}
                  type='primary small'
                  onClick={() => {}}
                />
              </div>
              <ReactTooltip
                id={`confirm_requisition-${params.data.id}`}
                className='eta-tooltip-content'
                event="focusin"
                eventOff="focusout"
                place="left"
              > 
                {
                  params.data.last_facility === null ? <div style={{lineHeight: 'normal'}}>{'No tiene información de instalación'}</div> :
                  <div style={{lineHeight: 'normal'}}>{ `En espera de confirmación de ${props.transactionType === 1 ? 'Proveedor' : props.transactionType === 2 ? 'Cliente' : 'Proveedor/Cliente'}` }</div>
                }
              </ReactTooltip>
            </>
          }

          </div>
      } else if (status_id === 2) {

        let has_deliveries_to_convert = false;

        (params.data.deliveries || []).map(item => {
          if (item.order_id === null) {
            has_deliveries_to_convert = true;
          }
        });

        view = <div style={{width: '100%', height: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          {
            has_deliveries_to_convert ? 
            <Button 
              text={'Convertir Pedido en Orden'}
              type='primary small'
              onClick={() => {
                if (props.isModeSelection) return;
                
                props.handleChange({
                  target: {
                    name: "convert_delivery_to_order",
                    value: {requisition: params.data, deliveries: (params.data.deliveries || []).filter( item => item.order_id === null)}
                  }
                });
              }}
            /> :
            <>
              <div
                data-tip
                data-for={`convert-delivery-to-order-${params.data.id}`}
                onMouseEnter={(e) => {
                  ReactTooltip.hide();
                  ReactTooltip.show(e.currentTarget);
                }}
                onMouseLeave={() => {
                  ReactTooltip.hide();
                }}
              >
                <Button 
                  disabled={true}
                  text={'Convertir Pedido en Orden'}
                  type='primary small'
                  onClick={() => {}}
                />
              </div>
              <ReactTooltip
                id={`convert-delivery-to-order-${params.data.id}`}
                className='eta-tooltip-content'
                event="focusin"
                eventOff="focusout"
                place="left"
              > 
                {
                  <div style={{lineHeight: 'normal'}}>{ 'El pedido ya tiene todas sus entregas convertidas en orden.' }</div>
                }
              </ReactTooltip>
            </>
          }
            
          </div>
      } else {
        view = <div/>;
      }

      break;
    default:
      view = <div></div>;
  }

  return view
};

const getCorrectName = (field, default_columns) => {
  let column = default_columns.filter( item => item.field === field);
  return (column && column.length > 0) ? column[0].headerName : null;
}

const getColumnsWithCustomCells = (columns, default_columns, props) => {

  columns.map( (item, index) => {

    let new_header_name = getCorrectName(item.field, default_columns);
    if (new_header_name !== null) {
      columns[index].headerName = new_header_name;
    }

    if (['sku_variants', 'total_weight', 'total_volume', 'company', 'origin_name', 'destiny_name', 'requisition_status_description', 'cta'].includes(item.field)) {
      columns[index].cellRenderer = params => {
        return getCellView(item.field, params, props)
      }
    }

  })

  return columns;
}

const applyNewColumns = (temp_colDefs, props) => {

  if (props.configuration['columns'] !== undefined && typeof props.configuration['columns'] === "object") {

    let temp_columns = props.configuration['columns'];

    if (temp_columns.length === temp_colDefs.length) {
      return getColumnsWithCustomCells(temp_columns, temp_colDefs, props);
    }

    try {

      let currentColumns = temp_colDefs.map( item => item.field);
      let currentColumnsSaved = props.configuration['columns'].map( item => item.field);
      let difference = currentColumns.filter(x => !currentColumnsSaved.includes(x));

      let newColumnsToAdd = [];

      temp_colDefs.map( (item, index) => {
        if (difference.includes(item.field)) {
          newColumnsToAdd.push({
            index,
            column: item
          })
        }
      });

      newColumnsToAdd.map( item => {
        temp_columns.splice(item.index, 0, item.column);
      });

      return getColumnsWithCustomCells(temp_columns, temp_colDefs, props);

    } catch (error) {
      console.error('Error setting columns definitions', error);

      return getColumnsWithCustomCells(temp_columns, temp_colDefs, props);
    }
  }
}

export const RequisitionView = (props) => {

  const gridRef = createRef();
  const [rowData, setRowData] = useState(props.rowData);
  const [called, setCalled] = useState(false);

  let default_columns = [
    { headerName: 'Fecha de Creación del Pedido', field: 'created', minWidth: 270, filter: 'agDateColumnFilter', filterParams: filterParams, suppressMenu: true, checkboxSelection: true, sortable: true, headerCheckboxSelection: true },
    { headerName: 'Fecha de Última Actualización', field: 'modified', minWidth: 250, filter: 'agDateColumnFilter', filterParams: filterParams, suppressMenu: true, sortable: true },
    { headerName: 'ID de Pedido', field: 'requisition_id', suppressMenu: true, filter: 'agTextColumnFilter', minWidth: 200, sortable: true },
    { headerName: 'Pedido', field: 'code', suppressMenu: true, filter: 'agTextColumnFilter', minWidth: 200, sortable: true },
    { headerName: 'Peso Bruto Total del Pedido', field: 'total_weight', minWidth: 250, filter: 'agNumberColumnFilter', suppressMenu: true, sortable: true,
      cellRenderer: params => {
        return getCellView('total_weight', params)
      }
    },
    { headerName: 'Volumen Total del Pedido', field: 'total_volume', minWidth: 230, filter: 'agNumberColumnFilter', suppressMenu: true, sortable: true,
      cellRenderer: params => {
        return getCellView('total_volume', params)
      }
    },
    { headerName: 'Variantes SKU', field: 'sku_variants', minWidth: 200, filter: 'agTextColumnFilter', suppressMenu: true, sortable: true,
      cellRenderer: params => {
        return getCellView('sku_variants', params)
      }
    },
    { headerName: 'Cantidad', field: 'sku_quantities', minWidth: 200, filter: 'agTextColumnFilter', suppressMenu: true, sortable: true, aggFunc: 'sum'},
    { headerName: 'Entregas', field: 'deliveries_count', minWidth: 200, filter: 'agTextColumnFilter', suppressMenu: true, sortable: true, aggFunc: 'sum' },
    { headerName: 'Contador de Órdenes', field: 'orders_count', minWidth: 200, filter: 'agTextColumnFilter', suppressMenu: true, sortable: true, aggFunc: 'sum'},
    { headerName:  props.transactionType === 1 ? 'Proveedor' : props.transactionType === 2 ? 'Cliente' : 'Proveedor/Cliente', field: 'company', minWidth: 200, filter: 'agTextColumnFilter', suppressMenu: true, sortable: true,
      cellRenderer: params => {
        return getCellView('company', params, props)
      }
    },
    { headerName: 'Origen', field: 'origin_name', minWidth: 200, filter: 'agTextColumnFilter', suppressMenu: true, sortable: true,
      cellRenderer: params => {
        return getCellView('origin_name', params)
      }
    },
    { headerName: 'Destino', field: 'destiny_name', minWidth: 200, filter: 'agTextColumnFilter', suppressMenu: true, sortable: true,
      cellRenderer: params => {
        return getCellView('destiny_name', params)
      }
    },
    { headerName: 'Estatus de Pedido', field: 'requisition_status_description', minWidth: 200, filter: true, suppressMenu: true, sortable: true, filter: 'agSetColumnFilter',
      cellRenderer: params => {
        return getCellView('requisition_status_description', params)
      }
    },
    { headerName: 'CTA', field: 'cta', minWidth: 230, suppressMenu: true, 
      cellRenderer: params => {
        return getCellView('cta', params, props)
      }
    },
    { headerName: 'Acciones', field: 'actions', minWidth: 200, suppressMenu: true, tooltipField: 'actions' }
  ];
  
  let new_def = applyNewColumns(cloneDeep(default_columns), props);
  
  if (new_def && new_def.length > 0) {
    default_columns = new_def;
  }
    
  const [colDefs, setColDefs] = useState(default_columns);

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      floatingFilter: true,
      tooltipComponent: params => {

        let has_deliveries_to_convert = false;

        (params.data.deliveries || []).map(item => {
          if (item.order_id === null) {
            has_deliveries_to_convert = true;
          }
        });

        return(
          <div
            style={{
              backgroundColor: 'black',
              color: 'white',
              padding: 10,
              borderRadius: 10
            }}
          >
            {
              (params.data?.status?.id || 0) !== 1 ? '' :
              has_deliveries_to_convert ?
              <>
                <div
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    props.handleChange({
                      target: {
                        name: "convert_delivery_to_order",
                        value: {requisition: params.data, deliveries: (params.data.deliveries || []).filter( item => item.order_id === null)}
                      }
                    });
                  }}
                >{'Convertir Pedido en Orden'}</div>
                <div style={{height: 8}}/>
              </> :
              <>
                <div
                  data-tip
                  data-for={`convert-to-order-${params.data.id}`}
                  onMouseEnter={(e) => {
                    ReactTooltip.hide();
                    ReactTooltip.show(e.currentTarget);
                  }}
                >{'Convertir Pedido en Orden'}</div>
                <div style={{height: 8}}/>

                <ReactTooltip
                  id={`convert-to-order-${params.data.id}`}
                  className='eta-tooltip-content'
                  event="focusin"
                  eventOff="focusout"
                  place="top"
                > 
                  <>
                    <div>{ 'El pedido ya tiene todas sus entregas convertidas en orden.' }</div>
                  </>
                </ReactTooltip>
              </>
            }


            {
              params.data.orders_count === 0 && (params.data?.status?.id || 0) !== 3 ?
              <div
                style={{cursor: 'pointer'}}
                onClick={() => {
                  props.handleChange({
                    target: {
                      name: "cancel-requisition-from-actions",
                      value: params.data
                    }
                  })
                }}
              >{'Cancelar Pedido'}</div> :
              <>
                <div
                  data-tip
                  data-for={`cancel-requisition-${params.data.id}`}
                  onMouseEnter={(e) => {
                    ReactTooltip.hide();
                    ReactTooltip.show(e.currentTarget);
                  }}
                >{'Cancelar Pedido'}</div>

                <ReactTooltip
                  id={`cancel-requisition-${params.data.id}`}
                  className='eta-tooltip-content'
                  event="focusin"
                  eventOff="focusout"
                  place="top"
                > 
                  <>
                    <div>{ params.data.orders_count > 0 ? 'Se requiere desvincular primero la orden para poder realizar la cancelación del pedido' : (params.data?.status?.id || 0) === 3 ? 'El pedido no esta en estatus correcto para cancelarlo' : ''}</div>
                  </>
                </ReactTooltip>
              </>
            }
          </div>
        );
      },
    };
  }, []);

  const autoGroupColumnDef = useMemo(() => {
    return {
      minWidth: 200,
      pinned: "left",
    };
  }, []);

  const updateAggridConf = (params) => {
    if (called) {
      let config = {
        columns: params.api.getColumnDefs(),
        filter: params.api.getFilterModel()
      };

      if (props.setNewFilter) {
        props.setNewFilter(config);
      }
    }
  };

  const onGridReady = useCallback((params) => {

    if (props.allSelected) {
      params.api.selectAll()
    }

    setCalled(true);
    
    // if (props.configuration['filter'] !== undefined && typeof props.configuration['filter'] === "object") {
    //   params.api.setFilterModel(
    //     props.configuration['filter']
    //   );
    // }

    // if (props.configuration['columns'] !== undefined && typeof props.configuration['columns'] === "object") {

    //   let temp_columns = props.configuration['columns'];

    //   if (temp_columns.length === colDefs.length) {
    //     params.api.setColumnDefs(
    //       getColumnsWithCustomCells(temp_columns)
    //     );
    //     setCalled(true);
    //     return;
    //   }

    //   try {

    //     let currentColumns = colDefs.map( item => item.field);
    //     let currentColumnsSaved = props.configuration['columns'].map( item => item.field);
    //     let difference = currentColumns.filter(x => !currentColumnsSaved.includes(x));

    //     let newColumnsToAdd = [];

    //     colDefs.map( (item, index) => {
    //       if (difference.includes(item.field)) {
    //         newColumnsToAdd.push({
    //           index,
    //           column: item
    //         })
    //       }
    //     });

    //     newColumnsToAdd.map( item => {
    //       temp_columns.splice(item.index, 0, item.column);
    //     });

    //     params.api.setColumnDefs(
    //       getColumnsWithCustomCells(temp_columns)
    //     );
    //     setCalled(true);

    //   } catch (error) {
    //     console.error('Error setting columns definitions', error);

    //     params.api.setColumnDefs(
    //       getColumnsWithCustomCells(temp_columns)
    //     );
    //     setCalled(true);
    //   }
    // } else {
    //   setCalled(true);
    // }

  }, []);

  return (
    <div>
      <div
        className="ag-theme-alpine"
        style={{ height: 650, marginBottom: 50 }}
      >
        <AgGridReact
          ref={gridRef}
          rowData={rowData}
          columnDefs={colDefs}
          enableCellTextSelection={true}
          suppressRowClickSelection={true}
          rowHeight={55}
          rowSelection="multiple"
          localeText={AG_GRID_LOCALE_ES}
          defaultColDef={defaultColDef}
          tooltipShowDelay={500}
          tooltipInteraction={true}
          keepDetailRows={true}
          onCellClicked={ params => {
            if (props.isModeSelection) return;

            props.handleChange({
              target: {
                name: "requisition_detail",
                value: params.data
              }
            });
          }}
          onSelectionChanged ={ params => {
            props.handleChange({
              target: {
                name: "requisitions_selected",
                value: (gridRef?.current?.api?.getSelectedRows() || []).map( item => item.requisition_id)
              }
            });
          }}
          onGridReady={onGridReady}

          gridOptions={{
            sideBar: {
              toolPanels: ['columns', 'filters'],
              position: 'right',
              defaultToolPanel: '',
            },
          }}
          groupDisplayType={"groupRows"}
          rowGroupPanelShow={"always"}
          autoGroupColumnDef={autoGroupColumnDef}
          enableCharts={true}
          enableRangeSelection={true}
          onFilterChanged={ params => {
            updateAggridConf(params);
          }}
          onColumnMoved={ params => {
            if (params.finished) {
              updateAggridConf(params);
            }
          }}
          onColumnVisible={ params => {
            updateAggridConf(params);
          }} 
        />
      </div>
      {/* <div style={{
        display: 'flex',
        justifyContent: 'end',
        marginBottom: 20
      }}>
        <Button
          settings={{
            disabled: props.isLoading,
            type: "btn primary",
            text: props.isLoading ? <Loader/> : "Guardar",
            onClick: () => {
              let config = {
                columns: gridRef.current.api.getColumnDefs(),
                filter: gridRef.current.api.getFilterModel()
              };

              if (props.setNewFilter) {
                props.setNewFilter(config);
              }
            }
          }}
        />
      </div> */}
    </div>
  );
}