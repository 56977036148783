// #region Imports

import React, { Component } from "react";
import { connect } from "react-redux";

import Panel from "../../shared/component/panel";
import SelectInput from "../../shared/controls/select-input";
import TextInput from "../../shared/controls/text-input";
import ColorSelector from "../../shared/controls/color-selector";
import SVGIcon, { icons } from "../../shared/svg-icon";
import ChipContainer from "../../shared/component/chip-container";
import FleetSpecificationEquipment, {
  units,
} from "../../shared/component/fleet/fleet-specification-equipment";
import Table from "../../shared/component/table";
import NormativityTableItem, {
  DocumentTypes,
} from "../../shared/component/fleet/normativity-table-item";
import Button from "../../shared/controls/button";
import RadioButtons from "../../shared/controls/radio";
import {
  validators,
  validateEmail,
  validateLettersAndNumbers,
} from "../../shared/validate";
import {
  createFleetData,
  loadFleetClasses,
  loadFleets,
  loadVehicleTypes,
  loadNormativityTypes,
  createEquipmentData,
  createHint,
  createGPSProvider,
  loadHints,
  loadGpsProvider,
  loadEquipmentSuspensionTypes,
  addTagToFleet,
  createNormativityData,
  postUploadImage,
  loadFleetData,
  loadEquipmentData,
  loadTagsForFleetData,
  loadNormativityForFleetData,
  loadNormativityImagesForFleetData,
  deleteNormativityImagesForFleetData,
  updateFleetData,
  updateEquipmentData,
  updateNormativityData,
  deleteTagInFleetData,
  postValidateEconomicNumber,
  postValidateGpsIdentifier,
  postValidateNiv,
  validatePlates,
} from "../../redux/reducers/fleet-reducer";
import {
  loadSession,
  addToast,
  setFleetCatalogTabSeleted,
} from "../../redux/reducers/global-catalog.reducer";
import { loadTags } from "../../redux/reducers/tags.reducer";
import { loadEquipmentTypes } from "../../redux/reducers/facility-create.reducer";
import ErrorDialog from "../../shared/dialog/error-dialog";
import { withRouter } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import ConfirmDialog from "../../shared/dialog/confirm-dialog";
import { updateDataNormativityStatus } from "../../redux/reducers/fleet-reducer";
import { createEntity, partialEntity } from "../../redux/api";
import { handleWSErrorWithKeys } from "../../shared/utils";

// #endregion

// let dollyId = null;

const MAXSIZE = 12;

class FleetEquipmentView extends Component {
  // #region Override

  constructor(props) {
    super(props);

    this.loaded = false;
    this.confirmDeleteAction = () => {};

    this.fetchCounter = 0;
    this.responseCounter = 0;

    this.loaded2 = false;

    this.state = {
      // validForm: false,
      allowNullNorm: false,
      validatingFields: false,

      modeEdit: false,

      dupliedEconomic: false,
      dupliedSerial: false,
      dupliedGps: false,
      dupliedPlates: false,

      ownEconomic: null,
      ownSerial: null,
      ownGps: null,
      ownPlates: null,

      status: 1,

      typeClassId: 0,
      subTypeId: 0,
      subTypes: [],
      economicNo: "",
      brand: "",
      model: "",
      year: new Date().getFullYear().toString(),
      plates: "",
      serial: "",
      color: "",
      fleetTags: [],

      height: "",
      width: "",
      length: "",
      weight: "",
      singleMaxLoad: "",
      tireQuantity: "",
      axisQuantity: "",
      unitSystem: units.systems[0],
      loadAxisSuspension: 0,
      abs: false,
      showFilesMessageError: false,
      showFilesSizeMessageError: false,

      assurance_company: '',

      card: {
        id: 0,
        expedition: "",
        expiration: "",
        number: "",
        files: [],
        isConsidered: true,
        changeConsidered: false,
      },
      assurance: {
        id: 0,
        expedition: "",
        expiration: "",
        number: "",
        files: [],
        isConsidered: true,
        changeConsidered: false,
      },
      authorization: {
        id: 0,
        expedition: "",
        expiration: "",
        number: "",
        files: [],
        isConsidered: true,
        changeConsidered: false,
      },
      certificate: {
        id: 0,
        expedition: "",
        expiration: "",
        number: "",
        files: [],
        isConsidered: true,
        changeConsidered: false,
      },
      // responses: [],

      identifier: "",
      lastIdentifier: true,
      provider: "",
      providerContact: "",
      providerEmail: "",
      providerPhone: "",

      showConfirmDialog: false,
      onFocused: false,

      fileIdToRemove: 0,
      normativityIdToRemove: 0,
      isDollySelected: false,

      cardExpeditionDate: "",

      assuranceExpeditionDate: "",
      assuranceExpirationDate: "",

      authorizationExpeditionDate: "",
      authorizationExpirationDate: "",

      certificateExpeditionDate: "",
      certificateExpirationDate: "",
      tagsData: [],
      normativityUpdated: false,
    };
  }

  componentDidMount() {
    if(this.props.country && this.props.country !== 'México'){
      this.setState({allowNullNorm: true})
    }

    this.unblock = this.props.history.block((targetLocation) => {
      if (this.state.normativityUpdated) {
        this.setState({ saveExit: true });
        this.props
          .updateDataNormativityStatus(
            this.props.fleet.company,
            this.props.fleet.id,
            this.props.fleetData.id
          )
          .then((r) => {
            this.setState({ saveExit: false, normativityUpdated: false });
            this.props.history.goBack();
          });
      }
      return !this.state.normativityUpdated;
    });
  }

  componentWillUnmount() {
    this.unblock();
  }

  componentDidUpdate() {
    if (
      this.fetchCounter > 0 &&
      this.fetchCounter === this.responseCounter &&
      this.props.toast === null
    ) {
      this.props.showToast({
        text: this.state.modeEdit
          ? "Se actualizó con exito el equipo"
          : "Se registró con exito el equipo",
      });
      if (this.state.modeEdit) {
        this.props.history.go(-2); 
      } else {
        this.props.history.goBack(); 
      }
    }

    // if (this.validateForm() !== this.state.validForm)
    //   this.setState({ validForm: !this.state.validForm });

    if (
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.equipmentId &&
      this.props.match.params.equipmentId > 0 &&
      this.props.fleet &&
      !this.loaded
    ) {
      this.loaded = true;
      this.props
        .loadFleetData(
          parseInt(this.props.match.params.equipmentId),
          this.props.fleet.id
        )
        .then((data) => {
          let response = data.response;
          let lastIdentifier =
            response.gps_identifier !== null &&
            response.gps_identifier.length > 0
              ? true
              : false;
          this.setState(
            {
              modeEdit: true,
              isDollySelected: String(response.type) === "7",
              economicNo: response.economic_number || "",
              abs: response.abs || false,
              model: response.model || "",
              year: String(response.year),
              plates: response.plates || "",
              serial: response.niv || "",
              color: response.color || "",
              status: response.status.id || 1,
              unitSystem: "METRIC",
              height: `${response.height}`,
              width: `${response.width}`,
              length: `${response.length}`,
              weight: `${response.weight}`,
              singleMaxLoad: `${response.load || ''}`,
              tractionCapacity: response.traction_axles_capacity,
              traction_axles_unit: response.traction_axles_unit,
              identifier: response.gps_identifier || "",
              lastIdentifier: lastIdentifier,
              providerContact: response.gps_contact || "",
              providerEmail: response.gps_email || "",
              providerPhone: response.gps_telephone || "",
              ownEconomic: response.economic_number || null,
              ownSerial: response.niv || null,
              ownGps: response.gps_identifier || null,
              ownPlates: response.plates || null,
            },
            () => {
              this.setEquipmentData();
            }
          );
        });
      this.props
        .loadEquipmentData(
          parseInt(this.props.match.params.equipmentId),
          this.props.fleet.id
        )
        .then((data) => {
          let response = data.response;
          if (Array.isArray(response) && response.length > 0) {
            let equipmentData = response[0];
            this.setState(
              {
                loadAxisSuspension: `${equipmentData.load_shaft_suspension}`,
                subTypeId: `${this.props.equipmentData?.type || ''}`,
              },
              () => {
                this.setEquipmentData();
              }
            );
          }
        });

      this.props
        .loadTagsForFleetData(
          parseInt(this.props.match.params.equipmentId),
          this.props.fleet.id
        )
        .then((data) => {
          let tagsData = data.response;
          let tagsToShow = [];
          tagsData.forEach((item) => {
            tagsToShow.push(item.tags);
          });

          this.setState({
            fleetTags: tagsToShow,
            tagsData: tagsData,
          });
        });

      this.props
        .loadNormativityForFleetData(
          parseInt(this.props.match.params.equipmentId),
          this.props.fleet.id
        )
        .then((data) => {
          let normativityData = data.response;
          // console.log("data", data);
          // console.log("generateNormativity", this.generateNormativity());
          let card = {};
          let assurance = {};
          let authorization = {};
          let certificate = {};
          // let pollution = {};

          this.generateNormativity().forEach((item, index) => {
            // console.log("this.props.normativityData", normativityData);
            normativityData.forEach((normItem) => {
              // console.log("normativityData", normItem);
              // console.log("forEach", item, index);
              // console.log("if", `${item.id}`, `${normItem.type}`);
              if (`${item.id}` === `${normItem.type}`) {
                switch (parseInt(normItem.type)) {
                  case 6:
                    card = {
                      expedition: normItem.expedition || '',
                      expiration: normItem.expiration || '',
                      number: normItem.code || '',
                      id: normItem.id || '',
                      files: [],
                      isConsidered: normItem.is_considered,
                    };
                    break;
                  case 7:
                    assurance = {
                      expedition: normItem.expedition || '',
                      expiration: normItem.expiration || '',
                      number: normItem.code || '',
                      id: normItem.id || '',
                      files: [],
                      isConsidered: normItem.is_considered || false,
                    };

                    this.setState({assurance_company: normItem.data?.assurance_company || ''});

                    break;
                  case 8:
                    authorization = {
                      expedition: normItem.expedition || '',
                      expiration: normItem.expiration || '',
                      number: normItem.code || '',
                      id: normItem.id || '',
                      files: [],
                      isConsidered: normItem.is_considered,
                    };
                    break;
                  case 9:
                    certificate = {
                      expedition: normItem.expedition || '',
                      expiration: (normItem.expiration ?? ''),
                      number: normItem.code || '',
                      id: normItem.id || '',
                      files: [],
                      isConsidered: normItem.is_considered,
                    };
                    break;
                  default:
                    break;
                }
              }
            });
          });

          if (Object.keys(card).length) {
            this.setState(
              {
                card,
              },
              () => {
                this.props
                  .loadNormativityImagesForFleetData(
                    parseInt(this.props.match.params.equipmentId),
                    this.props.fleet.id,
                    card.id
                  )
                  .then((response) => {
                    let filesToUload = [];

                    if (Array.isArray(response.response)) {
                      response.response.forEach((item) => {
                        filesToUload.push({
                          name:
                            item.original_name + " (" + item.file_size + ")",
                          idNormativityImage: item.id,
                        });
                      });
                    }

                    this.setState({
                      cardExpeditionDate: this.state.card?.expedition || '',
                      card: Object.assign(this.state.card, {
                        files: filesToUload,
                      }),
                    });
                  });
              }
            );
          }

          if (Object.keys(assurance).length) {
            this.setState(
              {
                assurance,
              },
              () => {
                this.props
                  .loadNormativityImagesForFleetData(
                    parseInt(this.props.match.params.equipmentId),
                    this.props.fleet.id,
                    assurance.id
                  )
                  .then((response) => {
                    let filesToUload = [];

                    if (Array.isArray(response.response)) {
                      response.response.forEach((item) => {
                        filesToUload.push({
                          name:
                            item.original_name + " (" + item.file_size + ")",
                          idNormativityImage: item.id,
                        });
                      });
                    }

                    this.setState({
                      assuranceExpeditionDate: this.state.assurance.expedition,
                      assuranceExpirationDate: this.state.assurance.expiration,
                      assurance: Object.assign(this.state.assurance, {
                        files: filesToUload,
                      }),
                    });
                  });
              }
            );
          }
          
          if (Object.keys(authorization).length) {
            this.setState(
              {
                authorization,
              },
              () => {
                this.props
                  .loadNormativityImagesForFleetData(
                    parseInt(this.props.match.params.equipmentId),
                    this.props.fleet.id,
                    authorization.id
                  )
                  .then((response) => {
                    let filesToUload = [];

                    if (Array.isArray(response.response)) {
                      response.response.forEach((item) => {
                        filesToUload.push({
                          name:
                            item.original_name + " (" + item.file_size + ")",
                          idNormativityImage: item.id,
                        });
                      });
                    }

                    this.setState({
                      authorizationExpeditionDate: this.state.authorization
                        .expedition,
                      authorizationExpirationDate: this.state.authorization
                        .expiration,
                      authorization: Object.assign(this.state.authorization, {
                        files: filesToUload,
                      }),
                    });
                  });
              }
            );
          } else {
            let newAuthorization = this.state.authorization;

            newAuthorization.isConsidered = false;
            newAuthorization.changeConsidered = true;
            this.setState({
              authorization: newAuthorization
            });
          }

          if (Object.keys(certificate).length) {
            this.setState(
              {
                certificate,
              },
              () => {
                this.props
                  .loadNormativityImagesForFleetData(
                    parseInt(this.props.match.params.equipmentId),
                    this.props.fleet.id,
                    certificate.id
                  )
                  .then((response) => {
                    let filesToUload = [];

                    if (Array.isArray(response.response)) {
                      response.response.forEach((item) => {
                        filesToUload.push({
                          name:
                            item.original_name + " (" + item.file_size + ")",
                          idNormativityImage: item.id,
                        });
                      });
                    }

                    this.setState({
                      certificateExpeditionDate: this.state.certificate
                        .expedition,
                      certificateExpirationDate: this.state.certificate
                        .expiration,
                      certificate: Object.assign(this.state.certificate, {
                        files: filesToUload,
                      }),
                    });
                  });
              }
            );
          }
        });
    }
  }

  // #endregion

  // #region Validations

  getErrors = () => {
    let errors = "";

    if (!this.validateForm()) {
      errors = "Por favor completa los siguientes campos:<br/>";
    }

    errors +=
      String(this.state.typeClassId).length === 0
        ? `<b>Seleccione un Tipo/Clase de vehiculo<b/><br/>`
        : "";
    errors +=
      this.state.subTypeId === 0 ? `<b>Seleccione un Sub-tipo<b/><br/>` : "";
    errors +=
      this.state.economicNo.length === 0
        ? `<b>Ingrese el número económico<b/><br/>`
        : "";
    errors +=
      this.state.brand.length === 0 ? `<b>Ingrese la marca<b/><br/>` : "";
    errors +=
      this.state.model.length === 0 ? `<b>Ingrese el modelo<b/><br/>` : "";
    errors +=
      parseInt(this.state.year) === 0 ? `<b>Ingrese el año<b/><br/>` : "";

    if (!this.isDollySelected()) {
      errors +=
        this.state.height.length === 0 ? `<b>Ingrese la altura<b/><br/>` : "";
      errors +=
        this.state.width.length === 0 ? `<b>Ingrese la anchura<b/><br/>` : "";
      errors +=
        this.state.length.length === 0 ? `<b>Ingrese largo<b/><br/>` : "";
      errors +=
        this.state.weight.length === 0 ? `<b>Ingrese el peso<b/><br/>` : "";
      errors +=
        this.state.plates.length === 0 ? `<b>Ingrese la placa<b/><br/>` : "";
      errors +=
        this.state.serial.length === 0 ? `<b>Ingrese la serie<b/><br/>` : "";

      errors += !this.validateTagData()
        ? "<b>Seleccione una etiqueta<b/><br/>"
        : "";

      // errors += this.state.identifier.length === 0 ? `<b>Ingrese el identificador de GPS<b/><br/>` : ''
      // errors += this.state.provider.length === 0 ? `<b>Ingrese el proveedor de GPS<b/><br/>` : ''
      // errors += this.state.providerContact.length === 0 ? `<b>Ingrese el nombre del proveedor de GPS<b/><br/>` : ''
      // errors += this.state.providerEmail.length === 0 ? `<b>Ingrese el email del proveedor de GPS<b/><br/>` : ''
      // errors += this.state.providerPhone.length === 0 ? `<b>Ingrese el teléfono del proveedor de GPS<b/><br/>` : ''
      errors +=
        this.state.singleMaxLoad.length === 0
          ? `<b>Ingrese la carga MAX. Sencillo<b/><br/>`
          : "";
      // if (
      //   this.state.providerEmail.length > 0 &&
      //   !validateEmail(this.state.providerEmail)
      // ) {
      //   errors += `<b>"${this.state.providerEmail}"<b/> no es una correo válido<br/>`;
      // }

      errors += this.state.dupliedSerial
        ? `<b>La serie "${this.state.serial}" ya existe<b/><br/>`
        : "";
      // errors += this.state.dupliedGps
      //   ? `<b>El identificador de GPS  "${this.state.identifier}" ya existe<b/><br/>`
      //   : "";
      errors += this.state.dupliedPlates
        ? `<b>Las placas  "${this.state.plates}" ya existe<b/><br/>`
        : "";
      errors +=
        this.state.loadAxisSuspension === 0
          ? `<b>Seleccione la suspensión eje de carga<b/><br/>`
          : "";
    }

    errors +=
      this.state.color.length === 0 ? `<b>Ingrese el color<b/><br/>` : "";
    
    if(!this.state.allowNullNorm){
      if(this.state.certificate.isConsidered){
        errors += !this.state.certificate.expedition ? `<b>Ingrese la fecha de expedición de su certificado<b/><br/>` : ''
        errors += this.state.certificate.expiration.length === 0 ? `<b>Ingrese la fecha de expiración de su certificado<b/><br/>` : ''
        errors += this.state.certificate.number.length === 0 ? `<b>Ingrese el número de documento de su certificado<b/><br/>` : ''
        errors += this.state.certificate.files.length === 0 ? `<b>Agregue por lo menos un documento de su certificado<b/><br/>` : ''
      }
      if(this.state.assurance.isConsidered){
        errors += !this.state.assurance.expedition ? `<b>Ingrese la fecha de expedición de su póliza<b/><br/>` : ''
        errors += !this.state.assurance.expiration ? `<b>Ingrese la fecha de expiración de su póliza<b/><br/>` : ''
        errors += this.state.assurance.number.length === 0 ? `<b>Ingrese el número de documento de su póliza<b/><br/>` : ''
        errors += this.state.assurance.files.length === 0 ? `<b>Agregue por lo menos un documento de su póliza<b/><br/>` : ''
      }
      if(this.state.authorization.isConsidered && !this.isDollySelected()){
        errors += !this.state.authorization.expedition ? `<b>Ingrese la fecha de expedición de su autorización<b/><br/>` : ''
        errors += (this.state.authorization.expiration || '').length === 0 ? `<b>Ingrese la fecha de expiración de su autorización<b/><br/>` : ''
        errors += (this.state.authorization.number || '').length === 0 ? `<b>Ingrese el número de documento de su autorización<b/><br/>` : ''
        errors += (this.state.authorization.files || []).length === 0 ? `<b>Agregue por lo menos un documento de su autorización<b/><br/>` : ''
      }
      if(this.state.card.isConsidered && !this.isDollySelected()){
        errors += !this.state.card.expedition ? `<b>Ingrese la fecha de expedición de Tarjeta de circulación<b/><br/>` : ''
        // errors += this.state.card.expiration.length === 0 ? `<b>Ingrese la fecha de expiración de Tarjeta de circulación<b/><br/>` : ''
        errors += this.state.card.number.length === 0 ? `<b>Ingrese el número de documento de Tarjeta de circulación<b/><br/>` : ''
        errors += this.state.card.files.length === 0 ? `<b>Agregue por lo menos un documento de Tarjeta de circulación<b/><br/>` : ''
      }
    }
    // errors += this.state.fleetTags.length === 0 ? `<b>Por lo menos seleccione una etiqueta<b/><br/>` : ''
    // errors += this.state.tireQuantity.length === 0 ? `<b>Ingrese el numero de llantas<b/><br/>` : ''
    // errors += this.state.axisQuantity.length === 0 ? `<b>Ingrese el numero de ejes<b/><br/>` : ''

    /*errors += this.state.card.expedition.length === 0 ? `<b>Ingrese la fecha de expedición de Tarjeta de circulación<b/><br/>` : ''
    errors += this.state.card.expiration.length === 0 ? `<b>Ingrese la fecha de expiración de Tarjeta de circulación<b/><br/>` : ''
    errors += this.state.card.number.length === 0 ? `<b>Ingrese el número de documento de Tarjeta de circulación<b/><br/>` : ''
    errors += this.state.card.files.length === 0 ? `<b>Agregue por lo menos un documento de Tarjeta de circulación<b/><br/>` : ''

    errors += this.state.assurance.expedition.length === 0 ? `<b>Ingrese la fecha de expedición de su póliza<b/><br/>` : ''
    errors += this.state.assurance.expiration.length === 0 ? `<b>Ingrese la fecha de expiración de su póliza<b/><br/>` : ''
    errors += this.state.assurance.number.length === 0 ? `<b>Ingrese el número de documento de su póliza<b/><br/>` : ''
    errors += this.state.assurance.files.length === 0 ? `<b>Agregue por lo menos un documento de su póliza<b/><br/>` : ''

    errors += this.state.authorization.expedition.length === 0 ? `<b>Ingrese la fecha de expedición de su autorización<b/><br/>` : ''
    errors += this.state.authorization.expiration.length === 0 ? `<b>Ingrese la fecha de expiración de su autorización<b/><br/>` : ''
    errors += this.state.authorization.number.length === 0 ? `<b>Ingrese el número de documento de su autorización<b/><br/>` : ''
    errors += this.state.authorization.files.length === 0 ? `<b>Agregue por lo menos un documento de su autorización<b/><br/>` : ''

    errors += this.state.certificate.expedition.length === 0 ? `<b>Ingrese la fecha de expedición de su certificado<b/><br/>` : ''
    errors += this.state.certificate.expiration.length === 0 ? `<b>Ingrese la fecha de expiración de su certificado<b/><br/>` : ''
    errors += this.state.certificate.number.length === 0 ? `<b>Ingrese el número de documento de su certificado<b/><br/>` : ''
    errors += this.state.certificate.files.length === 0 ? `<b>Agregue por lo menos un documento de su certificado<b/><br/>` : ''*/

    errors += this.state.dupliedEconomic
      ? `<b>El N° economico "${this.state.economicNo}" ya existe<b/><br/>`
      : "";
    errors += !this.isNormativityValid(1)
      ? "<b>Sube un nuevo archivo en Tarjeta de circulación<b/><br/>"
      : "";
    errors += !this.isNormativityValid(2)
      ? "<b>Sube un nuevo archivo en Póliza de seguro<b/><br/>"
      : "";

    if(!this.state.allowNullNorm){
      errors += !this.state.assurance_company
        ? "<b>Ingresa el nombre de la aseguradora<b/><br/>"
        : "";
    }
    errors += !this.isNormativityValid(3)
      ? "<b>Sube un nuevo archivo en Autorización expresa<b/><br/>"
      : "";
    errors += !this.isNormativityValid(4)
      ? "<b>Sube un nuevo archivo en Certificado de condición físico-mecánico<b/><br/>"
      : "";

    return errors;
  };

  validateForm = () => {
    const isDollySelected = this.isDollySelected();

    if (
      String(this.state.typeClassId).length > 0 &&
      this.state.subTypeId.length > 0 &&
      this.state.economicNo.length > 0 &&
      this.state.brand.length > 0 &&
      this.state.model.length > 0 &&
      parseInt(this.state.year) > 0 &&
      this.state.color.length > 0 &&
      // && this.state.fleetTags.length > 0
      // && this.state.tireQuantity.length > 0
      // && this.state.axisQuantity.length > 0
      // && this.state.unitSystem.length > 0
      // && this.state.abs.length > 0

      (
        this.state.allowNullNorm || (
          this.state.assurance_company &&
          (
            !this.state.certificate.isConsidered || (
              this.state.certificate.expedition && this.state.certificate.expedition.length > 0
              && this.state.certificate.expiration && this.state.certificate.expiration.length > 0
              && this.state.certificate.number && this.state.certificate.number.length > 0
              && Array.isArray(this.state.certificate.files) && this.state.certificate.files.length > 0
            )
          ) 
          && (
            !this.state.assurance.isConsidered || (
              this.state.assurance.expedition && this.state.assurance.expedition.length > 0
              && this.state.assurance.expiration && this.state.assurance.expiration.length > 0
              && this.state.assurance.number && this.state.assurance.number.length > 0
              && Array.isArray(this.state.assurance.files) && this.state.assurance.files.length > 0
            )
          ) 
        )
      ) &&

      /*&& this.state.card.expedition && this.state.card.expedition.length > 0
      && this.state.card.expiration && this.state.card.expiration.length > 0
      && this.state.card.number && this.state.card.number.length > 0
      && Array.isArray(this.state.card.files) && this.state.card.files.length > 0
      && this.state.assurance.expedition && this.state.assurance.expedition.length > 0
      && this.state.assurance.expiration && this.state.assurance.expiration.length > 0
      && this.state.assurance.number && this.state.assurance.number.length > 0
      && Array.isArray(this.state.assurance.files) && this.state.assurance.files.length > 0
      && this.state.authorization.expedition && this.state.authorization.expedition.length > 0
      && this.state.authorization.expiration && this.state.authorization.expiration.length > 0
      && this.state.authorization.number && this.state.authorization.number.length > 0
      && Array.isArray(this.state.authorization.files) && this.state.authorization.files.length > 0
      && this.state.certificate.expedition && this.state.certificate.expedition.length > 0
      && this.state.certificate.expiration && this.state.certificate.expiration.length > 0
      && this.state.certificate.number && this.state.certificate.number.length > 0
      && Array.isArray(this.state.certificate.files) && this.state.certificate.files.length > 0*/
      this.isNormativityValid(4) &&
      this.isNormativityValid(2) &&
      !this.state.dupliedEconomic
    ) {
      if (!isDollySelected) {
        return (
          this.state.plates.length > 0 &&
          this.state.serial.length > 0 &&
          this.state.singleMaxLoad.length > 0 &&
          this.state.loadAxisSuspension !== 0 &&
          this.state.weight.length > 0 &&
          this.state.height.length > 0 &&
          this.state.length.length > 0 &&
          this.state.width.length > 0 &&
          // && this.state.identifier.length > 0
          // && this.state.provider.length > 0
          // && this.state.providerContact.length > 0
          // && this.state.providerEmail.length > 0 && validateEmail(this.state.providerEmail)
          // && this.state.providerPhone.length > 0
          // ((this.state.providerEmail.length !== 0 &&
          //   validateEmail(this.state.providerEmail)) ||
          //   this.state.providerEmail.length === 0) &&
          !this.state.dupliedSerial &&
          !this.state.dupliedPlates &&
          !this.state.dupliedGps &&
          this.isNormativityValid(3) &&
          this.isNormativityValid(1) &&
          this.validateTagData() &&
          (
            this.state.allowNullNorm || (
              (
                !this.state.authorization.isConsidered || (
                  this.state.authorization.expedition && this.state.authorization.expedition.length > 0
                  && this.state.authorization.expiration && this.state.authorization.expiration.length > 0
                  && this.state.authorization.number && this.state.authorization.number.length > 0
                  && Array.isArray(this.state.authorization.files) && this.state.authorization.files.length > 0
                )
              ) 
              && (
                !this.state.card.isConsidered || (
                  this.state.card.expedition && this.state.card.expedition.length > 0
                  && this.state.card.number && this.state.card.number.length > 0
                  && Array.isArray(this.state.card.files) && this.state.card.files.length > 0
                )
              )
            )
          )
        );
      } else {
        return true;
      }
    } else return false;
  };

  isNormativityValid = (norm) => {
    if (!this.state.modeEdit /*  || this.isDollySelected() */ || this.state.allowNullNorm) return true;
    switch (norm) {
      case 1:
        // debugger;
        if (this.isDollySelected()) {
          return true;
        }
        if (
          this.state.cardExpeditionDate !== this.state.card.expedition &&
          Array.isArray(this.state.card.files)
        ) {
          let newFiles = false;

          this.state.card.files.forEach((file) => {
            if (file.idNormativityImage === undefined) {
              newFiles = true;
            }
          });

          return newFiles;
        }

        return true;

      case 2:
        if (
          this.state.assuranceExpeditionDate !==
            this.state.assurance.expedition ||
          this.state.assuranceExpirationDate !== this.state.assurance.expiration
        ) {
          if (Array.isArray(this.state.assurance.files)) {
            let newFiles = false;

            this.state.assurance.files.forEach((file) => {
              if (file.idNormativityImage === undefined) {
                newFiles = true;
              }
            });

            return newFiles;
          }
        }

        return true;

      case 3:
        if (this.isDollySelected()) {
          return true;
        }
        if ((
          this.state.authorizationExpeditionDate !==
            this.state.authorization.expedition ||
          this.state.authorizationExpirationDate !==
            this.state.authorization.expiration) && this.state.authorization.isConsidered
        ) {
          if (Array.isArray(this.state.authorization.files)) {
            let newFiles = false;

            this.state.authorization.files.forEach((file) => {
              if (file.idNormativityImage === undefined) {
                newFiles = true;
              }
            });

            return newFiles;
          }
        }

        return true;

      case 4:
        if (
          this.state.certificateExpeditionDate !==
            this.state.certificate.expedition ||
          this.state.certificateExpirationDate !==
            this.state.certificate.expiration
        ) {
          if (Array.isArray(this.state.certificate.files)) {
            let newFiles = false;

            this.state.certificate.files.forEach((file) => {
              if (file.idNormativityImage === undefined) {
                newFiles = true;
              }
            });

            return newFiles;
          }
        }

        return true;

      default:
        return true;
    }
  };

  validateGPSProvider = (hint) => {
    let gpsProvider = null;

    // if (this.isDollySelected()) {
    //   if (this.state.modeEdit) {
    //     this.updateFleetData(hint.id, gpsProvider);
    //   } else {
    //     this.saveFleetData(hint.id, gpsProvider);
    //   }
    //   return;
    // }

    // this.props.gpsProvider.forEach((item) => {
    //   if (
    //     String(item.name).toUpperCase() ===
    //     String(this.state.provider).toUpperCase()
    //   ) {
    //     gpsProvider = item.id;
    //     return;
    //   }
    // });

    // if (gpsProvider === null && this.state.provider !== "") {
    //   this.props
    //     .createNewGPSProvider(
    //       this.state.provider,
    //       this.state.providerEmail,
    //       this.state.providerPhone
    //     )
    //     .then((response) => {
    //       if (
    //         response.data &&
    //         response.data.type === "FLEET_GPS_PROVIDER_POST_ERROR"
    //       ) {
    //         response.data.error.then(e => {
    //           let error = (Object.values(e)).flat().map(err => <div>{err}</div>);
    //           this.setState(
    //             {
    //               showError: true,
    //               msgError: error,
    //               showRegisterError: false,
    //             }
    //           );
    //         })
    //       } else {
    //         if (this.state.modeEdit) {
    //           this.updateFleetData(hint.id, response.data.id);
    //         } else {
    //           this.saveFleetData(hint.id, response.data.id);
    //         }
    //       }
    //     });
    // } else {
      if (this.state.modeEdit) {
        this.updateFleetData(hint.id, gpsProvider);       
      } else {
        this.saveFleetData(hint.id, gpsProvider);
      }
    //}
  };

  // #endregion

  // #region Handle

  actionCloseDialog = () => {
    this.setState({
      showConfirmDialog: false,
      fileIdToRemove: null,
      showError: false,
      showFilesMessageError: false,
      showFilesSizeMessageError: false,
    });
  };

  handleLeave = () => {
    this.setState({ onFocused: true });
  };

  handleInput = (e) => {
    let property = e.target.name;
    let value = e.target.value;

    if (
      property === "card" ||
      property === "assurance" ||
      property === "authorization" ||
      property === "certificate"
    ) {
      if (
        value.files.filter((item) => {
          return item.size / 1024 / 1024 > MAXSIZE;
        }).length
      ) {
        this.setState({ showFilesSizeMessageError: true });

        e.target.value.files.pop();
      } else {
        this.setState({ [property]: value });
      }
    }

    if (property === "plates" || property === "economicNo") {
      if (!validateLettersAndNumbers(value)) {
        this.setState({ [property]: String(value).toUpperCase() });
      }
    } else {
      this.setState({ [property]: value });
    }

    if (property === "typeClassId" && this.props.vehicleType) {
      const typeClassIds = this.props.typeClasses.filter(
        (item) => String(item.id) === String(value)
      );
      if (typeClassIds.length > 0) {
        const types = this.props.vehicleType.filter(
          (v) => String(v.id) === String(typeClassIds[0].typeID)
        );

        if (types.length > 0) {
          const type = String(types[0].description).replace(" ", "-");
          this.setState({
            subTypes: this.props.equipmentTypes.filter(
              (eq) => String(eq.type) === String(type).toUpperCase()
            ),
            isDollySelected: false,
          });
        }
      }
    }
  };

  handleInputCheckbox = (e, name) => {
    let property = name;
    let value = e.target.checked;
    let stateData = this.state[property];
    stateData.isConsidered = !value;
    stateData.changeConsidered = true;

    this.setState({ [property]: stateData });
  };

  handleTagChange = (e) => {
    let st = this.state.fleetTags.filter((ft) => ft !== e.target.id);
    if (!e.target.value) {
      this.handleInput({ target: { name: "fleetTags", value: [e.target.id] } });
      if (this.state.modeEdit) {
        const fleetDataId = parseInt(this.props.match.params.equipmentId);

        this.state.tagsData.forEach((item) => {
          this.props.deleteTagInFleetData(
            parseInt(fleetDataId),
            this.props.fleet.id,
            item.id
          );
        });

        this.props
          .addTag(this.props.fleet ? this.props.fleet.id : "", fleetDataId, {
            data: fleetDataId,
            tags: e.target.id,
          })
          .then((fleet) => {
            let tagsData = this.state.tagsData.filter((tag) => {
              return tag.tags !== e.target.id;
            });

            tagsData.push(fleet.response);
            tagsData = tagsData.filter((item) => {
              return item.tags === e.target.id;
            });

            this.setState({
              tagsData: tagsData,
            });
          });
      }
    } else {
      if (this.state.modeEdit) {
        let tagToDelete = null;

        if (this.state.tagsData.length > 1) {
          this.state.tagsData.forEach((item) => {
            if (`${item.tags}` === `${e.target.id}`) tagToDelete = item.id;
          });

          if (tagToDelete) {
            this.props.deleteTagInFleetData(
              parseInt(this.props.match.params.equipmentId),
              this.props.fleet.id,
              tagToDelete
            );
          }
          this.handleInput({ target: { name: "fleetTags", value: st } });
        }
      } else {
        this.handleInput({ target: { name: "fleetTags", value: st } });
      }
    }
  };

  handleSaveFleetData = () => {
    if (this.fetchCounter > 0 || !this.validateForm()) { return; } 

    let hintFound = null;

    this.props.hints.forEach((item) => {
      if (
        String(item.description).toUpperCase() ===
        String(this.state.brand).toUpperCase()
      ) {
        hintFound = item;
        return;
      }
    });

    if (hintFound === null) {
      this.props.createHint(this.state.brand, 1).then((response) => {
        if (response.data && response.data.type === "FLEET_HINTS_POST_ERROR") {
          response.data.error.then(e => {
            let error = (Object.values(e)).flat().map(err => <div>{err}</div>);
            this.setState(
              {
                showError: true,
                msgError: error,
                showRegisterError: false,
              }
            );
          });
        } else {
          this.validateGPSProvider(response.data);
        }
      });
    } else {
      this.validateGPSProvider(hintFound);
    }
  };

  setClassInfo = (id) => {
    const typeClassIds = this.props.typeClasses.filter(
      (item) => String(item.id) === String(id)
    );
    const classInfo = this.props.fleetClass.filter((item) => {
      return typeClassIds.length > 0
        ? typeClassIds[0].classID === `${item.id}`
        : false;
    });

    if (classInfo.length > 0) {
      this.setState({
        tireQuantity: classInfo[0].tires,
        axisQuantity: classInfo[0].axles,
      });
    }
  };

  // #endregion

  // #region Others

  setEquipmentData = () => {
    if (this.props.fleetData && this.props.equipmentData) {
      let brand = "";
      let gpsProvider = "";
      let fleetClass = {};
      let tires = 0;
      let axles = 0;

      if(this.props.hints.length === 0){
        this.props.loadHint().then((response) => {
          if(response !== null && response.data !== null){
            response.data.forEach((item) => {
              if (`${item.id}` === `${this.props.fleetData.brand}`) {
                brand = item.description;
                this.setState({
                  brand: brand
                });
              }
            });
          } else {
            this.props.history.goBack();
          }
        });
      } else {
        this.props.hints.forEach((item) => {
          if (`${item.id}` === `${this.props.fleetData.brand}`) {
            brand = item.description;
          }
        });
      }

      this.props.gpsProvider.forEach((item) => {
        if (`${item.id}` === `${this.props.fleetData.gps_provider}`) {
          gpsProvider = item.name;
        }
      });

      this.props.typeClasses.forEach((item) => {
        if (String(item.classID) === String(this.props.fleetData.fleet_class)) {
          fleetClass = item;
        }
      });

      this.props.fleetClass.forEach((item) => {
        if (`${item.id}` === `${this.props.fleetData.fleet_class}`) {
          axles = item.axles;
          tires = item.tires;
        }
      });

      this.setState({
        brand: brand,
        provider: gpsProvider,
        typeClassId: `${fleetClass.id ? fleetClass.id : ""}`,
        tireQuantity: `${tires}`,
        axisQuantity: `${axles}`,
      });

      if (fleetClass.id) {
        // if(typeClassIds.length > 0){
        const types = this.props.vehicleType.filter(
          (v) => String(v.id) === String(fleetClass.typeID)
        );
        if (types.length > 0) {
          const type = String(types[0].description).replace(" ", "-");
          this.setState({
            subTypes: this.props.equipmentTypes.filter(
              (eq) => String(eq.type) === String(type).toUpperCase()
            ),
          });
        }
        // }

        // this.handleInput({ target: { name: 'typeClassId', value: fleetClass.id } } )
      }
    }
  };

  fleetTagsToChips = () => {
    return this.props.fleetTagsCatalog.map((tag) => ({
      id: tag.id,
      title: tag.description,
      color: tag.color,
      isFull: this.state.fleetTags.find((ft) => ft === tag.id) ? true : false,
    }));
  };

  getObjectData = (hintId, gpsProviderId) => {
    const typeClassIds = this.props.typeClasses.filter(
      (item) => String(item.id) === String(this.state.typeClassId)
    );

    let dataToCreate = {
      visible: true,
      photo_url: null,
      economic_number: this.state.economicNo,
      model: this.state.model,
      year: this.state.year,
      color: this.state.color,
      traction_axles_capacity: 0,
      traction_axles_unit: 0,
      abs: this.state.abs,
      status: this.state.status,
      type: 2,
      fleet: this.props.fleet ? this.props.fleet.id : "",
      brand: hintId,
      fleet_class:
        typeClassIds.length > 0 && typeClassIds[0].classID
          ? typeClassIds[0].classID
          : "",
    };

    if (!this.isDollySelected()) {
      dataToCreate = Object.assign(dataToCreate, {
        load: this.state.singleMaxLoad,
        niv: this.state.serial,
        plates: this.state.plates,
        axles: this.state.axisQuantity,
        tires: this.state.tireQuantity,
        height: this.state.height,
        length: this.state.length,
        width: this.state.width,
        weight: this.state.weight,

        // gps_identifier: this.state.identifier,
        // gps_provider: providerId,
        // gps_contact: this.state.providerContact,
        // gps_email: this.state.providerEmail,
        // gps_telephone: this.state.providerPhone,
      });

      this.state.identifier.length > 0
        ? (dataToCreate.gps_identifier = this.state.identifier)
        : console.log("");
      gpsProviderId !== null
        ? (dataToCreate.gps_provider = gpsProviderId)
        : console.log("");
      this.state.providerContact.length > 0
        ? (dataToCreate.gps_contact = this.state.providerContact)
        : console.log("");
      this.state.providerEmail.length > 0
        ? (dataToCreate.gps_email = this.state.providerEmail)
        : console.log("");
      this.state.providerPhone.length > 0
        ? (dataToCreate.gps_telephone = this.state.providerPhone)
        : console.log("");
    }

    return dataToCreate;
  };

  // #endregion

  // #region Update

  updateFleetData = (hint, gpsProviderId) => {

    const { id } = this.props.fleetData;

    const typeClassIds = this.props.typeClasses.filter(
      (item) => String(item.id) === String(this.state.typeClassId)
    );

    this.fetchCounter += 1;
    let dataJson = {
      economic_number: this.state.economicNo,
      model: this.state.model,
      year: this.state.year,
      color: this.state.color,
      abs: this.state.abs,
      status: this.state.status,
      type: 2,
      brand: hint,
      fleet_class: typeClassIds.length > 0 && typeClassIds[0].classID ? typeClassIds[0].classID : "",
      niv: this.state.serial.length === 0 ? null : this.state.serial,
      plates: this.state.plates.length === 0 ? null : this.state.plates,
      gps_identifier: this.state.identifier.length > 0 ? this.state.identifier : null,
      gps_provider: gpsProviderId !== null ? gpsProviderId : null,
      gps_contact: this.state.providerContact.length > 0 ? this.state.providerContact : null,
      gps_email: this.state.providerEmail.length > 0 ?  this.state.providerEmail : null,
      gps_telephone: this.state.providerPhone.length > 0 ? this.state.providerPhone : null,
      extra: {
        type: this.state.subTypeId,
        load_shaft_suspension: this.state.loadAxisSuspension
      },
      tag_list: this.state.fleetTags.map(t => ({tags: t})),
      
      normativity_list: this.generateNormativity().map((norm, i) => {
        let normData = this.state[this.getNormPropName(i)] || {};
        let extraData = {};
        if(norm.description.includes('seguro')){
          extraData.assurance_company = this.state.assurance_company;
        }
        return {
          code: normData.number ? normData.number : '',
          type: norm.id,
          is_considered: normData.isConsidered,
          expedition: normData.expedition && normData.expedition !== null ? normData.expedition : null,
          expiration: normData.expiration && normData.expiration !== null ? normData.expiration : null,
          data: extraData
        }
      }),
    };

    if (!this.isDollySelected()) {
      dataJson = Object.assign(dataJson, {
        height: this.state.height,
        length: this.state.length,
        width: this.state.width,
        weight: this.state.weight,
        load: this.state.singleMaxLoad.length === 0 ? null : this.state.singleMaxLoad,
      });
    }

    let newData = {
      data: JSON.stringify(dataJson),
      company_id: this.props.fleet?.company || 0,
      fleet_id: this.props.fleet?.id || 0,
      data_id: id || 0,
    }

    this.generateNormativity().forEach((norm, i) => {
      let normData = this.state[this.getNormPropName(i)] || {};
      normData.files.filter(f => !f.idNormativityImage).forEach((f, fI) => {
        if (f.idNormativityImage === undefined) {
          newData[''.concat(norm.id, '_', fI + 1)] = f;
        }
      })
    });

    this.props.updateFleetDataV2(
      newData, {
      isForm: true,
      onSuccess: () => {
        this.responseCounter += 1;
        this.forceUpdate();
      },
      onError: (e) => {

        let errors = e.response?.data?.errors || [];
        var errorsToShow = [];

        if (errors.length > 0) {
          if (typeof errors[0] === 'string') {
            errorsToShow = errors;
          } else {
            errorsToShow = errors[0];
          }
        } else {
          errorsToShow = ['Ocurrió un error al guardar, verifica los datos'];
        }

        this.setState(
          {
            showError: true,
            msgError: handleWSErrorWithKeys(errorsToShow),
            showRegisterError: false,
          }, () => {
            this.props.loadGps();
            this.props.loadHint();
            this.fetchCounter = 0;
            this.responseCounter = 0;
            this.forceUpdate();
          }
        )
      }
    });


    // const { id } = this.props.fleetData;

    // this.fetchCounter += 1;
    // this.forceUpdate();

    // this.props.normativityData.forEach((item) => {
    //   if (
    //     !this.isDollySelected() ||
    //     `${item.id}` === `${this.state.card.id}` ||
    //     `${item.id}` === `${this.state.certificate.id}`
    //   )
    //     this.fetchCounter += 1;
    //   switch (`${item.id}`) {
    //     case `${this.state.card.id}`:
    //       this.updateNormativity(id, item, this.state.card);
    //       break;
    //     case `${this.state.assurance.id}`:
    //       this.updateNormativity(id, item, this.state.assurance);
    //       break;
    //     case `${this.state.certificate.id}`:
    //       this.updateNormativity(id, item, this.state.certificate);
    //       break;
    //     case `${this.state.authorization.id}`:
    //       this.updateNormativity(id, item, this.state.authorization);
    //       break;
    //     default:
    //       break;
    //   }
    // });

    // this.props
    //   .updateFleetData(
    //     this.props.fleet ? this.props.fleet.id : "",
    //     id,
    //     this.getObjectData(hint, gpsProvider)
    //   )
    //   .then((response) => {
    //     this.responseCounter += 1;
    //     this.forceUpdate();
    //   });
    // this.updateEquipment(id);
  };

  updateEquipment = (id) => {
    let newEquipmentData = {
      data: id,
      type: this.state.subTypeId,
    };
    if (!this.isDollySelected()) {
      newEquipmentData.load_shaft_suspension = this.state.loadAxisSuspension;
    }
    this.fetchCounter += 1;
    this.props
      .updateEquipmentData(
        id,
        this.props.fleet ? this.props.fleet.id : "",
        newEquipmentData,
        this.props.equipmentData ? this.props.equipmentData.id : ""
      )
      .then((response) => {
        this.responseCounter += 1;
        this.forceUpdate();
      });
  };

  updateNormativity = (id, currentNormativity, dataToSave) => {
    let newNormativityData = {
      code: dataToSave.number ? dataToSave.number : "",
      fleet_data: id,
      type: currentNormativity.type,
      is_considered: dataToSave.isConsidered,
    };

    if(dataToSave.expedition){
      newNormativityData.expedition = dataToSave.expedition;
    }

    if(dataToSave.expiration) {
      newNormativityData.expiration = dataToSave.expiration
    }

    if (newNormativityData.code !== "" || dataToSave.changeConsidered) {
      this.props
        .updateNormativityData(
          this.props.fleet ? this.props.fleet.id : "",
          id,
          currentNormativity.id,
          newNormativityData
        )
        .then((response) => {
          this.setState({ normativityUpdated: true });
          this.responseCounter += 1;
          this.forceUpdate();
          //debugger
          dataToSave.files.forEach((file) => {
            if (file.idNormativityImage === undefined) {
              this.fetchCounter += 1;
              this.updateFileInNormativity(
                { response: { id: currentNormativity.id } },
                this.props.fleet ? this.props.fleet.id : "",
                id,
                file
              );
            }
          });
        });
    } else {
      this.setState({ normativityUpdated: true });
      this.responseCounter += 1;
      this.forceUpdate();
    }
  };

  updateFileInNormativity = (normativityData, fleetId, fleetDataId, file) => {
    const {
      response: { id = "0" },
    } = normativityData;
    //debugger
    this.props
      .uploadImageToNormativity(fleetId, fleetDataId, id, file)
      .then((response) => {
        this.setState({ normativityUpdated: true });
        this.responseCounter += 1;
        this.forceUpdate();
      });
  };

  // #endregion

  // #region Register

  getNormPropName = (index) => {
    if (this.isDollySelected()) {
      switch (index) {
        case 0:
          return "assurance";
        case 1:
          return "certificate";
        default:
          return ""
      }
    } else {
      switch (index) {
        case 0:
          return "card";
        case 1:
          return "assurance";
        case 2:
          return "authorization";
        case 3:
          return "certificate";
        case 4:
          return "pollution";
        default:
          return ""
      }
    }
  }

  saveFleetData = (hint, gpsProviderId) => {

    const typeClassIds = this.props.typeClasses.filter(
      (item) => String(item.id) === String(this.state.typeClassId)
    );

    this.fetchCounter += 1;
    let dataJson = {
      economic_number: this.state.economicNo,
      model: this.state.model,
      year: this.state.year,
      color: this.state.color,
      abs: this.state.abs,
      status: this.state.status,
      type: 2,
      brand: hint,
      fleet_class: typeClassIds.length > 0 && typeClassIds[0].classID ? typeClassIds[0].classID : "",
      niv: this.state.serial.length === 0 ? null : this.state.serial,
      plates: this.state.plates.length === 0 ? null : this.state.plates,
      gps_identifier: this.state.identifier.length > 0 ? this.state.identifier : null,
      gps_provider: gpsProviderId !== null ? gpsProviderId : null,
      gps_contact: this.state.providerContact.length > 0 ? this.state.providerContact : null,
      gps_email: this.state.providerEmail.length > 0 ?  this.state.providerEmail : null,
      gps_telephone: this.state.providerPhone.length > 0 ? this.state.providerPhone : null,
      extra: {
        type: this.state.subTypeId,
        load_shaft_suspension: this.state.loadAxisSuspension
      },
      tag_list: this.state.fleetTags.map(t => ({tags: t})),
      
      normativity_list: this.generateNormativity().map((norm, i) => {
        let normData = this.state[this.getNormPropName(i)] || {};
        let extraData = {};
        if(norm.description.includes('seguro')){
          extraData.assurance_company = this.state.assurance_company;
        }
        return {
          code: normData.number ? normData.number : '',
          type: norm.id,
          is_considered: normData.isConsidered,
          expedition: normData.expedition && normData.expedition !== null ? normData.expedition : null,
          expiration: normData.expiration && normData.expiration !== null ? normData.expiration : null,
          data: extraData
        }
      }),
    };

    if (!this.isDollySelected()) {
      dataJson = Object.assign(dataJson, {
        height: this.state.height,
        length: this.state.length,
        width: this.state.width,
        weight: this.state.weight,
        load: this.state.singleMaxLoad.length === 0 ? null : this.state.singleMaxLoad,
      });
    }

    let newData = {
      data: JSON.stringify(dataJson),
      company_id: this.props.fleet?.company || 0,
      fleet_id: this.props.fleet?.id || 0,
    }

    this.generateNormativity().forEach((norm, i) => {
      let normData = this.state[this.getNormPropName(i)] || {};
      normData.files.forEach((f, fI) => {
        newData[''.concat(norm.id, '_', fI + 1)] = f;
      })
    });

    this.props.createFleetDataV2(   
      newData, {
      isForm: true,
      onSuccess: () => {
        this.responseCounter += 1;
        this.forceUpdate();
      },
      onError: (e) => {

        let errors = e.response?.data?.errors || [];
        var errorsToShow = [];

        if (errors.length > 0) {
          if (typeof errors[0] === 'string') {
            errorsToShow = errors;
          } else {
            errorsToShow = errors[0];
          }
        } else {
          errorsToShow = ['Ocurrió un error al guardar, verifica los datos'];
        }

        this.setState(
          {
            showError: true,
            msgError: handleWSErrorWithKeys(errorsToShow),
            showRegisterError: false,
          }, () => {
            this.props.loadGps();
            this.props.loadHint();
            this.fetchCounter = 0;
            this.responseCounter = 0;
            this.forceUpdate();
          }
        )
      }
    });

    // this.fetchCounter += 1;
    // this.props
    //   .createFleetData(
    //     this.props.fleet ? this.props.fleet.id : "",
    //     this.getObjectData(hint, gpsProvider)
    //   )
    //   .then((response) => {
    //     let error = null;
    //     switch (response.type) {
    //       case "FLEET_DATA_POST_SUCCESS":
    //         this.saveTags(response);
    //         this.saveEquipmentData(response);

    //         // if (!this.isDollySelected()) {
    //         this.generateNormativity().forEach((item, index) => {
    //           this.fetchCounter += 1;
    //           this.forceUpdate();
    //           switch (item.name) {
    //             case "card":
    //               this.saveNormativityData(response, item.id, this.state.card);
    //               break;
    //             case "assurance":
    //               this.saveNormativityData(
    //                 response,
    //                 item.id,
    //                 this.state.assurance
    //               );
    //               break;
    //             case "authorization":
    //               this.saveNormativityData(
    //                 response,
    //                 item.id,
    //                 this.state.authorization
    //               );
    //               break;
    //             case "certificate":
    //               this.saveNormativityData(
    //                 response,
    //                 item.id,
    //                 this.state.certificate
    //               );
    //               break;
    //             default:
    //               break;
    //           }
    //         });
    //         // }

    //         this.responseCounter += 1;
    //         this.forceUpdate();
    //         break;
    //       case "FLEET_DATA_POST_ERROR":
    //         error = "Ocurrió un error al registrar, verifica los datos";

    //         if (response.error) {
    //           if (response.error.niv) {
    //             error = `La serie "${this.state.serial}" ya existe`;
    //           } else if (String(response.error).includes("economic_number")) {
    //             error = `El N° económico "${this.state.economicNo}" ya existe`;
    //           } else if (String(response.error).includes("gps_identifier")) {
    //             error = `El Identificador GPS "${this.state.identifier}" ya existe`;
    //           }
    //         }

    //         break;
    //       default:
    //         error = "Ocurrió un error al registrar, verifica los datos";
    //         break;
    //     }

    //     if (error !== null) {
    //       this.setState(
    //         {
    //           showError: true,
    //           msgError: error,
    //         },
    //         () => {
    //           this.fetchCounter = 0;
    //           this.responseCounter = 0;

    //           this.props.loadGps();
    //           this.props.loadHint();
    //           this.forceUpdate();
    //         }
    //       );
    //     }
    //   });
    // this.props.history.push('/company/fleet');
  };

  saveTags = (params) => {
    const {
      response: { id = "0" },
    } = params;

    this.state.fleetTags.forEach((item) => {
      this.props
        .addTag(this.props.fleet ? this.props.fleet.id : "", id, {
          data: id,
          tags: item,
        })
        .then((response) => {
          if (
            response &&
            response.response &&
            response.response.type === "FLEET_NORMATIVITY_POST_ERROR"
          ) {
            this.fetchCounter = 0;
            this.responseCounter = 0;

            this.setState({
              showError: true,
              msgError: "Ocurrió un error al registrar, verifica los datos",
            });
          } /*else {
          this.setState({ responses: this.state.responses.concat([true]) })
        }*/
        });
    });
  };

  saveNormativityData = (params, normativityType, dataToSave) => {
    const {
      response: { id = "0" },
    } = params;

    let newNormativityData = {
      code: dataToSave.number ? dataToSave.number : "",
      fleet_data: id,
      type: normativityType,
      is_considered: dataToSave.isConsidered,
    };

    // dataToSave.expedition && dataToSave.expedition !== null
    //   ? (newNormativityData.expedition = dataToSave.expedition)
    //   : console.log("");
    // dataToSave.expiration && dataToSave.expiration !== null
    //   ? (newNormativityData.expiration = dataToSave.expiration)
    //   : console.log("");

    if(dataToSave.expedition) {
      newNormativityData.expedition = dataToSave.expedition;
    }

    if(dataToSave.expiration) {
      newNormativityData.expiration = dataToSave.expiration
    }
      // debugger;

    this.props
      .createNormativityData(
        this.props.fleet ? this.props.fleet.id : "",
        id,
        newNormativityData
      )
      .then((response) => {
        this.setState({ normativityUpdated: true });
        this.responseCounter += 1;
        this.forceUpdate();
        //console.log(response)
        if (
          response &&
          response.response &&
          response.response.type === "FLEET_NORMATIVITY_POST_ERROR"
        ) {
          this.fetchCounter = 0;
          this.responseCounter = 0;

          this.setState({
            showError: true,
            msgError: "Ocurrió un error al registrar, verifica los datos",
          });
        } else {
          dataToSave.files.forEach((file) => {
            this.fetchCounter += 1;
            this.uploadFileInNormativity(
              response,
              this.props.fleet ? this.props.fleet.id : "",
              id,
              file
            );
          });
        }
      });
  };

  saveEquipmentData = (params) => {
    const {
      response: { id = "0" },
    } = params;

    let newEquipmentData = {
      data: id,
      type: this.state.subTypeId,
    };

    if (!this.isDollySelected()) {
      newEquipmentData.load_shaft_suspension = this.state.loadAxisSuspension;
    }

    this.fetchCounter += 1;
    this.props
      .createEquipmentData(
        id,
        this.props.fleet ? this.props.fleet.id : "",
        newEquipmentData
      )
      .then((response) => {
        let error = null;
        switch (response.type) {
          case "FLEET_EQUIPMENT_POST_SUCCESS":
            this.responseCounter += 1;
            this.forceUpdate();
            break;
          case "FLEET_EQUIPMENT_POST_ERROR":
            error = "Ocurrió un error al registrar, verifica los datos";
            break;
          default:
            error = "Ocurrió un error al registrar, verifica los datos";
            break;
        }

        if (error !== null) {
          this.setState(
            {
              showError: true,
              msgError: error,
              modeEdit: true,
            },
            () => {
              this.fetchCounter = 0;
              this.responseCounter = 0;

              this.forceUpdate();
            }
          );
        }
      });
  };

  // #endregion

  // #region Normativity

  uploadFileInNormativity = (normativityData, fleetId, fleetDataId, file) => {
    const {
      response: { id = "0" },
    } = normativityData;

    this.props
      .uploadImageToNormativity(fleetId, fleetDataId, id, file)
      .then((response) => {
        this.setState({ normativityUpdated: true });
        this.responseCounter += 1;
        this.forceUpdate();
      });
  };

  generateNormativity = () => {
    let results = [];
    //debugger
    this.props.normativityType
      .filter((item) => {
        // console.log(item);
        return (
          `${item.type}` === "2" &&
          !(
            this.isDollySelected() &&
            (`${item.id}` === "8" || `${item.id}` === "6")
          )
        );
      })
      .map((item) => {
        let newData = {
          id: item.id,
          description: item.description,
          documentType: DocumentTypes.file,
          label: "N° ",
          name: this.getNamesNormativity(item.id),
          considered: item.is_considered_displayed,
        };
        return results.push(newData);
      });
    return results;
  };

  deleteImageFromNormativity = (e, obj) => {
    const filesToUpdate = obj.files;
    const filesToCompare = e.target.value;
    let fileToRemove = null;

    if (filesToCompare.length > 0) {
      const differents = filesToUpdate.filter(
        ({ idNormativityImage: id1 }) =>
          !filesToCompare.some(({ idNormativityImage: id2 }) => id2 === id1)
      );

      if (differents.length > 0) {
        fileToRemove = differents[0];
      }
    } else {
      if (filesToUpdate.length > 0) fileToRemove = filesToUpdate[0];
    }

    if (fileToRemove && fileToRemove.idNormativityImage) {
      this.setState({
        fileIdToRemove: fileToRemove.idNormativityImage,
        normativityIdToRemove: obj.id,
        showConfirmDialog: true,
      });
    }
  };

  deleteImage = () => {
    this.props
      .deleteNormativityImagesForFleetData(
        parseInt(this.props.match.params.equipmentId),
        this.props.fleet.id,
        this.state.normativityIdToRemove,
        this.state.fileIdToRemove
      )
      .then((response) => {
        this.setState({ normativityUpdated: true });
        console.log("Remove Image");
        console.log(response);
      });
  };

  // #endregion

  // #region Render

  savingDataText = () => {
    if (this.state.modeEdit) {
      return this.fetchCounter > 0 ? "Guardando..." : "Guardar";
    } else {
      return this.fetchCounter > 0 ? "Registrando..." : "Registrar";
    }
  };

  handleValidate = (response, msg, field) => {
    switch (response.type) {
      case "FLEET_DATA_VALIDATIONS_ECONOMIC_SUCCESS":
      case "FLEET_DATA_VALIDATIONS_NIV_SUCCESS":
      case "FLEET_DATA_VALIDATIONS_GPS_IDENTIFIER_SUCCESS":
      case "FLEET_DATA_VALIDATIONS_ENGINE_NUMBER_SUCCESS":
      case "FLEET_DATA_VALIDATIONS_PLATES_SUCCESS":
        this.setDupliedField(field, false);
        break;
      case "FLEET_DATA_VALIDATIONS_ECONOMIC_ERROR":
      case "FLEET_DATA_VALIDATIONS_NIV_ERROR":
      case "FLEET_DATA_VALIDATIONS_GPS_IDENTIFIER_ERROR":
      case "FLEET_DATA_VALIDATIONS_ENGINE_NUMBER_ERROR":
      case "FLEET_DATA_VALIDATIONS_PLATES_ERROR":
        this.setDupliedField(field, true);
        this.fetchCounter = 0;
        this.responseCounter = 0;

        // if(response.error.error){
        //   this.setState({ showError: true, msgError: `${ msg } ya existe` })
        // } else {
        //   this.setState({ showError: true, msgError: "Comprueba los datos" });
        // }
        break;
      default:
        this.setDupliedField(field, true);
        this.fetchCounter = 0;
        this.responseCounter = 0;
        // this.setState({ showError: true, msgError: "Falló la conexión" })
        break;
    }
  };

  setDupliedField = (field, boolean) => {
    switch (field) {
      case "economic":
        this.setState({ dupliedEconomic: boolean });
        break;
      case "serial":
        this.setState({ dupliedSerial: boolean });
        break;
      case "gps":
        this.setState({ dupliedGps: boolean });
        break;
      case "plates":
        this.setState({ dupliedPlates: boolean });
        break;
      default:
        break;
    }
  };

  isDollySelected = () => {
    return (
      String(21) === String(this.state.typeClassId) ||
      String(22) === String(this.state.typeClassId) ||
      this.state.isDollySelected
    );
  };

  getNamesNormativity = (type) => {
    switch (type) {
      case 6:
        return "card";
      case 7:
        return "assurance";
      case 8:
        return "authorization";
      case 9:
        return "certificate";
      default:
        return "";
    }
  };

  validateTagData = () => {
    if (this.state.fleetTags.length === 0) {
      return false;
    }
    return true;
  };

  validIdGps = () => {
    if (
      this.state.lastIdentifier &&
      this.state.identifier.length > 0 &&
      this.state.modeEdit
    ) {
      return true;
    }
    return false;
  };

  getTypes = () => {

    if (this.state.modeEdit) {

      var typeIdToEvaluate = null;

      for (var i = 0; i < this.props.typeClasses.length; i++) {
        const { classID = '', typeID = '' } = this.props.typeClasses[i];

        if (`${this.state.typeClassId}` === `${classID}`) {
          typeIdToEvaluate = `${typeID}`;
        }
      }

      if (typeIdToEvaluate !== null) {

        return this.props.typeClasses.filter( (item) => {
          const { typeID = ''} = item;
          return (`${typeID}` === typeIdToEvaluate);
        });

      } else {
        return this.props.typeClasses;
      }

    } else {
      return this.props.typeClasses;
    }

  }

  validateUniqueFields = () => {
    
    this.setState({
      validatingFields: true
    }); 

    if (String(this.state.ownPlates) !== String(this.state.plates)) {
      this.props.validatePlates(this.props.fleet.id, this.state.plates).then((platesResponse) => {

        if (platesResponse.type.includes('ERROR')) {
          this.setState({
            validatingFields: false,
            showError: true,
            msgError: `Las placas "${this.state.plates}" estan en uso`
          });
        } else {
          this.validateEconomicField();
        }

      });
    } else {
      this.validateEconomicField();
    }

  }

  validateEconomicField = () => {

    if (String(this.state.ownEconomic) !== String(this.state.economicNo)) {

      this.props.postValidateEconomicNumber(this.props.fleet.id, this.state.economicNo).then((vinResponse) => {

        if (vinResponse.type.includes('ERROR')) {
          this.setState({
            validatingFields: false,
            showError: true,
            msgError: `El N economico "${this.state.economicNo}" esta en uso`
          });
        } else {
          this.validateSerialField();
        }

      });

    } else {
      this.validateSerialField();
    }

  }

  validateSerialField = () => {

    if (String(this.state.ownSerial) !== String(this.state.serial) && !this.isDollySelected()) {

      this.props.postValidateNiv(this.props.fleet.id, this.state.serial).then((vinResponse) => {

        if (vinResponse.type.includes('ERROR')) {
          this.setState({
            validatingFields: false,
            showError: true,
            msgError: `La serie "${this.state.serial}" esta en uso`
          });
        } else {
          this.setState({
            validatingFields: false,
          });

          setTimeout(this.handleSaveFleetData , 200);
        }

      });

    } else {

      this.setState({
        validatingFields: false,
      });

      setTimeout(this.handleSaveFleetData , 200);
    }

  }

  render() {
    return (
      <div className="fleet-equipment-view view">
        <div className="title">
          {this.props.match &&
          this.props.match.params &&
          this.props.match.params.equipmentId
            ? "Información de equipo"
            : "Registro de equipo"}
        </div>
        <Panel
          title="Datos Generales"
          number="1.0"
          className="general-data  text-without-arrow"
        >
          <SelectInput
            label="Tipo/clase *"
            name="typeClassId"
            value={this.state.typeClassId}
            className="item"
            //disabled={this.state.modeEdit && this.isDollySelected()}
            onChange={(e) => {
              this.handleInput(e);
              this.setClassInfo(e.target.value);
            }} 
            items={this.getTypes()}
            validate={[validators.required]}
            onFocused={this.state.onFocused}
          />
          <SelectInput
            label="Sub-tipo *"
            name="subTypeId"
            value={this.state.subTypeId}
            className="item"
            onChange={this.handleInput}
            //disabled={this.state.modeEdit && this.isDollySelected()}
            items={this.state.subTypes}
            validate={[validators.required]}
            onFocused={this.state.onFocused}
          />
          <TextInput
            label="N° económico *"
            name="economicNo"
            value={this.state.economicNo}
            className="item"
            onChange={(e) => {
              if (this.state.dupliedEconomic)
                this.setState({ dupliedEconomic: false });
              this.handleInput(e);
            }}
            validate={[validators.required]}
            onFocused={this.state.onFocused}
            errors={
              this.state.dupliedEconomic
                ? [`El N° económico "${this.state.economicNo}" esta en uso`]
                : []
            }
            handleBlur={(e) => {
              if (String(this.state.ownEconomic) !== String(e.target.value)) {
                this.props
                  .postValidateEconomicNumber(
                    this.props.fleet.id,
                    e.target.value
                  )
                  .then((response) => {
                    this.handleValidate(
                      response,
                      `El N° económico "${this.state.economicNo}"`,
                      "economic"
                    );
                  });
              }
            }}
          />
          <TextInput
            label="Marca *"
            name="brand"
            value={this.state.brand}
            className="item"
            onChange={this.handleInput}
            validate={[validators.required]}
            onFocused={this.state.onFocused}
          />
          <TextInput
            label="Modelo *"
            name="model"
            value={this.state.model}
            className="item"
            onChange={this.handleInput}
            validate={[validators.required]}
            onFocused={this.state.onFocused}
          />

          <TextInput
            type="number"
            length={4}
            numeric={true}
            label="Año *"
            name="year"
            value={this.state.year}
            className="item"
            onChange={this.handleInput}
            suffix={{ element: <SVGIcon name={icons.calendar} /> }}
            validate={[validators.required]}
            onFocused={this.state.onFocused}
          />
          {!this.isDollySelected() && (
            <TextInput
              label="Placas *"
              name="plates"
              value={this.state.plates}
              className="item"
              onChange={(e) => {
                if (this.state.dupliedPlates)
                  this.setState({ dupliedPlates: false });

                this.handleInput(e);
              }}
              validate={[validators.required]}
              onFocused={this.state.onFocused}
              length={10}
              errors={
                this.state.dupliedPlates
                  ? [`Las placas "${this.state.plates}" estan en uso`]
                  : []
              }
              handleBlur={(e) => {
                if (
                  String(this.state.ownPlates) !== String(e.target.value) &&
                  String(e.target.value).length > 0
                ) {
                  this.props
                    .validatePlates(this.props.fleet.id, e.target.value)
                    .then((response) => {
                      this.handleValidate(
                        response,
                        `Las placas "${this.state.plates}"`,
                        "plates"
                      );
                    });
                }
              }}
            />
          )}

          {!this.isDollySelected() && (
            <TextInput
              label="Serie *"
              name="serial"
              value={this.state.serial}
              className="item"
              onChange={(e) => {
                if (this.state.dupliedSerial)
                  this.setState({ dupliedSerial: false });

                this.handleInput(e);
              }}
              validate={[validators.required]}
              onFocused={this.state.onFocused}
              errors={
                this.state.dupliedSerial
                  ? [`La serie "${this.state.serial}" esta en uso`]
                  : []
              }
              handleBlur={(e) => {
                if (String(this.state.ownSerial) !== String(e.target.value)) {
                  this.props
                    .postValidateNiv(this.props.fleet.id, e.target.value)
                    .then((response) => {
                      this.handleValidate(
                        response,
                        `La serie "${this.state.serial}"`,
                        "serial"
                      );
                    });
                }
              }}
            />
          )}

          <ColorSelector
            label="Color *"
            name="color"
            value={this.state.color}
            className="item"
            onChange={this.handleInput}
            validate={[validators.required]}
            onFocused={this.state.onFocused}
          />
          <div className="fleet-tags">
            <ChipContainer
              items={this.fleetTagsToChips()}
              onChange={this.handleTagChange}
              title="Etiquetas"
              name="fleetTags"
            />
          </div>
        </Panel>
        <Panel title="Especificaciones técnicas y mecánicas" number="2.0">
          <div className="specifications">
            
            {
              this.isDollySelected() ? <></> : 
              <FleetSpecificationEquipment
                title="Especificaciones"
                height={this.state.height}
                width={this.state.width}
                length={this.state.length}
                weight={this.state.weight}
                singleMaxLoad={this.state.singleMaxLoad}
                tireQuantity={this.state.tireQuantity}
                axisQuantity={this.state.axisQuantity}
                unitSystem={this.state.unitSystem}
                onChange={this.handleInput}
                isDolly={this.isDollySelected()}
              />
            }

            <div className="specification-data">
              {this.isDollySelected() ? (
                ""
              ) : (
                <SelectInput
                  label="Suspensión eje de carga *"
                  name="loadAxisSuspension"
                  value={this.state.loadAxisSuspension}
                  className="item"
                  onChange={this.handleInput}
                  items={this.props.loadAxisSuspensionTypes}
                  validate={[validators.required]}
                  onFocused={this.state.onFocused}
                />
              )}
              <div className="radio-container item">
                <label className="">Sistema anti-bloqueo de frenos</label>
                <RadioButtons
                  onChange={() =>
                    this.setState({
                      abs: !this.state.abs,
                    })
                  }
                  value="Si"
                  name="abs"
                  id={String(1)}
                  checked={this.state.abs}
                />
                <RadioButtons
                  onChange={() =>
                    this.setState({
                      abs: !this.state.abs,
                    })
                  }
                  value="No"
                  name="abs"
                  id={String(2)}
                  checked={!this.state.abs}
                />
              </div>
            </div>
          </div>
        </Panel>

        {
          !this.state.allowNullNorm ? 
          <Panel title="Normatividad" number="3.0">
            <Table
              headerContent={normativityHeaderContent}
              className="normativity-table"
            >
              {this.generateNormativity().map((ni) => (
                <NormativityTableItem
                  className="row"
                  id={ni.id}
                  description={ni.description}
                  documentType={ni.documentType}
                  key={ni.id}
                  name={ni.name}
                  extraInfo={this.state.plates}
                  value={this.state[ni.name]}
                  considered={ni.considered}
                  extraField={ni.description.includes('seguro') 
                    ? (
                      <TextInput
                        label="Nombre de aseguradora*"
                        name="assurance_company"
                        value={this.state.assurance_company}
                        className="item inline-input"
                        onChange={this.handleInput}
                        validate={[validators.required]}
                        onFocused={this.state.onFocused}
                      />) 
                    : null}
                  onChange={(e) => {
                    this.handleInput(e);
                  }}
                  showLimitFiles={() =>
                    this.setState({ showFilesMessageError: true })
                  }
                  handleInputCheckbox={this.handleInputCheckbox}
                  confirmAction={
                    this.state.modeEdit && ni.id !== 0
                      ? (e, action) => {
                          this.confirmDeleteAction = action;

                          switch (e.target.name) {
                            case "card":
                              this.deleteImageFromNormativity(e, this.state.card);
                              break;
                            case "assurance":
                              this.deleteImageFromNormativity(
                                e,
                                this.state.assurance
                              );
                              break;
                            case "certificate":
                              this.deleteImageFromNormativity(
                                e,
                                this.state.certificate
                              );
                              break;
                            case "authorization":
                              this.deleteImageFromNormativity(
                                e,
                                this.state.authorization
                              );
                              break;
                            default:
                              break;
                          }
                        }
                      : null
                  }
                />
              ))}
            </Table>
          </Panel> : <div/>
        }

        {/* {!this.isDollySelected() && (
          <React.Fragment>
            <Panel title="Proveedor GPS" number="4.0">
              <div className="gps-provider-data">
                <TextInput
                  label="Identificador GPS"
                  name="identifier"
                  value={this.state.identifier}
                  className="item"
                  onChange={(e) => {
                    if (this.state.dupliedGps)
                      this.setState({ dupliedGps: false });
                    this.handleInput(e);
                  }}
                  errors={
                    this.state.dupliedGps
                      ? [
                          `El Identificador GPS "${this.state.identifier}" esta en uso`,
                        ]
                      : []
                  }
                  // validate={[validators.required]}
                  // onFocused={this.state.onFocused}
                  handleBlur={(e) => {
                    if (String(this.state.ownGps) !== String(e.target.value)) {
                      this.props
                        .postValidateGpsIdentifier(
                          this.props.fleet.id,
                          e.target.value
                        )
                        .then((response) => {
                          this.handleValidate(
                            response,
                            `El Identificador GPS "${this.state.identifier}"`,
                            "gps"
                          );
                        });
                    }
                  }}
                  disabled={this.validIdGps()}
                />
                <TextInput
                  label="Proveedor GPS"
                  name="provider"
                  value={this.state.provider}
                  className="item"
                  onChange={this.handleInput}
                  // validate={[validators.required]}
                  // onFocused={this.state.onFocused}
                />
                <TextInput
                  label="Nombre del Proveedor de GPS"
                  name="providerContact"
                  value={this.state.providerContact}
                  className="item"
                  onChange={this.handleInput}
                  // validate={[validators.required]}
                  // onFocused={this.state.onFocused}
                />
                <TextInput
                  label="Email proveedor GPS"
                  name="providerEmail"
                  value={this.state.providerEmail}
                  className="item"
                  onChange={this.handleInput}
                  // validate={[validators.required]}
                  // onFocused={this.state.onFocused}
                />
                <TextInput
                  label="Celular/Teléfono:Proveedor GPS"
                  name="providerPhone"
                  value={this.state.providerPhone}
                  className="item"
                  numeric={true}
                  onChange={this.handleInput}
                  // validate={[validators.required]}
                  // onFocused={this.state.onFocused}
                />
              </div>
            </Panel>
          </React.Fragment>
        )} */}
        <div className="actions">
          <Button
            settings={{
              type: "outline primary white",
              text: "Cancelar",
              onClick: () => this.props.history.goBack(),
            }}
          />
          <Button
            settings={{
              type: "primary",
              disabled: this.fetchCounter > 0 || !this.validateForm() || this.state.validatingFields,
              text: this.savingDataText(),
              // onClick: this.handleSaveFleetData,
              onClick: () => {
                this.validateUniqueFields();
              }
            }}
            dataTip={this.getErrors()}
            dataFor="orbi-tooltip-error"
            onMouseLeave={() => this.handleLeave()}
          />

          <ReactTooltip
            id={"orbi-tooltip-error"}
            place="top"
            type="light"
            effect="solid"
            html={true}
            event="mouseover"
            eventOff="mouseleave"
          />
        </div>

        {/* <ErrorDialog
          open={this.state.responsesWithErrors.length === 0}
          message={'Equipo registrado!!!'}
          acceptText="OK"
          acceptAction={() => this.props.history.goBack()}
          closeAction={() => this.props.history.goBack()}
        /> */}

        <ConfirmDialog
          open={this.state.showConfirmDialog}
          title="Eliminar imagen"
          message={"¿Estás seguro que deseas eliminar la imagen?"}
          acceptText="Eliminar"
          class="delete-tags"
          showCancel={true}
          cancelText="Cancelar"
          cancelButtonType="secondary"
          closeAction={this.actionCloseDialog}
          acceptAction={() => {
            this.actionCloseDialog();
            this.confirmDeleteAction();
            this.deleteImage();
          }}
        />

        <ErrorDialog
          open={this.state.showError}
          message={this.state.msgError}
          acceptText="OK"
          acceptAction={this.actionCloseDialog}
          closeAction={this.actionCloseDialog}
        />

        <ErrorDialog
          open={this.state.showFilesSizeMessageError}
          message={"El archivo que intenta subir sobrepasa los 12 MB."}
          acceptText="Aceptar"
          acceptAction={this.actionCloseDialog}
          closeAction={this.actionCloseDialog}
        />

        <ErrorDialog
          open={this.state.showFilesMessageError}
          message={"El numero de archivos permitido es de 3"}
          acceptText="Aceptar"
          acceptAction={this.actionCloseDialog}
          closeAction={this.actionCloseDialog}
        />
        <ConfirmDialog
          open={this.state.saveExit || false}
          title="Actualizando estatus de normatividad"
          disabledAccept={true}
        />
      </div>
    );
  }

  // #endregion
}

// #region Redux

const mapStateToProps = (state) => {
  const country = state.globalCatalog.session.company.country__description;
  const types = Array.isArray(state.fleetReducer.vehicleType.data)
    ? state.fleetReducer.vehicleType.data
    : [];
  const classes = Array.isArray(state.fleetReducer.fleetClass.data)
    ? state.fleetReducer.fleetClass.data
    : [];

  let typeClasses = [];

  typeClasses = classes
    .filter(
      (item) =>
        parseInt(item.type) === 7 ||
        parseInt(item.type) === 8 ||
        parseInt(item.type) === 9
    )
    .map((item) => {
      return {
        id: item.id,
        description: item.upload_description,
        classID: `${item.id}`,
        typeID: `${item.type}`,
      };
    });

  const fleets = state.fleetReducer.fleets.data;

  return {
    country,
    toast: state.globalCatalog.toast,
    fleetData: state.fleetReducer.fleetData.data,
    equipmentData:
      Array.isArray(state.fleetReducer.equipment.data) &&
      state.fleetReducer.equipment.data.length > 0
        ? state.fleetReducer.equipment.data[0]
        : null,
    tagsData: state.fleetReducer.tags.data,
    normativityData: state.fleetReducer.normativities.data,

    typeClasses: typeClasses,
    equipmentTypes: Array.isArray(state.facilityCreate.equipmentTypes)
      ? state.facilityCreate.equipmentTypes
      : [],

    normativityType: Array.isArray(state.fleetReducer.normativityType.data)
      ? state.fleetReducer.normativityType.data
      : [],
    fleet: Array.isArray(fleets) && fleets.length > 0 ? fleets[0] : null,

    loadAxisSuspensionTypes: Array.isArray(
      state.fleetReducer.equipmentSuspensionType.data
    )
      ? state.fleetReducer.equipmentSuspensionType.data
      : [],

    vehicleType: types,
    fleetClass: classes,

    fleetTagsCatalog: Array.isArray(state.tags.tags)
      ? state.tags.tags
          .filter(
            (tag) =>
              tag.type ===
              (
                (Array.isArray(state.tags.tagTypes.data)
                  ? state.tags.tagTypes.data
                  : []
                ).find(
                  (type) => type.description.toLowerCase() === "flotilla"
                ) || {}
              ).id
          )
          .map((tag) => ({
            id: tag.id,
            description: tag.title,
            color: tag.color,
          }))
      : [],

    gpsProvider: Array.isArray(state.fleetReducer.gpsProvider.data)
      ? state.fleetReducer.gpsProvider.data
      : [],
    hints: Array.isArray(state.fleetReducer.hints.data)
      ? state.fleetReducer.hints.data
      : [],
  };
};

// const namesNormativity = ["card", "assurance", "authorization", "certificate"];

const mapDispatchToProps = (dispatch) => {
  dispatch(loadEquipmentTypes());
  dispatch(loadSession());
  dispatch(loadFleetClasses());
  dispatch(loadTags());
  dispatch(loadFleets());
  dispatch(loadVehicleTypes());
  dispatch(loadNormativityTypes());
  dispatch(loadHints());
  dispatch(loadGpsProvider());
  dispatch(loadEquipmentSuspensionTypes());

  return {
    updateFleetDataV2: (params, opt) => 
      dispatch(partialEntity("COMPANIES.FLEETS.DATA.EQUIPMENTS", params, opt)),
    createFleetDataV2: (params, opt) => 
      dispatch(createEntity("COMPANIES.FLEETS.DATA.EQUIPMENTS", params, opt)),
    createFleetData: (fleetId, fleetDataObj) =>
      dispatch(createFleetData(fleetId, fleetDataObj)),
    createEquipmentData: (fleetDataId, fleetId, fleetDataObj) =>
      dispatch(createEquipmentData(fleetDataId, fleetId, fleetDataObj)),

    createNormativityData: (fleetId = 1, fleetDataId, normativityData) =>
      dispatch(createNormativityData(fleetId, fleetDataId, normativityData)),
    uploadImageToNormativity: (fleetId = 1, fleetDataId, normativityId, file) =>
      dispatch(postUploadImage(fleetId, fleetDataId, normativityId, file)),

    createHint: (description, type) => dispatch(createHint(description, type)),
    createNewGPSProvider: (name, email, telephone) =>
      dispatch(createGPSProvider(name, email, telephone)),

    loadGps: () => dispatch(loadGpsProvider()),
    loadHint: () => dispatch(loadHints()),

    loadFleetData: (fleetDataId, fleetId) =>
      dispatch(loadFleetData(fleetDataId, fleetId)),
    loadEquipmentData: (fleetDataId, fleetId) =>
      dispatch(loadEquipmentData(fleetDataId, fleetId)),
    loadTagsForFleetData: (fleetDataId, fleetId) =>
      dispatch(loadTagsForFleetData(fleetDataId, fleetId)),
    loadNormativityForFleetData: (fleetDataId, fleetId) =>
      dispatch(loadNormativityForFleetData(fleetDataId, fleetId)),
    loadNormativityImagesForFleetData: (fleetDataId, fleetId, normId) =>
      dispatch(loadNormativityImagesForFleetData(fleetDataId, fleetId, normId)),

    deleteNormativityImagesForFleetData: (
      fleetDataId,
      fleetId,
      normId,
      imageId
    ) =>
      dispatch(
        deleteNormativityImagesForFleetData(
          fleetDataId,
          fleetId,
          normId,
          imageId
        )
      ),
    deleteTagInFleetData: (fleetDataId, fleetId, tagId) =>
      dispatch(deleteTagInFleetData(fleetDataId, fleetId, tagId)),

    updateFleetData: (fleetId, fleetDataId, fleetDataObj) =>
      dispatch(updateFleetData(fleetId, fleetDataId, fleetDataObj)),
    updateEquipmentData: (fleetDataId, fleetId, fleetDataObj, equipmentId) =>
      dispatch(
        updateEquipmentData(fleetDataId, fleetId, fleetDataObj, equipmentId)
      ),
    updateNormativityData: (fleetId, fleetDataId, normId, normativityData) =>
      dispatch(
        updateNormativityData(fleetId, fleetDataId, normId, normativityData)
      ),

    addTag: (fleetId, fleetDataId, tagDataObj) =>
      dispatch(addTagToFleet(fleetId, fleetDataId, tagDataObj)),
    showToast: (payLoad) => dispatch(addToast(payLoad)),
    postValidateEconomicNumber: (fleetId, economic_number) =>
      dispatch(postValidateEconomicNumber(fleetId, economic_number)),
    postValidateNiv: (fleetId, niv) => dispatch(postValidateNiv(fleetId, niv)),
    postValidateGpsIdentifier: (fleetId, gps_identifier) =>
      dispatch(postValidateGpsIdentifier(fleetId, gps_identifier)),
    validatePlates: (fleetId, plates) =>
      dispatch(validatePlates(fleetId, plates)),
    updateDataNormativityStatus: (companyId, fleetId, dataId) =>
      dispatch(updateDataNormativityStatus(companyId, fleetId, dataId)),
  };
};

// #endregion

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FleetEquipmentView)
);

// #region Others

const normativityHeaderContent = [
  { text: "Nombre documento", width: "30%" },
  { text: "Documento", width: "15%" },
  { text: "Número del documento", width: "auto" },
  { text: "Fecha de expedición", width: "12%" },
  { text: "Fecha de expiración", width: "12%" },
];

// const joinRelatedArrays = (arr1 = [], arr2 = [], relatedField) => {
//   let count = -99999;
//   let x = [];
//   arr1.forEach((a1) => {
//     let t = arr2.filter((a2) => a1.id === a2[relatedField]);

//     let typeID = `${a1.id}`;

//     if (`${a1.type}` === "2") {
//       if (t.length) {
//         t.forEach((a2) => {
//           const idToAssing = count++;
//           if (String(a1.description).toLowerCase().includes("dolly"))
//             dollyId = idToAssing;
//           x.push({
//             id: idToAssing,
//             description: a2.upload_description,
//             classID: `${a2.id}`,
//             typeID,
//             ordering: a2.ordering,
//           });
//         });
//       } else {
//         const idToAssing = count++;
//         if (String(a1.description).toLowerCase().includes("dolly"))
//           dollyId = idToAssing;
//         x.push({ id: idToAssing, description: a1.description, typeID });
//       }
//     }
//   });
//   x.sort(function (a, b) {
//     return a["ordering"] - b["ordering"] || a["description"] - b["description"];
//   });
//   return x;
// };

// #endregion
