import React from "react";
import TagCategoryContainer from "../../shared/component/tag-category-container";
import EditTagsModal from "../../shared/dialog/edit-tags-modal";
import {
  listCompanyRolesPermissions,
  editCompanyRoles,
  editCompanyRolesPermissions,
  createCompanyRol,
  deleteCompanyRol,
  listCompanyRolPermissions,
  getSessionCompanyData
} from "../../redux/reducers/company-reducer";
// import { loadPermissionsCatalog } from '../../redux/reducers/global-catalog.reducer';
import { connect } from "react-redux";
import CatalogSelectionFooter from "../../shared/component/catalog-selection-footer";
import Tag from "../../shared/controls/tag";
import ConfirmDialog from "../../shared/dialog/confirm-dialog";
import {
  loadPermissionsCatalog,
  validateObjPerms
} from "../../redux/reducers/global-catalog.reducer";
import ValidatePerms from "../../shared/component/validate-perms";
import { createEntity, partialEntity, SaveItemCounter } from "../../redux/api";

class PermissionsTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openEditModal: false,
      openDeleteModal: false,
      selectedTags: [],
      selectedCategory: "roles",
      selectionModeOn: false,
      loading: false,
    };
  }

  componentDidMount() {
    this.loadRoles();
  }

  loadRoles = () => {
    this.props.reloadRoles();
  };

  handleRolClick = e => {
    this.setState({ selectedTags: [e.target.id], selectedCategory: "roles" });
    this.showEditModal();
  };
  handleCreateClick = categoryId => {
    this.setState({ selectedTags: [], selectedCategory: categoryId });
    this.showEditModal();
  };
  selectTag = e => {
    let selectedTags = [...this.state.selectedTags];
    if (selectedTags.includes(e.target.id)) {
      selectedTags.splice(selectedTags.indexOf(e.target.id), 1);
    } else {
      selectedTags.push(e.target.id);
    }
    this.setState({ selectedTags: selectedTags });
  };
  selectAllTags = () => {
    let categoryTags = this.props.rolesList;
    if (this.state.selectedTags.length < categoryTags.length) {
      this.setState({ selectedTags: categoryTags.map(tag => tag.id) });
    } else {
      this.setState({ selectedTags: [] });
    }
  };
  showDeleteModal = () => {
    this.setState({ openDeleteModal: true });
  };
  closeDeleteModal = () => {
    this.setState({ openDeleteModal: false, selectedTags: [] });
  };
  showEditModal = () => {
    this.setState({ openEditModal: true });
  };
  closeEditModal = () => {
    this.setState({ openEditModal: false });
  };
  onAccept = (newParams, oldParams, callback) => {
    this.setState({loading: true})
    let counter = new SaveItemCounter(() => {
      //console.log("DONE")
      this.loadRoles();
      this.setState({loading: false});
      this.closeEditModal();
      callback && callback();
    });
    let tagParams = {
      description: newParams.title,
      color: newParams.color,
      company_id: this.props.companyId
    };
    let tagPermissions = newParams.permissions;
    if (this.state.selectedTags.length) {
      if (this.state.selectedTags.length > 1) {
        counter.add();
        oldParams.forEach(rol => {
          tagParams.rol_id = rol.id;
          tagParams.description = rol.description;
          // this.saveRolChanges(tagParams);
          this.props.updateRol(
            tagParams, {
            onSuccess: () => {
              counter.done();
            }
          })
        });
      } else {
        tagParams.rol_id = this.state.selectedTags[0];
        counter.add();
        //console.log("add");
        this.props.updateRol(
          tagParams, {
          onSuccess: () => {
            tagPermissions.forEach(permission => {
              //company_id, rol_id, permission_id, p_create, p_read, p_update, p_delete
              counter.add();
              //console.log("add");
              this.props
                .updateRolPermissions({
                  company_id: this.props.companyId,
                  rol_id: this.state.selectedTags[0],
                  permission_id: permission.id,
                  p_create: permission.p_create,
                  p_read: permission.p_read,
                  p_update: permission.p_update,
                  p_delete: permission.p_delete
                }, {
                  onSuccess: () => {
                    counter.done();
                  }
                })
            });
            counter.done();
          }
        });
        
        // this.saveRolChanges(tagParams);
      }
    } else {
      counter.add();
      //console.log("add");
      this.props.createRol(
        tagParams,
        {
          onSuccess: (result) => {
            this.props.getCompanyRolPermissions(result.id).then(permissionsData => {
              if (permissionsData) {
                tagPermissions.forEach(tP => {
                  counter.add();
                  //console.log("add");
                  this.props
                    .updateRolPermissions({
                      company_id: this.props.companyId,
                      rol_id: result.id,
                      permission_id: permissionsData.find(
                        pD => pD.module_permission === tP.id
                      ).id,
                      p_create: tP.p_create,
                      p_read: tP.p_read,
                      p_update: tP.p_update,
                      p_delete: tP.p_delete
                    }, {
                      onSuccess: () => {
                        counter.done();
                      },
                      onError: () => {
    
                      }
                    })
                    // .then(() => this.loadRoles());
                });
                counter.done();
              } else {
                counter.done();
              }
            });
          }
        }
      )
    }
  };
  createRol = tagParams => {
    this.props.createRol(tagParams).then(() => this.loadRoles());
    // this.setState({ openEditModal: false });
  };
  saveRolChanges = tagParams => {
    this.props.updateRol(tagParams).then(() => this.loadRoles());
    // this.closeEditModal();
  };
  deleteRoles = () => {
    this.state.selectedTags.forEach(rolId => {
      this.props.deleteRol(rolId).then(() => this.loadRoles());
    });
    this.closeDeleteModal();
  };
  toggleSelectionMode = categoryId => {
    this.setState({
      selectionModeOn: !this.state.selectionModeOn,
      selectedCategory: categoryId,
      selectedTags: []
    });
  };
  render() {
    return (
      <ValidatePerms perms={this.props.permsRead}>
        <div className="tags-tab">
          <TagCategoryContainer
            key={"roles"}
            id={"roles"}
            title={"Roles"}
            tags={this.props.rolesList || []}
            selectedTags={this.state.selectedTags}
            selectedCategory={this.state.selectedCategory}
            handleTagClick={this.handleRolClick}
            handleCreateClick={this.handleCreateClick}
            selectTag={this.selectTag}
            selectionModeOn={this.state.selectionModeOn}
            toggleSelectionMode={this.toggleSelectionMode}
            {...this.props}
          />
          <EditTagsModal
            open={this.state.openEditModal}
            tags={this.props.rolesList.filter(r =>
              this.state.selectedTags.includes(r.id)
            )}
            permissions={this.props.permissionsCatalog.filter(
              p => p.company_type === this.props.companyInfo.type
            )}
            category={this.state.selectedCategory}
            acceptAction={this.onAccept}
            closeAction={this.closeEditModal}
            loading={this.state.loading}
            isPermission={true}
          />
          <ConfirmDialog
            open={this.state.openDeleteModal}
            title="Eliminar roles"
            message={"¿Estás seguro que deseas eliminar estos roles?"}
            contentObject={
              <div>
                {this.props.rolesList
                  .filter(t => this.state.selectedTags.includes(t.id))
                  .map(tag => (
                    <Tag
                      key={tag.id}
                      id={tag.id}
                      title={tag.title}
                      color={tag.color}
                    />
                  ))}
              </div>
            }
            acceptText="Eliminar"
            class="delete-tags"
            showCancel={true}
            cancelText="Cancelar"
            cancelButtonType="secondary"
            closeAction={this.closeDeleteModal}
            acceptAction={this.deleteRoles}
          />
          <CatalogSelectionFooter
            show={this.state.selectionModeOn}
            selectedElements={this.state.selectedTags.length}
            selectAll={this.selectAllTags}
            actions={[
              this.props.validateObjPerms(
                [
                  {
                    id: "5",
                    perm: "u"
                  }
                ],
                {
                  description: "Editar roles",
                  f: this.showEditModal
                }
              ),
              this.props.validateObjPerms(
                [
                  {
                    id: "5",
                    perm: "d"
                  }
                ],
                {
                  description: "Eliminar",
                  f: this.showDeleteModal
                }
              )
            ]}
          />
        </div>
      </ValidatePerms>
    );
  }
}

// export default PermissionsTab;

const mapStateToProps = state => {
  return {
    companyInfo: state.companyReducer.company.data,
    permissionsCatalog: state.globalCatalog.permissionsCatalog.data,
    rolesList: Array.isArray(state.companyReducer.companyRoles.data)
      ? state.companyReducer.companyRoles.data.map(rol => ({
          ...rol,
          title: rol.description
        }))
      : []
  };
};
const mapDispatchToProps = dispatch => {
  dispatch(getSessionCompanyData());
  dispatch(loadPermissionsCatalog());
  return {
    createRol:  (params, opt) => 
      dispatch(createEntity("COMPANIES.ROLES", params, opt)),
    // createRol: rol => dispatch(createCompanyRol(rol)),
    reloadRoles: () => dispatch(listCompanyRolesPermissions()),
    getCompanyRolPermissions: rol_id =>
      dispatch(listCompanyRolPermissions(rol_id)),
    updateRol:  (params, opt) => 
      dispatch(partialEntity("COMPANIES.ROLES", params, opt)),
    // updateRol: params => dispatch(editCompanyRoles(params)),
    updateRolPermissions: (params, opt) => 
      dispatch(partialEntity("COMPANIES.ROLES.PERMISSIONS", params, opt)),
    // updateRolPermissions: params =>
    //   dispatch(editCompanyRolesPermissions(params)),
    deleteRol: rolId => dispatch(deleteCompanyRol(rolId)),
    validateObjPerms: (perms, obj, evalPerm) =>
      validateObjPerms(perms, obj, evalPerm)
  };
};
const connectToStore = connect(mapStateToProps, mapDispatchToProps);

export default connectToStore(PermissionsTab);
