import React from "react";
import Modal from "react-modal";
import Button from "../controls/button";
import { cloneDeep } from "lodash";
import { getEntityItems, partialEntity } from "../../redux/api";
import { loadSession } from "../../redux/reducers/global-catalog.reducer";
import Loader from "../icons/tat/loader";
import { connect } from "react-redux";
import { groupFacilities } from "../utils";
import { cancelMultipleRequests, readEntities } from "../../redux/api/actions";
import ReactTooltip from "react-tooltip";
import InputSearch from "../controls/input-search";

Modal.setAppElement("#root");

class ManageFacilityUserModal extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      search: '',
      user: props.notification?.user,
      notification: props.notification?.notification,
      facilitiesSelected: cloneDeep(props.notification?.facilities || []),
      facilitiesAdded: cloneDeep(props.notification?.facilities || []),
      isAllSelected: false,
      errorMessage: null,
      confirmActions: false
    }

  }

  hasChanged = () => {
    if (this.state.facilitiesSelected.length > 0 && this.state.facilitiesAdded.length === this.state.facilitiesSelected.length) {
      return !this.state.facilitiesAdded.every( item => this.state.facilitiesSelected.includes(item));
    } else if (this.state.facilitiesSelected.length > 0) {
      return true;
    }

    return false;
  }

  componentDidMount() {
    this.props.loadFacilities({ company_id: this.props.companyId });
  }

  buildTitle = () => {
    let subtitle = '';

    if (this.state.user) {
      subtitle = `${this.state.user.users__first_name} - ${this.state.user.users__email} - ${this.state.user.role__description} - ${this.state.user.users_type__description}`;
    }

    return(
      <>
        <div className="title-action">{this.state.confirmActions ? 'Resumen' : 'Gestionar Instalaciones'}</div>
        <div className="subtitle">{subtitle}</div>
      </>
    );
  }

  buildSearch = () => {
    if (this.state.confirmActions) return '';

    return(
      <div className="search-component">
        <InputSearch
          type='text'
          label='Buscar Instalaciones'
          name='search'
          className="full"
          onChange={(name, value) => {
            this.handleChange({
              target: { name, value }
            })
          }}
        />
        <img
          src="/images/search.svg"
          alt=""
          className="icono"
        />
      </div>
    )
  }

  isTagSelected = (tag) => {
    return tag.items.every( facility => this.state.facilitiesSelected.includes(facility.id) )
  }

  getFacilitiesSearched = () => {

    if (this.state.search === '') { return this.props.facilitiesByTag; }

    let tags_temp = cloneDeep(this.props.facilitiesByTag);

    this.props.facilitiesByTag.map( (tag, index) => {
      tags_temp[index].items = tag.items.filter( facility => facility.description.toLowerCase().includes(this.state.search.toLowerCase()))
    });

    return tags_temp.filter( tag => tag.items.length > 0);
  }

  buildTags = () => {
    if (this.state.confirmActions) return '';

    return(
      <div className="users-section">

        <div className="user-item">
          <div
            className={`checkbox-container ${this.state.isAllSelected ? 'selected' : ''}`}
            onClick={() => {
              if (this.state.isAllSelected) {
                this.setState({
                  facilitiesSelected: [],
                  isAllSelected: false
                });
              } else {

                let facility_ids = [];

                this.props.facilitiesByTag.map( tag => {
                  tag.items.map( facility => {
                    facility_ids.push(facility.id)
                  })
                })

                this.setState({
                  facilitiesSelected: facility_ids,
                  isAllSelected: true
                });
              }
            }}
          >
            <div className="checkbox"></div>
          </div>

          <div style={{width: 8}}/>
          {'Todos'}
        </div>

        <div className="users-list" style={{height: this.state.errorMessage ? 'calc(100% - 300px)' : 'calc(100% - 270px)'}}>
          {
            this.getFacilitiesSearched().map( tag => {
              return(
                <React.Fragment key={`tag-content-${tag.id}`}>
                  <div className="user-item" key={`tag-${tag.id}`}>
                    <div
                      className={`checkbox-container ${this.isTagSelected(tag) ? 'selected' : ''}`}
                      onClick={() => {
                        if (this.isTagSelected(tag)) {
                          let facilities_not_allowed = tag.items.map( item => item.id);
                          this.setState({
                            facilitiesSelected: this.state.facilitiesSelected.filter( facility_id => !facilities_not_allowed.includes(facility_id)),
                            isAllSelected: false
                          })
                        } else {
                          let facilities_to_allow = tag.items.filter( item => !this.state.facilitiesSelected.includes(item.id) ).map( item => item.id);

                          this.setState({
                            facilitiesSelected: this.state.facilitiesSelected.concat(facilities_to_allow)
                          });
                        }
                      }}
                    >
                      <div className="checkbox"></div>
                    </div>

                    <div style={{width: 8}}/>
                    {tag.description}
                  </div>
                  {this.buildFacilities(tag)}
                </React.Fragment>
              );
            })
          }
        </div>     

      </div>
    );
  }

  buildFacilities = (tag) => {
    return(
      <>
        {
          tag.items.map( facility => {
            return(
              <div className="user-item" key={`tag-${tag.id}-${facility.id}`}>
                <div style={{width: 30}}/> 
                <div
                    className={`checkbox-container ${this.state.facilitiesSelected.includes(facility.id) ? 'selected' : ''}`}
                    onClick={() => {
                      if (this.state.facilitiesSelected.includes(facility.id)) {
                        this.setState({
                          facilitiesSelected: this.state.facilitiesSelected.filter( item_id => item_id !== facility.id),
                          isAllSelected: false
                        })
                      } else {
                        this.state.facilitiesSelected.push(facility.id);
                        this.setState({});
                      }
                    }}
                  >
                  <div className="checkbox"></div>
                </div>
                <div style={{width: 20}}/> 
                {facility.description}
              </div>
            );
          })
        }
      </>
    );
  }

  handleChange = (e) => {
    this.setState({[e.target.name]: e.target.value})
  }

  onClose = () => {
    if (this.props.isUpdatingLoading) return;
    if (this.props.closeAction) this.props.closeAction();
  };

  buildActions = () => {
    return(
      <div className="actions" style={{marginTop: 5}}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <div/>

          <div style={{display: 'flex'}}>
            <Button
              disabled={this.props.isUpdatingLoading}
              text={this.state.confirmActions ? 'Cancelar' : 'Cerrar'}
              type={'btn secondary primary'}
              onClick={() => {
                if (this.state.confirmActions) {
                  this.setState({confirmActions: false});
                } else {
                  this.onClose();
                }
              }}
            />

            <div style={{width: 20}}/>
            
            <Button
              disabled={this.props.isUpdatingLoading || this.state.confirmActions ? false : !this.hasChanged() }
              text={this.props.isUpdatingLoading ? <Loader circleColor={'black'}/> : this.state.confirmActions ? 'Aceptar' : 'Continuar' }
              type={'btn outline primary dark'}
              onClick={() => {

                if (this.state.confirmActions) {
                  let resume = this.getResume();

                  let data = {
                    company_id: this.props.companyId,
                    action: 'add_facilities',
                    facilities_to_add: resume.new_facilities.map( item => item.id),
                    facilities_to_remove: resume.facilities_to_remove.map( item => item.id),
                    notification_id: this.state.notification?.id || -1,
                    group: this.state.user?.group_id || -1
                  };
  
                  this.props.manageFacilities(data, {
                    onSuccess: () => {
                      if (this.props.handleChange) {
                        this.props.handleChange({
                          target: {
                            name:'show-manage-facility-success',
                            value: this.state.notification?.id || -1
                          }
                        });
                      }
                    },
                    onError: (e) => {
                      this.setState({
                        errorMessage: e.response?.data?.error || 'Ocurrió un error, intente de nuevo'
                      })
                    }
                  });

                } else {
                  this.setState({
                    confirmActions: true
                  });
                }

              }}
            />

          </div>

        </div>
      </div>
    );
  }

  getResume = () => {
    let new_facilities=[];
    let facilities_to_remove=[];

    this.props.facilitiesByTag.map( tag => {
      (tag.items || []).map( facility => {
        if (this.state.facilitiesSelected.includes(facility.id) && !this.state.facilitiesAdded.includes(facility.id)) {
          let facilities_found = new_facilities.filter( item => item.id === facility.id);
          if (facilities_found.length === 0) {
            new_facilities.push(facility);
          }
        } else if (this.state.facilitiesAdded.includes(facility.id) && !this.state.facilitiesSelected.includes(facility.id)) {
          let facilities_found = facilities_to_remove.filter( item => item.id === facility.id);
          if (facilities_found.length === 0) {
            facilities_to_remove.push(facility);
          }
        }
      })
    });

    return {new_facilities, facilities_to_remove}
  }

  confirmActions = () => {
    if (!this.state.confirmActions) return '';

    let resume = this.getResume();

    let new_facilities=resume.new_facilities;
    let facilities_to_remove=resume.facilities_to_remove;

    return(
      <div style={{
        height: '70%',
        marginBottom: '20px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        textDecoration: 'underline',
        alignItems: 'center',
        fontSize: 18
      }}>
        <div
          data-tip
          data-for={`new_users`}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: 'fit-content'
          }}
        ><img alt="" src="/images/oval green.svg"></img> <div style={{width: 10}}/> {`${new_facilities.length} Instalaciones agregados al usuario`}</div>

        {
          (new_facilities && new_facilities.length > 0) &&
          <ReactTooltip
            id={`new_users`}
            className='eta-tooltip-content'
            event="mouseover"
            eventOff="mouseleave"
            place="top"
          > 
            {
              new_facilities.map( (item, index) => {
                return(
                  <div key={`new-facility-${index}`}>{`${item.description}`}</div>
                );
              })
            }
          </ReactTooltip>
        }


        <div
          data-tip
          data-for={`remove_users`}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: 'fit-content'
          }}
        ><img alt="" src="/images/oval red.svg"></img> <div style={{width: 10}}/> {`${facilities_to_remove.length} Instalaciones eliminadas del usuario`}</div>
        {
          (facilities_to_remove && facilities_to_remove.length > 0) &&
          <ReactTooltip
            id={`remove_users`}
            className='eta-tooltip-content'
            event="mouseover"
            eventOff="mouseleave"
            place="top"
          > 
            {
              facilities_to_remove.map( (item, index) => {
                return(
                  <div key={`remove-facility-${index}`}>{`${item.description}`}</div>
                );
              })
            }
          </ReactTooltip>
        }
      </div>
    );
  }

  render() {
    return (
      <Modal
        isOpen={this.props.showModal}
        portalClassName={`dialog add-user-dialog ${
          this.props.addClass ? this.props.addClass : ""
        }`}
        overlayClassName="overlay"
        className="content"
      >
        <div className="close" onClick={() => {
          this.onClose();
        }}></div>

        { this.buildTitle() }
        { this.buildSearch() }
        { this.buildTags() }
        { this.confirmActions() }

        {
          this.state.errorMessage && <div style={{color: 'red', fontSize: 13}}>{this.state.errorMessage}</div>
        }

        { this.buildActions() }

      </Modal>
    );
  }
} 

const mapStateToProps = (state) => {
  const companyId = state.globalCatalog.session.company.id;
  const isUpdatingLoading = state?.api['COMPANIES.FACILITIES']?.status?.isFetching || false;
  const companyFacilities = getEntityItems(state, "COMPANIES.FACILITIES");
  const facilitiesByTag = groupFacilities(companyFacilities); 
  const isUsersLoading = state?.api['COMPANIES.NOTIFICATIONS.MANAGEUSERS']?.status?.isFetching || false;

  return {
    companyId,
    isUpdatingLoading: isUsersLoading || isUpdatingLoading,
    facilitiesByTag
  };
}

const mapDispatchToProps = (dispatch) => {
  dispatch(loadSession());

  return {
    cancelMultipleRequests: () => dispatch(cancelMultipleRequests()),
    loadFacilities: (params, opts) => dispatch(readEntities("COMPANIES.FACILITIES", params, opts)),
    manageFacilities: (params, opt) => dispatch(partialEntity("COMPANIES.NOTIFICATIONS.MANAGEUSERS", params, opt))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageFacilityUserModal);