import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { View } from "../shared/styled/view";
import { TabContainer } from "../company/home-co/style";
import OptionSelectorSwitch from "../shared/controls/option-selector-switch";
import { CrmTableView } from "./crm-table-view";
import { cancelMultipleRequests, readEntities } from "../redux/api/actions";
import moment from 'moment';
import Loader from "../shared/icons/tat/loader";
import GenericDialog from "../shared/dialog/generic-modal";
import { sendMessageToMultipleUsersInSocket, showSimpleFlashNotification } from "../shared/utils";
import SocketIO from "../shared/socket-io";

const ITEMS = [
  { description: "CO", value: 1 },
  { description: "TR", value: 2 },
]

class CrmView extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      key: '',
      tabSelected: 1,
      secondTabSelected: 1,
      seed: Math.random(),
      acceptCompany: null,
      cancelCompany: null,
      data: [],
      messageError: null
    };

  }

  componentDidMount() {

    SocketIO.disconnect();
    let timestamp = new Date().getTime();
    SocketIO.connect(`crm-${timestamp}`);

    document.addEventListener("keydown", this.cancelUpdate);

    const key = prompt('Indique el token');
    this.setState({
      key
    }, () => {

      if (`${key}` === 'null' || `${key}` === '') {
        this.props.history.push('/login');
        return;
      }

      this.loadItems();
    });
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.cancelUpdate, true);
  }

  cancelUpdate = (e) => {
    if(e.key === "Escape" && this.props.isLoading){
      this.props.cancelMultipleRequests();
    }
  }

  getTabs = () => { 
    return(
      <>
        <div 
          className={`tab ${this.state.tabSelected === 1 ? 'selected' : ''}`}
          onClick={() => {
            if (this.state.tabSelected !== 1) {
              this.setState({tabSelected: 1}, () => this.loadItems())
            }
          }}
        >Prospectos</div>

        <div 
          className={`tab ${this.state.tabSelected === 2 ? 'selected' : ''}`}
          onClick={() => {
            if (this.state.tabSelected !== 2) {
              this.setState({tabSelected: 2}, () => this.loadItems())
            }
          }}
        >Recomendados</div>

        <div 
          className={`tab ${this.state.tabSelected === 3 ? 'selected' : ''}`}
          onClick={() => {
            if (this.state.tabSelected !== 3) {
              this.setState({tabSelected: 3}, () => this.loadItems())
            }
          }}
        >Compañías no verificadas</div>

        <div 
          className={`tab ${this.state.tabSelected === 4 ? 'selected' : ''}`}
          onClick={() => {
            if (this.state.tabSelected !== 4) {
              this.setState({tabSelected: 4}, () => this.loadItems())
            }
          }}
        >Compañías verificadas</div>

        <div 
          className={`tab ${this.state.tabSelected === 5 ? 'selected' : ''}`}
          onClick={() => {
            if (this.state.tabSelected !== 5) {
              this.setState({tabSelected: 5}, () => this.loadItems())
            }
          }}
        >Clientes</div>
      </>
    );
  }

  onSuccess = (response) => {
    this.setState({
      data: response,
      seed: Math.random()
    });
  }

  onError = (e) => {
    let error = e.response?.data?.error || 'invalid token';

    if (error === 'invalid token') {
      this.props.history.push('/login');
      return;
    }
  }

  loadItems = () => {

    switch(this.state.tabSelected) {
      case 1:
        if (this.state.secondTabSelected === 1) {
          this.props.loadProspectsCO({
            key: this.state.key,
            requiresAuth: false
          }, {
            onSuccess: this.onSuccess,
            onError: this.onError
          });
        } else if (this.state.secondTabSelected === 2) {
          this.props.loadProspectsTR({
            key: this.state.key,
            requiresAuth: false
          }, {
            onSuccess: this.onSuccess,
            onError: this.onError
          });
        }

        break;
      case 2:
        this.props.loadRecommendations({
          key: this.state.key,
          requiresAuth: false
        }, {
          onSuccess: this.onSuccess,
          onError: this.onError
        });
        break;

      case 3:
        this.props.loadNotVerifiedCompanies({
          key: this.state.key,
          requiresAuth: false
        }, {
          onSuccess: this.onSuccess,
          onError: this.onError
        });
        break;
      case 4:
        this.props.loadVerifiedCompaniesNoRequisitions({
          key: this.state.key,
          requiresAuth: false
        }, {
          onSuccess: this.onSuccess,
          onError: this.onError
        });
        break;
      case 5:
          this.props.loadVerifiedCompaniesWithRequisitions({
            key: this.state.key,
            requiresAuth: false
          }, {
            onSuccess: this.onSuccess,
            onError: this.onError
          });
          break;
      default:
        break;
    }
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value }, () => {
      if (name === 'secondTabSelected') {
        this.loadItems();
      }
    });
  }

  handleGridChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    switch (name) {
      case 'open-url':
        window.open(value, "_blank");
        break;
      case 'accept-company':
        this.setState({
          acceptCompany: value
        });
        break;
      case 'cancel-company':
        this.setState({
          cancelCompany: value
        });
        break;
      default:
        break;
    }
  }

  render() {
    return(
      <View>

        {
          (this.props.isLoading) && 
          <div
            style={{
              position: "absolute",
              top: "0",
              left: "0",
              width: "100%",
              height: "100%",
              backgroundColor: "#00000080",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              zIndex: 1001,
              backdropFilter: "blur(3px)"
            }}
          >
            <div style={{display: "flex", alignItems: "center"}}>
              <Loader width="100px" height="100px"/>
              <div style={{color: "#fff", fontSize: "36px"}}>
                Cargando
              </div>
            </div>
            <div style={{color: "#fff", fontSize: "30px"}}>
              Si desea cancelar la consulta, presione ESC
            </div>
          </div>
        }
        
        <div style={{height: 20}}/>

        <TabContainer>
          { this.getTabs() }
        </TabContainer>

        {
          this.state.tabSelected === 1 ?
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <div style={{ width: 400 }}>
              <OptionSelectorSwitch
                value={this.state.secondTabSelected}
                name="secondTabSelected"
                onChange={this.handleChange}
                items={ITEMS}
              />
            </div>
          </div> : <div style={{height: 34}}/>
        }

        <div style={{height: 20}}/>

        <CrmTableView
          key={this.state.seed}
          handleChange={this.handleGridChange}
          configuration={{}}
          rowData={this.state.data}
          tab={this.state.tabSelected}
        />

        {
          this.state.acceptCompany !== null &&
          <GenericDialog
            open={this.state.acceptCompany !== null}
            title={'¿Estás seguro?'}
            message={`Esta acción aceptará a ${this.state.acceptCompany.orbi_id || ''} y será irreversible`}
            isLoading={this.props.isCompanyActionLoading}
            acceptAction={() => {

              let rs = new Date().getTime();
              let rq = rs + parseInt(this.state.acceptCompany.id);

              let data = {
                rs,
                rq,
                action: 'accept',
                requiresAuth: false
              }

              this.props.acceptCompany(data, {
                onSuccess: (response) => {
                  sendMessageToMultipleUsersInSocket(
                    null,
                    response['users'],
                    response['data']
                  );

                  showSimpleFlashNotification(`Se aceptó correctamente la compañia ${this.state.acceptCompany.orbi_id || ''}`, null);
                  this.setState({
                    acceptCompany: null
                  }, () => {
                    this.loadItems();
                  });
                },
                onError: (e) => {
                  this.setState({
                    messageError: e.response?.data?.error || 'Ocurrió un error',
                    acceptCompany: null
                  });
                }
              });

            }}
            closeAction={() => this.setState({acceptCompany: null})}
          />
        }

        {
          this.state.cancelCompany !== null &&
          <GenericDialog
            open={this.state.cancelCompany !== null}
            title={'¿Estás seguro?'}
            message={`Esta acción cancelará a ${this.state.cancelCompany.orbi_id || ''} y será irreversible`}
            isLoading={this.props.isCompanyActionLoading}
            acceptAction={() => {

              let rs = new Date().getTime();
              let rq = rs + parseInt(this.state.cancelCompany.id);

              let data = {
                rs,
                rq,
                action: 'cancel',
                requiresAuth: false
              }

              this.props.cancelCompany(data, {
                onSuccess: (response) => {

                  sendMessageToMultipleUsersInSocket(
                    null,
                    response['users'],
                    response['data']
                  );

                  showSimpleFlashNotification(`Se aceptó correctamente la compañia ${this.state.cancelCompany.orbi_id || ''}`, null);
                  this.setState({
                    cancelCompany: null
                  }, () => {
                    this.loadItems();
                  });
                },
                onError: (e) => {
                  this.setState({
                    messageError: e.response?.data?.error || 'Ocurrió un error',
                    cancelCompany: null
                  });
                }
              });

            }}
            closeAction={() => this.setState({cancelCompany: null})}
          />
        }

        {
          this.state.messageError !== null &&
          <GenericDialog
            open={this.state.messageError !== null}
            title={'Error'}
            message={`${this.state.messageError}`}
            acceptAction={() => this.setState({messageError: null})}
            closeAction={() => this.setState({messageError: null})}
          />
        }

      </View>
     )
  };

}

const mapStateToProps = (state) => {
  const isRecommendationsLoading = state?.api['CRM.RECOMMENDATIONS']?.status?.isFetching || false;
  const isProspectsCOLoading = state?.api['CRM.PROSPECTS.CO']?.status?.isFetching || false;
  const isProspectsTRLoading = state?.api['CRM.PROSPECTS.TR']?.status?.isFetching || false;
  const isNotVerifiedLoading = state?.api['CRM.NOTVERIFIED']?.status?.isFetching || false;
  const isVerifiedNoRequisitionsLoading = state?.api['CRM.VERIFIEDNOREQUISITIONS']?.status?.isFetching || false;
  const isVerifiedWithRequisitionsLoading = state?.api['CRM.VERIFIEDWITHREQUISITIONS']?.status?.isFetching || false;
  const isAcceptLoading = state?.api['CRM.NOTVERIFIED.ACCEPT']?.status?.isFetching || false;
  const isCancelLoading = state?.api['CRM.NOTVERIFIED.CANCEL']?.status?.isFetching || false;

  return {
    isLoading: isRecommendationsLoading || isProspectsCOLoading || isProspectsTRLoading || isNotVerifiedLoading || isVerifiedWithRequisitionsLoading || isVerifiedNoRequisitionsLoading,
    isCompanyActionLoading: isAcceptLoading || isCancelLoading
  };
}

const mapDispatchToProps = (dispatch) => {

  return {
    cancelMultipleRequests: () => dispatch(cancelMultipleRequests()),
    loadRecommendations: (params, opt) => dispatch(readEntities("CRM.RECOMMENDATIONS", params, opt)),
    loadProspectsCO: (params, opt) => dispatch(readEntities("CRM.PROSPECTS.CO", params, opt)),
    loadProspectsTR: (params, opt) => dispatch(readEntities("CRM.PROSPECTS.TR", params, opt)),
    loadNotVerifiedCompanies: (params, opt) => dispatch(readEntities("CRM.NOTVERIFIED", params, opt)),
    loadVerifiedCompaniesWithRequisitions: (params, opt) => dispatch(readEntities("CRM.VERIFIEDWITHREQUISITIONS", params, opt)),
    loadVerifiedCompaniesNoRequisitions: (params, opt) => dispatch(readEntities("CRM.VERIFIEDNOREQUISITIONS", params, opt)),
    acceptCompany: (params, opt) => dispatch(readEntities("CRM.NOTVERIFIED.ACCEPT", params, opt)),
    cancelCompany: (params, opt) => dispatch(readEntities("CRM.NOTVERIFIED.CANCEL", params, opt))
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CrmView));