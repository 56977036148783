import React from 'react';
import { connect } from 'react-redux';
import Panel from '../../shared/component/panel';
import InputProfileImage from '../../shared/controls/input-profile-image';
import TextInput from '../../shared/controls/text-input';
import SelectInput from '../../shared/controls/select-input';
import Button from '../../shared/controls/button';
import ErrorDialog from '../../shared/dialog/error-dialog';
import ReactTooltip from 'react-tooltip';
import { loadSession, refreshSession, addToast } from '../../redux/reducers/global-catalog.reducer';
import { withRouter } from 'react-router-dom';
import { validators } from '../../shared/validate';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import ConfirmDialog from '../../shared/dialog/confirm-dialog';
import { loadProductsCatalogs, loadProductsFilters, createNewProduct, RECEIVE_NEW_PRODUCT, getProduct, updateProduct, updateProductData, RECEIVE_PRODUCT_IMAGE } from '../../redux/reducers/products.reducer';
import ChipContainer from '../../shared/component/chip-container';
import { getUrlsEnv } from '../../shared/backend-api';
import { getEntityItems, readEntities } from '../../redux/api';
import Loader from '../../shared/icons/tat/loader';
import { cancelMultipleRequests } from '../../redux/api/actions';


let typingTimer;

class RegisterNewProductView extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      productName: '',
      sku: '',
      skuTemp: '',
      generalUnit: 0,
      netWeight: '',
      netUnit: 0,
      grossWeight: '',
      grossUnit: 0,
      volume: '',
      volumeUnit: '',
      tags: [],
      profileImage: null,
      length: '',
      width: '',
      height: '',
      dimension_unit: 0,
      tariff_fraction: '',
      amount: '',
      currency: 0,

      sat_object: null,
      sat_code: 0,
      sat_code_description: '',
      dangerous_product: '2',
      dangerous_product_code: 0,
      packing_code: 0,

      showOptions: false,

      crop: {
        unit: '%',
        width: 300,
        aspect: 1 / 1,
      },

      editImage: false,
      imageToCrop: null,
      uniqueError: '',
      editProductId: 0,
      volumeData: 0,
      maxElementsToShow: 50
    }

  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClick, false);
    const productIdToEdit = this.props.match?.params?.product || 0;

    this.props.loadSATUnitCatalog({},{
      onSuccess: (r) => {
        setTimeout(function() {
          this.getVolume();
        }.bind(this), 500)
      }
    });
    // this.props.loadProductKeys();
    this.props.loadSATUnitWeightCatalog();
    this.props.loadDangerCodes();
    this.props.loadPackingTypes();
    this.props.loadUnitCatalog({
      company_id: this.props.companyId
    }, {
      onSuccess: (r) => {
        this.setState({
          // grossUnit: (r.find(u => u.code === 'kg') || {}).id ?? '',
          // netUnit: (r.find(u => u.code === 'kg') || {}).id ?? '',
          volumeUnit: (r.find(u => u.code === 'cbm') || {}).id ?? ''
        }, () => { this.getVolume(); })
      }
    })
    //this.props.loadProductKeys({search: ''});

    if (productIdToEdit !== 0) {
      // this.props.loadProductKeys({search: ''});
      this.setState({
        editProductId: productIdToEdit
      });

      this.props.getProduct(productIdToEdit).then((item) => {
        const { data = {} } = item;
        const { description, gross_weight, gross_weight_unit, net_weight, net_weight_unit, product_unit, sku, url, volume, volume_unit, producttag_product, packing_code, dangerous_product, dangerous_product_code, sat_currency } = data;

        let profileImage = null;

        if (url !== null) {
          profileImage = getUrlsEnv().files.concat(url);
        }

        // console.log(this.props.unitsCatalogType2);

        const list2 = this.props.unitsCatalogType2 || [];
        const list3 = this.props.unitsCatalogType3 || [];
        const list1 = this.props.unitsCatalogType1 || [];

        let product_unit_temp = '';
        let gross_weight_unit_temp = '';
        let volume_unit_temp = '';
        let net_weight_unit_temp = '';

        for (let i = 0; i < list2.length; i++) {
          if (`${product_unit || ''}` === `${list2[i].id}`) {
            product_unit_temp = product_unit;
          }
        }

        for (let k = 0; k < list3.length; k++) {
          if (`${volume_unit || ''}` === `${list3[k].id}`) {
            volume_unit_temp = volume_unit;
          }
        }

        for (let j = 0; j < list1.length; j++) {
          if (`${gross_weight_unit || ''}` === `${list1[j].id}`) {
            gross_weight_unit_temp = gross_weight_unit;
          }
          if (`${net_weight_unit || ''}` === `${list1[j].id}`) {
            net_weight_unit_temp = net_weight_unit;
          }
        }

        let sat_currency_str = data?.data?.sat_currency?.clave|| 'MXN'

        this.props.loadCurrencyCatalog({}, {
          onSuccess: (r) => {
            let mxn = (r.find(c => c.clave === sat_currency_str) || {}).id || 0;
            this.setState({currency: mxn});
          }
        });

        this.setState({
          productName: description,
          sku,
          skuTemp: sku,
          netWeight: net_weight || '',
          netUnit: data?.data?.sat_net_weight_unit?.id || '',
          generalUnit: data?.data?.sat_product_unit?.id || '',
          grossWeight: gross_weight || '',
          grossUnit:data?.data?.sat_gross_weight_unit?.id || '',
          volume: volume || '',
          volumeUnit: volume_unit_temp === '' ? this.state.volumeUnit : volume_unit_temp,
          tags: producttag_product.map((item) => {
            const { id } = item.tag;
            return id;
          }),
          profileImage,

          //sat_code: sat_code || 0,
          dangerous_product: dangerous_product ? '1' : '2',
          dangerous_product_code: data?.data?.material_peligroso?.id ?? 0,
          packing_code: data?.data?.tipo_embalaje?.id ?? 0,
          dimension_unit: data?.data?.sat_dimension_unit?.id ?? 0,
          length: data?.length ?? '',
          height: data?.height ?? '',
          width: data?.width ?? '',
          sat_code: data?.data?.clave_prod_serv_cp?.id ?? '',
          sat_code_description: data?.data?.clave_prod_serv_cp?.descripcion ?? '',
          sat_object: data?.data?.clave_prod_serv_cp ?? null,
          tariff_fraction: `${data?.tariff_fraction ?? ''}`,
          amount: `${data?.commodity_value ?? ''}`,
        }, () => {
          this.getVolume()
        });

      });

    }

  }

  componentWillUnmount(){
    document.removeEventListener("mousedown", this.handleClick, false);
  }

  handlePicture = () => {
    this.refs.fileUploader.value = '';
    this.refs.fileUploader.click();
  }

  handleCreate = () => {

    if (this.props.isLoading) return;

    const { productName, sku, generalUnit, netWeight, netUnit, grossWeight, grossUnit, volume, volumeUnit, tags, profileImage, sat_code, packing_code, dangerous_product, dangerous_product_code, dimension_unit } = this.state;

    const valueToSend = {
      producttag_product: tags.map((item) => { return {tag: item}; }),
      sku,
      description: productName,
      // volume: this.getVolume(),
      dimension_unit: dimension_unit,
      volume: this.state.volumeData,
      volume_unit: volumeUnit,
      net_weight: parseFloat(netWeight),
      net_weight_unit: netUnit,
      gross_weight: parseFloat(grossWeight),
      gross_weight_unit: grossUnit,
      product_unit: generalUnit === '' ? null : generalUnit,
      dangerous_product: parseInt(dangerous_product) === 1 ? true :  false,
      tariff_fraction: `${this.state.tariff_fraction ?? ''}`,
      commodity_value: this.state.amount === '' ? null : this.state.amount,
      length: this.state.length === '' ? null : parseFloat(this.state.length),
      height: this.state.height === '' ? null : parseFloat(this.state.height),
      width: this.state.width === '' ? null : parseFloat(this.state.width),
      currency: this.state.currency ? parseInt(this.state.currency) : null,
      data: {
        clave_prod_serv_cp: sat_code || null,
        material_peligroso: parseInt(dangerous_product) === 1 ? (dangerous_product_code || null) : null,
        tipo_embalaje: parseInt(dangerous_product) === 1 ? (packing_code || null) : null,
        length: this.state.length === '' ? null : parseFloat(this.state.length),
        height: this.state.height === '' ? null : parseFloat(this.state.height),
        width: this.state.width === '' ? null : parseFloat(this.state.width),
        tariff_fraction: `${this.state.tariff_fraction ?? ''}`,
        // amount: this.state.amount ?? '',
        // commodity_value: this.state.amount ?? '',
        currency: this.state.currency ? parseInt(this.state.currency) : null,
      },
      // sat_code: sat_code || 0,
      // dangerous_product_code: dangerous_product === '1' ? (dangerous_product_code || null) : null,
      // packing_code: dangerous_product === '1' ? (packing_code || null) : null,
    };

    if (this.state.editProductId !== 0) {

      this.props.updateProductData(this.state.editProductId, valueToSend).then( responseProduct => {

        if (responseProduct.type === RECEIVE_PRODUCT_IMAGE) {

          if (profileImage !== null && !String(profileImage).includes("http")) {

            let formData = new FormData();
            formData.append("url", profileImage);
  
            this.props.updateProduct(this.state.editProductId, formData).then( response => {
              this.props.showToast({text: 'Se actualizó con éxito el producto'});
              this.props.history.goBack();
            });
          } else {

            this.props.showToast({text: 'Se actualizó con éxito el producto'});
            this.props.history.goBack();

          }

        } else {
          this.setState({ showError: true, msgError: "Ocurrió un error, comprueba los datos" });
        }

      });

    } else {
  
      this.props.createNewProduct(valueToSend).then( response => {
        //debugger;
        if (response.type === RECEIVE_NEW_PRODUCT) {
  
          if (profileImage !== null && !String(profileImage).includes("http")) {
  
            const { id } = response.data;

            let formData = new FormData();
            formData.append("url", profileImage);

            this.props.updateProduct(id, formData).then( response => {
              this.props.showToast({text: 'Se creó con éxito el producto'});
              this.props.history.goBack();
            });
  
          } else {
            this.props.showToast({text: 'Se creó con éxito el producto'});
            this.props.history.goBack();
          }
  
        } else {
          this.setState({ showError: true, msgError: "Ocurrió un error, comprueba los datos" });
        }
  
      });

    }

  }

  actionCloseDialog = () => {
    this.setState({
      openDialog: false,
      showError: false
    });
  };

  dragAndDrop = e => {

    e.preventDefault()
    e.stopPropagation()
    let dt = e.dataTransfer
    let files = dt.files

    let pictureFrame = document.getElementsByClassName("pictureFrameFac")
    let pictureButton = document.getElementsByClassName("btnPicture")

    switch (e.type) {
      case "dragenter":
      case "dragover":
        pictureFrame[0].style.transform = "scale(1.1, 1.1)"

        pictureButton[0].style.color = "#3D77F7"
        pictureButton[0].style.border = "2px solid #ffffff"
        pictureButton[0].style.backgroundColor = "#ffffff"
        break
      default:
        pictureFrame[0].style.transform = "scale(1.0, 1.0)"

        pictureButton[0].style.color = "rgb(232, 240, 255)"
        pictureButton[0].style.border = "2px solid white"
        pictureButton[0].style.background = "none"

        var reader = new FileReader();
        reader.addEventListener('load', () => {
          this.setState({
            imageToCrop: reader.result,
            editImage: true
          });
        })
        reader.readAsDataURL(files[0]);
        break;
    }
  }

  isValidForm = () => {
    const { productName, sku, generalUnit, netWeight, netUnit, grossWeight, grossUnit, volume, volumeUnit, uniqueError, dimension_unit, length, width, height, dangerous_product, dangerous_product_code, packing_code} = this.state;

    var dangerous_validation = true;

    if (parseInt(dangerous_product) === 1 && ((dangerous_product_code === 0 || dangerous_product_code === '' || Number.isNaN(dangerous_product_code)) || (packing_code === 0 || packing_code === '' || Number.isNaN(packing_code)))) {
      dangerous_validation = false;
    }

    return (
      productName !== '' 
      && sku !== '' 
      // && generalUnit 
      && this.isValidNumber(netWeight) 
      && netUnit 
      && this.isValidNumber(grossWeight) 
      && grossUnit 
      // && this.isValidNumber(volume) 
      // && volumeUnit 
      && uniqueError === ''
      && dangerous_validation
      // && dimension_unit
      // && length
      // && width
      // && height
      //&& (this.props.companyCountry !== 'México' || this.state.sat_code !== 0)
    );
  }

  isValidNumber(numberToTest) {

    if (numberToTest !== '') {
      const ceroOccurences = [...String(numberToTest)].filter(l => l === '.').length;
      if (ceroOccurences === 0) {
        return true;
      } else {
        return String(numberToTest).length > 1;
      }
    }

    return false;

  }

  handleLeave = () => {
    this.setState({ onFocused: true })
  }

  getErrors = () => {
    let errors = ''

    errors += this.state.productName === '' ? `<b>Nombre del producto<b/><br/>` : ''
    errors += this.state.sku === '' ? `<b>Sku<b/><br/>` : ''
    errors += !isNaN(this.state.generalUnit) ? '' : `<b>Unidad<b/><br/>`
    errors += this.state.netWeight === '' ? `<b>Peso Neto<b/><br/>` : ''
    errors += !isNaN(this.state.netUnit) ? '' : `<b>Unidad - Peso Neto<b/><br/>`
    errors += this.state.grossWeight === '' ? `<b>Peso Bruto<b/><br/>` : ''
    errors += !isNaN(this.state.grossUnit) ? '' : `<b>Unidad - Peso Bruto<b/><br/>`
    // errors += !this.state.dimension_unit ? `<b>Unidad - Dimensión del empaque<b/><br/>` : ''
    // errors += !this.state.length ? `<b>Largo<b/><br/>` : ''
    // errors += !this.state.height ? `<b>Alto<b/><br/>` : ''
    // errors += !this.state.width ? `<b>Ancho<b/><br/>` : ''
    // errors += this.state.volume === '' ? `<b>Volumen<b/><br/>` : ''
    // errors += this.state.volumeUnit ? '' : `<b>Unidad - Volumen<b/><br/>`
    errors += this.state.uniqueError !== '' ? `<b>Sku ya esta en uso<b/><br/>` : ''
    //errors += (this.props.companyCountry === 'México' && this.state.sat_code === 0) ? '<b>Selecciona Clave de Producto SAT<b/><br/>' : ''

    if (parseInt(this.state.dangerous_product) === 1) {
      errors += (this.state.dangerous_product_code === 0 || this.state.dangerous_product_code === '' || Number.isNaN(this.state.dangerous_product_code)) ? `<b>Clave de peligro<b/><br/>` : ''
      errors += (this.state.packing_code === 0 || this.state.packing_code === '' || Number.isNaN(this.state.packing_code)) ? `<b>Clave de embalaje<b/><br/>` : ''
    }

    if (errors !== '') {
      errors = `Por favor completa los siguientes campos:<br/>${errors}`
    }

    return errors
  }

  handleChange = (event) => {
    let property = event.target.name;
    let value = event.target.value;

    if (property === 'dangerous_product' && parseInt(value) === 2) {
      this.state.dangerous_product_code = 0;
      this.state.packing_code = 0;
    }

    this.setState({ [property]: value });

  }

  handleChangeSelector = (event) => {
    let property = event.target.name;
    let value = event.target.value;

    if (`${value}` === '0') {
      this.setState({ [property]: '' }, () => { this.getVolume(); });
    } else {

      if (property === 'netUnit' || property === 'grossUnit') {
        this.setState({ netUnit: value, grossUnit: value }, () => { this.getVolume(); });
      } else {
        this.setState({ [property]: value }, () => { this.getVolume(); });
      }
      
    }

  }

  handleChangeNumber = (event) => {
    let property = event.target.name;
    let value = event.target.value;

    const newValue = String(value).replace(/[^0-9\.]/g, '');
    const ceroOccurences = [...newValue].filter(l => l === '.').length;

    if (ceroOccurences < 2) {
      this.setState({ [property]: newValue }, () => { this.getVolume() });
    }
  }

  handleChipContainerClick = (event) => {
    let setTo = !event.target.value;
    const items = setTo ? [...this.state.tags, event.target.name] : this.state.tags.filter(f => f !== event.target.name);
    this.setState({ tags: items });
  }

  mapTagsToChips = () => {
    return this.props.tagFilter.map((ft) => {
      let active = this.state.tags.find(t => t === ft.id) ? true : false;
      return {
        id: ft.id,
        title: ft.title,
        color: ft.color,
        isFull: active,
        name: 'tags'
      }
    })
  }

  handleUniqueFieldBlur = event => {
    var skuFound = false;

    if (this.state.editProductId && this.state.sku === this.state.skuTemp) {
      this.setState({uniqueError: ''});
      return;
    }

    for(var i = 0; i < this.props.skuFilter.length; i++) {
      if (this.props.skuFilter[i].description === this.state.sku) {
        skuFound = true;
      }
    }

    this.setState({
      uniqueError: skuFound ? 'Sku esta en uso' : ''
    });
  }

  getDoneButton = () => {

    if (this.props.isLoading) {
      return 'Cargando...';
    } else if (this.state.editProductId !== 0) {
      return 'Actualizar';
    } else {
      return 'Agregar';
    }

  }

  getVolume = () => {
    let v = (this.state.height || 0) * (this.state.length || 0) * (this.state.width || 0);
    let cmId = (this.props.lengthUnits.find(u => u.code === 'cm') || {}).id || 0;
    const newValueToSet =  (v/(`${this.state.dimension_unit}` === cmId.toString() ? 1000000 : 61023.744094732)).toString() ?? '';

    this.setState({
      volumeData: newValueToSet
    });
  }

  handleSatCode = (e) => {
    const {value, name} = e.target;
    this.setState({[name]: value, showOptions: true, sat_code: 0, maxElementsToShow: 50})
    if(String(value).length >= 2) {
      this.props.cancelMultipleRequests();
      this.props.loadProductKeys({search: value, isMultipleCancels: true});
    } else if (String(value).length === 0) {
      this.setState({
        dangerous_product: '2',
        dangerous_product_code: 0,
        packing_code: 0,
        sat_object: null,
        sat_code_description: ''
      }); 
    }
  }

  selectOption = (o) => {

    if (o.material_peligroso === '1') {
      this.state.dangerous_product = '1';
    } else if (o.material_peligroso === '0' || o.material_peligroso === '0,1') {
      this.state.dangerous_product = '2';
    }

    this.setState({showOptions: false, sat_code: o.id, sat_code_description: o.description, sat_object: o})
  }
  handleClick = (e) => {
    if (this.node.contains(e.target)) return;
    this.setState({showOptions: false});
  }

  isDangerousProduct = () => {
    return (this.state.sat_object?.material_peligroso ?? '') === '1' || parseInt(this.state.dangerous_product) === 1;
  }

  getProductKeys = () => {
    return this.props.product_keys.slice(0, this.state.maxElementsToShow);
  }

  handleScroll = (e) => { 
    this.scrollTop = e.target.scrollTop;
    const bottom = (e.target.scrollHeight - e.target.scrollTop) < (e.target.clientHeight + 1);
    if (bottom) { 
      this.setState({
        maxElementsToShow: this.state.maxElementsToShow + 50
      });
    }
  }

  render() {

    return (
      <div className='register products'>

        <input accept="image/*" type="file" id="file" ref="fileUploader" style={{ display: "none" }} onChange={event => {

          event.stopPropagation();
          event.preventDefault();
          var reader = new FileReader();
          reader.addEventListener('load', () => {
            this.setState({
              imageToCrop: reader.result,
              editImage: true
            });
          })
          reader.readAsDataURL(event.target.files[0]);

        }} />

        <h1 className='title'>{ this.state.editProductId === 0 ? 'Agregar producto' : 'Editar producto'}</h1>

        <Panel title='Datos' number='01' className='panel'>

          <div className='form'>
            <div className='data'>

              <TextInput
                type='text'
                label='Nombre del producto'
                name='productName'
                // dataTip="<ul><li>El nombre de tu empresa no debe exceder de <b>45 caracteres</b></li></ul>"
                // dataFor="orbi-tooltip-company-name"
                className='full'
                validate={[validators.required]}
                onChange={this.handleChange}
                value={this.state.productName}
                onFocused={this.state.onFocused}
              />

              <div className='formDivided'>
                <TextInput
                  type='text'
                  label='Sku'
                  name='sku'
                  className='full'
                  value={this.state.sku}
                  onChange={this.handleChangeNumber}
                  validate={[validators.required]}
                  onFocused={this.state.onFocused}
                  handleBlur={this.handleUniqueFieldBlur}
                  errors={(this.state.uniqueError !== '') ? [this.state.uniqueError] : []}
                />

                <SelectInput
                  label='Unidad'
                  className="full"
                  name="generalUnit"
                  //items={this.props.unitsCatalogType2 || []}
                  items={this.props.skuUnits || []}
                  value={parseInt(this.state.generalUnit)}
                  onChange={this.handleChangeSelector}
                  validate={[validators.required]}
                  onFocused={this.state.onFocused}
                />
              </div>

              <div className='formDivided'>
                <TextInput
                  type='text'
                  label='Peso Neto'
                  name='netWeight'
                  className='full'
                  value={this.state.netWeight}
                  onFocused={this.state.onFocused}
                  onChange={this.handleChangeNumber}
                  validate={[validators.required]}
                />

                <SelectInput
                  label='Unidad'
                  className="full"
                  name="netUnit"
                  items={this.props.generalUnits || []}
                  value={parseInt(this.state.netUnit)}
                  onChange={this.handleChangeSelector}
                  validate={[validators.required]}
                  onFocused={this.state.onFocused}
                />
              </div>

              <div className='formDivided'>
                <TextInput
                  type='text'
                  label='Peso Bruto'
                  name='grossWeight'
                  className='full'
                  value={this.state.grossWeight}
                  onFocused={this.state.onFocused}
                  onChange={this.handleChangeNumber}
                  validate={[validators.required]}
                />

                <SelectInput
                  label='Unidad'
                  className="full"
                  name="grossUnit"
                  items={this.props.generalUnits || []}
                  value={parseInt(this.state.grossUnit)}
                  onChange={this.handleChangeSelector}
                  validate={[validators.required]}
                  onFocused={this.state.onFocused}
                />
              </div>
              <div className='formDivided'>
                <SelectInput 
                  label='Unidad de dimensión del empaque'
                  className="full"
                  name="dimension_unit"
                  items={this.props.lengthUnits || []}
                  value={parseInt(this.state.dimension_unit)}
                  onChange={this.handleChangeSelector}
                  validate={[validators.required]}
                  // disabled={true}
                  // hideCaret={true}
                />
                <TextInput
                  type='text'
                  label='Largo'
                  name='length'
                  className='full'
                  value={this.state.length}
                  // onFocused={this.state.onFocused}
                  onChange={this.handleChangeNumber}
                  // validate={[validators.required]}
                  disabled={!this.state.dimension_unit}
                />
              </div>
              <div className='formDivided'>
                <TextInput
                  type='text'
                  label='Alto'
                  name='height'
                  className='full'
                  value={this.state.height}
                  // onFocused={this.state.onFocused}
                  onChange={this.handleChangeNumber}
                  // validate={[validators.required]}
                  disabled={!this.state.dimension_unit}
                />
                <TextInput
                  type='text'
                  label='Ancho'
                  name='width'
                  className='full'
                  value={this.state.width}
                  // onFocused={this.state.onFocused}
                  onChange={this.handleChangeNumber}
                  // validate={[validators.required]}
                  disabled={!this.state.dimension_unit}
                />
              </div>

              <div className='formDivided'>
                <TextInput
                  type='text'
                  label='Volumen'
                  name='volume'
                  className='full'
                  // value={this.getVolume()}
                  value={this.state.volumeData}
                  onFocused={this.state.onFocused}
                  onChange={this.handleChangeNumber}
                  validate={[validators.required]}
                  disabled={true}
                />

                <SelectInput
                  label='Unidad'
                  className="full"
                  name="volumeUnit"
                  items={this.props.unitsCatalogType3 || []}
                  value={parseInt(this.state.volumeUnit)}
                  onChange={this.handleChangeSelector}
                  validate={[validators.required]}
                  disabled={true}
                  hideCaret={true}
                />
              </div>

              <div style={{color: "#fff", marginTop: "20px"}}>
                Los siguientes campos solo aplican para empresas establecidas en, ó que exportan a, México.
              </div>

              <TextInput
                type='text'
                label='Fracción arancelaria'
                name='tariff_fraction'
                className='full'
                value={this.state.tariff_fraction}
                onFocused={this.state.onFocused}
                onChange={this.handleChangeNumber}
                // validate={[validators.required]}
                // disabled={true}
              />
              
              <div className='formDivided'>
                <TextInput
                  type='text'
                  label='Valor de mercancía'
                  name='amount'
                  className='full'
                  value={this.state.amount}
                  onFocused={this.state.onFocused}
                  onChange={this.handleChangeNumber}
                  // validate={[validators.required]}
                  // disabled={true}
                />

                <SelectInput
                  label='Moneda'
                  className="full"
                  name="currency"
                  items={this.props.currencyCatalog || []}
                  value={parseInt(this.state.currency)}
                  onChange={this.handleChangeSelector}
                  // validate={[validators.required]}
                  // disabled={true}
                  // hideCaret={true}
                />
              </div>

              {/* <SelectInput
                label='Clave de Producto SAT'
                className="full"
                name="sat_code"
                items={this.props.product_keys || []}
                // items={[]}
                value={parseInt(this.state.sat_code)}
                onChange={this.handleChangeSelector}
                // validate={[validators.required]}
              /> */}

              <div ref={(node) => (this.node = node)}>
                <TextInput
                  type='text'
                  label='Clave de Producto SAT (selecciona una):'
                  name='sat_code_description'
                  className='full'
                  value={this.state.sat_code_description}
                  // value={this.getVolume()}
                  // onFocused={this.state.onFocused}
                  onChange={this.handleSatCode}
                  // validate={[validators.required]}
                  // disabled={true}
                  onClick={() => this.setState({showOptions: true})}
                  // handleFocus={() => this.setState({showOptions: true})}
                  // handleBlur={() => this.setState({showOptions: false})}
                />  
                {
                  this.state.showOptions 
                  ? <div style={{position: "relative"}}>
                      <div 
                        onScroll={this.handleScroll} 
                        style={{ position: "absolute", top: "-20px", zIndex: "1", backgroundColor: "#fff", width: "100%", maxHeight: "200px", overflowY: "auto", display: this.props.isSatKeysLoading ? 'flex' : 'block', flexDirection: 'column', alignItems: 'center'}}
                      > 
                        { 
                          this.props.isSatKeysLoading ? <Loader circleColor={'black'} width="40px" height="40px"/> :
                          (this.getProductKeys()).map(o => <div key={`products-sat-${o.id}`} className="synthetic-option" onClick={() => this.selectOption(o)}>{o.description}</div>)
                        }
                      </div>
                    </div>
                  : ''
                }  
              </div>

              

              {/* <label for="satCodeInput">Clave de Producto SAT:</label>
              <input list="satCodes" name="satCodeInput" id="satCodeInput" onChange={(e) => {this.handleSatCode(e)}}/>
              <datalist id="satCodes">
                {
                  (this.props.product_keys || []).map(o => <option value={o.description} onClick={() => console.log("CLICK ", o)}/>)
                }
              </datalist> */}
              {
                (this.state.sat_object !== null || String(this.state.sat_code_description).length > 0) &&
                <SelectInput
                  label='Peligroso'
                  className="full"
                  name="dangerous_product"
                  items={[{id: 1, description: 'Sí'}, {id: 2, description: 'No'}]}
                  value={parseInt(this.state.dangerous_product)}
                  onChange={this.handleChange}
                  disabled={(this.state.sat_object?.material_peligroso ?? '') === '1' || (this.state.sat_object?.material_peligroso ?? '') === '0'}
                  hideCaret={(this.state.sat_object?.material_peligroso ?? '') === '1' || (this.state.sat_object?.material_peligroso ?? '') === '0'}
                  // validate={[validators.required]} 
                />
              }

              {
                parseInt(this.state.dangerous_product) === 1 ?
                <>
                  <SelectInput
                    label='Clave de peligro'
                    className="full"
                    name="dangerous_product_code"
                    items={this.props.danger_codes || []}
                    value={parseInt(this.state.dangerous_product_code)}
                    onChange={this.handleChangeSelector}
                    validate={ this.isDangerousProduct() ? [validators.required] : []}
                    onFocused={this.state.onFocused}
                    hideDefaultOption
                  />
                  <SelectInput
                    label='Clave de embalaje'
                    className="full"
                    name="packing_code"
                    items={this.props.packing_types || []}
                    value={parseInt(this.state.packing_code)}
                    onChange={this.handleChangeSelector}
                    validate={ this.isDangerousProduct() ? [validators.required] : []}
                    onFocused={this.state.onFocused}
                    hideDefaultOption
                  />
                </>
                : ''
              }


              

              <div className="tags-panel">
                <div className="text">Selecciona o añade las etiquetas para este producto:</div>

                {
                  (this.props.tagFilter || []).length > 0 ?
                  <ChipContainer
                    items={this.mapTagsToChips()}
                    background='dark'
                    onChange={this.handleChipContainerClick}
                  />
                  : this.props.isFetchingFilters ? <Loader/> : ""
                }

              </div>

              <div className='extraPadding'/>

            </div>

            <InputProfileImage
              dragAndDrop={this.dragAndDrop}
              handlePicture={this.handlePicture}
              handleSave={this.handleSave}
              profileImage={this.state.profileImage}
              caption="Dale clic al cuadro para agregar tu foto o bien, arrástrala desde tu computadora:"
              defaultImage='/images/product_background.png'
            />

          </div>

        </Panel>

        <div className="contentButton">

          <Button
            text={this.getDoneButton()}
            type={'primary'}
            disabled={!this.isValidForm()}
            onClick={this.handleCreate}
            dataTip={this.getErrors()}
            dataFor="orbi-tooltip-error"
            onMouseLeave={() => this.handleLeave()}
          />

          <ReactTooltip
            id={"orbi-tooltip-error"}
            place="top"
            type="light"
            effect="solid"
            html={true}
            event="mouseover"
            eventOff="mouseleave"
          />

        </div>

        <ErrorDialog
          open={this.state.showError}
          message={this.state.msgError}
          acceptText="OK"
          acceptAction={this.actionCloseDialog}
          closeAction={this.actionCloseDialog}
        />

        <ConfirmDialog
          closeAction={this.closeImageEditModal}
          open={this.state.editImage}
          title={'Edita tu imagen'}
          message={'Edita tu imagen'}
          acceptText={'Guardar'}
          acceptAction={this.AcceptImageEdit}
          showCancel={true}
          cancelText={'Cancelar'}
          class='no-bottom-margin'
          contentObject={
            <ReactCrop
              src={this.state.imageToCrop}
              crop={this.state.crop}
              ruleOfThirds
              onImageLoaded={this.onImageLoaded}
              onComplete={this.onCropComplete}
              onChange={this.onCropChange}
              crossorigin='anonymous'
              style={{ maxHeight: '340px', overflow: 'auto', margin: '10px' }}
            />
          }
        />

      </div>

    );

  }


  closeImageEditModal = () => {
    this.setState({ editImage: false })
  }
  AcceptImageEdit = () => {
    this.setState({
      profileImage: this.state.croppedImageUrl,
      editImage: false
    });
  }
  onImageLoaded = (image) => {
    this.imageRef = image;
  }
  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  }
  onCropChange = (crop, percentCrop) => {
    this.setState({ crop });
  }

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        'newFile.jpeg'
      );
      this.setState({ croppedImageUrl });
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          return;
        }
        blob.name = fileName;
        var f = new File([blob], 'image.jpeg');
        resolve(f);
      }, 'image/jpeg');
    });
  }

}

const mapStateToProps = state => {

  const unitsCatalog = getEntityItems(state, "COMPANIES.CATALOGS.UNITS") || [];
  const tagsFilterData = state?.productsReducer?.filter?.data?.tags || [];
  const skuFilterData = state?.productsReducer?.filter?.data?.skus || [];
  const isFetchingFilters = state?.productsReducer?.filter?.isFetching || false;

  const tagFilter = tagsFilterData.map( (item) => {
    const { id, title, color } = item;
    return {id, name: title, color, title, isFull: false};
  });

  const skuFilter = skuFilterData.map( (item) => {
    const { id, sku } = item;
    return {id, description: sku};
  });

  const isCreatingProduct = state?.productsReducer?.product?.isFetching || false;
  const isCreatingimageProduct = state?.productsReducer?.productImage?.isFetching || false;
  const isSatKeysLoading = state.api["COMPANIES.SAT.CATALOGS.CLAVEPRODUCTOBUSQUEDA"]?.status?.isFetching || false;

  const unitsCatalogType1 = unitsCatalog.filter( (item) => {  
    const { code = '' } = item;
    return `${code}` === 'kg' || `${code}` === 'ton';
  }).sort((a, b) => a.description.localeCompare(b.description));

  const unitsCatalogType2 = unitsCatalog.filter( (item) => {  
    const { type = '' } = item;
    return `${type}` === '2';
  }).sort((a, b) => a.description.localeCompare(b.description));

  const unitsCatalogType3 = unitsCatalog.filter( (item) => {  
    const { type = '' } = item;
    return `${type}` === '3';
  }).sort((a, b) => a.description.localeCompare(b.description));

  
  const product_keys = getEntityItems(state, "COMPANIES.SAT.CATALOGS.CLAVEPRODUCTOBUSQUEDA").map(i => ({id: i.id, description: `${i.clave} - ${i.descripcion}`, material_peligroso: i.material_peligroso}));
  // const product_keys = [];
  const packing_types = getEntityItems(state, "COMPANIES.SAT.CATALOGS.TIPOEMBALAJE").map(i => ({id: i.id, description: i.clave.concat(' - ', i.descripcion)}));
  const danger_codes = getEntityItems(state, "COMPANIES.SAT.CATALOGS.CLAVEPELIGROSO").map(i => ({id: i.id, description: i.clave.concat(' - ', i.descripcion)}));

  if (danger_codes && danger_codes.length && danger_codes.length > 0) {
    danger_codes.unshift({id: 0, description: ''});
  }

  if (packing_types && packing_types.length && packing_types.length > 0) {
    packing_types.unshift({id: 0, description: ''});
  }

  const lengthUnits = getEntityItems(state, "COMPANIES.SAT.CATALOGS.CLAVEUNIDAD").filter(u => u.simbolo === 'cm' || u.simbolo === 'in').map(u => ({id: u.id, description: u.nombre, code: u.simbolo}));
  const currencyCatalog = getEntityItems(state, "COMPANIES.SAT.CFDI.MONEDAS").map(c => ({id: c.id, description: `${c.clave} ${c.descripcion}`}));
  const skuUnits = getEntityItems(state, "COMPANIES.SAT.CATALOGS.CLAVEUNIDAD").map(u => ({id: u.id, description: `${u.clave} - ${u.nombre}`, code: u.simbolo}));
  const generalUnits = getEntityItems(state, "COMPANIES.SAT.CATALOGS.CLAVEUNIDADPESO").filter(u => u.clave === 'TNE' || u.clave === 'KGM').map(u => ({id: u.id, description: `${u.clave} - ${u.nombre}`, code: u.simbolo}));

  return {  
    isFetchingFilters,
    companyId: state.globalCatalog.session.company.id,
    companyCountry: state.globalCatalog.session.company.country__description,
    unitsCatalogType1: [{id: 0}, ...unitsCatalogType1], 
    unitsCatalogType2: [{id: 0}, ...unitsCatalogType2], 
    unitsCatalogType3: [{id: 0}, ...unitsCatalogType3], 
    tagFilter, skuFilter,
    isLoading: isCreatingProduct || isCreatingimageProduct,
    refreshToken: state.globalCatalog.session && state.globalCatalog.session.refresh,
    product_keys,
    packing_types,
    danger_codes,
    lengthUnits,
    currencyCatalog,
    isSatKeysLoading,
    skuUnits,
    generalUnits
  };
}

const mapDispatchToProps = dispatch => {
  dispatch(loadSession());
  // dispatch(loadProductsCatalogs());
  dispatch(loadProductsFilters());

  return {
    loadCurrencyCatalog: (params, opt) =>{
      dispatch(
        readEntities("COMPANIES.SAT.CFDI.MONEDAS", params, {prefix: 'orbisat.', ...opt}) 
      );
    },
    loadSATUnitCatalog: (params, opt) =>{
      dispatch(
        readEntities("COMPANIES.SAT.CATALOGS.CLAVEUNIDAD", {}, {prefix: 'orbisat.', ...opt}) 
      );
    },
    loadSATUnitWeightCatalog: (params, opt) =>{
      dispatch(
        readEntities("COMPANIES.SAT.CATALOGS.CLAVEUNIDADPESO", {}, {prefix: 'orbisat.', ...opt}) 
      );
    },
    loadUnitCatalog: (params, opt) =>{
      dispatch(
        readEntities("COMPANIES.CATALOGS.UNITS", params, opt) 
      );
    },
    loadProductKeys: (params) =>{
      dispatch(
        readEntities("COMPANIES.SAT.CATALOGS.CLAVEPRODUCTOBUSQUEDA", params, {prefix: 'orbisat.'}) 
      );
    },
    loadPackingTypes: () =>{
      dispatch(
        readEntities("COMPANIES.SAT.CATALOGS.TIPOEMBALAJE", {}, {prefix: 'orbisat.'}) 
      );
    },
    loadDangerCodes: () =>{
      dispatch(
        readEntities("COMPANIES.SAT.CATALOGS.CLAVEPELIGROSO", {}, {prefix: 'orbisat.'}) 
      );
    },
    refreshSession: (token) => dispatch(refreshSession(token)),
    createNewProduct: (data) => dispatch(createNewProduct(data)),
    showToast: (text) => dispatch(addToast(text)),
    getProduct: (productId) => dispatch(getProduct(productId)),
    updateProduct: (productId, form) => dispatch(updateProduct(productId, form)),
    updateProductData: (productId, data) => dispatch(updateProductData(productId, data)),
    cancelMultipleRequests: () => dispatch(cancelMultipleRequests()),
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RegisterNewProductView))