import * as React from "react";
import { getUrlsEnv } from "../../shared/backend-api";

class ProductRow extends React.Component {
  state = {}

  static defaultProps = {
    productInfo: {},
    removeProduct: () => {},
    unitCatalog: []
  }

  render() {
    return (
      <div className={"product-row ".concat(this.props.className)}>
        <div className="avatar" style={{width: "30%"}}>
          {
            !this.props.emptyImage &&
            <img 
              alt="" 
              src={this.props.productInfo?.product?.url ? getUrlsEnv().files.concat(this.props.productInfo?.product?.url || '') : "/images/interrogacion.svg"}
              className={this.props.productInfo?.product?.url ? "" : "default"}
            />
          }
          <div>
            {this.props.productInfo?.product?.description}
          </div>
        </div>
        <div style={{width: "19%"}}>
          {this.props.productInfo?.product?.sku}
        </div>
        <div style={{width: "10%"}}>
          {this.props.qty}
        </div>
        <div style={{width: "9%"}}>
          {(this.props.productInfo?.data?.sat_product_unit?.clave || '').toLowerCase()}
        {/* {(this.props.unitCatalog.find(u => u.id === this.props.productInfo?.product?.product_unit) || {description: ""}).description} */}
        </div>
        <div style={{width: "10%"}}>
        {(parseFloat(this.props.productInfo?.product?.gross_weight || 0) * ((this.props.productInfo?.product?.gross_weight_unit || 0) === 98 ? 1000 : 1) * parseFloat(this.props.qty || 1)).toFixed(2) } {" kg"} {/*(this.props.unitCatalog.find(u => u.id === this.props.productInfo?.product?.net_weight_unit) || {code: ""}).code*/}
        </div>
        <div style={{width: "10%"}}>
        {(parseFloat(this.props.productInfo?.product?.net_weight|| 0) * ((this.props.productInfo?.product?.net_weight_unit || 0) === 98 ? 1000 : 1) * parseFloat(this.props.qty || 1)).toFixed(2)} {" kg"} {/*(this.props.unitCatalog.find(u => u.id === this.props.productInfo?.product?.gross_weight_unit) || {code: ""}).code*/}
        </div>
        <div style={{width: "10%"}}>
        {(parseFloat(this.props.productInfo?.product?.volume || 0) * parseFloat(this.props.qty || 1)).toFixed(2)} {" mtq"} {/*(this.props.unitCatalog.find(u => u.id === this.props.productInfo?.product?.volume_unit) || {code: ""}).code*/}
        </div>
        <div style={{width: "2%"}}>
          {
            !this.props.emptyActions && 
            <img
              alt=""
              src="/images/bin.png"
              style={{ cursor: "pointer"}}
              onClick={() => {this.props.removeProduct(this.props.productInfo.id)}}
            />
          }
        </div>
      </div>
    )
  }
}

export default ProductRow;