import React from "react";
import Button from "../../shared/controls/button";
import PrevSwitch from "../../shared/controls/prev-switch";
import SelectInputStyled from "../../shared/controls/select-input-styled";
import styled from "styled-components";
import NotificationConfigurationView from "./notification-configuration-view";
// import TextInput from "../../shared/controls/text-input";
// import AlertLevel from "./alert-level-config";
// import PrevSwitch from "../controls/prev-switch";

class CompanyNotificationRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: true
    };
  }

  manageCollapse = e => {
    this.setState({ collapsed: !this.state.collapsed });
  };

  getGroupItem = (obj) => {
    if (this.state.collapsed) { return('') }

    let takenFacilities = (this.props.takenFacilities || []).filter(f => !(obj.facilities || []).includes(f));
    let takenUsers = (this.props.takenUsers || []).filter(f => !(obj.users || []).includes(f));

    return(
      <React.Fragment key={`group-${obj.id}`}>
      <div
        className={"notification-content".concat(
          this.state.collapsed ? " collapsed" : "",
          obj.edit ? "" : " blocked"
        )}
      >
        {
          this.props.updateActivated &&
          <div
            className="edit-btn"
            onClick={(e) => this.props.onNotificationDataChange(e, "edit", {id: this.props.notification.id, group: obj.id})}
          >
            <img alt="" src="/images/edit-transparent.svg" />
          </div>
        }

        <div className="notification-users">
          <div style={{width: this.props.isLt ? '0%' : '100%', minWidth: this.props.isLt ? '0%' :'100%', marginRight: 20, visibility: this.props.isLt ? 'hidden' : 'visible'}}>
            <SelectInputStyled
              selectedItems={ obj.facilities }
              items={
                this.props.facilities.map(item => (
                    {
                      ...item, 
                      items: (item.items || []).filter(f => !takenFacilities.includes(`${f.id}`))
                    }
                  ))
              }//quitar las instalaciones ya seleccionadas en otros grupos
              onChange={(e) => this.props.onNotificationDataChange(e, "facility", {id: this.props.notification.id, group: obj.id})}
              className={""}
              name={"selectedFacility"}
              label={"Agrega al menos una ".concat(this.props.companyType === 1 ? 'base' : 'instalación')}
              multiple={true}
              disabled={!obj.edit}
            />
          </div>
          <div style={{width: '100%', minWidth: '100%'}}>
            <SelectInputStyled
              selectedItems={obj.users.map(u => u.toString())}
              items={
                this.props.users.map(item => (
                  {
                    ...item, 
                    items: (item.items || []).filter(f => !takenUsers.includes(`${f.id}`))
                  }
                ))
              }
              onChange={(e) => this.props.onNotificationDataChange(e, "user", {id: this.props.notification.id, group: obj.id})}
              className={""}
              name={"selectedUsers"}
              label={"Selecciona los usuarios que recibirán esta notificación"}
              multiple={true}
              disabled={!obj.edit}
            />
          </div>
        </div>
      </div>

      {/* BOTONES DE EDICION */}
      
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          borderBottom: '1px solid #fff3',
          paddingBottom: 10
        }}
      >
        {
          obj.edit &&
          <>
            {
              this.props.updateActivated &&
              <Button 
                text={"Cancelar"}
                type={"primary outline"}
                className={"cancel-changes-button"}
                disabled={this.props.isLoading}
                onClick={(e) => {
                  this.props.onNotificationDataChange(e, "cancel", {id: this.props.notification.id, group: obj.id})
                }}
              />
            }
            {
              this.props.deleteActivated &&
              <Button 
                text={"Eliminar"}
                type={"secondary"}
                className={"delete-changes-button"}
                disabled={this.props.isLoading}
                onClick={(e) => {
                  this.props.onNotificationDataChange(e, "delete", {id: this.props.notification.id, group: obj.id})
                }}
              />
            }
            <div style={{width: 10}}/>
            {
              this.props.updateActivated &&
              <Button
                text={"Guardar"}
                type={"primary"}
                className={"save-changes-button"}
                disabled={this.props.isLoading}
                onClick={(e) => {
                  this.props.onNotificationDataChange(e, "save", {id: this.props.notification.id, group: obj.id})
                }}
              />
            }
          </>
        }
      </div>
      </React.Fragment>
    );
  }

  buildContent = () => {

    let users_facilities_list = [
      'Perfil de empresa',
      'Perfil personal',
      'Catálogo de usuarios',
      'Catálogo de productos',
      'Catálogo de instalaciones',
      'Catálogo de flotilla dueño de carga',
      'Catálogo de flotilla transportista',
      'Catálogo de Red',
      'Control de pedidos',
      'Control de ordenes',
      'Control de embarques'
    ];

    if (users_facilities_list.includes(this.props.notification.description || '')) {

      return( this.state.collapsed ? '' : 
        <NotificationConfigurationView
          key={`company-notification-configuration-${this.props.notification.id}`}
          notification={this.props.notification}
          usersToDelete={this.props.usersToDelete}
          facilitiesToDelete={this.props.facilitiesToDelete}
          handleChange={(e) => {
            if (this.props.handleChange) this.props.handleChange(e);
          }}
        />
      );
      
    } else {
      return(
        <React.Fragment key={`company-notification-content-${this.props.notification.id}`}>
          { this.props.notification.groups.map( item => this.getGroupItem(item)) }

          {
            this.state.collapsed ? '' : 
            (this.props.isLoading || this.props.notification.groups.some(g => g.edit) || !this.props.updateActivated) ? 
            <AddButtonBlocked>+</AddButtonBlocked> 
            : 
            <AddButton
              onClick={ (e) =>  this.props.onNotificationDataChange(e, "new_group", {id: this.props.notification.id})}
            >+</AddButton>
          }
        </React.Fragment>
      );
    }

  }

  render() {
    return (
      <div className={"alert-row ".concat(this.props.className)} >
        <div className="alert-title">
          {this.props.notification.description}
          <div>
            <PrevSwitch
              disabled={!this.props.updateActivated}
              name="active"
              checked={this.props.notification.is_active}
              handleClick={e => this.props.onNotificationDataChange(e, "active", {id: this.props.notification.id})}
            />
            <div
              className={"collapser".concat(
                this.state.collapsed ? " collapsed" : ""
              )}
              onClick={this.manageCollapse}
            >
              <img alt="" src="/images/select-arrow.svg" />
            </div>
          </div>
          
        </div>

        { this.buildContent() }

      </div>
    );
  }
}

export default CompanyNotificationRow;

const AddButton = styled.div`
  color: #fff;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: linear-gradient(139.24deg, #2F4FF1 2.88%, #4184F9 96.5%);
  font-size: 35px;
  line-height: 44px;
  text-align: center;
  cursor: pointer;
  margin-bottom: 10px;
  margin-top: 10px;
`;

const AddButtonBlocked = styled.div`
  color: #fff;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: linear-gradient(139.24deg, #2F4FF1 2.88%, #4184F9 96.5%);
  font-size: 35px;
  line-height: 44px;
  text-align: center;
  cursor: pointer;
  margin-bottom: 10px;
  opacity: .7;
  cursor: not-allowed;
  margin-top: 10px;
`;