import { BACKEND, getApiUrl, postMultipartApiUrl, putMultipartApiUrl } from '../../shared/backend-api';
import store from "../store";

export const REQUEST_DATA = 'REQUEST_DATA';
export const ERROR_DATA = 'ERROR_DATA';

export const RECEIVE_COUNTRIES_ = 'RECEIVE_COUNTRIES_';
export const RECEIVE_STATES_ = 'RECEIVE_STATES_';
export const RECEIVE_CITIES_ = 'RECEIVE_CITIES_';
export const RECEIVE_TYPES = 'RECEIVE_TYPES';

export const REGISTER_COMPANY = 'REGISTER_COMPANY';
export const ERROR_REGISTER_COMPANY = 'ERROR_REGISTER_COMPANY';
export const RECEIVE_REGISTER_COMPANY = 'RECEIVE_REGISTER_COMPANY';

export const DATA_COMPANY = 'DATA_COMPANY';
export const ERROR_DATA_COMPANY = 'ERROR_DATA_COMPANY';
export const RECEIVE_DATA_COMPANY = 'RECEIVE_DATA_COMPANY';

export const EDIT_COMPANY_REQUEST = 'EDIT_COMPANY_REQUEST';
export const EDIT_COMPANY_ERROR = 'EDIT_COMPANY_ERROR';
export const EDIT_COMPANY_RESPONSE = 'EDIT_COMPANY_RESPONSE';

export const VALIDATE_ORBI_ID_REQUEST = 'VALIDATE_ORBI_ID_REQUEST';
export const VALIDATE_ORBI_ID_ERROR = 'VALIDATE_ORBI_ID_ERROR';
export const VALIDATE_ORBI_ID_RESPONSE = 'VALIDATE_ORBI_ID_RESPONSE';

export const VALIDATE_DOMAIN_REQUEST = 'VALIDATE_DOMAIN_REQUEST';
export const VALIDATE_DOMAIN_ERROR = 'VALIDATE_DOMAIN_ERROR';
export const VALIDATE_DOMAIN_RESPONSE = 'VALIDATE_DOMAIN_RESPONSE';

export const VALIDATE_COMPANY_REQUEST = 'VALIDATE_COMPANY_REQUEST';
export const VALIDATE_COMPANY_ERROR = 'VALIDATE_COMPANY_ERROR';
export const VALIDATE_COMPANY_RESPONSE = 'VALIDATE_COMPANY_RESPONSE';

const receiveCountries = json => ({
  type: RECEIVE_COUNTRIES_,
  data: json
})

const receiveStates = json => ({
  type: RECEIVE_STATES_,
  data: json
})

const receiveCities = json => ({
  type: RECEIVE_CITIES_,
  data: json
})

const receiveTypes = json => ({
  type: RECEIVE_TYPES,
  data: json
})

const requestData = () => ({
  type: REQUEST_DATA
});

const errorData = error => ({
  type: ERROR_DATA,
  error: error
})

const receiveRegisterCompany = json => ({
  type: RECEIVE_REGISTER_COMPANY,
  data: json
})

const registerCompany = params => ({
  type: REGISTER_COMPANY,
  params: params
});

const errorRegisterCompany = error => ({
  type: ERROR_REGISTER_COMPANY,
  error: error
})

const companyData = params => ({
  type: DATA_COMPANY,
  params: params
});

const errorCompanyData = params => ({
  type: ERROR_DATA_COMPANY,
  params: params
});

const receiveCompanyData = params => ({
  type: RECEIVE_DATA_COMPANY,
  params: params
});

const editCompanyRequest = () => ({
  type: EDIT_COMPANY_REQUEST
});

const editCompanyError = error => ({
  type: EDIT_COMPANY_ERROR,
  error
});

const editCompanyResponse = response => ({
  type: EDIT_COMPANY_RESPONSE,
  editCompany: response
});

const validateOrbiIdRequest = () => ({
  type: VALIDATE_ORBI_ID_REQUEST
});

const validateOrbiIdError = error => ({
  type: VALIDATE_ORBI_ID_ERROR,
  error
});

const validateOrbiIdResponse = response => ({
  type: VALIDATE_ORBI_ID_RESPONSE,
  editCompany: response
});

const validateDomainRequest = () => ({
  type: VALIDATE_DOMAIN_REQUEST
});

const validateDomainError = error => ({
  type: VALIDATE_DOMAIN_ERROR,
  error
});

const validateDomainResponse = response => ({
  type: VALIDATE_DOMAIN_RESPONSE,
  editCompany: response
});

const validateCompanyRequest = () => ({
  type: VALIDATE_COMPANY_REQUEST
});

const validateCompanyError = error => ({
  type: VALIDATE_COMPANY_ERROR,
  error
});

const validateCompanyResponse = response => ({
  type: VALIDATE_COMPANY_RESPONSE,
  company: response
});

export const initialState = {
  isFetching: false,
  countries: [],
  states: [],
  cities: [],
  types: [],
  error: '',
  editCompany: [],
  company: null
};

export default function (state = initialState, action) {

  switch (action.type) {
    case REQUEST_DATA:

      return {
        ...state,
        isFetching: true
      };

    case ERROR_DATA:

      return {
        ...state,
        isFetching: false,
        error: action.error
      };

    case RECEIVE_COUNTRIES_:

      return {
        ...state,
        isFetching: false,
        countries: action.data.results
      };

    case RECEIVE_STATES_:

      return {
        ...state,
        isFetching: false,
        states: action.data.results
      };

    case RECEIVE_CITIES_:

      return {
        ...state,
        isFetching: false,
        cities: action.data.results
      };

    case RECEIVE_TYPES:

      return {
        ...state,
        isFetching: false,
        types: action.data
      };

    case EDIT_COMPANY_REQUEST:

      return {
        ...state,
        isFetching: true
      };

    case EDIT_COMPANY_ERROR:

      return {
        ...state,
        isFetching: false,
        error: action.error
      };

    case EDIT_COMPANY_RESPONSE:

      return {
        ...state,
        isFetching: false,
        editCompany: action.data
      };

    default: return state;
  }

}

const getEndPoint = (action, param) => {
  switch (action) {
    case RECEIVE_STATES_:
      return BACKEND.PUBLIC.STATE + `?country=${param}`
    case RECEIVE_CITIES_:
      return BACKEND.PUBLIC.CITY + `?state=${param}`
    case RECEIVE_TYPES:
      return BACKEND.PUBLIC.COMPANY_TYPE
    default:
      return BACKEND.PUBLIC.COUNTRY
  }
}

export function loadData(action, param) {
  return function (dispatch) {
    dispatch(requestData())
    return getApiUrl(getEndPoint(action, param))
      .then(
        response => response.json(),
        error => dispatch(errorData(error))
      )
      .then(data => {
        switch (action) {
          case RECEIVE_STATES_:
            return dispatch(receiveStates(data))
          case RECEIVE_CITIES_:
            return dispatch(receiveCities(data))
          case RECEIVE_TYPES:
            return dispatch(receiveTypes(data))
          default:
            return dispatch(receiveCountries(data))
        }
      }
      )
  }
}

export function postRegisterCompany(params) {

  return function (dispatch) {
    dispatch(registerCompany(params))
    let formData = new FormData();
    formData.append('address', params.address)
    formData.append('city', params.city)
    formData.append('corporation_name', params.corporation_name)
    formData.append('country', params.country)
    formData.append('orbi_id', params.orbi_id)
    formData.append('phone', params.phone)
    formData.append('state', params.state)
    formData.append('type', params.type_company)
    formData.append('web_page', params.website)
    formData.append('postalcode', params.zipcode)
    if (params.profile_image && !String(params.profile_image).includes("http")) {
      formData.append('logo', params.profile_image)
    }
    return postMultipartApiUrl(BACKEND.PUBLIC.CREATE_COMPANY, formData)
      .then(response => response.json())
      .then(response => dispatch(receiveRegisterCompany(response)))
      .catch(error => error.then(e => dispatch(errorRegisterCompany(e))))
  }
}

export function postValidateOrbiId(orbi_id) {
  return function (dispatch) {
    dispatch(validateOrbiIdRequest())
    return getApiUrl(BACKEND.COMPANY.VALIDATE_ORBI_ID, { orbi_id })
      .then(response => response.json())
      .then(response => dispatch(validateOrbiIdResponse(response)))
      .catch(error => error.then(e => dispatch(validateOrbiIdError(e))))
  }
}

export function postValidateDomain(email) {
  return function (dispatch) {
    dispatch(validateDomainRequest())
    return getApiUrl(BACKEND.COMPANY.VALIDATE_DOMAIN, email)
      .then(response => response.json())
      .then(response => dispatch(validateDomainResponse(response)))
      .catch(error => error.then(e => dispatch(validateDomainError(e))))
  }
}

export function postValidateCompany(search_value, action) {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function (dispatch) {
    dispatch(validateCompanyRequest())
    return getApiUrl(BACKEND.COMPANY.VALIDATE_COMPANY, {company_id, search_value, action}) 
      .then(response => response.json())
      .then(response => dispatch(validateCompanyResponse(response)))
      .catch(error => error.then(e => dispatch(validateCompanyError(e))))
  }
}

export function getCompanyData(orbi_id) {
  return function (dispatch) {
    dispatch(companyData())
    return getApiUrl(BACKEND.PUBLIC.GET_COMPANY, { id_orbi: orbi_id.orbi_id })
      .then(
        response => response.json(),
        error => dispatch(errorCompanyData(error))
      )
      .then(
        data => dispatch(receiveCompanyData(data))
      )
  }
}

export function putCompany(params) {
  return function (dispatch) {
    dispatch(editCompanyRequest(params))
    let formData = new FormData();
    formData.append('address', params.address)
    formData.append('city', params.city)
    formData.append('corporation_name', params.corporation_name)
    formData.append('country', params.country)
    formData.append('orbi_id', params.orbi_id)
    formData.append('phone', params.phone)
    formData.append('state', params.state)
    formData.append('type', params.type)
    formData.append('web_page', params.web_page)
    formData.append('postalcode', params.postalcode)
    formData.append('allow_request', params.allow_request)
    formData.append('visible', params.visible)
    formData.append('email', params.email)
    if (params.logo && !String(params.logo).includes("http")) {
      formData.append('logo', params.logo)
    }
    return putMultipartApiUrl(BACKEND.PUBLIC.PUT_COMPANY + params.orbi_id + '/', formData)
      .then(response => response.json())
      .then(response => dispatch(editCompanyResponse(response)))
      .catch(error => error.then(e => dispatch(editCompanyError(e))))
  }
}