import React from "react";
import CatalogHeader, {
  GRID,
  LIST,
} from "../../shared/component/catalog-header";
import FilterBar from "../../shared/component/filter/filter-bar";
import Selector from "../../shared/component/selector";
import CatalogCard from "../../shared/component/catalog-card";
import { connect } from "react-redux";
import {
  loadSession,
  loadAccountStatusCatalog,
  validateObjPerms,
} from "../../redux/reducers/global-catalog.reducer";
import { loadTags } from "../../redux/reducers/tags.reducer";
import {
  getUsers,
  getUserTypes,
  filterUsersByText,
} from "../../redux/reducers/user-reducer";
import CatalogSelectionFooter from "../../shared/component/catalog-selection-footer";
import ExceedPlus from "../../shared/controls/exceed-plus";
import Tag from "../../shared/controls/tag";
import { getUrlsEnv } from "../../shared/backend-api";
import Button from "../../shared/controls/button";
import CatalogRow from "../../shared/component/catalog-row";
import { listFacilities } from "../../redux/reducers/facility-reducer";
import { normalizeStr } from "../../shared/utils";
import {
  acceptInvitation,
  cancelInvitation,
  resendInvitation,
  createInvitation,
  loadCompanyUsers,
  deleteCompanyUsers,
  listCompanyRoles,
} from "../../redux/reducers/company-reducer";
import ConfirmDialog from "../../shared/dialog/confirm-dialog";
import LoaderDialog from "../../shared/dialog/loader-dialog";
import InviteUsersModal from "../../shared/dialog/invite-users-modal";
import UserChipContainer from "../../shared/component/user-chip-container";
import TextExceedPlus from "../../shared/controls/text-exceed-plus";
import ValidatePerms from "../../shared/component/validate-perms";
import { FILTER_LIST_TYPES } from "../../shared/component/filter/filter-bar-content";
import { setConfig } from "../../redux/reducers/configuration-reducer";
import PaginatorControlled from "../../shared/controls/paginator-controlled";
import { Trash } from "../../shared/icons/traffic";
import { removeNavbarAction, setNavbarAction } from "../../redux/reducers/navbar-reducer";
import HistoricalIcon from "../../shared/icons/historical-icon";
import HelperFilesModal from "../../shared/component/helper_files_modal";
import { getEntityItems, readEntities } from "../../redux/api";

const defaultPaginatorItems = 10;

class UserCatalog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      viewMode: LIST,
      limit: 10,
      offset: 1,
      showInviteUsersModal: false,
      showEditUserTypeModal: false,
      showEditFacilitiesModal: false,
      showEditRolesModal: false,
      showDeleteUsersModal: false,
      showResendInvitationModal: false,
      showAcceptInvitationModal: false,
      showCancelInvitationModal: false,
      showRejectInvitationModal: false,
      showResultModal: false,

      resetPaginator: false,

      invitationsComplete: false,

      resultModalText: "",
      resultModalTitle: "",

      selectionModeOn: false,
      selectedUsersIds: [],
      selectedInvitationsIds: [],

      userTypes: [],
      connectionStatus: [],
      facilities: [],
      tags: [],
      permissions: [],
      accountStatus: [],
      searchStr: "",
      selected_invitation_id: 0,

      buttonFilterName: "Aplicar",
    };

    this.openedLink = false;
  }

  componentDidMount() {
    this.setState({ ...this.props.configuration });

    this.props.setNavbarAction("users-catalog", [
      {
        icon: <HelperFilesModal
          files={[]}
        />
      },
      {
        icon: <HistoricalIcon stroke="rgb(176, 201, 253)"/>,
        onClick: () => {
          this.props.history.push(`/company/user/historical`);
        },
      }
    ]);

    this.props.getUser({
      company_id: this.props.companyId,
      user_id: this.props.userId
    });

  }

  componentWillUnmount() {
    this.props.removeNavbarAction("users-catalog");
    let config = this.state;

    if (!this.openedLink) {
      config.searchStr = '';
    }

    this.props.setConfiguration(config);
    this.openedLink = false;
  }

  loadUsers = () => {
    this.props.reloadUsers(
      this.state.limit,
      this.state.offset,
      this.state.userTypes,
      this.state.facilities,
      this.state.tags,
      this.state.accountStatus,
      this.state.searchStr
    );
  };

  headerActionButtonSettings = {
    text: "Invitar",
    prefix: {
      element: (
        <img className="user-icon" src="/images/user-white.svg" alt=""></img>
      ),
    },
    onClick: () => {
      if ([4,2].includes(this.props.userInfo?.type?.id || 0)) return;
      this.setState({ showInviteUsersModal: true });
    },
  };

  toggleViewMode = (viewMode) => {
    this.setState({ viewMode: viewMode });
  };

  redirectToProfile = (e) => {
    // if(e.target.value.status_id !== 5 && ((e.target.value.status_id !== 1 || e.target.value.status_id !== 3) ))
    // debugger
    if (
      e.target.value.status_id !== 5 &&
      ((e.target.value.status_id !== 1 && e.target.value.status_id !== 3) ||
        e.target.value.invitation_type === 1)
    )
      this.props.history.push(`/user/profile/${e.target.name}`);
  };

  //SELECTOR
  toggleSelectMode = () => {
    this.setState({
      selectionModeOn: !this.state.selectionModeOn,
      selectedUsersIds: [],
      selectedInvitationsIds: []
    });
  };

  isOwnerUser = (id) => {
    var isOwner = false;

    for (var i = 0; i < this.props.users.length; i++) {
      let userType = `${this.props.users[i].user_type}`;
      let userId = `${this.props.users[i].user_id}`;

      if ((userType === '5' || userType === '6') && userId === `${id}`) {
        isOwner = true;
      }
    }

    return isOwner;
  }

  handleSelectUser = (e) => {
    // debugger
    if (!e.target.name.toString().includes("invitation")) {

      if (!this.isOwnerUser(e.target.name)) {

        let selectedUsersIds = [...this.state.selectedUsersIds];
        if (e.target.value) {
          selectedUsersIds.push(e.target.name);
        } else {
          selectedUsersIds.splice(
            selectedUsersIds.findIndex((f) => f === e.target.name),
            1
          );
        }
        this.setState({ selectedUsersIds: selectedUsersIds });

      }

    } else if (e.target.name.toString().includes("invitation")) {

      let selectedInvitationsIds = [...this.state.selectedInvitationsIds];
      if (e.target.value) {

        selectedInvitationsIds.push(e.target.name);
      } else {
        selectedInvitationsIds.splice(
          selectedInvitationsIds.findIndex((f) => f === e.target.name),
          1
        );
      }
      this.setState({ selectedInvitationsIds: selectedInvitationsIds });

    }
  };

  selectAllUsers = () => {
    if (
      this.props.users.length === (this.state.selectedUsersIds.length + this.state.selectedInvitationsIds.length)
    ) {
      this.setState({ selectedUsersIds: [], selectedInvitationsIds: [] });
    } else {
      let selectedUsersIds = [];
      let selectedInvitationsIds = [];

      this.props.users
        .filter((u) => u.status_id === 2 || u.status_id === 4)
        .map((user) => {

          if (!this.isOwnerUser(user.user_id)) {
            selectedUsersIds.push(user.user_id);
          }
          return null;
        });

      this.props.users
        .filter((u) => (u.status_id !== 2 && u.status_id !== 4))
        .map((user) => {
          selectedInvitationsIds.push("invitation-".concat(user.invitation_id));
          return null;
        });
      
      this.setState({ selectedUsersIds, selectedInvitationsIds });
    }
  };

  //SHOW/HIDE EACH MODAL
  showInviteUsersModal = (show) => {
    this.setState({ showInviteUsersModal: show, invitationsComplete: false });
  };

  showEditUserTypeModal = (show) => {
    this.setState({ showEditUserTypeModal: show });
  };

  showEditFacilitiesModal = (show) => {
    this.setState({ showEditFacilitiesModal: show });
  };

  showEditRolesModal = (show) => {
    this.setState({ showEditRolesModal: show });
  };

  showDeleteUsersModal = (show) => {
    this.setState({ showDeleteUsersModal: show });
  };

  showResendInvitationModal = (show) => {
    this.setState({ showResendInvitationModal: show });
  };

  showResultModal = (show) => {
    if (!show) {
      this.setState({ resetPaginator: true });
      this.loadUsers();
      // this.props.reloadUsers(defaultPaginatorItems, 1);
    }
    this.setState({ showResultModal: show, showProgressLoader: false });
  };

  showCancelInvitationModal = (show) => {
    this.setState({ showCancelInvitationModal: show });
  };

  showRejectInvitationModal = (show) => {
    this.setState({ showRejectInvitationModal: show });
  };

  showAcceptInvitationModal = (show) => {
    this.setState({ showAcceptInvitationModal: show });
  };

  //FILTERS
  handleFilterChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    this.setState({ buttonFilterName: "Aplicar" });
  };

  handleTextSearch = (name, value) => {
    this.setState({ searchStr: value });
  };

  handleFilter = (limit = defaultPaginatorItems, offset = 1) => {
    if(this.state.buttonFilterName === "Aplicar"){
      this.setState({ resetPaginator: true, limit, offset, buttonFilterName: "Restablecer" }, () => this.loadUsers() );
    } else {
      this.setState({ resetPaginator: true, limit, offset, buttonFilterName: "Aplicar" }, () => this.handlerClean() );
    }
    // this.props.reloadUsers(
    //   limit,
    //   offset,
    //   this.state.userTypes,
    //   this.state.facilities,
    //   this.state.tags,
    //   this.state.accountStatus,
    //   this.state.searchStr
    // );
  };

  handlerClean = () => {
    this.setState({
      userTypes: [],
      facilities: [],
      tags: [],
      accountStatus: []
    }, () => this.loadUsers() );
  }

  handlePaginator = (limit = defaultPaginatorItems, offset = 1) => {
    this.setState({ limit, offset }, () => this.loadUsers());

    // this.props.reloadUsers(
    //   limit,
    //   offset,
    //   this.state.userTypes,
    //   this.state.facilities,
    //   this.state.tags,
    //   this.state.accountStatus,
    //   this.state.searchStr
    // );
  };

  filterContents = () => [
    {
      title: "Tipo",
      items: this.props.userTypes,
      // items: [
      //   {id: ((this.props.userTypes || []).filter(uT => uT.description === 'Administrador' || uT.description === 'Owner').map(uT => uT.id).join() || 0), description: 'Administrador'},
      //   {id: ((this.props.userTypes || []).filter(uT => uT.description === 'Warehouser').map(uT => uT.id).join() || 1), description: 'Warehouser'}
      // ],
      name: "userTypes",
      listType: FILTER_LIST_TYPES.checkList,
    },
    // {
    //   title: 'Estatus',
    //   items: this.props.connectionStatus,
    //   name: 'connectionStatus',
    //   listType: FILTER_LIST_TYPES.checkList
    // },
    {
      title: "Instalaciones",
      items: this.props.facilitiesByTag,
      // items: this.props.facilities,
      name: "facilities",
      listType: FILTER_LIST_TYPES.checkListTextSearchAll,
    },
    {
      title: "Roles",
      items: this.props.tags,
      name: "tags",
      listType: FILTER_LIST_TYPES.chips,
    },
    // {
    //   title: 'Permisos',
    //   items: this.props.permissions,
    //   name: 'permissions',
    //   listType: FILTER_LIST_TYPES.checkListTextSearch
    // },
    {
      title: "Estatus Cuenta",
      items: this.props.accountStatusCatalog,
      name: "accountStatus",
      listType: FILTER_LIST_TYPES.checkListAll,
    },
  ];

  filterContentsLT = () => [
    {
      title: "Tipo",
      items: this.props.userTypes.filter((type) => {
        return type.description !== "Warehouser";
      }),
      // items: [
      //   {id: ((this.props.userTypes || []).filter(uT => uT.description === 'Administrador' || uT.description === 'Owner').map(uT => uT.id).join() || 0), description: 'Administrador'},
      //   {id: ((this.props.userTypes || []).filter(uT => uT.description === 'Warehouser').map(uT => uT.id).join() || 1), description: 'Warehouser'}
      // ],
      name: "userTypes",
      listType: FILTER_LIST_TYPES.checkList,
    },
    // {
    //   title: 'Estatus',
    //   items: this.props.connectionStatus,
    //   name: 'connectionStatus',
    //   listType: FILTER_LIST_TYPES.checkList
    // },
    {
      title: "Roles",
      items: this.props.tags,
      name: "tags",
      listType: FILTER_LIST_TYPES.chips,
    },
    // {
    //   title: 'Permisos',
    //   items: this.props.permissions,
    //   name: 'permissions',
    //   listType: FILTER_LIST_TYPES.checkListTextSearch
    // },
    {
      title: "Estatus Cuenta",
      items: this.props.accountStatusCatalog,
      name: "accountStatus",
      listType: FILTER_LIST_TYPES.checkListAll,
    },
  ];

  //ACCEPT/REJECT/CANCEL/SEND INVITATIONS
  handleCancelInvitation = (user) => {
    if (user.status_id === 5 || user.invitationtypeid === 2) {
      this.showCancelInvitationModal(true);
    } else {
      this.showRejectInvitationModal(true);
    }
    this.setState({ selected_invitation_id: user.invitation_id });
  };

  cancelInvitation = () => {
    this.props
      .cancelInvitation(this.state.selected_invitation_id)
      .then((r) => {
        this.setState({
          showCancelInvitationModal: false,
          showRejectInvitationModal: false,
          showResultModal: true,
          resultModalText: "La invitación ha sido cancelada",
          resultModalTitle: "¡Listo!",
        });
      })
      .catch((e) => {
        this.setState({
          showCancelInvitationModal: false,
          showRejectInvitationModal: false,
          showResultModal: true,
          resultModalText:
            "Ha ocurrido un error, intentalo de nuevo más tarde.",
          resultModalTitle: "¡Ups!",
        });
      });
  };

  rejectInvitation = () => {
    this.props
      .cancelInvitation(this.state.selected_invitation_id)
      .then((r) => {
        this.setState({
          showCancelInvitationModal: false,
          showRejectInvitationModal: false,
          showResultModal: true,
          resultModalText: "La solicitud ha sido rechazada",
          resultModalTitle: "¡Listo!",
        });
      })
      .catch((e) => {
        this.setState({
          showCancelInvitationModal: false,
          showRejectInvitationModal: false,
          showResultModal: true,
          resultModalText:
            "Ha ocurrido un error, intentalo de nuevo más tarde.",
          resultModalTitle: "¡Ups!",
        });
      });
  };

  handleAcceptInvitation = (user) => {
    if (user.status_id !== 5 && user.invitationtypeid !== 2) {
      this.showAcceptInvitationModal(true);
      this.setState({ selected_invitation_id: user.invitation_id });
    } else {
      this.props
        .resendInvitation(user.invitation_id)
        .then((r) => {
          this.setState({
            showResultModal: true,
            resultModalText: "Tu invitación ha sido reenviada",
            resultModalTitle: "¡Listo!",
          });
        })
        .catch((e) => {
          this.setState({
            showResultModal: true,
            resultModalText:
              "Ha ocurrido un error, intentalo de nuevo más tarde.",
            resultModalTitle: "¡Ups!",
          });
        });
    }
  };

  acceptInvitation = () => {
    this.props
      .acceptInvitation(this.state.selected_invitation_id)
      .then((r) => {
        this.setState({
          showAcceptInvitationModal: false,
          showResultModal: true,
          resultModalText: "La solicitud ha sido aceptada",
          resultModalTitle: "¡Listo!",
        });
      })
      .catch((e) => {
        this.setState({
          showAcceptInvitationModal: false,
          showResultModal: true,
          resultModalText:
            "Ha ocurrido un error, intentalo de nuevo más tarde.",
          resultModalTitle: "¡Ups!",
        });
      });
  };

  asyncSendInvitation = async (users, callback) => {
    for (let index = 0; index < users.length; index++) {
      await callback(users[index], index, users);
    }
  };

  handleSendInvitations = async (
    users,
    userType,
    facilities,
    roles,
    fleetTags
  ) => {
    await this.asyncSendInvitation(users, async (u) => {
      if (u.userId) {
        await this.props.inviteUser(
          u.userId,
          u.email,
          userType,
          facilities,
          roles,
          fleetTags
        );
      } else {
        await this.props.invitePerson(
          u.email,
          userType,
          facilities,
          roles,
          fleetTags
        );
      }
    });
    this.setState({ invitationsComplete: true, resetPaginator: true });
    // this.props.reloadUsers(defaultPaginatorItems, 1);
    this.loadUsers();
  };

  //RESEND INVITATION
  handleResendChipClick = (id) => {
    let selectedInvitationsIds = [...this.state.selectedInvitationsIds];
    selectedInvitationsIds.splice(
      selectedInvitationsIds.findIndex((f) => f === id),
      1
    );
    this.setState({ selectedInvitationsIds: selectedInvitationsIds });
  };
  resendInvitation = () => {
    if(this.state.selectedInvitationsIds.length > 0){
      this.setState({
        showDeleteUsersModal: false,
        showProgressLoader: true,
        showResendInvitationModal: false,
        showResultModal: false,
        selectionModeOn: false,
      });

      let proccessed = 0;

      asyncForEach(this.state.selectedInvitationsIds, async (item) => {
        let invitationId = item.replace('invitation-','');
        this.props.resendInvitation(invitationId)
          .then(response => {
            proccessed++;
            if(proccessed === this.state.selectedInvitationsIds.length - 1 || this.state.selectedInvitationsIds.length == 1){
              this.setState({
                showDeleteUsersModal: false,
                showResendInvitationModal: false,
                showProgressLoader: false,
                showResultModal: true,
                selectionModeOn: false,
                resultModalText: "Las invitaciones han sido reenviadas",
                resultModalTitle: "¡Listo!",
              });
            }
          })
          .catch(e => {
            proccessed++;
            if(proccessed === this.state.selectedInvitationsIds.length - 1 || this.state.selectedInvitationsIds.length == 1){
              this.setState({
                showDeleteUsersModal: false,
                showResendInvitationModal: false,
                showProgressLoader: false,
                showResultModal: true,
                selectionModeOn: false,
                resultModalText: "No logramos reenviar todas las invitaciones, intenta más tarde",
                resultModalTitle: "Lo sentimos!",
              });
            }
          });
      });
    } else {
      this.setState({
        showDeleteUsersModal: false,
        showResendInvitationModal: false,
        showResultModal: false,
        selectionModeOn: false,
      });
    }
  };

  //DELETE MULTIPLE USERS
  handleUserChipClick = (id) => {
    let selectedUsersIds = [...this.state.selectedUsersIds];
    selectedUsersIds.splice(
      selectedUsersIds.findIndex((f) => f === id),
      1
    );
    this.setState({ selectedUsersIds: selectedUsersIds });
  };
  deleteUsers = () => {
    let cUIds = this.props.company_users
      .filter((cU) => this.state.selectedUsersIds.includes(cU.users))
      .map((u) => u.id);
    asyncForEach(cUIds, async (id) => {
      this.props.deleteUser(id);
    });

    asyncForEach(this.state.selectedInvitationsIds, async (item) => {
      let idToCancel = item.replace('invitation-','');
      this.props.cancelInvitation(idToCancel);
    });

    this.setState({
      showDeleteUsersModal: false,
      showResultModal: true,
      selectionModeOn: false,
      resultModalText: "Los usuarios e invitaciones han sido eliminados",
      resultModalTitle: "¡Listo!",
    });
  };

  getAccountStatus = (user_status, invitation_type) => {
    switch (user_status) {
      case 1:
        if (invitation_type === 1) {
          return "No verificado, solicitud pendiente de aceptar";
        }
        return "No verificado, invitación pendiente de aceptar";
      case 3:
        if (invitation_type === 1) {
          return "Solicitud pendiente de aceptar";
        }
        return "Invitación pendiente de aceptar";
      case 5:
        return "Invitación enviada";
      default:
        return "";
    }
  };

  //GET CARDS/ROWS CONTENT
  getCard = (cardItem) => {
    return (
      <>
        {cardItem.status_id === 1 ||
        cardItem.status_id === 3 ||
        cardItem.status_id === 5 ? (
          <div className="actions">
            <div className="status">
              <span>
                {this.getAccountStatus(
                  cardItem.status_id,
                  cardItem.invitationtypeid
                )}
                {/*((this.props.accountStatusCatalog || []).find(aS => aS.id === cardItem.status_id) || {}).description*/}
              </span>
            </div>
            <div className="buttons">
              <ValidatePerms
                perms={[
                  {
                    id: "19",
                    perm: "d",
                  },
                ]}
              >
                <Button
                  text={
                    (cardItem.status_id === 1 || cardItem.status_id === 3) &&
                    cardItem.invitationtypeid === 1
                      ? "Rechazar"
                      : "Cancelar"
                  }
                  type="primary outline"
                  onClick={(e) => {
                    e.stopPropagation();
                    this.handleCancelInvitation(cardItem);
                  }}
                />
              </ValidatePerms>
              <ValidatePerms
                perms={[
                  {
                    id: "19",
                    perm: "c",
                  },
                ]}
              >
                <Button
                  text={
                    (cardItem.status_id === 1 || cardItem.status_id === 3) &&
                    cardItem.invitationtypeid === 1
                      ? "Aceptar"
                      : "Re-enviar"
                  }
                  type="primary"
                  onClick={(e) => {
                    e.stopPropagation();
                    this.handleAcceptInvitation(cardItem);
                  }}
                />
              </ValidatePerms>
            </div>
          </div>
        ) : (
          ""
        )}
        <div className="content">
          <div className="contact">
            <div
              className={"image ".concat(
                cardItem.profile_image ? "" : "default"
              )}
              style={{
                backgroundImage: "url(".concat(
                  getAvatarUrl(cardItem.profile_image, cardItem.status_id),""
                ),
              }}
            >
              <div
                className={"connection-state ".concat(cardItem.connected)}
              ></div>
            </div>
            <div className="contact-data">
              <span className="name">{cardItem.full_name}</span>
              <span className="email">{cardItem.email}</span>
            </div>
          </div>
          <div
            className={"connectivity user-status-".concat(cardItem.status_id)}
          >
            Última sesión: {cardItem.last_login}
          </div>
          <div className={"status user-status-".concat(cardItem.status_id)}>
            {cardItem.status_id === 1 || cardItem.status_id === 3
              ? this.getAccountStatus(
                  cardItem.status_id,
                  cardItem.invitationtypeid
                )
              : (
                  (this.props.accountStatusCatalog || []).find(
                    (aS) => aS.id === cardItem.status_id
                  ) || {}
                ).description}
          </div>
        </div>
        <div className="footer">
          <div className="facilities">
            <TextExceedPlus
              name={"facilities-".concat(cardItem.id)}
              tooltipClassName="user-facilities-tooltip"
            >
              {cardItem.facilities.map((f, index, arr) => {
                return (
                  <span key={index}>
                    {f.name}
                    {index + 1 === arr.length ? "" : ", "}
                  </span>
                );
              })}
            </TextExceedPlus>
          </div>
          <div className="tags">
            <ExceedPlus
              maxVisibleItems={2}
              name={
                cardItem.status_id === 5
                  ? "invitation-".concat(cardItem.invitation_id)
                  : cardItem.user_id.toString()
              }
            >
              {(cardItem.role_id || []).map((tag) => (
                <Tag
                  key={tag.id}
                  id={tag.id}
                  title={tag.description}
                  color={tag.color}
                />
              ))}
            </ExceedPlus>
          </div>
        </div>
      </>
    );
  };

  getRow = (rowItem) => {
    return (
      <div className="row-content">
        <div
          className={"contact".concat(
            ((this.props.companyType || 2).id || 2) === 2
              ? ""
              : " complete-table"
          )}
        >
          <div
            className={"image ".concat(rowItem.profile_image ? "" : "default")}
            style={{
              backgroundImage: "url(".concat(
                getAvatarUrl(rowItem.profile_image, rowItem.status_id),""),
            }}
          >
            <div
              className={"connection-state ".concat(rowItem.connected)}
            ></div>
          </div>
          <div className="contact-data">
            <span className="name">{rowItem.full_name}</span>
            <span className="email">{rowItem.email}</span>
          </div>
        </div>
        <div className={"role".concat(" user-type-", rowItem.user_type)}>
          {
            (
              (this.props.userTypes || []).find(
                (uT) => uT.id === rowItem.user_type
              ) || {}
            ).description
          }
        </div>
        {((this.props.companyType || 2).id || 2) === 2 && (
          <div className="facilities">
            <TextExceedPlus
              name={"facilities-".concat(rowItem.id)}
              tooltipClassName="user-facilities-tooltip"
            >
              { (Array.isArray(rowItem.facilities)) && rowItem.facilities.map((f, index, arr) => {
                return (
                  <span>
                    {f.name}
                    {index + 1 === arr.length ? "" : ", "}
                  </span>
                );
              })}
            </TextExceedPlus>
            {/* {rowItem.facilities.map(f => f.name).join(', ')} */}
          </div>
        )}
        <div className="tags">
          <ExceedPlus
            maxVisibleItems={2}
            name={
              rowItem.status_id === 5
                ? "invitation-".concat(rowItem.invitation_id)
                : ( rowItem.user_id ? rowItem.user_id.toString() : '')
            }
          >
            {(rowItem.role_id || []).map((tag) => (
              <Tag
                key={tag.id}
                id={tag.id}
                title={tag.description}
                color={tag.color}
              />
            ))}
          </ExceedPlus>
        </div>
        <div className="status">
          <span className={"icon user-status-".concat(rowItem.status_id)}>
            {USER_STATUS_ICON_CATALOG[rowItem.status_id]}
          </span>
          <div className="status-text">
            {/*((this.props.accountStatusCatalog || []).find(aS => aS.id === rowItem.status_id) || {}).description*/}
            {rowItem.status_id === 1 || rowItem.status_id === 3
              ? this.getAccountStatus(
                  rowItem.status_id,
                  rowItem.invitationtypeid
                )
              : (
                  (this.props.accountStatusCatalog || []).find(
                    (aS) => aS.id === rowItem.status_id
                  ) || {}
                ).description}
          </div>
        </div>
        {rowItem.status_id === 4 ? (
          <div
            className={"session".concat(rowItem.status_id === 4 ? "" : " hide")}
          >
            {rowItem.last_login}
          </div>
        ) : (
          ""
        )}
        {rowItem.status_id === 1 ||
        rowItem.status_id === 3 ||
        rowItem.status_id === 5 ? (
          <div className="actions ">
            <Button
              text={
                (rowItem.status_id === 1 || rowItem.status_id === 3) &&
                rowItem.invitationtypeid === 1
                  ? "Rechazar"
                  : "Cancelar"
              }
              type="primary outline"
              onClick={(e) => {
                e.stopPropagation();
                this.handleCancelInvitation(rowItem);
              }}
            />
            <Button
              text={
                (rowItem.status_id === 1 || rowItem.status_id === 3) &&
                rowItem.invitationtypeid === 1
                  ? "Aceptar"
                  : "Re-enviar"
              }
              type="primary"
              onClick={(e) => {
                e.stopPropagation();
                this.handleAcceptInvitation(rowItem);
              }}
            />
          </div>
        ) : (
          ""
        )}
      </div>
    );
  };

  validShow = (perm) => {
    let response = this.props.validateObjPerms([perm], {
      display: true,
    });
    if (Object.keys(response).length) {
      return true;
    }
    return false;
  };

  getSelectedFunction = (user) => {
    if (user.status_id === 5 || user.status_id === 1 || user.status_id === 3) {
      return this.state.selectedInvitationsIds.find((sU) => sU === "invitation-".concat(user.invitation_id)) ? true : false;
    } else {
      return this.state.selectedUsersIds.find((sU) => sU === user.user_id) ? true : false
    }
  }

  inviteButtonValidations = () => {
    if ([4,2].includes(this.props.userInfo?.type?.id || 0)) {
      return [];
    } else {
      return [this.headerActionButtonSettings];
    }
  }

  render() {
    return (
      <div className="catalog" onClick={this.clickOutside}>
        <ValidatePerms
          perms={[
            {
              id: "19",
              perm: "c",
            },
          ]}
          optionalElement={
            <CatalogHeader
              viewMode={this.state.viewMode}
              title={"Usuarios"}
              toggleView={this.toggleViewMode}
            />
          }
        >
          <CatalogHeader
            viewMode={this.state.viewMode}
            title={"Usuarios"}
            actionButtons={this.inviteButtonValidations()}
            toggleView={this.toggleViewMode}
          />
        </ValidatePerms>
        
        <FilterBar
          searchAction={this.handleTextSearch}
          filterAction={this.handleFilter}
          searchStr={this.state.searchStr}
          onChange={this.handleFilterChange}
          searchButtonText={this.state.buttonFilterName}
          content={
            ((this.props.companyType || 2).id || 2) === 2
              ? this.filterContents()
              : this.filterContentsLT()
          }
          selectedItems={{
            userTypes: this.state.userTypes,
            connectionStatus: this.state.connectionStatus,
            facilities: this.state.facilities,
            tags: this.state.tags,
            permissions: this.state.permissions,
            accountStatus: this.state.accountStatus,
          }}
        />
        <div className={"content ".concat(this.state.viewMode)}>
          {this.state.viewMode === GRID ? (
            ""
          ) : (
            <div className="user-row-header">
              <div className="logo"></div>
              <div
                className={"credentials".concat(
                  ((this.props.companyType || 2).id || 2) === 2
                    ? ""
                    : " complete-table"
                )}
              >
                Usuario
              </div>
              <div className="user-type">Perfil</div>
              {((this.props.companyType || 2).id || 2) === 2 && (
                <div className="facilities">Instalaciones</div>
              )}
              <div className="tags">Rol de Permisos</div>
              <div className="account-status">Estatus</div>
              <div className="last-connection">Última Sesión</div>
            </div>
          )}
          <Selector
            selectedItemClass="selected"
            selectionModeClass="selection"
            selectedItems={this.state.selectedUsersIds + this.state.selectedInvitationsIds}
            onSelect={this.handleSelectUser}
            selectionModeOn={this.state.selectionModeOn}
            toggleSelectionMode={this.toggleSelectMode}
            showSelector={this.validShow({
              id: "19",
              perm: "d",
            })}
          >
            {Array.isArray(this.props.users) &&
              this.props.users.map((user) => {
                return this.state.viewMode === GRID ? (
                  <CatalogCard
                    key={
                      user.status_id === 5 ||
                      user.status_id === 1 ||
                      user.status_id === 3
                        ? "invitation-".concat(user.invitation_id)
                        : user.user_id
                    }
                    defaultClass={"user-card ".concat(
                      "user-type-",
                      user.user_type,
                      " user-status-",
                      user.status_id
                    )}
                    id={
                      user.status_id === 5 ||
                      user.status_id === 1 ||
                      user.status_id === 3
                        ? "invitation-".concat(user.invitation_id)
                        : user.user_id
                    }
                    selected={this.getSelectedFunction(user)}
                    onClick={() => {
                      this.openedLink = true;
                      this.redirectToProfile({
                        target: {
                          name: user.user_id,
                          value: {
                            status_id: user.status_id,
                            invitation_type: user.invitationtypeid,
                          },
                        },
                      })
                    }}
                  >
                    {this.getCard(user)}
                  </CatalogCard>
                ) : (
                  <CatalogRow
                    isSelectable={!(user.user_type !== null && (`${user.user_type}` === '5' || `${user.user_type}` === '6'))}
                    key={
                      user.status_id === 5 ||
                      user.status_id === 1 ||
                      user.status_id === 3
                        ? "invitation-".concat(user.invitation_id)
                        : user.user_id
                    }
                    defaultClass={"user-row ".concat(
                      "user-type-",
                      user.user_type,
                      " user-status-",
                      user.status_id
                    )}
                    id={
                      user.status_id === 5 ||
                      user.status_id === 1 ||
                      user.status_id === 3
                        ? "invitation-".concat(user.invitation_id)
                        : user.user_id
                    }
                    selected={this.getSelectedFunction(user)}
                    onClick={() => {
                      this.openedLink = true;
                      this.redirectToProfile({
                        target: {
                          name: user.user_id,
                          value: {
                            status_id: user.status_id,
                            invitation_type: user.invitation_type,
                          },
                        },
                      })
                    }}
                  >
                    {this.getRow(user)}
                  </CatalogRow>
                );
              })}
          </Selector>
          <PaginatorControlled
            itemCount={this.props.userCount}
            onChange={(e) =>
              this.setState({ [e.target.name]: e.target.value }, () =>
                this.loadUsers()
              )
            }
            limit={this.state.limit}
            offset={this.state.offset}
            // resetPaginator={this.state.resetPaginator}
            // onReset={() => {
            //   this.setState({ resetPaginator: false });
            // }}
          />
        </div>
        <ValidatePerms
          perms={[
            {
              id: "19",
              perm: "d",
            },
          ]}
        >
          <CatalogSelectionFooter
            show={this.state.selectionModeOn}
            selectedElements={this.state.selectedUsersIds.length + this.state.selectedInvitationsIds.length}
            selectAll={this.selectAllUsers}
            actions={[
              /* {
              description: 'Editar accesos',
              f: () => this.showEditUserTypeModal(true)
            },
            {
              description: 'Editar instalaciones',
              f: () => this.showEditFacilitiesModal(true)
            },
            {
              description: 'Editar roles',
              f: () => this.showEditRolesModal(true)
            }, */
              {
                description: 'Reenviar invitaciones',
                f: () => this.showResendInvitationModal(true)
              }, 
              {
                description: "Eliminar",
                f: () => this.showDeleteUsersModal(true),
              },
            ]}
          />
        </ValidatePerms>
        <InviteUsersModal
          open={this.state.showInviteUsersModal}
          closeAction={() => this.showInviteUsersModal(false)}
          companyType={this.props.companyType.id}
          tags={this.props.tags}
          fleetTags={this.props.fleetTags}
          userTypes={this.props.userTypes}
          facilities={this.props.facilitiesByTag}
          maxFacilities={(this.props.facilities || []).length}
          invitationsComplete={this.state.invitationsComplete}
          handleInvitations={this.handleSendInvitations}
        />
        <ConfirmDialog
          open={this.state.showResultModal}
          title={this.state.resultModalTitle}
          message={this.state.resultModalText}
          acceptText="Aceptar"
          class="result-message"
          closeAction={() => this.showResultModal(false)}
          acceptAction={() => this.showResultModal(false)}
        />
        <ConfirmDialog
          open={this.state.showCancelInvitationModal}
          title="¿Cancelar la invitación?"
          message="¿Estás seguro que deseas cancelar la invitación de este usuario?"
          acceptText="Continuar"
          class="result-message"
          showCancel={true}
          cancelText="Regresar"
          cancelButtonType="secondary"
          closeAction={() => this.showCancelInvitationModal(false)}
          acceptAction={() => this.cancelInvitation()}
        />
        <ConfirmDialog
          open={this.state.showRejectInvitationModal}
          title="¿Rechazar la solicitud?"
          message="¿Estás seguro que deseas rechazar la solicitud de este usuario?"
          acceptText="Continuar"
          class="result-message"
          showCancel={true}
          cancelText="Regresar"
          cancelButtonType="secondary"
          closeAction={() => this.showRejectInvitationModal(false)}
          acceptAction={() => this.rejectInvitation()}
        />
        <ConfirmDialog
          open={this.state.showAcceptInvitationModal}
          title="¿Aceptar la solicitud?"
          message="¿Estás seguro que deseas aceptar la solicitud de este usuario?"
          acceptText="Continuar"
          class="result-message"
          showCancel={true}
          cancelText="Regresar"
          cancelButtonType="secondary"
          closeAction={() => this.showAcceptInvitationModal(false)}
          acceptAction={() => this.acceptInvitation()}
        />
        <LoaderDialog
          open={this.state.showProgressLoader}
          title={"Reenviando..."}
          message={"Espere un momento"}
          class="result-message"
          closeAction={() => this.showResultModal(false)}
          acceptAction={() => this.showResultModal(false)}
        />
        <ConfirmDialog
          open={this.state.showDeleteUsersModal}
          title="¿Eliminar usuarios?"
          message={"¿Estás seguro que deseas eliminar estos usuarios e invitaciones?"}
          contentObject={
            <UserChipContainer
              deleteTag={(id) => this.handleUserChipClick(id)}
              items={
                !Array.isArray(this.props.users)
                  ? []
                  : this.props.users
                      .filter((u) =>
                        this.state.selectedUsersIds.includes(u.user_id)
                      )
                      .map((u) => {
                        return {
                          id: u.user_id,
                          name: u.full_name,
                          avatarType: u.profile_image ? "full" : "contained",
                          avatar: getAvatarUrl(u.profile_image, u.status_id),
                        };
                      })
              }
            />
          }
          acceptText="Aceptar"
          class="delete-facility"
          showCancel={true}
          cancelText="Cancelar"
          cancelButtonType="secondary"
          closeAction={() => this.showDeleteUsersModal(false)}
          acceptAction={() => this.deleteUsers()}
        />
        <ConfirmDialog
          open={this.state.showResendInvitationModal}
          title="¿Reenviar invitaciones?"
          message={"¿Estás seguro que deseas reenviar estás invitaciones?"}
          contentObject={
            <UserChipContainer
              deleteTag={(id) => this.handleResendChipClick(id)}
              items={
                !Array.isArray(this.props.users)
                  ? []
                  : this.props.users
                      .filter((u) =>
                        this.state.selectedInvitationsIds.includes('invitation-' + u.invitation_id)
                      )
                      .map((u) => {
                        return {
                          id: u.user_id,
                          name: u.email,
                          avatarType: u.profile_image ? "full" : "contained",
                          avatar: getAvatarUrl(u.profile_image, u.status_id),
                        };
                      })
              }
            />
          }
          acceptText="Aceptar"
          class="delete-facility"
          showCancel={true}
          cancelText="Cancelar"
          cancelButtonType="secondary"
          closeAction={() => this.showResendInvitationModal(false)}
          acceptAction={() => this.resendInvitation()}
        />
      </div>
    );
  }
}

const getAvatarUrl = (partialUrl, userStatus) => {
  if (userStatus === 5) {
    return "/images/mail.svg";
  } else if (partialUrl) {
    return getUrlsEnv().files.concat(partialUrl);
  }
  return "/images/user.svg";
};

const mapStateToProps = (state) => {
  const userInfo = getEntityItems(state, "COMPANIES.USERINFO");
  let searchStr = normalizeStr(state.userReducer.searchStr);
  let users = (
    (state.userReducer.users.data && state.userReducer.users.data.results) ||
    []
  ).filter(
    (u) =>
      normalizeStr(u.full_name).includes(searchStr) ||
      normalizeStr(u.email).includes(searchStr) ||
      (u.facilities || []).some((f) =>
        normalizeStr(f.name).includes(searchStr)
      ) ||
      (u.role_id || []).some((g) => normalizeStr(g.title).includes(searchStr))
  );

  let facilitiesByTag = [
    { id: 9999999999, description: "Sin etiqueta", items: [] },
  ];
  (state.facilityReducer.facilities.data || []).forEach((f) => {
    if (f.tags.length) {
      f.tags.forEach((t) => {
        let tagIndex = facilitiesByTag.map((fT) => fT.id).indexOf(t.id);
        if (tagIndex !== -1) {
          facilitiesByTag[tagIndex].items.push({
            id: f.id,
            description: f.name.concat(" - ", f.code, " - ", f.alias),
          });
        } else {
          let newTag = {
            id: t.id,
            description: t.title,
            items: [
              {
                id: f.id,
                description: f.name.concat(" - ", f.code, " - ", f.alias),
              },
            ],
          };
          facilitiesByTag.push(newTag);
        }
      });
    } else {
      facilitiesByTag[0].items.push({
        id: f.id,
        description: f.name.concat(" - ", f.code, " - ", f.alias),
      });
    }
  });
  facilitiesByTag.push(facilitiesByTag.shift());

  const configuration = state.configuration.UserCatalog;
  return {
    configuration,
    facilitiesByTag,
    companyType: (state.globalCatalog.session && state.globalCatalog.session.company)
      ? state.globalCatalog.session.company.type
      : {},
    searchStr: state.userReducer.searchStr,
    fleetTags: (state.tags.tags || []).filter(
      (tag) =>
        tag.type ===
        (
          (state.tags.tagTypes.data || []).find(
            (type) => type.description.toLowerCase() === "flotilla"
          ) || { id: 0 }
        ).id
    ),
    tags: Array.isArray(state.companyReducer.companyRoles.data)
      ? state.companyReducer.companyRoles.data.map((rol) => ({
          ...rol,
          title: rol.description,
        }))
      : [],
    facilities:
      state.facilityReducer.facilities.data &&
      state.facilityReducer.facilities.data.map((f) => ({
        id: f.id,
        description: f.name,
        name: f.name,
        code: f.code,
        alias: f.alias,
      })),
    accountStatusCatalog: state.globalCatalog.accountStatus.data,
    userTypes: state.userReducer.userTypes.data,
    company_users: state.companyReducer.companyUsers.data,
    userCount:
      state.userReducer.users.data && state.userReducer.users.data.count,
    users: users/* state.userReducer.users.data && state.userReducer.users.data.results */,
    userInfo
  };
};

const mapDispatchToProps = (dispatch) => {
  dispatch(loadSession());
  dispatch(loadTags());
  // dispatch(getUsers(defaultPaginatorItems, 1));
  dispatch(loadCompanyUsers());
  dispatch(loadAccountStatusCatalog());
  dispatch(getUserTypes());
  dispatch(listFacilities());
  dispatch(listCompanyRoles());
  return {
    reloadUsers: (
      limit,
      offset,
      userType,
      facilities,
      role,
      account_status,
      searchStr
    ) =>
      dispatch(
        getUsers(
          limit,
          offset,
          userType,
          facilities,
          role,
          account_status,
          searchStr
        )
      ),
    searchByTextUser: (text) => dispatch(filterUsersByText(text)),
    acceptInvitation: (invitation_id) =>
      dispatch(acceptInvitation(invitation_id)),
    cancelInvitation: (invitation_id) =>
      dispatch(cancelInvitation(invitation_id)),
    resendInvitation: (invitation_id) =>
      dispatch(resendInvitation(invitation_id)),
    invitePerson: (email, userType, facilities, permissions, fleets) =>
      dispatch(
        createInvitation(
          3,
          userType,
          undefined,
          email,
          { facilities },
          { tags: permissions },
          { tags: fleets }
        )
      ),
    inviteUser: (userId, email, userType, facilities, permissions, fleets) =>
      dispatch(
        createInvitation(
          2,
          userType,
          userId,
          email,
          { facilities },
          { tags: permissions },
          { tags: fleets }
        )
      ),
    deleteUser: (company_user_id) =>
      dispatch(deleteCompanyUsers(company_user_id)),
    validateObjPerms: (perms, obj) => validateObjPerms(perms, obj),
    setConfiguration: (config) => dispatch(setConfig("UserCatalog", config)),
    setNavbarAction: (name, config) => dispatch(setNavbarAction(name, config)),
    removeNavbarAction: (name) => dispatch(removeNavbarAction(name)),
    getUser: (params, opt) => dispatch(readEntities("COMPANIES.USERINFO", params, opt)),
  };
};

const connectToStore = connect(mapStateToProps, mapDispatchToProps);
export default connectToStore(UserCatalog);

UserCatalog.defaultProps = {
  users: [],
  userCount: 0,
  userTypes: [],
  connectionStatus: [],
  facilities: [],
  tags: [],
  permissions: [],
  accountStatusCatalog: [],
  reloadUsers: () => {
    console.log("Not yet implemented");
  },
  searchByTextUser: () => {
    console.log("Not yet implemented");
  },
};

const USER_STATUS_ICON_CATALOG = {
  1: "?",
  2: "?",
  3: "?",
  4: "✓",
  5: "!",
};

async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}
